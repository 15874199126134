import { AttributeTypeEnum, CaseReportLinkAttributeView } from '@mark43/rms-api';
import { ReasonForRelationFormNItemDataShape } from '../state/form/reasonForRelationForm';

export const getCaseReportLinkAttributeView = ({
    reasonForRelationAttrId,
    reasonForRelationOther,
}: {
    reasonForRelationAttrId?: number;
    reasonForRelationOther?: string;
}): CaseReportLinkAttributeView | undefined => {
    if (!!reasonForRelationAttrId) {
        return {
            attributeId: reasonForRelationAttrId,
            attributeType: AttributeTypeEnum.REASON_FOR_RELATION.name,
            otherAttributeDescription: reasonForRelationOther,
        };
    }

    return;
};

export const getUniqueReportIds = (reportCaseLinks: ReasonForRelationFormNItemDataShape[]) => {
    const reportIds = reportCaseLinks.flatMap(({ reportData = [] }) =>
        reportData.flatMap(({ reportIds = [] }) => reportIds)
    );
    return [...new Set(reportIds)];
};
