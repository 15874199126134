import { UsageSourceModuleEnum } from '@mark43/rms-api';
import * as Sentry from '@sentry/browser';
import { Table as _Table, TableColumn as Column, TableRow as _Row } from 'components-mark43';
import React from 'react';
import { isFunction, without } from 'lodash';
import { compose, withHandlers, withPropsOnChange } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import PropertyTitle from '~/client-common/core/domain/item-profiles/components/PropertyTitle';
import { ItemID } from '~/client-common/core/domain/item-profiles/components/ItemID';
import { deleteHydratedItem } from '~/client-common/core/domain/item-profiles/state/data';
import _ConnectedPropertyStatusTitlesForItem from '~/client-common/core/domain/property-statuses/components/ConnectedPropertyStatusTitlesForItem';
import _ConnectedFormattedAttribute from '~/client-common/core/domain/attributes/components/ConnectedFormattedAttribute';
import { isStorageLocationEditableSelector } from '~/client-common/core/domain/chain-events/state/ui';
import FormattedAttribute from '~/client-common/core/domain/attributes/components/FormattedAttribute';
import ConnectedFormattedPropertyStatusQuantityForItem from '~/client-common/core/domain/property-statuses/components/ConnectedFormattedPropertyStatusQuantityForItem';
import itemSidePanelOperationEnum from '~/client-common/core/enums/client/itemSidePanelOperationEnum';
import {
    DISPLAY_ONLY_CUSTODY_LABEL,
    ITEM_FACILITY_LINK_STORAGE_LOCATION_ID,
    PROPERTY_STATUS_INFIELD_TRANSFER_BY_OFFICER_ID,
} from '~/client-common/core/enums/universal/fields';
import useFields from '~/client-common/core/fields/hooks/useFields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { itemHasChainEventsSelector } from '~/client-common/core/domain/chain-events/state/data';
import _Checkbox, {
    CheckboxBox,
    CheckboxDescription,
    CheckboxLabel,
} from '../../../../core/forms/components/checkboxes/Checkbox';
import { Tooltip } from '../../../../core/components/tooltip';
import testIds from '../../../../../core/testIds';
import { currentReportRENSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import { getRecentHydratedItems } from '../../state/ui/itemProfiles';
import { openItemSidePanel } from '../../state/ui/itemSidePanel';
import ActionIcons from '../../../../core/components/ActionIcons';
import useQuickAddCallbacks from '../../hooks/useQuickAddCallbacks';
import {
    closeConfirmationModal,
    openConfirmationModal,
    openErrorModal,
} from '../../../../../legacy-redux/actions/boxActions';
import { getItemTypeForItemEvent } from '../../../../dragon/events/get-item-type-for-item-event';
import _RecentItemsButton from './RecentItemsButton';

const strings = componentStrings.reports.core.ManageItemsItemList;

/**
 * Delete a linked hydrated item from a report and update the recent hydrated item profile ids Nexus state.
 * @param {boolean} isVehicle
 * @param {string} itemProfileId
 * @param {number} ownerId
 * @param {number} reportingEventNumber
 */
const removeHydratedItem = ({ itemProfileId, itemTypeAttrId }) => (dispatch) =>
    dispatch((_, _1, dependencies) =>
        dispatch(deleteHydratedItem(itemProfileId)).then((data) => {
            dependencies.dragonEvents.publish({
                type: 'ITEM_REMOVED',
                payload: {
                    itemType: getItemTypeForItemEvent(itemTypeAttrId),
                    itemId: itemProfileId,
                },
            });

            return data;
        })
    );

const fetchRecentHydratedItems = ({ isVehicle, ownerId, ownerType, reportingEventNumber }) => (dispatch) =>
    dispatch(
        getRecentHydratedItems({
            isVehicle,
            ownerId,
            ownerType,
            reportingEventNumber,
        })
    );

const PropertyTitleAndQuantity = styled.span`
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;
const ConnectedPropertyStatusTitlesForItem = styled(_ConnectedPropertyStatusTitlesForItem)`
    display: flex;
    flex-direction: column;
    margin-top: 3px;
`;
const ConnectedFormattedAttribute = styled(_ConnectedFormattedAttribute)`
    display: block;
`;
const _ItemActionIcons = styled(ActionIcons)`
    flex: 0 0 auto;
`;

const Table = styled(_Table)`
    flex: 0 0 auto;
    word-break: break-word;
`;

const Row = styled(_Row)`
    border-bottom: 2px solid ${(props) => props.theme.colors.extraLightGrey};
    display: flex;
    flex-direction: row;
    font-size: var(--arc-fontSizes-sm);
    margin: 0 16px;
    padding: 10px;
`;

const Column1 = styled(Column)`
    flex: 1 1 auto;
`;

const Column2 = styled(Column)`
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    margin-left: 10px;
`;

const Checkbox = styled(_Checkbox)`
    ${/* sc-selector */ CheckboxBox} {
        flex: 0 0 auto;
    }

    ${/* sc-selector */ CheckboxDescription} {
        flex: 1 1 auto;
    }

    ${/* sc-selector */ CheckboxLabel} {
        display: flex;
        float: none;
    }
    float: none;
    margin-bottom: 0;

    .dark & {
        float: none;
        margin-bottom: 0;
    }
`;

const RecentItemsButton = styled(_RecentItemsButton)`
    margin-left: 26px;
    margin-right: 26px;
`;

export const ItemCheckbox = ({
    itemProfileId,
    setSelectedItemProfileIds,
    label,
    value,
    disabled,
}) => {
    const fieldDisplayNames = useFields([
        ITEM_FACILITY_LINK_STORAGE_LOCATION_ID,
        PROPERTY_STATUS_INFIELD_TRANSFER_BY_OFFICER_ID,
        DISPLAY_ONLY_CUSTODY_LABEL,
    ]);
    const onChange = React.useCallback(() => {
        setSelectedItemProfileIds((selectedItemProfileIds) => {
            if (selectedItemProfileIds.indexOf(itemProfileId) >= 0) {
                return without(selectedItemProfileIds, itemProfileId);
            } else {
                return [...selectedItemProfileIds, itemProfileId];
            }
        });
    }, [itemProfileId, setSelectedItemProfileIds]);

    const checkbox = (
        <Checkbox
            onChange={onChange}
            label={label}
            value={value}
            disabled={disabled}
            testId={testIds.MANAGE_ITEMS_SIDE_PANEL_CHECKBOX}
        />
    );

    return disabled ? (
        <Tooltip
            side="left"
            content={strings.tooltips.disabledCheckboxTooltip({
                dropOffLocation: fieldDisplayNames[ITEM_FACILITY_LINK_STORAGE_LOCATION_ID],
                officerPossession:
                    fieldDisplayNames[PROPERTY_STATUS_INFIELD_TRANSFER_BY_OFFICER_ID],
                custody: fieldDisplayNames[DISPLAY_ONLY_CUSTODY_LABEL],
            })}
            collisionBoundary={document.querySelector('.mark43-react-side-panel')}
        >
            <div>{checkbox}</div>
        </Tooltip>
    ) : (
        checkbox
    );
};

/**
 * Action Icons for Item Entry V2
 * @param  {string}  props.ownerType
 * @param  {number}  props.ownerId
 * @param  {boolean} props.hideDuplicate
 * @param  {string}  props.className     (Optional) className for resulting span.
 * @param  {boolean}  props.hideDividers (Optional) className for resulting span.
 */
export const ItemActionIcons = compose(
    connect(
        createStructuredSelector({
            isStorageLocationEditable: isStorageLocationEditableSelector,
            reportingEventNumber: currentReportRENSelector,
            applicationSettings: applicationSettingsSelector,
            itemHasChainEvents: itemHasChainEventsSelector,
        }),
        {
            openItemSidePanel,
            onRemove: removeHydratedItem,
            openConfirmationModal,
            closeConfirmationModal,
            onRemoveError: (err) =>
                openErrorModal({
                    title: strings.errorModalTitle,
                    paragraphs: [err.message],
                }),
            fetchRecentHydratedItems,
        }
    ),
    withPropsOnChange(
        ['isStorageLocationEditable', 'masterItemId'],
        ({ isStorageLocationEditable, masterItemId }) => ({
            isStorageLocationIdDisabled: !isStorageLocationEditable(masterItemId),
        })
    ),
    withHandlers({
        onEdit({
            openItemSidePanel,
            isStorageLocationIdDisabled,
            itemProfileId,
            isVehicle,
            ownerId,
            ownerType,
            reportingEventNumber,
        }) {
            return () => {
                openItemSidePanel({
                    itemProfileId,
                    isStorageLocationIdDisabled,
                    isVehicle,
                    operation: itemSidePanelOperationEnum.EDIT,
                    ownerType,
                    ownerId,
                    reportingEventNumber,
                });
            };
        },
        onDuplicate({
            openItemSidePanel,
            itemProfileId,
            isVehicle,
            ownerId,
            ownerType,
            reportingEventNumber,
        }) {
            return () => {
                openItemSidePanel({
                    itemProfileId,
                    isVehicle,
                    operation: itemSidePanelOperationEnum.DUPLICATE,
                    ownerType,
                    ownerId,
                    reportingEventNumber,
                });
            };
        },
        onRemove: ({
            itemProfileId,
            itemTypeAttrId,
            isVehicle,
            onRemove,
            onRemoveError,
            ownerId,
            ownerType,
            reportingEventNumber,
            nameQuickAddCallback,
            fetchRecentHydratedItems,
            openConfirmationModal,
            closeConfirmationModal,
            applicationSettings,
            itemHasChainEvents,
            masterItemId,
        }) => () => {
            let modalCheck = false;
            function onRemoveLogic(modalCheck) {
                onRemove({
                    isVehicle,
                    itemProfileId,
                    itemTypeAttrId,
                    ownerId,
                    ownerType,
                    reportingEventNumber,
                })
                    .then(() => {
                        fetchRecentHydratedItems({ isVehicle, ownerId, reportingEventNumber })
                            .then(() => {
                                if (isFunction(nameQuickAddCallback)) {
                                    nameQuickAddCallback();
                                }
                            })
                            .catch((err) => {
                                Sentry.withScope((scope) => {
                                    scope.setTag('module', UsageSourceModuleEnum.REPORTS.name);
                                    scope.setExtra('params', {
                                        isVehicle,
                                        ownerId,
                                        reportingEventNumber,
                                    });
                                    scope.setExtra('errorMessage', err.message);
                                    Sentry.captureMessage('Failed to load recent hydrated items');
                                });
                            });
                        if (modalCheck) {
                            closeConfirmationModal();
                        }
                    })
                    .catch((err) => {
                        onRemoveError(err);
                    });
            }

            if (
                applicationSettings.RMS_EVIDENCE_LABELS_FIRST_ENABLED &&
                itemHasChainEvents(masterItemId)
            ) {
                modalCheck = true;
                openConfirmationModal({
                    title: 'Confirmation',
                    message:
                        'This item also exists in the Evidence Module. Deleting the item from this report will not delete it from the Custodial Property Report.',
                    saveCallback: () => onRemoveLogic(modalCheck),
                });
            } else {
                modalCheck = false;
                onRemoveLogic(modalCheck);
            }
        },
    })
)(_ItemActionIcons);

/**
 * List of items in the Manage Items Side Panel.
 * @param  {string}  props.className (Optional) className for resulting span.
 * @param  {boolean} props.disableItemLinking
 * @param  {object}  props.itemProfiles List of item profiles that will be rendered.
 * @param  {boolean} props.isVehicle
 * @param  {string}  props.ownerType
 * @param  {number}  props.ownerId
 * @param  {boolean|Function} [props.showActionButtons=true]
 */
const ManageItemsItemList = ({
    className,
    disableItemLinking = false,
    itemProfiles,
    isVehicle,
    limitToOne,
    ownerType,
    ownerId,
    showActionButtons = true,
    showCheckboxes = false,
    selectableItemProfileIds,
    selectedItemProfileIds,
    setSelectedItemProfileIds,
    renderAdditionalItem,
}) => {
    const { nameQuickAddCallback } = useQuickAddCallbacks();

    const renderBodyRow = ({ data: itemProfile, key, index }) => {
        const itemInfo = (
            <>
                <PropertyTitleAndQuantity>
                    <PropertyTitle itemProfile={itemProfile} delimiter=", " />
                    {!isVehicle && (
                        <ConnectedFormattedPropertyStatusQuantityForItem
                            itemProfileId={itemProfile.id}
                        >
                            {(renderQuantity) => (
                                <span>
                                    {' ('}
                                    {renderQuantity}
                                    {')'}
                                </span>
                            )}
                        </ConnectedFormattedPropertyStatusQuantityForItem>
                    )}
                </PropertyTitleAndQuantity>
                {!isVehicle && (
                    <ConnectedFormattedAttribute
                        attributeId={itemProfile.itemTypeAttrId}
                        format={FormattedAttribute.FORMATS.ITEM_TYPE}
                    />
                )}
                <ConnectedPropertyStatusTitlesForItem itemProfileId={itemProfile.id} />
                <ItemID itemProfile={itemProfile} />
                {isFunction(renderAdditionalItem) ? renderAdditionalItem({ index, itemProfile }) : null}
            </>
        );

        const shouldRenderActionButtons = typeof showActionButtons === 'function'
            ? showActionButtons(itemProfile.masterItemId)
            : showActionButtons;

        const renderActionButtons = ({ editButton, duplicateButton, deleteButton }) =>
            shouldRenderActionButtons && (
                <>
                    <Tooltip side="top" content={strings.tooltips.editItem}>
                        <span>{editButton}</span>
                    </Tooltip>
                    {!isVehicle && (
                        <Tooltip side="top" content={strings.tooltips.duplicateItem}>
                            <span>{duplicateButton}</span>
                        </Tooltip>
                    )}
                    <Tooltip side="top" content={strings.tooltips.deleteItem}>
                        <span>{deleteButton}</span>
                    </Tooltip>
                </>
            );

        return (
            <Row key={key}>
                <Column1>
                    {showCheckboxes ? (
                        <ItemCheckbox
                            label={itemInfo}
                            itemProfileId={itemProfile.id}
                            setSelectedItemProfileIds={setSelectedItemProfileIds}
                            value={selectedItemProfileIds.indexOf(itemProfile.id) >= 0}
                            disabled={selectableItemProfileIds.indexOf(itemProfile.id) === -1}
                        />
                    ) : (
                        itemInfo
                    )}
                </Column1>
                <Column2>
                    <ItemActionIcons
                        hideIconLabels={true}
                        isVehicle={isVehicle}
                        itemProfileId={itemProfile.id}
                        itemTypeAttrId={itemProfile.itemTypeAttrId}
                        masterItemId={itemProfile.masterItemId}
                        ownerType={ownerType}
                        ownerId={ownerId}
                        nameQuickAddCallback={nameQuickAddCallback}
                        render={renderActionButtons}
                    />
                </Column2>
            </Row>
        );
    };

    const recentItemsButton = disableItemLinking ? null : (
        <RecentItemsButton
            isVehicle={isVehicle}
            limitToOne={limitToOne}
            ownerId={ownerId}
            ownerType={ownerType}
        />
    );

    return (
        <div className={className}>
            <Table data={itemProfiles} renderBodyRow={renderBodyRow} />
            {recentItemsButton}
        </div>
    );
};

export default ManageItemsItemList;
