import React from 'react';
import { IconButton as ArcIconButton, IconButtonProps } from 'arc';
import { AnalyticsPropertyEnum } from '../../analytics/constants/analyticsEnum';
import { useAnalytics } from '../../analytics/hooks/useAnalytics';

type RmsIconButtonProps = IconButtonProps & { testId?: string };

export const IconButton = React.forwardRef<HTMLButtonElement, RmsIconButtonProps>(
    ({ onClick, testId, ...props }, ref) => {
        const { filteredTrack } = useAnalytics();
        const handleClickWithTrack = React.useCallback(
            (e: React.MouseEvent<HTMLButtonElement>) => {
                filteredTrack(
                    {
                        [AnalyticsPropertyEnum.BUTTON]: testId,
                    },
                    testId
                );
                onClick?.(e);
            },
            [onClick, filteredTrack, testId]
        );

        return (
            <ArcIconButton
                {...props}
                ref={ref}
                data-test-id={testId}
                onClick={handleClickWithTrack}
            />
        );
    }
);
