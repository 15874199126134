import _, { omit, get, first, map, isArray, compact } from 'lodash';

import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import { START_OF_DAY_TIME, END_OF_DAY_TIME } from '~/client-common/core/dates/utils/dateHelpers';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import rangeFieldKeyEnum from '~/client-common/core/enums/client/rangeFieldKeyEnum';
import rangeFieldTypeEnum from '~/client-common/core/enums/client/rangeFieldTypeEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import * as fields from '~/client-common/core/enums/universal/fields';
import {
    allSubdivisionAttrIdNames,
    includeLocationSubdivisions,
    getSubdivisionsFromLocation,
    getElasticQuerySubdivisionAttrIds,
} from '~/client-common/core/domain/locations/utils/subdivisionHelpers';
import { personnelFieldsetViewModel } from '../../../../../legacy-redux/configs/fieldsetsConfig';
import { convertFormModelToFilterGroups } from '../../../../../legacy-redux/helpers/formFilterHelpers';
import {
    filterFormData,
    buildFlatFormFieldViewModels,
    buildFormModel,
} from '../../../../../legacy-redux/helpers/formHelpers';
import { createFormModule } from '../../../../core/forms';
import { parseOffenseCaseStatusFromReportDefinitions } from '../../../../core/elastic-search/util/parseOffenseCaseStatusFromReportDefinitions';
import {
    extractDateTimeModelFromQuery,
    dateFormFieldsToElasticQueryFields,
} from '../../../../core/elastic-search/util/extractDateTimeModelFromQuery';
import { subdivisionAttrFieldsForViewModels } from '../../../../search/core/state/forms/subdivisionFields';

const strings = componentStrings.reports.dashboard;

const {
    DEPARTMENT,
    DATE_PRESET,
    FIELDSET,
    RANGE,
    ATTRIBUTE,
    AGENCY,
    LABEL,
    CUSTOM_REPORT_CLASSIFICATION,
    REPORT_DEFINITION,
    CASE_STATUS,
} = fieldTypeClientEnum;

const { DATE_TIME_RANGE, TIME_RANGE } = rangeFieldKeyEnum;

const { RANGE_START, RANGE_END, WITHIN_LAST_PERIOD, TO_DATE_PERIOD } = rangeFieldTypeEnum;

/**
 * View model for the 'DateTimeFieldset' component. Created specifically for the
 * react reports dashboard
 * @type {Object}
 */
export const dateTimeFieldsetViewModel = {
    type: FIELDSET,
    key: 'dateTime',
    title: strings.DateTimeFieldset.title,
    fields: {
        ...buildFlatFormFieldViewModels([
            {
                key: 'dateType',
                type: DATE_PRESET,
                label: strings.DateTimeFieldset.labels.dateType,
            },
            {
                key: 'withinLastPeriod',
                type: RANGE,
                rangeKey: DATE_TIME_RANGE,
                rangeType: WITHIN_LAST_PERIOD,
            },
            {
                key: 'toDatePeriod',
                type: RANGE,
                rangeKey: DATE_TIME_RANGE,
                rangeType: TO_DATE_PERIOD,
            },
            {
                key: 'startDateUtc',
                type: RANGE,
                rangeKey: DATE_TIME_RANGE,
                rangeType: RANGE_START,
                label: strings.DateTimeFieldset.labels.dateTimeRange,
            },
            {
                key: 'endDateUtc',
                type: RANGE,
                rangeKey: DATE_TIME_RANGE,
                rangeType: RANGE_END,
            },
            {
                key: 'startTime',
                type: RANGE,
                rangeKey: TIME_RANGE,
                rangeType: RANGE_START,
                label: strings.DateTimeFieldset.labels.shiftTimeRange,
            },
            {
                key: 'endTime',
                type: RANGE,
                rangeKey: TIME_RANGE,
                rangeType: RANGE_END,
            },
        ]),
    },
};

/**
 * View model for the 'ReportDetailsFieldset' component. Created specifically for the
 * react reports dashboard
 * @type {Object}
 */
const reportDetailsFieldsetViewModel = {
    type: FIELDSET,
    key: 'reportDetails',
    title: strings.ReportDetailsFieldset.title,
    fields: {
        ...buildFlatFormFieldViewModels([
            {
                key: 'reportingEventNumber',
                fieldNameForFilterLabel: fields.REPORT_REPORTING_EVENT_NUMBER,
                label: '', // label is directly passed in to ReportDetailsFieldset from fieldConfigs (don't use fieldStrings.js)
            },
            {
                key: 'globalSequenceNumber',
            },
            {
                key: 'reportDefinitions',
                type: REPORT_DEFINITION,
                label: strings.ReportDetailsFieldset.reportTypes,
            },
            {
                key: 'subdivisionAttrIds',
                type: ATTRIBUTE,
            },
            ...subdivisionAttrFieldsForViewModels,
            {
                key: 'agencyIds',
                fieldName: fields.REPORT_AGENCY_ID,
                fieldNameForFilterLabel: fields.REPORT_AGENCY_ID,
                type: AGENCY,
            },
            {
                key: 'departmentIds',
                fieldName: fields.REPORT_DEPARTMENT_ID,
                fieldNameForFilterLabel: fields.REPORT_DEPARTMENT_ID,
                type: DEPARTMENT,
            },
            {
                key: 'hasCustomReportClassificationAttrId',
                type: CUSTOM_REPORT_CLASSIFICATION,
                fieldName:
                    fields.DISPLAY_ONLY_REPORT_DETAILS_INCIDENT_OR_OFFENSE_CLASSIFICATION_LABEL,
            },
            {
                key: 'customReportClassificationAttrIds',
                type: CUSTOM_REPORT_CLASSIFICATION,
                fieldName:
                    fields.DISPLAY_ONLY_REPORT_DETAILS_INCIDENT_OR_OFFENSE_CLASSIFICATION_LABEL,
            },
            {
                key: 'routingLabelAttrIds',
                type: ATTRIBUTE,
                fieldName: fields.DISPLAY_ONLY_REPORT_DETAILS_LABELS,
            },
            {
                key: 'hasRoutingLabel',
                type: LABEL,
                fieldName: fields.DISPLAY_ONLY_REPORT_DETAILS_LABELS,
            },
            {
                key: 'hasCaseStatus',
                fieldName: fields.REPORT_CASE_STATUS_CASE_STATUS_ATTR_ID,
                type: CASE_STATUS,
            },
            {
                key: 'caseStatusAttrId',
                fieldName: fields.REPORT_CASE_STATUS_CASE_STATUS_ATTR_ID,
                type: CASE_STATUS,
            },
            {
                key: 'securityClassificationAttrId',
                type: ATTRIBUTE,
                fieldName: fields.SECURITY_CLASSIFICATION_SECURITY_CLASSIFICATION_ATTR_ID,
            },
            {
                key: 'offenseCaseStatusAttrId',
                fieldName: fields.OFFENSE_CASE_STATUS_CASE_STATUS_ATTR_ID,
                type: CASE_STATUS,
            },
            {
                key: 'offenseNfibNumber',
                fieldName: fields.OFFENSE_NFIB_NUMBER,
            },
            { key: 'clientApprovalStatuses' },
        ]),
    },
};

const fieldViewModels = {
    ...buildFlatFormFieldViewModels(['quickSearchQuery']),
    dateTime: dateTimeFieldsetViewModel,
    personnel: personnelFieldsetViewModel,
    reportDetails: reportDetailsFieldsetViewModel,
};

const convertReportsDashboardSearchFormModelToElasticQuery = (formModel = {}) => {
    const dateFields = dateFormFieldsToElasticQueryFields(formModel.dateTime);
    const hasRoutingLabelArrayOrVal = get(formModel, 'reportDetails.hasRoutingLabel');
    const hasRoutingLabel = isArray(hasRoutingLabelArrayOrVal)
        ? first(hasRoutingLabelArrayOrVal)
        : hasRoutingLabelArrayOrVal;
    const hasCustomReportClassification = first(
        get(formModel.reportDetails, 'hasCustomReportClassificationAttrId')
    );

    const reportDefinitions = map(
        get(formModel.reportDetails, 'reportDefinitions'),
        (reportDefinitionId) => ({ reportDefinitionId })
    );

    const offenseCaseStatusAttrId = get(formModel.reportDetails, 'offenseCaseStatusAttrId');
    const offenseNfibNumber = get(formModel.reportDetails, 'offenseNfibNumber');

    // We need to omit the `approvalStatus` and `secondaryApprovalStatus` from
    // the report details on the form model because there are still some
    // elasticQueries in the database that have these set despite them not
    // being used anymore.
    // Until we patch the data in elastic search to remove these fields entirely
    // this is the solution moving forward for KRA-5726.
    return filterFormData({
        ...dateFields,
        ...formModel.personnel,
        ...omit(
            formModel.reportDetails,
            'reportDefinitions', // set below
            ...allSubdivisionAttrIdNames, // move to locations
            'caseStatusAttrId', // expanded into array
            'hasCustomReportClassificationAttrId', // set below
            'approvalStatus',
            'secondaryApprovalStatus',
            'offenseCaseStatusAttrId',
            'offenseNfibNumber'
        ),
        reportDefinitions: _(reportDefinitions)
            .concat(
                (offenseCaseStatusAttrId || offenseNfibNumber) && {
                    offensesAndIncidents: [
                        {
                            offenseCaseStatusAttrIds: offenseCaseStatusAttrId && [
                                offenseCaseStatusAttrId,
                            ],
                            nfibNumber: offenseNfibNumber,
                        },
                    ],
                }
            )
            .compact()
            .value(),
        caseStatusAttrIds: [get(formModel.reportDetails, 'caseStatusAttrId')],
        hasRoutingLabel,
        involvedLocations: [
            ...(formModel.involvedLocations || []),
            ...(includeLocationSubdivisions(formModel.reportDetails)
                ? [getSubdivisionsFromLocation(formModel.reportDetails)]
                : []),
        ],
        quickSearchQuery: formModel.quickSearchQuery,
        hasCustomReportClassificationAttrId:
            hasCustomReportClassification === 'true'
                ? true
                : hasCustomReportClassification === 'false'
                ? false
                : null,
    });
};

const convertReportsDashboardElasticQueryToFormModel = (elasticQueryOrFormModel = {}) => {
    if (elasticQueryOrFormModel.isFormModel) {
        return omit(elasticQueryOrFormModel, 'isFormModel');
    }
    const dateTime = extractDateTimeModelFromQuery(elasticQueryOrFormModel);
    const hasCustomReportClassificationAttrIdVal = get(
        elasticQueryOrFormModel,
        'hasCustomReportClassificationAttrId'
    );

    const subdivisions = getElasticQuerySubdivisionAttrIds(
        elasticQueryOrFormModel,
        'involvedLocations'
    );

    return buildFormModel(
        {
            dateTime,
            personnel: {
                ..._(elasticQueryOrFormModel)
                    .pick(map(personnelFieldsetViewModel.fields, 'key'))
                    .value(),
            },
            reportDetails: {
                ..._(elasticQueryOrFormModel)
                    .pick(map(reportDetailsFieldsetViewModel.fields, 'key'))
                    .omit(
                        'reportDefinitions',
                        ...allSubdivisionAttrIdNames,
                        'caseStatusAttrId',
                        'hasCustomReportClassificationAttrId'
                    )
                    .value(),
                reportDefinitions: compact(
                    map(get(elasticQueryOrFormModel, 'reportDefinitions'), (reportDefObj) =>
                        get(reportDefObj, 'reportDefinitionId')
                    )
                ),
                caseStatusAttrId: get(elasticQueryOrFormModel, 'caseStatusAttrIds[0]'),
                offenseCaseStatusAttrId: parseOffenseCaseStatusFromReportDefinitions(
                    elasticQueryOrFormModel
                ),
                ...subdivisions, // move from locations
                ...(!isUndefinedOrNull(hasCustomReportClassificationAttrIdVal)
                    ? {
                          hasCustomReportClassificationAttrId: [
                              hasCustomReportClassificationAttrIdVal.toString(),
                          ],
                      }
                    : {}),
            },
        },
        fieldViewModels
    );
};

export const convertReportsDashboardFormModelToFilterGroups = (
    formModel,
    formatFieldValue,
    boundSelectors
) => {
    const combinedSubdivisionsLabel = boundSelectors.combinedSubdivisionsLabel;
    const formatFieldByName = boundSelectors.formatFieldByName;
    const globalSequenceNumberLabel = boundSelectors.globalSequenceNumberLabel;

    const relabeledFieldViewModels = {
        ...fieldViewModels,
        reportDetails: {
            ...fieldViewModels.reportDetails,
            fields: {
                ...omit(fieldViewModels.reportDetails.fields, 'clientApprovalStatuses'),
                subdivisionAttrIds: {
                    ...fieldViewModels.reportDetails.fields.subdivisionAttrIds,
                    label: combinedSubdivisionsLabel,
                },
                globalSequenceNumber: {
                    ...fieldViewModels.reportDetails.fields.globalSequenceNumber,
                    label: globalSequenceNumberLabel,
                },
                securityClassificationAttrId: {
                    ...fieldViewModels.reportDetails.fields.securityClassificationAttrId,
                    label: formatFieldByName(
                        fieldViewModels.reportDetails.fields.securityClassificationAttrId.fieldName
                    ),
                },
            },
        },
    };

    return convertFormModelToFilterGroups(
        formModel,
        relabeledFieldViewModels,
        formatFieldValue,
        formatFieldByName
    );
};

const reportsDashboardSearchForm = createFormModule({
    formName: formClientEnum.REPORTS_DASHBOARD_SEARCH,
    fieldViewModels,
    convertToFormModel: convertReportsDashboardElasticQueryToFormModel,
    convertFromFormModel: convertReportsDashboardSearchFormModelToElasticQuery,
});

reportsDashboardSearchForm.actionCreators.updateShiftTimeRange = () => {
    return (dispatch, getState) => {
        const formModel = reportsDashboardSearchForm.selectors.formModelSelector(getState());
        let { startTime, endTime } = formModel.dateTime || {};
        if (startTime && !endTime) {
            endTime = END_OF_DAY_TIME;
        } else if (endTime && !startTime) {
            startTime = START_OF_DAY_TIME;
        }
        dispatch(
            reportsDashboardSearchForm.actionCreators.changePath('dateTime.startTime', startTime)
        );
        dispatch(reportsDashboardSearchForm.actionCreators.changePath('dateTime.endTime', endTime));
    };
};

export default reportsDashboardSearchForm;
