import { EntityTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { compose, withHandlers, withPropsOnChange, withState } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { staffRemarksByEntitySelector as staffRemarksByEntity } from '~/client-common/core/domain/staff-remarks/state/data';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import CollapsibleList from '../../../core/components/CollapsibleList';
import Card from '../../../../legacy-redux/components/core/Card';
import { iconTypes } from '../../../core/components/Icon';
import { currentUserHasAbilitySelector as currentUserHasAbility } from '../../../core/current-user/state/ui';
import { StaffRemark as _StaffRemark } from '../../../evidence/core/components/StaffRemark';
import {
    currentReportCardUITitleByTypeSelector as currentReportCardUITitleByType,
    currentReportIdSelector as currentReportId,
} from '../../../../legacy-redux/selectors/reportSelectors';
import { openStaffRemarkSidePanel } from '../../../evidence/core/state/ui';
import testIds from '../../../../core/testIds';

const { EVIDENCE } = abilitiesEnum;
const { REPORT } = EntityTypeEnum;
const STRINGS = componentStrings.reports.custodialPropertySummary.StaffRemarksCard;

const StaffRemark = styled(_StaffRemark)`
    font-size: var(--arc-fontSizes-sm);
`;

const StaffRemarksCard = ({
    className,
    currentUserHasAbility,
    onEdit,
    staffRemarks,
    currentReportCardUITitleByType,
}) => (
    <Card
        canEdit={currentUserHasAbility(EVIDENCE.EDIT_STAFF_REMARKS)}
        className={className}
        onEdit={onEdit}
        onEditIconType={iconTypes.ADD}
        onEditLabel={STRINGS.labels.addStaffRemarks}
        summaryMode={true}
        title={currentReportCardUITitleByType(reportCardEnum.STAFF_REMARKS.id)}
        testId={testIds.STAFF_REMARK_CARD}
    >
        <CollapsibleList
            items={staffRemarks}
            noItemsText={STRINGS.labels.noRemarks}
            renderItem={(staffRemark) => (
                <StaffRemark key={staffRemark.id} staffRemark={staffRemark} deletable={true} />
            )}
        />
    </Card>
);

const mapStateToProps = createStructuredSelector({
    currentReportId,
    currentUserHasAbility,
    staffRemarksByEntity,
    currentReportCardUITitleByType,
});

const mapDispatchToProps = (dispatch) => ({
    openStaffRemarkSidePanel: (reportId) =>
        dispatch(openStaffRemarkSidePanel(REPORT.name, [reportId])),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withState('viewMore', 'setViewMore', false),
    withHandlers({
        onEdit({ openStaffRemarkSidePanel, currentReportId }) {
            return () => openStaffRemarkSidePanel(currentReportId);
        },
        onToggleViewMore({ setViewMore, viewMore }) {
            return () => setViewMore(!viewMore);
        },
    }),
    withPropsOnChange(
        ['currentReportId', 'staffRemarksByEntity'],
        ({ currentReportId, staffRemarksByEntity }) => ({
            staffRemarks: staffRemarksByEntity(REPORT.name, currentReportId),
        })
    )
)(StaffRemarksCard);
