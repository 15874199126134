import { attributeStatuses } from '~/client-common/core/domain/attributes/configuration';
import * as fields from '~/client-common/core/enums/universal/fields';

// ############  GLOBAL #################

export const adminListMinCharacters = 2;
export const adminListDebounce = 300;
export const adminListChunkSize = 200;

// forms

export const fullFormWidth = 450;
export const halfFormWidth = 218;
export const smallFormWidth = 150;

export const fullPanelFormWidth = 420;
export const halfPanelFormWidth = 196;
export const smallPanelFormWidth = 150;

export { attributeStatuses as adminListStatus };

export const titleTrimLength = 90;

export const attributesAdminFormFields = [
    'attributeType', // appears in the form only for a new attribute
    'displayAbbreviation',
    'inAppDescription',
    'displayValue',
    'endDateUtc',
    'isNone',
    'isOther',
    'isDefault',
    'parentAttributeId',
    'startDateUtc',
    'isEnabled',
    'isProtected',
    'linkType',
];

export const attributesAdminCodeLinkFormFields = [
    'attributeId',
    'attributeType',
    'codeId',
    'codeTypeId',
    'codeTypeSource',
];

export const departmentIPFormFieldList = ['ip', 'isAllowed'];

export const userProfileCjisExtensionFieldNames = [
    'hasCounterTerrorismDataSelfHomePrivilegeIndicator',
    'hasCriminalHistoryDataSelfSearchHomePrivilegeIndicator',
    'hasCriminalIntelDataSelfSearchHomePrivilegeIndicator',
    'hasCriminalInvestigDataSelfSearchHomePrivilegeIndicator',
    'hasGovernmentDataSelfSearchHomePrivilegeIndicator',
    'hasNcicCertificateIndicator',
    'hasNdexPrivilegeIndicator',
    'hasPciiCertificateIndicator',
    'hasCfrCertificateIndicator',
    'hasPublicSafetyOfficerIndicator',
    'hasSwornLawEnforcementOfficerIndicator',
    'hasIntelligenceAnalystIndicator',
    'authenticatorAssuranceLevel',
    'federationAssuranceLevel',
    'identityAssuranceLevel',
    'employerName',
    'federationId',
    'identityProviderId',
    'localId',
    'employerOri',
    'employerOrganizationGeneralCategoryCode',
    'employerStateCode',
];

export const userProfileFormFieldList = [
    'userId',
    'userProfilePhotoId',
    'removedUserProfilePhotoId',
    'departmentId',
    'primaryEmail',
    'dexStateUserId',
    // If Multiple ORI is enabled, we will use this over the dexStateUserId field.
    'dexStateUserIdOriAliasAssociations[].dexUserIdValue',
    'dexStateUserIdOriAliasAssociations[].oriAliasId',
    'dateDexCertificationExpires',
    'isDisabled',
    'isSsoUser',
    'firstName',
    'lastName',
    'middleName',
    'suffix',
    'dateOfBirth',
    'driversLicense',
    'driversLicenseTypeAttrId',
    'sexAttrId',
    'buildAttrId',
    'raceAttrId',
    'externalHrId',
    'dateHired',
    'yearsOfExperience',
    'stopUserAssignmentTypeAttrId',
    'stopUserAssignmentTypeOther',
    'isNonbinaryOfficer',
    'badgeNumber',
    'departmentAgencyId',
    'defaultArrestingAgencyAttrId',
    'user',
    'ssn',
    'eyeColorAttrId',
    'hairColorAttrId',
    'employeeTypeAttrId',
    'educationLevelAttrId',
    'attributes.SUBDIVISION_DEPTH_1',
    'attributes.SUBDIVISION_DEPTH_2',
    'attributes.SUBDIVISION_DEPTH_3',
    'attributes.SUBDIVISION_DEPTH_4',
    'attributes.SUBDIVISION_DEPTH_5',
    'attributes.BUREAU',
    'attributes.DIVISION',
    'attributes.BRANCH',
    'attributes.PERSONNEL_UNIT',
    'attributes.USER_SKILL',
    'attributes.USER_TRAINING',
    'attributes.STOP_DATA_OFFICER_RACE',
    'attributes.STOP_DATA_OFFICER_GENDER',
    'phones[].phoneNumber',
    'phones[].baseDisplayNumber',
    'phones[].hashedCountryCode',
    'phones[].initialCountryCode',
    'dutyStatuses[].id',
    'dutyStatuses[].dutyStatus',
    'dutyStatuses[].dateEffective',
    'userAssignments[].actingRankAttrId',
    'userAssignments[].branchAttrId',
    'userAssignments[].bureauAttrId',
    'userAssignments[].divisionAttrId',
    'userAssignments[].startDateUtc',
    'userAssignments[].isDetail',
    'userAssignments[].subdivision1AttrId',
    'userAssignments[].subdivision2AttrId',
    'userAssignments[].subdivision3AttrId',
    'userAssignments[].subdivision4AttrId',
    'userAssignments[].subdivision5AttrId',
    'userAssignments[].rankAttrId',
    'userAssignments[].title',
    'userAssignments[].endDateUtc',
    'userAssignments[].personnelUnitAttrId',
    'emergencyContactName',
    'emergencyContactEmail',
    'emergencyContactPhone',
    'emergencyContactAddress',
    'externalCadId',
    'cityIdNumber',
    'externalHrId',
    'stateIdNumber',
    'trainingIdNumber',
    'mobileId',
    'primaryUserGroup',
    'heightFeet',
    'heightInches',
    'weight',
    'employmentTypeAttrId',
    'userSignature',
    'removedUserSignature',
    'supervisorUserId',
    'isNonAgency',
].concat(userProfileCjisExtensionFieldNames);

// used to hook up validation rules from the server
export const userProfileFormFieldNames = {
    // User fields
    isDisabled: fields.USER_IS_DISABLED,
    // User Account fields
    isSsoUser: fields.USER_ACCOUNT_IS_SSO_USER,
    primaryEmail: fields.USER_ACCOUNT_PRIMARY_EMAIL,
    // State Authentication Fields
    dexStateUserId: fields.USER_PROFILE_DEX_STATE_USER_ID,
    dexStateUserIdOriAliasAssociations:
        fields.USER_PROFILE_DEX_STATE_USER_ID_ORI_ALIAS_ASSOCIATIONS,
    dateDexCertificationExpires: fields.USER_PROFILE_DATE_DEX_CERTIFICATION_EXPIRES,
    // UserProfile fields
    // yearsOfExperience, stopUserAssignmentTypeAttrId, and stopUserAssignmentTypeOther are intentionally excluded
    // since they are not involved in validation rules
    badgeNumber: fields.USER_PROFILE_BADGE_NUMBER,
    buildAttrId: fields.USER_PROFILE_BUILD_ATTR_ID,
    cityIdNumber: fields.USER_PROFILE_CITY_ID_NUMBER,
    dateHired: fields.USER_PROFILE_DATE_HIRED,
    dateOfBirth: fields.USER_PROFILE_DATE_OF_BIRTH,
    defaultArrestingAgencyAttrId: fields.USER_PROFILE_DEFAULT_ARRESTING_AGENCY_ATTR_ID,
    departmentAgencyId: fields.USER_PROFILE_DEPARTMENT_AGENCY_ID,
    driversLicense: fields.USER_PROFILE_DRIVERS_LICENSE,
    driversLicenseTypeAttrId: fields.USER_PROFILE_DRIVERS_LICENSE_TYPE_ATTR_ID,
    educationLevelAttrId: fields.USER_PROFILE_EDUCATION_LEVEL_ATTR_ID,
    emergencyContactAddress: fields.USER_PROFILE_EMERGENCY_CONTACT_ADDRESS,
    emergencyContactEmail: fields.USER_PROFILE_EMERGENCY_CONTACT_EMAIL,
    emergencyContactPhone: fields.USER_PROFILE_EMERGENCY_CONTACT_PHONE,
    emergencyContactName: fields.USER_PROFILE_EMERGENCY_CONTACT_NAME,
    employeeTypeAttrId: fields.USER_PROFILE_EMPLOYEE_TYPE_ATTR_ID,
    externalCadId: fields.USER_PROFILE_EXTERNAL_CAD_ID,
    externalHrId: fields.USER_PROFILE_EXTERNAL_HR_ID,
    eyeColorAttrId: fields.USER_PROFILE_EYE_COLOR_ATTR_ID,
    firstName: fields.USER_PROFILE_FIRST_NAME,
    hairColorAttrId: fields.USER_PROFILE_HAIR_COLOR_ATTR_ID,
    lastName: fields.USER_PROFILE_LAST_NAME,
    middleName: fields.USER_PROFILE_MIDDLE_NAME,
    mobileId: fields.USER_PROFILE_MOBILE_ID,
    raceAttrId: fields.USER_PROFILE_RACE_ATTR_ID,
    sexAttrId: fields.USER_PROFILE_SEX_ATTR_ID,
    ssn: fields.USER_PROFILE_SSN,
    stateIdNumber: fields.USER_PROFILE_STATE_ID_NUMBER,
    suffix: fields.USER_PROFILE_SUFFIX,
    trainingIdNumber: fields.USER_PROFILE_TRAINING_ID_NUMBER,
    primaryUserGroup: fields.USER_PROFILE_PRIMARY_USER_GROUP,
    // UserAssignment fields
    actingRankAttrId: fields.USER_ASSIGNMENT_ACTING_RANK_ATTR_ID,
    rankAttrId: fields.USER_ASSIGNMENT_RANK_ATTR_ID,
    // UserPhone fields
    phoneNumber: fields.USER_PHONE_PHONE_NUMBER,
    heightFeet: fields.USER_PROFILE_HEIGHT_FEET,
    heightInches: fields.USER_PROFILE_HEIGHT_INCHES,
    weight: fields.USER_PROFILE_WEIGHT,
    supervisorUserId: fields.USER_PROFILE_SUPERVISOR_USER_ID,
    isNonAgency: fields.USER_PROFILE_IS_NON_AGENCY,
    hasCounterTerrorismDataSelfHomePrivilegeIndicator:
        fields.USER_PROFILE_CJIS_EXTENSION_HAS_COUNTER_TERRORISM_DATA_SELF_HOME_PRIVILEGE_INDICATOR,
    hasCriminalHistoryDataSelfSearchHomePrivilegeIndicator:
        fields.USER_PROFILE_CJIS_EXTENSION_HAS_CRIMINAL_HISTORY_DATA_SELF_SEARCH_HOME_PRIVILEGE_INDICATOR,
    hasCriminalIntelDataSelfSearchHomePrivilegeIndicator:
        fields.USER_PROFILE_CJIS_EXTENSION_HAS_CRIMINAL_INTEL_DATA_SELF_SEARCH_HOME_PRIVILEGE_INDICATOR,
    hasCriminalInvestigDataSelfSearchHomePrivilegeIndicator:
        fields.USER_PROFILE_CJIS_EXTENSION_HAS_CRIMINAL_INVESTIG_DATA_SELF_SEARCH_HOME_PRIVILEGE_INDICATOR,
    hasGovernmentDataSelfSearchHomePrivilegeIndicator:
        fields.USER_PROFILE_CJIS_EXTENSION_HAS_GOVERNMENT_DATA_SELF_SEARCH_HOME_PRIVILEGE_INDICATOR,
    hasNcicCertificateIndicator: fields.USER_PROFILE_CJIS_EXTENSION_HAS_NCIC_CERTIFICATE_INDICATOR,
    hasNdexPrivilegeIndicator: fields.USER_PROFILE_CJIS_EXTENSION_HAS_NDEX_PRIVILEGE_INDICATOR,
    hasPciiCertificateIndicator: fields.USER_PROFILE_CJIS_EXTENSION_HAS_PCII_CERTIFICATE_INDICATOR,
    hasCfrCertificateIndicator: fields.USER_PROFILE_CJIS_EXTENSION_HAS_CFR_CERTIFICATE_INDICATOR,
    hasPublicSafetyOfficerIndicator:
        fields.USER_PROFILE_CJIS_EXTENSION_HAS_PUBLIC_SAFETY_OFFICER_INDICATOR,
    hasSwornLawEnforcementOfficerIndicator:
        fields.USER_PROFILE_CJIS_EXTENSION_HAS_SWORN_LAW_ENFORCEMENT_OFFICER_INDICATOR,
    hasIntelligenceAnalystIndicator:
        fields.USER_PROFILE_CJIS_EXTENSION_HAS_INTELLIGENCE_ANALYST_INDICATOR,
    authenticatorAssuranceLevel: fields.USER_PROFILE_CJIS_EXTENSION_AUTHENTICATOR_ASSURANCE_LEVEL,
    federationAssuranceLevel: fields.USER_PROFILE_CJIS_EXTENSION_FEDERATION_ASSURANCE_LEVEL,
    identityAssuranceLevel: fields.USER_PROFILE_CJIS_EXTENSION_IDENTITY_ASSURANCE_LEVEL,
    employerName: fields.USER_PROFILE_CJIS_EXTENSION_EMPLOYER_NAME,
    federationId: fields.USER_PROFILE_CJIS_EXTENSION_FEDERATION_ID,
    identityProviderId: fields.USER_PROFILE_CJIS_EXTENSION_IDENTITY_PROVIDER_ID,
    localId: fields.USER_PROFILE_CJIS_EXTENSION_LOCAL_ID,
    employerOri: fields.USER_PROFILE_CJIS_EXTENSION_EMPLOYER_ORI,
    employerOrganizationGeneralCategoryCode:
        fields.USER_PROFILE_CJIS_EXTENSION_EMPLOYER_ORGANIZATION_GENERAL_CATEGORY_CODE,
    employerStateCode: fields.USER_PROFILE_CJIS_EXTENSION_EMPLOYER_STATE_CODE,
};

// used to show correct labels based on field display values
export const userProfileFormLabelsFieldNames = [
    ...Object.values(userProfileFormFieldNames),
    fields.DISPLAY_ONLY_USER_PROFILE_IS_SSO_LABEL,
    fields.DISPLAY_ONLY_USER_PROFILE_STATE_AUTHENTICATION_LABEL,
    fields.DISPLAY_ONLY_USER_PROFILE_EMPLOYMENT_TYPE_LABEL,
    fields.DUTY_STATUS_HISTORY_DUTY_STATUS,
    fields.DUTY_STATUS_HISTORY_DATE_EFFECTIVE,
    fields.USER_ASSIGNMENT_START_DATE_UTC,
    fields.USER_ASSIGNMENT_END_DATE_UTC,
    fields.USER_ASSIGNMENT_TITLE,
    fields.USER_ASSIGNMENT_BRANCH_ATTR_ID,
    fields.USER_ASSIGNMENT_BUREAU_ATTR_ID,
    fields.USER_ASSIGNMENT_DIVISION_ATTR_ID,
    fields.USER_ASSIGNMENT_PERSONNEL_UNIT_ATTR_ID,
    fields.USER_ASSIGNMENT_IS_DETAIL,
    fields.USER_ASSIGNMENT_SUBDIVISION_1_ATTR_ID,
    fields.USER_ASSIGNMENT_SUBDIVISION_2_ATTR_ID,
    fields.USER_ASSIGNMENT_SUBDIVISION_3_ATTR_ID,
    fields.USER_ASSIGNMENT_SUBDIVISION_4_ATTR_ID,
    fields.USER_ASSIGNMENT_SUBDIVISION_5_ATTR_ID,
    fields.DISPLAY_ATTRIBUTE_PERSON_SKILL,
    fields.DISPLAY_ATTRIBUTE_PERSON_TRAINING,
    fields.DISPLAY_ONLY_USER_PROFILE_FULL_NAME_LABEL,
    fields.DISPLAY_ONLY_HEIGHT,
    fields.DISPLAY_ONLY_USER_PROFILE_ASSIGNMENT_LABEL,
    fields.DISPLAY_ONLY_USER_PROFILE_ATTACHMENT_LABEL,
    fields.USER_PROFILE_DEX_ORI_ALIAS,
];

export const departmentProfileFormFieldList = [
    'timeZone',
    'displayName',
    'city',
    'complianceGroup',
    'departmentStatus',
    'departmentId',
    'nibrsRegionalGroup',
    'nibrsSubmissionEndpoint',
    'dexRegion',
    'countryCode',
    'centerLat',
    'centerLng',
    'radiusMeters',
    'southwestLat',
    'southwestLng',
    'northeastLat',
    'northeastLng',
    'departmentProfileLogoId',
    'removedDepartmentProfileLogoId',
    'phoneNumber',
    'website',
    'departmentAddress',
    'stateAttrId',
    'statePlaneZone',
    'searchResyncsEnabled',
    'ageOfAdult',
    'personsSearchResultsDefault',
    'externalCustomerId',
];

export const departmentProfileFormFieldNames = {
    timeZone: fields.DEPARTMENT_PROFILE_TIME_ZONE,
    displayName: fields.DEPARTMENT_PROFILE_DISPLAY_NAME,
    city: fields.DEPARTMENT_PROFILE_CITY,
    complianceGroup: fields.DEPARTMENT_PROFILE_COMPLIANCE_GROUP,
    nibrsRegionalGroup: fields.DEPARTMENT_PROFILE_NIBRS_REGIONAL_GROUP,
    nibrsSubmissionEndpoint: fields.DEPARTMENT_PROFILE_NIBRS_SUBMISSION_ENDPOINT,
    dexRegion: fields.DEPARTMENT_PROFILE_DEX_REGION,
    countryCode: fields.LOCATION_BIAS_COUNTRY_CODE,
    centerLat: fields.LOCATION_BIAS_CENTER_LAT,
    centerLng: fields.LOCATION_BIAS_CENTER_LNG,
    radiusMeters: fields.LOCATION_BIAS_RADIUS_METERS,
    southwestLat: fields.LOCATION_BIAS_SOUTHWEST_LAT,
    southwestLng: fields.LOCATION_BIAS_SOUTHWEST_LNG,
    northeastLat: fields.LOCATION_BIAS_NORTHEAST_LAT,
    northeastLng: fields.LOCATION_BIAS_NORTHEAST_LNG,
    ageOfAdult: fields.DEPARTMENT_PROFILE_AGE_OF_ADULT,
    personsSearchResultsDefault: fields.DEPARTMENT_PROFILE_PERSONS_SEARCH_RESULTS_DEFAULT,
    externalCustomerId: fields.DEPARTMENT_PROFILE_EXTERNAL_CUSTOMER_ID,
};

export const shapefilesAdminFormFields = ['id', 'departmentId', 'displayName', 'geojsonFileId'];

export const customReportsAdminFormFields = [
    'isRestricted',
    'isEnabled',
    'isActive',
    'isGenerateRandomSequenceEnabled',
    'name',
    'nameAbbreviation',
    'hasReportCaseStatus',
    'hasOffenseCaseStatus',
    'allowMultiple',
    'reportTitleFormat',
    'historyVisibilityLevel',
    'minRequiredApprovalLevel',
    'terminalApprovalStatus',
    'defaultRoleLinks[].roleId',
    'defaultRoleLinks[].operationType',
    'reportCreationRolePermissions',
    'defaultExternalDepartmentRoleLinks[].roleId',
    'defaultExternalDepartmentRoleLinks[].operationType',
    'idFormatConfigurationId',
    'suffixType',
    'renConfiguration',
    'recordSequenceType',
    'lockReportOnApproval',
    'sequenceMaxLength',
    'formatDateString',
    'textBeforeDate',
    'textBeforeSequence',
    'showDatePrefix',
    'showCharacterPrefix',
    'reportApprovalLevel',
    'informationCardId',
    'downloadablesCardName',
    'restrictViewReportOwners',
];

export const customReportsAdminDownloadablesFormFields = ['displayName'];

export const customReportsAdminCardLinksFormFields = ['isHidden', 'title'];

export const attributesAdminColumnWidths = ['29%', '29%', '42%'];
export const departmentIPsAdminColumnWidths = ['33%', '67%'];
export const locationAliasesAdminColumnWidths = ['33%', '67%'];
export const caseDefinitionsAdminColumnWidths = ['33%', '67%'];
export const masterEntityAdminColumnWidths = ['33%', '67%'];
export const cadUnitsAdminColumnWidths = ['20%', '20%', '60%'];
export const cadDesktopConfigsAdminColumnWidths = ['33%', '67%'];
export const cadMessagingGroupsAdminColumnWidths = ['33%', '67%'];
export const agencyProfileAdminColumnWidths = ['33%', '67%'];
export const societyProfileAdminColumnWidths = ['33%', '67%'];
export const consortiumAdminColumnWidths = ['33%', '67%'];
export const shapefilesAdminColumnWidths = ['33%', '67%'];
export const customReportsAdminColumnWidths = ['33%', '67%'];
export const dispatchAreasAdminColumnWidths = ['30%', '70%'];
export const callForServiceAdminColumnWidths = ['33%', '67%'];
export const cadUnitTimersAdminColumnWidths = ['33%', '67%'];
export const cadUnitStatusTransitionsAdminColumnWidths = ['33%', '67%'];
export const cadMessagingAuditConfigsAdminColumnWidths = ['100%'];
export const cadServiceRotationAuditConfigsAdminColumnWidths = ['100%'];
export const cadServiceTypesAdminColumnWidths = ['33%', '67%'];
export const cadServiceProvidersAdminColumnWidths = ['29%', '29%', '42%'];
export const cadCallTakerStationsAdminColumnWidths = ['33%', '67%'];
export const cadBoloAuditConfigsAdminColumnWidths = ['100%'];
