import React, { FC, useState } from 'react';
import { Text } from '@arc/typography';
import { useSelector } from 'react-redux';
import * as fields from '~/client-common/core/enums/universal/fields';
import useFields from '~/client-common/core/fields/hooks/useFields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { reportShortTitleViewModelsSelector } from '~/client-common/core/domain/report-short-titles/state/ui';
import { reportIdsForCaseIdSelector } from '~/client-common/core/domain/case-links/state/data';
import { LinkedReportItem } from '../../../records/core/components/sidebar/LinkedReportItem';

const strings = componentStrings.cases.core.ReasonForRelationForm;

/**
 * This component displays information at the top of each row in the NItems of the Reason for Relation form. It
 * represents either a REN or a record number. It lists all the reports with that same REN or record number. This
 * component does not have any form inputs.
 */
const ReasonForRelationReportNItem: FC<{
    caseId: number;
    isRen: boolean;
    showReportDetails: boolean;
    selectedReportIds: number[];
    displayReportIds: number[];
    onChange: (reportIds: number[]) => void;
}> = ({ caseId, isRen, showReportDetails, selectedReportIds, displayReportIds, onChange }) => {
    const [initialSelectedReportIds] = useState(selectedReportIds);
    const reportShortTitleViewModels = useSelector(reportShortTitleViewModelsSelector);
    const caseReportIds = useSelector(reportIdsForCaseIdSelector)(caseId);

    const fieldDisplayNames = useFields([
        fields.REPORT_REPORTING_EVENT_NUMBER,
        fields.REPORT_RECORD_NUMBER,
    ]);

    const reportShortTitles = displayReportIds.map((id) => reportShortTitleViewModels[id]);

    if (!reportShortTitles.length) {
        return null;
    }

    const groupTitle = isRen
        ? strings.ren(
              fieldDisplayNames.REPORT_REPORTING_EVENT_NUMBER,
              reportShortTitles[0].reportingEventNumber || ''
          )
        : strings.recordWithoutRen(
              fieldDisplayNames.REPORT_RECORD_NUMBER,
              reportShortTitles[0].recordNumber
          );

    const selectedReports = reportShortTitles.filter(({ reportId }) =>
        initialSelectedReportIds.includes(reportId)
    );
    const unselectedReports = reportShortTitles.filter(
        ({ reportId }) => !initialSelectedReportIds.includes(reportId)
    );

    return (
        <div>
            <Text variant="headingXs">{groupTitle}</Text>
            {selectedReports.map((reportShortTitle) => (
                <LinkedReportItem
                    key={reportShortTitle.reportId}
                    reportShortTitle={reportShortTitle}
                    showReportDetails={showReportDetails}
                />
            ))}
            {unselectedReports.length !== 0 && (
                <div>
                    <Text variant="caption">
                        {strings.includeOtherReportsFromRen(
                            fieldDisplayNames.REPORT_REPORTING_EVENT_NUMBER
                        )}
                    </Text>
                    {unselectedReports.map((reportShortTitle) => (
                        <LinkedReportItem
                            key={reportShortTitle.reportId}
                            reportShortTitle={reportShortTitle}
                            showReportDetails={showReportDetails}
                            selectedReportIds={selectedReportIds}
                            caseReportIds={caseReportIds}
                            onChange={onChange}
                            selectable
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default ReasonForRelationReportNItem;
