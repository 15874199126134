import { useCallback, useMemo } from 'react';
import { isArray, keyBy } from 'lodash';
import { SqlSort } from '@mark43/rms-api';
import { DataTableConfig, DataTableProps } from '@arc/data-table/dist/types';
import { SortingState, Updater } from '@tanstack/table-core';
import sqlSortKeyEnum from '~/client-common/core/enums/universal/sqlSortKeyEnum';
import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';
import createDataTableSortColumns from '../../../../../search/core/utils/createDataTableSortColumns';
import { EFileInvolvedProfileGridRowT } from '../../../../types';
import { columnKeys } from './useProfilesColumns';

const sorts = [
    {
        id: columnKeys.nameData,
        sortKey: sqlSortKeyEnum.E_FILE_NAME_LINK_NAME,
        sortDirections: {
            asc: sortTypeEnum.ALPHABETICAL_A_TO_Z,
            desc: sortTypeEnum.ALPHABETICAL_Z_TO_A,
        },
    },
];

const columnSorts = createDataTableSortColumns<EFileInvolvedProfileGridRowT>()({
    columns: keyBy(sorts, 'id'),
});

export const useProfilesSort = (
    sort: SqlSort,
    updateSort: (sort: SqlSort) => void
): Pick<DataTableProps<EFileInvolvedProfileGridRowT>, 'onColumnSortChange'> &
    Pick<DataTableConfig, 'sort'> => {
    const columnSort = useMemo(() => {
        const columnSort = columnSorts.getColumnSort([sort]);
        return columnSort ? [columnSort] : undefined;
    }, [sort]);

    const handleColumnSortChange = useCallback(
        (updaterOrValue: Updater<SortingState>) => {
            if (!isArray(updaterOrValue)) {
                return;
            }
            const columnSort = updaterOrValue[0];
            const sqlSort = columnSort ? columnSorts.getSqlSort(columnSort) : null;

            if (sqlSort) {
                updateSort(sqlSort);
            }
        },
        [updateSort]
    );

    return {
        sort: columnSort,
        onColumnSortChange: handleColumnSortChange,
    };
};
