import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { AttributeTypeEnum, ElasticVehicle, ElasticItemAttribute } from '@mark43/rms-api';
import { ItemEvidenceState, ChainOfCustody, ChainEvent } from '@mark43/evidence-api';

import { first } from 'lodash';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { formatTitleForElasticVehicleSelector } from '~/client-common/core/domain/elastic-vehicles/state/ui';
import { departmentNameFromConsortiumLinksByDepartmentIdSelector } from '~/client-common/core/domain/consortium-link-view/state/ui';
import { ViewModel } from '~/client-common/helpers/viewModelHelpers';
import { formatRenSequenceNumberSelector } from '~/client-common/core/domain/item-reporting-event-links/state/ui';
import { chainOfCustodyViewModelsForMasterItemIdSelector } from '~/client-common/core/domain/chain-of-custodies/state/ui';
import { itemEvidenceStateByMasterItemIdSelector } from '~/client-common/core/domain/item-evidence-states/state/data';
import { formatDispositionStatus } from '~/client-common/core/domain/item-evidence-states/utils/formatDisposition';
import { formatElasticStorageLocationByIdSelector } from '~/client-common/core/domain/elastic-storage-locations/state/ui';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { FormattedDate } from '~/client-common/core/dates/components';
import { currentUserDepartmentIdSelector } from '../../../../../modules/core/current-user/state/ui';
import { VehicleEnhancedCautions } from '../../../../../modules/core/cautions/components/VehicleEnhancedCautions';
import { VehicleLabels } from '../../../../../modules/core/components/tags/VehicleLabels';
import Link from '../../../../../modules/core/components/links/Link';
import testIds from '../../../../../core/testIds';

const DepartmentText = styled.span`
    color: ${(props) => props.theme.colors.lightGrey};
`;

const ElasticVehicleInfo = styled.div`
    font-size: var(--arc-fontSizes-sm);
    color: ${(props) => props.theme.colors.darkGrey};
    word-wrap: break-word;
`;

const ElasticVehicleInfoContent = styled.div`
    vertical-align: top;
    display: inline-block;
    width: 350px;
`;

const ElasticVehicleModifiedText = styled.span`
    color: ${(props) => props.theme.colors.mediumGrey};
`;

const ElasticVehicleTitle = styled.div`
    font-weight: bold;
`;

type PersonProfileVehicleCellProps = {
    hasReadAccess: boolean;
    elasticVehicle: ViewModel<ElasticVehicle, never>;
};

type ChainOfCustodyViewModel = ViewModel<
    ChainOfCustody & {
        chainOfCustodyId: number;
        custodialReportId: number;
        chainEvents: ChainEvent[];
    },
    never
>;

export const PersonProfileVehicleCell: React.FC<PersonProfileVehicleCellProps> = ({
    hasReadAccess,
    elasticVehicle,
}) => {
    const {
        masterVehicleId,
        departmentId,
        updatedDateUtc,
        itemAttributes,
        isActiveTarget,
        isStolen,
        cautions,
    } = elasticVehicle;

    const formatAttributeById = useSelector(formatAttributeByIdSelector);
    const primaryColorAttrId = formatAttributeById(elasticVehicle.primaryColorAttrId);

    const currentUserDepartmentId = useSelector(currentUserDepartmentIdSelector);
    const vehicleBelongsToUserDepartment = departmentId === currentUserDepartmentId;

    const departmentNameFromConsortiumLinksByDepartmentId: (
        departmentId: number
    ) => string = useSelector(departmentNameFromConsortiumLinksByDepartmentIdSelector);
    const departmentName = !vehicleBelongsToUserDepartment
        ? departmentNameFromConsortiumLinksByDepartmentId(departmentId)
        : '';

    const itemEvidenceStateByMasterItemId = useSelector(itemEvidenceStateByMasterItemIdSelector);
    const itemEvidenceState: ItemEvidenceState | undefined = masterVehicleId
        ? itemEvidenceStateByMasterItemId(masterVehicleId)
        : undefined;
    const formattedDispositionStatus = itemEvidenceState
        ? formatDispositionStatus(itemEvidenceState.dispositionStatus)
        : '';

    // @ts-expect-error client-common to client RND-7529
    const chainOfCustodyViewModelsForMasterItemId: (
        masterVehicleId: number | undefined
    ) => ChainOfCustodyViewModel[] = useSelector(chainOfCustodyViewModelsForMasterItemIdSelector);
    const chainsOfCustody: ChainOfCustodyViewModel[] = chainOfCustodyViewModelsForMasterItemId(
        masterVehicleId
    );
    const chainOfCustody: ChainOfCustodyViewModel | undefined = first(
        chainsOfCustody.filter((c) => c.chainOfCustodyId === itemEvidenceState?.chainOfCustodyId)
    );

    // @ts-expect-error client-common to client RND-7529
    const formatRenSequenceNumber: (
        masterVehicleId: number | undefined,
        custodialReportId: number | undefined
    ) => string = useSelector(formatRenSequenceNumberSelector);
    const itemId: string = formatRenSequenceNumber(
        masterVehicleId,
        chainOfCustody?.custodialReportId
    );

    const formatElasticStorageLocationById: (
        storageLocationId: number | undefined
    ) => string = useSelector(formatElasticStorageLocationByIdSelector);

    const chainEvents: ChainEvent[] | undefined = chainOfCustody?.chainEvents;
    const storageLocation = chainEvents
        ? first(
              chainEvents
                  .filter((e) => {
                      return e.storageLocationId;
                  })
                  .map((e) => {
                      return formatElasticStorageLocationById(e.storageLocationId);
                  })
          )
        : '';

    const formatTitleForElasticVehicle: (vehicle: ElasticVehicle) => string = useSelector(
        formatTitleForElasticVehicleSelector
    );
    const title = (
        <ElasticVehicleTitle>
            <span>{`${formatTitleForElasticVehicle(elasticVehicle)} ${primaryColorAttrId}`}</span>
        </ElasticVehicleTitle>
    );

    const vehicleLabelAttributes: ElasticItemAttribute[] = itemAttributes
        ? itemAttributes.filter((attribute) => {
              return (
                  attribute?.itemAttrDetail?.type ===
                  AttributeTypeEnum.VEHICLE_LABEL_ATTRIBUTES.name
              );
          })
        : [];

    return (
        <ElasticVehicleInfo>
            <ElasticVehicleInfoContent>
                {hasReadAccess ? (
                    <Link
                        to={`/profiles/vehicles/${masterVehicleId}`}
                        className="entity-profile-link"
                    >
                        {title}
                    </Link>
                ) : (
                    <div>{title}</div>
                )}
                {itemId && <div>{itemId}</div>}
                {formattedDispositionStatus && <div>{formattedDispositionStatus}</div>}
                {storageLocation && <div>{storageLocation}</div>}
                <div>
                    <ElasticVehicleModifiedText>
                        {componentStrings.core.personProfileHelpers.modified}:{' '}
                    </ElasticVehicleModifiedText>
                    <span>
                        <FormattedDate
                            date={updatedDateUtc}
                            format={FormattedDate.FORMATS.FORM_DATE}
                        />
                    </span>
                </div>

                <FeatureFlagged
                    flag="RMS_VEHICLE_CAUTIONS_ENHANCEMENTS_ENABLED"
                    fallback={
                        <FeatureFlagged flag="RMS_VEHICLE_CAUTIONS_ENABLED">
                            <VehicleLabels
                                testId={testIds.VEHICLE_LABELS}
                                elasticItemAttributes={vehicleLabelAttributes}
                                isActiveTarget={isActiveTarget}
                                isStolen={isStolen}
                                size="md"
                            />
                        </FeatureFlagged>
                    }
                >
                    <VehicleEnhancedCautions
                        cautions={cautions}
                        isActiveTarget={isActiveTarget}
                        isStolen={isStolen}
                        size="sm"
                    />
                </FeatureFlagged>
                {departmentName && (
                    <div>
                        <DepartmentText>
                            {componentStrings.core.personProfileHelpers.agency}:{' '}
                        </DepartmentText>
                        <span>{departmentName}</span>
                    </div>
                )}
            </ElasticVehicleInfoContent>
        </ElasticVehicleInfo>
    );
};
