import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

export default createResource({
    name: 'Rules Admin Resource',
    methods: {
        createRuleBundle(rule) {
            return req({
                method: 'POST',
                url: 'rules/bundle',
                data: rule,
            });
        },
        deleteRuleById(ruleId) {
            return req({
                method: 'POST',
                url: `rules/delete/${ruleId}`,
            });
        },
        updateRuleBundles(rules) {
            return req({
                method: 'PUT',
                url: 'rules/bundles',
                data: rules,
            });
        },
        getRuleBundleForRuleId(ruleId) {
            return req({
                method: 'GET',
                url: `rules/bundle/${ruleId}`,
            });
        },
    },
});
