import { useCallback, useEffect, useState } from 'react';
import { EFileNameSearchRequest, SearchResultEFileNameSearchView } from '@mark43/rms-api';
import isEqual from 'lodash/isEqual';
import { useResource } from '~/client-common/core/hooks/useResource';
import { useDataTablePagination } from '../../../../../search/core/hooks/useDataTablePagination';
import { EFileSearchRequest } from '../../../../types';
import { useEFileContext } from '../../../../hooks';
import { useProfilesSort } from './useProfilesSort';

export const useProfilesSearch = (
    initialSearchRequest: EFileSearchRequest<EFileNameSearchRequest>,
    eFileId: number
) => {
    const {
        actions: {
            search: { setEFileProfilesSearchRequest },
        },
        getters: {
            search: { getEFileProfiles, searchResult, searchRequest },
        },
        handlers: { getInvolvedProfiles, onFetchError, onFetchSuccess },
    } = useEFileContext();

    const [initialLoading, setInitialLoading] = useState<boolean>(true);

    const getProfiles = useCallback(
        () =>
            searchRequest
                ? getInvolvedProfiles(searchRequest, eFileId)
                : Promise.resolve(undefined),
        [eFileId, getInvolvedProfiles, searchRequest]
    );

    const onGetProfilesSuccess = useCallback(
        (searchResult?: SearchResultEFileNameSearchView) => {
            if (searchResult) {
                onFetchSuccess(searchResult);
                setInitialLoading(false);
            }
        },
        [onFetchSuccess]
    );

    const onGetProfilesFailure = useCallback(() => {
        onFetchError();
        setInitialLoading(false);
    }, [onFetchError]);

    const updateSearchRequest = useCallback(
        (searchRequestPatch) => {
            const nextSearchRequest = {
                ...(searchRequest || {}),
                ...(searchRequestPatch || {}),
            };

            if (!isEqual(searchRequest, nextSearchRequest)) {
                setEFileProfilesSearchRequest(nextSearchRequest);
            }
        },
        [setEFileProfilesSearchRequest, searchRequest]
    );

    const updateSearchRequestSort = useCallback(
        (sort) => {
            updateSearchRequest({ sorts: [sort] });
        },
        [updateSearchRequest]
    );

    const defaultedSearchRequest = searchRequest || initialSearchRequest;
    const { sortKey, sortType } = defaultedSearchRequest.sorts[0];
    const { sort, onColumnSortChange } = useProfilesSort(
        { sortKey, sortType },
        updateSearchRequestSort
    );

    const { from, size } = defaultedSearchRequest;
    const pagination = useDataTablePagination(
        searchResult?.totalCount || 0,
        { from, size },
        updateSearchRequest
    );

    useEffect(() => {
        // set initial search request only once when the state is empty
        if (!searchRequest) {
            updateSearchRequest(initialSearchRequest);
        }
    }, [updateSearchRequest, initialSearchRequest, searchRequest]);

    const { isLoading, errorMessage } = useResource(
        getProfiles,
        onGetProfilesSuccess,
        onGetProfilesFailure
    );

    return {
        results: getEFileProfiles(),
        loading: isLoading,
        initialLoading,
        errorMessage,
        pagination,
        sort,
        onColumnSortChange,
    };
};
