import React, { useCallback, useState } from 'react';

import { ElasticReport, RefContextEnum } from '@mark43/rms-api';
import { SkeletonText, Text } from 'arc';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { get, noop } from 'lodash';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import { useFormGetter } from '../../../core/forms/hooks/useFormGetter';
import { OverlayBaseHelper } from '../../../core/components/OverlayBaseHelper';
import Modal from '../../../core/overlays/components/Modal';
import {
    convertFromFormModelToCaseDefaultsRequest,
    resetReasonForRelationForm,
} from '../../core/state/form/reasonForRelationForm';
import { RmsDispatch } from '../../../../core/typings/redux';
import ReasonForRelationForm from '../../core/components/ReasonForRelationForm';
import CaseTypesSection from '../../core/components/CaseTypesSection';
import {
    caseDefaultsResponseToCaseDetails,
    getCaseDefaults,
    openCreateManageCaseModalForCase,
} from '../../core/state/ui';
import { clearUnassignedReportsSelection } from '../state/ui';

const strings = componentStrings.cases.core.CreateCaseFromMultiReportsModal;

const DescriptionWrapper = styled.div`
    display: flex;
    margin-bottom: 10px;
`;

const message = (caseDisplayName: string, numReports: number) => (
    <DescriptionWrapper>
        <Text fontWeight="bold" fontSize="sm">
            {strings.descriptionBoldStart(caseDisplayName)}
        </Text>
        &nbsp;
        <Text fontSize="sm"> {strings.descriptionEnd(numReports)}</Text>
    </DescriptionWrapper>
);

type CreateCaseFromMultiReportsModalProps = {
    reports: ElasticReport[];
};

type MultiReportsModalFormLocation =
    | typeof overlayIdEnum.CREATE_CASE_FROM_MULTI_REPORTS_MODAL
    | typeof overlayIdEnum.CREATE_CASE_FROM_REPORT_MODAL;

const CreateCaseFromMultiReportsModal = ({
    formLocation,
}: {
    formLocation: MultiReportsModalFormLocation;
}) => {
    const { singularCaseFieldName: caseDisplayName } = useCaseFieldName();

    return (
        <OverlayBaseHelper<CreateCaseFromMultiReportsModalProps> id={formLocation}>
            {(renderProps) => {
                const { reports } = renderProps.overlayBase.overlayState.customProperties;
                return (
                    <Modal
                        id={formLocation}
                        okText={null}
                        onSave={noop}
                        onCancel={() => {
                            resetReasonForRelationForm(
                                RefContextEnum.FORM_REASON_FOR_RELATION_MODAL.name
                            );
                        }}
                        title={strings.title(caseDisplayName)}
                    >
                        <ModalContent
                            caseDisplayName={caseDisplayName}
                            formLocation={formLocation}
                            reports={reports}
                            setError={renderProps.overlayBase.setError}
                            closePanel={renderProps.closePanel}
                            isLoading={renderProps.overlayBase.overlayState.isLoading}
                        />
                    </Modal>
                );
            }}
        </OverlayBaseHelper>
    );
};

type ModalContentProps = {
    caseDisplayName: string;
    formLocation: MultiReportsModalFormLocation;
    reports: ElasticReport[];
    setError: (error: string) => void;
    closePanel: () => void;
    isLoading?: boolean;
};

const ModalContent = ({
    caseDisplayName,
    formLocation,
    reports = [],
    setError,
    closePanel,
    isLoading = false,
}: ModalContentProps) => {
    const dispatch = useDispatch<RmsDispatch>();
    const { getForm } = useFormGetter();
    const [reportIds, setReportIds] = useState(reports.map(({ id }) => id));

    const onCaseTypeClick = useCallback(
        async (caseDefinitionId: number) => {
            const form = getForm(RefContextEnum.FORM_REASON_FOR_RELATION_MODAL.name);
            if (!form) {
                throw new Error('Error finding form');
            }

            try {
                // Check for form errors before getting case defaults
                // and going to the case management modal
                const formData = await form.submit();

                // make the case default request
                const caseDefaultsRequest = convertFromFormModelToCaseDefaultsRequest(
                    formData.form.getState().model,
                    caseDefinitionId
                );

                const caseDefaults = await dispatch(getCaseDefaults(caseDefaultsRequest));
                closePanel();

                dispatch(
                    openCreateManageCaseModalForCase(
                        caseDefaultsResponseToCaseDetails(caseDefaults)
                    )
                );
                dispatch(clearUnassignedReportsSelection());
            } catch (err) {
                if (err instanceof Error) {
                    setError(err.message);
                } else if (err) {
                    setError(get(err, 'validationResult.formErrors'));
                }
            }
        },
        [closePanel, dispatch, getForm, setError]
    );

    const onChangeReportIds = useCallback(() => {
        const form = getForm(RefContextEnum.FORM_REASON_FOR_RELATION_MODAL.name);
        if (form) {
            const { reportIds } = convertFromFormModelToCaseDefaultsRequest(
                form.getState().model,
                -1
            );

            setReportIds(reportIds);
        }
    }, [getForm]);

    if (isLoading) {
        return <SkeletonText noOfLines={3} />;
    }

    return (
        <>
            {message(caseDisplayName, reportIds.length)}
            <ReasonForRelationForm
                context={RefContextEnum.FORM_REASON_FOR_RELATION_MODAL.name}
                formLocation={formLocation}
                onChangeReportIds={onChangeReportIds}
                onRemoveItem={onChangeReportIds}
            />
            <CaseTypesSection onClick={onCaseTypeClick} />
        </>
    );
};

export default CreateCaseFromMultiReportsModal;
