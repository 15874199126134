import _ from 'lodash';
import { RefContextEnum } from '@mark43/rms-api';

import { filterFormData } from '~/client-common/helpers/formHelpers';
import { NEXUS_STATE_PROP as RULES_STATE_PROP } from '~/client-common/core/domain/rules/state/data';
import { NEXUS_STATE_PROP as RULE_CONFIGURATION_CONTEXTS_STATE_PROP } from '~/client-common/core/domain/rule-configuration-contexts/state/data';
import { NEXUS_STATE_PROP as RULE_ACTIONED_FIELDS_STATE_PROP } from '~/client-common/core/domain/rule-actioned-fields/state/data';
import { NEXUS_STATE_PROP as RULE_CONDITIONS_STATE_PROP } from '~/client-common/core/domain/rule-conditions/state/data';
import { NEXUS_STATE_PROP as RULE_CONDITION_ARGS_STATE_PROP } from '~/client-common/core/domain/rule-condition-args/state/data';
import stringsConfig from '~/client-common/core/strings';

import rulesAdminResource from '../../resources/rulesAdminResource';

const strings = stringsConfig.components.admin.rules;

const SAVE_RULE_ADMIN_FORM_START = 'rules/SAVE_RULE_ADMIN_FORM_START';
const SAVE_RULE_ADMIN_FORM_SUCCESS = 'rules/SAVE_RULE_ADMIN_FORM_SUCCESS';
const SAVE_RULE_ADMIN_FORM_FAILURE = 'rules/SAVE_RULE_ADMIN_FORM_FAILURE';
const DELETE_RULE_ADMIN_FORM_START = 'rules/DELETE_RULE_ADMIN_FORM_START';
export const DELETE_RULE_ADMIN_FORM_SUCCESS = 'rules/DELETE_RULE_ADMIN_FORM_SUCCESS';
const DELETE_RULE_ADMIN_FORM_FAILURE = 'rules/DELETE_RULE_ADMIN_FORM_FAILURE';

function saveRulesAdminFormStart() {
    return { type: SAVE_RULE_ADMIN_FORM_START };
}

function saveRulesAdminFormSuccess() {
    return { type: SAVE_RULE_ADMIN_FORM_SUCCESS };
}

export function saveRulesAdminFormFailure(errorMessages) {
    return {
        type: SAVE_RULE_ADMIN_FORM_FAILURE,
        payload: errorMessages,
    };
}

export const deleteRuleAdminFormStart = (ruleId) => ({
    type: DELETE_RULE_ADMIN_FORM_START,
    payload: ruleId,
});

export const deleteRuleAdminFormFailure = (error) => ({
    type: DELETE_RULE_ADMIN_FORM_FAILURE,
    payload: error,
});

export function saveNewRule(rulesAdminFormFieldViewModel, departmentId, router) {
    return (dispatch, getState, dependencies) => {
        dispatch(saveRulesAdminFormStart());
        const bundle = {
            rules: [{ ...rulesAdminFormFieldViewModel.rule, id: -1, departmentId }],
            ruleConfigurationContexts: [
                ...rulesAdminFormFieldViewModel.ruleConfigurationContexts.map((rcc) => ({
                    ...rcc,
                    contextId: RefContextEnum[rcc.context].value,
                })),
            ],
            ruleActionedFields: rulesAdminFormFieldViewModel.ruleActionedFields,
            ruleConditions: rulesAdminFormFieldViewModel.ruleConditions,
            ruleConditionArgs: rulesAdminFormFieldViewModel.ruleConditionArgs,
        };
        const filteredBundle = filterFormData(bundle);

        return rulesAdminResource.createRuleBundle(filteredBundle).then((rulesBundle) => {
            const {
                rules,
                ruleConfigurationContexts,
                ruleActionedFields,
                ruleConditions,
                ruleConditionArgs,
            } = rulesBundle;
            dispatch(
                dependencies.nexus.withEntityItems(
                    {
                        [RULES_STATE_PROP]: rules,
                        [RULE_CONFIGURATION_CONTEXTS_STATE_PROP]: ruleConfigurationContexts,
                        [RULE_ACTIONED_FIELDS_STATE_PROP]: ruleActionedFields,
                        [RULE_CONDITIONS_STATE_PROP]: ruleConditions,
                        [RULE_CONDITION_ARGS_STATE_PROP]: ruleConditionArgs,
                    },
                    saveRulesAdminFormSuccess()
                )
            );
            router.push(`/admin/rules/${rules[0].id}`);
        });
    };
}

export function saveRulesAdminForm(rulesAdminFormFieldViewModel) {
    return (dispatch, getState, dependencies) => {
        dispatch(saveRulesAdminFormStart());
        const bundle = [
            {
                rules: [rulesAdminFormFieldViewModel.rule],
                ruleConfigurationContexts: rulesAdminFormFieldViewModel.ruleConfigurationContexts,
                ruleActionedFields: rulesAdminFormFieldViewModel.ruleActionedFields,
                ruleConditions: rulesAdminFormFieldViewModel.ruleConditions,
            },
        ];
        const filteredBundle = filterFormData(bundle);
        return rulesAdminResource
            .updateRuleBundles(filteredBundle)
            .then((rulesBundle) => {
                const rules = _(rulesBundle).map('rules').flatten().value();
                const ruleConfigurationContexts = _(rulesBundle)
                    .map('ruleConfigurationContexts')
                    .flatten()
                    .value();
                const ruleActionedFields = _(rulesBundle)
                    .map('ruleActionedFields')
                    .flatten()
                    .value();
                const ruleConditions = _(rulesBundle).map('ruleConditions').flatten().value();
                return dispatch(
                    dependencies.nexus.withEntityItems(
                        {
                            [RULES_STATE_PROP]: rules,
                            [RULE_CONFIGURATION_CONTEXTS_STATE_PROP]: ruleConfigurationContexts,
                            [RULE_ACTIONED_FIELDS_STATE_PROP]: ruleActionedFields,
                            [RULE_CONDITIONS_STATE_PROP]: ruleConditions,
                        },
                        saveRulesAdminFormSuccess()
                    )
                );
            })
            .catch(() => dispatch(saveRulesAdminFormFailure([strings.saveError])));
    };
}

export const getRuleBundleForRuleId = (ruleId) => {
    return rulesAdminResource.getRuleBundleForRuleId(ruleId);
};
