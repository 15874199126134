import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { reduce, find, findIndex } from 'lodash';
import {
    EFileNameSearchRequest,
    EFileNameSearchView,
    SearchResultEFileNameSearchView,
} from '@mark43/rms-api';
import { augmentActionWithNexusHydratedOrganizations } from '~/client-common/core/domain/organization-profiles/state/data';
import { augmentActionAndStoreRmsHydratedPersonProfiles } from '~/client-common/core/domain/person-profiles/state/data';
import redirectToErrorPage from '../../core/utils/redirectToErrorPage';

import { EFileHandlersT, EFileReducerType, EFileActionsT, EFileSearchRequest } from '../types';
import { INVOLVED_PROFILES_PAGE_SIZE } from '../constants';
import { eFileResource } from '../resources';
import { groupProfiles } from '../helpers';

/**
 * This hook can only be used within the E-File Module and the eFileContext
 * Using this outside of the module will cause errors
 * @returns EFileHandlersT
 */

export function useEFileHandlers(state: EFileReducerType, actions: EFileActionsT): EFileHandlersT {
    const dispatch = useDispatch();

    const eFileId = state.eFile?.efile.id;
    const searchRequest = state.search.request;

    const getInvolvedProfiles = useCallback(
        async (searchQuery: EFileSearchRequest<EFileNameSearchRequest>, eFileId: number) => {
            const searchResult = await eFileResource.getInvolvedProfiles({
                ...searchQuery,
                from: searchQuery.from || 0,
                size: searchQuery.size || INVOLVED_PROFILES_PAGE_SIZE,
                query: {
                    ...searchQuery.query,
                    efileId: eFileId,
                },
            });

            // store the fetched profiles in redux
            const { personProfiles, organizationProfiles } = groupProfiles(searchResult.items);
            dispatch(
                augmentActionAndStoreRmsHydratedPersonProfiles(
                    { type: 'STORE_E_FILE_PERSON_PROFILES' },
                    personProfiles.map(({ personProfile }) => personProfile)
                )
            );
            dispatch(
                augmentActionWithNexusHydratedOrganizations(
                    { type: 'STORE_E_FILE_ORGANIZATION_PROFILES' },
                    organizationProfiles.map(({ organizationProfile }) => organizationProfile)
                )
            );
            return searchResult;
        },
        [dispatch]
    );

    const onFetchError = useCallback(() => {
        dispatch(redirectToErrorPage());
    }, [dispatch]);

    const onFetchSuccess = useCallback(
        (searchResult?: SearchResultEFileNameSearchView) => {
            if (searchResult) {
                // store the search result in the context
                actions.search.setEFileProfiles(searchResult);
            }

            return searchResult;
        },
        [actions.search]
    );

    const refreshInvolvedProfiles = useCallback(() => {
        if (searchRequest && eFileId) {
            return getInvolvedProfiles(searchRequest, eFileId)
                .then(onFetchSuccess)
                .catch(onFetchError);
        }
        return Promise.resolve(undefined);
    }, [eFileId, getInvolvedProfiles, onFetchError, onFetchSuccess, searchRequest]);

    const updateInvolvedNamesDeactivatedState = (eFileLinkId: number, isDeactivated: boolean) => {
        const profilesSearchResults = state.search.profiles;
        if (!profilesSearchResults) {
            return;
        }
        const profiles = state.search.profiles?.items;

        if (!profiles) {
            return;
        }

        const updatedProfiles = reduce(
            profiles,
            (acc, item) => {
                const entityProfile = item.person ? item.personProfile : item.organizationProfile;
                if (entityProfile) {
                    const { nameEFileLinks } = entityProfile;
                    const nameEFileLink = find(nameEFileLinks, { id: eFileLinkId });

                    if (nameEFileLink) {
                        return [
                            ...acc,
                            {
                                ...item,
                                isDeactivated,
                            },
                        ];
                    }
                }

                return [...acc, item];
            },
            [] as EFileNameSearchView[]
        );
        actions.search.setEFileProfiles({
            ...profilesSearchResults,
            items: updatedProfiles,
        });
    };

    const updateInvolvedProfileDeactivatedState = (eFileLinkId: number, isDeactivated: boolean) => {
        const defendantOrganizationProfile =
            state.selectedEFileEntity.defendantOrganizationProfile?.hydratedOrganization;
        const defendantPersonProfile =
            state.selectedEFileEntity.defendantPersonProfile?.hydratedPerson;

        if (defendantPersonProfile) {
            const index = findIndex(defendantPersonProfile.nameEFileLinks, { id: eFileLinkId });

            if (index !== -1) {
                defendantPersonProfile.nameEFileLinks[index] = {
                    ...defendantPersonProfile.nameEFileLinks[index],
                    isDeactivated,
                };
                actions.selectedEFileEntity.setEFileDefendantPersonProfile({
                    hydratedPerson: defendantPersonProfile,
                });
            }
        }

        if (defendantOrganizationProfile) {
            const index = findIndex(defendantOrganizationProfile.nameEFileLinks, {
                id: eFileLinkId,
            });

            if (index !== -1) {
                defendantOrganizationProfile.nameEFileLinks[index] = {
                    ...defendantOrganizationProfile.nameEFileLinks[index],
                    isDeactivated,
                };
                actions.selectedEFileEntity.setEFileDefendantOrganizationProfile({
                    hydratedOrganization: defendantOrganizationProfile,
                });
            }
        }
    };

    const removeInvolvedNamesFromState = (eFileLinkId: number) => {
        const profilesSearchResults = state.search.profiles;
        if (!profilesSearchResults) {
            return;
        }
        const profiles = state.search.profiles?.items;

        if (!profiles) {
            return;
        }

        const updatedProfiles = reduce(
            profiles,
            (acc, item) => {
                const entityProfile = item.person ? item.personProfile : item.organizationProfile;
                if (entityProfile) {
                    const { nameEFileLinks } = entityProfile;
                    const nameEFileLink = find(nameEFileLinks, { id: eFileLinkId });

                    if (!nameEFileLink) {
                        acc = [...acc, item];
                    }
                }

                return acc;
            },
            [] as EFileNameSearchView[]
        );
        actions.search.setEFileProfiles({
            ...profilesSearchResults,
            totalCount: profilesSearchResults.totalCount - 1,
            items: updatedProfiles,
        });
    };

    return {
        getInvolvedProfiles,
        refreshInvolvedProfiles,
        onFetchError,
        onFetchSuccess,
        updateInvolvedNamesDeactivatedState,
        updateInvolvedProfileDeactivatedState,
        removeInvolvedNamesFromState,
    };
}
