import React, { FC, useCallback } from 'react';
import { DataTable, DataTableProps } from '@arc/data-table';
import { Tr } from '@arc/table';
import { Box } from '@arc/layout';
import { cssVar } from 'arc';
import styled, { css } from 'styled-components';

import testIds from '../../../core/testIds';
import NoDataBlock from './NoDataBlock';
import { SkeletonTable } from './Skeleton';

const StyledDataTable = styled(DataTable)`
    width: 100%;
    height: 100%;

    & th {
        font-size: ${cssVar('arc.fontSizes.sm')};
    }

    /* fix spacing for the container of sort arrow icon */
    & th > div > div > div {
        display: flex;
        align-items: center;
        gap: ${cssVar('arc.space.1')};
    }
`;

export const DataTableRow = styled(Tr)`
    & td {
        border: none;
    }

    ${(props) =>
        !props.hideBorder &&
        css`
            & {
                border-bottom: 1px solid ${cssVar('arc.colors.border.default')};
            }
        `}
`;

type DataTableWrapperProps<T extends unknown> = DataTableProps<T> & {
    noDataText: string;
    initialLoading: boolean;
    loading: boolean;
    rowTestId?: keyof typeof testIds;
    className?: string;
};

export const DataTableWrapper = <T extends unknown>({
    noDataText,
    initialLoading,
    loading,
    rowTestId,
    data = [],
    ...other
}: DataTableWrapperProps<T>) => {
    const renderRow = useCallback(
        ({ row, renderedCells }) => (
            <DataTableRow key={row.id} data-test-id={rowTestId}>
                {renderedCells}
            </DataTableRow>
        ),
        [rowTestId]
    );

    if (initialLoading) {
        return <SkeletonTable width="100%" />;
    }

    if (!loading && !data.length) {
        return (
            <Box textAlign="center">
                <NoDataBlock>{noDataText}</NoDataBlock>
            </Box>
        );
    }

    return <StyledDataTable<FC<DataTableProps<T>>> data={data} renderRow={renderRow} {...other} />;
};
