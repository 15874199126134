import { EFileNameSearchView, EFileTitle, LocationBundle } from '@mark43/rms-api';
import { filter, orderBy, sortBy, compact, map, find } from 'lodash';
import sortRelatedRecordsEnum from '~/client-common/core/enums/client/reportSidebarSortRelatedRecordsEnum';
import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';
import { formatLocationLinkTypeId } from '~/client-common/helpers/linkTypesHelpers';
import { EFileNameSearchViewOrganizationT, EFileNameSearchViewPersonT } from '../types';

function isPersonProfile(item: EFileNameSearchView): item is EFileNameSearchViewPersonT {
    return item.person;
}

function isOrganizationProfile(
    item: EFileNameSearchView
): item is EFileNameSearchViewOrganizationT {
    return !item.person;
}

export function groupProfiles(items: EFileNameSearchView[]) {
    return {
        personProfiles: filter(items, isPersonProfile),
        organizationProfiles: filter(items, isOrganizationProfile),
    };
}

export const sortEFileTitles = ({
    eFileTitles,
    activeValue,
    sortType,
}: {
    eFileTitles: EFileTitle[];
    activeValue?: keyof typeof sortRelatedRecordsEnum;
    sortType?: keyof typeof sortTypeEnum;
}): EFileTitle[] => {
    return !!sortType && !!activeValue
        ? ((sortType, activeValue) => {
              if (sortType === sortTypeEnum.ALPHABETICAL_A_TO_Z) {
                  return orderBy(eFileTitles, ['title'], ['asc']);
              } else if (sortType === sortTypeEnum.ALPHABETICAL_Z_TO_A) {
                  return orderBy(eFileTitles, ['title'], ['desc']);
              } else if (
                  activeValue === sortRelatedRecordsEnum.CREATED_DATE &&
                  sortType === sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT
              ) {
                  return orderBy(eFileTitles, ['createdDateUtc'], ['desc']);
              } else if (
                  activeValue === sortRelatedRecordsEnum.CREATED_DATE &&
                  sortType === sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT
              ) {
                  return orderBy(eFileTitles, ['createdDateUtc'], ['asc']);
              } else if (
                  activeValue === sortRelatedRecordsEnum.MODIFIED_DATE &&
                  sortType === sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT
              ) {
                  return orderBy(eFileTitles, ['updatedDateUtc'], ['desc']);
              } else if (
                  activeValue === sortRelatedRecordsEnum.MODIFIED_DATE &&
                  sortType === sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT
              ) {
                  return orderBy(eFileTitles, ['updatedDateUtc'], ['asc']);
              } else {
                  return sortBy(eFileTitles, ['title']);
              }
          })(sortType, activeValue)
        : sortBy(eFileTitles, ['title']);
};

export const formatEFileInvolvedProfileAddresses = (locations: LocationBundle[]) => {
    return compact(
        map(locations, (location) => {
            const { location: innerLocation } = location;
            const entityLink = find(
                location.entityLinks,
                (link) => link.locationId === innerLocation.id
            );
            if (entityLink) {
                const locationType = formatLocationLinkTypeId(entityLink.linkType);

                return {
                    label: locationType,
                    value: innerLocation.fullAddress,
                };
            }
            return undefined;
        })
    );
};
