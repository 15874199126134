import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sumBy, some, map, uniqBy, flatMap, reduce, uniq, filter, includes, isEmpty } from 'lodash';
import styled, { css } from 'styled-components';
import { cssVar, Text as ArcText, Icon, HStack } from 'arc';
import { change } from 'redux-form-mark43';
import { prettify } from '~/client-common/helpers/stringHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { attachmentFolderPrintablesSelector } from '~/client-common/core/domain/attachment-folder-printables/state/data';
import _NoticeBar from '../../../modules/core/components/NoticeBar';
import { ExportAttachmentsSidePanel } from '../../../modules/exports/core/components/ExportAttachmentsSidePanel';
import { exportAttachmentsSelector } from '../../../modules/exports/core/state/data';
import { groupAttachmentsByEntityIdSelector } from '../../../modules/exports/core/state/ui';
import { groupAttachmentsByEntityIdWithFolders } from '../../../modules/exports/core/utils/groupAttachmentsByEntityId';
import Radio from '../../../modules/core/forms/components/Radio';
import { Button } from '../../../modules/core/components/Button';
import testIds from '../../../core/testIds';
import { computeTotalFileSize, formatFileSize } from '../../../modules/attachments/files/helpers';
import HelpText from '../core/HelpText';
import { computeSelectedAttachments } from '../../../modules/exports/core/utils/computeSelectedAttachments';
import getCaseIdsInPackets from '../../../modules/exports/core/utils/getCaseIdsInPackets';
import { getTopLevelFoldersForOwner } from '../../../modules/cases/core/state/data/folders';
import { FormOptionWrapper, ExportOption } from './FormOptionComponents';

const strings = componentStrings.exports.FormExportsOptions;
const optionStrings = componentStrings.exports.options;
const MAX_MERGEABLE_ATTACHMENT_SIZE = 1000 * 1024 * 1024;

export const attachmentRadioStyles = css`
    margin-top: 10px;
    margin-left: 22px;
    margin-bottom: 3px;

    .radio-option.newline {
        margin-bottom: 10px;
        margin-right: 0;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .radio-label {
        float: none;
        overflow: hidden;
    }

    input {
        margin-bottom: 20px;
    }
`;

const AttachmentRadio = styled(Radio)`
    ${attachmentRadioStyles};
`;

const AttachmentInfoText = styled(ArcText)`
    font-style: italic;
    font-size: ${cssVar('arc.fontSizes.xs')};
    margin-left: ${(props) => props.marginLeft || 0}px;
    margin-top: ${(props) => props.marginTop || 0}px;
    margin-bottom: ${(props) => props.marginBottom || 0}px;
`;

const NoticeBar = styled(_NoticeBar)`
    margin: 10px 0 4px 32px;
`;

const AttachmentInfoRow = styled.div`
    display: flex;
    align-items: center;
    margin-left: 33px;
`;

/**
 * Computes the total selected attachment count based on selected attachment ids and
 * total attachments for selected packets.
 *
 * @param {object[]} packets             The selected packets for which to compute the selected attachment count
 * @param {object} [selectedAttachments] An object with an array of attachment ids, indexed by packet id
 */
export function computeSelectedAttachmentCount(
    packets,
    selectedAttachments = {},
    attachmentsWhere,
    acceptedFileTypes
) {
    const { ids, countWithoutIds } = reduce(
        packets,
        (acc, packet) => {
            const { entityId: parentEntityId, childAttachments } = packet;
            const childrenEntityIds = uniq(map(childAttachments, 'id'));

            const entityIds = [parentEntityId, ...childrenEntityIds];

            const selected = reduce(
                entityIds,
                (accumulator, entityId) => {
                    const children = selectedAttachments[entityId];
                    if (children) {
                        if (!accumulator) {
                            accumulator = [];
                        }
                        accumulator = [...accumulator, ...children];
                    }
                    return accumulator;
                },
                // This is undefined instead of an empty array, so that we can default to the max attachment count
                undefined
            );

            if (selected) {
                return {
                    ...acc,
                    ids: uniq([...acc.ids, ...selected]),
                };
            } else {
                return {
                    ...acc,
                    countWithoutIds: acc.countWithoutIds + packet.attachmentCount,
                };
            }
        },
        {
            ids: [],
            countWithoutIds: 0,
        }
    );

    if (acceptedFileTypes) {
        const mergeableAttachments = filter(ids, (id) =>
            includes(
                acceptedFileTypes,
                attachmentsWhere({ attachmentId: id })[0]?.image?.originalFile?.fileType
            )
        );
        return uniq(mergeableAttachments).length + countWithoutIds;
    }

    return uniq(ids).length + countWithoutIds;
}

// if the selected attachments exceed this number of bytes, then we show a warning message to the user
const EXPORT_ATTACHMENTS_WARNING_SIZE = 3 * 1024 * 1024 * 1024;

export function computeSelectedAttachmentsSize(
    packets,
    selectedAttachments = {},
    allAttachments = {}
) {
    return sumBy(packets, (packet) => {
        const { entityId: parentEntityId, childAttachments } = packet;
        const childrenEntityIds = uniq(map(childAttachments, 'id'));
        const entityIds = [parentEntityId, ...childrenEntityIds];
        const sum = sumBy(entityIds, (entityId) =>
            computeTotalFileSize(
                selectedAttachments[entityId]
                    ? filter(allAttachments[entityId], (attachment) =>
                          includes(selectedAttachments[entityId], attachment.attachmentId)
                      )
                    : allAttachments[entityId]
            )
        );

        return sum;
    });
}

function computeSelectedMergeableAttachmentsSize(
    packets,
    selectedAttachments = {},
    allAttachments = {}
) {
    return sumBy(packets, (packet) => {
        const { entityId: parentEntityId, childAttachments } = packet;
        const childrenEntityIds = uniq(map(childAttachments, 'id'));
        const entityIds = [parentEntityId, ...childrenEntityIds];
        const filteredSum = sumBy(entityIds, (entityId) =>
            computeTotalFileSize(
                selectedAttachments[entityId]
                    ? filter(allAttachments[entityId], (attachment) =>
                          includes(selectedAttachments[entityId], attachment.attachmentId)
                      )
                    : allAttachments[entityId],
                ['PDF', 'PNG', 'JPG', 'JPEG']
            )
        );
        return filteredSum;
    });
}

function computeTotalExportableAttachments(allAttachments = {}) {
    const totalAttachmentsLength = Object.keys(allAttachments).reduce((total, key) => {
        return (total += allAttachments[key].length);
    }, 0);
    return isEmpty(allAttachments) ? 0 : totalAttachmentsLength;
}

export const AttachmentInfo = (props) => {
    return (
        <>
            <AttachmentInfoText {...props}>{optionStrings.supportedFileTypes}</AttachmentInfoText>
            {props.size > props.maxSize && (
                <HStack spacing="5px" marginLeft={props.marginLeft} alignItems="normal">
                    <Icon icon="Alert" size="sm" color="negative.default" />
                    <AttachmentInfoText color="negative">
                        {strings.selectedMergeableAttachmentsTooLarge(
                            formatFileSize(props.size),
                            formatFileSize(props.maxSize)
                        )}
                    </AttachmentInfoText>
                </HStack>
            )}
        </>
    );
};

const AttachmentOption = ({
    includeAttachments,
    packetsSelected,
    packetEntities,
    includeFiles,
    excludeAttachmentLinkTypes,
    selectedAttachmentsToInclude,
    mergeAttachments,
    clearExportPreset,
    loadAttachments,
    selectedPacketValues,
}) => {
    const dispatch = useDispatch();
    const applicationSettings = useSelector(applicationSettingsSelector);
    const exportAttachments = useSelector(exportAttachmentsSelector);
    const groupAttachmentsByEntityId = useSelector(groupAttachmentsByEntityIdSelector);
    const attachmentFolderPrintables = useSelector(attachmentFolderPrintablesSelector);
    const uniqueSelectedPacketEntities = uniqBy(packetEntities, 'entityId');
    const formattedChildAttachmentEntities = flatMap(uniqueSelectedPacketEntities, (entity) =>
        map(entity.childAttachments, (child) => ({
            entityId: child.id,
            entityType: child.type,
            display: `${entity.display} ${prettify(child.type)}`,
        }))
    );
    const entities = React.useMemo(
        () => [...uniqueSelectedPacketEntities, ...formattedChildAttachmentEntities],
        [uniqueSelectedPacketEntities, formattedChildAttachmentEntities]
    );

    const groupedAttachmentsById = groupAttachmentsByEntityId({
        attachments: exportAttachments(excludeAttachmentLinkTypes),
        entities: packetEntities,
    });

    const attachmentsSize = computeSelectedAttachmentsSize(
        uniqueSelectedPacketEntities,
        selectedAttachmentsToInclude.value,
        groupedAttachmentsById
    );
    const mergeableAttachmentsSize = computeSelectedMergeableAttachmentsSize(
        uniqueSelectedPacketEntities,
        selectedAttachmentsToInclude.value,
        groupedAttachmentsById
    );

    const isMergeableAttachmentSizeTooLarge =
        mergeableAttachmentsSize > MAX_MERGEABLE_ATTACHMENT_SIZE;

    useEffect(() => {
        // when "mergeSelectedAttachments" radio button becomes disabled, automatically select the other option
        dispatch(change('exports', 'mergeAttachments', false));
    }, [isMergeableAttachmentSizeTooLarge, dispatch]);

    const onChange = React.useCallback(
        (value) => {
            // when the checkbox is checked, load attachments which are needed to determine whether the size warning should be displayed
            // we load attachments from all packets rather only the selected packets, because the user may select other packets afterwards
            if (value) {
                const change = (attachmentsByEntity) => {
                    const selectedEntities = packetEntities.filter(pe => selectedPacketValues.includes(pe.value)).map(pe => pe.entityId)
                    const computedAttachments = computeSelectedAttachments({
                        attachmentsByEntity,
                        initialSelectedAttachments: selectedAttachmentsToInclude.value,
                        selectedEntities,
                    });
                    selectedAttachmentsToInclude.onChange(computedAttachments);
                };

                /**
                 * Tech debt: any new attachments loaded by `loadAttachments()` (new ones which are not previously
                 * stored in Redux state) are not actually handled by the resolve callback (inside `.then()`). In other
                 * words, Redux state is not updated with these new attachments when `exportAttachments()` is called.
                 */
                loadAttachments().then(() => {
                    const caseIds = getCaseIdsInPackets(entities);
                    if (
                        applicationSettings.RMS_CASE_FOLDERING_ENABLED &&
                        attachmentFolderPrintables.length > 0 &&
                        caseIds.length > 0
                    ) {
                        /**
                         * When a case has attachment folders, load the folders in order to
                         * 1. adjust the total attachment count, and
                         * 2. show the folder info when the user opens the attachments side panel
                         * This logic doesn't belong here (and wouldn't belong in `loadAttachmentsByEntityIdsAndTypes`
                         * either), it would take some refactoring to move it into CaseExports.
                         */
                        dispatch(getTopLevelFoldersForOwner(caseIds[0])).then((folders) => {
                            const attachmentsByEntity = groupAttachmentsByEntityIdWithFolders({
                                entities,
                                attachments: exportAttachments(excludeAttachmentLinkTypes),
                                attachmentFolderPrintables,
                                folders,
                            });
                            change(attachmentsByEntity);
                        });
                    } else {
                        const attachmentsByEntity = groupAttachmentsByEntityId({
                            entities,
                            attachments: exportAttachments(excludeAttachmentLinkTypes),
                        });
                        change(attachmentsByEntity);
                    }
                });
            }
        },
        [
            loadAttachments,
            groupAttachmentsByEntityId,
            entities,
            exportAttachments,
            excludeAttachmentLinkTypes,
            selectedAttachmentsToInclude,
            attachmentFolderPrintables,
            applicationSettings,
            packetEntities,
            selectedPacketValues,
            dispatch,
        ]
    );

    if (!includeAttachments) {
        return null;
    }

    const showAttachmentOption =
        includeAttachments &&
        packetsSelected &&
        some(
            packetEntities,
            (packet) => packet.attachmentCount > 0 || packet.mugshotAttachmentCount > 0
        );

    const totalExportableAttachmentsCount = computeTotalExportableAttachments(
        groupAttachmentsByEntityId({
            attachments: exportAttachments(excludeAttachmentLinkTypes),
            entities,
        })
    );

    const selectedAttachmentText = strings.selectedAttachments(
        computeSelectedAttachmentCount(
            uniqueSelectedPacketEntities,
            selectedAttachmentsToInclude.value
        ),
        totalExportableAttachmentsCount,
        attachmentsSize > 0 ? formatFileSize(attachmentsSize) : undefined
    );

    const mugshotAttachmentCount = sumBy(uniqueSelectedPacketEntities, 'mugshotAttachmentCount');

    const attachmentRadioOptions = [
        { label: optionStrings.includeSelectedInZip, value: false },
        {
            label: optionStrings.mergeSelectedAttachments,
            value: true,
            isOptionDisabled: isMergeableAttachmentSizeTooLarge,
        },
    ];

    const attachmentCheckbox = (
        <ExportOption
            title={optionStrings.includeAttachmentFiles}
            field={includeFiles}
            noMargin={true}
            disabled={!showAttachmentOption}
            helpText={!showAttachmentOption ? optionStrings.attachmentTooltip : undefined}
            clearExportPreset={clearExportPreset}
            onChange={onChange}
        />
    );
    return (
        <FormOptionWrapper>
            {attachmentCheckbox}
            {!!includeFiles.value && showAttachmentOption && (
                <>
                    <ExportAttachmentsSidePanel
                        id={overlayIdEnum.EXPORTS_ATTACHMENT_SIDEPANEL}
                        entities={entities}
                        excludeAttachmentLinkTypes={excludeAttachmentLinkTypes}
                        onPanelSave={(selectedAttachments) =>
                            selectedAttachmentsToInclude.onChange(selectedAttachments)
                        }
                        initialSelectedAttachments={selectedAttachmentsToInclude.value}
                        renderButton={({ overlayBase: { open }, setCloseFocusRefs }) => (
                            <AttachmentInfoRow data-test-id={testIds.EXPORTS_ATTACHMENTS_INFO_ROW}>
                                <ArcText variant="bodyMd" color="info">
                                    {selectedAttachmentText}
                                </ArcText>
                                <Button
                                    variant="ghost"
                                    onClick={open}
                                    ref={setCloseFocusRefs}
                                    testId={testIds.EXPORTS_ATTACHMENTS_SIDE_PANEL_OPEN_BUTTON}
                                    isTextTransformNone={true}
                                >
                                    {strings.viewAndEditAttachmentSelection}
                                </Button>
                            </AttachmentInfoRow>
                        )}
                    />
                    {mugshotAttachmentCount > 0 && (
                        <AttachmentInfoText
                            marginLeft={32}
                            marginTop={2}
                            marginBottom={10}
                            color="mediumGrey"
                        >
                            {strings.includedMugshotAttachments}{' '}
                            <HelpText content={strings.mugshotAttachmentsTooltip} />
                        </AttachmentInfoText>
                    )}
                    {attachmentsSize > EXPORT_ATTACHMENTS_WARNING_SIZE && (
                        <NoticeBar>{strings.selectedAttachmentsSizeWarning}</NoticeBar>
                    )}
                    {
                        <>
                            <AttachmentRadio
                                {...mergeAttachments}
                                options={attachmentRadioOptions}
                                newlines={true}
                                orientation="column"
                                onChange={(...args) => {
                                    clearExportPreset();
                                    return mergeAttachments.onChange(...args);
                                }}
                            />
                            <AttachmentInfo
                                marginLeft={50}
                                size={mergeableAttachmentsSize}
                                maxSize={MAX_MERGEABLE_ATTACHMENT_SIZE}
                            />
                        </>
                    }
                </>
            )}
        </FormOptionWrapper>
    );
};

export default AttachmentOption;
