import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ElasticStaffRemark, StaffRemark as StaffRemarkType } from '@mark43/evidence-api';
import { deleteStaffRemark } from '~/client-common/core/domain/staff-remarks/state/data';
import { FormattedDate } from '~/client-common/core/dates/components';
import {
    ConnectedFormattedUser,
    FORMATS as USER_FORMATS,
} from '~/client-common/core/users/components';

import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import Icon, { iconTypes } from '../../../core/components/Icon';
import testIds from '../../../../core/testIds';
import { currentUserHasAbilitySelector } from '../../../core/current-user/state/ui';

const IconContainer = styled.div`
    width: 20px;
`;

const ContentContainer = styled.div`
    flex: 1;
`;

const TrashContainer = styled.div`
    width: 20px;
`;

const SemiBold = styled.span`
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

type StaffRemarkProps = {
    staffRemark: StaffRemarkType | ElasticStaffRemark;
    deletable: boolean;
    className?: string;
};

const _StaffRemark = ({ staffRemark, deletable, className }: StaffRemarkProps) => {
    const dispatch = useDispatch();
    const currentUserHasEditStaffRemarksAbility = useSelector(currentUserHasAbilitySelector)(
        abilitiesEnum.EVIDENCE.EDIT_STAFF_REMARKS
    );
    const handleDelete = () => {
        dispatch(deleteStaffRemark(staffRemark.id));
    };

    return (
        <div className={className} data-test-id={testIds.STAFF_REMARK_CONTAINER}>
            <IconContainer>
                <Icon size={12} type={iconTypes.NOTE} />
            </IconContainer>
            <ContentContainer>
                <SemiBold>
                    <FormattedDate
                        date={staffRemark.remarkDateUtc}
                        format={FormattedDate.FORMATS.FORM_DATE_TIME}
                    />
                </SemiBold>
                {' by '}
                <SemiBold>
                    <ConnectedFormattedUser
                        userId={staffRemark.remarkUserId}
                        format={USER_FORMATS.FULL_NAME_WITH_FIRST_INITIAL}
                    />
                </SemiBold>
                <div>{staffRemark.remark}</div>
            </ContentContainer>
            <TrashContainer>
                {deletable && currentUserHasEditStaffRemarksAbility && (
                    <Button
                        className={buttonTypes.ICON_LINK}
                        iconLeft={iconTypes.TRASH_CAN}
                        onClick={handleDelete}
                        testId={testIds.TRASH_BUTTON}
                    />
                )}
            </TrashContainer>
        </div>
    );
};

export const StaffRemark = styled(_StaffRemark)`
    display: flex;
    margin-bottom: 16px;

    &:last-child {
        margin-bottom: 0;
    }

    .mark43-icon::before {
        margin-top: -3px;
    }
`;
