import sortKeyEnum from '~/client-common/core/enums/universal/sortKeyEnum';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { ReasonForRelationFormLocationType } from '../components/ReasonForRelationForm';

/**
 * Default reports search query params.
 * @type {Object}
 */
export const reportsSearchQueryParamDefaults = {
    FROM: 0,
    SIZE: 75,
};

/**
 * Default cases search query params.
 * @type {Object}
 */
export const casesSearchQueryParamDefaults = {
    FROM: 0,
    SIZE: 25,
};

export const sortKeyToColumnKey = {
    [sortKeyEnum.REPORT_UPDATED_DATE_UTC]: 'updatedDateUtc',
    [sortKeyEnum.REPORT_EVENT_START_UTC]: 'eventStartUtc',
    [sortKeyEnum.REPORT_REPORT_CREATION_DATE_UTC]: 'reportCreationDateUtc',
    [sortKeyEnum.REPORT_REPORTING_EVENT_NUMBER]: 'reportingEventNumber',
    [sortKeyEnum.CASE_UPDATED_DATE_UTC]: 'updatedDateUtc',
    [sortKeyEnum.CASE_CREATED_DATE_UTC]: 'createdDateUtc',
    [sortKeyEnum.CASE_ASSIGNED_DATE_UTC]: 'assignedDateUtc',
    [sortKeyEnum.CASE_DUE_DATE_UTC]: 'dueDateUtc',
};

export const casesTabEnum = {
    MINE: 'mine',
    UNASSIGNED: 'unassigned',
    ALL: 'all',
};

export const caseFolderDefaults = {
    MAX_BREADCRUMBS_DISPLAYED: 2,
};

export const SHOW_REPORT_DETAILS_FORM_LOCATIONS: ReasonForRelationFormLocationType[] = [
    overlayIdEnum.CREATE_CASE_FROM_REPORT_MODAL,
    overlayIdEnum.CREATE_CASE_FROM_MULTI_REPORTS_MODAL,
];

export const HIDE_REASON_FOR_RELATION_FORM_LOCATIONS: ReasonForRelationFormLocationType[] = [
    overlayIdEnum.CREATE_CASE_FROM_REPORT_MODAL,
    overlayIdEnum.INDIVIDUAL_REPORT_SELECTION_SIDE_PANEL,
];

export const SHOW_REMOVE_ITEM_BUTTON_FOR_LOCATIONS: ReasonForRelationFormLocationType[] = [
    overlayIdEnum.CREATE_CASE_FROM_MULTI_REPORTS_MODAL,
    overlayIdEnum.REASON_FOR_RELATION_MODAL,
    overlayIdEnum.REASON_FOR_RELATION_SIDE_PANEL,
];

export const SHOW_CASE_HEADER_FOR_LOCATIONS: ReasonForRelationFormLocationType[] = [
    overlayIdEnum.INDIVIDUAL_REPORT_SELECTION_SIDE_PANEL,
];
