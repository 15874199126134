import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compact, flatten, get, isEmpty, isUndefined, map, partition, values } from 'lodash';
import { Report } from '@mark43/rms-api';
import { InlineBanner, SkeletonText } from 'arc';
import { useResource } from '~/client-common/core/hooks/useResource';
import componentStrings from '~/client-common/core/strings/componentStrings';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { REPORT_REPORTING_EVENT_NUMBER } from '~/client-common/core/enums/universal/fields';
import { reportsSelector } from '~/client-common/core/domain/reports/state/data';
import { reportShortTitleViewModelsSelector } from '~/client-common/core/domain/report-short-titles/state/ui';
import type { ReportShortTitleWrapper } from '~/client-common/core/domain/report-short-titles/types';
import { withEntityItems } from '~/client-common/core/utils/nexusHelpers';
import { NEXUS_STATE_PROP as REPORT_SHORT_TITLES_NEXUS_STATE_PROP } from '~/client-common/core/domain/report-short-titles/state/data';
import { RmsDispatch } from '../../../../core/typings/redux';
import Subsection from '../../../core/components/Subsection';
import LinkedReports from '../../../records/core/components/sidebar/LinkedReports';
import reportsResource from '../../../reports/core/resources/reportsResource';
import buildReportIdentifierView from '../../../reports/core/utils/buildReportIdentifierView';
import { LinkedReportItemSelectableProps } from '../../../records/core/components/sidebar/LinkedReportItem';

const strings = componentStrings.cases.core.CreateNewReportFromMultiRenModal;

type RenReportType = Pick<Report, 'id' | 'recordNumber' | 'reportingEventNumber'>;

const getTitle = (renDisplayName: string, ren?: string, recordTitle?: string) => {
    if (ren) {
        return strings.linkedRenReports(renDisplayName, ren);
    }

    if (recordTitle) {
        return strings.linkedReport(recordTitle);
    }

    return strings.linkedReport('');
};

type LinkedReportsModalContentProps = {
    selectedRen?: string;
    selectedReportId?: number;
    linkedReportItemProps?: LinkedReportItemSelectableProps;
};

const LinkedReportsModalContent = ({
    selectedRen,
    selectedReportId,
    linkedReportItemProps,
}: LinkedReportsModalContentProps) => {
    const dispatch = useDispatch<RmsDispatch>();
    const caseReports: ReturnType<typeof reportsSelector> = useSelector(reportsSelector);
    const reportShortTitleViewModels: ReturnType<
        typeof reportShortTitleViewModelsSelector
    > = useSelector(reportShortTitleViewModelsSelector);
    const fieldDisplayNames = useFields([REPORT_REPORTING_EVENT_NUMBER]);
    const renDisplayName = fieldDisplayNames.REPORT_REPORTING_EVENT_NUMBER;

    const reportsForShortTitles: RenReportType[] = useMemo(
        () =>
            compact(
                map(caseReports, ({ id, reportingEventNumber, recordNumber }) => {
                    if (
                        selectedRen &&
                        !isEmpty(reportingEventNumber) &&
                        selectedRen === reportingEventNumber
                    ) {
                        return {
                            id,
                            reportingEventNumber,
                            recordNumber,
                        };
                    }

                    if (selectedReportId && selectedReportId === id) {
                        return {
                            id,
                            recordNumber,
                        };
                    }

                    return undefined;
                })
            ),
        [caseReports, selectedRen, selectedReportId]
    );

    const loadReportShortTitles = useCallback(() => {
        const reportIdentifierView = buildReportIdentifierView(reportsForShortTitles);
        return reportsResource.findReportTitlesByReportIdentifier(reportIdentifierView);
    }, [reportsForShortTitles]);

    const onSuccess = useCallback(
        (shortTitleWrapper: ReportShortTitleWrapper) => {
            // store the report short titles in redux
            dispatch(
                withEntityItems(
                    {
                        // Store both record and ren short titles
                        [REPORT_SHORT_TITLES_NEXUS_STATE_PROP]: [
                            ...flatten(values(shortTitleWrapper.reportShortTitlesByREN)),
                            ...flatten(
                                values(
                                    shortTitleWrapper.reportShortTitlesByRecordWithoutRENRecordNumber
                                )
                            ),
                        ],
                    },
                    { type: 'STORE_REPORT_SHORT_TITLES' }
                )
            );
        },
        [dispatch]
    );

    const reportIds = map(reportsForShortTitles, 'id');
    const allReportShortTitles = compact(map(reportIds, (id) => reportShortTitleViewModels[id]));
    const recordWithoutRen = selectedReportId && reportShortTitleViewModels[selectedReportId];

    const [, renShortTitles] = partition(allReportShortTitles, (shortTitle) =>
        isUndefined(shortTitle.reportingEventNumber)
    );

    const title = getTitle(renDisplayName, selectedRen, get(recordWithoutRen, 'recordNumber'));

    const { isLoading, errorMessage } = useResource(loadReportShortTitles, onSuccess);
    if (errorMessage) {
        return <InlineBanner status="error" description={errorMessage} />;
    }

    if (isLoading) {
        return <SkeletonText noOfLines={3} />;
    }

    return (
        <>
            {selectedRen && (
                <Subsection title={title}>
                    <LinkedReports
                        reportShortTitleViewModels={renShortTitles}
                        linkedReportItemProps={linkedReportItemProps}
                        isCollapsible
                        paddingLeft={0}
                    />
                </Subsection>
            )}
        </>
    );
};

export default LinkedReportsModalContent;
