import { makeResettable } from '~/client-common/helpers/reducerHelpers';
import { nowUtc } from '~/client-common/core/dates/utils/dateHelpers';
import actionTypes from '../actions/types/userProfileAdminActionTypes';
import { setFormValues } from '../helpers/formReducerHelpers';
import {
    convertUserProfileDataStateToFormState,
    convertAttributesDataStateToFormState,
    convertDutyStatusesDataStateToFormState,
    convertPhonesDataStateToFormState,
    convertUserAssignmentsDataStateToFormState,
    convertMultiStateOrisToFormState,
} from '../helpers/userProfileAdminHelpers';

const initialDataState = {};

const initialUiState = {
    profile: {},
    attributes: [],
    dutyStatuses: [],
    phones: [],
    pageLoadError: false,
    saveFailed: null,
    resendConfirmation: {
        sending: false,
        error: null,
        success: false,
    },
    unlockUserAccount: {
        sending: false,
        error: null,
        success: false,
    },
    userProfileAttachments: [],
    dexStateUserIdOriAliasAssociations: [],
};

export const userProfileAdminDataReducer = makeResettable(
    actionTypes.RESET_USER_PROFILE_ADMIN_STATE,
    function userProfileAdminDataReducer(state = initialDataState, action) {
        switch (action.type) {
            case actionTypes.LOAD_USER_PROFILE_SUCCESS:
            case actionTypes.SAVE_USER_PROFILE_SUCCESS:
                return { ...state, profile: action.payload };
            case actionTypes.LOAD_USER_ATTRIBUTES_SUCCESS:
            case actionTypes.SAVE_USER_ATTRIBUTES_SUCCESS:
                return { ...state, attributes: action.payload };
            case actionTypes.LOAD_USER_DUTY_STATUSES_SUCCESS:
            case actionTypes.SAVE_USER_DUTY_STATUSES_SUCCESS:
                return { ...state, dutyStatuses: action.payload };
            case actionTypes.LOAD_USER_PHONES_SUCCESS:
            case actionTypes.SAVE_USER_PHONES_SUCCESS:
                return { ...state, phones: action.payload };
            case actionTypes.SAVE_MULTI_STATE_ORIS_SUCCESS:
            case actionTypes.LOAD_MULTI_STATE_ORIS_SUCCESS:
                return {
                    ...state,
                    dexStateUserIdOriAliasAssociations: action.payload.dexStateUserIds,
                };
            default:
                return state;
        }
    },
    initialDataState
);

export const userProfileAdminUiReducer = makeResettable(
    actionTypes.RESET_USER_PROFILE_ADMIN_STATE,
    function userProfileAdminUiReducer(state = initialUiState, action) {
        switch (action.type) {
            case actionTypes.LOAD_USER_PROFILE_SUCCESS:
                return {
                    ...state,
                    profile: action.payload,
                    resendConfirmation: initialUiState.resendConfirmation,
                };
            case actionTypes.SAVE_USER_PROFILE_SUCCESS:
                return {
                    ...state,
                    saveFailed: null,
                    profile: {
                        ...action.payload,
                        user: state.profile.user,
                    },
                };
            case actionTypes.SAVE_USER_PROFILE_FAILURE:
                return {
                    ...state,
                    saveFailed: true,
                };
            case actionTypes.SAVE_USER_ACCOUNT_SUCCESS:
                return {
                    ...state,
                    profile: {
                        ...state.profile,
                        isSsoUser: action.payload.isSsoUser,
                        user: action.payload,
                        primaryEmail: action.payload.primaryEmail,
                    },
                };
            case actionTypes.SAVE_USER_ACCOUNT_STATUS_SUCCESS:
                return {
                    ...state,
                    profile: {
                        ...state.profile,
                        user: {
                            ...state.profile.user,
                            isDisabled: action.payload,
                        },
                    },
                };
            case actionTypes.LOAD_USER_ATTRIBUTES_SUCCESS:
            case actionTypes.SAVE_USER_ATTRIBUTES_SUCCESS:
                return { ...state, attributes: action.payload };
            case actionTypes.SAVE_USER_DUTY_STATUSES_SUCCESS:
            case actionTypes.LOAD_USER_DUTY_STATUSES_SUCCESS:
                return { ...state, dutyStatuses: action.payload };
            case actionTypes.LOAD_USER_PHONES_SUCCESS:
            case actionTypes.SAVE_USER_PHONES_SUCCESS:
                return { ...state, phones: action.payload };
            case actionTypes.LOAD_USER_PROFILE_ATTACHMENTS_SUCCESS:
            case actionTypes.SAVE_USER_PROFILE_ATTACHMENTS_SUCCESS:
                return { ...state, userProfileAttachments: action.payload };

            case actionTypes.PAGE_LOAD_ERROR:
                return {
                    ...state,
                    pageLoadError: true,
                };
            case actionTypes.RESEND_CONFIRMATION_START:
                return {
                    ...state,
                    resendConfirmation: {
                        sending: true,
                        error: null,
                        success: false,
                    },
                };
            case actionTypes.RESEND_CONFIRMATION_SUCCESS:
                return {
                    ...state,
                    resendConfirmation: {
                        sending: false,
                        error: null,
                        success: true,
                    },
                };
            case actionTypes.RESEND_CONFIRMATION_FAILURE:
                return {
                    ...state,
                    resendConfirmation: {
                        sending: false,
                        error: action.payload,
                        success: false,
                    },
                };

            case actionTypes.UNLOCK_USER_ACCOUNT_START:
                return {
                    ...state,
                    unlockUserAccount: {
                        sending: true,
                        error: null,
                        success: false,
                    },
                };
            case actionTypes.UNLOCK_USER_ACCOUNT_SUCCESS:
                return {
                    ...state,
                    unlockUserAccount: {
                        sending: false,
                        error: null,
                        success: true,
                    },
                };
            case actionTypes.UNLOCK_USER_ACCOUNT_FAILURE:
                return {
                    ...state,
                    unlockUserAccount: {
                        sending: false,
                        error: action.payload,
                        success: false,
                    },
                };
            case actionTypes.SAVE_MULTI_STATE_ORIS_SUCCESS:
            case actionTypes.LOAD_MULTI_STATE_ORIS_SUCCESS:
                return {
                    ...state,
                    dexStateUserIdOriAliasAssociations: action.payload.dexStateUserIds,
                };
            default:
                return state;
        }
    },
    initialUiState
);

const initialFormState = {
    dutyStatuses: [
        {
            dutyStatus: {
                value: 'FULL',
            },
            dateEffective: {
                value: nowUtc(),
            },
        },
    ],
};

export const userProfileAdminFormReducer = makeResettable(
    actionTypes.RESET_USER_PROFILE_ADMIN_STATE,
    function userProfileAdminFormReducer(state = initialFormState, action) {
        switch (action.type) {
            case actionTypes.LOAD_USER_PROFILE_SUCCESS:
            case actionTypes.SAVE_USER_PROFILE_SUCCESS:
                return convertUserProfileDataStateToFormState(state, action.payload);
            case actionTypes.SAVE_USER_ACCOUNT_SUCCESS:
                return setFormValues(state, {
                    isSsoUser: action.payload.isSsoUser,
                    primaryEmail: action.payload.primaryEmail,
                    user: action.payload,
                });
            case actionTypes.SAVE_USER_ACCOUNT_STATUS_SUCCESS:
                return setFormValues(state, {
                    isDisabled: action.payload,
                });
            case actionTypes.LOAD_USER_ATTRIBUTES_SUCCESS:
            case actionTypes.SAVE_USER_ATTRIBUTES_SUCCESS:
                return {
                    ...state,
                    attributes: convertAttributesDataStateToFormState(action.payload),
                };
            case actionTypes.LOAD_USER_PHONES_SUCCESS:
            case actionTypes.SAVE_USER_PHONES_SUCCESS:
                return {
                    ...state,
                    phones: convertPhonesDataStateToFormState(action.payload),
                };
            case actionTypes.LOAD_USER_DUTY_STATUSES_SUCCESS:
            case actionTypes.SAVE_USER_DUTY_STATUSES_SUCCESS:
                return {
                    ...state,
                    dutyStatuses: convertDutyStatusesDataStateToFormState(action.payload),
                };
            case actionTypes.SET_USER_ASSIGNMENT_FORM_VALUES:
                return {
                    ...state,
                    userAssignments: convertUserAssignmentsDataStateToFormState(action.payload),
                };
            case actionTypes.RESET_USER_PROFILE_FORM:
                const ui = action.payload;
                const uiHasUserData = ui && ui.profile && Object.keys(ui.profile).length > 0;
                if (!uiHasUserData) {
                    return initialFormState;
                }
                const {
                    profile,
                    phones,
                    dutyStatuses,
                    attributes,
                    userProfileAttachments,
                    dexStateUserIdOriAliasAssociations,
                } = ui;

                return {
                    ...convertUserProfileDataStateToFormState(state, profile),
                    phones: convertPhonesDataStateToFormState(phones),
                    dutyStatuses: convertDutyStatusesDataStateToFormState(dutyStatuses),
                    attributes: convertAttributesDataStateToFormState(attributes),
                    dexStateUserIdOriAliasAssociations: convertMultiStateOrisToFormState(
                        dexStateUserIdOriAliasAssociations
                    ),
                    userProfileAttachments,
                };
            case actionTypes.LOAD_USER_PROFILE_ATTACHMENTS_SUCCESS:
            case actionTypes.SAVE_USER_PROFILE_ATTACHMENTS_SUCCESS:
                return { ...state, userProfileAttachments: action.payload };
            case actionTypes.SAVE_MULTI_STATE_ORIS_SUCCESS:
            case actionTypes.LOAD_MULTI_STATE_ORIS_SUCCESS:
                return {
                    ...state,
                    dexStateUserIdOriAliasAssociations: convertMultiStateOrisToFormState(
                        action.payload.dexStateUserIds
                    ),
                };
            default:
                return state;
        }
    },
    initialFormState
);
