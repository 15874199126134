import { RefContextEnum } from '@mark43/rms-api';
import _, {
    chain,
    filter,
    first,
    get,
    includes,
    isArray,
    map,
    parseInt,
    size,
    some,
    sortBy,
} from 'lodash';

import { createSelector } from 'reselect';

import { ruleFieldGetters } from 'arbiter-mark43';
import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';
import { makeResettable } from '~/client-common/helpers/reducerHelpers';
import { fieldDetailByIdSelector } from '~/client-common/core/domain/field-details/state/data';
import { fieldConfigurationByIdSelector } from '~/client-common/core/domain/field-configurations/state/data';
import { rulesSelector, ruleByIdSelector } from '~/client-common/core/domain/rules/state/data';
import { ruleConfigurationContextsWhereSelector } from '~/client-common/core/domain/rule-configuration-contexts/state/data';
import { ruleActionedFieldsWhereSelector } from '~/client-common/core/domain/rule-actioned-fields/state/data';
import { ruleConditionsWhereSelector } from '~/client-common/core/domain/rule-conditions/state/data';
import { ruleConditionArgsWhereSelector } from '~/client-common/core/domain/rule-condition-args/state/data';
import { codesSelector } from '~/client-common/core/domain/codes/state/data';
import { nibrsOffenseCodesSelector } from '~/client-common/core/domain/nibrs-offense-codes/state/data';
import { getCodeTypeCategory } from '~/client-common/core/domain/codes/utils/codesHelpers';
import { attributeStatuses } from '~/client-common/core/domain/attributes/configuration';
import stringsConfig from '~/client-common/core/strings';

import rulesAdminForm from '../forms/rulesAdminForm';
import {
    getLogicalStructureForStructuredConditions,
    nestConditions,
    sortRuleConfigurationContextsByContextName,
} from '../../utils/rulesAdminHelpers';
import { getRuleBundleForRuleId } from '../data';

const { ACTIVE, EXPIRED } = attributeStatuses;

const strings = stringsConfig.components.admin.rules;

const RESET_RULE_ADMIN_PAGE = 'rules/RESET_RULE_ADMIN_PAGE';
const SELECT_RULE_START = 'rules/SELECT_RULE_START';
const SELECT_RULE_SUCCESS = 'rules/SELECT_RULE_SUCCESS';
const SELECT_RULE_FAILURE = 'rules/SELECT_RULE_FAILURE';

// SELECTORS
export const uiSelector = (state) => state.ui.rulesAdmin;

export const rulesAdminListItemsSelector = createSelector(
    uiSelector,
    rulesSelector,
    ruleConfigurationContextsWhereSelector,
    (ui, rules, ruleConfigurationContextsWhere) =>
        _(rules)
            .map((rule) => {
                const ruleId = rule.id;
                const ruleConfigurationContexts = sortRuleConfigurationContextsByContextName(
                    ruleConfigurationContextsWhere({ ruleId })
                );
                let contexts = _.compact(
                    map(ruleConfigurationContexts, (ruleConfigurationContext) => {
                        return get(RefContextEnum, ruleConfigurationContext.context);
                    })
                );
                const longContextDisplayNames = joinTruthyValues(map(contexts, 'displayName'));

                const personContexts = _.filter(contexts, (c) =>
                    _.startsWith(c.name, 'FORM_PERSON_')
                );
                if (personContexts.length >= 5) {
                    contexts = _.reject(contexts, (c) => _.startsWith(c.name, 'FORM_PERSON_'));
                    contexts.push({
                        displayName: `Person Side Panel (${personContexts.length} contexts)`,
                    });
                }
                const evdContexts = _.filter(contexts, (c) =>
                    _.startsWith(c.name, 'FORM_EVIDENCE_DISPOSITION_')
                );
                if (evdContexts.length >= 5) {
                    contexts = _.reject(contexts, (c) =>
                        _.startsWith(c.name, 'FORM_EVIDENCE_DISPOSITION_')
                    );
                    contexts.push({
                        displayName: `Evidence Disposition Side Panel (${evdContexts.length} contexts)`,
                    });
                }
                // if any context is enabled the rule is considered active
                const isActive = some(ruleConfigurationContexts, ({ isDisabled }) => !isDisabled);
                return {
                    key: ruleId,
                    title: rule.displayName,
                    subtitle: joinTruthyValues(_.sortBy(map(contexts, 'displayName'))),
                    path: `/admin/rules/${ruleId}`,
                    selected: ui.selectedRuleId === ruleId,
                    internalName: `${rule.name} ${longContextDisplayNames}`,
                    status: isActive ? ACTIVE : EXPIRED,
                    contextNames: map(ruleConfigurationContexts, (ruleConfigurationContext) =>
                        get(RefContextEnum, `${ruleConfigurationContext.context}.name`)
                    ),
                    ruleType: rule.ruleType,
                };
            })
            // Not using `sortByAll` because we want to specially handle empty
            // string `subtitle`s by sorting them last.
            .sortBy('title')
            .sortBy((listItem) => (listItem.subtitle ? listItem.subtitle : null))
            .value()
);

export function attachFieldConfigurationsToOperators(nextValue, state) {
    if (isArray(nextValue) && nextValue.length === 3) {
        return map(nextValue, (value) => attachFieldConfigurationsToOperators(value, state));
    }
    const ruleCondition = nextValue;
    const fieldConfigurationId = ruleCondition.fieldConfigurationId;
    const fieldConfiguration = fieldConfigurationByIdSelector(state)(fieldConfigurationId);
    // must have this condition here... we blindly are passing in sets of 3
    // tokens... some of those tokens could be conditions or boolean operators.
    // if it's a boolean operator, all of these consts end up undefined
    // (fieldConfigurationId + fieldConfiguration).  This is confusing & is on
    // radar for refactor.
    const fieldDetail = fieldDetailByIdSelector(state)(get(fieldConfiguration, 'fieldDetailId'));

    const ruleConditionArgs = filter(
        rulesAdminForm.selectors.formModelByPathSelector(state)('ruleConditionArgs'),
        { ruleConditionId: ruleCondition.id }
    );

    const codes = codesSelector(state);
    let staticValueDescription;
    if (
        ruleCondition.ruleFieldGetter === ruleFieldGetters.GET_CODE_FOR_ATTRIBUTE_ID_AND_SOURCE.name
    ) {
        const attributeType = get(fieldDetail, 'fieldTypeMappedIdEnum');
        const source = chain(ruleConditionArgs).find({ argName: 'source' }).get('value').value();
        const category = getCodeTypeCategory(source, attributeType);
        const categories = isArray(category) ? category : [category];
        if (categories.length > 0) {
            staticValueDescription = chain(codes)
                .find(
                    (code) =>
                        code.code === ruleCondition.staticValue &&
                        includes(categories, code.codeType.category)
                )
                .get('description')
                .value();
        }
    } else if (
        get(fieldDetail, 'fieldName') === 'DISPLAY_ONLY_OFFENSE_NIBRS_CODE_CODE' &&
        nextValue.staticValue
    ) {
        const nibrsOffenseCodes = nibrsOffenseCodesSelector(state);
        const nibrsOffenseCode = nibrsOffenseCodes[nextValue.staticValue];
        if (nibrsOffenseCode) {
            staticValueDescription = nibrsOffenseCode.name;
        }
    }

    const attachedFieldConfigValue = {
        ...ruleCondition,
        fieldConfiguration,
        fieldDetail,
        ruleConditionArgs,
        staticValueDescription,
    };

    if (ruleCondition.fieldConfiguration2Id) {
        const fieldConfiguration2Id = ruleCondition.fieldConfiguration2Id;
        const fieldConfiguration2 = fieldConfigurationByIdSelector(state)(fieldConfiguration2Id);
        const fieldDetail2 = fieldDetailByIdSelector(state)(
            get(fieldConfiguration2, 'fieldDetailId')
        );

        return {
            ...attachedFieldConfigValue,
            fieldConfiguration2,
            fieldDetail2,
        };
    }

    return attachedFieldConfigValue;
}

function getLogicalStructureForRule(rule, state) {
    const ruleConditions = ruleConditionsWhereSelector(state)({ ruleId: rule.id });

    const sortedConditions = sortBy(ruleConditions, 'tokenOrder');
    const nestedConditions = nestConditions(sortedConditions);
    const structuredConditions = attachFieldConfigurationsToOperators(
        first(nestedConditions) || [],
        state
    );
    const ruleActionedFields = ruleActionedFieldsWhereSelector(state)({ ruleId: rule.id });

    return getLogicalStructureForStructuredConditions({
        rule,
        structuredConditions,
        sortedConditions,
        ruleActionedFields,
    });
}

let ruleIdToLogicalStructure;

export const ruleIdToLogicalStructureSelector = (state) => {
    if (size(ruleIdToLogicalStructure) > 500) {
        return ruleIdToLogicalStructure;
    }
    const rules = rulesSelector(state);
    ruleIdToLogicalStructure = _(rules)
        .mapKeys('id')
        .mapValues((rule) => getLogicalStructureForRule(rule, state))
        .value();
    return ruleIdToLogicalStructure;
};

// ACTIONS
// Select action
function selectRuleStart() {
    return { type: SELECT_RULE_START };
}

function selectRuleSuccess(ruleId) {
    return {
        type: SELECT_RULE_SUCCESS,
        payload: ruleId,
    };
}

function selectRuleFailure(errorMessage) {
    return {
        type: SELECT_RULE_FAILURE,
        payload: errorMessage,
    };
}

export function selectRule(ruleId) {
    return (dispatch, getState) => {
        dispatch(selectRuleStart());
        const state = getState();
        const id = parseInt(ruleId);
        const rule = ruleByIdSelector(state)(id);

        if (rule) {
            const ruleConfigurationContexts = ruleConfigurationContextsWhereSelector(state)({
                ruleId: id,
            });
            const ruleActionedFields = ruleActionedFieldsWhereSelector(state)({ ruleId: id });
            const ruleConditions = ruleConditionsWhereSelector(state)({ ruleId: id });
            const ruleConditionArgs = ruleConditionArgsWhereSelector(state)({ ruleId: id });

            const fieldDetailById = fieldDetailByIdSelector(state);
            const fieldConfigurationById = fieldConfigurationByIdSelector(state);

            const fieldConfigurations = map(ruleActionedFields, (ruleActionedField) =>
                fieldConfigurationById(ruleActionedField.fieldConfigurationId)
            );
            const fieldDetails = map(fieldConfigurations, (fieldConfiguration) =>
                fieldDetailById(fieldConfiguration.fieldDetailId)
            );

            dispatch(
                rulesAdminForm.actionCreators.change({
                    fieldDetails,
                    fieldConfigurations,
                    rule,
                    ruleConfigurationContexts,
                    ruleActionedFields,
                    ruleConditions,
                    ruleConditionArgs,
                })
            );
            dispatch(selectRuleSuccess(id));
        } else {
            dispatch(selectRuleFailure(strings.selectRuleError(ruleId)));
        }
    };
}

export const prefillRuleToDuplicate = (ruleId) => {
    return (dispatch, getState) => {
        return getRuleBundleForRuleId(ruleId).then((ruleBundle) => {
            const state = getState();
            if (!ruleBundle || !ruleBundle?.rules?.[0]) {
                throw new Error(`No ruleBundle returned for ruleId ${ruleId}`);
            }
            const {
                ruleConfigurationContexts,
                ruleActionedFields,
                ruleConditions,
                ruleConditionArgs,
                rules,
            } = ruleBundle;
            const rule = rules[0];
            const fieldDetailById = fieldDetailByIdSelector(state);
            const fieldConfigurationById = fieldConfigurationByIdSelector(state);

            const fieldConfigurations = map(ruleActionedFields, (ruleActionedField) =>
                fieldConfigurationById(ruleActionedField.fieldConfigurationId)
            );
            const fieldDetails = map(fieldConfigurations, (fieldConfiguration) =>
                fieldDetailById(fieldConfiguration.fieldDetailId)
            );
            dispatch(
                rulesAdminForm.actionCreators.change({
                    fieldConfigurations,
                    fieldDetails,
                    rule: { ...rule, canDisableInAnyContext: true },
                    ruleActionedFields,
                    ruleConditions,
                    ruleConditionArgs,
                    ruleConfigurationContexts,
                })
            );
        });
    };
};

// Reset action
export function resetRuleAdminPage() {
    return { type: RESET_RULE_ADMIN_PAGE };
}

// INITIAL STATE
const initialUiState = {
    selectedRuleId: null,
    selectedContextName: null,
    selectRuleError: null,
};

// REDUCER
function rulesAdminUiReducer(state = initialUiState, action) {
    switch (action.type) {
        case SELECT_RULE_START:
            return {
                ...state,
                selectedRuleId: null,
                selectRuleError: null,
            };
        case SELECT_RULE_SUCCESS:
            return {
                ...state,
                selectedRuleId: action.payload,
                selectRuleError: null,
            };
        case SELECT_RULE_FAILURE:
            return {
                ...state,
                selectedRuleId: null,
                selectRuleError: action.payload,
            };
        default:
            return state;
    }
}

export default makeResettable(RESET_RULE_ADMIN_PAGE, rulesAdminUiReducer);
