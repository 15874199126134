import React, { FC } from 'react';
import { ReportShortTitle } from '@mark43/rms-api';
import { Flex } from 'arc';
import styled from 'styled-components';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';

import { PopoutLink } from '../../../../core/components/links/Link';
import Checkbox from '../../../../core/forms/components/checkboxes/Checkbox';
import { ConditionalTooltip } from '../../../../core/components/tooltip';
import LinkedReport from './LinkedReport';

const strings = componentStrings.cases.core.ReasonForRelationForm;

const CheckboxStyled = styled(Checkbox)`
    margin-top: 5px;
    margin-right: 0px;
`;

const PopoutLinkStyled = styled(PopoutLink)`
    margin: 5px 0px;
`;

type LinkedReportWrapperProps = {
    reportShortTitle: ReportShortTitle;
    showReportDetails: boolean;
};

const LinkedReportWrapper: FC<LinkedReportWrapperProps> = ({
    children,
    reportShortTitle,
    showReportDetails,
}) => {
    const { reportId, shortTitle } = reportShortTitle;

    const reportLink = showReportDetails ? (
        <LinkedReport reportShortTitleViewModel={reportShortTitle} />
    ) : (
        <PopoutLinkStyled to={`/reports/${reportId}`}>{shortTitle}</PopoutLinkStyled>
    );

    return (
        <Flex>
            {children}
            {reportLink}
        </Flex>
    );
};

type LinkedReportItemProps = LinkedReportWrapperProps & Partial<LinkedReportItemSelectableProps>;

export type LinkedReportItemSelectableProps = {
    selectable: boolean;
    selectedReportIds: number[];
    caseReportIds?: number[];
    onChange: (reportIds: number[]) => void;
};

export const LinkedReportItem: FC<LinkedReportItemProps> = ({
    selectable,
    selectedReportIds = [],
    caseReportIds = [],
    onChange = () => {},
    ...props
}) => {
    const { singularCaseFieldName: caseDisplayName } = useCaseFieldName();
    const { reportId } = props.reportShortTitle;

    const handleChange = () => {
        const nextReportIds = selectedReportIds.includes(reportId)
            ? selectedReportIds.filter((id) => id !== reportId)
            : [...selectedReportIds, reportId];

        onChange(nextReportIds);
    };

    const disabled = caseReportIds.includes(reportId);

    return (
        <LinkedReportWrapper {...props}>
            {selectable && (
                <ConditionalTooltip
                    condition={disabled}
                    content={strings.reportAlreadyExists(caseDisplayName)}
                    align="start"
                >
                    <div>
                        <CheckboxStyled
                            value={
                                selectedReportIds.includes(reportId) ||
                                caseReportIds.includes(reportId)
                            }
                            disabled={disabled}
                            onChange={handleChange}
                        />
                    </div>
                </ConditionalTooltip>
            )}
        </LinkedReportWrapper>
    );
};

export default LinkedReportItem;
