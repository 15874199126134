import { flowRight } from 'lodash';
import React from 'react';
import { Portal } from 'react-portal';
import classNames from 'classnames';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import SidePanelBase from '../../../modules/core/overlays/components/SidePanelBase';
import { boxUiSelector } from '../../selectors/boxSelectors';
import { closeBox, saveBoxStart } from '../../actions/boxActions';
import { renderOnlyIf } from '../../helpers/reactHelpers';
import { ContentSection as _ContentSection } from './Content';

const SidePanel = ({ context, ui, customValidationErrorMessages = [], ...rest }) => {
    const contextData = ui[context.name];

    const { errorMessages: contextErrorMessages, ...resContextData } = contextData;
    const allErrorMessages = [
        ...new Set([...contextErrorMessages, ...customValidationErrorMessages]),
    ];

    return (
        <SidePanelBase
            isLegacy={true}
            testId={context.name}
            {...rest}
            {...resContextData}
            errorMessages={allErrorMessages}
        />
    );
};

const PortalSidePanelBase = styled(SidePanelBase)`
    right: 0;
    z-index: auto;
    height: 100%;
    bottom: auto;

    && {
        position: absolute;
        top: auto;
    }
`;

SidePanel.propTypes = {
    title: PropTypes.string.isRequired,
    saveText: PropTypes.string,
    cancelText: PropTypes.string,
    saveDisabled: PropTypes.bool,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    ui: PropTypes.object.isRequired,
    noPadding: PropTypes.bool,
    width: PropTypes.number,
    hideFooter: PropTypes.bool,
    useDefaultStyle: PropTypes.bool,
    customValidationErrorMessages: PropTypes.arrayOf(PropTypes.string),
};

const mapDispatchToProps = (dispatch, props) => ({
    savePanel: () => {
        dispatch(saveBoxStart(props.context));
        props.onSave();
    },
    closePanel: () => {
        if (props.onCancel) {
            props.onCancel();
        } else {
            // if the prop isn't provided, just close the panel
            dispatch(closeBox(props.context));
        }
    },
});

const mapStateToProps = createStructuredSelector({
    ui: boxUiSelector,
});

/**
 * @type React.FC<{
 *     title: string;
 *     context?: { name: string };
 *     width?: number;
 *     cancelText?: string;
 *     saveText?: string;
 *     onSave?: () => void;
 *     onCancel?: () => void;
 *     className?: string;
 * }>
 * @deprecated
 * New side panels (and modals) should be written using (overlay-manager + OverlayButton + modules/core/overlays/components/SidePanel.tsx)
 * rather than (boxActions + legacy-redux/components/core/SidePanel), since this SidePanels component renders 'globally' for every route,
 * even routes that never open this side panel.
 * https://readme.mark43.io/guides/products/rms/rms-frontend/rmsfe-overlays/
 */
const LegacySidePanel = flowRight(
    connect(mapStateToProps, mapDispatchToProps),
    renderOnlyIf(({ context, ui }) => ui[context.name].isOpen)
)(SidePanel);

export default LegacySidePanel;

const ContentSection = styled(_ContentSection)`
    padding: 0 0 var(--arc-space-4);
`;

/**
 * Content section inside a `SidePanel` with an optional header.
 * Same props as `ContentSection`.
 */
export const SidePanelSection = (props) => {
    return (
        <ContentSection
            {...props}
            headerStyle={{ width: 'calc(100% + 40px)', marginLeft: '-20px', marginBottom: '12px' }}
        />
    );
};

export function PortalSidePanel(props) {
    const { isAtBottomOfStack, className, ...rest } = props;
    const applicationSettings = useSelector(applicationSettingsSelector);
    const isMainNavEnabled = applicationSettings.RMS_ARC_NAVIGATION_ENABLED;

    return (
        <Portal>
            <div
                className={classNames('mark43-modal-overlay', className, {
                    // we only render the background for the first side panel so that multiple open side panels won't multiply
                    dimmed: isAtBottomOfStack && isAtBottomOfStack(),
                    // This can be removed when the RMS_ARC_NAVIGATION_ENABLED feature flag is torn down
                    'no-header': isMainNavEnabled,
                })}
            >
                <PortalSidePanelBase isLegacy={true} {...rest} />
            </div>
        </Portal>
    );
}
