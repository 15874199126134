import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SkeletonText } from '@arc/skeleton';
import { RefContextEnum } from '@mark43/rms-api';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { RmsDispatch } from '../../../../core/typings/redux';
import { OverlayBaseHelper } from '../../../core/components/OverlayBaseHelper';
import { useFormGetter } from '../../../core/forms/hooks/useFormGetter';
import Modal from '../../../core/overlays/components/Modal';
import caseReportLinksResource from '../../core/resources/caseReportLinksResource';
import ReasonForRelationForm from '../../core/components/ReasonForRelationForm';
import {
    convertFromFormModelToCaseRequest,
    resetReasonForRelationForm,
} from '../../core/state/form/reasonForRelationForm';
import { caseOnEnter } from '../..';

const strings = componentStrings.cases.caseSummary.CaseReasonForRelationModal;

const formContext = RefContextEnum.FORM_REASON_FOR_RELATION_MODAL.name;

const ReasonForRelationModal: React.FC<{ caseId: number }> = ({ caseId }) => {
    return (
        <OverlayBaseHelper id={overlayIdEnum.REASON_FOR_RELATION_MODAL}>
            {({ closePanel, overlayBase }) => (
                <ReasonForRelationModalContent
                    caseId={caseId}
                    closePanel={closePanel}
                    isLoading={overlayBase.overlayState.isLoading}
                />
            )}
        </OverlayBaseHelper>
    );
};

type ReasonForRelationModalContentProps = {
    caseId: number;
    closePanel: () => void;
    isLoading?: boolean;
};

const ReasonForRelationModalContent: FC<ReasonForRelationModalContentProps> = ({
    caseId,
    closePanel,
    isLoading = false,
}) => {
    const dispatch = useDispatch<RmsDispatch>();
    const applicationSettings = useSelector(applicationSettingsSelector);
    const individualReportSelectionEnabled = !!applicationSettings.RMS_INDIVIDUAL_REPORT_SELECTION_ENABLED;

    const { getForm } = useFormGetter();
    const onSave = useCallback(() => {
        const form = getForm(formContext);
        if (form) {
            return form.submit().then((result) => {
                const formModel = result.form.getState().model;
                const caseRequest = convertFromFormModelToCaseRequest(
                    formModel,
                    caseId,
                    individualReportSelectionEnabled
                );

                return caseReportLinksResource
                    .createCaseReportLinksFromCase(caseRequest)
                    .then(() => {
                        closePanel();
                        resetReasonForRelationForm(formContext);
                        dispatch((dispatch, getState) => {
                            caseOnEnter.call(
                                { dispatch, getState },
                                {
                                    params: {
                                        caseId,
                                    },
                                }
                            );
                        });
                    });
            });
        } else {
            return Promise.resolve();
        }
    }, [getForm, caseId, individualReportSelectionEnabled, closePanel, dispatch]);

    return (
        <Modal id={overlayIdEnum.REASON_FOR_RELATION_MODAL} title={strings.title} onSave={onSave}>
            {isLoading ? (
                <SkeletonText noOfLines={3} />
            ) : (
                <ReasonForRelationForm
                    context={RefContextEnum.FORM_REASON_FOR_RELATION_MODAL.name}
                    formLocation={overlayIdEnum.REASON_FOR_RELATION_MODAL}
                />
            )}
        </Modal>
    );
};

export default ReasonForRelationModal;
