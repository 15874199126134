import React, { DragEvent, useCallback } from 'react';
import { Skeleton } from '@arc/skeleton';
import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import invariant from 'invariant';
import { useToast } from 'arc';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { useFetchSVGAsset } from '../hooks';
import { widgetTypes } from '../types';
import { createWidget, formatFileName, getImageDimensions } from '../helpers';
import { useCrashDiagram } from '../context/CrashDiagramContext';
import { DEFAULT_WIDGET_POSITION, STAGE_CENTER } from '../config';
import { logError } from '../../../../../../../core/logging';
import { NoImagePlaceHolder } from './shared';

type WidgetPropsT = {
    src: string;
};

const WidgetWrapper = styled.div`
    width: 100%;
    height: fit-content;
    display: inline-block;
`;

const DiagramAsset = styled(LazyLoadImage)`
    max-width: 85px;
    width: 85px;
    max-height: 70px;
    height: 70px;
`;

const strings = componentStrings.dragon.crashDiagram.CrashDiagramModal;

export const Widget = ({ src }: WidgetPropsT) => {
    const { svgDataUrl, isLoading } = useFetchSVGAsset({
        value: src,
        assetKey: src,
    });

    const toast = useToast();
    const { addWidget } = useCrashDiagram();

    const handleDragStart = (e: DragEvent) => {
        if (e.dataTransfer && svgDataUrl) {
            e.dataTransfer.setData('svgDataUrl', svgDataUrl);
            e.dataTransfer.setData('widgetType', widgetTypes.IMAGE);
            e.dataTransfer.setData('widgetLabel', formatFileName(src));
        }
    };

    const handleClick = useCallback(
        async (svgDataUrl) => {
            try {
                invariant(!!svgDataUrl, 'Failed to get SVG data url for asset.');

                const { width, height } = await getImageDimensions(svgDataUrl);

                const widget = createWidget({
                    type: widgetTypes.IMAGE,
                    value: svgDataUrl,
                    position: {
                        ...DEFAULT_WIDGET_POSITION,
                        x: STAGE_CENTER[0],
                        y: STAGE_CENTER[1],
                        height,
                        width,
                        offsetX: width / 2,
                        offsetY: height / 2,
                    },
                    opacity: 1,
                });

                addWidget({
                    widget,
                });
            } catch (error) {
                toast({
                    status: 'error',
                    description: strings.errorMessages.unhandledWidgetOnClickException(
                        formatFileName(src)
                    ),
                });
                logError('Failed to create widget for asset on click', { error });
            }
        },
        [addWidget, toast, src]
    );

    let children: JSX.Element | null = null;

    if (!svgDataUrl && !isLoading) {
        children = <NoImagePlaceHolder />;
    } else {
        children = (
            <DiagramAsset
                src={svgDataUrl}
                draggable
                onDragStart={handleDragStart}
                onClick={() => handleClick(svgDataUrl)}
                effect="opacity"
            />
        );
    }

    return (
        <Skeleton isLoaded={!isLoading}>
            <WidgetWrapper>{children}</WidgetWrapper>
        </Skeleton>
    );
};
