import {
    CaseReportLinksResource,
    CaseReportLinkCreationFromCaseRequestWrapper,
    CaseReportLinkCreationFromReportRequestWrapper,
    CaseReportLinkRemovalRequest,
    CaseReportLinkMoveRequest,
} from '@mark43/rms-api';
import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

export default createResource({
    name: 'Case Report Links Resource',
    methods: {
        createCaseReportLinksFromCase(data: CaseReportLinkCreationFromCaseRequestWrapper) {
            return req<CaseReportLinksResource.CreateCaseReportLinksFromCase>({
                method: 'POST',
                url: 'case_report_links/case',
                data,
            });
        },
        createCaseReportLinksFromReports(data: CaseReportLinkCreationFromReportRequestWrapper) {
            return req<CaseReportLinksResource.CreateCaseReportLinksFromReports>({
                method: 'POST',
                url: 'case_report_links/reports',
                data,
            });
        },
        createCaseReportLinksFromReportsBatch(
            data: CaseReportLinkCreationFromReportRequestWrapper[]
        ) {
            return req<CaseReportLinksResource.CreateCaseReportLinksFromReportsBatch>({
                method: 'POST',
                url: 'case_report_links/reports/batch',
                data,
            });
        },
        removeFromCase(data: CaseReportLinkRemovalRequest) {
            return req<CaseReportLinksResource.RemoveFromCase>({
                method: 'POST',
                url: 'case_report_links/delete',
                data,
            });
        },
        removeFromCurrentCaseAndCreateNewCase(data: CaseReportLinkMoveRequest) {
            return req<CaseReportLinksResource.RemoveFromCurrentCaseAndCreateNewCase>({
                method: 'POST',
                url: 'case_report_links/move',
                data,
            });
        },
    },
});
