import { AttributeTypeEnum, EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import { filter, get, head, includes, toUpper } from 'lodash';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, withHandlers, withPropsOnChange } from 'recompose';
import { Fieldset as MFTFieldset, Observer } from 'markformythree';
import { Box, cssVar } from 'arc';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import {
    attributeIsOtherSelector,
    parentAttributeIdByAttributeIdSelector,
} from '~/client-common/core/domain/attributes/state/data';
import { vehicleModelsSelector } from '~/client-common/core/domain/vehicle-models/state/data';

import FormRow from '../../../../../core/forms/components/FormRow';
import { SidePanelSection } from '../../../../../../legacy-redux/components/core/SidePanel';
import { ArbiterMFTAttributeSelect } from '../../../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTYearSelect } from '../../../../../core/forms/components/selects/YearSelect';
import { ArbiterMFTDatePicker } from '../../../../../core/forms/components/DatePicker';
import { ArbiterMFTText } from '../../../../../core/forms/components/Text';
import { ArbiterMFTBooleanSelect } from '../../../../../core/forms/components/selects/BooleanSelect';
import { ArbiterMFTVehicleMakeSelect } from '../../../../../core/forms/components/selects/VehicleMakeSelect';
import {
    ArbiterMFTVehicleModelSelect,
    initialVehicleModelRelated,
} from '../../../../../core/forms/components/selects/VehicleModelSelect';
import { InlineAttachmentsEntityProfileUploader } from '../../../../../core/names/components/InlineAttachmentsEntityProfileUploader';
import InlineAttachmentsUploader from '../../../../../attachments/core/components/InlineAttachmentsUploader';
import {
    itemSidePanelVehicleModelRelatedSelector,
    storeItemSidePanelVehicleModelRelated,
    itemSidePanelMasterItemIdSelector,
} from '../../../state/ui';
import withItemCategoryFilterOptions from './withItemCategoryFilterOptions';

const OTHER_OPTION_VALUE = -1;

function VehicleFieldset({
    bodyStyleFilterOptions,
    form,
    helpTextCollisionBoundary,
    itemCategoryFilterOptionsOverride,
    handleVehicleModelChange,
    handleVehicleModelChangeWithModelRelated,
    parentAttributeIdByAttributeId,
    storeItemSidePanelVehicleModelRelated,
    vehicleModelRelated,
    vehicleModelsFromState,
}) {
    const masterVehicleProfileId = useSelector(itemSidePanelMasterItemIdSelector);

    return (
        <SidePanelSection>
            <Box sx={{ display: 'flex', gap: cssVar('arc.space.4') }}>
                <InlineAttachmentsUploader
                    entityId={masterVehicleProfileId}
                    entityType={EntityTypeEnum.ITEM_PROFILE.name}
                    linkType={LinkTypesEnum.ITEM_PROFILE_PHOTO}
                >
                    {(inlineAttachmentsRenderProps) => (
                        <InlineAttachmentsEntityProfileUploader {...inlineAttachmentsRenderProps} />
                    )}
                </InlineAttachmentsUploader>
                <MFTFieldset path="vehicle">
                    <ArbiterMFTText
                        alterValue={toUpper}
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="vinNumber"
                    />
                    <ArbiterMFTText
                        alterValue={toUpper}
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="tag"
                    />
                </MFTFieldset>
            </Box>
            <MFTFieldset path="vehicle">
                <ArbiterMFTAttributeSelect
                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                    path="itemCategoryAttrId"
                    attributeType={AttributeTypeEnum.ITEM_CATEGORY.name}
                    filterOptions={itemCategoryFilterOptionsOverride}
                />
                <Observer
                    subscriptions={{
                        itemCategoryAttrId: 'vehicle.itemCategoryAttrId',
                    }}
                    render={({ itemCategoryAttrId }) => (
                        <>
                            <ArbiterMFTAttributeSelect
                                helpTextCollisionBoundary={helpTextCollisionBoundary}
                                path="vehicleMakeAttrId"
                                attributeType={AttributeTypeEnum.VEHICLE_MAKE.name}
                                grouped={!itemCategoryAttrId}
                                showSelectedGroup={false}
                                filterOptions={(options) => {
                                    const vehicleMakeAttrId = form.get('vehicle.vehicleMakeAttrId');
                                    if (!itemCategoryAttrId) {
                                        return options;
                                    } else {
                                        return filter(
                                            options,
                                            ({ value }) =>
                                                parentAttributeIdByAttributeId(value) ===
                                                    itemCategoryAttrId ||
                                                // show current value no matter what
                                                value === vehicleMakeAttrId
                                        );
                                    }
                                }}
                                onChange={(vehicleMakeAttrId) => {
                                    form.set('vehicle.vehicleMakeAttrId', vehicleMakeAttrId);

                                    // autofill parent
                                    if (!itemCategoryAttrId) {
                                        form.set(
                                            'vehicle.itemCategoryAttrId',
                                            parentAttributeIdByAttributeId(vehicleMakeAttrId)
                                        );
                                        form.resetUi('vehicle.itemCategoryAttrId');
                                    }

                                    // reset vehicle model when this new vehicle make is no longer
                                    // its parent
                                    const vehicleModelAttrId = form.get(
                                        'vehicle.vehicleModelAttrId'
                                    );
                                    if (
                                        !!vehicleMakeAttrId &&
                                        !!vehicleModelAttrId &&
                                        vehicleMakeAttrId !==
                                            parentAttributeIdByAttributeId(vehicleModelAttrId)
                                    ) {
                                        form.resetUi('vehicle.vehicleModelAttrId');
                                        form.set('vehicle.vehicleModelAttrId', undefined);
                                    }
                                }}
                                length="md"
                            />
                        </>
                    )}
                />
                <FormRow>
                    <Observer
                        subscriptions={{
                            itemCategoryAttrId: 'vehicle.itemCategoryAttrId',
                        }}
                        render={({ itemCategoryAttrId }) => (
                            <ArbiterMFTVehicleMakeSelect
                                path="vehicleMakeId"
                                length="md"
                                helpTextCollisionBoundary={helpTextCollisionBoundary}
                                includeOtherOption={true}
                                itemCategoryAttrId={itemCategoryAttrId}
                                vehicleMake={vehicleModelRelated.vehicleMake}
                                onChange={(vehicleMakeId) => {
                                    const vehicleModelId = form.get('vehicle.vehicleModelId');
                                    if (
                                        vehicleMakeId === OTHER_OPTION_VALUE &&
                                        vehicleModelId !== OTHER_OPTION_VALUE
                                    ) {
                                        // when Make becomes Other, Model must become Other too because every
                                        // Model (which is not Other) is linked to a Make
                                        form.resetUi('vehicle.vehicleModelId');
                                        form.set('vehicle.vehicleModelId', OTHER_OPTION_VALUE);
                                    } else if (
                                        !!vehicleModelId &&
                                        (!vehicleMakeId ||
                                            vehicleMakeId !==
                                                get(
                                                    vehicleModelsFromState[vehicleModelId],
                                                    'vehicleMakeId'
                                                ))
                                    ) {
                                        // Reset Vehicle Model when this newly selected Make is no longer linked to
                                        // the Model. This includes when no Make is selected.
                                        form.resetUi('vehicle.vehicleModelId');
                                        form.set('vehicle.vehicleModelId', undefined);
                                    }

                                    storeItemSidePanelVehicleModelRelated(
                                        initialVehicleModelRelated
                                    );
                                }}
                            />
                        )}
                    />
                    <ArbiterMFTText
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="itemMake"
                        length="md"
                    />
                </FormRow>
                <FormRow>
                    <Observer
                        subscriptions={{
                            itemCategoryAttrId: 'vehicle.itemCategoryAttrId',
                            vehicleMakeAttrId: 'vehicle.vehicleMakeAttrId',
                        }}
                        render={({ itemCategoryAttrId, vehicleMakeAttrId }) => (
                            <ArbiterMFTAttributeSelect
                                helpTextCollisionBoundary={helpTextCollisionBoundary}
                                path="vehicleModelAttrId"
                                attributeType={AttributeTypeEnum.VEHICLE_MODEL.name}
                                grouped={!vehicleMakeAttrId}
                                showSelectedGroup={false}
                                filterOptions={(options) => {
                                    const vehicleModelAttrId = form.get(
                                        'vehicle.vehicleModelAttrId'
                                    );
                                    if (!itemCategoryAttrId && !vehicleMakeAttrId) {
                                        return options;
                                    } else if (!vehicleMakeAttrId) {
                                        return filter(
                                            options,
                                            ({ value }) =>
                                                parentAttributeIdByAttributeId(
                                                    parentAttributeIdByAttributeId(value)
                                                ) === itemCategoryAttrId ||
                                                // show current value no matter what
                                                value === vehicleModelAttrId
                                        );
                                    } else {
                                        return filter(
                                            options,
                                            ({ value }) =>
                                                parentAttributeIdByAttributeId(value) ===
                                                    vehicleMakeAttrId ||
                                                // show current value no matter what
                                                value === vehicleModelAttrId
                                        );
                                    }
                                }}
                                onChange={(vehicleModelAttrId) => {
                                    form.set('vehicle.vehicleModelAttrId', vehicleModelAttrId);

                                    // autofill parent
                                    if (!vehicleMakeAttrId) {
                                        form.set(
                                            'vehicle.vehicleMakeAttrId',
                                            parentAttributeIdByAttributeId(vehicleModelAttrId)
                                        );
                                        form.resetUi('vehicle.vehicleMakeAttrId');
                                    }

                                    // autofill grandparent
                                    if (!itemCategoryAttrId) {
                                        form.set(
                                            'vehicle.itemCategoryAttrId',
                                            parentAttributeIdByAttributeId(
                                                parentAttributeIdByAttributeId(vehicleModelAttrId)
                                            )
                                        );
                                        form.resetUi('vehicle.itemCategoryAttrId');
                                    }
                                }}
                                length="md"
                            />
                        )}
                    />
                    <Observer
                        subscriptions={{
                            itemCategoryAttrId: 'vehicle.itemCategoryAttrId',
                            vehicleMakeId: 'vehicle.vehicleMakeId',
                        }}
                        render={({ itemCategoryAttrId, vehicleMakeId }) => (
                            <ArbiterMFTVehicleModelSelect
                                path="vehicleModelId"
                                length="md"
                                helpTextCollisionBoundary={helpTextCollisionBoundary}
                                includeOtherOption={true}
                                itemCategoryAttrId={itemCategoryAttrId}
                                vehicleMakeId={vehicleMakeId}
                                onChange={handleVehicleModelChange}
                                onChangeWithVehicleModelRelated={
                                    handleVehicleModelChangeWithModelRelated
                                }
                            />
                        )}
                    />
                    <ArbiterMFTText
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="itemModel"
                        length="md"
                    />
                </FormRow>
                <ArbiterMFTText
                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                    path="description"
                />
            </MFTFieldset>
            <MFTFieldset path="attributeLinks">
                <FormRow>
                    <ArbiterMFTAttributeSelect
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="vehicleUseAttrId"
                        attributeType={AttributeTypeEnum.QC_EMERGENCY_MOTOR_VEHICLE_USE.name}
                        length="lg"
                    />
                </FormRow>
            </MFTFieldset>
            <MFTFieldset path="vehicle">
                <ArbiterMFTYearSelect
                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                    path="yearOfManufacture"
                    length="md"
                />
                <FormRow>
                    <ArbiterMFTAttributeSelect
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="primaryColorAttrId"
                        attributeType={AttributeTypeEnum.ITEM_COLOR.name}
                        length="md"
                    />
                    <ArbiterMFTAttributeSelect
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="secondaryColorAttrId"
                        attributeType={AttributeTypeEnum.ITEM_COLOR.name}
                        length="md"
                    />
                </FormRow>
            </MFTFieldset>
            <MFTFieldset path="attributeLinks">
                <FormRow>
                    <ArbiterMFTAttributeSelect
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="vehicleAdditionalSecondaryColorAttrIds"
                        attributeType={AttributeTypeEnum.ITEM_COLOR.name}
                        length="lg"
                        multiple
                    />
                </FormRow>
            </MFTFieldset>
            <MFTFieldset path="vehicle">
                <FormRow>
                    <ArbiterMFTAttributeSelect
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="bodyStyleAttrId"
                        attributeType={AttributeTypeEnum.VEHICLE_BODY_STYLE.name}
                        filterOptions={bodyStyleFilterOptions}
                        length="md"
                    />
                    <ArbiterMFTText
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="bodyStyleOther"
                        length="md"
                    />
                </FormRow>
                <FormRow>
                    <ArbiterMFTYearSelect
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="registrationYear"
                        length="md"
                    />
                    <ArbiterMFTAttributeSelect
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="registrationStateAttrId"
                        attributeType={AttributeTypeEnum.STATE.name}
                        length="md"
                    />
                    <ArbiterMFTText
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="registrationType"
                        length="md"
                    />
                </FormRow>
                <ArbiterMFTText
                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                    path="mileage"
                    length="md"
                />
                <FormRow>
                    <ArbiterMFTText
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="insuranceProviderName"
                        length="md"
                    />
                    <ArbiterMFTText
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="insurancePolicyNumber"
                        length="md"
                    />
                </FormRow>
                <FormRow>
                    <ArbiterMFTText
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="insuranceCode"
                    />
                </FormRow>
                <FormRow>
                    <ArbiterMFTDatePicker
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="dateInsuranceExpiration"
                        includeTime={false}
                    />
                </FormRow>
                <FormRow>
                    <ArbiterMFTBooleanSelect
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="wasAntifreezeInVehicle"
                        length="sm"
                    />
                    <ArbiterMFTBooleanSelect
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="wasRadiatorTaggedAndDrained"
                        length="sm"
                    />
                </FormRow>
            </MFTFieldset>
        </SidePanelSection>
    );
}

/**
 * Fieldset in the item entry v2 form that appears only when the item type is vehicle. It includes
 *   fields from the Vehicle and ItemProfile models.
 */
export default compose(
    connect(
        createStructuredSelector({
            attributeIsOther: attributeIsOtherSelector,
            parentAttributeIdByAttributeId: parentAttributeIdByAttributeIdSelector,
            vehicleModelsFromState: vehicleModelsSelector,
            vehicleModelRelated: itemSidePanelVehicleModelRelatedSelector,
        }),
        { storeItemSidePanelVehicleModelRelated }
    ),
    withItemCategoryFilterOptions(globalAttributes.itemType.vehicle),
    withPropsOnChange(
        ['vehicleModelRelated'],
        ({ vehicleModelRelated: { bodyStyleAttrIds, itemCategoryAttrIds } }) => ({
            modelRelatedBodyStylesIsEmpty: !bodyStyleAttrIds || bodyStyleAttrIds.length < 1,
            modelRelatedItemCategoryIsEmpty: !itemCategoryAttrIds || itemCategoryAttrIds.length < 1,
        })
    ),
    withHandlers({
        bodyStyleFilterOptions({
            attributeIsOther,
            form,
            modelRelatedBodyStylesIsEmpty,
            parentAttributeIdByAttributeId,
            vehicleModelRelated: { bodyStyleAttrIds },
        }) {
            return (options) => {
                if (modelRelatedBodyStylesIsEmpty) {
                    const bodyStyleAttrId = form.get('vehicle.bodyStyleAttrId');
                    const itemCategoryAttrId = form.get('vehicle.itemCategoryAttrId');

                    return filter(
                        options,
                        ({ value }) =>
                            parentAttributeIdByAttributeId(value) === itemCategoryAttrId ||
                            // show current value no matter what
                            value === bodyStyleAttrId
                    );
                }

                return filter(
                    options,
                    ({ value }) => includes(bodyStyleAttrIds, value) || attributeIsOther(value)
                );
            };
        },
        handleVehicleModelChange({
            form,
            storeItemSidePanelVehicleModelRelated,
            vehicleModelRelated: { vehicleMake },
        }) {
            return (value) => {
                if (!value) {
                    form.transaction(() => {
                        form.set('vehicle.vehicleModelHasBodyStyleOptions', false);
                        form.set('vehicle.bodyStyleAttrId', undefined);
                        form.set('vehicle.bodyStyleOther', undefined);
                    });

                    // vehicleMakeSelect requires vehicleMake to display the persisted Make
                    storeItemSidePanelVehicleModelRelated({
                        ...initialVehicleModelRelated,
                        vehicleMake,
                    });
                }
            };
        },
        handleVehicleModelChangeWithModelRelated({ form, storeItemSidePanelVehicleModelRelated }) {
            return (vehicleModelRelated) => {
                const { bodyStyleAttrIds, itemCategoryAttrIds, vehicleMake } = vehicleModelRelated;
                const itemCategoryAttrId = form.get('vehicle.itemCategoryAttrId');
                const vehicleMakeId = form.get('vehicle.vehicleMakeId');

                form.transaction(() => {
                    if (!vehicleMakeId && !!vehicleMake) {
                        form.set('vehicle.vehicleMakeId', vehicleMake.id);
                    }

                    if (itemCategoryAttrIds.length === 1) {
                        form.set('vehicle.itemCategoryAttrId', head(itemCategoryAttrIds));
                    } else if (
                        itemCategoryAttrIds.length > 0 &&
                        !includes(itemCategoryAttrIds, itemCategoryAttrId)
                    ) {
                        form.set('vehicle.itemCategoryAttrId', undefined);
                    }

                    form.set(
                        'vehicle.vehicleModelHasBodyStyleOptions',
                        bodyStyleAttrIds.length > 0
                    );
                    form.set('vehicle.bodyStyleAttrId', undefined);
                    form.set('vehicle.bodyStyleOther', undefined);
                });

                storeItemSidePanelVehicleModelRelated(vehicleModelRelated);
            };
        },
        itemCategoryFilterOptionsOverride({
            modelRelatedItemCategoryIsEmpty,
            itemCategoryFilterOptions,
            vehicleModelRelated: { itemCategoryAttrIds },
        }) {
            if (modelRelatedItemCategoryIsEmpty) {
                return itemCategoryFilterOptions;
            }

            return (options) =>
                filter(options, ({ value }) => includes(itemCategoryAttrIds, value));
        },
    })
)(VehicleFieldset);
