import React, { FC } from 'react';
import { Text } from 'arc';
import styled from 'styled-components';
import { ProfileView } from '@mark43/rms-api';
import { formatName } from '~/client-common/core/domain/mini-users/utils/miniUsersHelpers';
import { Tooltip } from '../../../core/components/tooltip';

const VISIBLE_PROFILES_NUM = 3;

const convertProfileViewToElement = ({ firstName, lastName }: ProfileView, index: number) => (
    <div key={index}>{formatName({ first: firstName, last: lastName })}</div>
);

const MoreProfiles = styled(Text)`
    cursor: default;
`;

export const CaseEFileDefendantsCell: FC<{ defendants: ProfileView[] }> = ({ defendants }) => {
    const visibleDefs = defendants.slice(0, VISIBLE_PROFILES_NUM);
    const otherDefs = defendants.slice(VISIBLE_PROFILES_NUM);

    const visibleDefElements = visibleDefs.map(convertProfileViewToElement);

    return (
        <>
            {visibleDefElements}
            {otherDefs.length > 0 && (
                <Tooltip
                    content={
                        <>
                            {visibleDefElements}
                            {otherDefs.map(convertProfileViewToElement)}
                        </>
                    }
                >
                    <MoreProfiles variant="bodyMd">+{otherDefs.length}</MoreProfiles>
                </Tooltip>
            )}
        </>
    );
};
