import { AttributeTypeEnum, EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import { toUpper } from 'lodash';
import React from 'react';
import { Box, cssVar } from 'arc';
import { useSelector } from 'react-redux';

import { Fieldset as MFTFieldset } from 'markformythree';
import componentStrings from '~/client-common/core/strings/componentStrings';

import FormRow from '../../../../../core/forms/components/FormRow';
import { ArbiterMFTAttributeSelect } from '../../../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTYearSelect } from '../../../../../core/forms/components/selects/YearSelect';
import { SidePanelSection } from '../../../../../../legacy-redux/components/core/SidePanel';
import { ArbiterMFTText } from '../../../../../core/forms/components/Text';
import { HelpTextProps } from '../../../../../../legacy-redux/components/core/HelpText';
import { InlineAttachmentsEntityProfileUploader } from '../../../../../core/names/components/InlineAttachmentsEntityProfileUploader';
import InlineAttachmentsUploader from '../../../../../attachments/core/components/InlineAttachmentsUploader';
import { itemSidePanelMasterItemIdSelector } from '../../../state/ui';

type VehicleRegistrationFieldsetPropsT = {
    helpTextCollisionBoundary: HelpTextProps['collisionBoundary'];
};

const vehicleFieldsetStrings = componentStrings.search.fieldsets.VehicleFieldset;

export const VehicleRegistrationFieldset: React.FC<VehicleRegistrationFieldsetPropsT> = ({
    helpTextCollisionBoundary,
}) => {
    const masterVehicleProfileId = useSelector(itemSidePanelMasterItemIdSelector);

    return (
        <SidePanelSection title={vehicleFieldsetStrings.sections.vehicleRegistration}>
            <Box sx={{ display: 'flex', gap: cssVar('arc.space.4') }}>
                <InlineAttachmentsUploader
                    entityId={masterVehicleProfileId}
                    entityType={EntityTypeEnum.ITEM_PROFILE.name}
                    linkType={LinkTypesEnum.ITEM_PROFILE_PHOTO}
                >
                    {/* @ts-expect-error: InlineAttachmentsUploader is not typed yet */}
                    {(inlineAttachmentsRenderProps) => (
                        <InlineAttachmentsEntityProfileUploader {...inlineAttachmentsRenderProps} />
                    )}
                </InlineAttachmentsUploader>
                <MFTFieldset path="vehicle">
                    <FormRow>
                        <ArbiterMFTText
                            alterValue={toUpper}
                            helpTextCollisionBoundary={helpTextCollisionBoundary}
                            path="vinNumber"
                        />
                        <ArbiterMFTText
                            alterValue={toUpper}
                            helpTextCollisionBoundary={helpTextCollisionBoundary}
                            path="tag"
                        />
                    </FormRow>
                </MFTFieldset>
            </Box>
            <MFTFieldset path="vehicle">
                <FormRow>
                    <ArbiterMFTYearSelect
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="registrationYear"
                        length="md"
                    />
                    <ArbiterMFTAttributeSelect
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="registrationStateAttrId"
                        length="md"
                        attributeType={AttributeTypeEnum.STATE.name}
                    />
                </FormRow>
                <ArbiterMFTText
                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                    path="registrationType"
                />
            </MFTFieldset>
            <MFTFieldset path="attributeLinks">
                <FormRow>
                    <ArbiterMFTAttributeSelect
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="vehicleUseAttrId"
                        attributeType={AttributeTypeEnum.QC_EMERGENCY_MOTOR_VEHICLE_USE.name}
                    />
                </FormRow>
            </MFTFieldset>
        </SidePanelSection>
    );
};
