import pluralize from 'pluralize';
import {
    ApprovalStatusForCaseEnum,
    AttributeTypeEnum,
    ChainEventCategoryEnum,
    DeduplicatePersonProfileDecisionEnum,
    DepartmentProfile,
    DispositionApprovalLevelEnum,
    DispositionApprovalTypeEnum,
    ElasticSearchTypeEnum,
    EntityTypeEnum,
    EntityTypeEnumType,
    ExportReportVersionEnum,
    InventoryTypeEnum,
    ItemInvolvementTypeEnum,
    LabelPrintMethodEnum,
    LinkTypesEnum,
    MentionCategoryEnum,
    NibrsOffenseGroupEnum,
    OfficerInvolvementEnum,
    OperationTypeEnum,
    PersonsSearchResultsDefaultEnum,
    ProductModuleEnum,
    UcrSummaryOffensePartEnum,
    UsageActionEnum,
    UsageCompletionEnum,
    UsageSourceApplicationEnum,
    UsageSourceModuleEnum,
} from '@mark43/rms-api';
import approvalStatusClientEnum from '../enums/client/approvalStatusClientEnum';
import caseDefaultTaskAssigneeTypeEnum from '../enums/client/caseDefaultTaskAssigneeTypeEnum';
import reportApprovalLevelClientEnum from '../enums/client/reportApprovalLevelClientEnum';
import globalAttributes from '../legacy-constants/globalAttributes';
import { lastModified, prettify, lastModifiedAndSynced } from '../../helpers/stringHelpers';
import { MentionCategoryType } from '../../../modules/core/editor/plugins/mentions/briefing/types';

export default {
    admin: {
        alerts: {
            AlertAdminForm: {
                newAlert: 'New Alert',
                footer: {
                    lastModified,
                },
            },
            AlertsAdmin: {
                newAlert: 'New Alert',
                title: 'Manage Alerts',
                allAlerts: 'All Alerts',
            },
        },
        attributes: {
            AttributesAdminForm: {
                labels: {
                    displayValue: 'Attribute Name',
                    displayAbbreviation: 'Code',
                    inAppDescription: 'Description',
                    attributeType: 'Attribute Type',
                    parentAttributeId: 'Parent Attribute Type',
                    startDateUtc: 'Active Date / Time',
                    endDateUtc: 'Expiration Date / Time',
                    isOther: 'Is Other',
                    isNone: 'Is None',
                    isDefault: 'Is Default',
                    isEnabled: 'Is Enabled',
                    isProtected: 'Is Protected',
                    linkType: 'Link Type',
                },
                helpText: {
                    isOther:
                        "Selecting an 'Is Other' attribute will display a free-text explanation field in most contexts.",
                    isNone: "An 'Is None' attribute will be mutually exclusive with other attributes in that field",
                    isDefault:
                        "An 'Is Default' attribute will be the default value of any form field with the corresponding attribute type.",
                    isEnabled:
                        'An enabled attribute/offense code will appear as a value in search and filter inputs (advanced search, dashboards).',
                    isProtected:
                        "An 'Is Protected' subdivision attribute will not be expired automatically by uploading new subdivisions if no longer used.",
                },
                displayAbbreviationTakenError: 'Code is already in use',
                displayNameDisabledNotice:
                    'To avoid modifying previously created reports, Attribute Name is not editable. To prevent this Attribute Name from being used, expire it in the Expiration Date/ Time field, and create a new Attribute.',
                requiredError: 'This field is required',
                endDateUtcRequiredWhenDisabled:
                    'A disabled attribute must have an expiration date in the past set.',
            },
            footer: {
                lastModified,
            },
            title: 'Attributes',
            newAttribute: 'New Attribute',
            selectAttributeError: 'Failed to select attribute',
            groupTitle: 'Attribute Types',
            showExpired: 'show expired/disabled',
            linkToCodeTypes: (attributeType: string) => `Code Type Mappings (${attributeType})`,
        },
        attributeTypeCodeTypeLinks: {
            AttributeTypeCodesTypeLinksForm: {
                labels: {
                    add: 'Add',
                    codeTypeId: 'Code Type',
                    codeTypeSource: 'Code Type Source',
                    requiresMapping: 'Required',
                    fieldsetTitle: 'Attribute Code Type Mappings',
                },
            },
            title: 'Attribute Code Type Mappings',
            searchTitle: 'Attribute Types',
            linkToAttributes: (attributeType: string) => `Attributes (${attributeType})`,
        },
        boloTypeAdmin: {
            formLabels: {
                name: 'Name',
                priority: 'Priority',
                autoExpireAfter: 'Auto Expire After',
                resetAutoExpiration: 'Reset Auto Expiration upon each update',
                activeDate: 'Active Date',
                expirationDate: 'Expiration Date',
            },
            subheaderTitle: 'BOLO',
            adminListTitle: 'BOLO',
            adminHeaderNewBoloTypeTitle: 'New BOLO Type',
            adminHeaderNoBoloTypeTitle: '',
            addBoloTypeButtonText: 'New BOLO Type',
            validation: {
                name: 'BOLO description required',
                priority: 'Must select a Priority',
                activeDateUtc: 'Active date is required',
                expirationDateUtc: 'Expiration date must be later than active date',
                autoExpire: 'Must be a positive number',
            },
        },
        bulkEntityPermissions: {
            BulkEntityPermissionsAdmin: {
                title: 'Departments',
            },
            BulkEntityPermissionsAdminForm: {
                requiredError: 'Required',
                grantAccess: 'Grant Access',
                notice: 'Please alert RMS engineers when using this page, since it is an expensive operation that may impact the stability of the whole environment. Typically, this page is used when setting up a new consortium. The engineers should monitor SignalFX (passively, not necessarily in real time), and query the database to determine the current progress and whether it succeeded.',
                currentDeptHelpText: (deptName: string) =>
                    `You are configuring bulk permissions for roles in ${deptName}. Select the role and access level desired for a given entity type. Check the "Set from a specific date?" checkbox if you wish to set a specific date range for the permission updates.`,
                externalDeptMainHelpText: ({
                    externalDeptName,
                    currentDeptName,
                }: {
                    externalDeptName: string;
                    currentDeptName: string;
                }) =>
                    `You are configuring bulk permissions for roles in ${externalDeptName}. If there are multiple agencies in the consortium, you are configuring the level of access that other agencies have to ${currentDeptName}. Example: if you are currently logged into ${currentDeptName} and this is the page for ${externalDeptName}, you are configuring the access that ${externalDeptName} has to ${currentDeptName}.`,
            },
            EntityPermissionRow: {
                externalDeptEntityHelpText:
                    'Note: You will need to run a search sync for these permissions to be granted. Only the Department role permissions can be set in consortium set ups from this page.',
                linkClose: ')',
                labels: {
                    userRole: 'User / Role',
                    currentDeptAccessLevel: 'Access Level',
                    externalDeptAccessLevel: 'Department Role Access',
                    isStartDateRequired: 'Set from a specific date?',
                    isStartDateRequiredHelpText:
                        'If not checked, permissions are set for all dates in the past until present day',
                    createdDate: 'Entity Created Date',
                    present: '- Present',
                },
                [EntityTypeEnum.REPORT.name]: {
                    title: 'Reports',
                    linkOpen: '(Report Types Admin Page Link',
                    helpText:
                        'Note: Be sure to check the Report Types admin page to ensure that each report will have at least "Can Manage" when making bulk updates. You will need to run a search sync for the permissions to go into effect.',
                },
                [EntityTypeEnum.CASE.name]: {
                    title: 'Cases',
                    linkOpen: '(Cases Types Admin Page Link',
                    helpText:
                        'Note: Be sure to check the Cases Types admin page to ensure that each report will have at least "Can Manage" when making bulk updates. You will need to run a search sync for the permissions to go into effect.',
                },
                [EntityTypeEnum.PERSON_PROFILE.name]: {
                    title: 'Persons',
                    linkOpen: '(Master Entities Admin Page Link',
                    helpText:
                        'Note: Be sure to check the Master Entities admin page to ensure that each report will have at least "Can Manage" when making bulk updates. You will need to run a search sync for the permissions to go into effect.',
                },
                [EntityTypeEnum.ORGANIZATION_PROFILE.name]: {
                    title: 'Organizations',
                    linkOpen: '(Master Entities Admin Page Link',
                    helpText:
                        'Note: Be sure to check the Master Entities admin page to ensure that each report will have at least "Can Manage" when making bulk updates. You will need to run a search sync for the permissions to go into effect.',
                },
                [EntityTypeEnum.ITEM_PROFILE.name]: {
                    title: 'Items',
                    linkOpen: '(Master Entities Admin Page Link',
                    helpText:
                        'Note: Be sure to check the Master Entities admin page to ensure that each report will have at least "Can Manage" when making bulk updates. You will need to run a search sync for the permissions to go into effect.',
                },
            },
            GrantRequestStatus: {
                title: 'Job Status',
                refreshStatus: 'Refresh Status',
                jobId: 'Job ID',
                processed: 'Processed',
                error: 'Errored',
                totalCount: 'Total Items',
                status: 'Status',
            },
        },
        cadCsvUpload: { CsvErrorTable: { noResults: 'No Errors' } },
        cadUnitsLogon: { BulkLogOnErrorTable: { noResults: 'No Errors' } },
        consortium: {
            ConsortiumAdminForm: {
                singleConsortium: 'A department can only belong to one consortium at a time.',
                noAccess: 'Access to this consortium is restricted.',
                createConsortiumNote: (departmentName: string) =>
                    `NOTE: When creating a new consortium, your department (${departmentName}) will automatically be added to it.`,
            },
            ConsortiumAdminRemoveDepartmentModal: {
                modalContent:
                    'Remove Department will remove department from consortium. All sharing settings will be lost.',
                modalOk: 'Confirm',
                modalTitle: 'Remove Department',
            },
            ConsortiumAdminAddDepartmentModal: {
                modalContent: ({
                    departmentName,
                    consortiumName,
                    departmentStatus,
                }: {
                    departmentName: string;
                    consortiumName: string;
                    departmentStatus: string;
                }) =>
                    `Warning: ${departmentName} has a status of ${departmentStatus} which is different than at least one of the existing departments in ${consortiumName}`,
                modalOk: 'Confirm',
                modalTitle: 'Inconsistent Department Status',
            },
            ConsortiumAdminEditDepartmentForm: {
                noAccessToConsortium: 'Access to this consortium is restricted.',
                noAccessToDepartment: 'This department does not belong to your consortium.',
                noteTitle: (departmentName: string) =>
                    `Set ${departmentName}'s Access to Other Departments`,
                formDescription: (departmentName: string) =>
                    `Set the access level that ${departmentName} will have to other departments in the consortium. This is a one time action, and will require running a search sync for the department(s) whose data has now become findable/viewable from ${departmentName} when Sync Progress returns a Current Status of "Cleared". To view permissions once set, navigate to particular entities page (e.g. for Report permissions navigate to Report Types Admin page).`,
                grantAccessButton: 'Grant Access',
                accessLevelLabel: 'Access Level (Cannot be reduced from "Can View" to "Can Find")',
                accessLevelRequiredError: 'Access Level must be set for each department.',
                noAccessLabel: 'No Additional Access',
                refreshStatus: 'Refresh Status',
                syncProgress: 'Sync Progress',
                departmentLabel: 'Department',
                statusLabel: 'Current Status',
            },
            ConsortiumAdmin: {
                newConsortium: 'New Consortium',
                editConsortium: 'Edit Consortium',
                deleteConsortium: 'Delete Consortium',
                addDepartment: 'Add Department',
                listTitle: 'Departments',
            },
            ConsortiumAdminAddDepartmentForm: {
                timeZoneError:
                    'WARNING: Certain date-based searches may not work well if departments are in multple time zones.',
                requiredError: 'Required',
                departmentIdFieldName: 'Department Name',
                noAccess: 'Access to this consortium is restricted.',
            },
        },
        diagramAssets: {
            diagramTypes: 'Diagram Types',
            categories: 'Diagram Categories',
            categoryAssets: 'Category Assets',
            addNewAsset: 'New Asset',
            errors: {
                failedToFetchAssets: 'Failed to fetch diagram category assets',
            },
        },
        diagramAssetsAdminForm: {
            labels: {
                assetName: 'Asset Name',
                assetFile: 'Image',
                uploadImage: 'Upload Asset',
                isGlobal: 'Is Global',
            },
            helpText: {
                isGlobal: 'Marking an asset as global will allow it to be used by ALL departments',
            },
        },
        disclaimerMessages: {
            subheaderTitle: 'Disclaimer Messages',
            listTitle: 'Disclaimer Messages',
            newDisclaimerButton: 'New Disclaimer',
            newDisclaimerTitle: 'New Disclaimer',
            addingForbiddenTooltip: 'Only 1 Disclaimer Message permitted',
            removeDisclaimerButton: 'Remove',
        },
        exportReleaseSettings: {
            ExportReleaseSettingsAdmin: {
                exportReleaseSettings: 'Export Release Settings',
                releaseType: 'Release Type',
            },
        },
        hospitalAdmin: {
            subheaderTitle: 'Hospital List',
            adminListTitle: 'HOSPITAL',
            adminHeaderNewHospitalTitle: 'New Hospital',
            adminHeaderNoHospitalTitle: '',
            addHospitalButtonText: 'New Hospital',
            historyButtonEditAriaLabel: 'Edit Hospital',
            historyButtonShowHistoryAriaLabel: 'Show History for Hospital',
            importExport: {
                importButtonText: 'Import Hospitals',
                importModalTitle: 'Import Hospitals',
                importModalConfirm: 'OK',
                exportTitle: 'Export Hospitals',
            },
            validation: {
                name: 'Hospital name required',
                status: 'Must select a status',
                location: 'Location is required',
                activeDateUtc: 'Active date is required',
                expirationDateUtc: 'Expiration date must be later than active date',
            },
            addLocationButtonText: 'Add Location',
        },
        usageLogs: {
            UsageLogsAdmin: {
                noResults: 'At least one result must be returned in order to export',
                noUser: 'No User',
                title: 'Usage Logs',
                userUnknown: 'User Unknown',
                export: 'Export',
                columns: {
                    user: {
                        label: 'User',
                    },
                    primaryEntity: {
                        label: 'Entity',
                    },
                    source: {
                        label: 'Source',
                    },
                    usageLogDateUtc: {
                        label: 'Date/Time',
                    },
                    usageAction: {
                        label: 'Action',
                    },
                    usageCompletion: {
                        label: 'Completion',
                    },
                    ipAddress: {
                        label: 'IP Address',
                    },
                },
                fields: {
                    userId: {
                        label: 'Acting User',
                    },
                    primaryEntityType: {
                        label: 'Entity Type',
                    },
                    primaryEntityId: {
                        label: 'Entity ID',
                    },
                    completion: {
                        label: 'Completion',
                    },
                    action: {
                        label: 'Action',
                    },
                    usageLogStartDateUtc: {
                        label: 'Date/Time Range',
                        helpText: 'Must be less than 4 months',
                    },
                    usageLogEndDateUtc: {
                        label: 'End Date',
                    },
                    excludedIpAddresses: {
                        label: 'Excluded IP',
                    },
                    departmentIds: {
                        label: 'Agency', // this is how we communicate multi-tenant consortium
                    },
                },
                usageAction: {
                    [UsageActionEnum.EXPORTED_CASE_JACKET_PDF.name]: 'Exported Case Jacket PDF',
                    [UsageActionEnum.EXPORTED_CASE_NOTE_PDF.name]: 'Exported Case Note PDF',
                    [UsageActionEnum.VIEWED_CASE.name]: 'Viewed Case',
                    [UsageActionEnum.VIEWED_CASE_HISTORY.name]: 'Viewed Case History',
                    [UsageActionEnum.VIEWED_CASE_LINKED_PROFILE.name]: 'Viewed Case Profiles Tab',
                    [UsageActionEnum.VIEWED_CASE_NOTE.name]: 'Viewed Case Note',
                    [UsageActionEnum.VIEWED_CASE_NOTES_LIST.name]: 'Viewed Case Notes Tab',
                    [UsageActionEnum.VIEWED_CASE_SUMMARY.name]: 'Viewed Case Summary',
                    [UsageActionEnum.VIEWED_CASE_TASK_LIST.name]: 'Viewed Case Tasks Tab',
                    [UsageActionEnum.VIEWED_CASE_ATTACHMENTS.name]: 'Viewed Case Attachments Tab',
                    [UsageActionEnum.VIEWED_CASE_ASSOCIATED_RECORDS.name]:
                        'Viewed Case Associated Records Tab',
                    [UsageActionEnum.WARRANTS_DASHBOARD_VIEWED.name]: 'Viewed Warrants Dashboard',
                    [UsageActionEnum.WARRANT_RECORD_VIEWED.name]: 'Viewed Warrant Record',
                    [UsageActionEnum.EXPORTED_WARRANT.name]: 'Exported Warrant PDF',
                    [UsageActionEnum.VIEWED_REPORT.name]: 'Viewed Report',
                    [UsageActionEnum.EXPORTED_REPORT.name]: 'Exported Report PDF',
                    [UsageActionEnum.VIEWED_ORGANIZATION_PROFILE.name]:
                        'Viewed Organization Profile',
                    [UsageActionEnum.VIEWED_VEHICLE_PROFILE.name]: 'Viewed Vehicle Profile',
                    [UsageActionEnum.VIEWED_PROPERTY_PROFILE.name]: 'Viewed Property Profile',
                    [UsageActionEnum.VIEWED_PERSON_PROFILE.name]: 'Viewed Person Profile',
                    [UsageActionEnum.EXPORTED_PERSON_PROFILE.name]: 'Exported Person Profile PDF',
                    [UsageActionEnum.VIEWED_REPORTS_DASHBOARD.name]: 'Viewed Reports Dashboard',
                    [UsageActionEnum.VIEWED_REPORT_HISTORY.name]: 'Viewed Report History',
                    [UsageActionEnum.VIEWED_UNASSIGNED_CASES_DASHBOARD.name]:
                        'Viewed Unassigned Reports Dashboard',
                    [UsageActionEnum.VIEWED_MY_CASES_DASHBOARD.name]: 'Viewed My Cases Dashboard',
                    [UsageActionEnum.VIEWED_ALL_CASES_DASHBOARD.name]: 'Viewed All Cases Dashboard',
                    [UsageActionEnum.DOWNLOADED_BULK_ATTACHMENTS.name]:
                        'Downloaded Bulk Attachments',
                    [UsageActionEnum.DOWNLOADED_ATTACHMENT.name]: 'Downloaded Attachment',
                    [UsageActionEnum.VIEWED_ATTACHMENT.name]: 'Viewed Attachment',
                    [UsageActionEnum.VIEWED_MOBILE_COLLECTIONS.name]: 'Viewed Mobile Collections',
                    [UsageActionEnum.VIEWED_MOBILE_COLLECTION_ARCHIVE.name]:
                        'Viewed Mobile Archive',
                    [UsageActionEnum.VIEWED_MOBILE_COLLECTION.name]: 'Viewed Mobile Collection',
                    [UsageActionEnum.VIEWED_NOTIFICATION_INBOX.name]: 'Viewed Notification Inbox',
                    [UsageActionEnum.VIEWED_NOTIFICATION_ARCHIVE.name]:
                        'Viewed Notification Archive',
                    [UsageActionEnum.VIEWED_NOTIFICATION_ALERTS.name]: 'Viewed Notification Alerts',
                    [UsageActionEnum.VIEWED_PERSONAL_DASHBOARD.name]: 'Viewed Personal Dashboard',
                    [UsageActionEnum.VIEWED_TASKS_DASHBOARD.name]: 'Viewed Tasks Dashboard',
                    [UsageActionEnum.EXPORTED_INVENTORIES.name]: 'Exported Inventories',
                    [UsageActionEnum.EXPORTED_INVENTORY.name]: 'Exported Inventory',
                },
                usageCompletion: {
                    [UsageCompletionEnum.SUCCEEDED.name]: 'Succeeded',
                    [UsageCompletionEnum.SERVER_ERROR.name]: 'Server Error',
                    [UsageCompletionEnum.INSUFFICIENT_PERMISSIONS.name]: 'Insufficient Permissions',
                    [UsageCompletionEnum.UNAUTHORIZED.name]: 'Unauthorized',
                    [UsageCompletionEnum.FEATURE_FLAGGED_OFF.name]: 'Feature Unavailable',
                },
                usageSourceModule: {
                    [UsageSourceModuleEnum.CASE_MANAGEMENT.name]: 'Cases',
                    [UsageSourceModuleEnum.WARRANTS.name]: 'Warrants',
                    [UsageSourceModuleEnum.REPORTS.name]: 'Reports',
                    [UsageSourceModuleEnum.ENTITY_PROFILES.name]: 'Entity Profiles',
                    [UsageSourceModuleEnum.EVIDENCE.name]: 'Evidence',
                    [UsageSourceModuleEnum.NOTIFICATIONS.name]: 'Notifications',
                    [UsageSourceModuleEnum.RMS_GENERAL.name]: 'General',
                    [UsageSourceModuleEnum.RMS_MOBILE.name]: 'RMS Mobile',
                    [UsageSourceModuleEnum.TASKS.name]: 'Tasks',
                },
                usageSourceApplication: {
                    [UsageSourceApplicationEnum.RMS.name]: 'RMS',
                    [UsageSourceApplicationEnum.MOBILE_RMS.name]: 'RMS Mobile',
                },
                entityType: {
                    [EntityTypeEnum.CASE.name]: 'Case',
                    [EntityTypeEnum.CASE_NOTE.name]: 'Case Note',
                    [EntityTypeEnum.EVIDENCE_INVENTORY.name]: 'Evidence Inventory',
                    [EntityTypeEnum.REPORT.name]: 'Report',
                    [EntityTypeEnum.PERSON_PROFILE.name]: 'Person',
                    [EntityTypeEnum.ORGANIZATION_PROFILE.name]: 'Organization',
                    [EntityTypeEnum.ITEM_PROFILE.name]: 'Item',
                    [EntityTypeEnum.WARRANT.name]: 'Warrant',
                    [EntityTypeEnum.NOTIFICATION.name]: 'Notification',
                    [EntityTypeEnum.FIELD_NOTE.name]: 'Mobile Collection',
                    [EntityTypeEnum.TASK.name]: 'Task',
                },
            },
        },
        userDisablingAdmin: {
            all: 'All',
            disable: 'Disable',
            indeterminate: 'INDETERMINATE',
            noResults: 'No results found.',
            of: 'of ',
            prefix: 'Prefix',
            postfix: 'Postfix',
            search: 'Search',
        },
        dispatchAreas: {
            title: 'Dispatch Areas',
            newDispatchAreaTitle: 'New Dispatch Area',
            dispatchAreasAdmin: {
                labels: {
                    subheaderTitle: 'Dispatch Areas',
                },
            },
            dispatchAreasForm: {
                labels: {
                    agency: 'Agency',
                    agencyType: 'Agency Type',
                    radioChannel: 'Radio Channel',
                    dispatchAreaName: 'Name',
                    dispatchGroup: 'Dispatch Group',
                    dispatchAreaStatus: 'Dispatch Area Status',
                    isOOJ: 'Is OOJ',
                },
            },
            confirmationModal: {
                title: 'Change Agency Type',
                message:
                    'Changing agency type may have unintended implications to Stations, Units, and Run Orders.',
            },
        },
        cadUnits: {
            title: 'CAD Units',
            newUnit: 'New Unit',
            listTypeTitle: 'UnitClass',
            listTitle: 'Units',
            importExport: {
                importUnits: 'Import Units',
                exportUnits: 'Export Units',
                unitTypeDetailsHeader: 'Unit Type Details',
                importUnitTypeDetails: 'Import Unit Type Details',
                exportUnitTypeDetails: 'Export Unit Type Details',
                hamburgerMenuAria: 'Toggle Import Export Units Menu',
            },
            cadUnitsAdminForm: {
                labels: {
                    callSign: 'Call Sign',
                    dispatchAreaId: 'Dispatch Area',
                    unitTypeAttrId: 'Unit Type',
                    primaryUnitTypeAttrId: 'Primary Unit Type',
                    primarySubdivisionId: 'Primary Subdivision',
                    additionalUnitTypesAttrIds: 'Additional Unit Types',
                    equipmentIds: 'Equipment',
                    radioId: 'Radio Id',
                    agencyId: 'Agency',
                    isTemporary: 'Is Temporary',
                    stationId: 'Station',
                    beat: 'Beat',
                    status: 'Status',
                    tagNumber: 'Vehicle',
                    unitCapabilities: {
                        title: 'Unit Capabilities',
                    },
                    unitMembers: {
                        title: 'Unit Members',
                        member: 'Member',
                        add: 'Member',
                        remove: 'Remove',
                    },
                    membersRequired: 'Members Required',
                    defaultStaffCount: 'Default Staff Count',
                },
                helpText: {
                    primaryUnitType:
                        'Group, search, and filter by Primary Unit Type in unit queues and panels.',
                    additionalUnitTypes:
                        'Search and filter by Additional Unit Types in unit queues and panels. Additional Unit Types will be listed in the app in the order they are listed here.',
                    equipment:
                        'Add longterm Equipment in this field. Add or edit Equipment temporarily (for the duration of a shift) when logging on a unit in the app. Unit Equipment will be listed in the app in the order it is listed here.',
                    tagNumber:
                        'Add longterm Vehicle in this field. Add or edit Vehicle temporarily (for the duration of a shift) when logging on a unit in the app.',
                    radioId: 'Add radio ids',
                    defaultStaffCount:
                        'Default staff count for the unit unless changed during log on or editing',
                },
            },
            cadUnitsClassTypes: {
                police: 'Police',
                fire: 'Fire',
            },
            footer: {
                lastModified,
            },
            confirmationModal: {
                title: 'Are you sure?',
                message:
                    'This Dispatch Area is not associated with the selected Agency. Are you sure you want to save this unit?',
            },
        },
        cadGPSConfigs: {
            title: 'CAD GPS Configs',
            newGPSConfig: 'New GPS Config',
            listTitle: 'GPS Configs',
            cadGPSConfigsAdminForm: {
                labels: {
                    tagNumber: 'License Plate',
                    configurationTag: 'Tag',
                    comPort: 'COM Port',
                    baudRate: 'BAUD Rate',
                    legacyBaudRate: 'Legacy BAUD Rate',
                    legacyComPort: 'Legacy COM Port',
                    passThruComPort: 'Pass Thru COM Port',
                    supportLegacy: 'Support Legacy',
                    hostName: 'Host Name',
                },
            },
            footer: {
                lastModified,
            },
        },
        cadCallTakerStations: {
            title: 'CAD Call Taker Stations',
            newCallTakerStation: 'New Call Taker Station',
            editCallTakerStation: 'Edit Call Taker Station',
            listTitle: 'Call Taker Stations',
            cadCallTakerStationsAdminForm: {
                labels: {
                    displayValue: 'Call Taker Station',
                    startDate: 'Start Date',
                    endDate: 'End Date',
                    isEnabled: 'Is Enabled',
                    integrationAlias: 'Integration Alias',
                },
            },
            footer: {
                lastModified,
            },
        },
        cadDesktopConfigs: {
            title: 'CAD Desktop Configs',
            newDesktopConfig: 'New Desktop Config',
            editDesktopConfig: 'Edit Desktop Config',
            listTitle: 'Desktop Configs',
            cadDesktopConfigsAdminForm: {
                labels: {
                    remove: 'REMOVE',
                    comPort: 'COM Port',
                    baudRate: 'BAUD Rate',
                    gpsInstalled: 'Use GPS',
                    hostName: 'Host Name',
                    isConfirmed: 'Is Confirmed',
                    machineName: 'Confirmed Machine Name',
                    machineInfo: 'Last Known Machine Metadata',
                    metadataMachineName: 'Metadata Machine Name',
                    metadataUpdatedDateUtc: 'Metadata Last Updated Date',
                    metadataLoggedInUserId: 'Last Logged In User ID',
                    metadataMacAddress: 'Mac Address',
                    metadataIsVersion: 'OS Version',
                    metadataInternalIp: 'Internal IP',
                    metadataExternalIp: 'External IP',
                    metadataInternetHealth: 'Internet Health',
                    readerType: 'Reader Type',
                    sentenceType: 'Sentence Type',
                },
            },
            footer: {
                lastModified,
            },
        },
        cadBoloAudit: {
            title: 'Bolo Notification Audit',
            cadBoloAuditAdminForm: {
                labels: {
                    button: 'Export',
                    startDateUtc: 'Start Date Notification',
                    endDateUtc: 'End Date Notification',
                    createdByUsers: 'Created By',
                    notifiedByusers: 'Notified By',
                    ackByUsers: 'Acknowledged By',
                    reportingEventNumber: 'Bolo ID',
                    units: 'Units',
                    dispatchAreas: 'Dispatch Areas',
                },
                tooltips: {
                    ackTooltip:
                        'User selected the Acknowledge button when notification was received',
                    viewedByTooltip:
                        'User selected the View Bolo button when notification was received',
                },
            },
        },
        cadMessagingAudit: {
            title: 'Messaging Audit',
            cadMessagingAuditAdminForm: {
                labels: {
                    button: 'Export',
                    startDateUtc: 'Start Date',
                    endDateUtc: 'End Date',
                    users: 'Users',
                    keywords: 'Keywords',
                    unitAssignments: 'Unit Assignments',
                    dispatchAreaOwnerAssignments: 'Dispatch Area Owner Assignments',
                    callTakerStationAssignments: 'Call Taker Station Assignments',
                },
            },
        },
        cadServiceRotations: {
            title: 'Service Rotation',
            newServiceRotation: 'New Service Rotation',
            listTitle: 'Service Rotations',
            cadServiceRotationsAdminForm: {
                labels: {
                    name: 'Service Rotation Name',
                    code: 'Service Abbr',
                    capaqbilityCode: 'Capability Code',
                    capaqbilityName: 'Capability Name',
                    description: 'Service Rotation Description',
                    includeLocation: 'Geographically Mapped',
                    isTowRotation: 'Is Tow Rotation Rotation',
                    isActive: 'Status',
                    shapefile: 'Shapefile',
                    acceptedAction: 'Accepted Result',
                    declinedAction: 'Declined Result',
                    noAnswerAction: 'No Answer Result',
                    skipedAction: 'Skip Result',
                    userCancellationAction: 'User Cancellation Result',
                    providerCancellationAction: 'Provider Cancellation Result',
                    shapefileProperty: 'Shapefile Property',
                    capabilitiesheader: 'Capabilities',
                    actionsHeader: 'Actions',
                    providerResponses: 'Provider Responses',
                    cancelationResponse: 'Cancellations',
                },
            },
            footer: {
                lastModified,
            },
        },
        cadMessagingGroups: {
            title: 'Messaging Groups',
            newMessagingGroup: 'New Group',
            listTitle: 'Groups',
            CadMessagingGroupsAdminListForm: {
                memberRemovalError:
                    'There was an error removing the member from this group, please try again',
                labels: {
                    userName: 'User Name',
                    unitCallSign: 'Unit Call Sign',
                    dispatchArea: 'Dispatch Area',
                    dispatchAreaName: 'Dispatch Area Name',
                    unitType: 'Unit Type',
                    agencyType: 'Agency Type',
                    status: 'Status',
                    callTakerStationName: 'Call Taker Station Name',
                },
            },
            cadMessagingGroupsAdminForm: {
                labels: {
                    name: 'Name',
                    description: 'Description',
                    messageGroupTypeAttrId: 'Group Type',
                },
            },
            CadMessagingGroupsAddEntiesForm: {
                requiredError: 'This field is required',
                labels: {
                    addEntity: 'Add',
                    dispatchAreas: 'Dispatch Areas',
                    callTakerStations: 'Call Taker Stations',
                    unitCallSigns: 'Unit Call Signs',
                    users: 'Users',
                },
            },
            footer: {
                lastModified,
            },
        },
        cadServiceProviders: {
            title: 'Service Rotation Provider',
            companyLabel: 'Company',
            providerLabel: 'Provider',
            providersListTitle: 'Providers',
            newServiceType: 'New Service Rotation Provider',
            listTitle: 'Service Types',
            cadServiceProvidersAdminForm: {
                labels: {
                    name: 'Service Provider Name',
                    description: 'Comments',
                    rotationServiceType: 'Service Type',
                    location: 'Primary Location',
                    phoneNumber: 'Phone',
                    capabilities: 'Capabilities',
                    isTowProviderCheckboxLabel: 'Is Tow Provider',
                    serviceTypesDropdown: 'Service Type(s)',
                    suspensions: {
                        title: 'Suspensions',
                        viewHistory: 'View History',
                        addSuspension: 'Add Suspension',
                        noSuspensionsMessage: 'No suspensions for this provider',
                        addSuspensionDrawerTitle: 'Suspend Service',
                        editSuspensionDrawerTitle: 'Edit Suspension',
                        suspensionsHistoryDrawerTitle: 'Suspensions History',
                        closeHistoryDrawerButton: 'Close',
                        noPastSuspensionsMessage: 'No past suspensions for this provider',
                        suspensionsHistoryRequestErrorMessage:
                            'Unable to load suspensions history. Try again later.',
                        startTimeUtc: 'Start',
                        endTimeUtc: 'End',
                        indefinitely: 'Indefinitely',
                        serviceTypesDropdown: 'Service Type(s)',
                        reason: 'Reason',
                        suspendButton: 'Suspend',
                        endDateAfterStartOneValidationErrorMessage:
                            'End date cannot be before the start date.',
                        selectAllTypes: 'Select all',
                        unselectAllTypes: 'Unselect all',
                        resumeButton: 'Resume',
                        resumeErrorMessage: 'Unable to resume this service. Try again later.',
                        editButton: 'Edit',
                        suspendedOptionNote: '(suspended)',
                        serviceTypesDropdownComplementaryText:
                            'Suspended service types will not appear in CAD',
                        bannerTitle: 'Suspension',
                        bannerText:
                            'One or more service types have been suspended for this provider.',
                        resumeServiceModalTitle: 'Resume Service',
                        resumeServiceModalText: 'Are you sure you want to resume service for',
                    },
                },
            },
            footer: {
                lastModified,
            },
        },
        cadRotationsLists: {
            title: 'Rotation Lists',
            providersList: 'Rotation List',
            providersListTitle: 'Providers',
            newServiceType: 'New Service Rotation Provider',
            listTitle: 'Service Types',
            cadRotationsListsAdminForm: {
                labels: {
                    name: 'List Name',
                    description: 'Comments',
                    rotationServiceType: 'Service Type',
                    rotationArea: 'Rotation Area',
                },
                cadRotationListEntryForm: {
                    location: 'Location',
                    provider: 'Provider',
                    activeDate: 'Active Date / Time',
                    expirationDate: 'Expiration Date / Time',
                    save: 'Save',
                    cancel: 'Cancel',
                },
            },
            footer: {
                lastModified,
            },
        },
        cadServiceTypes: {
            title: 'Service Rotation Type',
            newServiceType: 'Rotation Type',
            listTitle: 'Service Types',
            cadServiceTypesAdminForm: {
                labels: {
                    name: 'Service Type Name',
                    code: 'Service Abbr',
                    capaqbilityCode: 'Capability Code',
                    capaqbilityName: 'Capability Name',
                    description: 'Service Type Description',
                    includeLocation: 'Geographically Mapped',
                    isTowRotation: 'Is Tow Rotation Type',
                    isActive: 'Status',
                    shapefile: 'Shapefile',
                    acceptedAction: 'Accepted Result',
                    declinedAction: 'Declined Result',
                    noAnswerAction: 'No Answer Result',
                    skipedAction: 'Skip Result',
                    userCancellationAction: 'User Cancellation Result',
                    providerCancellationAction: 'Provider Cancellation Result',
                    shapefileProperty: 'Shapefile Property',
                    capabilitiesheader: 'Capabilities',
                    actionsHeader: 'Actions',
                    providerResponses: 'Provider Responses',
                    cancelationResponse: 'Cancellations',
                },
            },
            footer: {
                lastModified,
            },
        },
        cadServiceRotationAudit: {
            title: 'Service Rotation Audit',
            cadServiceRotationAuditAdminForm: {
                labels: {
                    button: 'Export',
                    startDateUtc: 'Start Date',
                    endDateUtc: 'End Date',
                    startUtc: 'Start',
                    endUtc: 'End',
                    serviceTypes: 'Rotation Type',
                    companyName: 'Service Provider',
                    rotationList: 'Rotation List',
                    rotationAreas: 'Rotation Area(s)',
                    action: 'Action',
                },
            },
        },
        cadServiceRotationsListAdmin: {
            title: 'Service Rotations List',
            cadServiceRotationsListAdminForm: {
                labels: {
                    serviceType: 'Service Type',
                    rotationListHeader: 'Rotation List',
                    newRotationButton: '+ Rotation List',
                    okButton: 'Save',
                    cancelButton: 'Cancel',
                    rotationList: 'Rotation List',
                    listName: 'List Name',
                    comments: 'Comments',
                    primaryLocation: 'Primary Location',
                    exampleListName: 'Tow Rotation for Acme Corp',
                    rotationArea: 'Rotation Area',
                },
            },
        },
        cadRapidSosAdmin: {
            clientId: 'Client ID',
            clientSecret: 'Client Secret',
            userEmail: 'RapidSOS user email',
            userPassword: 'RapidSOS user password',
            lastModified: (date: string, updatedBy: string) =>
                `Last Modified ${date} by ${updatedBy}`,
            integrationMessage:
                'To set up the integration, copy the tokens from the RapidSOS Portal and paste them below. The tokens can' +
                ' be found in the RapidSOS Portal Admin Settings page under Integration Management. Then provide email/password' +
                ' of a single RapidSOS account. All CAD users will interact with RapidSOS using that account.',
            integrationSetup: 'Integration Setup',
            title: 'RapidSOS',
            useSandboxUrl: 'Use Sandbox Url',
        },
        cadUnitTimerPolicies: {
            title: 'CAD Unit Timer Policies',
            newUnitTimer: 'New Unit Timer',
            editUnitTimer: 'Edit Timer',
            listTitle: 'Unit Status',
            historyTitle: 'Global History',
            cadUnitTimersAdminForm: {
                labels: {
                    displayValue: 'Unit Status',
                    globalTimer: 'Global Status Timer',
                    startDate: 'Active Date/Time',
                    endDate: 'Expiration Date/Time',
                    isEnabled: 'Active',
                    isNotEnabled: 'Not Active',
                },
            },
            cfsTimerHelpText:
                'Override Global Status Timer for units on events with these CFS type(s)',
            footer: {
                lastModified,
            },
        },
        caseTypes: {
            deleteCaseFolderModal: {
                title: 'Delete Folder',
                okText: 'Ok',
                cancelText: 'Cancel',
                message:
                    'Deleting this default folder will only impact future case jackets created after this saved action. Historic case jackets with this folder will not be retroactively affected.',
            },
        },
        courtsAdmin: {
            courtsAdminForm: {
                labels: {
                    courtName: 'Court Name',
                    courtIdentifier: 'Court Identifier',
                    isDeactivated: 'Is Expired',
                    creatNewCourt: 'New Court',
                },
                title: 'New Court',
            },
        },
        submissions: {
            messageTypes: {
                newSubmissionMessageTitle: 'New Submission Message',
                addNewButton: 'New Submission Message',
                title: 'Submission Messages',
                adminListTitle: 'Submission Messages',
            },
            submissionMessagesAdminForm: {
                labels: {
                    displayName: 'Display Name',
                    displayCode: 'Display Code',
                    requiresApproval: 'Requires approval to submit this message type',
                    entityType: 'Entity Type',
                    allowMultiple: 'Allow Multiple',
                    reportDefinitionIds: 'Report Types',
                    roleIds: 'Roles',
                    isActive: 'Is Active',
                    maxNumberOfAllowedSubmissions: 'Max number of allowed submissions',
                },
            },
            createSubmissionMessageModal: {
                labels: {
                    messageType: 'Submission Message Type',
                    attachments: 'Attachments',
                },
            },
        },
        fillablePdfTemplates: {
            title: 'Fillable PDFs',
            fillablePdfTemplateAdmin: {
                title: 'New Fillable PDF',
                sidebarTitle: 'Fillable PDFs',
                labels: {
                    viewHandlebars: 'View Handlebars',
                    newFillableTemplateButton: 'New Fillable PDF',
                },
            },
            fillablePdfTemplateAdminForm: {
                labels: {
                    title: 'Title',
                },
                requiredError: 'This field is required',
            },
            viewHandlebars: {
                handlebarsValidator: 'Handlebars Validator',
                labels: {
                    handlebars: 'Handlebars Template String',
                    cancelButton: 'Close',
                    output: 'Output Preview',
                    reportId: 'Report ID',
                    templatesRequired: 'Enter a handlebars template string to preview',
                    reportIdRequired: 'Enter a Report ID',
                    reportIdInvalid: 'Enter a valid Report ID',
                },
                newFillablePdf: 'New Fillable PDF',
                placeholders: {
                    handlebars: 'Enter the handlebars template strings to preview',
                    keywordSearch: 'Keyword search',
                    reportId: 'Eg. 123456',
                    output: 'Output',
                    reactJson: 'enter report id in top left corner to view handlebar template',
                },
                reportHandlebars: 'Report Handlebars',
                title: 'View Handlebars',
                validate: 'Validate',
                view: 'View',
            },
        },
        pdf: {
            DeletePdfTemplateModal: {
                bodyText:
                    'Deleting the PDF will permanently remove the file. This action cannot be undone.',
                cancel: 'Cancel',
                delete: 'Delete',
                title: 'Delete PDF',
            },
            NewPdfTemplateButton: {
                newPdf: 'New PDF',
            },
            NewPdfTemplateModal: {
                cancel: 'Cancel',
                createNewPdf: 'Create New PDF',
                templateType: 'Template Type',
                save: 'Save',
                templateNameLabel: 'Name',
            },
            PdfBuilder: {
                cannotSaveWithoutTemplate: 'Cannot save without a template',
                cannotSaveWithoutTitle: 'Cannot save without a title',
                failedToSave: 'Failed to save, please try again',
            },
            PdfBuilderSubheader: {
                done: 'Done',
                reportId: 'Report ID',
            },
            PdfDashboard: {
                title: 'PDFs',
            },
            PdfDashboardTable: {
                emptyStateMessage: 'No PDFs',
            },
            PdfDashboardTableFilter: {
                searchPdf: 'search PDF',
                showDisabledPdfs: 'Show disabled PDFs',
            },
            PdfDashboardTableHeader: {
                name: 'Name',
                type: 'Type',
            },
            PdfDashboardTableRow: {
                delete: 'Delete',
                disable: 'Disable',
                enable: 'Enable',
                name: 'Name',
                type: 'Type',
            },
        },
        vehicleMakeModels: {
            title: 'Vehicle Makes and Models',
            listTitle: 'Make/Models',
            notice: 'This page only allows the import and export of Vehicle Makes and Models. Any changes to the Vehicle Make and Model list via import will affect every single department and cannot be undone. Please do not import anything without the explicit permission of the engineering team.',
        },
        warrantConfiguration: {
            title: 'Warrant Configuration',
            WarrantConfigurationTypeFormContent: {
                title: 'Warrant Number Sequence Type',
                label: 'Warrant Number Sequence Type',
                singleConfigurationLink: 'ID Generator Admin Page',
                multipleConfigurationLink: 'Warrant Number Configuration Admin Page',
                preLinkText: 'After saving your selection, please navigate to the ',
                postLinkText: ' to configure the sequence.',
            },
            WarrantConfigurationAdminForm: {
                labels: {
                    defaultPermissions: {
                        title: 'Default User Permissions',
                        addText: 'Personnel',
                        roleId: 'User / Role',
                        operationType: 'Access Level',
                    },
                    defaultDepartmentPermissions: {
                        title: 'Default External Agency Permissions',
                        addText: 'Personnel',
                        roleId: 'Role',
                        operationType: 'Access Level',
                    },
                },
            },
        },
        warrantActivityStatusLinks: {
            WarrantActivityMappingsAdmin: {
                title: 'Warrant Activity Mapping',
                adminListTitle: 'Warrant Activities',
            },
        },
        ssoConfiguration: {
            title: 'Single Sign On Configuration',
        },
        dataExchange: {
            title: 'Data Exchange',
            DexDevicesAdmin: {
                title: 'DEX Devices',
                newDeviceButtonLabel: 'New Device',
                importExport: {
                    importButtonText: 'Import DEx Devices',
                    importModalTitle: 'Import DEx Devices',
                    importModalConfirm: 'OK',
                    exportTitle: 'Export DEx Devices',
                    failedToUpload: 'Failed to upload DEx Device CSV. Please try again.',
                },
            },
            LocationBasedOri: {
                title: 'Location Based ORI',
                locationBasedOri: 'Location Based DEx ORI',
                overrideBannerTitle: 'Warning',
                overrideBannerBody:
                    'Saving a new Shapefile configuration will remove any existing ORIs.',
                formLabels: {
                    shapefile: 'Shapefile',
                    shapefileProperty: 'Shapefile Property',
                },
                validation: {
                    shapefile: 'Shapefile is required',
                    property: 'Shapefile property is required',
                },
                dataFetching: {
                    failedToSavePanel: 'Network Error occurred when saving panel',
                    failedToGetExistingAliasOptions:
                        'Failed to get existing ORI Alias configuration',
                },
                existingAliasesTitle: 'Existing ORI Aliases',
            },
            DexDevices: {
                tableHeaders: {
                    deviceName: 'Device Name',
                    identifier: 'Vendor Device Identifier',
                    agencyOri: 'ORI',
                    actions: 'Actions',
                    mnemonic: 'Mnemonic',
                },
                addOriText: 'Add Ori',
                filterLabel: 'Filter',
                removeDexDeviceButton: 'Remove Device',
                removeDexDeviceConfirmationModal: {
                    title: 'Delete Device',
                    message:
                        'Device deletion is a permanent action and cannot be undone. All of your data will be lost.',
                },
                multipleOriFormLabels: {
                    oriAlias: 'ORI Alias',
                    deviceId: 'Device ID',
                    mnemonic: 'Mnemonic',
                },
                formValidation: {
                    required: 'Required',
                    noOriPresent: 'At least one ORI is required',
                    noDuplicateOriAliases: 'Aliases must be unique between ORIs',
                },
                dataFetching: {
                    failedToGetOriAliases: 'Failed to fetch ORI Alias options',
                    failedToSavePanel: 'Network Error occurred when saving panel',
                },
            },
            DexDevicesSidePanel: {
                newDevice: {
                    title: 'New Device',
                },
                editDevice: {
                    title: 'Edit Device',
                },
            },
        },
        masterEntities: {
            title: 'Master Entities',
            MasterEntityAdmin: {
                title: 'Types',
                labels: {
                    [EntityTypeEnum.BRIEFING.name]: 'Briefings',
                    [EntityTypeEnum.ITEM_PROFILE.name]: 'Items',
                    [EntityTypeEnum.ORGANIZATION_PROFILE.name]: 'Organizations',
                    [EntityTypeEnum.PERSON_PROFILE.name]: 'Persons',
                },
            },
            MasterEntityAdminForm: {
                labels: {
                    defaultPermissions: {
                        title: 'Default User Permissions',
                        addText: 'Personnel',
                        roleId: 'User / Role',
                        operationType: 'Access Level',
                    },
                    defaultDepartmentPermissions: {
                        title: 'Default External Agency Permissions',
                        addText: 'Personnel',
                        roleId: 'Role',
                        operationType: 'Access Level',
                    },
                },
            },
        },
        personDeduplication: {
            DuplicateCandidatesTable: {
                title: 'Person Deduplication Dashboard',
                load: 'Load More Potential Duplicates',
                compare: 'Compare',
                explanation: (count: number) =>
                    `This is a list of ${count} potential duplicate persons. Click the Compare button to decide whether each pair should be merged into one profile or not.`,
                decisionSummary: {
                    [DeduplicatePersonProfileDecisionEnum.YES.name]: 'Yes',
                    [DeduplicatePersonProfileDecisionEnum.NO.name]: 'No',
                    [DeduplicatePersonProfileDecisionEnum.UNSURE.name]: 'Unsure',
                },
                noResults: 'No Potential Duplicate Persons',
            },
            DuplicateCandidate: {
                title: (totalCount: number) =>
                    `Compare Potential Duplicate Persons (${totalCount - 1} pairs remaining)`,
                question: 'Are these duplicate profiles?',
                decisionActions: {
                    [DeduplicatePersonProfileDecisionEnum.YES.name]: 'Yes, Merge',
                    [DeduplicatePersonProfileDecisionEnum.NO.name]: 'No',
                    [DeduplicatePersonProfileDecisionEnum.UNSURE.name]: 'Unsure',
                },
                decisionTooltips: {
                    [DeduplicatePersonProfileDecisionEnum.YES.name]:
                        'Merge the pair and remove it from this dashboard',
                    [DeduplicatePersonProfileDecisionEnum.NO.name]:
                        'This will not merge the pair. The pair will be removed from this dashboard.',
                    [DeduplicatePersonProfileDecisionEnum.UNSURE.name]:
                        'This will not merge the pair. The pair will be removed from this dashboard until a later time.',
                },
            },
        },
        caseDefinitions: {
            newDefinition: 'New Case Type',
            title: 'Case Types',
            listTitle: 'Case Types',
            CaseDefinitionsAdminForm: {
                labels: {
                    name: 'Case Type',
                    nameAbbreviation: 'Case Type Abbreviation',
                    startDateUtc: 'Active Date / Time',
                    endDateUtc: 'Expiration Date / Time',
                    caseDuration: 'Case Duration (In Days)',
                    defaultCaseStatusAttrId: 'Default Case Status',
                    defaultPersonnelUnitAttrId: 'Default Unit Assigned',
                    defaultAssigneeRoleId: 'Default User Assigned',
                    folderName: 'Folder Name',
                    defaultSupervisors: {
                        title: 'Default Supervisors',
                        addText: 'Supervisor',
                        roleId: 'User',
                    },
                    defaultPermissions: {
                        title: 'Default User Permissions',
                        addText: 'Personnel',
                        roleId: 'User / Role',
                        operationType: 'Access Level',
                    },
                    defaultDepartmentPermissions: {
                        title: 'Default External Agency Permissions',
                        addText: 'Personnel',
                        roleId: 'Role',
                        operationType: 'Access Level',
                    },
                    defaultTaskList: {
                        title: 'Default Task List',
                        description: 'Description',
                        dueDateInterval: 'Task Duration (Optional)',
                        assigneeRoleId: 'Default Assignee',
                        addText: 'New Task',
                    },
                    caseTaskList: {
                        title: 'Title',
                        taskType: 'Type',
                        assigneeType: 'Assignee',
                        userRole: 'User/Role',
                        daysUntilDue: 'Days Until Due',
                        description: 'Description',
                    },
                    defaultAttachmentFolders: {
                        title: 'Default Attachment Folders',
                        addText: 'Folder',
                        defaultFoldersMessage:
                            'Adding or deleting folders will only be applied to new cases created after saving.',
                    },
                    defaultNotesFolders: {
                        title: 'Default Notes Folders',
                        addText: 'Folder',
                        defaultFoldersMessage:
                            'Adding or deleting folders will only be applied to new cases created after saving.',
                    },
                },
                placeholders: {
                    caseDuration: '# of Days',
                    taskDuration: '# of Days',
                    taskDescription: 'Description',
                    description: 'Additional Details',
                },
                newDefinition: 'New Definition',
                removeDefinition: 'Remove',
            },
            footer: {
                lastModified,
            },
        },
        agencyProfile: {
            newAgency: 'New Agency',
            title: 'Agencies Profiles',
            listTitle: 'Agencies',
            AgencyProfileAdminForm: {
                labels: {
                    subtitleLogo: 'Agency Logo',
                    subtitleMain: 'Agency Information',
                    name: 'Agency Name',
                    code: 'Agency Code',
                    type: 'Agency Type',
                    ori: 'Agency ORI',
                    countyIndicator: 'County Indicator',
                    nibrsStartDateUtc: 'NIBRS Start Date',
                    externalAgency: 'External Agency',
                    isDefaultAgency: 'Default Agency',
                    defaultAgencyTooltip:
                        "Events will default to this agency when located in more than one agency's subdivisions. Selecting this will override any other selections made.",
                    quickCrashTitle: 'Quick Crash',
                    reportingDistrict: 'Reporting District',
                },
            },
            CADSection: {
                disposition: {
                    title: 'Disposition',
                    label: 'Disposition Required to Close Events',
                },
                multiRenGeneration: {
                    title: (renDisplay: string) => `${renDisplay} Generation`,
                    eventBasedTooltip: (renDisplay: string) =>
                        `Automatically generate a ${renDisplay} whenever an event occurs under the agency's jurisdiction.`,
                    callForSerivceTypesTooltip: (renDisplay: string) =>
                        `${renDisplay}s will not be automatically generated for the following Call for Service Types.`,
                    unitBasedTooltip: (renDisplay: string) =>
                        `Automatically generate a ${renDisplay} whenever the first unit from this agency is placed in a certain status (specified below).`,
                    unitStatusesTooltip: (renDisplay: string) =>
                        `${renDisplay}s will be automatically generated when the first unit from this agency is placed in the following statuses.`,
                    agencyAbbreviationTooltip: (renDisplay: string) =>
                        `Agency abbreviation displayed within ${renDisplay}`,
                    labels: {
                        agencyAbbreviation: (renDisplay: string) =>
                            `${renDisplay} Agency Abbreviation`,
                        eventBased: (renDisplay: string) =>
                            `Automatic Event-Based ${renDisplay} Generation`,
                        callForServiceTypes: 'Call for Service Types to Exclude',
                        unitBased: (renDisplay: string) =>
                            `Automatic Status-Based ${renDisplay} Generation`,
                        unitStatusTypes: 'Statuses to Include',
                    },
                },
                mapToRmsAgency: {
                    title: 'Map to RMS Agency',
                    labels: {
                        department: 'Department',
                        agency: 'Agency',
                    },
                },
            },
            footer: {
                lastModified,
            },
        },
        societyProfile: {
            newProfile: 'New Society Profile',
            SocietyProfileAdminForm: {
                labels: {
                    name: 'Society Name',
                },
                removeProfile: 'Remove',
            },
        },
        landingBackground: {
            title: 'Change Login Background',
            imagePlaceholder: 'Click to upload',
            resetToDefault: 'Reset to Default',
            save: 'Save',
            upload: 'Upload New',
        },
        reportPrintingTemplates: {
            ReportPrintingTemplatesAdmin: {
                title: 'Report Printing Template Configs',
                newReportPrintingTemplate: 'New Report Printing Template Config',
                listTitle: 'Report Template Configs',
            },
            ReportPrintingTemplatesAdminForm: {
                labels: {
                    title: 'Title',
                    printingTemplate: 'Printing Template',
                    reportDefinition: 'Report Definition (leave blank to choose all)',
                },
                newReportPrintingTemplate: 'New Report Template Config',
                deleteButton: 'Delete',
                saveSuccess: 'Saved Successfully',
            },
        },
        casePrintingTemplates: {
            CasePrintingTemplatesAdmin: {
                title: 'Case Printing Template Configs',
                newCasePrintingTemplate: 'New Case Printing Template Config',
                listTitle: 'Case Template Configs',
            },
            CasePrintingTemplatesAdminForm: {
                labels: {
                    title: 'Title',
                    printingTemplate: 'Printing Template',
                    caseDefinition: 'Case Definition (leave blank to choose all)',
                },
                newCasePrintingTemplate: 'New Case Template Config',
                deleteButton: 'Delete',
                saveSuccess: 'Saved Successfully',
            },
        },
        userProfile: {
            attachments: {
                addAttachment: 'Attachment',
            },
            theme: {
                title: 'Theme',
                enable: 'Enable Dark Mode',
                disable: 'Enable Light Mode',
            },
            confirmation: {
                confirmationSent: 'Confirmation Email Sent',
                resendConfirmation: 'Resend Confirmation Email',
            },
            unlockAccount: {
                userUnlocked: 'User has been unlocked',
                unlockUser: 'Unlock User',
            },
            roles: {
                role: 'Role',
                canManage: 'Can Manage',
                assignedRoles: 'Assigned Roles',
                heading: 'Edit Roles',
                searchPlaceholder: 'Search Roles',
            },
            personalTokens: {
                noTokensAvailable: 'You do not yet have any tokens',
            },
            sectionHeaders: {
                sidepanelEditing: 'Edit User',
                sidepanelAdding: 'Add User',
                userAccount: 'User Account',
                californiaStop: 'Racial and Identity Profiling Act (RIPA)',
                californiaStopHelpText:
                    'RIPA requires these fields for the officer performing each stop. Any values that filled in here will become the default values to be prefilled into the RIPA card in reports created by this user.',
                profileInfo: 'Profile Info',
                cjisInfo: 'Cjis Indicators',
                identifiers: 'Identifiers',
                dutyStatus: 'Duty Status',
                assignment: 'Assignment',
                trainingCertification: 'Training / Certification',
                contactInfo: 'Contact Info',
                emergencyContactInfo: 'Emergency Contact Info',
                abilities: 'Abilities',
                notificationSettings: 'Notification Settings',
                attachments: 'Attachments',
                personalApiAccessTokens: 'Personal API Access Tokens',
                signature: 'Signature',
            },
            labels: {
                editButton: 'Edit',
            },
            fieldValues: {
                accountStatus: {
                    active: 'Active',
                    inactive: 'Inactive',
                },
            },
            fieldHelpTexts: {
                userProfilePrimaryUserGroup:
                    'For Mark43 Internal Use Only. This is unrelated to roles and abilities. Mark43 uses this to improve our product metrics and send targeted surveys, trainings, and release notes.',
                isSsoUser:
                    'Checking this box permits users to submit reports without re-entering their password.',
            },
            fieldLabels: {
                // User/UserAccount
                active: 'Active',
                inactive: 'Inactive',
                enabled: 'Enabled',
                disabled: 'Disabled',
                // UserProfile
                userProfileYearsOfPreviousService: 'Years of previous service',
                userProfileYearsOfPreviousServiceHelp:
                    'Total years of service prior to joining this agency',
                // DutyStatus
                addDutyStatusText: 'Duty Status',
                // UserAssignment
                addUserAssignmentText: 'Assignment',
                // Contact Info
                addHomeAddress: 'Home Address',
                addPhone: 'Phone',
            },
            validations: {
                dutyStatusRequiredErrorMessage: 'Duty Status is required',
                effectiveDateErrorMessage: 'Effective Date is required',
            },
        },
        departmentprofile: {
            deptProfileFields: {
                headerTitle: 'Department Profile Admin',
                subheaderTitle: 'Department Profile Info',
                deptContactInfo: 'Department Contact Info',
                deptAddress: 'Department Address',
                departmentStatus: 'Department Status',
                timeZone: 'Time Zone',
                displayName: 'Display Name',
                city: 'City',
                nibrsRegionalGroup: 'UCR/NIBRS Regional Group',
                nibrsSubmissionEndpoint: 'NIBRS Submission Endpoint',
                dexRegion: 'DEX Regional Group',
                countryCode: 'Country',
                centerLat: 'Center Latitude',
                centerLng: 'Center Longitude',
                radiusMeters: 'Radius (Meters)',
                southwestLat: 'SW Latitude',
                southwestLng: 'SW Longitude',
                northeastLat: 'NE Latitude',
                northeastLng: 'NE Longitude',
                map: 'Map',
                deptPhoneNumber: 'Phone Number',
                website: 'Website',
                location: 'Department Address',
                locationBias: 'Location Bias',
                stateAttrId: 'State',
                statePlaneZone: 'State Plane Zone',
                searchResyncsEnabled: 'Search Resyncs Enabled',
                ageOfAdult: 'Age of Adult',
                personsSearchResultsDefault: 'Person Advanced Search Results',
                complianceGroup: 'Compliance Group',
                externalCustomerId: 'External Customer Id',
            },
            consortiumProfileFields: {
                title: 'Consortium Info',
                name: 'Name',
                description: 'Description',
                externalAgencies: 'External Agencies',
            },
            panel: {
                title: 'Edit Department',
                deptInfo: 'Department Information',
                locationBias: 'Location Bias',
                deptLogo: 'Department Logo',
            },
            helpTexts: {
                ageOfAdult:
                    'Involved profiles with ages under selected value will be flagged as juveniles on profile pages',
                personsSearchResultsDefault:
                    'Sets default to include or exclude juveniles from persons search results',
                nibrsSubmissionEndpoint: 'URL of the State Web Service to make NIBRS Submissions',
            },
        },
        departmentreset: {
            title: 'Reset Departmental Data',
            submit: 'Submit',
            confirmPerformReset: 'Reset Data?',
            resetConfirmationText: `Are you sure you want to reset this department?
                You cannot undo this action.
                This will not affect department configuration.`,
            yesReset: 'Yes, reset',
            noCancel: 'No, cancel',
            success: 'Success!',
            resetSuccessful: 'Reset Succeeded!',
            retainMark43SupportUsers:
                'Only tick if you want to retain the internal support users for reset, otherwise those users will be removed after reset',
        },
        departmentsetup: {
            title: 'Setup Wizard',
        },
        departmentsubdomainupdate: {
            title: 'Department Subdomain Update',
            current: (currentDomain: string) => `Current Department Domain: ${currentDomain}`,
            uponSave:
                'Upon saving, the full domain and internal user emails & role names that include the subdomain will be updated.',
            newSubdomain: 'New Subdomain',
            invalidSubdomain: 'Proposed subdomain is not valid',
            subdomainRequired: 'Required',
            save: 'Save',
            success: (newSubdomain: string, domain: string) =>
                `Success! Enter ${newSubdomain.toLowerCase()}${domain} in the address bar and log in to continue.`,
            helpText:
                'Subdomain must be less than 63 characters and must begin and end with an alphanumeric (i.e. letters [A-Z, a-z] and/or digits [0-9]). It may contain hyphens, but may not begin or end with a hyphen.',
        },
        departmentIps: {
            DepartmentIPsAdminForm: {
                labels: {
                    ip: 'IP Address',
                    isAllowed: 'Allowed?',
                },
            },
            footer: {
                lastModified,
            },
            newDepartmentIP: 'New IP Address',
            deleteDepartmentIP: 'Remove',
            departmentIPsSubheaderText: 'Department IP Addresses',
            departmentIPsListTitleText: 'DEPARTMENT IP ADDRESSES',
        },
        offenseCodes: {
            homeOfficeValidations: 'Home Office Validations',
            offenseCode: (offenseDisplayName: string) => `${offenseDisplayName} Code`,
            newOffenseCode: (offenseDisplayName: string) => `New ${offenseDisplayName} Code`,
            OffenseCodesAdmin: {
                toggleExpired: 'Show Expired/Disabled',
            },
            OffenseCodeAdminForm: {
                victimOrState: 'Victim or State',
                victimOrStateOptions: {
                    victim: 'Victim',
                    state: 'State',
                    victimAndState: 'Victim & State',
                },
                displayNameDisabledNotice: (offenseDisplayName: string) =>
                    `To avoid modifying previously created reports, ${offenseDisplayName} Display Name is not editable. To prevent this ${offenseDisplayName} Display Name from being used, expire it in the Expiration Date/ Time field, and create a new ${offenseDisplayName} Code.`,
                offenseCodeWordingTemplates: {
                    fieldsetTitle: (offenseDisplayName: string) =>
                        `${offenseDisplayName} Wording Templates`,
                    nItems: {
                        label: (formattedDate: string) =>
                            `Wording Template - Created on ${formattedDate}`,
                        addButton: (offenseDisplayName: string) =>
                            `${offenseDisplayName} Wording Template`,
                    },
                },
            },
        },
        nibrsOffenseCodes: {
            bannerDescription: `NIBRS Offense Codes are prioritized in descending order to clear incidents based on arrest.
                                                Drag and drop or enter a number to adjust the hierarchy. Note: Enter key must be pressed when updating list using keyboard.`,
            tableHeaderText: 'IBRS Offense Code',
            resetToDefault: 'Reset to Default',
            save: 'Save',
            title: 'NIBRS Offense Code Hierarchy',
            duplicateExists: 'At least 1 duplicate or blank value exists.',
        },
        ripaOffenseCodes: {
            title: 'RIPA Codes Update',
            notice: (offenseDisplayName: string) =>
                `This page only allows the import and export of RIPA ${offenseDisplayName} Codes or School Codes. NOTE: Import affects ALL departments and is a FULL replace of existing codes.
                All existing codes will be deactivated before importing the new codes.
                Before implementing this change do the following so that Prod Support is aware you are performing the bulk update and can provide support if necessary.`,
            navigation: [
                `Navigate to the Slack → Production-Updates page`,
                `Post in the page the following information:`,
            ],
            massageStructure: [
                `What job you are preparing to run`,
                `The DepartmentID of the tenant from where you are running the job`,
                `The comand Runner version being used`,
                `Which Cloud environment will be affected`,
                `How many records you are updating`,
            ],
            examples: [
                [
                    `Initiating a bulk upload of new RIPA Offense Codes`,
                    `Department id: 56648172721`,
                    `Command Runner v. 2024.225.112644`,
                    `Cloud: AWS`,
                    `Count: 3,500`,
                ],
                [
                    `Kicking off a Legacy Entity Details data loader for Holyoke Round 1:`,
                    `Department id: 56648172721`,
                    `Command Runner v. 2024.225.112644`,
                    `Cloud: AWS`,
                    `Count: 2,495,608`,
                ],
            ],
            exampleTitle: `Example`,
            importExport: {
                exportRIPAOffenseCodes: 'Export RIPA Offense Code Template',
                importRIPAOffenseCodes: 'Import RIPA Offense Code Template',
                exportRIPASchoolCodes: 'Export RIPA School Codes Template',
                importRIPASchoolCodes: 'Import RIPA School Codes Template',
            },
        },
        radioChannels: {
            radioChannel: 'Radio Channel',
            newRadioChannel: 'New Radio Channel',
            globalHistory: 'Global History',
            saveSuccess: 'Radio Channel Successfully Saved',
            RadioChannelsAdmin: {
                adminListTitle: 'Channels',
                toggleDeactivated: 'Show Deactivated',
            },
            RadioChannelAdminForm: {
                labels: {
                    radioChannelName: 'Name',
                    radioChannelStatus: 'Status',
                },
                validationStrings: {
                    nameRequired: 'This field is required',
                    nameLengthError: 'Radio channel name length cannot exceed 15 characters',
                    nameInvalidCharactersError:
                        'Invalid characters: only alphabetic characters, numbers, "_", "-" and space are allowed',
                    statusRequired: 'This field is required',
                },
            },
            RadioChannelAdminHistory: {
                labels: {
                    allRadioChannelHistories: 'All Radio Channels History',
                },
            },
        },
        eventLabels: {
            eventLabels: 'Event Labels',
            newEventLabel: 'Event Label',
            globalHistory: 'History',
            saveSuccess: 'Event Label Successfully Saved',
            EventLabelsAdmin: {
                adminListTitle: 'Labels',
                toggleExpired: 'Show Expired',
            },
            EventLabelAdminForm: {
                labels: {
                    eventLabelName: 'Label Name',
                    eventLabelLevel: 'Level',
                    eventLabelPriority: 'Priority',
                    eventLabelActiveDate: 'Active Date / Time',
                    eventLabelExpirationDate: 'Expiration Date / Time',
                },
                validationStrings: {
                    nameRequired: 'This field is required',
                    nameLengthError: 'Event Label name length cannot exceed 50 characters',
                    levelRequired: 'This field is required',
                    expirationDateAfterActiveDate: 'Expiration Date must be after Active Date',
                },
            },
            EventLabelAdminHistory: {
                labels: {
                    allEventLabelHistories: 'All Event Labels History',
                },
            },
        },
        requestAssistance: {
            requestAssistanceProfiles: 'Request Assistance Profiles',
            newRequestAssistanceProfile: 'New Request Assistance Profile',
            globalHistory: 'History',
            saveSuccess: 'Request Assistance Profile Successfully Saved',
            importTriageQuestions: 'Import Triage Questions',
            exportTriageQuestions: 'Export Triage Questions',
            RequestAssistanceProfilesAdmin: {
                adminListTitle: 'Profiles',
                toggleDeactivated: 'Show Deactivated',
            },
            RequestAssistanceProfilesAdminForm: {
                labels: {
                    name: 'Request Assistance Profile Name',
                    isEnabled: 'Active',
                    triageQuestions: 'Triage Questions',
                    integrationProtocol: 'Integration Protocol',
                    mustBeOnlineToInitiateRequest: 'Must Be Online To Initiate Request',
                    sendCallerDataPacketWithRequest: 'Send Caller Data Packet',
                    showReportPartyNameWithRequest: 'Send Reporting Party/Involved Person Name',
                    showReportPartyPhoneWithRequest:
                        'Send Reporting Party/Involved Person Phone Number',
                    enableStatusUpdate: 'Send Resource Status Updates',
                    assistanceRequestTimeout: 'Assistance Request Timeout (seconds)',
                    urgentAssistanceRequestTimeout: 'Urgent Assistance Request Timeout (seconds)',
                    narrativeShareTimeout: 'Narrative Share Timeout (seconds)',
                    urgentNarrativeShareTimeout: 'Urgent Narrative Share Timeout (seconds)',
                    outMessageNotReceiveTimeout: 'System Response Timeout (seconds)',
                    healthCheckInterval: 'Health Check Interval (seconds)',
                    statusQueryTimeout: 'Status Query Timeout (seconds)',
                },
                helpText: {
                    name: `Please consider any character limits that may apply to your agency's integration`,
                    mustBeOnlineToInitiateRequest: `For integration protocols providing online/offline
                        status, check this box if users should be prevented from initiating a request to
                        this profile if it's considered offline`,
                    sendCallerDataPacketWithRequest: `Select this option to send the event's caller data
                        packet with the request`,
                    showReportPartyNameWithRequest: `Select this option to send the event's Reporting Party
                        and Involved Person Names with the request`,
                    showReportPartyPhoneWithRequest: `Select this option to send the event's Reporting Party
                        and Involved Person Phone Numbers with the request`,
                    enableStatusUpdate: `Select this option if this profile will be sending/receiving
                        resource status updates`,
                    assistanceRequestTimeout: `Enter a number of seconds before users should be alerted that
                        the outgoing request operationally timed out`,
                    urgentAssistanceRequestTimeout: `Enter a number of seconds before users should be alerted
                        that the outgoing urgent request operationally timed out`,
                    narrativeShareTimeout: `Enter a number of seconds before users should be alerted that the
                        outgoing message operationally timed out`,
                    urgentNarrativeShareTimeout: `Enter a number of seconds before users should be alerted that
                        the outgoing urgent message operationally timed out`,
                    outMessageNotReceiveTimeout: `Enter a number of seconds before users should be alerted that
                        the responding system has not received the message`,
                    healthCheckInterval: `Enter a number of seconds for polling time`,
                    statusQueryTimeout: `Enter a number of seconds before users should be alerted that the
                        responding system has not provided the resource status update`,
                },
                validationStrings: {
                    nameRequired: 'This field is required',
                    nameLengthError: 'Request profile name length cannot exceed 64 characters',
                    positiveIntError: 'This field must be a positive integer',
                    integrationProtocolRequired: 'This field is required',
                },
            },
            RequestAssistanceProfilesAdminHistory: {
                labels: {
                    allRequestAssistanceHistories: 'All Request Assistance Profiles History',
                },
            },
        },
        locationSettingsAndRegionalPreferences: {
            title: 'Default Location Settings and Regional Preferences',
            defaultTimeZone: 'Default Time Zone',
            primaryTimeZone: 'Primary Time Zone',
            locationSetting: 'Location Setting',
            country: 'Country',
            measurementSystem: 'Measurement System',
            height: 'Height',
            weight: 'Weight',
            distance: 'Distance/Speed',
            settingConfiguredInDepartmentProfile: 'Setting configured in the Department Profile',
        },
        callForService: {
            footer: {
                lastModified,
            },
            successMessage: 'Call For Service Successfully Saved',
            CallForServiceAdminForm: {
                requiredError: 'This field is required',
                addAgencyTypeDropdownButton: 'AGENCY TYPE',
                agencyTypeWarning: 'Agency types cannot be changed after creation',
                helpText:
                    'When selected, this will create a new Event Form and prefill the CFS and the Event Origin for First Responders.',
            },
        },
        customreports: {
            SearchSyncRequiredModal: {
                title: 'Search Sync Required',
                info_message: `Updating the Report Type's Name, Display Title Format, Additional Review Requirement, or Ability to View Report Owner will require a full Search Sync of this Report Type, otherwise it's search results will be outdated.`,
                instructions: `After confirming, please navigate to the Search Sync page. In the Sync Type section, select Entity Type = REPORT, select this Report Type, and run a Search Sync.`,
            },
            CustomReportsAdmin: {
                pageTitle: 'Report Types',
                listTitle: 'Report Types',
                showDisabled: 'Show Disabled',
            },
            CustomReportsAdminForm: {
                reportTypeLabel: 'Report Type',
                reachOutToSupportToUpdateThisFieldTooltip:
                    'Please reach out to Mark43 support to update this field as a Search Sync may be required.',
                searchSyncReportIfThisFieldEditedTooltip:
                    'A search sync will be required for this report type if this field is edited.',
                reportDefinitionTitleFormat: 'Display Title Format',
                sequenceMaxLength: 'Number of Digits',
                sequenceMaxLengthHelpText:
                    'Number of digits to be used in sequence. Do not include prefix.',
                datePrefix: 'Date Prefix',
                dateFormat: 'Date Format',
                characterPrefix: 'Character Prefix',
                textBeforeDate: 'Character prefix (before date)',
                textBeforeSequence: 'Character prefix (before sequence)',
                displayNameDisabledNotice:
                    'To support cross agency report searching, Report Name is not editable.',
                defaultUserPermissions: {
                    title: 'Default User Permissions',
                    addText: 'Personnel',
                    roleId: 'User / Role',
                    operationType: 'Access Level',
                    lockReportOnApproval:
                        'Restrict ability to return report to draft after initial approval',
                    lockReportOnApprovalHelpText:
                        'Users with "can edit" and "can manage" permissions will be unable to return reports to draft after initial approval.',
                },
                defaultDepartmentPermissions: {
                    title: 'Default External Agency Permissions',
                    addText: 'Personnel',
                    roleId: 'Role',
                    operationType: 'Access Level',
                },
                isEnabled: 'Is Enabled',
                isEnabledHelpText:
                    'An enabled report type will appear as a value in search and filter inputs',
                isActive: 'Is Active',
                isActiveHelpText:
                    'An active report type will appear as an option for users creating new reports if the report is also Enabled',
                rolePermissions: {
                    isRestricted: 'Is Creation Restricted?',
                    isRestrictedHelpText:
                        'When "Yes", this report type can only be created by users in the specified roles. The "Create Reports" ability is also required.',
                    permissionedRoles: 'Permissioned Roles',
                },
                name: 'Name',
                abbreviation: 'Abbreviation',
                hasReportStatus: 'Has Report Status',
                hasOffenseStatus: (offenseDisplayName: string) =>
                    `Has ${offenseDisplayName} Status`,
                minRequiredApprovalLevel: 'Minimum Required Approval Level',
                terminalApprovalStatus: 'Terminal Approval Status',
                reportApprovalLevel: 'Does this report type require additional review?',
                allowMultiple: (renDisplayName: string) =>
                    `Allow multiple under ${renDisplayName}?`,
                reportHistoryVisible: 'Report History Visible',
                recordIdConfiguration: {
                    title: 'Record ID Configuration',
                    renConfiguration: (renDisplayName: string) =>
                        `Can this report type be linked to a ${renDisplayName}?`,
                    sequenceType: 'Record ID Type',
                    suffixType: 'Suffix Type',
                    yes: 'Yes',
                    no: 'No',
                    yesNoteDisplay: (renDisplayName: string) =>
                        `Multiple records of this type are allowed per ${renDisplayName}.`,
                    noNoteDisplay: (renDisplayName: string) =>
                        `Only one record of this type is allowed per ${renDisplayName}. The ID for this record type will be the ${renDisplayName}.`,
                    suffixTypeSelect: {
                        numeric: 'Numeric (eg. -1)',
                        alphabetic: 'Alphabetic (eg. A)',
                    },
                    autogeneratedSequenceType: 'Auto-Generated Sequence Type',
                    autogeneratedSequenceTypeSelect: {
                        sequential: 'Sequential',
                        random: 'Random',
                    },
                },
                reportConfiguration: {
                    title: 'Report Configuration',
                    defaultCards: 'Default Cards',
                    card: 'Card',
                    dragonCard: 'Custom',
                    name: 'Configured Card Names',
                    show: 'Show',
                    requiredCardHelpText:
                        'This card is required in this report type and cannot be hidden',
                    externalCardHelpText: 'To hide or rename this card, go to the forms admin page',
                    order: 'Order',
                    orderHelpText:
                        'Click and hold at the left side of a row to drag and drop a card, or click the up and down arrows',
                    downloadablesTitle: 'Downloadable Files',
                    loadError: 'Failed to load report card configuration, please reload.',
                    validationError:
                        'Invalid Report Configuration section. Fix errors and try again.',
                },
                informationCardOptions: {
                    eventInformation: 'Event Information',
                    supplementInformation: 'Supplement Information',
                    migrationInformation: 'Migration Information',
                    crashEventInformation: 'Crash Event Information',
                },
                selectInformationCard: 'Select Information card',
            },
            CustomReportsAdminDownloadablesForm: {
                placeholder: 'UI Display Name',
            },
            footer: {
                lastModified,
            },
            requiredError: 'This field is required',
            textLengthError: (maxLength: number) => `Must be at most ${maxLength} characters`,
            minMaxError: (min: number, max: number) => `Must be a number between ${min} and ${max}`,
            downloadablesMustHaveDisplayNameError:
                'All Downloadable Forms must have display names.',
            fileTooLargeError: 'Failed to upload file (too large)',
            genericDownloadablesError: 'Failed to upload file',
            newDefinition: 'New Report Type',
            supplementCardAndTitleNotMatchError:
                'The Supplement Information Card must be selected for the supplement description title format',
        },
        reportModules: {
            reportModule: 'Report Module',
            reportModules: 'Report Modules',
            instructions: [
                'Report modules appear only when the application settings rms.report_modules.enabled and rms.arc_navigation.enabled are true and the user has enabled the new navigation in their My Account page.',
                'Click the up and down arrows to re-order modules vertically in the left navigation.',
                'Drag and drop report types to move them between modules.',
            ],
            defaultHelpText: 'The default module cannot be edited',
            name: {
                label: 'Name',
                helpText:
                    "The name appears in the left navigation and at the top of the module's dashboard. The name should be short, otherwise it gets truncated. Check how it appears in the navigation when expanded or collapsed.",
            },
            url: {
                label: 'URL',
                defaultLabel: 'URL for Default Module',
                helpText:
                    "The URL should be short. After the module is created, the URL becomes uneditable in order to preserve old links from emails and elsewhere. If you need to change the URL and that's not a problem, then create a new module and delete this one.",
                validationError: 'Please enter only lowercase letters and hyphens',
            },
            icon: {
                label: 'Icon',
            },
            reportDefinitions: {
                label: 'Report Types',
                helpText: 'Each module must have at least one report type',
                defaultLabel: 'Report Types in Default Module',
                defaultHelpText:
                    "These are the remaining report types that don't belong to any other module",
                none: 'None',
            },
            moduleOrder: 'Order',
        },
        locationaliases: {
            LocationAliasesAdminForm: {
                labels: {
                    alias: 'Alias',
                },
            },
            footer: {
                lastModified,
            },
            newAlias: 'Add Location Alias',
            deleteAlias: 'Remove',
            listTitle: 'Location Aliases',
            selectAliasError: 'Alias not found',
            purge: 'Purge all',
            confirmationModal: {
                title: 'Purge All Aliases?',
                body: 'Are you sure you want to purge all location aliases?',
                confirmLabel: 'Purge',
            },
        },
        pointLocations: {
            listTitle: 'Point Locations',
            helpText:
                'No locations will appear until a minimum of four characters (excluding spaces) are typed',
            PointLocationsAdminForm: {
                loadingError: 'Error loading required data',
                noSubdivisions: 'No subdivisions specified',
            },
            purge: 'Purge all',
            confirmationModal: {
                title: 'Purge All Point Locations?',
                body: 'Are you sure you want to purge all point locations?',
                confirmLabel: 'Purge',
            },
        },
        users: {
            title: 'Users',
            newUser: 'New User',
            UsersDashboardSearchForm: {
                placeholder: 'Name, Badge #, Email',
                filterUsers: 'Filters',
                clearFilters: 'Clear filters',
                filterModal: {
                    title: 'Filters',
                    okText: 'Apply Filters',
                    cancelText: 'Clear Filters',
                    dutyStatuses: 'Duty Status',
                    training: 'Training',
                    skills: 'Skills',
                    rankAttrIds: 'Rank',
                    unitAttrIds: 'Unit',
                    roleIds: 'Role',
                    abilityIds: 'Ability',
                    employeeTypeAttrIds: 'Employee Type',
                    accountStatus: 'Account Status',
                },
            },
            UsersDashboardSearchResultsHeader: {
                unsaveSearch: 'Unsave search',
                saveSearch: 'Save search',
                inactiveToggle: 'Inactive users (90+ Days)',
            },
            dashboard: {
                results: {
                    columns: {
                        user: 'user',
                        assignment: 'assignment',
                        dutyStatus: 'duty status',
                        roles: 'roles',
                        hasMfaConfig: 'MFA configured',
                    },
                    actionButtons: {
                        notificationSettings: 'Notification Settings',
                        deleteMfaConfigs: 'Delete MFA device(s)',
                    },
                    mfaConfigModal: {
                        title: 'Delete MFA device(s)',
                        selectLabel: 'Delete MFA device(s)',
                        okText: 'Confirm',
                        cancelText: 'cancel',
                        errorText: 'Failed to delete MFA device(s)',
                        noMfaDevicesToDelete: 'No MFA devices to delete',
                        mfaDevicesToDelete: (numUsers: number) =>
                            `Delete MFA devices for ${numUsers} user(s).`,
                    },
                    bulkUpdateModal: {
                        title: 'Edit Notification Settings',
                        selectLabel: 'Notification Setting',
                        emailDeliveryLabel: 'Email',
                        inAppLabel: 'In-App',
                        radioOptionOnLabel: 'On',
                        radioOptionOffLabel: 'Off',
                        addNotificationChangeLabel: 'Add Notification Change',
                        okText: 'save',
                        cancelText: 'cancel',
                        inAppDeliveryRadioInputError: 'inAppDelivery selection cannot be empty',
                        emailDeliveryRadioInputError: 'email Delivery selection cannot be empty',
                        notificationTypeInputError:
                            'notification type cannot be empty & must be unique',
                        usersSelectedText: (totalUsers: number) =>
                            `${totalUsers} user(s) will receive the selected override(s) to their notification settings.`,
                    },
                    bulkUpdateSuccess: 'Bulk update of notification settings kicked-off',
                    bulkUpdateFailure: 'Bulk update of notification settings failed',
                    cells: {
                        user: {
                            displayName: (
                                firstName: string,
                                lastName: string,
                                badgeNumber: string
                            ) =>
                                `${firstName} ${lastName} ${
                                    badgeNumber ? `(#${badgeNumber})` : ''
                                }`,
                            inactive: 'inactive',
                        },
                        roles: {
                            additionalRoles: (length: number) => `+ ${length} more`,
                        },
                        assignment: {
                            title: 'Title',
                            rank: 'Rank',
                            branch: 'Branch',
                            bureau: 'Bureau',
                            unit: 'Unit',
                            division: 'Division',
                            otherAssignments: (num: number) => `+ ${num} more`,
                        },
                        dutyStatus: (dutyStatus: string, dateEffectiveStart: string) =>
                            `${dutyStatus} (as of ${dateEffectiveStart})`,
                    },
                    allSelected: (totalResults: number) =>
                        `All ${totalResults} results are selected.`,
                    clearSelection: 'Clear Selection',
                    selectAll: (totalResults: number) =>
                        `Select all ${totalResults} results to update settings.`,
                    allOnPageSelected: (selected: number) =>
                        `All ${selected} results on this page are selected.`,
                    someOnPageSelected: (selected: number) => {
                        if (selected === 1) {
                            return '1 result on this page is selected.';
                        } else {
                            return `${selected} results on this page are selected.`;
                        }
                    },
                },
            },
        },
        roles: {
            SaveBar: {
                save: 'Save',
            },
            ManageRoles: {
                title: 'Roles',
                newRole: 'New Role',
                ColumnHeaders: {
                    title: 'Role',
                    lastModified: 'Last Modified',
                    remove: 'Remove',
                },
                noResults: 'There are 0 results in Roles based on the current filter settings.',
                filterRoles: 'Filter Roles',
                allDepartmentUsers: 'All Department Users - ',
                internal: 'Internal - ',
                lastModified: (date: string, initial: string, last: string, updatedBy: string) =>
                    `${date} by ${initial} ${last} #${updatedBy}`,
                AddNewRoleSidePanel: {
                    title: 'New Role',
                },
                AddNewRoleForm: {
                    roleName: 'Role Name',
                    sessionTimeoutLength: 'Session Timeout Length',
                },
            },
            RolesHamburgerMenu: {
                import: 'Import Role Abilities',
                export: 'Export Role Abilities',
            },
            EditRole: {
                title: 'Edit Role',
                users: 'Users',
                abilities: 'Abilities',
                approvals: 'Approvals',
                editName: 'Edit Role',

                EditRoleName: {
                    title: 'Edit Role',
                    name: 'Role Name',
                    sessionTimeoutLength: 'Session Timeout Length',
                    cjisComplianceMessage:
                        'CJIS requirement states that users must re-authenticate at least once every 12 hours and after 30 minutes of inactivity.',
                },
            },
            DeleteRole: {
                title: 'Delete Role',
                body: 'Delete Role is a permanent action and cannot be undone. All of your data will be lost.',
            },
            RoleUsersActions: {
                filterPlaceholder: 'Search Users (Name, Badge #)',
                assignButton: 'Assign User',
                AssignUserRoleSidePanel: {
                    title: 'Assign User(s)',
                    AssignUserRoleForm: {
                        label: 'Users',
                        includeInactiveUsers: 'Include inactive users',
                    },
                },
            },
            RoleUsersTableHeaders: {
                name: 'Name',
                manage: 'Manage',
                lastModified: 'Last Modified',
            },
            RoleUsers: {
                noResults: 'There are 0 results in Users based on the current filter settings.',
                labels: {
                    canManage: 'Can Manage',
                },
            },
            RoleAbilitiesActions: {
                filterPlaceholder: 'Search Abilities (Name, Description)',
                enabledOnly: 'Only Show Enabled Abilities',
            },
            RoleAbilitiesTableHeaders: {
                category: 'Category',
                active: 'Active',
                nameDescription: 'Name/Description',
                enabled: 'Enabled',
            },
            RoleAbilities: {
                lastModified: 'Last Modified',
                save: 'Save',
            },
            ModuleRow: {
                labels: {
                    isActive: 'Active',
                },
            },
            ModuleAbilities: {
                hide: 'Hide',
                show: 'Show',
                noAbilities: 'No abilities for this module',
            },
            AbilityRow: {
                labels: {
                    enabledYes: 'Yes',
                    enabledNo: 'No',
                },
            },
            RoleApprovals: {
                labels: {
                    approvalLevel: 'Approval Level',
                    approvalStatus: 'Approval Status',
                },
                lastModified: 'Last Modified',
            },
            ToggleAbilityModal: {
                enable: {
                    title: {
                        single: 'Enable Depended Ability',
                        multiple: 'Enable Depended Abilities',
                    },
                    body: {
                        single: 'The selected ability depends on an additional ability that is currently disabled. By enabling the selected ability, you will also enable the following ability:',
                        multiple:
                            'The selected ability depends on additional abilities that are currently disabled. By enabling the selected ability, you will also enable the following abilities:',
                    },
                    confirmation: {
                        single: 'Would you like to enable both abilities now?',
                        multiple: 'Would you like to enable these abilities now?',
                    },
                },
                disable: {
                    title: {
                        single: 'Disable ability with Dependent',
                        multiple: 'Disable ability with Dependents',
                    },
                    body: {
                        single: 'The selected ability has a dependent ability that is currently enabled. By disabling the selected ability, you will also disable the following dependent ability:',
                        multiple:
                            'The selected ability has dependent abilities that are currently enabled. By disabling the selected ability, you will also disable the following dependent abilities:',
                    },
                    confirmation: {
                        single: 'Would you like to disable both abilities now?',
                        multiple: 'Would you like to disable these abilities now?',
                    },
                },
            },
        },
        responsePlansAdmin: {
            subheaderTitle: 'Response Plans',
        },
        tiebreakersAdmin: {
            updateFormSuccess: 'Successfully updated',
            updateFormFailure: 'Failed to load Tiebreaker Form',
            updateFormFailureTitle: 'Error Updating Tiebreakers',
            timeSinceLastHint:
                'Using the most recent time of an action to determine recommended units.',
            lowerPriorityStatusHint: 'Select units you would prefer to lower priority status.',
            saveForm: 'Save',
            timeSinceLastTitle: 'Time Since Last',
            lowerPriorityStatusTitle: 'Lower Priority Status',
        },
        csvUploaderAdmin: {
            resourcePatterns: {
                title: 'Resource Patterns',
                item: 'Resource Pattern',
                path: 'resource-patterns',
                apiBase: 'resource_pattern',
            },
            runOrder: {
                title: 'Run Order',
                item: 'Run Order',
                path: 'run-order',
                apiBase: 'run_card',
            },
            moveUps: {
                title: 'Move Ups',
                item: 'Move Ups',
                path: 'move-ups',
                apiBase: 'move_ups',
            },
        },
        shapefiles: {
            ShapefilesAdminForm: {
                labels: {
                    displayName: 'Display Name',
                    geojsonFileId: 'Geojson File ID',
                },
            },
            newShapefile: 'Upload Shapefile',
            deleteShapefile: 'Remove',
            downloadShapefile: 'Download',
            listTitle: 'Shapefiles',
            selectShapefile: 'Shapefile not found',
        },
        subdivisions: {
            displayNames: {
                SUBDIVISION_DEPTH_1: '', // see field-configurations/state/data/index.js // KEEP
                SUBDIVISION_DEPTH_2: '', // see field-configurations/state/data/index.js // KEEP
                SUBDIVISION_DEPTH_3: '', // see field-configurations/state/data/index.js // KEEP
                SUBDIVISION_DEPTH_4: '', // see field-configurations/state/data/index.js // KEEP
                SUBDIVISION_DEPTH_5: '', // see field-configurations/state/data/index.js // KEEP
                BUREAU: 'Bureau',
                DIVISION: 'Division',
                BRANCH: 'Branch',
                PERSONNEL_UNIT: 'Unit',
            },
            fire: 'Fire',
            fireDispatchArea: 'Fire Dispatch Area',
            police: 'Police',
            policeDispatchArea: 'Police Dispatch Area',
            ems: 'EMS',
            emsDispatchArea: 'EMS Dispatch Area',
            tableColumns: {
                shapefile: 'SHAPEFILE',
                displayName: 'DISPLAY NAME',
                class: 'CLASS',
                property: 'PROPERTY',
                name: 'NAME',
                count: 'COUNT',
                updated: 'UPDATED',
                change: 'CHANGE',
                type: 'TYPE',
                primary: 'PRIMARY',
            },
            viewMap: 'VIEW',
            deptTitle: (dept: string) => `${dept} Subdivisions`,
            manage: 'Manage Subdivisions',
            title: 'Shapefiles and Subdivisions',
            wizard: {
                addDispatchArea: 'Dispatch Area',
                back: 'BACK',
                dispatchArea: 'Dispatch Area',
                dispatchAreaOojNameHelpText:
                    'Out of Jurisdiction Name - refers to the name of the dispatch area that all out of jurisdiction calls will be assigned to.',
                title: 'Shapefiles and Subdivisions Setup Wizard',
                shapefileLabel: 'Shapefile',
                newShapefile: 'UPLOAD SHAPEFILE',
                newSubdivision: 'SUBDIVISION',
                police: 'POLICE',
                fire: 'FIRE',
                ems: 'EMS',
                level: 'Level',
                reportingArea: 'Reporting Area',
                viewOnMap: 'VIEW',
                reportingAreaHelpText:
                    'Reporting Area - refers to the lowest level breakdown of the geography of your jurisdiction.',
                noDeleteHelpText: 'The saved reporting area cannot be deleted',
                oojHelpText:
                    'Out of Jurisdiction Name - refers to the individual subdivision name that will be used when a location falls outside of the bounds of the shapefile data.',
                oojAbbrHelpText:
                    'Out of Jurisdiction Code - refers to the abbreviation of the Out of Jurisdication Name',
                added: 'ADDED',
                removed: 'REMOVED',
                downloadables: 'Downloadable CSVs',
                fireAttributesCSV: 'Fire Attributes CSV File',
                policeAttributesCSV: 'Police Attributes CSV File',
                emsAttributesCSV: 'EMS Attributes CSV File',
                fireSubdivisionsCSV: 'Fire Subdivisions CSV File',
                policeSubdivisionsCSV: 'Police Subdivisions CSV File',
                emsSubdivisionsCSV: 'EMS Subdivisions CSV File',
                formLabels: {
                    dispatchAreaOojDescription: 'Out of Jurisdiction Description',
                    dispatchAreaOojName: 'Out of Jurisdiction Name',
                    displayName: 'Display Name',
                    subdivisionName: 'Subdivision Name',
                    property: 'Shapefile Property',
                    shapefile: 'Shapefile',
                    oojValue: 'Out of Jurisdiction Name',
                    oojAbbr: 'Out Of Jurisdiction Code',
                },
                forward: {
                    mapSubdivisions: 'MAP SUBDIVISIONS',
                    previewAndExit: 'PREVIEW AND EXIT',
                    save: 'SAVE AND EXIT',
                },
                stepTitles: {
                    uploadShapefiles: 'Upload Shapefiles (Optional)',
                    manageSubdivisions: 'Map Subdivisions',
                    summary: 'Summary',
                },
            },
            beatHelpText:
                'Choose one police subdivision to be your Primary Beat, which units can be assigned to in the admin or in the app.',
        },
        geoSubdivisions: {
            title: 'Shapefiles and Subdivisions',
            buttons: {
                manageSubdivisions: 'MANAGE SUBDIVISIONS',
            },
            classes: {
                fire: 'Fire',
                fireDispatchArea: 'Fire Dispatch Area',
                fireDispatchAreaShort: 'Fire D-Area',
                police: 'Police',
                policeDispatchArea: 'Police Dispatch Area',
                policeDispatchAreaShort: 'Police D-Area',
                ems: 'EMS',
                emsDispatchArea: 'EMS Dispatch Area',
                emsDispatchAreaShort: 'EMS D-Area',
            },
            dispatchArea: 'Dispatch Area',
            labels: {
                noShapefiles: 'No shapefiles have been configured yet',
                uploadDirections:
                    'Upload a new shapefile or click on Manage Subdivisions to use an existing shapefile to configure subdivisions.',
            },
            manageView: {
                agency: 'Agency',
                dispatchAreas: 'Dispatch Areas',
                addSubdivision: 'Add Subdivision',
                addDispatchArea: 'Add Dispatch Area',
                reviewChanges: 'Review Changes',
                saveChanges: 'Save',
                shapefileLabel: 'Shapefile',
                shapefilePropertyLabel: 'Shapefile Property',
                shapefileNameLabel: 'Subdivision Name',
                oojNameLabel: 'Out of Jurisdiction Name',
                oojCodeLabel: 'Out of Jurisdiction Code',
                exitMessage:
                    'Are you sure you wish to exit from managing subdivisions? Any changes will not be saved.',
                agencyChangeMessage:
                    'Selecting another agency will remove any unsaved changes in this form.',
            },
            reviewView: {
                title: 'Review Changes',
                dispatchArea: 'Dispatch Area',
            },
        },
        officerDemographicsRIPA: {
            displayNames: {
                STOP_DATA_OFFICER_RACE: 'Officer Race/Enthnicity',
                STOP_DATA_OFFICER_GENDER: 'Officer Gender',
            },
        },
        trainingAndSkills: {
            title: 'TRAINING / SKILLS',
            displayNames: {
                USER_SKILL: 'Skills',
                USER_TRAINING: 'Training',
            },
        },
        evidenceConfig: {
            DispositionApprovalFieldset: {
                alternate: 'Alternate',
                alternateSubtitle:
                    'Users in these selected roles will receive disposition approval requests if no users exist in the selected "Preferred" roles.',
                preferred: 'Preferred',
                perferredSubtitle:
                    'Users in these selected roles will always receive disposition approval requests unless no such users exist.',
                title: 'Disposition Approval Settings',
            },
            DispositionPeriodsFieldset: {
                title: 'Release Timer Settings',
            },
            EvidenceConfigAdmin: {
                title: (evidenceModuleName: string) =>
                    `${prettify(evidenceModuleName)} Configuration`,
            },
            EvidenceConfigAdminForm: {
                saveSuccess: 'Settings successfully saved.',
            },
            ItemTypesFieldset: {
                title: 'Item Type Settings',
            },
            LabelLayoutElementsFieldset: {
                title: 'Label Customization',
                labels: {
                    variables: 'Variables',
                    labelPreview: 'Label Preview',
                },
                or: 'OR',
                useExistingTemplate: 'USE EXISTING TEMPLATE',
            },
            LabelPrintersFieldset: {
                title: 'Label Printers',
                addText: 'Label Printer',
            },
            UploadCustomLabelSection: {
                uploadCustomLabel: 'UPLOAD CUSTOM LABEL',
                dragAndDrop: 'Drag and drop to upload SVG file or',
                maxFileSize: 'Max file size 3GB',
                browse: 'Browse',
                deleteCustomLabel: 'Delete Custom Label?',
                actionCannotBeUndone: 'This action cannot be undone.',
                delete: 'Delete',
                cancel: 'Cancel',
            },
        },
        evidencePrintingTemplates: {
            EvidencePrintingTemplatesAdmin: {
                title: (evidenceModuleName: string) =>
                    `${prettify(evidenceModuleName)} Document Settings`,
                newEvidencePrintingTemplate: 'New Document',
                listTitle: 'Documents',
            },
            EvidencePrintingTemplateAdminForm: {
                deleteButton: 'Delete',
                newTitle: 'New Document',
                uploadButton: 'Attachment',
            },
        },
        evidenceChainEventTypes: {
            EvidenceChainEventTypesAdmin: {
                title: 'Chain Event Types',
                newChainEventType: 'New Chain Event Type',
            },
            EvidenceChainEventTypesAdminForm: {
                chainEventCategoryHelpText: {
                    [ChainEventCategoryEnum.CHECKED_IN_MAIN.name]: 'Item in permanent storage',
                    [ChainEventCategoryEnum.CHECKED_OUT_LAB.name]:
                        'Item temporarily in custody of other agency',
                    [ChainEventCategoryEnum.CHECKED_OUT_PERSON.name]:
                        'Item temporarily in custody of person',
                    [ChainEventCategoryEnum.TRANSFERRED.name]:
                        'TERMINAL - Item permanently transferred to other agency',
                    [ChainEventCategoryEnum.RELEASED.name]: 'TERMINAL - Item released to civilian',
                    [ChainEventCategoryEnum.DISPOSITIONED.name]: 'TERMINAL - Item dispositioned',
                    [ChainEventCategoryEnum.IN_POLICE_CUSTODY.name]:
                        'Item taken into custody still in the field',
                    [ChainEventCategoryEnum.CHECKED_IN_TEMP.name]:
                        'Item dropped off in temporary locker',
                    [ChainEventCategoryEnum.LOCATION_UPDATED.name]:
                        'Item moved to location within the facility',
                    [ChainEventCategoryEnum.MIGRATED.name]:
                        'Chain events migrated from legacy system',
                    [ChainEventCategoryEnum.LOCATION_UPDATED_AUTOMATICALLY.name]:
                        'Item location updated by system during audit',
                    [ChainEventCategoryEnum.INFIELD_TRANSFER.name]:
                        'Item still in the field being transferred with an officer',
                },
            },
        },
        evidenceRetentionPolicies: {
            RetentionPoliciesAdmin: {
                title: 'Retention Policies',
                listTitle: 'Retention Policies',
                newRetentionPolicy: 'New Retention Policy',
            },
            RetentionPolicyAdminForm: {
                helpText: {
                    isInfinite:
                        'When checking this box for indefinite hold, do not select No Approval as the terminal disposition approval level; instead, select either Initial Approval or Secondary Approval.',
                },
                retentionPeriod: {
                    label: 'Retention Period',
                    periodUnit: {
                        days: 'Days',
                        months: 'Months',
                        years: 'Years',
                    },
                    storeForLimitedTime: 'Store for limited time',
                    storeIndefinitely: 'Store indefinitely',
                },
            },
        },
        printingTemplates: {
            PrintingTemplatesAdmin: {
                title: 'Printing Templates',
                newPrintingTemplate: 'New Template',
            },
            PrintingTemplatesAdminForm: {
                saveSuccess: 'Template saved successfully.',
                deleteButton: 'Delete',
                uploadButton: 'Upload',
                labels: {
                    printingDataType: 'Data Type',
                    printingTemplateType: 'Template Type',
                    includeAttachments: 'Include Attachments',
                },
            },
        },
        evidenceFacilities: {
            EvidenceFacilitiesAdmin: {
                titles: {
                    locationsSettings: 'Storage Locations',
                    facilityAdminList: 'Core Facilities',
                    facilityAdminListSub: 'Core Facility',
                    storageAdminList: 'Sub-Location',
                },
                labels: {
                    printAllFacilityLabels: 'Print all barcodes',
                    printAllStorageLabels: 'Print all barcodes',
                },
                showExpired: 'show expired',
                temporary: 'Temporary',
            },
            FacilityImport: {
                import: 'Import Sub-Locations',
                export: 'Export Sub-Locations',
                disabledImportTooltip:
                    'Cannot import to a facility that already contains sub-locations (even expired locations)',
            },
            FacilityAdminForm: {
                titles: {
                    add: 'Add Location',
                    edit: 'Edit Location',
                },
                labels: {
                    locationName: 'Name of Location',
                    streetAddress: 'Street Address',
                    subPremise: 'Floor, Room #',
                    locality: 'City',
                    adminArea1: 'State',
                    postalCode: 'Zip',
                    latitude: 'Latitude',
                    longitude: 'Longitude',
                    phoneNumber: 'Phone Number',
                    description: 'Description',
                    expiredDateUtc: 'Expiration Date / Time',
                },
            },
            StorageLocationAdminForm: {
                titles: {
                    add: 'Add Location',
                    edit: 'Edit Location',
                },
                labels: {
                    displayValue: 'Name of Location',
                    description: 'Description',
                    expiredDateUtc: 'Expiration Date / Time',
                    parentStorageLocation: 'Parent Location',
                    reset: 'Reset',
                    isTemporary: 'Is Temporary',
                    isHighValueLocation: 'High Risk Storage Location',
                },
                highValueTooltip:
                    "This sub-location is set as high risk due to parent location. Change parent location's value to allow change of this value",
            },
            DeleteStorageModal: {
                title: 'Expire Sub-Location',
                alreadyExpiredWarning: (label: string) =>
                    `The sub-location “${label}” is already expired.`,
                hasEvidenceItemsWarning: ({
                    label,
                    evidenceModuleName,
                }: {
                    label: string;
                    evidenceModuleName: string;
                }) =>
                    `The sub-location “${label}” cannot be expired because there are existing ${evidenceModuleName?.toLowerCase()} items associated with it.`,
                hasStorageLocationChildren: (label: string) =>
                    `Expiring this location will expire all of its sub-locations. Are you sure you want to expire “${label}”?`,
                areYouSure: (label: string) =>
                    `Are you sure you want to expire the sub-location “${label}”?`,
                delete: 'Expire',
                cancel: 'Cancel',
                close: 'Close',
                notFoundError: 'Storage location not found, please close this box and try again.',
            },
            MoveStorageModal: {
                title: 'Update Location',
                message: 'This change will update the chain of custody for all items in ',
                confirm: 'Confirm',
            },
            ManageStoragePermissionsModal: {
                okText: 'Save',
                notFoundError: 'Storage location not found, please close this box and try again.',
                tooltipText:
                    "Evidence locations will inherit their parent location's permission settings unless configured specifically",
                deletePermissionsTitle: 'Delete Permissions',
                editPermissionsTitle: 'Edit Permissions',
                confirmationMessage:
                    'Evidence locations will inherit their parent location’s settings unless configured specifically. Do you wish to proceed?',
                proceed: 'Proceed',
            },
            CreateNewPermissionsModal: {
                title: 'Create new permissions',
                message:
                    'This location has no permissions of its own and adopts those of its parent location. Do you want to create new permissions?',
                createButton: 'Create New Permissions',
                viewButton: 'View Parent Permissions',
                cancelButton: 'Cancel',
            },
        },
        importexport: {
            AdminImportExportHamburgerMenu: {
                import: 'Import Template',
                export: 'Export Template',
            },
            importPhase: {
                importing: 'importing',
                validating: 'validating',
            },
            successfulValidation: {
                title: 'Validation Successful',
                message: (successMessage: string) =>
                    `A .xlsx file detailing the changes you are attempting to make has been exported. ` +
                    `If you are satisfied with the changes, press OK to continue or CANCEL to abort. ${successMessage}`,
            },
            genericExportError: 'The export failed, please try again.',
            genericImportError: 'The import failed, please try again.',
            bulkImportError: (importPhase: string) =>
                `Error occurred while ${importPhase} template records. In the case that the error is a result of invalid data in the template, please resolve the error and log a triage ticket to better handle this error in the future : `,
            validateImportError: (errorCount: number) =>
                `This template has ${errorCount} error${errorCount > 1 ? 's' : ''}.`,
            importValidationExpectedValueLabel: 'Expected:',
            importValidationActualValueLabel: 'Actual:',
            importExportModalConfirm: 'OK',
            importExportModalCancel: 'CANCEL',
            noFileDataOnUploadError:
                'Received no file data after uploading a CSV from the local file system.',
        },
        global: {
            save: 'save',
            cancel: 'cancel',
            delete: 'delete',
            showMore: 'SHOW MORE',
            remove: 'Remove',
        },
        adminListStatuses: {
            ACTIVE: 'Active',
            SCHEDULED: 'Scheduled',
            EXPIRED: 'Expired',
        },

        dutyStatus: {
            title: 'Duty Status',
        },

        identifiers: {
            title: 'Identifiers',
        },

        assignment: {
            title: 'Assignment',
        },

        contactInfo: {
            title: 'Contact Info',
        },

        emergencyContactInfo: {
            title: 'Emergency Contact Info',
        },
        streetAliases: {
            title: 'Street Search Aliases',
            listTitle: 'Aliases',
            newAlias: 'New Street Search Alias',
            listItemTitle: (streetName: string) => `Street Name: ${streetName}`,
            listItemSubtitle: (alias: string) => `Alias: ${alias}`,
            remove: 'Remove',
            labels: {
                streetName: 'Street Name (e.g. 6th Ave)',
                alias: 'Alias For Street (e.g. Avenue of the Americas)',
            },
            footer: {
                lastModified,
            },
        },
        streetCenterLines: {
            StreetCenterLinesAdmin: {
                uploadNewStreetCenterLineFile: 'Upload street centerline shapefile',
            },
            StreetCenterLinesAdminForm: {
                pleaseUploadFile: 'Please upload a street center line file',
                uploadSuccess: 'Street center line file succcessfully uploaded!',
                defaultStateRequirePattern: 'This field must contain 2 alpha characters',
                fields: {
                    versionName: 'Version Name',
                    versionDescription: 'Version Description',
                    defaultState: 'Default State',
                    defaultStreetWidth: 'Default Street Width',
                    defaultCity: 'Default City',
                    defaultPostalCode: 'Default Postal Code',
                    databaseColumn: 'Database column',
                    shapefileColumn: 'Shapefile column',
                },
            },
        },
        fieldConfigurations: {
            selectFieldConfigurationError: (fieldConfigurationId: number) =>
                `Field Configuration id '${fieldConfigurationId}' was not found.`,
            saveError: 'Failed to update field configurations.',
            fieldConfigurationFieldsetTitle: 'Field',
            fieldConfigurationContextsFieldsetTitle: 'Contexts',
            rulesSectionTitle: 'Involved Rules',
            fieldDetailFieldName: 'Internal Name',
            fieldDetailDescription: 'Internal Description',
            fieldDetailFieldType: 'Field Type',
            fieldDetailFieldTypeMappedIdAttributeType: 'Attribute Type',
            fieldDetailGenericFieldTypeMappedIdAttributeType: 'Generic Attribute Type',
            fieldDetailGenericFieldTypeMappedIdLinkType: 'Generic Link Type',
            fieldDetailLogicalMin: 'Absolute minimum',
            fieldDetailLogicalMax: 'Absolute maximum',
            fieldDetailCanStaticallyHideInAnyContext: 'Can ever be hidden in a context?',
            fieldDetailContextCanStaticallyHideInContext: 'Can hide in context?',
            ruleDisplayName: 'Rule Name',
            ruleConfigurtionContextIsDisabled: 'Status',
            showInternal: 'Show Internal',
            ShowAllFieldsConfirmationModal: {
                title: 'Show All Fields',
                message: (yesCount: number, total: number) =>
                    `Of the ${total} fields in contexts, the ${yesCount} fields displayed in this table currently have "Always Hide In Context?" set to Yes. Clicking OK will update the ${yesCount} values to No. This operation cannot be undone. Since many fields are meant only for specific agencies, you will have to hide those fields again.`,
                errorMessage: 'Failed to show all fields',
            },
        },
        rules: {
            addContext: 'Add Context',
            form: 'Form',
            missingRuleCondition: 'Enter missing rule condition(s) in "Logical Conditions" section',
            newRule: 'New Rule',
            selectRuleError: (ruleId: number) => `Rule id '${ruleId}' was not found.`,
            ruleFieldsetTitle: 'Rule',
            ruleConfigurationContextIsEnabledInContext: 'Is Enabled In This Context?',
            ruleConfigurationContextsFieldsetTitle: 'Contexts',
            ruleActionedFieldsFieldsetTitle: 'Actioned Fields',
            ruleName: 'Internal Name',
            ruleDisplayName: 'Display Name',
            ruleRuleType: 'Rule Type',
            ruleCanDisableInAnyContext: 'Can ever be disabled in a context?',
            ruleConfigurationPanelErrorMessageHelpText:
                'To include the display name of a field in the error message text, follow the format {{INTERNAL_NAME}}. INTERNAL_NAME is the internal name of the field found on the Fields admin page.',
            ruleConfigurationContextRuleFailureDisplayText: 'Panel Error Message',
            ruleConfigurationContextIsDisabled: 'Status',
            ruleActionedFieldsTableHeaderFieldNameColumn: 'Field Name',
            ruleActionedFieldsTableHeaderRuleFailureDisplayTextColumn: 'Field Error Message',
            ruleActionedFieldsRuleFailureDisplayTextNull: '(none)',
            deleteConfirmationModal: {
                body: 'This will permanently delete this rule in this department. This action cannot be undone.',
                confirmLabel: 'Delete Rule',
                success: 'Rule successfully deleted',
            },
            ruleConditions: {
                argument: 'Argument',
                argumentName: 'Name',
                argumentType: 'Type',
                argumentValue: 'Value',
                cancel: 'Cancel',
                convertFromBooleanWarning:
                    'Converting from a boolean condition will destroy all nested conditions',
                logicalConditionsIn: 'Logical Conditions in ',
                notation: ' Notation',
                ruleCondition: 'Rule Condition',
                ruleFieldGetter1Arguments: 'Rule Field Getter 1 Arguments',
                ruleFieldGetter2Arguments: 'Rule Field Getter 2 Arguments',
                save: 'Save',
                selectAConditionType: 'Select a Condition Type',
            },
            NewRuleModal: {
                cancel: 'Cancel',
                createNew: 'Create New',
                displayName: 'Display Name:',
                duplicate: 'Duplicate',
                helpText:
                    'Enter a rule id from any tenant in the same environment. The rule id is the number at the end of the url when viewing the rule in the Rules Admin page. You will have a chance to view and edit the rule prior to saving.',
                ruleIdToCopy: 'Rule ID to Copy',
                ruleName: 'Rule Name:',
                title: 'Add New Rule',
            },
        },
        streetSegments: {
            title: 'Street Segments',
            listTitle: 'Segments',
            newStreetSegment: 'New Street Segment',
            remove: 'Remove',
            downloadStreetCenterline: 'Download Centerline',
            footer: {
                lastModified,
                lastModifiedAndSynced,
            },
        },
        locationCautions: {
            footer: {
                lastModified,
            },
        },
        externalLinks: {
            ExternalLinksAdmin: {
                newExternalLink: 'New External Link',
            },
            ExternalLinksAdminForm: {
                removeExternalLink: 'Remove',
                footer: {
                    lastModified,
                },
            },
        },
        narrativeGuides: {
            NarrativeGuidesAdmin: {
                newNarrativeGuide: 'New Guide',
            },
            NarrativeGuidesAdminForm: {
                removeNarrativeGuide: 'Remove',
                narrativeGuideAdminFormSaveDisabledNotice:
                    'All fields must be complete to save this Guide',
            },
            availabilityOptions: {
                briefing: 'Briefing',
                report: 'Narrative',
            },
        },
        warrantNumberConfiguration: {
            WarrantNumberConfigurationAdmin: {
                new: 'New Warrant Number Sequence',
            },
            WarrantNumberConfigurationAdminForm: {
                unnamed: 'Unnamed',
                remove: 'Remove',
                note: 'Please ensure that the Warrant Number Sequence Type is set to Multiple on the Warrant Configuration Admin Page to use this number series.',
            },
        },
        exportPresets: {
            ExportPresetsAdmin: {
                defaultPrefix: '(Default) - ',
                exportPreset: ' Export Preset',
                newExportPreset: 'New Export Preset',
            },
            ExportPresetsAdminForm: {
                allReportTypes: 'All Report Types',
                or: 'OR',
                someReportTypes: 'Some Report Types',
                releaseTracking: 'Release Tracking',
                removeExportPreset: 'Remove',
                currentReportDescriptionText:
                    'This default preset appears in the report Export dropdown menu, and enables the user to export the current report they are viewing.',
                officerReportDescriptionText:
                    'This default preset appears in the report Export dropdown menu, and enables the user to export the version of the report as it was when submitted by an officer and approved by their supervisor.',
                exportOptions: 'Export Options',
                reportType: 'Report Type',
                exportPresetAdminFormSaveDisabledNotice: (
                    releasedToField: string,
                    dropdownField: string
                ) => {
                    return `"${releasedToField}" must be filled in to save this Export Preset if "${dropdownField}" is checked`;
                },
                noReportTypeSelectedError:
                    'For each report type added, at least one printing template must be selected.',
                reportVersion: 'Report Version',
                exportReportVersionOptions: {
                    [ExportReportVersionEnum.CURRENT.name]: 'Current Report',
                    [ExportReportVersionEnum.OFFICER.name]: 'Officer Report',
                    [ExportReportVersionEnum.UNKNOWN.name]: '',
                },
            },
        },
        settings: {
            labels: {
                requiredAbility: 'Required abilities:',
            },
        },
        mentionsConfiguration: {
            MentionsConfigurationAdmin: {
                header: {
                    title: 'MENTION',
                    subtitle: 'Enabled',
                },
                mentionTypes: {
                    [MentionCategoryEnum.DATE.name]: {
                        description:
                            'Relevant date and time stamps including: today, first dispatched, first on scene, call time, event start date and event end date',
                    },
                    [MentionCategoryEnum.LOCATION.name]: {
                        description: 'List of associated event location(s) within report',
                    },
                    [MentionCategoryEnum.OFFICER.name]: {
                        description: 'List of associated officer(s) within the report',
                    },
                    [MentionCategoryEnum.ORGANIZATION.name]: {
                        description: 'List of associated organizations within the report',
                    },
                    [MentionCategoryEnum.PERSON.name]: {
                        description: 'List of associated people within the report',
                    },
                    [MentionCategoryEnum.PROPERTY.name]: {
                        description: 'List of associated property within the report',
                    },
                    [MentionCategoryEnum.VEHICLE.name]: {
                        description: 'List of associated vehicle(s) within report',
                    },
                },
            },
        },
        productModulesAdmin: {
            [ProductModuleEnum.TRAFFIC_CRASH.name]: { title: 'QuickCrash' },
        },
        geoCautionsAdmin: {
            subheaderTitle: 'Location Cautions',
            subPremiseSubHeaderTitle: 'SUB-PREMISE',
            addLocationBtn: 'New Location',
            labels: {
                subpremise: 'Sub Premise',
                category: 'Caution Category',
                type: 'Caution Type',
                details: 'Caution Details',
                radius: 'Radius',
                activeDate: 'Active Date/Time',
                expirationDate: 'Expiration Date/Time',
                preview: 'Caution Preview',
                customRadius: 'Custom Radius',
                radiusToolTip: 'Default Radius is 600ft. Select to add a custom radius.',
                excludeNearbyCautions: 'Exclude from nearby cautions',
                addNewCaution: 'NEW CAUTION',
            },
            validation: {
                requireSubpremise: 'Subpremise is required',
                requireCautionCategory: 'Caution Category is required',
                requireCautionType: 'Caution Type is required',
                radiusMustBeNumber: 'Radius must be a number',
                descriptionMaxSize: 'Caution Details cannot be longer than 1024 characters',
                attachmentTitleMaxSize: 'Attachment Title must be shorter than 128 characters.',
                attachmentUrl: 'url must start with http:// or https://',
                mustHaveSubPremiseNameOrValue: 'Either name or value is required.',
            },
            table: {
                col1: 'CAUTION',
                col2: 'CATEGORY',
                col3: 'RADIUS',
                col4: 'STATUS',
                col5: 'ATTACHMENT',
                emptyTableMessage: 'No Location Cautions',
            },
            discardModal: {
                description:
                    'Are you sure you wish to exit from adding a new location caution? Any changes will not be saved.',
                title: 'Unsaved Changes',
                ok: 'EXIT',
                cancel: 'CANCEL',
            },
            importValidation: {
                successTitle: 'Location Cautions successfully uploaded.',
                errorTitle: 'Upload Error',
                secondLevelDescription:
                    'There was an error importing your CSV. See the error list below.',
                reImportButton: 'Re-Import CSV',
                reImportText: 'Try importing the file again:',
                reImportErrorText:
                    'After fixing the errors, export to a .CSV and re-import the file:',
            },
        },
        noResultsFound: 'No Results Found',
    },
    alerts: {
        Banner: {
            info: (when: string) => `Activated ${when} by `,
            acknowledge: 'Acknowledge',
            more: (n: number) => `${n} more alert${n > 1 ? 's' : ''}`,
            attachmentsN: (n: number) => `(${n})`,
            modalTitle: 'Attachments',
        },
        Modal: {
            close: 'Close',
            title: (n: number) => `Attachments (${n})`,
        },
    },
    analysis: {
        core: {
            Analysis: {
                title: 'Analytics',
            },
        },
        dashboard: {
            failedToLoadError: 'Failed to load dashboards, please try again.',
            failedToLoadWithIdError: (dashboardId: string) =>
                `Could not load the dashboard with id: ${dashboardId}. This may be due to insufficient permissions or the dashboard does not exist anymore.`,
        },
        subheader: {
            newDashboard: 'New Dashboard',
        },
        CreateAnalysisDashboardModal: {
            groupDashboardTitle: 'Group Dashboard',
            personalDashboardTitle: 'Personal Dashboard',
            title: 'Create New Dashboard',
            selectPersonalDashboard: 'Personal',
            selectGroupDashboard: 'Group',
        },
        CopyLinkButton: {
            title: 'Copy Link',
            disabledDescription: 'Not supported on Explore/Look pages.',
            success: 'The link has been successfully copied to your clipboard.',
            failed: (error: string) =>
                `The link could not be copied: ${error}. Please review the error and try again.`,
        },
        ViewQueriesDrawer: {
            title: 'Queries',
            killingQuery: {
                success: (query_id: string) => `Query #${query_id} stopped successfully.`,
                failure: (query_id: string) =>
                    `Query #${query_id} could not be stopped because it is no longer running.`,
            },
            queryStopButton: {
                title: 'Stop',
            },
            table: {
                noResults: 'No active queries found',
                columnHeaders: {
                    time: 'Time',
                    status: 'Status',
                    runtime: 'Runtime',
                    actions: ' ',
                },
            },
        },
        menu: {
            noContent: 'No Content',
        },
    },
    briefings: {
        dashboard: {
            title: 'All Briefings',
            createNewBriefing: 'Create New Briefing',
            filters: 'Filters',
        },
        table: {
            viewAll: 'View All',
            noResults: 'No briefings',
            urn: 'URN',
            columnHeaders: {
                postedDateUtc: 'Posted Date',
                title: 'Briefing Title / URN',
                locations: 'Location',
                createdBy: 'Created By',
                state: 'Status',
                actions: 'Actions',
            },
            actions: {
                moreActions: 'More actions',
                duplicate: 'Duplicate',
                edit: 'Edit',
                delete: 'Delete',
            },
        },
        filters: {
            fields: {
                urn: 'URN',
                createdBy: 'Created By',
                postedDateUtc: 'Posted Date',
                state: 'Status',
                inputPlaceholder: 'Start typing for search',
            },
        },
        modal: {
            create: {
                title: 'Create New Briefing',
                ok: 'Create Briefing',
            },
            edit: {
                title: 'Edit Briefing',
                ok: 'Save',
            },
            duplicate: {
                title: 'Duplicate Briefing',
                ok: 'Duplicate',
            },
            cancel: 'Cancel',
        },
        core: {
            postBriefing: 'Post Briefing',
            save: 'Save',
            saveAndExit: 'Save & Exit',
            saveMenu: 'Briefing save menu',
            editAttachments: 'Edit Attachments',
            createTask: 'Create Task',
            delete: 'Delete Briefing',
            duplicate: 'Duplicate',
            managePermissions: 'Manage Briefing Permissions',
            editRoutings: 'Edit Briefing Routing Options',
            editContent: 'Edit Briefing',
            menu: 'Briefing actions menu',
            urn: 'Briefing #',
            posted: 'Posted',
            author: 'Author',
            location: 'Location',
            postFailToast: 'Posting failed',
            saveSuccessToast: 'Successfully saved',
            saveFailToast: 'Saving failed',
            autosaveFailToast: 'Autosaving failed',
            autosave: (interval: number) => `Autosaves every ${interval} seconds`,
            tasksTabTitle: 'Tasks',
            attachmentsTabTitle: 'Attachments',
            duplicateBriefingPrefix: 'DUPLICATE OF',
            duplicateFailToast: 'Duplication failed',
            deleteModalConfirm: 'Delete Briefing',
            deleteModalCancel: 'Cancel',
            deleteModalBody: 'Deleting this briefing cannot be undone',
            deleteFailToast: 'Deletion failed',
            permissionsModalTitle: (title: string) => `Permissions: ${title}`,
            editBannerTitle: 'Unable to edit briefing',
            editBannerMessage: (author: string, time: string) => `${author} last edited at ${time}`,
        },
    },
    bookings: {
        bookingNumber: 'Booking number',
        editInJms: 'Edit in Jms',
        retrievingDetails: 'Retrieving Booking Details...',
    },
    clips: {
        ClipsDashboardContainer: {
            defaultTitle: 'CLIPS',
        },
        ClipsReportCards: {
            collapseAll: 'Collapse All',
            expandAll: 'Expand All',
            peopleTitle: 'People',
            vehiclesTitle: 'Vehicles',
            propertyTitle: 'Property',
        },
        ClipsReportCardItems: {
            entryNameLabel: 'Entry Name',
            entryNameHelpText:
                'Entry name is used to identify the appropriate entry in CLIPS for prefill',
        },
        ClipsReportEventDetailSidebar: {
            title: 'Event Information',
            dateSubtitle: 'Report Date / Time:',
            agencySubtitle: 'Primary Agency:',
            eventSubtitle: 'Event Time:',
            reporterSubtitle: 'Primary Reporter:',
            renSubtitle: 'REN:',
            notAvailable: 'N/A',
        },
        ClipsReportForm: {
            unknownLastName: 'Unknown Last Name',
            unknownFirstName: 'Unknown First Name',
            unknownMake: 'Unknown Make',
            unknownModel: 'Unknown Model',
            unknownState: 'Unknown State',
            unknownPlate: 'Unknown Plate',
            unknownSerial: 'Unknown Serial Number',
            unknownCategory: 'Unknown Category',
        },
        ClipsFooter: {
            send: 'Send',
            cancel: 'Cancel',
            success: 'SUCCESS',
            error: 'ERROR',
            banner: {
                success: 'All selected entity information has been sent to CLIPS',
                error: 'Error: no entity information has been sent to CLIPS',
            },
            errors: {
                nothingSelected: 'Please select something to send',
            },
        },
        ClipsSendingModal: {
            sending: 'Sending...',
        },
        ClipsConfirmationBannner: {
            success: 'All selected entity information has been sent to CLIPS',
            error: 'Error: no entity information has been sent to CLIPS',
        },
    },
    cobalt: {
        navigation: {
            core: {
                AccountSwitcherLink: {
                    switchTo: (deptProf: DepartmentProfile) =>
                        `Switch To: ${deptProf.displayName} (${deptProf.department.subDomain}) (${deptProf.department.departmentStatus})`,
                    viewAllDepartments: 'View All Departments',
                    goToCad: 'Go to Mark43 CAD',
                    noCadDepartmentError: 'CAD is unavailable for the current department.',
                },
                AccountSwitcherModal: {
                    modalTitle: 'Switch Departments',
                    modalOk: 'Switch',
                    selectLabel: 'Department',
                },
            },
        },
    },
    compliance: {
        ComplianceDashboard: {
            title: 'Compliance Export Types:',
            nibrsDisabled:
                'NIBRS exports have been disabled. Contact Mark43 Support for more information.',
        },
        ComplianceWrapper: {
            loadError:
                'The Compliance Dashboard could not be opened. Please reload. If this problem persists, contact Mark43 at support@mark43.com.',
        },
        ComplianceDateSelect: {
            labels: {
                exportIntervalTypeCustom: 'Custom',
                exportIntervalTypeYearly: 'Year',
                exportIntervalTypeMonthly: 'Month',
                exportIntervalTypeWeekly: 'Weekly',
            },
        },
        ComplianceDeleteButton: {
            label: 'Delete',
            modals: {
                confirm: 'Confirm',
                cancel: 'Cancel',
                deleteConfirmation: {
                    title: 'Delete Export History',
                    message:
                        'This action will delete all NIBRS export history from the selected month to the most recently exported month. Do you want to proceed?',
                },
            },
        },
        ComplianceDownloadButton: {
            errorExportNibrsButton: 'Nibrs Export',
            exportButtonTooltip:
                'You must first export data for the oldest month that has not yet been exported',
            modals: {
                confirm: 'Confirm',
                cancel: 'Cancel',
                notMostRecentMonthInfo: {
                    title: 'NIBRS File Generation Not Possible',
                    message:
                        'The export you are trying to generate is not for the most recent month. Please select the most recent month and try again.',
                },
                confirmationStrings: (complianceExportTypeDisplayValue = 'Compliance Export') => ({
                    exportConfirmation: {
                        title: `Export ${complianceExportTypeDisplayValue} File`,
                        message: `Downloading this ${complianceExportTypeDisplayValue} File will prevent you from making changes to previous months. Press confirm to continue.`,
                    },
                    overwriteConfirmation: {
                        title: `Overwrite Previous ${complianceExportTypeDisplayValue} File Data`,
                        message: `Exporting outstanding errors for the selected month will overwrite previous ${complianceExportTypeDisplayValue} File exports for the month selected. Press confirm to continue.`,
                        externalSubmissionTitle: `Submit ${complianceExportTypeDisplayValue} Data to State`,
                        externalSourceMessage: `Exporting will download an XML of ${complianceExportTypeDisplayValue} data for the given month and submit it directly to the state repository. No further action for submission is required.`,
                    },
                }),
            },
        },
        ComplianceExport: {
            title: 'Compliance Export',
            serverError: 'An error occurred with the export, please try again.',
            header: (agencyName: string) => `${agencyName} Errors`,
            doesNotSupportErrors:
                'Errors for this compliance type are surfaced during report writing.',
            labels: {
                generateErrorsButton: 'Generate Errors',
                historyButton: 'History',
            },
        },
        ComplianceErrorsCounts: {
            labels: {
                errorRate: (rate: string) => `${rate}%`,
                errorRateHelpText:
                    '(incidents with errors + arrests with errors) / (total incidents + total arrests ) * 100 -- excludes warnings',
                totalErrorRate: 'Total Error Rate',
                totalIncidents: 'Total Group A Incidents',
                totalArrests: 'Total Group B Arrests',
                withErrors: 'With Errors or Warnings',
            },
        },
        ComplianceExportDownloadButton: {
            labels: {
                exportButton: 'Export',
                exportAndSubmit: 'Export and Submit',
            },
        },
        ComplianceResubmissionExportDownloadButton: {
            labels: {
                exportButton: 'Export',
            },
            modal: {
                confirm: 'Confirm',
                cancel: 'Cancel',
                title: 'Export Resubmission File',
                message: 'Exporting reports queued for resubmission. Press confirm to continue.',
                errorMessage: 'Error occurs while exporting file',
            },
        },
        ComplianceExportErrors: {
            table: {
                noResults: 'No Reports With Errors',
                labels: {
                    report: 'Report',
                    errors: 'Errors',
                },
                cells: {
                    moreErrors: (numErrors: number) => `+ ${numErrors} more`,
                },
            },
        },
        ComplianceExportErrorsTable: {
            table: {
                noResults: 'No Reports With Errors',
                labels: {
                    report: 'Report',
                    errors: 'Validation Errors',
                },
                showErrorsOnly: 'Show Errors Only',
            },
        },
        ComplianceExportErrorsCell: {
            moreErrors: (numErrors: number) => `+ ${numErrors} more`,
            noErrors: '(no NIBRS errors)',
        },
        ComplianceExportForm: {
            labels: {
                generateReportsButton: 'Generate Reports',
                agencySelect: 'Agency Name',
                exportZeroReportSegmentCheckbox: 'Export Zero Report',
                exportZeroReportSegmentHelpText: (offenseDisplayName: string) =>
                    `Include a zero report segment in the NIBRS export if there are 0 ${offenseDisplayName.toLowerCase()} and arrest reports for the month`,
            },
        },
        ComplianceExportAuthenticationForm: {
            content: (complianceTypeDisplayValue = 'Compliance Export') =>
                `Please enter your ${complianceTypeDisplayValue} username and password.`,
            labels: {
                username: 'Username',
                password: 'Password',
                showPassword: 'Show',
                hidePassword: 'Hide',
            },
        },
        ComplianceExportHistory: {
            title: 'Compliance History',
            button: {
                exportRoute: 'Export',
                loadHistory: 'Load History',
                deleteHistory: 'Delete History',
            },
            label: {
                header: (complianceType: string, agencyName: string) => {
                    return `${complianceType} - ${agencyName} Export History`;
                },
                deleteHistoryFromMonth: 'Delete History From',
            },
        },
        ComplianceExportHistoryTable: {
            noResults: 'No Exports for this Agency',
            labels: {
                dateRange: 'Date Range',
                downloadedBy: 'Downloaded By',
                complianceExportFile: 'Compliance Export',
                overwritten: 'Overwritten',
                deleted: 'Deleted',
                errorRate: 'Error %',
                nibrsExport: 'Nibrs Export',
            },
        },
        ComplianceResubmission: {
            header: (agencyName: string) => `${agencyName} Errors`,
        },
        ComplianceResubmissionForm: {
            labels: {
                agencySelect: 'Agency Name',
                exportZeroReportSegmentCheckbox: 'Export Zero Report',
                exportZeroReportSegmentHelpText: (offenseDisplayName: string) =>
                    `Include a zero report segment in the NIBRS export if there are 0 ${offenseDisplayName.toLowerCase()} and arrest reports for the month`,
                generateReportsButton: 'Generate Reports',
            },
            errors: {
                reportsError: 'Failed to load reports',
            },
        },
        ComplianceResubmissionReportsTable: {
            labels: {
                noReports: 'No Reports For Resubmission',
                report: 'Report',
                lastExportDate: 'Last Exported Date',
                resubmitButton: 'Resubmit',
            },
        },
        ComplianceResubmissionResults: {
            totalReports: (total: number) => `Reports (${total})`,
            totalErrors: (total: number) => `Errors (${total})`,
        },
        ComplianceSidebar: {
            menu: {
                export: 'Export',
                resubmission: 'Resubmissions',
            },
            intervals: {
                monthly: 'Monthly',
                weekly: 'Weekly',
            },
        },
        ComplianceSubheader: {
            title: 'Compliance Export',
            ibrsDashboard: 'IBRS Dashboard',
            labels: {
                historyButton: 'History',
            },
        },
    },
    core: {
        adminMenu: {
            support: 'Mark43 Help Center',
            logout: 'Log Out',
        },
        AsyncDropdownMenu: {
            error: 'Error retrieving options.',
        },
        navigation: {
            search: 'Search',
            dashboard: 'Dashboard',
            reports: 'Reports',
            cases: (caseModuleName: string) => caseModuleName,
            evidence: (evidenceModuleName: string) => evidenceModuleName,
            analysis: 'Analytics',
            insights: 'Insights',
            notifications: 'Notifications',
            warrants: 'Warrants',
            mobile: 'Mobile',
            dex: 'DEx',
            tasks: 'Tasks',
        },
        genericPlaceholders: {
            date: 'MM/DD/YYYY',
            // TODO: Leaving this blank for now
            // because `+1 (000) 000 - 0000` is misleading
            // without input masking
            phoneNumbers: '',
            phoneType: 'Home, Cell, Work...',
            emailType: 'Home, Work...',
            age: '00',
            unknownName: 'UNKNOWN',
        },
        personProfileHelpers: {
            primary: 'primary',
            unknownName: 'UNKNOWN',
            dateEffectiveFrom: 'Effective from',
            dateEffectiveTo: 'Effective to',
            agency: 'Agency',
            modified: 'Modified',
        },
        exportSelectors: {
            fillablePdf: 'Fillable PDF: ',
        },
        cards: {
            AttachmentsCard: {
                name: 'Name',
                uploadDate: 'Upload Date',
                sortDateDesc: 'Newest First',
                sortDateAsc: 'Oldest First',
                noResultsFound: 'No Results Found',
                noData: 'No attachments',
                addEdit: 'Add/Edit Attachments',
                searchTextPlaceholder: 'Filter by name',
                downloadResults: 'Download Results',
                emailResults: 'Email Results',
                open: 'Open',
            },
        },
        header: {
            RecordsHeader: {
                agency: 'agency',
            },
            RecordsHeaderHamburgerMenu: {
                tooltip: 'More Options',
            },
            RecordsHeaderPermissionsButton: {
                tooltip: 'Manage Permissions',
            },
            RecordsHeaderBookingButton: {
                tooltip: 'Booking',
            },
            RecordsHeaderEditButton: {
                tooltip: 'Edit',
            },
            RecordsHeaderLinkReportMenu: {
                tooltip: 'Create Linked Report',
                create: (renDisplayName: string, reportingEventNumber: string) =>
                    `Create a new report for ${renDisplayName} ${reportingEventNumber}`,
                linkToRen: (renDisplayName: string) => `Link to ${renDisplayName}`,
                notLinkedToRen: (renDisplayName: string) =>
                    `Not currently linked to a ${renDisplayName}`,
            },
            CaseHeaderReviewCaseButton: {
                tooltip: (displayFieldName: string) => `Review ${displayFieldName}`,
                label: 'Review',
            },
            RecordsHeaderExportsButton: {
                tooltip: 'Export',
            },
            RecordsHeaderHistoryButton: {
                tooltip: 'View History',
            },
            RecordsHeaderCurrentViewersToggle: {
                tooltip: 'Other Viewers',
                description: 'Other Viewers',
                loadAll: 'Load all',
            },
            ReportHeaderExportsDropdown: {
                tooltip: 'Export Options...',
                shortcutCommand: '(CTRL + P)',
                advancedOptions: 'Advanced Export Options...',
            },
        },
        itemTypes: {
            [globalAttributes.itemType.firearm]: 'Firearm',
            [globalAttributes.itemType.drugs]: 'Drugs',
            [globalAttributes.itemType.vehicle]: 'Vehicle',
        },
        ActionIcons: {
            edit: 'Edit',
            remove: 'Remove',
            duplicate: 'Duplicate',
        },
        AutofillDateButton: {
            useEventStartDate: 'Use Event Start Date',
            useEventStartDateTime: 'Use Event Start Date / Time',
            useEventStartEndDateTime: 'Use Event Start and End Date / Time',
        },
        Button: {},
        ButtonCopyToClipboard: {
            copy: 'Copy',
            copied: 'Copied!',
        },
        ButtonRadio: {
            attributeType: 'Attribute Type: ',
        },
        attributeComponents: {
            attributeTypeLoadingError: 'Loading attributes failed, please try again.',
            retryLoading: 'Retry loading',
        },
        Icon: {},
        Card: {
            edit: 'Edit',
            save: 'Save Progress',
            removeCard: 'Remove card',
            duplicateCard: 'Duplicate card',
            required: '(Required)',
        },
        CardHeaderErrorsSection: {
            showMore: 'Show More',
            showLess: 'Show Less',
        },
        CollapsibleList: {
            viewLessItems: 'View Less',
            viewMoreItems: 'View More',
            noItems: 'No Items',
        },
        DateCell: {
            noDateTime: 'Data does not exist',
            overdue: 'Overdue',
        },
        Drawer: {
            show: 'Show',
            hide: 'Hide',
        },
        Editor: {
            Toolbar: {
                tooltips: {
                    undo: 'Undo (CTRL+Z)',
                    redo: 'Redo (CTRL+SHIFT+Z)',
                    narrativeGuides: 'Narrative Guides',
                    mentions: 'Mentions (@)',
                    bold: 'Bold (CTRL+B)',
                    italic: 'Italic (CTRL+I)',
                    underline: 'Underline (CTRL+U)',
                    alignLeft: 'Align left',
                    alignCenter: 'Align center',
                    alignRight: 'Align right',
                    bulletList: 'Bullet list',
                    numberedList: 'Numbered list',
                    decreaseIndent: 'Decrease indent',
                    increaseIndent: 'Increase indent',
                    fontSize: 'Font size',
                },
            },
            errors: {
                // This error means the TinyMCE editor failed to load. Possible causes include: the TinyMCE script was
                // not found, the script threw an exception, a critical TinyMCE plugin was not found or threw an
                // exception, or the iframe failed to load.
                failedToLoad: 'Failed to load editor, please reload this page to try again.',
                // This error means the editor itself loaded, but the initial content failed to load into the editor.
                // Possible causes include: the TinyMCE `init` event did not fire, initialization took longer than
                // TINYMCE_INIT_TIMEOUT milliseconds (which we control), or a bug in our code.
                failedToInitialize: (entityType: EntityTypeEnumType): string => {
                    let entityTypeString = '';
                    switch (entityType) {
                        case EntityTypeEnum.BRIEFING.name:
                            entityTypeString = 'briefing';
                            break;
                        case EntityTypeEnum.CASE_NOTE.name:
                            entityTypeString = 'case note';
                            break;
                        case EntityTypeEnum.REPORT.name:
                            entityTypeString = 'narrative';
                            break;
                        default:
                            entityTypeString = 'editor';
                            break;
                    }
                    return `Failed to open ${entityTypeString}, please reload this page to try again.`;
                },
                saveTimeout: 'Save attempt timed out, please try again',
                imageUploadFailToast: 'Image upload failed',
            },
            plugins: {
                mentionsForBriefing: {
                    at: '@',
                    loading: 'Loading',
                    back: 'Back',
                    categories: {
                        person: 'Person',
                        vehicle: 'Vehicle',
                        property: 'Property',
                        report: 'Report',
                        warrant: 'Warrant',
                    },
                    inPoliceCustody: 'In Police Custody',
                    yes: 'Yes',
                    no: 'No',
                    resourceGenericError: (category: MentionCategoryType, id?: string) =>
                        `Error with getting ${category} resource${id ? ` [ ${id} ]` : ''}`,
                },
            },
        },
        ExternalReportStatusSummary: {
            title: 'External Status',
        },
        GeneratedSegments: {
            GeneratedSegmentsSidePanel: {
                ButtonClose: 'Close',
                ButtonRunErrorCheck: 'Run Error Check',
                fatalErrors:
                    'Fatal errors must be resolved in order for NIBRS segments to be generated',
                failed: 'Something went wrong. Try refreshing the page or running the error check again. If this problem persists, contact Mark43 at support@mark43.com',
                noNibrsErrors: 'No NIBRS errors',
                noFatalErrors: 'No fatal errors',
                runErrorCheck: 'Please run error check for this report',
                title: 'NIBRS Details',
                titleFatalErrors: 'Fatal Errors',
                titleNibrsErrors: 'NIBRS Errors',
            },
        },
        OffenseCaseStatusSummary: {
            title: (offenseDisplayName: string) => `${offenseDisplayName} Status`,
            itemTitle: ({
                offenseIncidentTitle,
                ren,
            }: {
                offenseIncidentTitle?: string;
                ren?: string;
            }) =>
                `${ren ? `${'REN'}: ${ren} - ` : ''}${
                    offenseIncidentTitle ? `${offenseIncidentTitle}` : ''
                }`,
        },
        ConfirmationModal: {
            confirm: 'Confirm',
        },
        LoadingModal: {
            loadingMessage: 'Loading...',
        },
        LocationSidePanel: {
            addLocation: 'Add Location',
            locationInfo: 'Location Info',
            address: 'Address',
            classifications: 'Classifications',
            searchPlaceholder: 'Search for a location or address',
            back: 'Back',
            addNew: 'New Location',
            searchResults: 'Search Results',
            verifiedAddressNote: 'NOTE: You must select a verified street address.',
            edit: 'Edit',
            searchHelpText: 'Search for a location to get started',
            searchEmptyStateHelpText:
                'Try entering a different search term, or click below to add a new location',
            searchEmptyState: 'No search results found',
            mapEmptyState: 'No Location Found',
            mapNearestLocation: 'Nearest Found Location',
        },
        LocationQuickAdd: {
            more: (numMore: number) => `${numMore} More`,
        },
        QuickAddInstruction: {
            instructions: (type: string, renDisplayName: string, suffix: string) =>
                `Add ${type} from this ${renDisplayName}${suffix ?? ''}:`,
            people: 'people',
            organizations: 'organizations',
            locations: 'locations',
            societyVictimEnabled: (offenseDisplayName: string) =>
                `The selected ${offenseDisplayName} requires a society victim. Choose from the following society profiles:`,
            addPersonFromDex: 'Add Person from DEx',
        },
        Pagination: {},
        Popover: {
            okText: 'Continue',
        },
        RenSearchModal: {
            goButtonText: 'Search',
        },
        ReportExportsCheckboxTree: {
            expandAll: 'Expand all',
            collapseAll: 'Collapse all',
            selectAll: 'Select All',
        },
        PersonLabels: {
            juvenile: 'Juvenile',
            potentialActiveWarrant: 'Potential Active Warrant(s)',
            suspectedGangMember: 'Suspected Gang Member',
            vulnerableAdult: 'Vulnerable Adult',
            vulnerableChild: 'Vulnerable Child',
            activeTarget: 'Active Target',
        },
        VehicleLabels: {
            isStolen: 'Stolen',
            isActiveTarget: 'Active Target',
        },
        PersonPill: {
            showDetails: (showMore: number) => `${showMore ? 'Less' : 'More'} Details...`,
            nicknamesPrefix: 'aka',
            identify: 'Identify',
        },
        PersonPillSmall: {
            nicknamesPrefix: 'aka',
            noInfoKnown: 'No Info Known',
            id: 'ID',
        },
        VehiclePillSmall: {
            registrationNumber: `License Plate / Registration #`,
            vin: `VIN #`,
            epmtyTitle: '--',
            epmtySubtitle: '--',
        },
        FirearmPillSmall: {
            serialNumber: `Serial #:`,
            epmtySerialNumber: '--',
        },
        PersonQuickAdd: {
            more: (numMore: number) => `${numMore} More`,
            genericSaveError:
                'Quick adding a person failed. Try again in a few moments. If this problem persists, contact Mark43 at support@mark43.com.',
        },
        VehicleQuickAdd: {
            dex: {
                genericLoadError:
                    'Failed to load the data for vehicles from DEx. Try again in a few moments. If this problem persists, contact Mark43 at support@mark43.com.',
                addItem: 'Add Vehicle from DEx',
            },
        },
        FirearmQuickAdd: {
            dex: {
                genericLoadError:
                    'Failed to load firearms data from DEx. Try again in a few moments. If this problem persists, contact Mark43 at support@mark43.com.',
                addItem: 'Add firearm from DEx',
            },
        },
        PersonSearchToAdd: {
            or: 'Or',
            addUnknown: 'Unknown Person',
            search: 'Search',
            fieldLabels: {
                dateOfBirthQuickSearchQuery: 'DOB (optional)',
                homeAddressQuickSearchQuery: 'Home Address (optional)',
                mniQuickSearchQuery: 'MNI # (optional)',
            },
            newFieldLabels: {
                quickSearchQuery: 'Name',
                dateOfBirthQuickSearchQuery: 'DOB',
                homeAddressQuickSearchQuery: 'Address',
                mniQuickSearchQuery: 'MNI # (optional)',
                dlNumberQuickSearchQuery: 'DL #',
                stateQuickSearchQuery: 'State',
                phoneNumberQuickSearchQuery: 'Phone Number',
            },
            fieldPlaceholders: {
                dateOfBirthQuickSearchQuery: 'MM/DD/YYYY',
                homeAddressQuickSearchQuery: '123 Main Street',
                mniQuickSearchQuery: 'MNI #',
            },
        },
        OrganizationQuickAdd: {
            more: (numMore: number) => `${numMore} More`,
            genericSaveError: (organization: string) =>
                `Quick adding an ${organization.toLowerCase()} failed. Try again in a few moments. If this problem persists, contact Mark43 at support@mark43.com.`,
        },
        OrganizationSearchToAdd: {
            search: 'Search',
            fieldLabels: {
                locationStreetAddress: 'Address',
                quickSearchQuery: (organization: string) => `${organization} Name`,
                organizationTypeAttrIds: (organization: string) => `${organization} Type`,
            },
            fieldPlaceholders: {
                quickSearchQuery: 'Business, Education, Religious, etc',
            },
        },
        RecentEntities: {
            emptyList: ({
                renDisplayName,
                entityTypeDisplayName,
                ren,
            }: {
                renDisplayName: string;
                entityTypeDisplayName: string;
                ren: string;
            }) =>
                `There are no ${entityTypeDisplayName || 'entities'}${
                    ren ? ` in ${renDisplayName || 'REN'} ${ren}` : ''
                }`,
            listTitle: ({
                renDisplayName,
                entityTypeDisplayName,
                ren,
            }: {
                renDisplayName: string;
                entityTypeDisplayName: string;
                ren: string;
            }) =>
                `Involved ${entityTypeDisplayName || 'Entities'}${
                    ren ? ` In ${renDisplayName || 'REN'} ${ren}` : ''
                }`,
            entityTypeDisplayName: {
                [EntityTypeEnum.PERSON_PROFILE.name]: 'persons',
                [EntityTypeEnum.ORGANIZATION_PROFILE.name]: 'organizations',
                [EntityTypeEnum.LOCATION.name]: 'locations',
            },
        },
        BackBanner: {
            back: 'Back',
        },
        ExportAttachmentsSidePanel: {
            retry: 'Try loading attachments again',
            title: 'Attachments for Export',
            noAttachmentsFound: 'No attachments found',
            AttachmentSection: {
                selectedAttachments: (numSelected: number, total: number) =>
                    `${numSelected}/${total} selected`,
                selectAllDisabled: 'Cannot bulk select or deselect while filter is applied',
            },
            AttachmentRow: {
                uploadedBy: (name: string) => `by ${name}`,
            },
            FilterInput: {
                placeholder: 'Filter by file name',
            },
        },
        ExportRedactionSidePanel: {
            title: 'Redact Information',
            redactionInfo: 'Select information to redact from PDF export:',
            FieldSection: {
                selectedFields: (numSelected: number, total: number) =>
                    `${numSelected}/${total} selected`,
                selectAllDisabled: 'Cannot bulk select or deselect while filter is applied',
            },
            FilterInput: {
                placeholder: 'Filter by field name',
            },
        },
        ExportRedactionPage: {
            export: 'Export',
            backToExportPage: 'Back to export page',
            errorLoadingPreview:
                'Error loading preview. Try again in a few moments. If this problem persists, contact Mark43 at support@mark43.com.',
            redactFields: 'Redact fields',
            selectAll: 'Select all',
            redactText: 'Redact Text',
            redact: 'Redact',
            enterTextToRedact: 'Enter text to redact',
            fillablePdfMessage:
                'Fillable PDFs will be included in your export, but they cannot be redacted at this time',
            minimumLengthErrorMessage: 'Must be at least 3 characters long',
        },
        InlineAttachmentsEntityProfileUploader: {
            remove: 'Remove',
        },
        PersonSidePanel: {
            of: 'of',
            title: (type: string) => `Add/Edit ${type || 'Person'}`,
            SidePanelSearchResults: {
                addNew: 'New Person Profile',
                viewMore: 'View More Results',
                noResults: 'No person was found.',
                searchResults: (n: number) => `${n} Search ${pluralize('Result', n)}`,
            },
            identifyUnknown: (name: string) =>
                `You are identifying ${
                    name || 'UNKNOWN'
                }. The person information on the original report will not be impacted.`,
            identifyNewUnknown: 'Create new person profile',
            createNewProfile: 'Create new profile',
            backToSearchPerson: 'Back to Search Person',
            savedSuccessfully: 'Person Profile successfully saved',
            addFromDexQuery: 'Add From DEx Query',
            addToReport: 'Add to Report',
            overridePersonModalTitle: 'Update person profile',
            overridePersonModalBody:
                'New information on this Person was found. Would you like to update the profile?',
            overrideBtnLabel: 'Update profile',
            overrideCancelBtnLabel: 'Keep existing profile',
        },
        PersonInvolvementTypeSelectionForm: {
            nextButtonText: 'Next',
            validations: {
                required: 'This field is required.',
            },
        },
        PersonProfileForm: {
            phoneNumbers: 'Phone',
            emails: 'Email',
            Attachments: {
                attachmentUploadFailedPleaseTryAgain: 'Attachment upload failed, please try again',
            },
            PersonProfileFormInjuryFields: {
                injury: 'Injury',
                treatmentDetails: 'Treatment Details',
                physician: 'Physician',
            },
            PersonProfileFormComponent: {
                otherNamesUsed: 'Other Names Used',
                phoneNumbers: 'Phone',
                emails: 'Email',
                identifyingMarks: 'Scars, Marks, Tattoos',
                militaryHistories: 'Military Service',
                passports: 'Passport',
                sections: {
                    coreDetails: {
                        header: 'Core Details',
                        description: 'Names, identifiers, and physical descriptors.',
                    },
                    contactInfo: {
                        header: 'Contact Info',
                        description: 'Personal, employer, school, & emergency contact information.',
                    },
                    eventSpecificInfo: {
                        header: 'Event Specific Info',
                        description:
                            'Injuries, clothing, notes, & characteristics related to the event.',
                    },
                    miscellaneousInfo: {
                        header: 'Miscellaneous Info',
                        description: 'Additional background info, and attachments.',
                    },
                },
                dexWarningBannerTitle:
                    'The query returned a name that may have been auto-filled incorrectly.',
                dexWarningBannerText: 'Check the person name to confirm its accuracy.',
            },
            PersonProfileFormEmergencyContactFields: {
                addEmergencyContactInfo: 'Emergency Contact Information',
            },
            PersonProfileFormBodyRelatedFields: {
                addPhysicalDescriptors: 'Additional Physical Descriptors',
                addHeightRange: 'Add Height Range',
                addWeightRange: 'Add Weight Range',
                removeRange: 'Remove Range',
                ft: 'ft.',
                in: 'in.',
                lbs: 'lbs.',
            },
            PersonProfileFormIdentifierFields: {
                identifiers: 'Name Identifier',
            },
            PersonProfileEmploymentSchoolHistories: {
                employmentHistories: 'Employer Info',
                schoolHistories: 'School Info',
            },
            PersonProfileFormGeneralPersonRelatedFields: {
                addSuffix: 'Add Title/Suffix',
                living: 'Living',
                deceased: 'Deceased',
                dateOfBirth: 'Date of Birth',
                unknown: 'Unknown',
                known: 'Known',
                unborn: 'Unborn',
                expectedDueDate: 'Expected Due Date',
            },
            PersonProfileFormAddressFields: {
                homeAddress: 'Home Address',
                workAddress: 'Work Address',
                addNoFixedAddress: 'No Fixed Address',
            },
            nameFormNItemsRenderers: {
                addNotes: 'Add Notes',
            },
            IdentifyingMarksUploader: {
                attachment: 'Attachment',
            },
        },
        OrganizationSidePanel: {
            of: 'of',
            title: (organization: string) => `Add/Edit ${organization}`,
            SidePanelSearchResults: {
                addNew: (organization: string) => `New ${organization}`,
                viewMore: 'View More Results',
                noResults: (orgnization: string) => `No ${orgnization} was found.`,
            },
        },
        OrganizationProfileForm: {
            phoneNumbers: 'Phone',
            emails: 'Email',
            identifiers: 'Identifier',
            OrganizationProfileFormAddressFields: {
                mailingAddress: 'Mailing Address',
                physicalAddress: 'Physical Address',
                addAddress: 'Address',
            },
            Attachments: {
                attachmentUploadFailedPleaseTryAgain: 'Attachment upload failed, please try again',
            },
        },
        SimpleLoading: {},
        ToggleButton: {},
        Table: {
            noResults: 'No Rows To Display',
            restrictedAccess: 'Restricted Access',
            canFindOnly: 'This record has been made private.',
            sealedRecord: 'This record has been sealed per court order mandate.',
        },
        ImageGallery: {
            uploadInfo: (uploadedDate: string, uploadedBy: string) => {
                return `Uploaded ${uploadedDate} by ${uploadedBy}`;
            },
        },
        historyEvents: {
            formattingChanges: 'Only Formatting Changes Made',
            narrativeFormattingChanges:
                'Change to only the text formatting or inline comment(s). No change to the narrative text',
        },
        maps: {
            NoMapContainer: {
                noMapText: 'Map will display when values are set.',
            },
            Esri: {
                loading: 'Loading...',
                loadingError: 'Error loading map layer',
            },
        },
        tables: {
            tableConfigs: {
                upsertError: 'Table column configuration failed to save.',
            },
            caseNotesTable: {
                NoteTitleAuthorDateCell: {
                    untitled: 'Untitled',
                    createdDate: (formattedDate: string) => `Created on ${formattedDate}`,
                },
            },
            caseTasksTable: {
                TaskDescriptionCell: {
                    overdue: '(Overdue)',
                },
            },
            elasticEvidenceTable: {
                StaffRemarksCell: {
                    title: (staffRemarksCount: number) => `${staffRemarksCount} Remarks`,
                    reportLevelStaffRemarks: (
                        renDisplayName: string,
                        reportingEventNumber: string
                    ) => `${renDisplayName} ${reportingEventNumber} Remarks`,
                    itemLevelStaffRemarks: `Item Remarks`,
                },
            },
            elasticPersonTable: {
                BiographicalInfoCell: {
                    modified: 'Modified',
                    agency: 'Agency',
                },
            },
            elasticReportsTable: {
                PersonsCell: {
                    noInvolvedPersons: 'No Involved Persons',
                    isJuvenileTag: '(Juvenile)',
                    isNonDisclosure: '(Non-Disclosure)',
                },
                ReportTypeCell: {
                    vacated: '(VACATED)',
                },
            },
        },
        entitySearch: {
            noResults: 'No results found',
            searching: 'Searching...',
            error: 'A server request error occurred. Your search could not be completed.',
            loadMore: (amountToLoad: number) => `Load ${amountToLoad} More`,
            close: 'Close',
            searchButton: 'Search',
            overrideModalTitle: (entityName: string) => `Update ${entityName} profile`,
            overrideModalBody:
                'New information on this vehicle was found. Would you like to update the profile?',
            overrideBtnLabel: 'Update profile',
            overrideCancelBtnLabel: 'Keep existing profile',
        },
        permissions: {
            casePermissionsModal: {
                title: (displayFieldName: string) => `${displayFieldName} Permissions`,
                saveText: 'Save',
                noUserAssignedToCase: (displayFieldName?: string) =>
                    `No user is assigned to this ${displayFieldName?.toLowerCase()}`,
                helpText: (assigneeFieldName: string, displayFieldName: string) =>
                    `${prettify(
                        displayFieldName
                    )} ${assigneeFieldName} and Supervisor(s) are automatically granted Can Manage permission on the ${displayFieldName?.toLowerCase()}, allowing them to modify ${displayFieldName?.toLowerCase()} permissions`,
                assigneeName: (name: string, assigneeFieldName: string) =>
                    `${name} (${assigneeFieldName})`,
                supervisorName: (name: string) => `${name} (Supervisor)`,
                assigneesTitle: (assigneeFieldName: string) => `${assigneeFieldName}/Supervisors`,
                reportPermissionSettings: 'Report Permission Settings',
                hideReports: 'Hide Reports',
            },
            genericPermissionsModal: {
                saveText: 'Save',
            },
            reportPermissionsModal: {
                title: (reportTitle: string) => `Report Permissions: ${reportTitle}`,
                reportOwner: (reportOwnerDisplayValue: string) =>
                    `Report ${reportOwnerDisplayValue}`,
                reportOwnerHelpText: (reportOwnerDisplayValue: string) =>
                    `The report ${reportOwnerDisplayValue} is the only user that can submit a report for approval. The ${reportOwnerDisplayValue} is always granted Can Manage permissions on the report. The ${reportOwnerDisplayValue} is automatically re-assigned to any user who returns the report to Draft.`,
                saveText: 'Save',
            },
            recordPermissionsForm: {
                roleSettings: 'Role Settings',
                userSettings: 'User Settings',
                externalAgencySettings: 'External Agency Settings',
                externalHelpText: (caseDisplayName: string, casesDisplayName: string) =>
                    `This ${caseDisplayName} could be made accessible to users in external agencies through a data sharing agreement configured by your department. External agency users cannot edit ${casesDisplayName} in your department.`,
            },
            departmentPermissionsModal: {
                title: 'Department Permissions Confirmation',
                message: (entityType: string) =>
                    `All members of this department, excluding explicitly authorized users added below, will no longer have access to find or view any information related to this ${entityType.toLowerCase()}. Do you wish to proceed?`,
            },
        },
        SubscribedSavedSearchLabel: {
            subscribed: 'Subscribed',
        },
        errors: {
            ErrorBoundary: {
                fallbackError: 'An error has occurred',
            },
        },
        cautions: {
            tooltip: {
                description: 'Description',
                effective: 'Effective',
                effectiveDates: (effectiveFrom?: string, effectiveTo?: string) =>
                    `${effectiveFrom || 'N/A'} - ${effectiveTo || ''}`,
                provenance: 'Provenance',
            },
            table: {
                type: 'Type',
                effectiveFrom: 'Effective From',
                effectiveTo: 'Effective To',
                dateCreated: 'Date Created',
                createdBy: 'Created By',
            },
        },
    },
    dragon: {
        crashDiagram: {
            CrashDiagramFormField: {
                captureButtonText: 'Draw Diagram',
                successToastMessage: 'Diagram Captured',
                emptyState: 'Crash Diagram has not been captured yet',
            },
            CrashDiagramModal: {
                duplicateButtonText: 'Duplicate',
                arrangeButtonText: 'Arrange',
                flipButtonText: 'Flip',
                ghostButtonText: 'Ghost',
                numberOfDuplicates: 'Number of Duplicates',
                opacity: 'Opacity %',

                okText: 'Add Diagram',
                cancelText: 'Cancel',
                title: 'Draw Diagram',
                clearButtonText: 'Clear',
                undoButtonText: 'Undo',
                redoButtonText: 'Redo',
                streetLayersButtonText: 'Street Layers:',
                textButtonText: 'Text',
                noResultsFound: 'No symbols found',
                widgetTextMenu: {
                    brintToFrontButtonText: 'Bring to Front',
                    sendToBackButtonText: 'Send to Back',
                    flipHorizontallyButtonText: 'Flip Horizontally',
                    flipVerticallyButtonText: 'Flip Vertically',
                },
                errorMessages: {
                    unhandledUploadException:
                        'Failed to save crash diagram due to an unhandled exception. Contact your admin to report this issue.',
                    unhandledWidgetOnDropException: (widgetLabel: string) =>
                        `Failed to drop ${widgetLabel} on canvas. Check your SVG file and try again. [Retry]`,
                    unhandledWidgetOnClickException: (widgetLabel: string) =>
                        `Failed to add ${widgetLabel} on canvas. Check your SVG file and try again. [Retry]`,
                },
            },
            CrashDiagramImage: {
                summaryMode: {
                    emptyState: 'Crash Diagram has not been captured yet',
                },
                editMode: {
                    emptyState: 'Failed to capture crash diagram',
                },
            },
        },
        signature: {
            SignatureModal: {
                okText: 'Add Signature',
                cancelText: 'Cancel',
                title: 'Add Signature',
                disclaimer: 'Sign Here',
                clearButtonText: 'Clear',
                capturedSuccessfullyMessage: 'Signature Captured',
                errors: {
                    missingSignature: 'A signature is required. Please capture a signature',
                    unhandledUploadException:
                        'Failed to save signature, an unhandled exception occurred. Please contact support at support@mark43.com',
                },
            },
            SignatureFormField: {
                captureSignatureButtonText: 'Capture Signature',
                emptyStateText: 'Signature has not been captured yet',
                signatureCapturedMessage: 'Signature Captured',
            },
        },
    },
    persons: {
        DeceasedLabel: {
            deceased: 'Deceased',
        },
    },
    evidence: {
        chainOfCustody: {
            ChainOfCustody: {
                title: (custody: string) => `Chain of ${custody}`,
                dropOffStorageLocation: 'Drop-Off Location',
                checkedOutLabFacility: 'Destination',
                checkOutOtherUser: 'Personnel',
                transferredAgency: 'Agency',
                releasedPersonProfile: (custody: string) => `Released to ${custody} of`,
                showDetails: 'Show Details',
                hideDetails: 'Hide Details',
                signature: 'Signature:',
                identification: 'Identification:',
                attachments: 'Attachments:',
                viewMore: 'View More',
                viewLess: 'View Less',
                location: 'Location',
                receivedBy: 'Received By',
            },
            DeleteChainEventModal: {
                title: (custody: string) => `Delete Chain of ${custody} Entry`,
                confirmButton: 'Confirm',
            },
            DeleteChainEventForm: {
                confirmDeleteChainEvent: `This action will be tracked in the item's history. This action may change the item's disposition status and cannot be undone.`,
            },
        },
        core: {
            EvidenceHeader: {
                menu: {
                    // keyed by route paths in kebab-case
                    dashboard: (evidenceModuleName: string) =>
                        `Manage ${prettify(evidenceModuleName)}`,
                    inventories: 'Inventories & Audits',
                },
            },
            FacilitySelect: {
                attributeTypeLoadingError: 'Error, select dropdown again to reload data',
                userHasNoPermissionsToAllLocationsError:
                    "Error, you don't have permissions to manage storage locations",
            },
            FacilityStorageLocationSelect: {
                facilities: 'Core Facilities',
                storageLocations: 'Storage Locations',
            },
            StaffRemarkSidePanel: {
                titles: {
                    itemProfile: 'Add Item Remarks',
                    report: (renDisplayName: string, reportingEventNumber: string) =>
                        !!reportingEventNumber
                            ? `Add Remarks to ${renDisplayName} ${reportingEventNumber}`
                            : 'Add Remarks',
                },
                labels: {
                    remark: 'Remarks',
                },
                itemsSelected: (count: number) => `${count} Item(s) Selected`,
            },
            HighRiskLabel: {
                highRisk: 'High Risk',
            },
        },
        dashboard: {
            searchForm: {
                searchOptionPopover: {
                    itemIdentifiers: (
                        renDisplayName: string,
                        barcodeFieldName: string,
                        serialFieldName: string,
                        itemIdDisplayName: string
                    ) =>
                        `Item identifier numbers (${renDisplayName}, Exhibit #, ${barcodeFieldName}, ${serialFieldName}, ${itemIdDisplayName})`,
                    propertyOwners: 'Property owner',
                    itemNotes: 'Item notes',
                    itemDescription: (itemDescriptionFieldName: string) =>
                        `${itemDescriptionFieldName}`,
                },
                placeholders: {
                    basicPlaceholder: 'Search',
                    quickSearchQuery: (renDisplayName: string) =>
                        `${renDisplayName}, Barcode, Item Description, Notes`,
                },
                filterEvidence: (evidenceModuleName: string) =>
                    `Filter ${prettify(evidenceModuleName)}`,
                search: 'Search',
                searchByButton: 'What can I search by?',
            },
            filterForm: {
                title: (evidenceModuleName: string) => `Filter ${prettify(evidenceModuleName)}`,
                applyFilters: 'Apply Filters',
                clearFilters: 'Clear All Filters',
                renCreationDateRange: (renDisplayName: string) =>
                    `${renDisplayName} Creation Date/Time Range`,
                facility: 'Core Facility(s)',
                excludeExpiredStorageLocations: 'Exclude Expired Locations',
                isOverdueHelpText: 'Show only items that are overdue',
                addRen: (renDisplayName: string) => `Add ${renDisplayName}`,
                itemIdentifier: `Item Identifier`,
                personnelName: 'Personnel Name',
                involvementStatus: 'Involvement Status',
                addPersonnel: 'Add Personnel',
                footer: {
                    saveSearch: 'Save Search',
                    unsaveSearch: 'Unsave Search',
                },
            },
            EvidenceDashboardExports: {
                exportType: 'Export Type',
                includeColumns: 'Include for export:',
                labels: {
                    download: 'Download',
                    cancel: 'Cancel',
                    pdfType: 'PDF Type',
                },
            },
            EvidenceDashboardSearchResults: {
                columns: {
                    info: (renDisplayName: string) => `${renDisplayName}/Date Acquired`,
                    identifiers: 'Identifiers',
                    location: (custody: string) => `Chain of ${custody} Status/Location`,
                    relevance: 'Relevance',
                    reportingEventNumber: {
                        label: (renDisplayName: string) => `${renDisplayName}`,
                        ascendingSort: 'Oldest First',
                        descendingSort: 'Newest First',
                    },
                    dateAcquired: {
                        label: 'Date Acquired',
                        ascendingSort: 'Oldest First',
                        descendingSort: 'Newest First',
                    },
                    chainEventDate: {
                        label: 'Date Updated',
                        ascendingSort: 'Oldest First',
                        descendingSort: 'Newest First',
                    },
                },
                // be careful that the content in this horizontal action bar does not overflow, each button can't be too wide
                actionButtons: {
                    addToQueue: 'Add to Basket',
                    disabledAddToQueueTooltip: (custody: string) =>
                        `Items still "In Police ${custody}" cannot be added to the Item Basket`,
                    noManagePermissionTooltip:
                        'You do not have permissions to manage this storage location',
                    printLabels: 'Print Label(s)',
                    disabledPrintLabelsTooltip: (custody: string) =>
                        `Items still "In Police ${custody}" cannot generate labels`,
                    updateDisposition: 'Disposition',
                    export: 'Export',
                    newTask: 'New Task',
                    disabledNewTaskTooltip: (limit: number) => `Max ${limit} Items Per Task`,
                },
                allOnPageSelected: (selected: number) =>
                    `All ${selected} results on this page are selected.`,
                someOnPageSelected: (selected: number) => {
                    return selected === 1
                        ? '1 result on this page is selected.'
                        : `${selected} results on this page are selected.`;
                },
                selectAll: (totalResults: number) =>
                    `Select all ${totalResults} results to export.`,
                allSelected: (totalResults: number) => `All ${totalResults} results are selected.`,
                clearSelection: 'Clear Selection',
                taskSaveConfirmation: 'Task has been successfully created',
                paginationTotalSummary: (total: string) => `of approximately ${total} results`,
            },
            EvidenceDashboardSearchStorageLocationFieldset: {
                title: 'Storage Location',
            },
        },
        inventories: {
            searchForm: {
                dateCompleted: 'Completion Date/Time Range',
                inventoryType: 'Inventory Type',
                storageLocation: 'Storage Location',
                responsiblePersonnel: 'Responsible Personnel',
                titleForFilter: 'Filter Options',
                clear: 'Clear All Filters',
            },
            SearchResultsTableHeader: {
                columns: {
                    dateCompleted: {
                        label: 'Completion Date',
                        ascendingSort: 'Oldest First',
                        descendingSort: 'Newest First',
                    },
                    itemId: {
                        label: 'Item',
                    },
                    itemIdentifier: {
                        label: 'Item Identifiers',
                    },
                    status: {
                        label: 'Status',
                    },
                    storageLocation: {
                        label: 'Storage Location',
                    },
                    responsiblePersonnel: {
                        label: 'Responsible Personnel',
                    },
                    inventoryType: {
                        label: 'Type',
                    },
                    missingItemsCount: {
                        label: 'Missing Items',
                        ascendingSort: 'A-Z',
                        descendingSort: 'Z-A',
                    },
                },
            },
            actionBar: {
                export: 'Export',
                clearSelection: 'Clear Selection',
                allOnPageSelected: (selected: number) =>
                    `All ${selected} results on this page are selected.`,
                someOnPageSelected: (selected: number) => {
                    return selected === 1
                        ? '1 result on this page is selected.'
                        : `${selected} results on this page are selected.`;
                },
                selectAll: (totalResults: number) =>
                    `Select all ${totalResults} results to export.`,
                allSelected: (totalResults: number) => `All ${totalResults} results are selected.`,
            },
        },
        inventory: {
            Inventory: {
                [InventoryTypeEnum.AUDIT.name]: {
                    labels: {
                        missing: 'Missing',
                        none: '-',
                        scanned: 'Verified',
                        unexpected: 'Unexpected',
                        unexpectedForAutoUpdate: 'Unexpected',
                        autoUpdatedLocation: 'Unexpected - Location updated',
                    },
                },
                [InventoryTypeEnum.INVENTORY.name]: {
                    labels: {
                        missing: 'Absent',
                        none: '-',
                        scanned: 'Verified',
                        unexpected: 'Unexpected',
                        unexpectedForAutoUpdate: 'Unexpected',
                        autoUpdatedLocation: 'Unexpected - Location updated',
                    },
                },
            },
            InventoryDetails: {
                [InventoryTypeEnum.AUDIT.name]: {
                    labels: {
                        completionDate: 'Completion Date',
                        details: 'Details',
                        missingItemsCount: 'Missing Items',
                        notes: 'Notes',
                        responsiblePersonnel: 'Responsible Personnel',
                        results: 'Results',
                        scannedItemsCount: 'Verified Items',
                        storageLocation: 'Location',
                        unexpectedItemsCount: 'Unexpected Items',
                    },
                },
                [InventoryTypeEnum.INVENTORY.name]: {
                    labels: {
                        completionDate: 'Completion Date',
                        details: 'Details',
                        missingItemsCount: 'Absent Items',
                        notes: 'Notes',
                        responsiblePersonnel: 'Responsible Personnel',
                        results: 'Results',
                        scannedItemsCount: 'Verified Items',
                        storageLocation: 'Location',
                        unexpectedItemsCount: 'Unexpected Items',
                    },
                },
            },
            InventoryNavigation: {
                [InventoryTypeEnum.AUDIT.name]: {
                    title: 'Audit',
                    export: 'Export as CSV',
                },
                [InventoryTypeEnum.INVENTORY.name]: {
                    title: 'Inventory',
                    export: 'Export as CSV',
                },
                none: {
                    title: '-',
                },
            },
        },
        disposition: {
            DispositionNoticeBar: {
                readyForApproval: 'Ready for Approval on ',
                reviewReport: 'Review Report',
            },
        },
        documents: {
            DocumentExportingModal: {
                title: 'Export Documents',
                export: 'Export',
                close: 'Cancel',
            },
        },
        itemQueue: {
            CreateChainEventsForm: {
                receivedByPersonName: 'Person',
                transferredReceivedByName: 'Agency',
                releasedReceivedByName: (custody: string) => `Released to ${custody} Of`,
                attachIDScan: 'Attach ID',
                signature: 'Signature',
                signaturePadMessage: (evidenceModuleName: string) =>
                    `Click Continue to sign and receive the ${evidenceModuleName?.toLowerCase()}.`,
                idScan: 'Identification',
                attachments: 'Attachments',
                noSubsequentEventTypeIds: 'No valid status updates.',
                notInSameFacility: 'The items in the basket are in different Core Facilities.',
                successConfirmation: (count: number) =>
                    `${count} item${count !== 1 ? 's' : ''} have been updated with your changes.`,
                remove: 'Remove',
            },
            ItemQueue: {
                title: (count: number) => `Item Basket (${count})`,
                submitCreateChainEventsPanel: 'Update Status',
                createChainEventsPanelTitle: 'Status Update:',
                clear: 'Empty Basket',
            },
            ItemQueuePopover: {
                title: (count: number) => `Item Basket (${count})`,
                labels: {
                    noItems: 'No Items In Basket',
                    viewQueue: 'View Basket',
                    clearQueue: 'Clear All Items',
                },
            },
            ItemQueueTable: {
                empty: (evidenceModuleName: string) => `No ${prettify(evidenceModuleName)} Added`,
            },
            ItemQueueHeader: {
                itemInfo: 'Item ID #',
                storageLocation: 'Location',
                status: 'Status',
            },
            StaffRemarksNotice: {
                title: (count: number) => `${count} Items with Remarks`,
            },
            StaffRemarksRow: {
                title: (count: number) => `${count} Remarks`,
                label: {
                    reportRemarks: (renDisplayName: string, reportingEventNumber: string) =>
                        `${renDisplayName} ${reportingEventNumber} Remarks`,
                    itemRemarks: 'Item Remarks',
                },
            },
        },
        itemSplit: {
            CopyDataButton: {
                label: 'Copy Data',
            },
            ItemSplitPropertyStatusFieldset: {
                labels: {
                    autoBalanceDeclaredValue: 'Auto-Balance Value',
                    autoBalanceQuantity: 'Auto-Balance Quantity',
                },
                helpText: {
                    declaredValue:
                        'Automatically subtract the value entered here from the value of the original item, indicated at left.',
                    quantity:
                        'Automatically subtract the quantity entered here from the quantity of the original item, indicated at left.',
                },
            },
            ItemSplitConfirmationSidePanel: {
                title: 'Review Changes',
                labels: {
                    back: 'Back',
                    confirm: 'Confirm Split',
                },
            },
            ItemSplitConfirmationSummaries: {
                labels: {
                    newItem: 'New Item',
                    existingItem: 'Existing Item',
                },
            },
            ItemSplitSidePanel: {
                title: 'Split Item',
                labels: {
                    next: 'Next',
                    save: 'Save',
                },
            },
        },
        labelPrinting: {
            LabelPrintingModal: {
                titleForItems: 'Print Labels',
                titleForStorageLocations: 'Print Barcodes',
                preview: 'Preview',
                batchDescriptionForItems: (count: number) => `Barcodes for ${count} selected items`,
                batchDescriptionForStorageLocations: (count: number, fullDisplayPath: string) =>
                    `Barcodes for all active locations in ${fullDisplayPath} (${count} labels)`,
                print: 'Print',
                close: 'Close',
            },
        },
    },
    exports: {
        emails: {
            email: 'Email',
            noPacketsTooltip: 'At least 1 PDF should be selected',
            attachmentsSizeTooltip: 'Attachments exceeding 20 MB cannot be emailed',
            sendEmail: 'Send Email',
            cancel: 'Cancel',
            enterEmail: 'Enter a valid email address',
            successfulToast: 'Email sent successfully',
            failedToast: 'Email failed to send',
            fieldLabels: {
                externalRecipients: 'External Recipient(s)',
                internalUsers: 'Internal User(s)',
                subject: 'Email Subject',
                message: 'Email Message',
            },
            validations: {
                recipientRequired: 'At least 1 recipient required',
                subjectRequired: 'Email Subject is required',
                invalidEmail: 'Invalid email format',
            },
        },
        options: {
            includeAttachmentFiles: 'Include attachment files',
            includeHistoryEvents: 'Include history',
            includeNameAddendums: 'Include addendums',
            combinedPDF: 'Combined PDF',
            onlyIncludeFieldsWithData: 'Only include fields with data',
            includeWarrantActivities: 'Include warrant activity',
            includeConfidentialInformation: 'Include confidential information',
            redactFields: 'Redact information',
            includeSelectedInZip: 'Include selected attachments in .zip file',
            includeAllInZip: 'Include all attachments in .zip file',
            includeAttachmentForBulkExport: 'Include attachment files',
            includeZipFilesForBulkExport: (type: string) => `Zip ${type} files`,
            mergeSelectedAttachments:
                'Merge selected attachments into attachment addendum and include in .zip file',
            mergeAllAttachments:
                'Merge all attachments into attachment addendum and include in .zip file',
            supportedFileTypes: 'Supported file types for merge: JPG, PNG, and PDF',
            attachmentTooltip:
                'A report packet containing attachments must be selected to export attachments',
        },
        releaseTracking: {
            components: {
                CreateReleaseModal: {
                    title: 'Release Tracking',
                },
                EditReleaseModal: {
                    title: 'Edit Release Tracking',
                },
                ReleaseTracking: {
                    title: 'Release History',
                    noReleases: 'No releases',
                },
                ReleaseHistoryBlock: {
                    addAttachment: 'Edit / Add Attachments',
                    downloadRelease: 'Download Release',
                    exportedBy: 'Exported By',
                    edit: 'Edit',
                    showDetails: 'Show details…',
                    hideDetails: 'Hide details…',
                    details: 'Details',
                    content: 'Content',
                    attachments: 'Release History Attachments',
                    attachmentsTooltip:
                        'Attachments that are added to the release history and are not part of the release content.',
                    version: 'Version',
                    reportVersions: {
                        [ExportReportVersionEnum.CURRENT.name]: 'Current Report',
                        [ExportReportVersionEnum.OFFICER.name]: 'Officer Report',
                    },
                    emailedBy: 'Emailed By',
                    recipients: 'Recipient(s)',
                    emailRelease: 'Email Release',
                },
            },
        },
        ExportsFile: {
            expireTime: 'Expires in 12 hrs',
            systemFailed:
                'Export failed. Try again in a few moments. If this problem persists, contact Mark43 at support@mark43.com.',
            errorMessage: 'Error message:',
            mergeFailedErrorMessage: 'Could not merge the following attachments:',
        },
        FormExports: {
            failedToLoadError: 'Failed to load printing options, please try again.',
            viewReleaseTracking: 'View Release Tracking',
            exportAssociationSuccessMessage: (numExports: number) =>
                `${numExports} ${
                    numExports > 1 ? pluralize('Export') : 'Export'
                } successfully added as an attachment to the report.`,
        },
        FormExportsOptions: {
            exportOptions: 'Export options',
            includeInExport: 'Include in export',
            externalTemplates: {
                title: 'Fillable PDFs',
                show: 'Show',
                hide: 'Hide',
            },
            currentReport: 'Current Report',
            viewAndEditAttachmentSelection: 'View/Edit Selections',
            selectRedactableFields: 'View and edit selections',
            selectedAttachments: (selected: number, total: number, size: number) =>
                `${selected}/${total} attachments selected${size ? ` (${size})` : ''}.`,
            selectedMergeableAttachmentsTooLarge: (size: number, maxSize: number) =>
                `Selection of supported files exceeds total size limit${
                    size ? ` (${size}/${maxSize} max)` : ''
                }`,
            selectedAttachmentsSizeWarning:
                'When an export includes attachments of several gigabytes, it may fail or take too many minutes to zip. If it does, please first try to export without including the large attachments, and then download the large attachments individually.',
            includedMugshotAttachments: 'Person profile attachments are included',
            mugshotAttachmentsTooltip:
                'Person profile attachments cannot be removed from the export',
            download: 'Download',
            export: 'Export',
            cancel: 'Cancel',
            redactAndExport: 'Redact & Export',
            addToReport: 'Add To Report',
            fillablePdfTooltip:
                'Fillable PDFs will be included in your export, but they cannot be redacted at this time',
        },
        MyExports: {
            title: 'My Exports',
            clearAll: 'CLEAR ALL',
            downloadAll: 'DOWNLOAD ALL',
            downloadAllTooltip: (limit: number) => `Download count cannot exceed ${limit} files`,
        },
        CombinedExports: {
            title: 'Combined PDF',
            Case: {
                groupTitle: (caseNumberDisplayName: string, caseNumber: string) =>
                    `${caseNumberDisplayName} ${caseNumber}`,
            },
        },
    },
    forms: {
        AgeRangeSlider: {
            label: 'Age Range',
        },
        DateRangePicker: {
            periodUnits: {
                H: 'Hour',
                D: 'Day',
                M: 'Month',
                Y: 'Year',
            },
            noFilter: 'All Time',
            withinLastPeriodPrefix: 'Last',
            toDatePeriodSuffix: 'to Date',
            customRange: 'Custom Range',
            saveCustomRange: 'Apply',
            invalidDateRange: 'Invalid Date Range',
            labels: {
                startDateUtc: 'Start Date / Time',
                startDate: 'Start Date',
                endDateUtc: 'End Date / Time',
                endDate: 'End Date',
            },
        },
        HeightRangeSlider: {
            label: 'Height Range',
        },
        VehicleYearRangeSlider: {
            label: 'Vehicle Year',
        },
        WeightRangeSlider: {
            label: 'Weight Range',
        },
        YearsOfServiceSlider: {
            label: 'Years of Service Range',
        },
        SignaturePad: {
            capture: 'Capture',
            upload: 'Attach',
            remove: 'Remove',
            cancel: 'Cancel',
            captureComplete: 'Signature capture complete.',
            sigWebNotRunningError:
                'Signature pad software not running. Restart the browser or computer or reinstall SigWeb.',
            notDetectedError: 'No signature pad detected. Check the USB connection.',
            notDetectedOrSupportedError:
                'Signature pad is not plugged in or not supported. Check the USB connection and model number.',
            canvasUploadError: 'Failed to upload the captured signature. Please try again.',
            manualUploadError: 'File upload failed. Please try again.',
            signatureUpload:
                'Signature image should be 70 pixels high and no more than 560 pixels wide.',
        },
        select: {
            ApprovalStatusSelect: {
                labels: {
                    approvalStatus: 'Report Approval Status',
                },
                options: {
                    [approvalStatusClientEnum.APPROVED]: 'Approved',
                    [approvalStatusClientEnum.DRAFT]: 'Draft',
                    [approvalStatusClientEnum.SUBMITTED]: 'Pending',
                    [approvalStatusClientEnum.REJECTED]: 'Rejected',
                    [approvalStatusClientEnum.PENDING_SUPERVISOR_REVIEW]:
                        'Pending Supervisor Review',
                    [approvalStatusClientEnum.PENDING_SECONDARY_REVIEW]: 'Pending Secondary Review',
                    [approvalStatusClientEnum.COMPLETED]: 'Completed',
                },
            },
            ApprovalStatusForCaseSelect: {
                // intentionally omitting 'Case ' from 'Case Approval Status'
                label: 'Approval Status',
                options: {
                    [ApprovalStatusForCaseEnum.APPROVED.name]: 'Approved',
                    [ApprovalStatusForCaseEnum.DRAFT.name]: 'Draft',
                    [ApprovalStatusForCaseEnum.SUBMITTED.name]: 'Pending',
                    [ApprovalStatusForCaseEnum.REJECTED.name]: 'Rejected',
                },
            },
            AttributeSelect: {
                expired: '(expired)',
            },
            AgencyProfileSelect: {
                label: 'Agency Profile',
            },
            BooleanSelect: {
                options: {
                    true: 'Yes',
                    false: 'No',
                },
            },
            CaseDefaultTaskAssigneeTypeSelect: {
                options: {
                    [caseDefaultTaskAssigneeTypeEnum.ASSIGNED_INVESTIGATOR]:
                        'Assigned Investigator',
                    [caseDefaultTaskAssigneeTypeEnum.ROLE]: 'User/Role',
                },
            },
            CaseDefinitionSelect: {
                label: (caseDisplayName: string) => `${caseDisplayName} Type`,
            },
            CaseStatusSelect: {
                label: (caseDisplayName: string) => `${caseDisplayName} Status`,
                options: {
                    hasCaseStatus: {
                        false: 'Unassigned',
                    },
                },
            },
            CountrySelect: {
                label: 'Country',
                loadingFailedError: 'Failed to load. Please try again.',
            },
            CustomReportClassificationAttrSelect: {
                label: (offenseDisplayName: string) =>
                    `Incident / ${offenseDisplayName} Classification`,
                options: {
                    hasCustomReportClassificationAttrId: {
                        false: (offenseDisplayName: string) =>
                            `No Incident / ${offenseDisplayName} Classification`,
                        true: (offenseDisplayName: string) =>
                            `Any Incident / ${offenseDisplayName} Classification`,
                    },
                },
            },
            OffenseCaseStatusAttrSelect: {
                label: (offenseDisplayName: string) => `${offenseDisplayName} Case Status`,
                options: {
                    hasOffenseCaseStatus: {
                        false: (offenseDisplayName: string) =>
                            `No ${offenseDisplayName} Case Status`,
                        true: (offenseDisplayName: string) =>
                            `Any ${offenseDisplayName} Case Status`,
                    },
                },
            },
            DepartmentTypeSelect: {
                label: 'Department Type',
            },
            DispositionApprovalLevelSelect: {
                options: {
                    [DispositionApprovalLevelEnum.NONE_RELEASE.name]:
                        'No Approval (Immediate Release)',
                    [DispositionApprovalLevelEnum.PRIMARY.name]: 'Initial Approval',
                    [DispositionApprovalLevelEnum.SECONDARY.name]: 'Secondary Approval',
                    [DispositionApprovalLevelEnum.NONE_DISPOSITION.name]:
                        'No Approval (Immediate Disposition)',
                },
            },
            DispositionApprovalTypeSelect: {
                options: {
                    [DispositionApprovalTypeEnum.ROLE.name]: 'Roles',
                    [DispositionApprovalTypeEnum.RESPONSIBLE_OFFICER.name]:
                        'Primary Reporting Officer',
                    [DispositionApprovalTypeEnum.ASSIGNED_INVESTIGATOR.name]:
                        'Assigned Investigator',
                    [DispositionApprovalTypeEnum.ASSIGNED_SUPERVISORS.name]:
                        'Assigned Supervisor(s)',
                },
            },
            GangNameSelect: {
                label: 'Criminal Street Gang Name',
                anyGang: 'Any Gang',
            },
            InventoryTypeSelect: {
                label: 'Inventory Type',
                options: {
                    [InventoryTypeEnum.AUDIT.name]: 'Audit',
                    [InventoryTypeEnum.INVENTORY.name]: 'Inventory',
                },
            },
            LabelPrintMethodSelect: {
                options: {
                    [LabelPrintMethodEnum.THERMAL_TRANSFER.name]: 'Thermal Transfer',
                    [LabelPrintMethodEnum.DIRECT_THERMAL.name]: 'Direct Thermal',
                },
            },
            LabelPrinterSelect: {
                label: 'Printer',
            },
            LabelsWithNoneAttrSelect: {
                label: 'Labels',
                options: {
                    hasNoLabels: 'No Label(s)',
                },
            },
            NibrsCodeSelect: {
                label: 'NIBRS Code',
            },
            OffenseCodeSelect: {
                expired: '(expired)',
            },
            OffenseGroupSelect: {
                label: (offenseDisplayName: string) => `${offenseDisplayName} Group`,
                options: {
                    nibrsGroups: {
                        [NibrsOffenseGroupEnum.A.name]: 'NIBRS Group A',
                        [NibrsOffenseGroupEnum.B.name]: 'NIBRS Group B',
                    },
                    ucrGroups: {
                        [UcrSummaryOffensePartEnum.I.name]: 'UCR Part I',
                        [UcrSummaryOffensePartEnum.II.name]: 'UCR Part II',
                    },
                },
            },
            OfficerInvolvementSelect: {
                label: AttributeTypeEnum.FIELD_CONTACT_SUBJECT_TYPE.displayName,
                options: {
                    officerInvolvement: {
                        [OfficerInvolvementEnum.RESPONDING.name]: 'Primary Reporter',
                        [OfficerInvolvementEnum.RECOVERED_BY.name]: 'Property Recovered By Officer',
                        [OfficerInvolvementEnum.ARRESTING.name]: 'Arresting Officer',
                        [OfficerInvolvementEnum.ADVISED_RIGHTS.name]:
                            'Arrest Advised Rights Officer',
                        [OfficerInvolvementEnum.USE_OF_FORCE.name]: 'Use of Force Officer',
                        [OfficerInvolvementEnum.TOW_VEHICLE_RELEASED_BY.name]:
                            'Tow Vehicle Released By Officer',
                    },
                },
            },
            OperationTypeSelect: {
                // dropdown options appear in this order; these are different from
                // legacy strings in `models/case/caseOtherPersonnelModel`, etc.
                label: 'Permission',
                options: {
                    [OperationTypeEnum.SEARCH.name]: 'Can Find',
                    [OperationTypeEnum.READ.name]: 'Can View',
                    [OperationTypeEnum.DELETE.name]: 'Can Edit',
                    [OperationTypeEnum.MANAGE.name]: 'Can Manage',
                },
            },
            PersonsSearchResultsDefaultSelect: {
                options: {
                    [PersonsSearchResultsDefaultEnum.SEARCH_OPTION_NOT_DISPLAYED.name]:
                        'Search Option Not Displayed',
                    [PersonsSearchResultsDefaultEnum.DEFAULT_JUVENILE_ONLY.name]:
                        'Default Juvenile Only',
                    [PersonsSearchResultsDefaultEnum.DEFAULT_ADULT_ONLY.name]: 'Default Adult Only',
                    [PersonsSearchResultsDefaultEnum.DEFAULT_JUVENILE_AND_ADULT.name]:
                        'Default Juvenile and Adult',
                },
            },
            ReportApprovalLevelSelect: {
                options: {
                    [reportApprovalLevelClientEnum.NONE]: {
                        display: 'No',
                        noteDisplay: 'Report will be Completed upon Submission',
                    },
                    [reportApprovalLevelClientEnum.ONE]: {
                        display: 'Yes, only Supervisor Review',
                        noteDisplay: 'Report will be Completed upon Supervisor Approval',
                    },
                    [reportApprovalLevelClientEnum.TWO]: {
                        display: 'Yes, both Supervisor and Secondary Review',
                        noteDisplay: 'Report will be Completed upon Secondary Approval',
                    },
                },
            },
            PropertyCategorySelect: {
                label: 'Property Category',
            },
            RecordSequenceTypeSelect: {
                renDescription: (renDisplayName: string) =>
                    `The Record ID # will copy the linked ${renDisplayName}.`,
                autoGenerated: 'Auto-Generated #',
                autoGeneratedDescription:
                    'A Record ID # will be generated in the specified format by the RMS',
                formattedFreeText: 'Formatted Free-Text',
                formattedFreeTextDescription:
                    'A free-text Record ID # that will conform to a configured format',
                freeText: 'Free-Text #',
                freeTextDescription:
                    'A free-text Record ID # can be entered by the user at record creation.',
                cad: 'CAD Event #',
                cadDescription: 'The Record ID # will copy the linked CAD Event #.',
            },
            RenConfigurationSelect: {
                none: 'No',
                noneDescription: (renDisplayName: string) =>
                    `Record will never be linked to a ${renDisplayName}.`,
                optional: 'Sometimes',
                optionalDescription: (renDisplayName: string) =>
                    `Linking a ${renDisplayName} to this record type is optional.`,
                required: 'Yes',
                requiredDescription: (renDisplayName: string) =>
                    `Record must be linked to a ${renDisplayName}.`,
            },
            SelectOptions: {
                noResults: 'No results found',
                typeToSearch: 'Type to search...',
                noGroup: 'Uncategorized',
            },
            StatePlaneZoneSelect: {
                label: 'State Plane Zone',
            },
            TimeZoneSelect: {
                label: 'Time Zone',
            },
            UcrCodeSelect: {
                label: 'UCR Code',
                options: {
                    hasUcrCode: {
                        false: 'No UCR Code',
                        true: 'Any UCR Code',
                    },
                },
            },
            CodeSelect: {
                label: 'Code',
            },
            UcrGroupSelect: {
                label: 'UCR Group',
            },
            ItemInvolvementSelect: {
                options: {
                    [ItemInvolvementTypeEnum.NONE.name]: 'No Property or Vehicles Involved',
                    [ItemInvolvementTypeEnum.UNKNOWN.name]:
                        'Unknown If Property or Vehicles Involved',
                    [ItemInvolvementTypeEnum.PROPERTY_INVOLVED.name]:
                        'Property and/or Vehicles Involved',
                },
            },
            UserSelect: {
                myself: 'Myself',
            },
        },
        validation: {
            failure: 'Form validation failed',
        },
    },
    cases: {
        core: {
            AssignedToCell: {
                assignedUnit: 'Assigned Unit',
                none: 'None',
            },
            BulkBanner: {
                inProgressTitle: (numJobs: number) => `${numJobs} Updates in Progress`,
                partialSuccessTitle: () => 'Some Updates Successful',
                successfulTitle: (numJobs: number) => `${numJobs} Updates Successful`,
                failedTitle: (numJobs: number) => `${numJobs} Updates Failed`,
                inProgressDescription: 'This may take a few moments to complete.',
                partialSuccessDescription: (errorCount: number, operationCount: number) =>
                    `${errorCount} of ${operationCount} updates failed due to validation, permission, or system errors. Refresh the page to see an updated dashboard.`,
                successfulDescription: 'Refresh the page to see an updated dashboard.',
                failedDescription: (caseDisplayName: string) =>
                    `Updates failed due to validation, permission, or system errors. Try updating these ${caseDisplayName} individually.`,
                startedAt: 'started at',
            },
            BulkManageCasesSidePanel: {
                title: (caseDisplayName: string) => `Bulk Manage ${caseDisplayName}`,
                preBoldDescription: 'Choose fields to edit on the selected ',
                boldDescription: (numCases: number, caseDisplayName?: string) =>
                    `${numCases} ${caseDisplayName?.toLowerCase()}`,
                postBoldDescription: (caseDisplayName?: string) =>
                    `. Saving changes made here will overwrite existing data in fields on the selected ${caseDisplayName?.toLowerCase()}.`,
                saveText: 'Apply Change(s)',
                addMe: 'Add Me',
            },
            CasesActionBar: {
                clearSelection: 'Clear Selection',
                serverError: (caseDisplayName: string) =>
                    `An error occurred with the ${caseDisplayName} assignment, please try again.`,
                selectAll: (totalResultsCount: number) =>
                    `Select all ${totalResultsCount} results.`,
                allSelected: (totalResultsCount: number) =>
                    `All ${totalResultsCount} results are selected.`,
                someOnPageSelected: (selected: number) => {
                    return selected === 1
                        ? '1 result on this page is selected.'
                        : `${selected} results on this page are selected.`;
                },
            },
            CasesContainer: {
                // not uppercased with css because individual case titles aren't
                // all allcaps
                title: (casesDisplayName: string) => `${casesDisplayName}`,
            },
            CaseDetailsForm: {
                titles: {
                    caseInformation: (caseDisplayName: string) => `${caseDisplayName} Information`,
                    otherPersonnel: 'Other Personnel',
                },
                labels: {
                    title: (caseDisplayName: string) => `${caseDisplayName} Name (optional)`,
                    assignedUnit: 'Assigned Unit',
                    assistingInvestigators: 'Assisting Investigator(s)',
                    userRole: 'User',
                    accessLevel: 'Access Level',
                    status: 'Status',
                    closedByDivision: 'Closed by Division',
                    closedByUnit: 'Closed by Unit',
                    statusDate: 'Status Date',
                    dueDate: 'Due Date',
                },
                buttonText: {
                    addMeAsSupervisor: (supervisor: string) => `Add Me as ${supervisor}`,
                    assignToMe: 'Assign to Me',
                    addMe: 'Add Me',
                },
            },
            CaseDetailsModal: {
                title: (caseDisplayName: string) => `Edit/Assign ${caseDisplayName}`,
                titleWithNumber: (caseNumberDisplayName: number, caseNumber: number) =>
                    `Edit/Assign ${caseNumberDisplayName} ${caseNumber}`,
            },
            CreateCaseFromMultiReportsModal: {
                title: (caseDisplayName: string) => `Create New ${caseDisplayName}`,
                descriptionBoldStart: (caseDisplayName: string) => `Create 1 ${caseDisplayName}`,
                descriptionEnd: (numReports: number) => `from ${numReports} Reports selected`,
            },
            CreateNewReportFromMultiRenModal: {
                title: (reportDefinition?: string) => `Create New Report: ${reportDefinition}`,
                okText: 'Create Report',
                description: (renDisplayName: string, reportDefinition: string) =>
                    `Select the ${renDisplayName} for this ${reportDefinition} report`,
                linkedRenReports: (renDisplayName: string, ren: string) =>
                    `${renDisplayName} ${ren} Reports`,
                linkedReport: (recordTitle: string) => `Report ${recordTitle}`,
            },
            CreateManageCaseForm: {
                sectionTitles: {
                    caseInformation: (caseDisplayName: string) => `${caseDisplayName} Information`,
                    personnel: 'Personnel',
                },
                labels: {
                    status: 'Status',
                },
                buttonText: {
                    addMe: 'Add Me',
                },
            },
            DeleteCaseModal: {
                title: (caseDisplayName: string) => `Delete ${caseDisplayName}`,
                confirmationText: (caseDisplayName: string) =>
                    `Are you sure you want to delete this ${caseDisplayName}?`,
                cannotDeleteText: (caseDisplayName: string) =>
                    `This ${caseDisplayName} cannot be deleted because it has been approved at least once.`,
            },
            EditCaseRenModal: {
                title: (renDisplayName: string) => `Edit ${renDisplayName}`,
                okText: 'Save',
                existingCaseRen: (renDisplayName: string) => `Current ${renDisplayName}`,
                newCaseRen: (renDisplayName: string) => `New ${renDisplayName}`,
                duplicateCaseRenError: (caseDisplayName: string) =>
                    `A ${caseDisplayName} already exists for this number.`,
            },
            CaseDetailsSummary: {
                title: (caseDisplayName: string) => `${caseDisplayName} Details`,
                due: 'DUE:',
                overdue: '[Overdue]',
                caseType: (caseDisplayName: string) => `${caseDisplayName} Type`,
                caseStatus: (caseDisplayName: string) => `${caseDisplayName} Status`,
                dueDate: 'Due Date',
                approvalStatus: 'Approval Status',
                assistingInvestigators: 'Assisting Investigators',
                assignedPersonnelUnit: 'Assigned Unit',
                supervisors: 'Supervisors',
                edit: 'Edit',
            },
            CaseTargetProfileDetails: {
                targetProfileDetails: (targetProfileDisplayName: string) =>
                    `${targetProfileDisplayName} Details`,
            },
            CaseTasks: {
                tasks: 'Tasks',
                noTasks: 'No Tasks',
            },
            CaseHeader: {
                createCase: (caseDisplayName: string) => `Create ${caseDisplayName}`,
                deleteCase: (caseDisplayName: string) => `Delete ${caseDisplayName}`,
                supervisors: 'Supervisors',
                export: 'Export',
                editCaseRen: (renDisplayName: string) => `Edit ${renDisplayName}`,
                manageCase: (caseDisplayName: string) => `Manage ${caseDisplayName}`,
                save: 'Save',
                editSecurityClassification: (securityClassificationDisplayName: string) =>
                    `Edit ${securityClassificationDisplayName}`,
            },
            CaseHeaderPermissionsButton: {
                managePermissions: 'Manage Permissions',
                applyCasePermissionsToAllReports: (caseDisplayName: string) =>
                    `Apply ${caseDisplayName} Permissions to All Reports`,
            },
            KebabMenu: {
                delete: 'Delete',
                download: 'Download',
                rename: 'Rename Folder',
                createNewFolder: 'Create New Folder',
                removeFromFolder: 'Remove From Folder',
            },
            CreateFolderModal: {
                title: 'Create Folder',
                okText: 'Create',
                cancelText: 'Cancel',
            },
            CreateFolderForm: {
                label: 'Folder Name',
            },
            UpdateFolderModal: {
                title: 'Update Folder',
                okText: 'Update',
                cancelText: 'Cancel',
            },
            UpdateFolderForm: {
                label: 'Folder Name',
            },
            DeleteCaseAttachmentsModal: {
                title: 'Delete Confirmation',
                okText: 'Delete',
                cancelText: 'Cancel',
                message: (caseDisplayName?: string) =>
                    `All selected folders and attachments linked to the ${caseDisplayName?.toLowerCase()} will be deleted. Any attachments originating outside of the ${caseDisplayName?.toLowerCase()} will return to the Attachments Tab.`,
                nonCaseAttachmentsWarningMessage: (
                    numAttachments: number,
                    entityTypeName: string
                ) => {
                    return `${numAttachments} attachments originated from a ${entityTypeName.toLowerCase()}. Unable to delete attachments.`;
                },
                selectedMultipleAttachmentsWarningMessage: (entityTypeName: string) =>
                    `Selected items were not deleted because multiple ${entityTypeName} cannot be deleted at once. To delete ${entityTypeName}, please select a single ${entityTypeName}.`,
            },
            ApplyCasePermissionsToReportsModal: {
                title: (caseDisplayName: string) =>
                    `Apply ${caseDisplayName} Permissions to All Reports`,
                message: (caseDisplayName: string) =>
                    `This action will apply the current ${caseDisplayName?.toLowerCase()} permissions to all reports within this ${caseDisplayName?.toLowerCase()}. Additional changes to reports can be made by updating the permissions on each report individually.`,
                confirm: 'Confirm',
                errorMessage: (caseDisplayName: string) =>
                    `Failed to apply ${caseDisplayName?.toLowerCase()} permissions to all reports. Try again, or try updating report permissions individually.`,
            },
            CasesHeader: {
                mine: (caseDisplayName: string) => `My ${caseDisplayName}`,
                unassigned: 'Unassigned Reports',
                all: (caseDisplayName: string) => `All ${caseDisplayName}`,
                newCase: (caseDisplayName: string) => `New ${caseDisplayName}`,
            },
            CasesSearchFormFooter: {
                search: 'Apply',
                clear: 'Clear All Filters',
                saveSearch: 'Save Search',
                unsaveSearch: 'Unsave Search',
            },
            CasesTable: {
                columns: {
                    caseNumber: (caseDisplayName: string) =>
                        `${caseDisplayName} # - ${caseDisplayName} Name`,
                    createdDateUtc: 'Created Date',
                    involvement: 'Involvement',
                    caseStatus: (caseDisplayName: string) => `${caseDisplayName} Status`,
                    approvalStatus: 'APV Status',
                },
                noDataText: (caseDisplayName: string) => `No ${caseDisplayName}`,
            },
            CreateCaseModal: {
                title: (caseDisplayName: string) => `Create New ${caseDisplayName}`,
                renCasesSection: (renDisplayName: string, ren: string, caseDisplayName: string) =>
                    `${renDisplayName} ${ren}: ${caseDisplayName}`,
                casesSection: (caseDisplayName: string) => `${caseDisplayName}`,
                renReportsSection: (renDisplayName: string, ren: string) =>
                    `${renDisplayName} ${ren}: Reports`,
                reportsSection: 'Reports',
                noReportsForRen: (renDisplayName: string) =>
                    `No reports exist for that ${renDisplayName}`,
                getCasesForReportError:
                    'Failed to find cases for this report. If this report is already linked to existing cases, please click Cancel and try again.',
            },
            CaseTypesSection: {
                caseTypeSection: (caseDisplayName: string) => `${caseDisplayName} Type`,
                filterPlaceholder: (caseDisplayName: string) => `Filter ${caseDisplayName} Types`,
            },
            CaseSidebar: {
                summary: (caseDisplayName: string) => `${caseDisplayName} Summary`,
                notes: (caseNoteCount: number) => `Notes (${caseNoteCount})`,
                attachments: (attachmentCount: number) => `Attachments (${attachmentCount})`,
                linkedProfiles: 'Profiles',
                taskList: 'Tasks',
                photoLineup: (photoLineupFieldName: string, numLineups: number) =>
                    `${photoLineupFieldName} (${numLineups})`,
                eFiles: (eFileDisplayName: string, numEFiles: number) =>
                    `${eFileDisplayName} (${numEFiles})`,
                tooltips: {
                    editableFromSource: 'Only editable from source report',
                },
            },
            ReasonForRelationForm: {
                removeText: 'Remove',
                entityError: 'No Appropriate Entity Found',
                ren: (renDisplayName: string, ren: string) => `${renDisplayName} ${ren} Reports`,
                includeOtherReportsFromRen: (renDisplayName: string) =>
                    `Include other reports from ${renDisplayName}`,
                recordWithoutRen: (recordNumberDisplayName: string, recordNumber: string) =>
                    `${recordNumberDisplayName} ${recordNumber} Reports`,
                addToCase: (formattedCaseTitle: string) => `Add to ${formattedCaseTitle}`,
                reportAlreadyExists: (caseDisplayName: string) =>
                    `Report already exists in ${caseDisplayName}`,
            },
        },
        myCases: {
            MyCases: {
                noResults: (caseDisplayName: string) => `No ${caseDisplayName}`,
                editAssign: 'Edit/Assign',
                actionButtons: {
                    save: 'Save',
                    manageCase: (caseDisplayName: string) => `Manage ${caseDisplayName}`,
                    bulkManageCases: (caseDisplayName: string) => `Bulk Manage ${caseDisplayName}`,
                    export: 'Export',
                },
                maxBulkCaseExportReached: (caseDisplayName?: string) =>
                    `You have exceeded the maximum bulk ${caseDisplayName?.toLowerCase()} export`,
                columns: {
                    assignedDateUtc: {
                        label: 'Date Assigned',
                        ascendingSort: 'Oldest First',
                        descendingSort: 'Newest First',
                    },
                    updatedDateUtc: {
                        label: 'Modified Date',
                        ascendingSort: 'Oldest First',
                        descendingSort: 'Newest First',
                    },
                    createdDateUtc: {
                        label: 'Creation Date',
                        ascendingSort: 'Oldest First',
                        descendingSort: 'Newest First',
                    },
                    dueDateUtc: {
                        label: 'Due Date',
                        ascendingSort: 'Oldest First',
                        descendingSort: 'Newest First',
                    },
                    caseNumberName: {
                        label: (caseDisplayName: string) =>
                            `${caseDisplayName} # - ${caseDisplayName} Name`,
                    },
                    caseType: {
                        label: (caseDisplayName: string) => `${caseDisplayName} Type`,
                    },
                    yourInvolvement: {
                        label: 'Your Involvement',
                    },
                    assignedUnit: {
                        label: 'Assigned Unit',
                    },
                    supervisors: {
                        label: 'Supervisor(s)',
                    },
                    primaryLocation: {
                        label: 'Event Location',
                    },
                    offenseLocation: {
                        label: (offenseDisplayName: string) => `${offenseDisplayName} Location`,
                    },
                    caseApprovalStatus: {
                        label: 'APV STAT',
                    },
                    caseStatus: {
                        label: (caseDisplayName: string) => `${caseDisplayName} Status`,
                    },
                },
            },
            MyCasesSearchPersonnelFieldset: {
                title: 'Personnel',
            },
            MyCasesSearchCaseInformationFieldset: {
                title: (caseDisplayName: string) => `${caseDisplayName} Information`,
            },
        },
        caseReviews: {
            CaseReviewComment: {
                approved: 'Approved',
                rejected: 'Rejected',
                requestForApproval: 'Request for Approval',
                notificationSentToUser: (user: string) => `Notification sent to ${user}`,
            },
            CaseReviewForm: {
                requestApproval: 'Request Approval',
                approve: 'Approve',
                reject: 'Reject',
                comment: 'Comment',
                labels: {
                    userToNotify: 'Notify',
                    body: (canApprove: boolean, caseDisplayName?: string) =>
                        `Note ${
                            canApprove
                                ? `(Required to reject ${caseDisplayName?.toLowerCase()})`
                                : ''
                        }`,
                },
            },
            CaseReviewSidePanel: {
                title: (caseDisplayName: string) => `${caseDisplayName} Reviews`,
                noComments: 'No comments yet.',
            },
        },
        caseSummary: {
            CaseReasonForRelationModal: {
                title: 'Reason for Addition',
            },
            CaseReasonForRemovalModal: {
                title: 'Remove Report(s)',
                willNotInvestigateText: 'Will Not Investigate',
                okText: (caseDisplayName: string) => `Create ${caseDisplayName}`,
                cancelText: 'Cancel',
                removeText: 'Remove',
                removeBodyTextBeginning: 'Are you sure you want to remove',
                removeBodyTextEnd: (caseDisplayName: string, renDisplayName: string) =>
                    `from the ${caseDisplayName}? This will remove all reports, profiles, attachments and associated records attached to this ${renDisplayName}.`,
                removeSelectedReports: (caseDisplayName: string) =>
                    `Select which reports to remove
                     from the ${caseDisplayName}.This will remove all profiles, attachments and associated records associated
                     with selected reports.`,
            },
            CaseReportsTable: {
                columns: {
                    renOwnerDate: (renDisplayName: string) => `${renDisplayName} / OWNER / DATE`,
                    reportType: 'Report Type',
                    locations: 'Locations',
                    persons: 'Persons',
                    stat: 'Stat',
                },
            },
            EnhancedCaseReportsTable: {
                columns: {
                    renOwnerDate: (renDisplayName: string) => `${renDisplayName} / OWNER / DATE`,
                    reportType: 'Report Type',
                    narrative: 'Narrative',
                    eventLocation: 'Event Location',
                    persons: 'Involved Persons',
                    stat: 'Status',
                    offenseLocation: (offenseDisplayName: string) =>
                        `${offenseDisplayName} Location`,
                },
                noResult: 'No Report Results',
                remove: 'Remove',
                cannotEditCaseTooltip: (caseDisplayName: string) =>
                    `Cannot remove report(s) because this user does not have edit permissions on the ${caseDisplayName}.`,
            },
            CaseReports: {
                title: (caseDisplayName: string) => `${caseDisplayName} Reports`,
                addReports: 'Add Report(s)',
                placeholder: 'Search for reports',
                helpText: (caseDisplayName: string) =>
                    `Reports in this ${caseDisplayName} will not appear in this search.`,
                addText: 'Add Selection(s)',
                entitySearchResultHeader: (size: number) => `Results (${size})`,
                cannotEditCaseTooltip: (caseDisplayName: string) =>
                    `Cannot add report(s) because this user does not have edit permissions on the ${caseDisplayName}.`,
            },
            CaseTasksTable: {
                columns: {
                    description: 'Description',
                    status: 'Status',
                    dueDate: 'Due Date',
                    assignedTo: 'Assigned To',
                },
                noResult: 'No Tasks',
            },
            CaseNotes: {
                title: 'Notes',
                addNote: 'Add Note',
                seeAll: 'See All',
                save: 'Save',
                location: 'Location',
                attachment: 'Attachment',
                attachments: 'ATTACHMENTS',
                noteIsEmpty: 'Note is empty',
                errorFailedToSaveCaseNotePleaseTryAgain: {
                    title: 'Error',
                },
                confirmDeleteModal: {
                    title: (caseDisplayName: string) =>
                        `Remove ${caseDisplayName} Note Confirmation`,
                    message: (caseDisplayName?: string) =>
                        `Are you sure you wish to remove this ${caseDisplayName?.toLowerCase()} note?`,
                },
                failedToDeleteLocation: 'Failed to delete location. Please try again.',
            },
            CaseNotesTable: {
                columns: {
                    titleAuthorDate: 'Title / Author / Date',
                    note: 'Note',
                },
                sortAsc: 'Oldest First',
                sortDsc: 'Newest First',
                noResult: 'No Notes',
            },
        },
        unassignedReports: {
            NarrativeModal: {
                title: 'Narrative',
                close: 'Close',
            },
            UnassignedReports: {
                noResults: 'Search Returned 0 Results',
                createCase: (caseDisplayName: string) => `Create ${caseDisplayName}`,
                createCases: (caseDisplayName: string) => `Create ${caseDisplayName}`,
                bulkCreateCases: (caseDisplayName: string) => `Bulk Create ${caseDisplayName}`,
                createSeprateCases: (caseDisplayName: string) =>
                    `Create Separate ${caseDisplayName}`,
                addToExistingCase: (caseDisplayName: string) =>
                    `Add To Existing ${caseDisplayName}`,
                willNotInvestigate: (fieldDisplayName: string, isBulk: boolean) =>
                    `${isBulk ? 'Bulk ' : ''}${fieldDisplayName}`,
                cannotMarkWillNotInvestigate: (
                    willNotInvestigate: string,
                    renDisplayName: string,
                    offenseReportDefinitionName: string
                ) =>
                    `Cannot Mark '${willNotInvestigate}' if ${renDisplayName} contains ${offenseReportDefinitionName}`,
                createNumCases: (numCases: number, caseDisplayName: string) =>
                    `Create ${caseDisplayName} (${numCases})`,
                reportsFromNumRensSelected: (numRens: number, fieldDisplayName: string) =>
                    `Reports from ${numRens} ${fieldDisplayName}(s) selected, which will create`,
                reportsFromNumReportsSelected: (numReports: number) =>
                    `${numReports} Reports selected, which will create`,
                casesWillBeCreatedForAllReports: (caseDisplayName: string) =>
                    `${caseDisplayName} will be created for all reports`,
                numCases: (numCases: number, caseDisplayName: string) =>
                    `${numCases} ${caseDisplayName}`,
                routingLabelsColumnHeader: 'Labels',
                tabs: {
                    new: 'New',
                },
                columns: {
                    sortBy: {
                        label: 'Sort By',
                    },
                    reportingEventNumber: {
                        label: (renDisplayName: string, recordNumberDisplayName: string) =>
                            `${renDisplayName} / ${recordNumberDisplayName}`,
                        ascendingSort: (renDisplayName: string, recordNumberDisplayName: string) =>
                            `${renDisplayName} / ${recordNumberDisplayName} A-Z`,
                        descendingSort: (renDisplayName: string, recordNumberDisplayName: string) =>
                            `${renDisplayName} / ${recordNumberDisplayName} Z-A`,
                    },
                    eventDate: {
                        label: 'Event Date',
                        descendingSort: 'Newest First',
                        ascendingSort: 'Oldest First',
                    },
                    modifiedDate: {
                        label: 'Modified Date',
                        descendingSort: 'Newest First',
                        ascendingSort: 'Oldest First',
                    },
                    creationDate: {
                        label: 'Creation Date',
                        descendingSort: 'Newest First',
                        ascendingSort: 'Oldest First',
                    },
                    reportType: {
                        label: 'Report Details',
                    },
                    offenseLocation: {
                        label: (offenseDisplayName: string) => `${offenseDisplayName} Location`,
                    },
                    location: {
                        label: 'Report Taken Location',
                    },
                    persons: {
                        label: 'Persons',
                    },
                    narrative: {
                        label: 'Narrative',
                    },
                    status: {
                        label: 'Stat',
                    },
                },
                savedSearch: {
                    tabLabel: 'Tab',
                    new: 'New, ',
                    willNotInvestigate: (willNotInvestigateLabel: string) =>
                        `${willNotInvestigateLabel}, `,
                },
            },
            UnassignedReportsSearchForm: {
                searchTooltip: (reportCaseStatusLabel: string) =>
                    `${reportCaseStatusLabel} must be filled in`,
            },
            UnassignedReportsSearchPersonnelFieldset: {
                title: 'Personnel',
            },
            UnassignedReportsSearchReportDetailsFieldset: {
                title: 'Report Details',
            },
            UnassignedReportsSearchReportDefinitionFieldset: {
                title: 'Report Type',
                addReportType: 'Report Type',
                labels: {
                    reportDefinitionId: 'Report Type',
                    nibrsCodes: 'NIBRS Code',
                },
            },
            WillNotInvestigateModal: {
                bulkTitle: (fieldDisplayName: string) => `Bulk ${fieldDisplayName}`,
                title: (fieldDisplayName: string) => `${fieldDisplayName}`,
                description: 'This action will be applied to ',
                boldDescription: (numReports: number) => `${numReports} reports.`,
            },
        },
        allCases: {
            AllCases: {
                editAssign: 'Edit/Assign',
                dropFileSuccessfulMessage: (size: number, folderName: string) =>
                    `${size} file(s) have been moved to ${folderName}`,
                dropFolderSuccessfulMessage: (size: number, folderName: string) =>
                    `${size} folder(s) have been moved to ${folderName}`,
                dropFolderAndFileDropSuccessMessage: (
                    fileSize: number,
                    folderSize: number,
                    destFolderName: string
                ) =>
                    `${fileSize} file(s) and ${folderSize} folder(s) have been moved to ${destFolderName}`,
                actionButtons: {
                    manageCase: (caseDisplayName: string) => `Manage ${caseDisplayName}`,
                    bulkManageCases: (caseDisplayName: string) => `Bulk Manage ${caseDisplayName}`,
                    export: 'Export',
                },
                maxBulkCaseExportReached: (caseDisplayName: string) =>
                    `You have exceeded the maximum bulk ${caseDisplayName} export`,
                tabs: {
                    tabLabel: 'Tab',
                    open: 'Open',
                    inactive: 'Inactive',
                    closed: 'Closed',
                },
                noResults: 'Search Returned 0 Results',
                columns: {
                    assignedDateUtc: {
                        label: 'Date Assigned',
                        ascendingSort: 'Oldest First',
                        descendingSort: 'Newest First',
                    },
                    updatedDateUtc: {
                        label: 'Modified Date',
                        ascendingSort: 'Oldest First',
                        descendingSort: 'Newest First',
                    },
                    createdDateUtc: {
                        label: 'Creation Date',
                        ascendingSort: 'Oldest First',
                        descendingSort: 'Newest First',
                    },
                    dueDateUtc: {
                        label: 'Due Date',
                        ascendingSort: 'Oldest First',
                        descendingSort: 'Newest First',
                    },
                    caseNumberName: {
                        label: (caseDisplayName: string) =>
                            `${caseDisplayName} # - ${caseDisplayName} Name`,
                    },
                    investigators: {
                        label: 'Investigator(s)',
                    },
                    primaryLocation: {
                        label: 'Event Location',
                    },
                    offenseLocation: {
                        label: (offenseDisplayName: string) => `${offenseDisplayName} Location`,
                    },
                    caseApprovalStatus: {
                        label: 'APV STAT',
                    },
                },
            },
            AllCasesSearchPersonnelFieldset: {
                title: 'Personnel',
                labels: {
                    noAssignee: (assigneeDisplayName: string) => `No ${assigneeDisplayName}`,
                    noSupervisor: (supervisor: string) => `No ${supervisor}`,
                },
            },
            AllCasesSearchCaseInformationFieldset: {
                title: (caseDisplayName: string) => `${caseDisplayName} Information`,
                labels: {
                    caseStatus: (caseDisplayName: string) => `${caseDisplayName} Status`,
                    subdivision: 'Subdivision',
                },
            },
        },
        linkedProfiles: {
            actions: {
                addEditProfile: 'Add/Edit Profile(s)',
                addInformation: 'Add Information',
                editProfile: 'Edit Profile(s)',
            },
            banner: {
                title: 'Missing Information',
            },
            persons: {
                title: 'Persons',
            },
            locations: {
                title: 'Locations',
            },
            vehicles: {
                title: 'Vehicles',
            },
            property: {
                title: 'Property',
            },
        },
        sourceIndicator: {
            title: 'Source',
        },
        taskList: {
            TaskDetailsSidePanel: {
                title: 'Task Details',
                okText: 'Save',
            },
            CaseTaskList: {
                headers: {
                    task: 'Task',
                    assignee: 'Assigned To',
                    dueDate: 'Due Date',
                    status: 'Status',
                    createTask: 'Create Task',
                },
            },
            TaskListRow: {
                overdue: 'Overdue',
                attach: 'Attach',
            },
            TaskDetailsForm: {
                description: 'Task Description',
                assignee: 'Assigned To',
                dueDate: 'Due Date',
                status: 'Status',
            },
        },
        caseAttachments: {
            CaseAttachments: {
                name: 'Name',
                type: 'Type',
                createdDate: 'Date Created',
                source: 'Source',
                sortDateDesc: 'Newest First',
                sortDateAsc: 'Oldest First',
                downloadAll: 'Download All',
                searchTextPlaceholder: 'Attachment Name, Source',
                noResultsFound: 'No Attachments',
                addAttachmentButton: 'Add Attachments',
                addEditAttachmentButton: 'Add/Edit Attachments',
                downloadZipFileTitle: (caseNumber: string, caseDisplayName: string) =>
                    `${prettify(caseDisplayName)} #${caseNumber} Attachments.zip`,
                attachmentsAndFoldersSearchTextPlaceholder: 'Attachment Name, Folder Name',
                bulkActionsDisabled: 'Bulk actions are not available from the search page.',
            },
            AttachmentTableColumns: {
                description: (displayFieldName: string) => `${displayFieldName} Attachment`,
                folderType: 'Folder',
            },
            caseAttachmentRowViewConfig: {
                caseAttachmentDescription: (caseDisplayName: string) =>
                    `${prettify(caseDisplayName)} Attachment`,
                caseNoteAttachmentDescription: (title: string, caseDisplayName: string) =>
                    `${prettify(caseDisplayName)} Note - ${title}`,
                caseNoteAttachmentSource: (caseDisplayName: string) =>
                    `${prettify(caseDisplayName)} Note`,
                caseNoteAttachmentLink: (caseId: number, caseNoteId: number) =>
                    `cases/${caseId}/notes/${caseNoteId}`,
                caseTaskAttachmentDescription: (
                    description: string | undefined,
                    caseDisplayName: string
                ) => `${prettify(caseDisplayName)} Task - ${description}`,
                caseTaskAttachmentSource: (caseDisplayName: string) =>
                    `${prettify(caseDisplayName)} Task`,
                caseTaskAttachmentLink: (caseId: number) => `cases/${caseId}/task-list`,
                caseReportAttachmentDescription: (shortTitle: string | undefined) =>
                    `Report - ${shortTitle}`,
                caseReportAttachmentSource: 'Report',
                caseReportAttachmentLink: (reportId: number) => `reports/${reportId}`,
                caseWarrantAttachmentDescription: (warrantTitle: string) =>
                    `Warrant - ${warrantTitle}`,
                caseWarrantAttachmentSource: 'Warrant',
                caseWarrantAttachmentLink: (warrantId: number) => `warrants/${warrantId}`,
            },
        },
        caseAssociatedRecords: {
            CaseAssociatedRecords: {
                associatedRecord: 'Associated Record',
                reasonForAssociation: 'Reason for Association',
                sourceReport: 'Source Report',
                searchTextPlaceholder: 'Associated Record, Source Report',
                noResultsFound: 'No Associated Records',
            },
        },
        caseNotes: {
            CaseNotes: {
                downloadZipFileTitle: (caseNumber: string, caseDisplayName: string) =>
                    `${caseDisplayName} #${caseNumber} ${caseDisplayName}Notes.zip`,
            },
        },
        casePhotoLineups: {
            CasePhotoLineups: {
                defaultLineupFieldName: 'Photo Lineup',
                summaryRow: (lineupField: string, title: string, name: string) =>
                    `${lineupField} #${title} (${name})`,
                summaryRowNoName: (lineupField: string, title: string) =>
                    `${lineupField} #${title}`,
                noPhotoLineups: (photoLineupFieldName: string) => `No ${photoLineupFieldName}`,
                createLineupButton: (photoLineupFieldName: string) =>
                    `Create ${photoLineupFieldName}`,
                CreateLineupModal: {
                    title: (photoLineupFieldName: string) => `Create New ${photoLineupFieldName}`,
                    message: (photoLineupFieldName: string) =>
                        `Select suspect image for ${photoLineupFieldName}`,
                    okText: 'Create',
                    cancelText: 'Cancel',
                    personOfInterest: 'Person of Interest',
                },
                LineupKebabMenu: {
                    edit: 'Edit',
                    delete: 'Delete',
                    export: 'Export',
                },
                DeleteLineupModal: {
                    deletePhotoLineup: (photoLineupFieldName: string) =>
                        `Delete ${photoLineupFieldName}`,
                    deletePhotoLineupConfirmation: (photoLineupFieldName: string, title: string) =>
                        `Are you sure you want to delete ${photoLineupFieldName} ${title}?`,
                    cancelText: 'Cancel',
                    deleteText: 'Delete',
                },
            },
            ComposeLineup: {
                header: (lineupField: string, title: string, name: string) =>
                    `Create ${lineupField} #${title} (${name})`,
                headerNoName: (lineupField: string, title: string) =>
                    `Create ${lineupField} #${title}`,
                title: (lineupField: string, title: string) => `${lineupField} # ${title}`,
                subtitle: (minPhotoLineupSlots: number) =>
                    `Minimum of ${minPhotoLineupSlots} images required`,
                uploadImages: 'Upload Image(s)',
                shuffleOrder: 'Shuffle Order',
                errorText: 'Error',
                goBackText: 'Go Back',
                saveText: 'Save',
                savingText: 'Saving',
                cancelText: 'Cancel',
                exceedError:
                    'Upload exceeds maximum number of images that can be included within a lineup. Select the images to upload and try again.',
                understood: 'Understood',
                imageError: (number: number) => `${number} image(s) not uploaded successfully`,
                fileSizeError: (number: number) =>
                    `${number} image(s) exceed file size limit of 3GB`,
                maximumLineupTitle: 'Lineup Maximum',
                maximumLineupWarning: (maxNum: number) =>
                    `Maximum number of images permitted is ${maxNum}`,
                sameMasterIdError:
                    'To use this image, remove the selected image related to this profile',
                remove: 'Remove',
            },
            SelectionAreaContainer: {
                errorTitle: 'Search Error',
            },
            PhotoLineupFilterForm: {
                physicalCharacteristics: 'Physical Characteristics',
                identifyingMarks: 'Scars, Marks, and Tattoos',
                applyFilters: 'Apply',
                clearFilters: 'Clear All Filters',
            },
            ExportLineup: {
                header: (lineupField: string, title: string, name: string) =>
                    `Export ${lineupField} #${title} (${name})`,
                lineupPresentation: (lineupField: string) => `${lineupField} Presentation`,
                errorText: 'Error',
                goBackText: 'Go Back',
                downloadText: 'Download',
                cancelText: 'Cancel',
                noPrintables: 'No packets found',
            },
            DetailsLineup: {
                header: (lineupField: string, title: string, name: string) =>
                    `${lineupField} #${title} (${name})`,
                errorText: 'Error',
            },
            ExportDisableTooltip: {
                exportDisableToolTip: (minSlot: number, lineupField: string) =>
                    `A minimum of ${minSlot} photos is required to export a ${lineupField}`,
            },
        },
        caseEFiles: {
            CaseEFiles: {
                noDataText: (eFileDisplayName: string) => `No ${eFileDisplayName} Results`,
                grid: {
                    columns: {
                        createdDate: 'Date Created',
                        eFileNumber: (eFileDisplayName: string) =>
                            `${eFileDisplayName} Number - Name`,
                        ownerName: 'Owner',
                        defendants: 'Defendant(s)',
                        status: 'Status',
                    },
                },
            },
        },
    },
    submissions: {
        createSubmissionsModal: {
            title: 'Create Submission',
            saveText: 'Submit',
        },
    },
    securityClassification: {
        editSecurityClassificationModal: {
            title: (securityClassificationFieldName: string) =>
                `Edit ${securityClassificationFieldName}`,
            saveText: 'Save',
            cancelText: 'Cancel',
            form: {
                addSecurityClassification: (securityClassificationFieldName: string) =>
                    `Add ${securityClassificationFieldName}`,
            },
        },
    },
    eFiles: {
        core: {
            eFileDetailsModal: {
                createEFile: (eFileDisplayName: string) => `Create ${eFileDisplayName}`,
                manageEFile: (eFileDisplayName: string, eFileLocalId: string) =>
                    `Manage ${eFileDisplayName} ${eFileLocalId}`,
                saveText: 'Save',
                form: {
                    addInvolvedPersonnel: (involvedPersonnelFieldName: string) =>
                        `Add ${involvedPersonnelFieldName}`,
                    addMe: 'Add Me',
                },
            },
            header: {
                title: (eFileDisplayName: string, eFileLocalId: string) =>
                    `${eFileDisplayName} ${eFileLocalId}`,
                newSubmission: 'New Submission',
                submission: 'Submission',
                tasks: 'Tasks',
                hamburgerMenu: {
                    manageEFileDetails: (eFileDisplayName: string) =>
                        `Manage ${eFileDisplayName} Details`,
                },
            },
            sidebar: {
                defendantsLink: 'Defendants',
                materialLink: 'Material',
                summaryLink: 'Summary',
                tasksLink: 'Tasks',
            },
            summaryPage: {
                detailsCard: {
                    title: (eFileDisplayName: string) => `${eFileDisplayName} Details`,
                },
            },
            iceCreamMenu: {
                deactivate: (eFileDisplayName: string) => `Deactivate from ${eFileDisplayName}`,
                reactivate: (eFileDisplayName: string) => `Reactivate in ${eFileDisplayName}`,
                remove: (eFileDisplayName: string) => `Remove from ${eFileDisplayName}`,
            },
            currentlyViewingSidePanel: {
                currentlyViewing: 'Currently Viewing',
            },
            sidePanel: {
                buttonLabel: {
                    [LinkTypesEnum.DEFENDANT_IN_EFILE]: 'Add Defendant(s)',
                    [LinkTypesEnum.INVOLVED_PROFILE_IN_EFILE]: 'Add Victim / Witness',
                    [LinkTypesEnum.ITEM_IN_EFILE]: 'Add Items',
                    [LinkTypesEnum.ATTACHMENT_IN_EFILE]: 'Add Attachments',
                },
                title: {
                    [LinkTypesEnum.DEFENDANT_IN_EFILE]: 'Add Defendant(s)',
                    [LinkTypesEnum.INVOLVED_PROFILE_IN_EFILE]: 'Add Victim / Witness',
                    [LinkTypesEnum.ITEM_IN_EFILE]: 'Add Items',
                    [LinkTypesEnum.ATTACHMENT_IN_EFILE]: 'Add Attachments',
                },
                business: 'Business',
                selectAll: 'Select All',
                section: {
                    suspects: 'SUSPECT(S)',
                    other: 'OTHER',
                },
                person: 'Person',
                noResultsFound: 'No Results Found',
            },
            grid: {
                deactivatedLabels: {
                    [LinkTypesEnum.DEFENDANT_IN_EFILE]: 'Deactivated Defendant',
                    [LinkTypesEnum.INVOLVED_PROFILE_IN_EFILE]: 'Deactivated Witness/Victim',
                    [LinkTypesEnum.ATTACHMENT_IN_EFILE]: 'Deactivated Attachment',
                    [LinkTypesEnum.ITEM_IN_EFILE]: 'Deactivated Item',
                },
            },
        },
        defendants: {
            header: {
                title: 'Defendants',
                addDefendant: 'Add Defendant(s)',
            },
            grid: {
                error: 'Failed to load data for defendants',
                noDataText: 'No Defendant Results',
                paginationSummaryMessage: 'Showing Results',
                columns: {
                    name: 'Name',
                    identifiers: 'Identifiers',
                    locationData: 'Location Data',
                    offenses: (offenseDisplayName: string) => pluralize(offenseDisplayName),
                },
            },
        },
        defendantProfile: {
            error: 'Failed to load data for defendant profile',
        },
        materials: {
            header: {
                title: 'Material',
                addItems: 'Add Items',
                addAttachments: 'Add Attachments',
            },
            body: {
                tabs: {
                    itemTabLabel: 'Items',
                    attachmentTabLabel: 'Attachments',
                },
            },
            attachments: {
                grid: {
                    noDataText: 'No Attachment Results',
                    paginationSummaryMessage: 'Showing Results',
                    columns: {
                        name: 'Name',
                        source: 'Source',
                        createdDate: 'Created Date',
                    },
                    report: 'Report',
                    record: 'Record',
                    remove: (eFileDisplayName: string) => `Remove from ${eFileDisplayName}`,
                    removeError: 'Failed to remove attachment, please try again.',
                },
            },
            items: {
                grid: {
                    noDataText: 'No Item Results',
                    paginationSummaryMessage: 'Showing Results',
                    columns: {
                        description: 'Description',
                        source: 'Source',
                        createdDate: 'Created Date',
                    },
                    report: 'Report',
                    record: 'Record',
                    remove: (eFileDisplayName: string) => `Remove from ${eFileDisplayName}`,
                    removeError: 'Failed to remove item, please try again.',
                },
            },
        },
        tasks: {
            header: {
                title: 'Tasks',
                addItems: 'Add Task(s)',
            },
        },
    },
    insights: {
        core: {
            Insights: {
                title: 'Insights',
            },
        },
        GoodDataDashboard: {
            failedToInjectJWTerror:
                'Unable to renew insights session; you could be logged out shortly',
        },
        NavigationDrawer: {
            addNewDashboard: 'New Dashboard',
            failedToLoadDashboardserror: 'Unable to load dashboards',
        },
    },
    mergeEntities: {
        generic: {
            lastModified: 'Last modified',
        },
        MergeEntitiesDashboard: {
            title: 'Merge Profiles',
        },
        MergeActionBar: {
            mergeProfiles: 'Merge profiles',
            cancelMerge: 'Cancel merge',
        },
        MergeEntitiesConfirmationModal: {
            title: 'Confirm Merge',
            message:
                'The two profiles you have selected will now be merged together. They will no longer be independently accessible.',
            notice: 'This action is final. Profile merging cannot be undone.',
            confirm: 'Merge profiles',
        },
        MergeNoticeRow: {
            notice: 'Values not displayed here (e.g. address, phone number) will be automatically pulled from both profiles into the new, merged profile. No data will be lost.',
        },
        SelectAllHeaderColumn: {
            selectAll: 'Select all values',
        },
    },
    notifications: {
        dashboard: {
            NotificationsAlerts: {
                attachmentsN: (n: number) => `(${n})`,
                noAlerts: 'No Alerts',
                expired: 'Expired',
                active: 'Active',
            },
            NotificationsDashboard: {
                title: 'Notifications',
                settings: 'Settings',
                menu: {
                    // keyed by route paths
                    inbox: 'Inbox',
                    archive: 'Archive',
                    alerts: 'Alerts',
                },
            },
            NotificationsTable: {
                noNotifications: 'No Notifications',
                noResults: 'Search Returned 0 Results',
                all: 'All',
                archive: 'Archive',
                markAs: 'Mark As',
                // yes these labels are unintuitive, see NotificationStatus.java
                markAsAcknowledged: 'Read',
                markAsRead: 'Unread',
                unarchive: 'Unarchive',
            },
            NotificationsActionBar: {
                selectAll: (totalResultsCount: number) => `Select all ${totalResultsCount} results`,
                allSelected: (totalResultsCount: number) =>
                    `All ${totalResultsCount} results are selected.`,
                allOnPageSelected: (selected: number) =>
                    `All ${selected} results on this page are selected.`,
                someOnPageSelected: (selected: number) => {
                    if (selected === 1) {
                        return '1 result on this page is selected.';
                    } else {
                        return `${selected} results on this page are selected.`;
                    }
                },
                clearSelection: 'Clear Selection',
            },
            unsubscribeConfirmation: (notificationType = 'this notification') =>
                `You have successfully unsubscribed from ${notificationType}.\nClick Settings button to re-subscribe or adjust your settings.`,
        },
        popover: {
            NotificationsPopover: {
                title: (count: number) => `Notifications (${count})`,
                labels: {
                    notificationsPage: 'See All',
                    noNotifications: 'You Have No Notifications',
                    settings: 'Settings',
                },
            },
        },
        settings: {
            NotificationCategorySettingsFieldset: {
                labels: {
                    type: 'Actions',
                    email: 'Email',
                    inApp: 'In-App',
                },
            },
            NotificationSettingsSidePanel: {
                title: 'Edit Notification Settings',
            },
            NotificationSettingsSummary: {
                labels: {
                    email: 'Email',
                    inApp: 'In-App',
                },
            },
        },
    },
    reports: {
        core: {
            EntitySidebar: {
                about: 'About',
                involvedProfiles: 'Involved Profiles',
                tasks: 'Tasks',
                eventInfo: 'Event Info',
                submissions: 'Submissions',
            },
            InvolvedProfilesSidebar: {
                people: 'People',
                organizations: (organization: string) => `${pluralize(organization)}`,
                locations: 'Locations',
                vehicles: 'Vehicles',
                property: 'Property',
                expandAll: 'Expand all',
                collapseAll: 'Collapse all',
                filterPlaceholder: 'Filter by name',
                noFilterResults: 'No matching profiles',
                noResults: 'No involved profiles on this report',
            },
            EventInfo: {
                coreEventInfo: 'Core Event Info',
                eventHistory: 'Event History',
                showMore: 'Show More',
                showLess: 'Show Less',
            },
            ChargeTitle: {
                vacated: '(Vacated)',
            },
            BookingSidePanel: {
                title: 'Booking',
                noChargesCloseButton: 'Close',
                noChargesMessage:
                    'Selected report has no arrests, so booking information cannot be entered.',
                chargesText: (numCharges: number) =>
                    `${numCharges} ${numCharges === 1 ? 'charge' : 'charges'}`,
                arrestText: (recordNumber: string) => `Arrest #${recordNumber}`,
                applyToAll: 'Apply to all',
            },
            LocationSummaryViewWrapper: {
                addText: 'Location',
                removeErrorModalTitle: 'Remove Location',
            },
            LocationSummaryView: {
                removeText: 'Remove',
            },
            NameSummaryViewWrapper: {
                cancel: 'Cancel',
                nameSocietyOptions: {
                    person: 'Person',
                    organization: (organization: string) => `${organization}`,
                    noInfoKnown: 'No Info Known',
                },
                noInfoKnownError: 'Failed to add person, please try again',
                addButtonText: (personType: string) => `Add ${personType}`,
            },
            NibrsAdminSection: {
                header: 'NIBRS Admin',
                message: {
                    noErrors: 'This report has no NIBRS errors',
                    hasFatalErrors:
                        'This report contains fatal errors which prevent NIBRS segment generation. The report cannot be exported until the errors are resolved.',
                    hasNibrsErrors: 'This report has NIBRS errors',
                    unChecked: 'Please run error check for this report',
                    running: 'NIBRS error check is running',
                    failed: 'Something went wrong. Try refreshing the page or running the error check again. If this problem persists, contact Mark43 at support@mark43.com',
                },
                button: {
                    runErrorCheck: 'Run Error Check',
                    viewNibrsDetails: 'View NIBRS Details',
                },
            },
            ArrestBlock: {
                submitReportSeparately: 'Submit Report Separately',
                separateAndSubmit: 'Separate and Submit',
                separateAndSubmitMessage:
                    'Are you sure you want to separate and submit this report?',
            },
            ArrestBlockDropdownMenu: {
                dropdownOptions: {
                    separateReports: 'View Report Separately',
                    editLabels: 'Edit Labels',
                    deleteReport: 'Delete Report',
                },
            },
            ArrestCard: {
                defendantUpdateErrorMessage: (defendantId: number, fieldName: string) => {
                    const action = !defendantId ? 'remove' : 'add';
                    return `Failed to ${action} ${fieldName}`;
                },
            },
            ArrestCardForm: {
                assignToMe: 'Myself',
            },
            ArrestChargesSidePanel: {
                NChargesScreen: {
                    priorOffense: (offenseDisplayName: string) => `Prior ${offenseDisplayName}`,
                    newOffense: (offenseDisplayName: string) => `New ${offenseDisplayName}`,
                    newOffenseDisabledTooltip: (offenseDisplayName: string) =>
                        `Cannot create stub ${offenseDisplayName}. If there exists a linked ${offenseDisplayName} report which was previously submitted, please return that ${offenseDisplayName} report to draft and add the ${offenseDisplayName} directly to it. Otherwise, it means a new ${offenseDisplayName} report cannot be linked to this report.`,
                    legacyOffense: (offenseDisplayName: string) => `Legacy ${offenseDisplayName}`,
                    cancel: 'Cancel',
                    findWarrant: 'Find Warrant',
                    addNewWarrant: 'Add New Warrant',
                    addWarrant: 'Warrant',
                    chargeTitle: (index: number) => `Charge #${index}`,
                    offenseRenTitle: (offenseDisplayName: string, renLabel: string) =>
                        `${offenseDisplayName} ${renLabel}`,
                    nonMatchingAgencies:
                        'The primary agency on the arrest report does not match the primary agency on the linked offense report. If charges should be linked, the primary agencies must match.',
                },
                PriorOffenseSearchScreen: {
                    searchButtonText: 'Search',
                    orDivider: 'Or',
                    searchStartDate: 'Between',
                    searchEndDate: 'And',
                },
                PriorOffenseSearchResultsScreen: {
                    noReportsFound: 'No Reports Found',
                    respondingOfficerByPrefix: '| By',
                    topNOffenses: (offenseDisplayName: string, n: number) =>
                        n === 1
                            ? `Top ${offenseDisplayName}`
                            : `Top ${n} ${pluralize(offenseDisplayName)}:`,
                    nMoreOffenses: (offenseDisplayName: string, n: number) =>
                        `${n} more ${pluralize(offenseDisplayName, n)}`,
                    viewMoreResults: 'View More Results',
                },
                PriorOffenseSearchOffenseSelectorScreen: {
                    addOffenseButtonText: (offenseDisplayName: string) =>
                        `Add ${offenseDisplayName}`,
                },
                FindWarrantSearchScreen: {
                    searchButtonText: 'Search',
                    orDivider: 'Or',
                    searchStartDate: 'Between',
                    searchEndDate: 'And',
                },
                FindWarrantSearchResultsScreen: {
                    noWarrantsFound: 'No Warrants Found',
                    viewMoreResults: 'View More Results',
                },
                StubWarrantScreen: {
                    isOtherJurisdictionDisabledHelpText:
                        'Charge code was configured to be external warrant via admin page. Contact Department admin or select a different charge',
                },
                sidePanelTitle: 'Add/Edit Charges',
                findWarrantScreensTitle: 'Add Warrant',
                legacyOffenseScreenTitle: (offenseDisplayName: string) =>
                    `Add Legacy ${offenseDisplayName}`,
                priorOffenseSearchScreensTitle: (offenseDisplayName: string) =>
                    `Look Up Previous ${pluralize(offenseDisplayName)}`,
                stubOffenseScreenTitle: (offenseDisplayName: string) =>
                    `Add New ${offenseDisplayName}`,
                stubWarrantScreenTitle: 'Add Warrant',
                sidePanelSaveButtonText: 'Save',
            },
            ReasonForRelationSidePanel: {
                reportAddedTo: (caseDisplayName: string, caseNumber: string) =>
                    `Report added to ${caseDisplayName} #${caseNumber}`,
                title: (displayCaseName: string) => `Add Report to ${displayCaseName}(s)`,
                recentlyViewed: (displayCaseName: string) => `Recently Viewed ${displayCaseName}`,
                entitySearchResultLabel: 'Quick Search',
                entitySearchResultHeader: (size: number) => `Result (${size})`,
                entitySearchResultFooter: {
                    addText: 'Add',
                    closeText: 'Close',
                },
                nextText: 'Next',
                saveText: 'Save',
                openText: 'Open',
            },
            ChargesCard: {
                title: 'Charges',
                noData: 'There are no charges yet.',
                addCharges: 'Add/Edit Charges',
                sealedCharge: 'Sealed Charge',
            },
            UseOfForceCardForm: {
                myself: 'Myself',
                uofSupervisorName: 'Supervisor Name',
            },
            BookingCard: {
                lockupDetails: 'Lockup Details',
                noChargesMessage:
                    'Report has no charges, so booking information cannot be entered.',
                applyToAll: 'Apply to all',
                sealedCharge: 'Sealed Charge',
            },
            PropertyCard: {
                title: 'Property',
            },
            VehicleCard: {
                title: 'Vehicle',
                printLabels: 'Print Label(s)',
            },
            SummaryNarrativecard: {
                title: 'Summary Narrative',
            },
            NarrativeCard: {
                title: 'Narrative',
                guideDropdownLabel: 'Select a guide',
                autosaveReminder: (duration: number) => `autosaves every ${duration} seconds`,
                expandCollapseLabel: (isExpanded: boolean) => (isExpanded ? 'collapse' : 'expand'),
            },
            NarrativeCardContent: {
                sealingNotice: (formattedUser: string) =>
                    formattedUser ? `Contact ${formattedUser} for more information.` : '',
                autosaveNotificationMessage: (date: string) =>
                    `Narrative failed to save and was restored from a version created on ${date}`,
                autosaveFailed:
                    'Autosave failed, please click the save button to save your latest changes.',
                nonDisclosureMessage: 'A person in this report should not be disclosed.',
                loadNarrativeGuidesFailed: 'Failed to load narrative guides.',
            },
            NarrativeVariables: {
                officer: 'Officer',
                assistingOfficer: 'Assisting Officer',
                eventStartDate: 'Event Start Date',
                eventEndDate: 'Event End Date',
                vehicle: 'Vehicle',
                property: 'Property',
                guide: 'Guide',
                guideSearchPlaceholder: 'Search guides',
                noResults: 'No Results',
                g: 'G',
                at: '@',
                today: 'Today',
                offenseStartDate: (prefix: string) => `${prefix} Start Date`,
                offenseEndDate: (prefix: string) => `${prefix} End Date`,
                firstUnitDispatchDateHeader: 'First Dispatched',
                firstUnitArrivalDateHeader: 'First On Scene',
                callDateHeader: 'Call Time',
            },
            ItemCard: {
                addEditPropertyButton: 'Add/Edit Property',
                addEditVehicleButton: (plural: boolean) => `Add/Edit Vehicle${plural ? '(s)' : ''}`,
                propertyHeaderText: 'Property',
                searchTextPlaceholder: 'Filter for item type, description, etc.',
                statusHeaderText: 'Status',
                vehicleHeaderText: 'Vehicle',
                unknownQuantity: 'Unknown',
                associatedNames: 'Associated Person(s)',
                dateEffectiveFrom: 'Effective From',
                dateEffectiveTo: 'Effective To',
                attachments: 'Attachments',
                addVehicleButton: 'Add Vehicle',
            },
            LinkItemsToOffenseSidePanel: {
                linkPropertyToOffenseTitle: (offenseDisplayName: string, title: string) =>
                    `Link Property to ${offenseDisplayName} ${title}`,
                linkVehiclesToOffenseTitle: (offenseDisplayName: string, title: string) =>
                    `Link Vehicle(s) to ${offenseDisplayName} ${title}`,
                linkPropertyToOffenseButton: (offenseDisplayName: string) =>
                    `Link Property to ${offenseDisplayName}`,
                linkVehiclesToOffenseButton: (offenseDisplayName: string) =>
                    `Link Vehicle(s) to ${offenseDisplayName}`,
            },
            ManageItemsItemList: {
                tooltips: {
                    editItem: 'Edit item data',
                    duplicateItem: 'Duplicate item data',
                    deleteItem: 'Delete item from report',
                    disabledCheckboxTooltip: ({
                        dropOffLocation,
                        officerPossession,
                        custody,
                    }: {
                        dropOffLocation: string;
                        officerPossession: string;
                        custody: string;
                    }) =>
                        `Only items in police ${custody?.toLowerCase()} with a ${dropOffLocation} or ${officerPossession} can be printed`,
                },
                errorModalTitle: 'Remove item',
            },
            ManageItemsSidePanel: {
                addEditPropertyTitle: 'Add/Edit Property',
                addEditVehicleTitle: (plural: boolean) => `Add/Edit Vehicle${plural ? '(s)' : ''}`,
                limitOneVehicleForThisReportType:
                    'No more than one vehicle can be added to this report type.',
                selectAll: 'Select All',
                close: 'Close',
                printLabels: 'Print Label(s)',
                printLabelsDisabledTooltipText: 'Select an item to print',
                runningImportEvent: (custodialPropertySummaryReportDefinitionName: string) =>
                    `Updating ${custodialPropertySummaryReportDefinitionName} Report`,
                importEventErrorMessage: (custodialPropertySummaryReportDefinitionName: string) =>
                    `Failed to update ${custodialPropertySummaryReportDefinitionName} report, please click Close and try again.`,
            },
            ItemSidePanel: {
                createPropertyButton: 'Create Property',
                createPropertyTitle: 'Create Property',
                createVehicleButton: 'Create Vehicle',
                createVehicleTitle: 'Create Vehicle',
                duplicatePropertyTitle: 'Duplicate Property',
                duplicateVehicleTitle: 'Duplicate Vehicle',
                editPropertyTitle: 'Edit Property',
                editVehicleTitle: 'Edit Vehicle',
                saveChangesButton: 'Save Changes',
                recoveredLocation: 'Recovered Location',
                deleteItemFacilityLinkErrorMessage: 'Failed to clear storage location.',
                saveItemFacilityLinkErrorMessage: 'Failed to save storage location.',
                createNewProfile: 'Create New Profile',
                searchResults: (n: number) => `${n} Search ${pluralize('Result', n)}`,
                atfManufacturerMustBeSelectedErrorMessage: 'ATF Manufacturer must be selected.',
                addFromDexQuery: 'Add from DEx query',
                backToSearchVehicle: 'Back to search vehicle',
            },
            PropertyStatusesFieldset: {
                propertySectionTitle: 'Property Status(es)',
                vehicleSectionTitle: 'Vehicle Status(es)',
            },
            RecentItemsButton: {
                selectNumberOfItems: (numberOfItems: number) =>
                    `(${numberOfItems} ${numberOfItems > 1 ? 'items' : 'item'})`,
                selectProperty: ({
                    renDisplayName,
                    reportingEventNumber,
                }: {
                    renDisplayName: string;
                    reportingEventNumber: string;
                }) =>
                    `Select property
                     from other reports in ${renDisplayName} ${reportingEventNumber}`,
                selectVehicle: ({
                    renDisplayName,
                    reportingEventNumber,
                }: {
                    renDisplayName: string;
                    reportingEventNumber: string;
                }) =>
                    `Select vehicle(s)
                     from other reports in ${renDisplayName} ${reportingEventNumber}`,
            },
            RecentItemsSidePanel: {
                addPropertyToReport: (numberOfItems: number) =>
                    `Add Property (${numberOfItems}) to Report`,
                addVehiclesToReport: (numberOfItems: number) =>
                    `Add Vehicles (${numberOfItems}) to Report`,
                addVehicleToReport: 'Add Vehicle to Report',
                otherPropertyInREN: ({
                    renDisplayName,
                    reportingEventNumber,
                }: {
                    renDisplayName: string;
                    reportingEventNumber: string;
                }) => `Other Property in ${renDisplayName} ${reportingEventNumber}`,
                otherVehiclesInREN: ({
                    renDisplayName,
                    reportingEventNumber,
                }: {
                    renDisplayName: string;
                    reportingEventNumber: string;
                }) => `Other Vehicles in ${renDisplayName} ${reportingEventNumber}`,
                selectAll: 'Select All',
            },
            CustodyStatusFieldset: {
                missingManagePermissionTooltip:
                    "You do not have permissions to manage this property's current location",
                itemInNonTempLocationTooltipText:
                    'Drop-off location cannot be edited unless the item is checked into a temporary location, the chain of custody should be updated instead',
            },
            RecoveryInfoFieldset: {
                assignToMe: 'Myself',
            },
            ItemSectionsInOffense: {
                propertySectionTitle: 'Property',
                vehicleSectionTitle: 'Vehicle(s)',
                linkPropertyButton: 'Link Property',
                linkVehicleButton: 'Link Vehicle(s)',
            },
            AttachmentsSidePanel: {
                title: (attachmentCount: number) => `Attachments (${attachmentCount})`,
                addAttachmentButtonText: 'Select files to upload',
                uploadDetails: 'Uploaded just now by you',
                description: 'Description',
                save: 'Save',
                cancel: 'Close', // PLAT-2027
                viewMoreText: (viewMoreCount: number) => `View ${viewMoreCount} More Attachments`,
                profilePhoto: 'Profile photo',
                additionalAttachments: 'Additional attachments',
                uploadErrorMessage: 'An error has occurred',
            },
            InlineAttachmentsUploader: {
                addAttachments: 'Attachment',
                addEditAttachments: 'Add/Edit Attachments',
            },
            AttachmentDeletionModal: {
                title: 'Confirm Remove Attachment',
                message: 'Are you sure you wish to remove this attachment?',
                errorMessage: 'Could not remove attachment',
            },
            DefendantSection: {
                mugshot: 'Mugshot',
            },
            WarrantSection: {
                noWarrants: (warrant: string) => `No ${pluralize(warrant)}`,
                checks: 'Checks',
            },
            SealedReportCard: {
                body: 'Details of this sealed report cannot be accessed unless the report is unsealed.',
            },
            EventInfoCard: {
                cadEventInfoSectionTitle: 'CAD Event Info',
                caseAssignmentStatusSectionTitle: (caseDisplayName: string) =>
                    `${caseDisplayName} Assignment Status`,
                notificationsSectionTitle: 'Notifications',
                reportingInfoSectionTitle: 'Reporting Info',
                suspectSectionTitle: 'Suspect',
                witnessSectionTitle: 'Witness',
                victimSectionTitle: 'Victim',
                otherInvolvedNameSectionTitle: 'Other Involved Name',
                ucrInfoSectionTitle: 'UCR Info',
                ucrClassificationSectionTitle: 'UCR Info',
                ucrClassificationOffenseSectionTitle: (
                    ucrOffenseClassificationDisplayName: string,
                    sequenceNum: number
                ) => `${ucrOffenseClassificationDisplayName} #${sequenceNum}`,
                ucrClassificationPropertySectionTitle: (sequenceNum: number) =>
                    `UCR Property Classification #${sequenceNum}`,
                assistingPersonnelAddText: 'Assisting Personnel',
                notificationAddText: 'Notification',
                responderAddText: 'Responder',
                cadCommentsViewMore: 'Show CAD Notes',
                cadCommentsViewLess: 'Hide CAD Notes',
                respondingOfficerAssignToMe: 'Myself',
                personnelUnitAssignToMyUnit: 'My Unit',
                paramedicNameLabel: 'Name',
                paramedicIdNumberLabel: 'ID#',
                agencyIdHelpText: (offenseDisplayName: string) =>
                    `Primary Agency cannot be updated when an ${offenseDisplayName.toLowerCase()} is charge linked to an arrest.`,
                multipleReportsAdditionalFooterContent: 'Changes apply to all embedded arrests',
            },
            OffenseCard: {
                wasCompleted: {
                    trueDisplay: 'Completed',
                    falseDisplay: 'Attempted',
                },
                arsonBuildingInhabited: {
                    trueDisplay: 'Inhabited',
                    falseDisplay: 'Uninhabited, Abandoned, or not Normally in Use',
                },
                offenseDateUnknown: (offenseDisplayName: string) =>
                    `(${offenseDisplayName} date unknown)`,
                offenseLocationSectionTitle: (offenseDisplayName: string) =>
                    `${offenseDisplayName} Location`,
                offenseOtherInvolvedNamesSectionTitle: 'Other Involved Names',
                offenseOtherInvolvedNamesSectionAddButton: 'Name',
                offenseWitnessSectionTitle: 'Witness',
                offenseLinkedArrest: 'Linked Arrest(s)',
                offenseLinkedWarrant: 'Linked Warrant(s)',
                duplicateOffenseSuccessMessage: (offenseDisplayName: string) =>
                    `This is a duplicate ${offenseDisplayName} Card. Enter a new ${offenseDisplayName} Code.`,
                duplicateOffenseErrorMessage: (offenseDisplayName: string) =>
                    `${offenseDisplayName} card duplication failed.`,
                saveGenericError: 'An error occurred, please try again.',
                offenseIsAssociatedWithAnArrest: (offenseDisplayName: string) =>
                    `${offenseDisplayName} is associated with an arrest.`,
                offenseInvolvedChildren: {
                    addChild: 'Child',
                },
                ukDrugEntryRequired: 'A drug-type property entry is required for this offence',
                ukPropertyEntryRequired: 'A property entry is required for this offence',
                offenseCodeChangeWorkflow: {
                    errorMessage: 'You do not have permissions to modify this field',
                },
                moKeywords: 'MO Keywords',
            },
            AddArrestReportButton: {
                addArrestReport: (arrestReportDefinitionName: string) =>
                    `Add ${arrestReportDefinitionName} Report`,
                singleArrestReportMessage1: (arrestReportDefinitionName: string) =>
                    `An ${arrestReportDefinitionName.toLowerCase()} report for`,
                singleArrestReportMessage2: 'has already been started for this incident:',
                addReport: 'Add Report',
                multipleArrestReports: (arrestReportDefinitionName: string) =>
                    `Multiple ${arrestReportDefinitionName} Reports`,
                multipleArrestReportsMessage1: ({
                    reportCount,
                    arrestReportDefinitionName,
                }: {
                    reportCount: number;
                    arrestReportDefinitionName: string;
                }) =>
                    `There are currently ${reportCount} ${arrestReportDefinitionName.toLowerCase()} reports in draft for`,
                multipleArrestReportsMessage2: (arrestReportDefinitionName: string) =>
                    `for this incident. There can only be 1 ${arrestReportDefinitionName.toLowerCase()} per person in a single incident. Please remove one of the reports.`,
                error: 'Error',
                close: 'Close',
                searchErrorMessage:
                    'Searching for this suspect failed with the following error. Please try again or link a new arrest report.',
                noMasterPersonIdErrorMessage:
                    'This suspect is missing data to be added to an arrest report. Please link a new arrest report instead.',
            },
            IncidentCard: {
                incidentOffenseCodeLabel: 'Incident Type',
                incidentCardTitlePrefix: 'Incident',
                incidentLocationSectionTitle: 'Incident Location',
                incidentOtherInvolvedNamesSectionTitle: 'Other Involved Names',
                incidentOtherInvolvedNamesSectionAddButton: 'Name',
                incidentSubjectSectionTitle: 'Subject',
                incidentWitnessSectionTitle: 'Witness',
                saveGenericError: 'Failed to save, please try again',
            },
            StopCard: {
                stopIncidentSectionTitle: 'Incident',
                stopOfficerSectionTitle: 'Officer',
            },
            StubOffenseIncidentCard: {
                title: (offenseDisplayName: string) => `${offenseDisplayName}/Incident`,
                anchor: (index: number) => `stub-offense-incident-card-${index}`,
                incident: 'Incident',
                offenseCodeSelectLabel: (offenseDisplayName: string) =>
                    `Select an ${offenseDisplayName} Code or Incident Type`,
            },
            StatuteCodeSetRadio: {
                localOffense: 'Local',
                federalOffense: 'Federal',
                incident: 'Incident',
            },
            SupplementInfoCard: {
                respondingOfficerAssignToMe: 'Myself',
                personnelUnitAssignToMyUnit: 'My Unit',
            },
            InvolvedProfilesCard: {
                personSectionTitle: 'Persons',
                personAddButtonText: 'Person',
                orgSectionTitle: (organization: string) => `${pluralize(organization)}`,
                orgAddButtonText: (organization: string) => `${organization}`,
                locationSectionTitle: 'Locations',
            },
            TowVehicleCard: {
                towInfoTitle: 'Tow Information',
                connect: {
                    towedFrom: {
                        label: 'Towed from',
                        addText: 'Location',
                    },
                    locationReportedStolen: {
                        label: 'Location Reported Stolen',
                        addText: 'Location',
                    },
                    outsideRecoveryLocation: {
                        label: 'Recovery Location',
                    },
                },
            },
            TowVehicleReleaseCard: {
                releaseAuthorizedByAssignToMe: 'Myself',
            },
            TrafficCrashCard: {
                subjectsSectionTitle: 'Subjects',
                addSubjectButtonText: 'Subject',
            },
            CrashEventInfoCard: {
                dayOfWeek: 'Day of Week',
            },
            FieldContactCard: {
                subjectSectionHelpText: (organization: string) =>
                    `All persons or ${pluralize(
                        organization
                    )?.toLowerCase()} contacted during this event`,
            },
            TrafficCrashLocationCard: {
                missingLocationText: 'Please add a Traffic Crash Location',
            },
            CommunityInfoCard: {
                subjectSectionHelpText: (organization: string) =>
                    `All persons or ${pluralize(
                        organization
                    )?.toLowerCase()} contacted during this event`,
            },
            StopCardSubjectSection: {
                searchType: 'Search Type',
            },
            MissingPersonsCard: {
                title: 'Missing Persons',
                missingPersonAddButtonText: 'Person',
                witnessAddButtonText: 'Witness',
                lastKnownContactAddButtonText: 'Last Known Contact',
            },
            SummaryNarrativeCard: {
                title: 'Summary Narrative',
            },
            TowVehicleForm: {
                sections: {
                    vehicle: 'Vehicle',
                    towInformation: 'Tow Information',
                    stolen: 'Stolen',
                    other: 'Other',
                },
            },
            TowVehicleCardSummary: {
                sections: {
                    vehicle: 'Vehicle',
                    towInformation: 'Tow Information',
                    stolen: 'Stolen',
                    other: 'Other',
                },
                connect: {
                    towedFrom: {
                        label: 'Towed from',
                        addText: 'Location',
                    },
                    locationReportedStolen: {
                        label: 'Location Reported Stolen',
                        addText: 'Location',
                    },
                    outsideRecoveryLocation: {
                        label: 'Recovery Location',
                    },
                },
            },
            UseOfForceCard: {
                title: 'Use of Force',
            },
            UseOfForceSubjectCard: {
                subject: 'Subject',
                deleteUseOfForceSubjectModal: {
                    confirmUseOfForceSubjectRemoval: 'Confirm Use of Force Subject Removal',
                    confirmationText: `Are you sure you'd like to remove this use of force subject? All information entered on this card will be lost.`,
                    confirm: 'Confirm',
                },
                addDeEscalationType: 'Add Other De-escalation Type',
            },
            ImpoundCard: {
                ncicTitle: 'NCIC Information',
                ncicSectionTitle: 'NCIC Information',
            },
            ReportStatusCommentsCard: {
                core: {
                    addLabelsLink: 'Click here',
                    addLabelsMessage: ' to add or edit labels.',
                    reassignLink: 'Click here',
                    reassignMessage: (reportOwnerDisplayValue: string) =>
                        ` to change the report ${reportOwnerDisplayValue}.`,
                    lastExportedDateLabel: 'Last Exported Date:',
                    approve: 'Approve',
                    forceApprove: 'Force-Approve',
                    reject: 'Reject',
                    submit: 'Submit',
                    bulkSubmit: (count: number) => `Submit ${count} Reports`,
                    title: 'Report Status & Comments',
                    validate: 'Validate Report',
                    bulkValidate: (count: number) => `Validate ${count} Reports`,
                    validateOnlyOriginalReport: (reportDefinitionName: string) =>
                        `Validate ${reportDefinitionName} Report Only`,
                    validateOnlyOriginalReportTooltip: (embeddedReportCount: number) =>
                        `This will separate the ${
                            embeddedReportCount > 1 ? embeddedReportCount : ''
                        } embedded ${pluralize('report', embeddedReportCount)}`,
                    ucrApprove: 'UCR Approve',
                    ucrReject: 'UCR Reject',
                    secondaryReject: 'Secondary Reject',
                    you: 'you',
                    tooltips: {
                        missingAbilityToApprove: 'You do not have the ability to Secondary Review',
                        canSecondaryApprove:
                            'Report must be validated before it can be Secondary Approved',
                    },
                },
                approved: {
                    approvedTitle: 'Report Approved',
                    ucrApprovedTitle: 'Report Approved & UCR Approved',
                    approvedText: (user: string) => `Report Approved by ${user} on `,
                    ucrApprovedText: (user: string) => `Report UCR Approved by ${user} on `,
                },
                rejected: {
                    rejectedText: (user: string) => `Report Rejected by ${user} on `,
                    enhancedRejectedText: (
                        user: string,
                        date: string | null,
                        rejectedField: string
                    ) => `Report ${rejectedField} by ${user}${date ? ` on ${date}` : ''}`,
                    ucrRejectedText: (user: string) => `Report UCR Rejected by ${user} on `,
                },
                draft: {
                    submitTitle: 'Submit Report',
                    draftTitle: 'Report in Draft',
                    warning:
                        'By providing your password and submitting this report you agree that the above information is true to the best of your knowledge.',
                    userSelectLabel: 'Notify Users',
                    userSelectHelpText: 'Send a notification to the selected individuals',
                    roleSelectLabel: 'Notify Roles',
                    roleSelectHelpText:
                        'Send a notification to all individuals in the selected roles',
                    passwordLabel: 'Password',
                    quickCrashHelpText:
                        'To submit QuickCrash reports, please use the “Edit Report” button located at the top of this page and go to the Submit tab.',
                    returnToDraft: (user: string) => `Report Returned to Draft by ${user} on `,
                    returnToDraftEnhancements: (
                        user: string,
                        date: string | null,
                        draftField: string
                    ) => `Report Returned to ${draftField} by ${user}${date ? ` on ${date}` : ''}`,
                    draftError: (user: string, reportOwnerDisplayValue: string) =>
                        `${user} is the ${reportOwnerDisplayValue} of the report. `,
                },
                submitted: {
                    title: 'Report Submitted & Pending Approval',
                    by: (user: string, submittedDate: string | null) =>
                        `Submitted by ${user}${submittedDate ? ` on ${submittedDate}` : ''}`,
                },
                custodial: {
                    submittedTitle: 'Report Submitted',
                },
                completed: {
                    completedUponSubmission: (user: string, date: string) =>
                        `Submitted by ${user} on ${date}`,
                    completedBySupervisor: (user: string, date: string) =>
                        `Supervisor approved by ${user} on ${date}`,
                    completedBySecondary: (
                        secondaryUser: string,
                        secondaryApprovalDate: string,
                        supervisorUser: string,
                        supervisorApprovalDate: string
                    ) =>
                        `Supervisor approved by ${supervisorUser} on ${supervisorApprovalDate} and secondary approved by ${secondaryUser} on ${secondaryApprovalDate}`,
                },
                pendingSecondaryReview: {
                    pendingSecondaryReviewText: (user: string, date: string | null) =>
                        `Supervisor approved by ${user}${date ? ` on ${date}` : ''}`,
                },
                submissions: {
                    errors: {
                        generic: {
                            title: 'Error Submitting the Report',
                        },
                        conflict: {
                            title: 'Please fix the following submission errors',
                        },
                        returnToDraft: {
                            title: 'Error Returning the Report to Draft',
                            message:
                                'There was an error while returning the report to draft. Please try again.',
                        },
                        submit: {
                            title: 'Error Submitting the Report',
                            message:
                                'There was an error while submitting the report. Please try again.',
                        },
                        review: {
                            title: 'Error Reviewing Report',
                            message: 'There was an error reviewing the report. Please Try again.',
                        },
                        secondaryReview: {
                            title: 'Error with Secondary Review',
                        },
                        edit: {
                            title: 'Error Editing Report',
                        },
                        modal: {
                            title: 'Please fix the following submission errors',
                            cancelText: 'OK',
                        },
                        submission: {
                            title: 'Submission Errors',
                        },
                        package: {
                            title: 'Error Packaging Report',
                        },
                        secondaryValidation: {
                            offenseValidationFailureTitle: 'Officer must fix validation errors',
                        },
                    },
                },
                resubmissions: {
                    addResubmission: 'Add resubmission',
                    removeResubmission: 'Remove Resubmission',
                    addButton: 'Add',
                    removeButton: 'Remove',
                    title: 'NIBRS Resubmission',
                    addResubmissionBodyText:
                        'This report will be added to resubmissions in the NIBRS Compliance Dashboard',
                    removeResubmissionBodyText:
                        'This report will be removed from resubmission in the NIBRS Compliance Dashboard',
                    errorModalMessage:
                        'Error occurs while processing resubmission workflow request',
                },
            },
            ReportCommentsAndHistory: {
                header: 'Comments & History',
                sameUserText: '(you)',
            },
            ReportCommentForm: {
                submit: 'Comment',
            },
            InlineCommentsResolveButton: {
                label: 'Resolve',
            },
            ReportSupplementInfoMessage: {
                offenseReportSnapshotLinkText: (offenseReportDefinitionName: string) =>
                    `${offenseReportDefinitionName} (Snapshot)`,
                viewCombinedOffenseRecordTitle: (offenseReportDefinitionName: string) =>
                    `View the Combined ${offenseReportDefinitionName} Record:`,
                viewCombinedOffenseRecordLinkText: (offenseReportShortTitle: string) =>
                    `${offenseReportShortTitle} Report`,
                offenseReportModifiedByTitle: (offenseReportDefinitionName: string) =>
                    `This Combined ${offenseReportDefinitionName} Record is modified by the following report(s):`,
                offenseModifyingSupplementReportModifiedByTitle: (omsReportShortTitle: string) =>
                    `This ${omsReportShortTitle} is modified by the following report(s):`,
                offenseModifyingSupplementReportModifiesTitle: (omsReportShortTitle: string) =>
                    `This ${omsReportShortTitle} modifies the following report(s):`,
            },
            ItemPopoverForm: {
                sectionTitles: {
                    itemType: 'Item Type',
                    policeCustodyPropertyStatuses: (custody: string) =>
                        `In Police ${custody} Status(es)`,
                    nonPoliceCustodyPropertyStatuses: 'Item Status(es)',
                },
                itemTypeOptions: [
                    // not all item types are included
                    {
                        value: globalAttributes.itemType.firearm,
                        display: 'Firearm',
                    },
                    {
                        value: globalAttributes.itemType.drugs,
                        display: 'Drugs',
                    },
                    {
                        value: globalAttributes.itemType.vehicle,
                        display: 'Vehicle',
                    },
                    {
                        value: globalAttributes.itemType.item,
                        display: 'Other Item',
                    },
                ],
            },
            LocationWithEditableDescription: {
                labels: {
                    locationCategory: 'Location Category:',
                    propertyType: 'Public / Private:',
                    description: 'Additional Description',
                    remove: 'Remove',
                    addLocation: 'Address',
                },
            },
            AssociatedNamesFieldset: {
                addItem: (organization: string) => `Associated People & ${pluralize(organization)}`,
                addAssociatedPerson: 'Associated Person',
            },
            ReportHeader: {
                author: 'Author',
                authors: 'Authors',
                reportSnapshotBannerText: 'This is a report snapshot.',
                viewOriginalReport: 'Click here to view the current report.',
                sealedReportBannerText: 'This report has been sealed per court order mandate.',
                partiallySealedReportBannerText:
                    'Person(s) have been sealed per court order mandate.',
                vacatedChargesBannerText:
                    'Charge(s) have been vacated (set aside) per court order mandate.',
                sealedChargesBannerText: 'Charge(s) have been sealed per court order mandate.',
                detentionDispositionBannerText:
                    'This report has been updated to a Detention per the courts',
            },
            ReportExports: {
                defaultTitle: 'Combined Reports',
                export: 'Export',
                currentReport: 'Current Report',
            },
            ReportFormExportsOptions: {
                exportPreset: 'Export Preset',
            },
            SortedReportExportsCheckboxTree: {
                owner: (ownerName: string, ownerDisplayValue: string) =>
                    ownerName ? `${ownerDisplayValue}: ${ownerName}` : '',
                createdDate: (createdDate: string) =>
                    createdDate ? `Created Date: ${createdDate}` : '',
            },
            StatusHistories: {
                completed: 'Approved',
                staffReviewRescinded: 'Returned to Approved',
                staffReviewRejected: 'UCR Rejected',
                staffReviewApproved: 'UCR Approved',
                secondaryReviewApproved: 'Secondary Approved',
                supervisorReviewApproved: 'Supervisor Approved',
                returnedToDraft: 'Returned to Draft',
            },
            ReportHeaderHamburgerMenu: {
                addCharges: 'Add/Edit Charges',
                createNewCase: (caseDisplayName: string) => `Create New ${caseDisplayName}`,
                addToCase: (caseDisplayName: string) => `Add to ${caseDisplayName}(s)`,
                deleteReport: 'Delete Report',
                sendToClips: 'Send to CLIPS',
                sendArrestReportToBooking: 'Send to Booking',
                editSecurityClassification: (securityClassificationDisplayName: string) =>
                    `Edit ${securityClassificationDisplayName}`,
                renChange: (renDisplayName: string) => `Change ${renDisplayName}`,
                removeRen: (renDisplayName: string) => `Remove ${renDisplayName}`,
                recordNumberChange: (recordNumberDisplayName: string) =>
                    `Change ${recordNumberDisplayName}`,
                reportCaseStatus: 'Edit Report Status',
                towVehicleCheckIn: 'Check In Information',
                towVehicleRelease: 'Release Information',
                ucrClassification: 'Edit UCR Classification',
                changeOwner: (reportOwnerDisplayValue: string) =>
                    `Change ${reportOwnerDisplayValue}`,
                sealReport: 'Seal',
                unsealReport: 'Unseal',
                vacateCharges: 'Vacate Charges',
                associatedRecords: 'Edit Associated Records',
                editAttachments: 'Edit Attachments',
                criminalStreetGangTracking: 'Edit Criminal Street Gang Tracking',
                returnToDraft: 'Return to Draft',
                createTask: 'Create Task',
                taskCreatedSuccessfullyConfirmationMessage: 'Task has been successfully created',
                createNewEFile: (eFileDisplayName: string) => `Create New ${eFileDisplayName}`,
                createSubmission: 'Create Submission',
                detentionDisposition: 'Edit Detention Disposition',
            },
            ReportChangeOwnerModal: {
                title: (reportOwnerDisplayValue: string) => `Change ${reportOwnerDisplayValue}`,
                okText: 'Confirm',
                currentOwner: (reportOwnerDisplayValue: string) =>
                    `Current Report ${reportOwnerDisplayValue}:`,
                newOwnerLabel: (reportOwnerDisplayValue: string) =>
                    `New Report ${reportOwnerDisplayValue}:`,
            },
            OffenseIncidentDeletionModal: {
                title: (isIncident: boolean, offenseDisplayName: string) =>
                    `Delete ${isIncident ? 'Incident' : offenseDisplayName}`,
                okText: 'Confirm',
                message: (isIncident: boolean, offenseDisplayName: string) =>
                    `Are you sure you'd like to remove this ${
                        isIncident ? 'incident' : offenseDisplayName.toLowerCase()
                    }? All information entered on this card will be lost.`,
                errorMessage: (isIncident: boolean, offenseDisplayName: string) =>
                    `Could not delete ${
                        isIncident ? 'incident' : offenseDisplayName.toLowerCase()
                    }.`,
            },
            OffenseCodeChangeReasonModal: {
                title: 'Reason for Code Change',
                okText: 'Confirm',
                message: (offenseDisplayName: string) =>
                    `Please indicate why the ${offenseDisplayName.toLowerCase()} code/classification was changed`,
                placeholder: 'Add a comment',
            },
            ReportPrefillModal: {
                message: {
                    initial: (renDisplayName?: string, cardName?: string) =>
                        `Reports already exist with this ${renDisplayName}. Update ${cardName} card with this data?`,
                    loading: 'Pulling existing data...',
                    error: 'A server error occurred.  The card could not be updated.',
                },
                title: {
                    initial: 'Pull Existing Data',
                    error: 'Data Error',
                },
                buttonText: {
                    okButton: 'AUTO-FILL',
                    cancelButton: {
                        initial: 'NO THANKS',
                        error: 'CONTINUE',
                    },
                },
            },
            ReportSendArrestToBookingModal: {
                title: 'Send to Booking',
                okText: 'Confirm',
                errorMessage: 'Failed to send to Booking',
                message:
                    'Send to Booking will send this arrest report to an external booking system. Repeating this action multiple times will generate duplicates in the booking system.',
            },
            ReportRejectionModal: {
                title: 'Reject Report',
                okText: 'Confirm',
                comments: 'Comments',
                placeholder: 'Add a comment',
                warning: (rejectedLabel: string) =>
                    `Rejecting this report will send it to the ${rejectedLabel} tab and notify its owner.`,
            },
            ReportSubmissionModal: {
                title: 'Submit Report',
                areYouSure: 'Are you sure you want to submit this report?',
            },
            ReturnReportToDraftModal: {
                title: 'Return Report to Draft',
                pendingSupervisorTitle: 'Edit Supervisor Approved Report',
                defaultNote: (draftDisplayFieldName: string) => {
                    return `Editing this report will return it to ${draftDisplayFieldName} and will require resubmitting the report.`;
                },
                pendingSupervisorNote: (draftDisplayFieldName: string) => {
                    return `Editing this Supervisor Approved report will return the report to ${draftDisplayFieldName}.`;
                },
                note: 'Editing this report will return it to Draft and will require resubmitting the report.',
            },
            RescindStaffReviewModal: {
                title: (completedDisplayFieldName: string) => {
                    return `Edit ${completedDisplayFieldName} Report`;
                },
                confirmationText: (
                    pendingSecondaryReviewDisplayFieldName: string,
                    completedDisplayFieldName: string
                ) => {
                    return `Editing this ${completedDisplayFieldName} report will return the report to ${pendingSecondaryReviewDisplayFieldName} status. The report will need to be approved again after changes are made.`;
                },
                cancel: 'Cancel',
                confirm: 'Confirm',
            },
            SubmissionCommentsModal: {
                title: 'Supplementary Comments',
                label: 'Explain Report Changes',
            },
            PropertyStatusSummary: {
                labels: {
                    statusDate: 'Status Date',
                    recoveredLocation: 'Recovered Location',
                    quantity: 'Quantity',
                    reasonForPoliceCustody: (custody: string) => `Reason For Police ${custody}`,
                    recoveringOfficer: 'Recovering Officer',
                    dropOffStorageLocation: 'Drop-Off Location',
                    declaredValue: 'Declared Value',
                    wasContainerOpen: 'Open Container',
                },
                inPoliceCustody: (custody: string) => `In Police ${custody}`,
            },
            DuplicateNameResultsSidePanel: {
                title: 'Potential Duplicates Found',
                back: 'Back',
                newPerson: 'New Profile',
                similarExistingProfiles: 'Similar Existing Profiles',
                saveNew: 'Save New',
                compareToNew: 'Compare To New',
                helpText:
                    'We found existing profiles similar to the person you entered. Review the similarities and confirm this person does not exist in the system before creating a new profile.',
            },
            OwnerClaimantNItems: {
                labels: {
                    edit: 'Edit',
                    remove: 'Remove',
                    person: 'Person',
                    organization: (organization: string) => `${organization}`,
                    cancel: 'Cancel',
                },
            },
            History: {
                categoryTypeSelectLabel: 'Filter Timeline',
                noEvents: 'No events to display',
                categoryTypes: {
                    allEvents: 'All Events',
                    reportContent: 'Report Content',
                    workflow: 'Workflow',
                    folderChanges: 'Folder Changes',
                    anpr: 'ANPR',
                    registrationChange: 'Registration Change',
                    reportActivity: 'Report Activity',
                    otherActivity: 'Other Activity',
                },
                viewSnapshot: 'View Snapshot',
                privacy: {
                    sealing: ' has performed a sealing action per court order mandate',
                    unsealing: ' has performed an unsealing action per court order mandate',
                    vacate: ' has vacated charge(s) per court order mandate',
                    unvacate: ' has unvacated charge(s)',
                },
                noHistoryAvailable: 'No History Available',
                afterFirstSubmission:
                    'Only changes made after first submission will appear in this view',
                afterFirstApproval:
                    'Only changes made after first approval will appear in this view',
                cannotBeDisplayed: 'History cannot be displayed for this report definition',
                displayLinkAsText: (isPrimaryTypeCaseReportLink: boolean) =>
                    isPrimaryTypeCaseReportLink ? ` ` : ` as a(n) `,
            },
            VehicleInlineSearch: {
                label: 'Vehicle Search',
                addButtonText: 'Create New Vehicle Profile',
                placeholder: 'Search for vehicle by VIN or Tag/Registration #',
            },
            FirearmFieldset: {
                addButtonText: (serialNumber: string) => `Use Serial # ${serialNumber}`,
            },
            HydratedItemForm: {
                itemDuplicationRequiresUniqueIdentifiers:
                    'You are creating a duplicate item. Unique identifiers such as Serial Number will need to be re-entered.',
                attachments: 'Attachments',
                additionalAttachments: 'Additional attachments',
            },
            header: {
                DeleteReportModal: {
                    title: 'Delete Report',
                    okText: 'Confirm',
                    unapprovedMessage:
                        'Delete Report is a permanent action and cannot be undone. All of your data will be lost.',
                    approvedMessage: 'The action you are taking is permanent and cannot be undone.',
                    approvedSubMessage:
                        'The report will not be recoverable and all data will be lost.',
                    errorMessage: 'Could not delete report',
                    unableToDeleteDueToLinkedCharges: ({
                        offenseDisplayName,
                        offenseNames,
                        chargeCount,
                    }: {
                        offenseDisplayName: string;
                        offenseNames: string[];
                        chargeCount: number;
                    }) => {
                        const offenses = pluralize(
                            offenseDisplayName.toLowerCase(),
                            offenseNames.length
                        );
                        const charges = pluralize('charge', chargeCount);
                        return `This report cannot be deleted because the following ${offenses} ${
                            offenseNames.length > 1 ? 'are' : 'is'
                        } linked to ${chargeCount} ${charges}: ${offenseNames.join(
                            ', '
                        )}. Please edit the ${charges} to remove the ${offenses} before deleting this report.`;
                    },
                    deletingOffenseReportWillDeleteAllOMS: (
                        offenseReportTypeDisplayName?: string,
                        omsReportTypeDisplayName?: string
                    ) => {
                        return `Deleting this approved ${offenseReportTypeDisplayName} will delete any associated ${omsReportTypeDisplayName} reports.`;
                    },
                },
            },
        },
        ArrestUcrClassificationSidePanel: {
            title: 'UCR Classification',
            ArrestUcrClassificationForm: {
                ucrLabel: (ucr: string, offenseDisplayName: string) =>
                    `UCR Classification of Top ${offenseDisplayName}: ${ucr}`,
                arrestUcrClassification: 'Arrest UCR Classification',
            },
        },
        UcrClassificationSidePanel: {
            title: 'UCR Classification',
            loadUcrClassificationsError: 'Failed to load UCR classifications.',
            loadUcrEventError: 'Failed to load data for UCR event classification.',
            loadUcrOffenseError: (offenseDisplayName: string) =>
                `Failed to load data for UCR ${offenseDisplayName.toLowerCase()} classification.`,
            loadUcrPropertyError: 'Failed to load data for UCR property.',
            PrefillButton: {
                loadEventData: 'Load Data',
            },
            UcrOffenseFieldset: {
                loadOffenseData: 'Load Data',
                suggestedUcr: (codeDisplay: string) => `Suggested: ${codeDisplay}`,
                fieldsetHeader: (sequenceNumber: number) => `Classification #${sequenceNumber}`,
                noSuggestedUcrCode: 'No Suggested Code',
                arsonBuildingInhabited: {
                    true: 'Inhabited',
                    false: 'Uninhabited, Abandoned, or not Normally in Use',
                },
            },
            UcrEventFieldset: {
                title: 'Event Statistics',
            },
            UcrPropertyFieldset: {
                title: 'Items & Property',
                fromReport: 'From Current Report',
                newItem: 'New Item',
                loadItemData: 'Load Data',
                propertyStatusIdLabel: 'Item',
                itemNotesHelpText: 'Notes not exported for UCR reporting purposes',
                fieldsetHeader: (index: number) => `Item #${index}`,
            },
        },
        CreateReportModal: {
            title: 'Create New Report',
            reportDefinitionsHeader: 'Report Type',
            eventInfoHeader: 'Event Info',
            report: 'report',
            createReportButton: 'Create Report',
            filterPlaceholder: 'Filter Report Types',
            reportDefinitionsTitle: 'Report Types',
            goButtonText: 'Search',
            optionalLabel: 'optional',
            relatedTitle(renDisplayName: string, reportingEventNumber: string) {
                return `${renDisplayName} ${reportingEventNumber} reports`;
            },
            cadRelatedTitle: 'CAD Event Info',
            noReportsUnderRen(renDisplayName: string) {
                return `No reports exist for this ${renDisplayName}`;
            },
            eventHasReportForReportType: 'A report of this type already exists for this event',
            noCadEvents: 'No CAD events exist',
            noFieldsFailure: 'Field required for search',
            eventInfoFailure: 'Search Failed',
            noCadEventsFailure: 'No CAD Events for report type under CAD Agency #',
            createReportFailure: 'Failed to create report',
            fetchReportDefinitionsFailure: 'Failed to retrieve report types',
            clearButton: 'Clear',
            autogenerateRenText(renDisplayName: string) {
                return `Generate ${renDisplayName}`;
            },
            renTitle: (renDisplayName: string, ren: string) => `${renDisplayName} ${ren}`,
            noReportsExist: 'No reports exist',
            reportTypeHeader: 'Select a Report Type',
            eventInformationHeader: 'Event Information',
        },
        CreateReportModalResults: {
            relatedTitle(renDisplayName: string, reportingEventNumber: string) {
                return reportingEventNumber
                    ? `${renDisplayName} ${reportingEventNumber}: Reports`
                    : 'Reports';
            },
            cadRelatedTitle(renDisplayName: string, reportingEventNumber: string) {
                return reportingEventNumber
                    ? `${renDisplayName} ${reportingEventNumber}: CAD events`
                    : 'CAD events';
            },
            filterPlaceholder: 'Filter Report Types',
            reportDefinitionsTitle: 'Report Types',
        },
        custodialPropertySummary: {
            DispositionActionSidePanel: {
                holdItemTitle: 'Hold Item(s)',
                primaryApprovalForDispositionTitle: 'Approve Item(s) for Disposition',
                primaryApprovalForReleaseTitle: 'Approve Item(s) for Release to Owner',
                requestDispositionTitle: 'Request Disposition',
                secondaryApprovalTitle: 'Secondary Approve Item(s)',
                secondaryRejectionTitle: 'Secondary Reject Item(s)',
                resetRetentionPolicyTitle: 'Reset Retention Policy',
                confirmButton: 'Submit',
                holdDuration: 'Hold duration',
            },
            CustodialPropertyActionBar: {
                addStaffRemarks: 'Add Remarks',
                printLabels: 'Print Label(s)',
                disabledPrintLabelsTooltip: (custody: string) =>
                    `Items still "In Police ${custody}" cannot generate labels`,
                exportDocuments: 'Export Document',
                export: 'Export',
                addToQueue: 'Add to Basket',
                disabledAddToQueueTooltip: (custody: string) =>
                    `Items still "In Police ${custody}" cannot be added to the Item Basket`,
                noManagePermissionTooltip:
                    'You do not have permissions to manage this storage location',
                noRequestDisposition:
                    'You do not have permissions to request disposition on this item',
                noHoldEvidenceItem: 'You do not have permissions to hold this item',
                noApproveRelease: 'You do not have permissions to approve the release of this item',
                noApproveRequestDisposition:
                    'You do not have permissions to approve the request disposition on this item',
                noRejectRequestDisposition:
                    'You do not have permissions to reject the request disposition on this item',
                noResetRetentionPolicy:
                    'You do not have permissions to reset retention policy on this item',
                updateDisposition: 'Update Disposition',
                requestDisposition: 'Request Disposition',
                disabledRequestDispositionInPoliceCustodyTooltip: (custody: string) =>
                    `Items still "In Police ${custody}" cannot be requested for disposition`,
                disabledRequestDispositionTooltip:
                    'One or more selected items is ineligible for, already approved, or currently awaiting disposition approval',
                primaryApproveRelease: 'Release to Owner',
                primaryApproveDisposition: 'Disposition',
                hold: 'Hold',
                disabledPrimaryDispositionReviewTooltip:
                    'One or more selected items is not "Pending Disposition Review"',
                secondaryApprove: 'Secondary Approve',
                secondaryReject: 'Secondary Reject',
                disabledSecondaryDispositionReviewTooltip:
                    'One or more selected items is not "Pending Secondary Approval"',
                resetRetentionPolicy: 'Reset Retention Policy',
                disabledResetRetentionPolicyTooltip:
                    'One or more selected items has already been dispositioned or is currently awaiting disposition approval',
                splitItem: 'Split Item',
                disabledItemSplitTooManyTooltip:
                    'You have selected too many items. Only one item can be split at a time.',
                disabledItemSplitInvalidTooltip:
                    'Only items checked into a Main Warehouse can be split.',
                more: 'More',
            },
            CustodialPropertyCard: {
                collapseAll: 'Collapse All',
                expandAll: 'Expand All',
                sectionTitles: {
                    withCoC: 'Submitted Property',
                    withoutCoC: 'Saved Property',
                },
                showMore: 'Show More',
                batchRequestFailed: 'Failed to request more evidence items',
            },
            PersonnelCard: {
                labels: {
                    assignedInvestigator: 'Assigned Investigator',
                    assignedUnit: 'Assigned Unit',
                    assignedSupervisors: 'Assigned Supervisor(s)',
                    involvedPersonnel: 'Involved Personnel',
                    responsibleOfficer: 'Primary Reporting Officer',
                },
                save: 'Save', // not tied to report submission
            },
            LinkedReportsCard: {
                labels: {
                    noRelatedReports: 'No Related Reports',
                },
            },
            StaffRemarksCard: {
                labels: {
                    addStaffRemarks: 'Add',
                    noRemarks: 'No Remarks Added',
                },
            },
            DeleteEvidenceItemModal: {
                title: (evidenceModuleName: string) =>
                    `Delete ${prettify(evidenceModuleName)} Item`,
                confirmButton: 'Confirm',
            },
            DeleteEvidenceItemForm: {
                confirmDeleteEvidenceItem: ({
                    reportDefinitionName,
                    evidenceModuleName,
                    custody,
                }: {
                    reportDefinitionName?: string;
                    evidenceModuleName: string;
                    custody: string;
                }) =>
                    `This action will be tracked in the ${
                        reportDefinitionName ? `${reportDefinitionName} ` : ''
                    }report history. Deleting an item will remove it from the ${prettify(
                        evidenceModuleName
                    )} module along with its chain of ${custody?.toLowerCase()}. The item will remain on all linked reports unless removed from those reports directly. This action cannot be undone.`,
            },
            custodialPropertyCardItem: {
                CustodialPropertyCardItemActions: {
                    disabledEditTooltip: (evidenceModuleName: string) =>
                        `Unable to edit items submitted to the ${evidenceModuleName?.toLowerCase()}  module.`,
                    disabledEditDispositionedTooltip:
                        'Unable to edit items that have been dispositioned.',
                    disabledDeleteTooltip: (evidenceModuleName: string) =>
                        `Unable to delete items submitted to the ${evidenceModuleName?.toLowerCase()} module.`,
                },
                CustodialPropertyCardItemLinkedReports: {
                    linkedReports: 'Linked Reports:',
                },
                CustodialPropertyCardItemStaffRemarks: {
                    title: (staffRemarksCount: number) => `${staffRemarksCount} Item Remarks`,
                },
            },
            disposition: {
                DispositionHistory: {
                    title: 'Disposition History',
                    showHistory: 'Show History...',
                    hideHistory: 'Hide History...',
                },
                DispositionHistoryRow: {
                    retentionPeriodIsReset: (user: string) => `Retention Period Reset by ${user}`,
                    retentionPeriodStartForItemEligibleForDisposition: 'Retention Period Started',
                    retentionPeriodStartForItemEligibleForDispositionRetentionPolicyCode: (
                        expiredDateUtc: string,
                        codeNo: string
                    ) => `Expires ${expiredDateUtc}. Retention policy code ${codeNo}`,
                    retentionPeriodStartsForItemIneligibleForDisposition:
                        'Retention Period Started',
                    retentionPeriodCompletedDispositionAutoRequested:
                        'Retention Period Completed & Disposition Auto-Requested',
                    retentionPeriodCompletedDispositionAutoApproved:
                        'Retention Period Completed & Auto-Approved for Disposition',
                    retentionPeriodCompletedReleaseToOwnerAutoApproved:
                        'Retention Period Completed & Auto-Approved for Release to Owner',
                    dispositionRequestedByUser: (user: string) =>
                        `Disposition Requested by ${user}`,
                    dispositionRequestedByUserDispositionAutoApproved: (user: string) =>
                        `Disposition Requested by ${user} & Auto-Approved for Disposition`,
                    dispositionRequestedByUserReleaseToOwnerAutoApproved: (user: string) =>
                        `Disposition Requested by ${user} & Auto-Approved for Release to Owner`,
                    retentionPeriodStartsForItemIneligibleForDispositionRententionPolicyCode: (
                        codeNo: string
                    ) =>
                        `Indefinite retention period because this item is ineligible for disposition. Retention policy code ${codeNo}`,
                    initialApproverHolderItem: (days: number, user: string) =>
                        `Put on Hold for ${days > 1 ? `${days} days` : `${days} day`} by ${user}`,
                    initialApproverApprovesForReleaseNoSecondaryApprovalRequired: (user: string) =>
                        `Approval for Release to Owner by ${user}`,
                    initialApproverApprovesForDispositionNoSecondaryApprovalRequired: (
                        user: string
                    ) => `Approval for Disposition by ${user}`,
                    initialApproverApprovesForReleaseSecondaryApprovalRequired: (user: string) =>
                        `Initial Approval for Release to Owner by ${user}`,
                    initialApproverApprovesForDispositionSecondaryApprovalRequired: (
                        user: string
                    ) => `Initial Approval for Disposition by ${user}`,
                    secondaryApproverRejectsInitialApproverDecisionToRelease: (user: string) =>
                        `Release to Owner Rejected by ${user}`,
                    secondaryApproverRejectsInitialApproverDecisionToDisposition: (user: string) =>
                        `Disposition Rejected by ${user}`,
                    secondaryApproverApprovesInitialApproverDecisionToRelease: (user: string) =>
                        `Secondary Approval for Release to Owner by ${user}`,
                    secondaryApproverApprovesInitialApproverDecisionToDisposition: (user: string) =>
                        `Secondary Approval for Disposition by ${user}`,
                    releaseTimerExpiredDispositionAutoApproved:
                        'Release Timer Expired & Auto-Approved for Disposition',
                    itemIsReleasedCompleted: (user: string) => `Released to Owner by ${user}`,
                    itemIsDispositionCompleted: (user: string) => `Dispositioned by ${user}`,
                    itemIsTransferredToAnotherAgency: (user: string) =>
                        `Transferred to Another Agency by ${user}`,
                },
                DispositionReviewCard: {
                    titles: {
                        pendingPrimaryReview: 'Pending Disposition Review',
                        pendingSecondaryReviewForRelease:
                            'Pending Secondary Approval for Release to Owner',
                        pendingSecondaryReviewForDisposition:
                            'Pending Secondary Approval for Disposition',
                        waitingOnPrimaryReview: 'Waiting on Your Disposition Review',
                        waitingOnSecondaryReviewForDisposition:
                            'Waiting on Your Secondary Approval for Disposition',
                        waitingOnSecondaryReviewForRelease:
                            'Waiting on Your Secondary Approval for Release to Owner',
                    },
                    actions: {
                        primaryApproveRelease: 'Release to Owner',
                        primaryApproveDisposition: 'Disposition',
                        primaryRejectToHold: 'Hold',
                        secondaryApproveRelease: 'Approve',
                        secondaryApproveDisposition: 'Approve',
                        secondaryReject: 'Reject',
                    },
                    tooltips: {
                        evidenceLocationPermissions:
                            'You do not have permissions to manage the storage location of this item',
                    },
                },
                DispositionEventDetails: {
                    assignedInvestigator: 'Assigned Investigator',
                    assignedSupervisors: 'Assigned Supervisor(s)',
                    initialApprovalGranted: (timeAgo: string, date: string) =>
                        `Initial approval granted ${timeAgo} on ${date}`,
                    requestMade: (timeAgo: string, date: string) =>
                        `Request made ${timeAgo} on ${date}`,
                    requestSentToAssignedInvestigator: 'Request sent to Assigned Investigator',
                    requestSentToAssignedSupervisors: 'Request sent to Assigned Supervisor(s)',
                    requestSentToResponsibleOfficer: 'Request sent to Primary Reporting Officer',
                    requestSentToFollowingRoles: (roles: string) =>
                        `Request sent to the following roles: ${roles}`,
                    responsibleOfficer: 'Primary Reporting Officer',
                },
            },
        },
        dashboard: {
            ReportsDashboardContainer: {
                title: 'Reports',
                newReport: 'New Report',
            },
            ReportsDashboardSearchResults: {
                columns: {
                    eventDate: {
                        label: 'Event Date',
                        descendingSort: 'Newest First',
                        ascendingSort: 'Oldest First',
                    },
                    modifiedDate: {
                        label: 'Modified Date',
                        descendingSort: 'Newest First',
                        ascendingSort: 'Oldest First',
                    },
                    creationDate: {
                        label: 'Creation Date',
                        descendingSort: 'Newest First',
                        ascendingSort: 'Oldest First',
                    },
                    ren: {
                        label: (renDisplayName: string) => `${renDisplayName}`,
                        descendingSort: 'Z to A',
                        ascendingSort: 'A to Z',
                    },
                    reviewers: {
                        label: 'Reviewers',
                    },
                    authors: {
                        label: 'Authors',
                    },
                    arrestingOfficer: {
                        label: 'Arresting Officer',
                    },
                    involvedPersons: {
                        label: 'Involved Persons',
                    },
                    offenseLocation: {
                        label: (offenseDisplayName: string) => `${offenseDisplayName} Location`,
                    },
                    reportTakenLocation: {
                        label: 'Report Taken Location',
                    },
                    arrestLocation: {
                        label: 'Arrest Location',
                    },
                    eventLocation: {
                        label: 'Event Location',
                    },
                    agency: {
                        label: 'Agency',
                    },
                    units: {
                        label: (unitDisplayName: string) => `${unitDisplayName}`,
                    },
                    district: {
                        label: (districtDisplayName: string) => `${districtDisplayName}`,
                    },
                    reportType: {
                        label: 'Report Details',
                    },
                    narrative: {
                        label: 'Narrative',
                    },
                    routingLabels: {
                        label: 'Labels',
                        status: 'Status',
                    },
                },
                tabs: {
                    draft: 'Draft',
                    rejected: 'Rejected',
                    pendingApproval: 'Pending Approval',
                    approved: 'Approved',
                    ucrApproved: 'UCR Approved',
                },
                noResults: 'No Reports',
            },
            ReportDetailsFieldset: {
                title: 'Report Details',
                reportTypes: 'Report Type(s)',
                caseStatus: 'Report Status',
                offenseStatus: 'Offense Status',
            },
            DateTimeFieldset: {
                title: 'Date & Time',
                labels: {
                    dateType: 'Date Type',
                    dateTimeRange: 'Date/Time Range',
                    shiftTimeRange: 'Shift Time Range',
                },
            },
            ReportPersonnelFieldset: {
                title: 'Personnel',
                labels: {
                    currentSubmissionOwnerIds: 'Report Owner',
                    authorIds: 'Author(s)',
                    reviewerIds: 'Reviewer(s)',
                    officerIds: 'Involved Officer(s)',
                },
            },
            SearchFooter: {
                saveSearch: 'Save Search',
                unsaveSearch: 'Unsave Search',
                clear: 'Clear Filters',
                advancedSearch: 'Advanced Search',
                helpText: 'Open Advanced Search in a new tab with the current filters applied.',
            },
        },
        RenChangeModal: {
            changeRen: (renDisplayName: string) => `Change ${renDisplayName}`,
            linkToRen: (renDisplayName: string) => `Link to ${renDisplayName}`,
        },
        RenChangeForm: {
            labels: {
                currentRen: (renDisplayName: string) => `Current ${renDisplayName}`,
                validateButton: 'Validate',
                reportsUnderRen: (renDisplayName: string, reportingEventNumber: string) =>
                    `${renDisplayName} ${reportingEventNumber}: reports`,
            },
            validRenNotice: (renDisplayName: string) => `Proposed ${renDisplayName} is valid.`,
            renTooLongError: 'Too long: max 30 characters.',
            renTakenError: (renDisplayName: string) =>
                `Proposed ${renDisplayName} already exists and has a report of this type.`,
            sameRenError: (renDisplayName: string) =>
                `Proposed ${renDisplayName} cannot be the same as current ${renDisplayName}`,
            requiredError: (renDisplayName: string) => `New ${renDisplayName} field is required`,
            changedSinceValidationError: (renDisplayName: string) =>
                `New ${renDisplayName} field has changed since being validated.`,
        },
        RemoveRenModal: {
            title: (renDisplayName: string) => `Remove ${renDisplayName}`,
            message: (renDisplayName: string, ren: string) =>
                `This action will unlink this record from ${renDisplayName} ${ren}. Do you wish to continue?`,
            okText: 'Unlink record',
        },
        RecordNumberChangeModal: {
            title: (recordNumberDisplayName: string) => `Change ${recordNumberDisplayName}`,
        },
        RecordNumberChangeForm: {
            labels: {
                currentRecordNumber: (recordNumberDisplayName: string) =>
                    `Current ${recordNumberDisplayName}`,
                newRecordNumber: (recordNumberDisplayName: string) =>
                    `New ${recordNumberDisplayName}`,
                currentCadEventNumber: (cadEventNumberDisplayName: string) =>
                    `${cadEventNumberDisplayName}`,
                newCadEventNumber: (cadEventNumberDisplayName: string) =>
                    `New ${cadEventNumberDisplayName}`,
                validateButton: 'Validate',
            },
            sameRecordNumberError: (recordNumberDisplayName: string) =>
                `Proposed ${recordNumberDisplayName} cannot be the same as current ${recordNumberDisplayName}`,
            requiredError: (recordNumberDisplayName: string) =>
                `New ${recordNumberDisplayName} field is required`,
            recordNumberTakenError: (recordNumberDisplayName: string) =>
                `Proposed ${recordNumberDisplayName} already exists and has a report of this type.`,
            cadTicketNotFoundError: (recordNumberDisplayName: string) =>
                `CAD ticket with proposed ${recordNumberDisplayName} not found.`,
            validRecordNumberNotice: (recordNumberDisplayName: string) =>
                `Proposed ${recordNumberDisplayName} is valid.`,
            changedSinceValidationError: (recordNumberDisplayName: string) =>
                `New ${recordNumberDisplayName} field has changed since being validated.`,
        },
        DownloadablesCard: {
            downloads: 'Downloads',
        },
        ReportSidebar: {
            title: 'Report Records',
            renTitle: (renDisplayName: string, ren: string) => `${renDisplayName} ${ren} Records`,
            currentlyViewing: 'Currently Viewing',
            labels: 'Labels',
            noLabels: 'No labels',
            edit: 'Edit',
            DetentionSection: {
                title: 'Detention Disposition',
                updatedBy: 'Updated By',
                updatedDate: 'Updated Date',
            },
            CourtOrderSection: {
                title: 'Court Order Information:',
                statuteSealing: 'Statutory Sealing',
                statutePersons: 'Involved Person(s)',
                sealedBy: 'Sealed By',
                vacatedBy: 'Vacated By',
                sealDate: 'Date of Seal',
                vacateDate: 'Date of Vacate',
                attachments: 'Attachments',
            },
            reportInDraft: 'Report in Draft',
            retrievingReportState: 'Retrieving Report State...',
            sortOptions: {
                createdDateUtc: {
                    label: 'Created Date',
                    ascendingSort: 'Oldest First',
                    descendingSort: 'Newest First',
                },
                modifiedDateUtc: {
                    label: 'Modified Date',
                    ascendingSort: 'Oldest First',
                    descendingSort: 'Newest First',
                },
                alphabetical: {
                    label: 'Alphabetical',
                    ascendingSort: 'A to Z',
                    descendingSort: 'Z to A',
                },
                dueDateUtc: {
                    label: 'Due Date',
                    ascendingSort: 'Oldest First',
                    descendingSort: 'Newest First',
                },
                status: {
                    label: 'Task Status',
                    // "To Do" is used as a general term to represent both the PENDING and PROGRESS global attributes
                    ascendingSort: 'To Do First',
                    descendingSort: 'Completed First',
                },
            },
        },
        LinkedReports: {
            reportDisplay(reportType: string) {
                return `${reportType} Report`;
            },
            noCadTickets: 'No CAD events',
            noReportsForRen: (renDisplayName: string) =>
                `No reports exist for this ${renDisplayName}`,
            noCadTicketsForRen: (renDisplayName: string) =>
                `No CAD events exist for this ${renDisplayName}`,
        },
        InvolvedProfilesCard: {
            title: 'Involved Profiles',
        },
        InvolvedNamesCard: {
            sealedPerson: 'Sealed Person',
            isNonDisclosure: '(Non-Disclosure)',
            relationships: {
                noDataMessage: 'No relationships specified',
                addRelationships: (organization: string) =>
                    `To add relationships, add one more person or ${organization?.toLowerCase()} to report`,
            },
            InvolvedNamesCardTabs: {
                involvedLabel: 'Involvement:',
            },
            InvolvedNamesForm: {
                labels: {
                    // relationships
                    nameNameLink: {
                        linkTypeIdPrefix: 'Relationship(s) to',
                        description: 'Explain Other',
                    },
                },
                hasMoreThanOneSpouseError:
                    'Person cannot have more than one relationship of type "Spouse of"',
            },
        },
        ReportCaseStatusesForm: {
            title: 'Report Status',
            noCancelAttributeError: 'Cannot cancel report: no active cancel status available',
        },
        gangTracking: {
            GangTrackingSidePanel: {
                title: 'Criminal Street Gang Tracking',
                personTitle: (personName: string) => `Criminal Street Gang Tracking: ${personName}`,
                noResults: 'No persons with criteria on this report',
                cancel: 'Cancel',
                close: 'Close',
                save: 'Save',
                back: 'Back',
                add: 'Add',
                criminalStreetGangAffiliation: 'Suspected Gang Affiliation',
                newHelpText:
                    'Adding a suspected gang affiliation will add the gang name, “Suspected Gang Member” caution, and expiration date to the person profile.',
                existingHelpText:
                    'Updating the suspected gang affiliation will add the gang name, “Suspected Gang Member” caution, and expiration date to the person profile, and overwrite the current gang affiliation.',
                willExpire: 'Affiliation will expire on',
                criteria: (reportTitle: string) => `Criteria: ${reportTitle}`,
                criminalStreetGangName: 'Criminal Street Gang Name',
            },
        },
        EntityPrefillPopover: {
            suggestedProfiles: (count: number) => `Suggested Profiles (${count})`,
            rmsResults: (total: number) =>
                total <= 3 ? `RMS Results (${total} of ${total})` : `RMS Results (3 of ${total})`,
            viewAllResults: 'View All Results',
            createNewProfile: 'Create New Profile',
            addUnknown: 'Add Unknown',
            dobPlaceholder: 'DOB (optional)',
            dobPlaceholderFocused: 'MM/DD/YYYY',
            dlSsnText: (dlNumber: string | undefined, ssn: string | undefined) =>
                !!dlNumber && !!ssn
                    ? `DL: ${dlNumber} | SSN: ${ssn}`
                    : !!dlNumber
                      ? `DL: ${dlNumber}`
                      : !!ssn
                        ? `SSN: ${ssn}`
                        : '',
            vehicePlaceholder: 'Search by VIN or Tag/Registration #',
            viewMobileCollections: 'View Mobile Collections',
            addFromDexQuery: 'Add From DEx Query',
        },
        audit: {
            action: {
                accepted: 'Accepted',
                declined: 'Declined',
                noAnswer: 'No Answer',
                skip: 'Skip',
                providerCancel: 'Provider Cancel',
                userCancel: 'User Cancel',
            },
        },
    },
    recordPrivacy: {
        courtOrders: {
            CourtOrderEditActionBar: {
                save: 'Save',
                cancel: 'Cancel',
            },
            CourtOrderEditDashboard: {
                title: 'Edit Court Order Information',
            },
            CourtOrderEditForm: {
                courtOrderAttrId: 'Statute Code',

                editInformation:
                    'Edit Court Case Information. This information will be recorded for your reference.',
                involvedPersons: {
                    note: 'Unseal person(s) via "Unseal" in the more options drop down on the report.',
                },
                charges: {
                    note: 'Unseal charge(s) via "Unseal" in the more options drop down on the report.',
                },
            },
            CourtOrderSelect: {
                label: 'Court Cases In This Report',
            },
            CourtOrderTypeSelect: {
                label: 'Type',
                options: [
                    {
                        label: 'Court Ordered',
                        value: false,
                    },
                    {
                        label: 'Statutory',
                        value: true,
                    },
                ],
            },
        },
        shared: {
            sections: {
                charges: {
                    title: 'Charges',
                },
                narrative: {
                    title: 'Narrative',
                    note: 'Sealing the narrative hides narrative text when viewing the report.',
                },
                involvedPersons: {
                    title: 'Involved Persons',
                },
            },
            PreviouslySealedNote: {
                narrativePreviouslySealedNote: 'This narrative has been previously sealed.',
            },
            ReportSealingFormFields: {
                isSealReport: {
                    label: 'Seal Remaining Report Contents',
                    helpText:
                        'Sealing the report hides all report information from view. Only court order information will be displayed on the report.',
                },
            },
            CourtOrderFormFields: {
                enterInformation:
                    'Enter the court case information. This information will be recorded for your reference.',
            },
        },
        vacating: {
            ChargeVacatingDashboard: {
                title: (reportTitle: string) => `Vacate ${reportTitle || ''}`,
            },
            ChargeVacatingForm: {
                charges: {
                    title: 'Charges',
                    note: 'Flag charge(s) on the report view to indicate that a charge has been vacated (set aside). Report access will not be impacted.',
                },
            },
            ChargeRow: {
                vacateTag: 'Vacated',
                vacateCharge: 'Vacate',
            },
            ChargeVacatingActionBar: {
                cancel: 'Cancel',
                save: 'Save',
            },
        },
        unsealing: {
            CourtOrderSummary: {
                sealedBy: 'Sealed By',
                dateOfSeal: 'Date Of Seal',
                attachments: 'Attachments',
            },
            ReportUnsealingForm: {
                sections: {
                    charges: {
                        note: 'Unsealing charge(s) restores its view on the report for all users.',
                    },
                    narrative: {
                        note: 'Unsealing a narrative restores its view on the report for all users.',
                    },
                    involvedPersons: {
                        note: 'Involved person with this court order is automatically re-added to the report. No user action is required.',
                    },
                },
                isUnsealNarrative: {
                    label: 'Unseal Narrative',
                },
                unsealReportContentsNote:
                    'Unsealing a person will unseal the report contents view for all users.',
            },
            ReportUnsealingFormFields: {
                isUnsealNarrative: {
                    label: 'Unseal Narrative',
                },
            },
            UnsealingDashboard: {
                title: (reportTitle: string) => `Unseal ${reportTitle || ''}`,
            },
            UnsealingActionBar: {
                cancel: 'Cancel',
                save: 'Unseal',
            },
        },
        sealing: {
            SealingDashboard: {
                title: (reportTitle: string) => `Seal ${reportTitle || ''}`,
            },
            ReportSealingForm: {
                involvedPersons: {
                    note: 'Sealing a person replaces the identity in the report with ‘SEALED PERSON’. All involvement of the person in the report will be removed.',
                },
            },
            InvolvedPersonRow: {
                sealedTag: 'Sealed',
                seal: 'Seal',
            },
            SealingActionBar: {
                cancel: 'Cancel',
                seal: 'Seal',
                selectOneProfile: 'To seal, select one person.',
                selectOneProfileOrCharge: 'To seal, select one person or a charge.',
            },
            SealingChargesSection: {
                sectionTitle: 'Charges',
                sealedTag: 'Sealed',
                sealButton: 'Seal',
            },
        },
    },
    entityProfiles: {
        global: {
            agency: 'Agency',
        },
        LegacyEntityDetails: {
            title: 'Legacy Data',
        },
        EntityProfileSectionNoData: {
            noData: 'No information available',
        },
        EntityProfileContextInfoBar: {
            contextedProfile: 'Contexted Profile: ',
            contextedProfileGeneric: 'Contexted Profile',
            linkToMaster: 'View Master Profile',
        },
        EntityProfileHeaderMenu: {
            edit: 'Edit Profile',
            evidenceOptions: (evidenceModuleName: string) =>
                `${prettify(evidenceModuleName)} Options`,
            permissionModalTitle: (entityName: string) => `Permissions: ${entityName}`,
        },
        EntityProfileAttachmentDetails: {
            title: 'Attachments',
        },
        EntityProfileLocations: {
            noLocations: 'No associated locations',
        },
        EntityProfileProperty: {
            propertyTitle: 'Associated Property',
            noPropertyText: 'No associated property',
        },
        EntityProfileVehicles: {
            vehiclesTitle: 'Associated Vehicles',
            noVehiclesText: 'No associated vehicles',
        },
        EntityProfileRelationships: {
            personsTitle: 'Associated Persons',
            organizationsTitle: (organization: string) => `Associated ${pluralize(organization)}`,
            noRelationshipsText: 'No relationships specified',
        },
        EntityProfileVehicleReports: {
            columns: {
                eventDate: {
                    label: 'Event Date',
                },
                renOwner: {
                    label: (renDisplayName: string, ownerDisplayValue: string) =>
                        `${renDisplayName}/${ownerDisplayValue}`,
                },
                reportType: {
                    label: 'Report Details',
                },
                locations: {
                    label: 'Locations',
                },
                propertyStatuses: {
                    label: 'Property Status',
                },
            },
        },
        EntityProfilePersonReports: {
            noResults: 'No Report Results',
            OptionsTableColumnOption: {
                eventDate: 'Event Date',
                createdDate: 'Created Date',
                sortAsc: 'Sort Asc',
                sortDesc: 'Sort Desc',
            },
            columns: {
                eventDate: {
                    label: 'Event Date',
                },
                renOwner: {
                    label: (renDisplayName: string, ownerDisplayValue: string) =>
                        `${renDisplayName}/${ownerDisplayValue}`,
                },
                reportType: {
                    label: 'Report Details',
                },
                locations: {
                    label: 'Locations',
                },
                involvement: {
                    label: 'Involvement',
                },
            },
            filterForm: {
                reportDetailsFieldset: {
                    title: 'Report Details',
                    reportTypes: 'Report Types',
                },
                clearFilters: 'Clear Filters',
            },
        },
        EntityProfilePersonBookings: {
            noResults: 'No Booking Results',
            columns: {
                bookingDateTime: {
                    label: 'Booking Date/Time',
                },
                bookingDetails: {
                    label: 'Booking Details',
                },
                arrestingOfficer: {
                    label: 'Arresting Officer',
                },
                eventNumber: {
                    label: 'Event Number',
                },
                bookingNumber: {
                    label: 'Booking Number',
                },
                bookingOfficer: {
                    label: 'Booking Officer',
                },
                bookingFacility: {
                    label: 'Booking Facility',
                },
                detainee: {
                    label: 'Detainee',
                },
            },
        },
        EntityProfilePersonWarrants: {
            columns: {
                warrant: {
                    label: 'Warrant',
                },
                subject: {
                    label: 'Subject',
                },
                status: {
                    label: 'Status',
                },
            },
        },
        EntityProfileActiveTarget: {
            header: 'Active Target',
        },
        EntityProfileOrganizationReports: {
            columns: {
                eventDate: {
                    label: 'Event Date',
                },
                renOwner: {
                    label: (renDisplayName: string, ownerDisplayValue: string) =>
                        `${renDisplayName}/${ownerDisplayValue}`,
                },
                reportType: {
                    label: 'Report Details',
                },
                locations: {
                    label: 'Locations',
                },
                involvement: {
                    label: 'Involvement',
                },
            },
        },
        EntityProfilePropertyReports: {
            columns: {
                eventDate: {
                    label: 'Event Date',
                },
                renOwner: {
                    label: (renDisplayName: string, ownerDisplayValue: string) =>
                        `${renDisplayName}/${ownerDisplayValue}`,
                },
                reportType: {
                    label: 'Report Details',
                },
                locations: {
                    label: 'Locations',
                },
                propertyStatuses: {
                    label: 'Property Status',
                },
            },
        },
        EntityProfilePropertyStaffRemarks: {
            title: 'Remarks',
            labels: {
                addItemRemarks: 'Add Item Remarks',
                noRemarks: 'No Remarks Added',
                itemRemarks: 'Item remarks',
                reportRemarks: (renDisplayName: string, reportingEventNumber: string) =>
                    `${renDisplayName} ${reportingEventNumber}`,
            },
        },
        EntityProfilePersonHeader: {
            dob: 'D.O.B',
            ageRange: 'Age Range',
            nickname: 'Nickname',
        },
        EntityProfileVehicleHeader: {
            color: 'Color',
            tag: 'Tag',
            state: 'State',
        },
        EntityProfilePersonWarrantsSummary: {
            potentialActiveWarrants: 'Potential Active Warrant(s)',
            confirmationRequired: 'Confirmation Required',
        },
        EntityProfileTasks: {
            newTask: 'Create New Task',
        },
        EvidenceItemMenu: {
            addToQueue: 'Add to Basket',
            downloadChainOfCustody: (custody: string) => `Download Chain of ${custody}`,
            printItemLabel: 'Print Item Label',
            requestDisposition: 'Request Disposition',
            setHighValue: (isHighValue: boolean) =>
                isHighValue ? 'Remove High Risk Status' : 'Add High Risk Status',
            setHighValueMessage: (isHighValue: boolean) =>
                isHighValue
                    ? 'You are removing the high risk status from this item. There will be no restrictions on where this item can be placed with the storage location.'
                    : 'You must place an item flagged as High Risk in a High Risk designated storage location, any misplacement will be audited and reported.',
            reversibleAction: 'Applying this status is reversible.',
            confirm: 'Confirm',
            setHighValueModalTitle: 'High Risk Status',
            itemIsHighValue: 'Item Is High Risk',
        },
        person: {
            ContactDetails: {
                title: 'Contact',
                labels: {
                    phone: 'Phone',
                    email: 'Email',
                    needsInterpreter: 'Needs Interpreter',
                    languagesSpoken: 'Language(s) Spoken',
                    dateEffectiveFrom: 'Effective From',
                    dateEffectiveTo: 'Effective To',
                    description: 'Description',
                    safe: 'safe',
                    primary: 'primary',
                    details: 'Details',
                },
            },
            IdentificationDetails: {
                title: 'Identification',
                labels: {
                    trueName: 'True Name',
                    birthInfo: 'Birth Info',
                    raceEthnicity: 'Race/Ethnicity',
                },
            },
            ProfileImages: {
                title: 'Profile Images',
            },
            AppearanceDetails: {
                title: 'Appearance',
                labels: {
                    height: 'Height',
                    weight: 'Weight',
                    skinTone: 'Skin Tone',
                    eyeColor: 'Eye Color',
                    hairColor: 'Hair Color',
                    hairStyle: 'Hair Style',
                    hairLength: 'Hair Length',
                    facialHair: 'Facial Hair',
                    build: 'Build',
                    physicalChar: 'Physical Char.',
                    identifyingMarks: 'Identifying Marks',
                    vision: 'Vision',
                    hatHeadwear: 'Hat / Headwear',
                    shirtBlouse: 'Shirt / Blouse',
                    jacketCoatSweater: 'Jacket / Coat / Sweater',
                    other: 'Other',
                    pantsDressSkirt: 'Pants / Dress / Skirt',
                    jewelryPiercing: 'Jewelry / Piercing',
                    footwear: 'Footwear',
                },
            },
            MilitaryDetails: {
                title: 'Military',
                labels: {
                    militaryBranch: 'Military Branch',
                },
            },
            EducationDetails: {
                title: 'Education',
                labels: {
                    school: 'School',
                    address: 'Address',
                    grade: 'Grade',
                    status: 'Status',
                    phoneNumber: 'Phone Number',
                },
            },
            EmploymentDetails: {
                title: 'Employment',
                labels: {
                    employer: 'Employer',
                    address: 'Address',
                    occupation: 'Occupation',
                    phoneNumber: 'Phone Number',
                    dateEmployed: 'Date Employed',
                },
            },
            DemeanorDetails: {
                title: 'Demeanor',
                labels: {
                    behavioralChar: 'Behavior Char.',
                    mood: 'Mood',
                    modusOperandi: 'Modus Operandi',
                    skills: 'Skills',
                },
            },
        },
        organization: {
            IdentificationDetails: {
                title: 'Identification',
                labels: {
                    type: 'Type',
                    industry: 'Industry',
                    tax: 'Tax',
                    phone: 'Phone',
                    customerService: 'Customer Service',
                    corporate: 'Corporate',
                    email: 'Email',
                    publicRelations: 'Public Relations',
                },
            },
            ProfileImages: {
                title: 'Images',
            },
        },
        property: {
            IdentificationDetails: {
                title: 'Identification',
            },
            ProfileImages: {
                title: 'Images',
            },
        },
        item: {
            OwnerDetails: {
                title: 'Owner Details',
                labels: {
                    name: 'Owner Name',
                    address: 'Address',
                    phone: 'Phone #',
                    dateEffectiveFrom: 'Effective From',
                    dateEffectiveTo: 'Effective To',
                    proofOfOwnership: 'Proof of Ownership',
                },
            },
            ClaimantDetails: {
                title: 'Claimant Details',
                labels: {
                    name: 'Claimant Name',
                    address: 'Address',
                    phone: 'Phone #',
                    dateEffectiveFrom: 'Effective From',
                    dateEffectiveTo: 'Effective To',
                },
            },
            CollectorDetails: {
                title: 'Collector Details',
                labels: {
                    name: 'Collector Name',
                    address: 'Address',
                    phone: 'Phone #',
                    dateEffectiveFrom: 'Effective From',
                    dateEffectiveTo: 'Effective To',
                },
            },
            GenericAssociatedNamesDetails: {
                title: 'Associated Person Details',
                labels: {
                    name: 'Name',
                    address: 'Address',
                    phone: 'Phone #',
                    dateEffectiveFrom: 'Effective From',
                    dateEffectiveTo: 'Effective To',
                },
            },
        },
        vehicle: {
            IdentificationDetails: {
                title: 'Identification',
                labels: {
                    tag: 'Tag',
                    vinHin: 'VIN/HIN #',
                    regState: 'Reg. State',
                    regYear: 'Reg. Year',
                    regType: 'Reg. Type',
                    mileage: 'Mileage',
                    insuranceCompany: 'Insurance Company',
                    policyNumber: 'Policy Number',
                },
            },
            AppearanceDetails: {
                title: 'Appearance',
                labels: {
                    makeModel: 'Make / Model',
                    yearOfManufacture: 'Year of Manufacture',
                    color: 'Color',
                    category: 'Category',
                    bodyStyle: 'Body Style',
                    description: 'Description',
                },
            },
        },
    },
    search: {
        core: {
            SearchCapabilityIcon: {
                tooltipCommon: ' is available for this field',
                booleanLinkText: 'Boolean search',
                wildcardLinkText: 'Wildcard search',
            },
        },
        savedSearch: {
            SavedSearch: {
                labels: {
                    lastExecutedDateUtc: 'last used',
                    subscribed: 'Subscribed',
                    shared: 'Shared',
                },
                notifications: {
                    staleQuery:
                        'Search parameters are no longer valid. Try recreating a new query and saving.',
                },
                successMessage: 'Search Saved \nVisit "Saved Searches" to name this search',
                successModalMessage: 'Search Successfully Saved',
                shareSuccessMessage: 'Saved Search Shared',
                unshareSuccessMessage: 'Saved Search Unshared',
                updateSuccessMessage: 'Search Successfully Updated',
            },
            SavedSearchConfirmationModals: {
                CopySavedSearchConfirmationModal: {
                    titleAction: 'Save',
                    message:
                        'Copying will create a new search. Changes made to the original saved search will not apply to this search.',
                },
                DeleteSavedSearchConfirmationModal: {
                    titleAction: 'Delete',
                    message:
                        'The search you are deleting is a shared search. Deleting will remove it for everyone and cannot be un-done.',
                },
                UpdateSavedSearchConfirmationModal: {
                    titleAction: 'Update',
                    message:
                        'The search you are updating is a shared search. Changes made apply to everyone.',
                },
            },
            SavedSearchModal: {
                title: 'Saved Searches',
                labels: {
                    button: 'Saved Searches',
                    cancelButton: 'Close',
                },
            },
            SavedSearchModalPermissionContent: {
                labels: {
                    roles: 'Roles',
                    buttonAddRole: 'Add Role',
                },
            },
            ShareSavedSearchModal: {
                title: 'Share Search',
                labels: {
                    buttonShare: 'Share Search',
                    buttonCancel: 'Cancel',
                },
            },
            SavedSearchEditModal: {
                title: 'Save as New Search',
                labels: {
                    buttonCancel: 'Cancel',
                    buttonSave: 'Save',
                    buttonSaveAndShare: 'Save & Share',
                    inputName: 'Saved Search Name',
                    checkboxShare: 'Share This Saved Search',
                },
                validations: {
                    searchNameRequired: 'Search name is required',
                },
            },
            SavedSearchContent: {
                labels: {
                    noResults: 'No saved searches found.',
                    sharedWithDepartment: 'Shared Searches',
                    mySavedSearches: 'My Saved Searches',
                    favoriteSearches: 'Favorites',
                },
            },
            SavedSearchMoreOptionsMenu: {
                copy: 'Copy',
                rename: 'Rename',
                delete: 'Delete',
                subscribe: 'Subscribe',
                share: 'Share',
                unshare: 'Unshare',
                unsubscribe: 'Unsubscribe',
                favorite: 'Favorite',
                unfavorite: 'Unfavorite',
            },
            SavedSearchRenameForm: {
                requiredError: 'A saved search requires a name.',
                uniqueNameError: 'Name already exists. Please enter a unique name.',
            },
            SavedSearchDropdownButton: {
                dropdownButton: 'Saved Search',
                updateSearch: 'Update Saved Search',
                saveNewSearch: 'Save New Search',
                disabledUpdateSearchTooltip:
                    'Make changes to the search criteria to update the search',
            },
        },
        AdvancedSearch: {
            titles: {
                all: 'All',
                reports: 'Reports',
                persons: 'Persons',
                locations: 'Locations',
                vehicles: 'Vehicles',
                property: 'Property',
                attachments: 'Attachments',
                courtOrders: 'Court Orders',
                bookings: 'Bookings',
            },
        },
        AdvancedSearchFormFooter: {
            labels: {
                fuzzyMatchingEnabled: 'enable fuzzy matching',
                fuzzyMatchingEnabledNote: '(may slow search speed)',
                showAdults: 'Show Adults',
                showJuveniles: (juvenileDisplayName: string) => `Show ${juvenileDisplayName}`,
            },
            helpText: {
                fuzzyMatchingEnabled:
                    'Results will include variations on your search criteria, in addition to exact matches. Fuzzy match enabled fields will appear highlighted in blue.',
                showAdults: 'Return results that include adults',
                showJuveniles: (juvenileDisplayName: string) =>
                    `Return results that include ${juvenileDisplayName}`,
            },
            clear: 'Clear All Fields',
        },
        AdvancedSearchBookingsForm: {
            search: 'Search',
            sections: {
                personnel: 'Personnel',
                booking: 'Booking',
            },
            fields: {
                eventNumber: 'Event Number',
                bookingNumber: 'Booking Number',
                bookingTime: 'Booking Date/Time',
                bookingFacility: 'Booking Facility',
                detaineeName: 'Detainee First Name',
                detaineeLastName: 'Detainee Last Name',
                bookingOfficer: 'Booking Officer',
                arrestingOfficer: 'Arresting Officer',
            },
        },
        AdvancedSearchAttachmentsForm: {
            search: 'Search',
            sections: {
                fileDetails: 'File Details',
            },
            fields: {
                fileName: {
                    label: 'File Name',
                    placeholder: 'Report_123456.pdf',
                },
                description: {
                    label: 'Description',
                    helpText:
                        'In order to get exact matches, use quotations around the search phrase.',
                },
                fileContents: {
                    label: 'File Contents Keyword Search',
                    helpText:
                        'Matches for keywords extracted from the uploaded or imported file. In order to get exact matches, use quotations around the search phrase.',
                    placeholder: '123 Main Street OR John Smith etc',
                },
                uploadedDate: {
                    label: 'Uploaded Date',
                },
                entityTypes: {
                    label: 'Uploaded To',
                },
            },
        },
        AdvancedSearchCourtOrdersForm: {
            search: 'Search',
            fields: {
                statuteCode: {
                    label: 'Statute',
                },
                courtCaseDate: {
                    label: 'Court Case Date',
                },
                modifiedDate: {
                    label: 'Modified Date',
                },
            },
        },
        AdvancedSearchAttachmentsResults: {
            noResults: 'Search Returned 0 Results',
            columns: {
                relevance: {
                    label: 'Relevance',
                },
                uploadedDate: {
                    label: 'Uploaded Date',
                    descendingSort: 'Newest First',
                    ascendingSort: 'Oldest First',
                },
                fileName: {
                    label: 'File Name / Description',
                },
                uploadedBy: {
                    label: 'Uploaded By',
                },
                source: {
                    label: 'Source',
                },
            },
        },
        AdvancedSearchSaveSearchButton: {
            labels: {
                savedSearch: 'Saved Search',
                savedSearchHover: 'Unsave Search',
                unsavedSearch: 'Save Search',
                unsavedSearchHover: 'Save Search',
            },
        },
        AdvancedSearchCadTicketsForm: {
            sectionTitles: {
                locations: (cadDisplayName: string) => `${cadDisplayName} Event Location`,
            },
            search: 'Search',
        },
        AdvancedSearchPersonsForm: {
            sectionTitles: {
                physicalCharacteristics: 'Physical Characteristics',
                identifiers: 'Identifiers',
                locations: 'Associated Address',
            },
            fieldsetTitles: {
                locations: (index: number) => `Associated Address #${index + 1}`,
            },
            search: 'Search',
        },
        AdvancedSearchReportsForm: {
            sectionTitles: {
                reportDetails: 'Report Details',
                personnel: 'Personnel',
                involvedPersons: 'Involved Persons',
                involvedProperty: 'Involved Property',
                involvedVehicles: 'Involved Vehicles',
                involvedLocations: 'Involved Locations',
                involvedOrganizations: (organizations: string) => `Involved ${organizations}`,
            },
            fieldsetTitles: {
                involvedPersons: (index: number) => `Involved Person #${index + 1}`,
                involvedProperty: (index: number) => `Involved Property #${index + 1}`,
                involvedVehicles: (index: number) => `Involved Vehicle #${index + 1}`,
                involvedLocations: (index: number) => `Involved Location #${index + 1}`,
                involvedOrganizations: (organization: string, index: number) =>
                    `Involved ${organization} #${index + 1}`,
            },
            filterLabels: {
                reportDefinitions: (index: number) => `Report Type #${index + 1}`,
                involvedOfficers: (index: number) => `Involved Officer #${index + 1}`,
            },
            dragon: {
                reportField: 'Report Field',
                keywordField: 'Keywords',
                inputFields: {
                    number: {
                        exactValueLabel: 'Exact Value',
                        minLabel: 'Min',
                        maxLabel: 'Max',
                        rangeLabel: 'Range',
                    },
                    text: {
                        label: 'Input Value',
                    },
                    id: {
                        label: 'Input Value',
                    },
                    date: {
                        rangeLabel: 'Date Range',
                        label: 'Date',
                    },
                },
                optionsFetchError: {
                    default: {
                        description:
                            'An error occurred while trying to fetch options for this field.',
                        title: 'Error fetching options',
                        retry: 'Retry',
                    },
                },
            },
            search: 'Search',
        },
        AdvancedSearchOrganizationsForm: {
            search: 'Search',
        },
        AdvancedSearchVehiclesForm: {
            sectionTitles: {
                owner: 'Owner Information',
            },
            search: 'Search',
        },
        AdvancedSearchPropertyForm: {
            sectionTitles: {
                owner: 'Owner Information',
            },
            search: 'Search',
        },
        AdvancedSearchResultsTableHeader: {
            sizeOption: (size: number) => `View ${size} per page`,
            hideFilters: 'Hide Filters',
            showFilters: 'Show filters',
        },
        TableResultsSummary: {
            showingResults: 'Showing results',
            of: 'of',
        },
        SearchExportBar: {
            selectAll: 'Select all viewable results.',
            allSelected: 'All viewable results are selected.',
            allOnPageSelected: (totalResultsOnPageCount: number) =>
                `All ${totalResultsOnPageCount} results on this page are selected.`,
            someOnPageSelected: (selectedCount: number) =>
                `${selectedCount} results on this page are selected.`,
            clearSelection: 'Clear Selection',
            serverError: 'An error occurred with the export, please try again.',
        },
        SearchMergeEntityBar: {
            buttons: {
                mergeEntities: 'Merge profiles',
            },
            notifications: {
                mergingDisabledConsortium:
                    'Select exactly two profiles owned by your agency to begin merging.',
                mergingDisabledNonConsortium: 'Select exactly two profiles to begin merging.',
                vehicleDisabledChainOfEntity: (custody: string) =>
                    `We do not support merging vehicles with a chain of ${custody?.toLowerCase()}`,
            },
        },
        AdvancedSearchExport: {
            download: 'DOWNLOAD',
            cancel: 'CANCEL',
            includeForExport: 'Include for export:',
        },
        AdvancedSearchReportsResults: {
            noResults: 'Search Returned 0 Results',
            routingLabelsColumnHeader: 'Labels',
            columns: {
                relevance: {
                    label: 'Relevance',
                },
                eventDate: {
                    label: 'Event Date',
                    descendingSort: 'Newest First',
                    ascendingSort: 'Oldest First',
                },
                modifiedDate: {
                    label: 'Modified Date',
                    descendingSort: 'Newest First',
                    ascendingSort: 'Oldest First',
                },
                creationDate: {
                    label: 'Creation Date',
                    descendingSort: 'Newest First',
                    ascendingSort: 'Oldest First',
                },
                ren: {
                    descendingSort: 'Z to A',
                    ascendingSort: 'A to Z',
                },
                reportType: {
                    label: 'Report Details',
                },
                location: {
                    label: 'Location',
                },
                persons: {
                    label: 'Persons',
                },
                organizations: {
                    label: (organization: string) => `${pluralize(organization)}`,
                },
                property: {
                    label: 'Property',
                },
                vehicles: {
                    label: 'Vehicles',
                },
                status: {
                    label: 'Stat',
                },
            },
        },
        AdvancedSearchCadTicketsResults: {
            noResults: 'Search Returned 0 Results',
            relevance: {
                label: 'Relevance',
            },
            eventDate: {
                label: 'Event Date',
                descendingSort: 'Newest First',
                ascendingSort: 'Oldest First',
            },
            cadRen: {
                label: (cadDisplayName: string, renDisplayName: string) =>
                    `${cadDisplayName} # / ${renDisplayName}`,
            },
            subdivision: {
                label: 'Subdivision',
            },
            location: {
                label: 'location',
                noLocation: (cadDisplayName: string) => `No ${cadDisplayName} Event Location`,
            },
            primaryUnit: {
                label: 'Primary Unit',
            },
            involvedUnits: {
                label: 'Involved Unit(s)',
            },
            callForService: {
                label: 'Call For Service Type',
            },
        },
        AdvancedSearchPersonsResults: {
            noResults: 'Search Returned 0 Results',
            sortBy: {
                label: 'Sort By',
            },
            relevance: {
                label: 'Relevance',
            },
            firstName: {
                label: 'First Name',
                ascendingSort: 'A - Z',
                descendingSort: 'Z - A',
            },
            lastName: {
                label: 'Last Name',
                ascendingSort: 'A - Z',
                descendingSort: 'Z - A',
            },
            age: {
                label: 'Age',
                descendingSort: 'Oldest First',
                ascendingSort: 'Youngest First',
            },
            modifiedDate: {
                label: 'Modified Date',
                descendingSort: 'Newest First',
                ascendingSort: 'Oldest First',
            },
            biographicalInfo: {
                label: 'Biographical Info',
            },
            identifiers: {
                label: 'Identifiers',
            },
            locations: {
                label: 'Location Data',
            },
        },
        AdvancedSearchOrganizationsResults: {
            noResults: 'Search Returned 0 Results',
            sortBy: {
                label: 'Sort By',
            },
            relevance: {
                label: 'Relevance',
            },
            modifiedDate: {
                label: 'Modified Date',
                descendingSort: 'Newest First',
                ascendingSort: 'Oldest First',
            },
            name: {
                label: (organization: string) => `${organization} Name`,
                ascendingSort: 'A - Z',
                descendingSort: 'Z - A',
            },
            identifiers: {
                label: 'Identifiers',
            },
            locations: {
                label: 'Location Information',
            },
        },
        AdvancedSearchVehiclesResults: {
            noResults: 'Search Returned 0 Results',
            sortBy: {
                label: 'Sort By',
            },
            relevance: {
                label: 'Relevance',
            },
            modifiedDate: {
                label: 'Modified Date',
                descendingSort: 'Newest First',
                ascendingSort: 'Oldest First',
            },
            vehicle: {
                label: 'Vehicle Status',
            },
            identifiers: {
                label: 'Vehicle Identifiers',
            },
            owner: {
                label: 'Owner Information',
            },
            IdentifiersCell: {
                labels: {
                    vin: 'VIN:',
                    tag: 'LP #:',
                    registrationState: 'Reg. State:',
                    registrationYear: 'Reg. Year:',
                },
            },
        },
        AdvancedSearchCourtOrdersResults: {
            noResults: 'Search Returned 0 Results',
            courtOrderDate: {
                label: 'Court Order Date',
                descendingSort: 'Newest First',
                ascendingSort: 'Oldest First',
            },
            statuteCode: {
                label: 'Statute Code',
            },
            courtCaseNumber: {
                label: 'Court Case #',
            },
            arrestNumber: {
                label: 'Arrest #',
            },
            modifiedDate: {
                label: 'Modified Date',
                descendingSort: 'Newest First',
                ascendingSort: 'Oldest First',
            },
            reportEventNumber: {
                label: 'REN',
            },
            involvedPerson: {
                label: 'Involved Person',
            },
            attachment: {
                label: 'Attachment',
            },
        },
        AdvancedSearchPropertyResults: {
            noResults: 'Search Returned 0 Results',
            sortBy: {
                label: 'Sort By',
            },
            relevance: {
                label: 'Relevance',
            },
            modifiedDate: {
                label: 'Modified Date',
                descendingSort: 'Newest First',
                ascendingSort: 'Oldest First',
            },
            property: {
                label: 'Property Status',
            },
            identifiers: {
                label: 'Identifiers',
            },
            owner: {
                label: 'Owner Information',
            },
            IdentifiersCell: {
                labels: {
                    serialNumber: 'Serial #:',
                    category: 'Category:',
                },
            },
            OwnerCell: {
                ownerNameLabel: 'Owner Name:',
                homeAddressLabel: 'Home Address:',
                phoneNumberLabel: 'Phone #:',
            },
        },
        CadTicketDoesNotRequireReportModal: {
            title: 'CAD Event',
            text: (renDisplayName: string) =>
                `This CAD event does not yet have a ${renDisplayName} associated, please generate a ${renDisplayName} to create new reports.`,
        },
        fieldsets: {
            CadTicketFieldset: {
                title: (cadDisplayName: string) => `${cadDisplayName} Event`,
                labels: {
                    dateTimeRange: 'Event Date/Time Range',
                    agencyEventNumber: (cadDisplayName: string) => `${cadDisplayName} Event #`,
                },
                filterLabels: {
                    agencyEventNumber: (cadDisplayName: string, index: number) =>
                        `${cadDisplayName} Event #${index + 1}`,
                },
            },
            ClothingFieldset: {
                title: 'Clothing',
                addText: 'Add Another Clothing',
                filterLabel: (index: number) => `Clothing #${index + 1}`,
            },
            CommunityInformationFieldSet: {
                labels: {
                    communityInformationDispositionAttrIds: 'Community Information Disposition',
                },
            },
            IdentifiersFieldset: {
                title: 'Identifiers',
                NameIdentifiersNItems: {
                    filterLabel: (index: number) => `ID #${index + 1}`,
                },
                Passports: {
                    filterLabel: 'Passports',
                },
            },
            IdentifyingMarksFieldset: {
                title: 'Scars, Marks and Tattoos',
                addText: 'Add Another Scar, Mark or Tattoo',
                filterLabel: (index: number) => `Marks #${index + 1}`,
            },
            LocationFieldset: {
                title: 'Location',
            },
            LocationsFieldset: {
                title: 'Locations',
                labels: {
                    geoRadiusQuery: {
                        lat: 'Center Latitude',
                        lon: 'Center Longitude',
                        radiusInMeters: 'Radius (Meters)',
                    },
                    geoPolygonQuery: {
                        lat: (lat: string, index: number) => `${lat} Value ${index + 1}`,
                        lon: (lon: string, index: number) => `${lon} Value ${index + 1}`,
                        filterLabel: (index: number) => `Polygon Point ${index + 1}`,
                    },
                },
                radiusSearchTitle: 'Radius Search',
                polygonSearchTitle: 'Polygon Search',
            },
            OffenseFieldSet: {
                labels: {
                    offenseNibrsGroups: 'Offense NIBRS Groups',
                    offenseUcrGroups: 'Offense UCR Groups',
                    offenseHasUcrCode: 'Has UCR Code',
                    offenseUcrCodes: 'UCR Codes',
                },
            },
            OrganizationFieldset: {
                labels: {
                    name: (organization: string) => `${organization} Name`,
                    phoneNumber: (organization: string) => `${organization} Phone`,
                    involvement: (organization: string) => `${organization} Involvement`,
                    industryAttrIds: (organization: string) => `${organization} Industry`,
                    typeAttrIds: (organization: string) => `${organization} Type`,
                },
            },
            PersonDetailsFieldset: {
                title: 'Person Details',
                labels: {
                    dateOfBirth: 'DOB',
                    involvement: 'Person Involvement',
                    ageRangeInReport: (ageRangeInReport: string) => `${ageRangeInReport}`,
                    hasCriminalStreetGangCriteria: 'Has criminal street gang criteria?',
                },
            },
            PersonGangTrackingsFieldset: {
                filterLabel: (index: number) => `Criminal Gang Tracking #${index + 1}`,
                isExpired: 'Any Gang',
            },
            PersonInjuriesFieldset: {
                title: 'Injury',
                filterLabel: (index: number) => `Injury #${index + 1}`,
            },
            PersonnelFieldset: {
                title: 'Personnel',
                labels: {
                    officerId: 'Involved Officer(s)',
                    reportCaseStatus: (displayFieldName: string) =>
                        `Report ${displayFieldName} Status`,
                },
            },
            PersonsFieldset: {
                title: 'Persons',
            },
            PhysicalAttributesFieldset: {
                title: 'Physical Characteristics',
            },
            PropertyFieldset: {
                title: 'Property',
            },
            PropertiesFieldset: {
                title: 'Properties',
                labels: {
                    statusDateRange: 'Status Date/Time Range',
                    modifiedDateRange: 'Modified Date/Time Range',
                },
            },
            CourtOrdersFieldset: {
                title: 'Court Orders',
                labels: {
                    statuteCodeAttrId: 'Statute Code',
                    courtCaseNumber: 'Court Case #',
                    courtCodeAttrId: 'Court Code',
                    courtCaseDateRange: 'Court Case Date Date/Time Range',
                    modifiedDateRange: 'Modified Date/Time Range',
                    stateIdNumber: 'State Id Number',
                    involvedPerson: 'Involved Person',
                },
            },
            ReportDetailsFieldset: {
                title: 'Report Details',
                labels: {
                    dateTimeRange: 'Event Date/Time Range',
                    shiftTimeRange: 'Shift Time Range',
                    narrative: 'Narrative Keyword Search',
                    ucrGroup: 'UCR Group',
                    agencyIds: 'Primary Agencies',
                },
                helpTexts: {
                    offenseUcrCodes: (offenseDisplayName: string) =>
                        `This field searches by federal UCR codes set at the ${offenseDisplayName.toLowerCase()} level. If you want to search by the UCR Classification of a report, please search by the UCR Classification field below, in the reports section.`,
                },
                reportDefinitions: {
                    labels: {
                        officerUserProfileIds: 'Officer',
                        officerSexAttrIds: 'Officer Sex',
                        officerRaceAttrIds: 'Officer Race',
                        officerDateOfBirth: 'Officer DOB',
                        officerAgeRangeInReport: 'Age Range (At Time of Report)',
                    },
                },
            },
            VehicleFieldset: {
                title: 'Vehicle',
                labels: {
                    tag: 'License Plate #',
                    registrationStateAttrIds: 'LP State',
                    description: 'Description',
                    itemCategoryAttrIds: 'Vehicle Type',
                    vehicleCautionAttrIds: 'Vehicle Cautions',
                },
                sections: {
                    vehicleRegistration: 'Vehicle Registration',
                    vehicleDescription: 'Vehicle Description',
                },
            },
            CautionsFieldset: {
                activeWithinRange: 'Active Within Range',
                includeInactiveHelperText: 'Include expired and future cautions',
            },
            AdditionalDetailsFieldset: {
                title: 'Additional Details',
            },
            VehiclesFieldset: {
                title: 'Vehicles',
            },
            ItemLocationFieldset: {
                title: 'Locations',
                menuTitle: 'Location types',
            },
        },
        CasesSearchSavedSearchRenameForm: {
            uniqueNameError: 'Name already exists. Please enter a unique name.',
        },
    },
    security: {
        InactivityModal: {
            title: 'Session Time-out',
            content:
                "Your session is about to expire, if you'd like to continue working, please click the button below. If you do not respond within 5 minutes, the system will log you out.",
            okText: 'Continue Session',
            cancelText: 'Log Out',
        },
        EntityPermissionsModal: {
            title: 'Permissions',
        },
        EntityPermissionsForm: {
            otherPersonnel: 'Other Personnel',
            users: {
                tabTitle: 'Users',
                labels: {
                    roleId: 'User or Group',
                },
            },
            departments: {
                tabTitle: 'External Agencies',
                labels: {
                    roleId: 'Group',
                },
            },
            labels: {
                operationType: 'Permission',
            },
            requiredError: 'This field is required',
            noManagePermissionError: 'At least one user or group must have Can Manage permissions.',
            duplicateRoleError: 'The same user or group cannot be added twice.',
        },
    },
    summaries: {
        LocationSummary: {
            verifiedAddress: 'Verified Address',
            latLong: 'Lat, Long',
            noFixed: 'No Fixed Address',
            verifiedLocation: 'Verified Location',
            longitude: 'Longitude',
            latitude: 'Latitude',
        },
        PersonProfileSummary: {
            addresses: 'Addresses',
            phones: 'Phone(s)',
            emails: 'Email(s)',
            emergency: 'Emergency',
            id: 'ID',
            injuries: 'Injuries',
            appearance: 'Appearance',
            clothing: 'Clothing',
            identifyingMarks: 'Identifying Marks',
            military: 'Military',
            employment: 'Employment',
            school: 'School',
            religion: 'Religion',
            religionOther: 'Explain Religion Other',
            attachments: 'Attachments',
            physicians: 'Physician(s)',
            homeAddresses: 'Home Addresses',
            workAddresses: 'Work Addresses',
            unknownDate: 'Unknown',
            passports: 'Passport',
        },
        PersonProfileTitleSummary: {
            dateOfBirth: 'DOB',
            sex: 'Sex',
            height: 'Height',
            eyes: 'Eyes',
            hair: 'Hair',
            ssn: 'SSN',
            dlNumber: 'DLN',
            deceased: 'Deceased',
            unborn: 'Unborn',
        },
        OrganizationProfileSummary: {
            addresses: 'Addresses',
            phones: 'Phone(s)',
            emails: 'Email(s)',
            id: 'ID',
            attachments: 'Attachments',
            mailingAddresses: 'Mailing Addresses',
            physicalAddresses: 'Physical Addresses',
        },
        OrganizationProfileTitleSummary: {
            physicalAddress: 'Physical Address',
        },
    },
    support: {
        attributeCodes: {
            AttributeCodes: {
                title: 'Clone Attribute Codes',
            },
            CloneAttributeCodesForm: {
                sourceFrom: 'Clone from Code Type Source',
                sourceTo: 'Clone to Code Type Source',
                submitButton: 'Clone',
                warning: 'Only clone UCR and NIBRS code type sources',
            },
        },
        blacksmith: {
            BlacksmithIntegrations: {
                title: 'Blacksmith Integrations',
                createIntegration: 'Create Integration',
                blacksmithId: 'ID',
                blacksmithName: 'Name',
                blacksmithType: 'Type',
                department: 'Department',
                isEnabled: 'Is Enabled',
                lastRun: 'Last Run',
                lastStatus: 'Last Status',
            },
        },
        codeTypes: {
            codeType: 'Code Type',
            newCodeType: 'New Code Type',
            cloneCodeType: 'Clone Code Type',
            code: 'Code',
            codeDescription: 'Code Description',
            CodeTypeForm: {
                notice: (env: string) =>
                    `Code Types and Codes are configured globally, not for each tenant. Any changes saved here will immediately apply to all tenants in this environment (${env}). Codes that are mapped to attributes cannot be edited or removed.`,
                source: 'Code Type Source',
                category: 'Code Type Category',
                categoryHelpText:
                    'Code Type Category does not need to be filled in when there will be no RMS logic for these Codes. Code Type Categories cannot be created from this page, and can only be created by engineers.',
                name: 'Name',
                nameHelpText:
                    'Naming convention is PascalCase with no spaces. Different Code Type Sources can have Code Types with the same name. For example, various regional groups each have a Code Type called "Race" because they capture race data differently.',
                description: 'Description',
            },
            BulkAddCodesModal: {
                overlayButtonText: 'Multiple Codes',
                title: 'Bulk Add Codes',
                label: 'Enter in the codes and their descriptions, 1 per line, separated by an equals sign (=)',
                preview: (count: number) => `Live Preview (${count} codes)`,
                invalidFormat: 'Invalid format',
            },
        },
        partnershipRealTimeActions: {
            partnershipRealTimeAction: 'Partnership Real Time Action',
            partnershipRealTimeActionId: 'ID',
            partnershipRealTimeActionName: 'Name',
            partnershipRealTimeActionConsumerType: 'Consumer Type',
            partnershipRealTimeActionSubscriberType: 'Subscriber Type',
            department: 'Department',
            isEnabled: 'Is Enabled',
            newPartnershipRealTimeAction: 'New Partnership Real Time Action',
            partnershipRealTimeActionsAdmin: {
                toggleDisabled: 'Show Disabled',
            },
        },
        searchSync: {
            AreEntitiesSyncedContent: {
                inMySql: 'In MySQL',
                inElasticSearch: 'In ElasticSearch',
                isSynced: 'Is Synced',
                lastSyncedDate: 'Last Synced Date',
            },
            entityType: 'Entity Type',
            entityId: 'Entity ID',
            entityIds: 'Entity IDs',
            entityIdsHelpText: 'A comma-separated list of Entity IDs',
            reportTypeSyncHelpText:
                'Report Type Search Syncs will always run a mass sync. The Start Date field will be ignored.',
            startDate: 'Start Date',
            startDateHelpText:
                'When specified, only records on or after the start date will be synced, making the sync faster (Optional)',
            syncIndividualEntity: 'Sync Individual Entity',
            kickOffSync: 'Kick Off Sync',
            getSyncStatus: 'Get Sync Status',
            massSync: 'Sync Type',
            syncingStatus: 'Current Status',
            syncingType: 'Type',
            department: 'Department',
            syncingCurrentId: 'Current ID',
            syncingMaxId: 'Max ID',
            refreshStatus: 'Refresh Status',
            started: 'Started',
            cancel: 'Cancel Sync',
            multiSync: 'Multi Department Sync',
            entityTypes: 'Entity Types',
            departments: 'Departments',
        },
        cadUnitStatusTransitions: {
            title: 'Unit Status Transitions',
            listTitle: 'STATUS',
            headers: {
                status: 'Status',
                dispatcher: 'Dispatcher',
                firstResponder: 'First Responder',
                system: 'Admin',
                eventRelated: 'Event Related',
                nonEventRelated: 'Non-Event Related Statuses',
            },
            importExportMenuItems: {
                exportUnitStates: 'Export Unit States',
                importUnitStates: 'Import Unit States',
                groupTitles: {
                    exportUnitStateTransitions: 'Export Unit State Transitions',
                    importUnitStateTransitions: 'Import Unit State Transitions',
                },
                police: 'Police',
                fire: 'Fire',
                ems: 'EMS',
            },
        },
        idGenerator: {
            title: 'ID Generator',
            sequenceTitle: 'Sequence Configuration',
            listTitle: 'Entity',
            agencyListTitle: 'Agency',
            save: 'Save',
            note: 'Please ensure that the Warrant Number Sequence Type is set to Single on the Warrant Configuration Admin Page to use this number series.',
            useDepartmentDefaults: 'Use department defaults',
            labels: {
                format: 'Format',
                sequence: 'Increase By',
                extraKey: 'Extra Key',
            },
        },
        ssoConfiguration: {
            SsoInitialSetupForm: {
                noConnectionsFound: 'No connections found',
                formSubmitSuccess: 'Connection successfully set',
                submitForm: 'Set connection',
                fieldLabels: {
                    connection: 'Connection',
                    createMark43SsoConnection: 'Create Mark43 SSO Connection?',
                    domainHint: 'Domain Hint',
                },
            },
            SsoConfigurationAdminForm: {
                formSubmitSuccess: 'Save successful',
                submitForm: 'Add/update SSO configuration',
                deleteConfig: 'Delete SSO configuration',
                deletionSuccess: 'Configuration successfully deleted',
                fieldLabels: {
                    clientId: 'Client ID',
                    clientSecret: 'Client Secret',
                    connectionId: 'Connection Name',
                    federatedLogout: 'Federated Logout?',
                    domainHint: 'Domain Hint',
                },
            },
        },
        sequenceIncreaseModal: {
            link: 'Advanced Settings',
            title: 'Sequence Number Configuration Advanced Settings',
            description:
                'Enter the record ID prefix to see the current sequence number for this record type. Increasing the sequence will skip the sequence forward.',
            datePrefix: 'Date Prefix',
            increaseSequenceBy: 'Increase Sequence By',
            currentSequence: 'Current Sequence Number',
            nextSequence: 'Next Sequence Number',
            save: 'Save',
            sequenceValueReadFailure: 'Failed to read sequence value',
            sequenceValueIncreaseFailure: 'Failed to increase sequence value',
        },
        core: {
            preview: 'Preview',
            useSimpleUi: {
                errorMessage: 'Error',
                successMessage: 'Success',
            },
        },
    },
    validation: {
        heightFeetError: 'Must be a number 1-9',
        heightInchesError: 'Must be a number 0-11',
        yearsOfExperienceError: 'Must be a number 0-99',
        weightError: 'Must be a number 1-999',
        duplicateError: 'This must be unique',
        mustBeIntError: 'Must be a number',
        mustBeIntsError: 'Must be all numbers',
        mustBeIntAndGreaterThanZeroError: 'Must be a number and greater than zero',
        requiredError: 'This field is required',
        requiredNumberError: 'This field is required and must be a number',
        serverError: 'An error has occurred',
        phoneLengthError: 'Phone number length cannot exceed 15 digits',
        phoneInvalidCharactersError:
            'Phone number should only include digits, space, and any of: (, ), +, -, .',
        duplicateWarrantNumberError: 'A warrant already exists for this number.',
        internalEntityPermissionMustHaveManage:
            'At least one internal user/role must have MANAGE permissions',
        duplicateEventSearchRadiusIsRequired: 'Search Radius is required',
        duplicateEventSearchTimespanIsRequired: 'Search Timespan is required',
        accessLevelIsRequired: 'Access Level is Required',
        userRoleIsRequired: 'User/Role is Required',
        atLeastOneRoleMustHaveCanManage: 'At least one Role must have "Can Manage"',
        caseDefaultTaskTitlesRequired: 'Each default task must have a title',
        required: 'Required',
        stationNameLengthError: 'Station Name cannot be longer than 5 characters',
        duplicateDispatchAreaNameError: 'Dispatch area name is already in use',
        duplicateCallSignError: 'Call sign is already in use',
        unitClassMustMatchAgencyClassError: 'Unit class must match agency class',
        dispatchAreaMustMatchAgencyClassError: 'Dispatch area must match agency class',
        strictAlphaNumeric: 'Must be alphanumeric with no spaces',
    },
    warrants: {
        generic: {
            obtainedBy: 'Obtained by',
        },
        core: {
            WarrantStubSidePanel: {
                title: 'Add Warrant',
            },
        },
        warrant: {
            WarrantNumberConfigurationSelect: {
                unnamed: 'Unnamed',
            },
            WarrantHeader: {
                changeWarrantNumber: (warrantNumberDisplay: string) =>
                    `Change ${warrantNumberDisplay}`,
                permissionsModalTitle: (warrantShortTitle: string) =>
                    `Warrant Permissions: ${warrantShortTitle}`,
                status: 'Status',
                editRen: (ren: string) => `Change ${ren}`,
                deleteWarrant: 'Delete Warrant',
                sendToClips: 'Send to CLIPS',
                deleteRen: (ren: string) => `Remove ${ren}`,
                createTask: 'Create Task',
            },
            WarrantSendToClipsModal: {
                clips: 'CLIPS',
                entryName: 'Entry Name',
                title: (warrantNumber: string, warrantType: string) =>
                    `CLIPS #${warrantNumber} ${warrantType} Warrant`,
                message: 'All warrant information will be sent to CLIPS, including listed charges.',
                sendButtonText: 'Send',
                warrantType: (type: string) => `${type} Warrant`,
                validationStrings: {
                    entryNameRequired: 'Entry name is required',
                },
                sendSuccessMessage: 'All warrant information has been sent to CLIPS',
                sendErrorMessage: 'Error: no warrant information has been sent to CLIPS',
            },
            WarrantSidebar: {
                title: 'Linked Arrests',
                noReportsForRen: 'No Arrest Reports linked to this Warrant',
            },
            WarrantDexSidebarSection: {
                dataExchange: 'Data Exchange',
                entryMustBeEntered: (entryLevelFieldDisplayName: string) =>
                    `An ${entryLevelFieldDisplayName} must be entered before this warrant can be sent to external databases.`,
                entryLevelText: (entryLevelFieldDisplayName: string, entryLevel: string) =>
                    `The ${entryLevelFieldDisplayName} for this warrant is ${entryLevel}`,
                entry: 'Entry',
                cancel: 'Cancel',
                action: 'Action',
                send: 'Send',
                viewReturns: 'View Returns',
            },
            CreateWarrantModal: {
                title: 'Create Warrant',
                okText: 'Create',
            },
            WarrantRenSearchResults: {
                noReportsForNewRen: (renDisplayName: string) =>
                    `No reports exist for this ${renDisplayName}. A ${renDisplayName} will be created.`,
                noReportsForRen: (renDisplayName: string) =>
                    `No reports exist for this ${renDisplayName}.`,
                relatedReports: (renDisplayName: string, ren: string) =>
                    `${renDisplayName} ${ren}: reports`,
                currentRenRelatedReports: (renDisplayName: string, ren: string) =>
                    `Current ${renDisplayName} ${ren}: reports`,
                currentRen: (renDisplayName: string, ren: string) =>
                    `Current ${renDisplayName} ${ren}`,
            },
            ChangeWarrantNumberForm: {
                warrantNumber: (warrantDisplayName: string) => `Current ${warrantDisplayName}`,
                newWarrantNumber: (warrantDisplayName: string) => `New ${warrantDisplayName}`,
            },
            ChangeWarrantNumberModal: {
                title: (warrantDisplayName: string) => `Change ${warrantDisplayName}`,
                save: 'Save',
            },
            DeleteWarrantModal: {
                title: 'Delete Warrant',
                confirmationText: 'Are you sure you want to delete this warrant?',
                cannotDeleteText:
                    'This warrant cannot be deleted because it is used in the following arrest(s):',
                cannotDeleteNoArrestsText:
                    'This warrant cannot be deleted because it is used in arrest(s) to which you may not have access',
                okText: 'Confirm',
            },
            WarrantDetailsCard: {
                title: 'Warrant Details',
                courtInformation: 'Court Information',
                warrantInformation: 'Warrant Information',
                noBail: 'No Bail',
                vehicleInformation: 'Vehicle Information',
            },
            WarrantChargesCard: {
                noCharges: (charge: string) => `No ${pluralize(charge)}`,
            },
            WarrantDexSubmissionCard: {
                recordsWorkflow: 'Records Workflow',
                externalDatabaseIdNumber: 'External Database ID Number(s)',
                myself: 'Myself',
            },
            WarrantDexReturns: {
                exportAll: 'Export All',
                refresh: 'Refresh',
                dataExchangeReturns: 'Data Exchange Returns',
                noReturns: 'No Returns',
            },
            WarrantActivitySidePanel: {
                title: 'Add/Edit Warrant Activity',
                myself: 'Myself',
            },
            WarrantChargesSidePanel: {
                addCharges: (charge: string) => `Add ${pluralize(charge)}`,
                editCharge: (charge: string) => `Edit ${charge}`,
            },
            WarrantActivities: {
                title: 'Warrant Activities',
                add: 'Add',
                showMore: 'Show More',
                noActivities: 'No Activities',
            },
            WarrantLinkedArrestRow: {
                departmentName: (departmentName: string) => `(${departmentName})`,
                reportInfoOwnedBy: (reportOwnerName: string) => `Owned by ${reportOwnerName}`,
                created: 'created',
            },
            WarrantClipsSendingModal: {
                sending: 'Sending...',
            },
        },
        dashboard: {
            title: 'Warrants',
            newWarrant: 'New Warrant',
            searchForm: {
                title: 'Filters',
                okText: 'Apply Filters',
                subjectFieldset: (subjectFieldDisplayName: string) => `${subjectFieldDisplayName}`,
                warrantDetailsFieldset: 'Warrant Details',
                recordsFieldset: 'Records',
                labels: {
                    isLinkedToArrest: 'Linked To Arrest',
                    nameIdentifierTypeAttrId: 'Identifier Type',
                    idValue: 'Name/ID',
                    agency: 'Primary Agency',
                },
                placeholders: {
                    quickSearchQuery: (warrantNumberDisplayName: string, renDisplayName: string) =>
                        `${warrantNumberDisplayName}, Subject Name, ${renDisplayName}`,
                },
                footer: {
                    saveSearch: 'Save Search',
                    unsaveSearch: 'Unsave Search',
                    clearFilters: 'Clear Filters',
                },
            },
            results: {
                header: {
                    saveSearch: 'Save Search',
                    unsaveSearch: 'Unsave Search',
                },
                columns: {
                    issuedDate: {
                        descendingSort: 'Newest First',
                        ascendingSort: 'Oldest First',
                    },
                    subject: {
                        firstNameDescendingSort: 'First Name A to Z',
                        firstNameAscendingSort: 'First Name Z to A',
                        lastNameDescendingSort: 'Last Name A to Z',
                        lastNameAscendingSort: 'Last Name Z to A',
                    },
                    warrantInformation: 'Warrant Information',
                    warrantLocation: 'Warrant Location',
                    charges: 'Charges',
                    status: {
                        label: 'Status',
                        ascendingSort: 'Z to A',
                        descendingSort: 'A to Z',
                    },
                },
                noResults: 'No Warrants',
                actionButtons: {
                    addToQueue: 'Add to Basket',
                    export: 'Export',
                },
                allOnPageSelected: (selected: number) =>
                    `All ${selected} results on this page are selected.`,
                someOnPageSelected: (selected: number) => {
                    if (selected === 1) {
                        return '1 result on this page is selected.';
                    } else {
                        return `${selected} results on this page are selected.`;
                    }
                },
                selectAll: (totalResults: number) =>
                    `Select all ${totalResults} results to export.`,
                allSelected: (totalResults: number) => `All ${totalResults} results are selected.`,
                clearSelection: 'Clear Selection',
            },
        },
        connectWarrants: {
            title: 'Add Warrant',
            moreResults: 'View more results',
            back: 'BACK',
            noResultsFound: 'No results found',
            search: 'SEARCH',
            labels: {
                startDateUtc: 'Between',
                endDateUtc: 'And',
                warrantTypeAttrIds: 'Warrant Type(s)',
                obtainingOfficerIds: 'Obtaining Officer',
            },
        },
    },
    tasks: {
        core: {
            TaskEntitiesLinkSidePanel: {
                title: 'Add Linked Items',
                labels: {
                    linkedItemsSection: 'Linked Items',
                    quickSearchInput: 'Quick Search',
                },
                disabledQuickSearchTooltip: (limit: number) => `Max ${limit} Items Per Task`,
            },
            TaskSidePanel: {
                assignMyself: 'Myself',
                addLinkedItem: 'Add Linked Items',
                editTaskTitle: 'Edit Task',
                linkedItems: 'Linked Items',
                newTaskTitle: 'Create New Task',
                taskUpdates: 'Task Updates',
                addUpdate: 'Add Update',
                history: 'History',
                attachments: 'Attachments',
                HistorySection: {
                    assignee: 'Assigned User/Role',
                    priority: 'Task Priority',
                    type: 'Task Type',
                    status: 'Status',
                    dueDate: 'Due Date',
                    fieldChangeWithoutValue: (
                        changedBy: string,
                        fieldName: string,
                        formattedCreatedDateUtc: string
                    ) => `${changedBy} changed ${fieldName} ${formattedCreatedDateUtc}`,
                    fieldChange: (
                        changedBy: string,
                        fieldName: string,
                        newFieldValue: unknown,
                        formattedCreatedDateUtc: string
                    ) =>
                        `${changedBy} changed ${fieldName} to ${newFieldValue} ${formattedCreatedDateUtc}`,
                    linkChange: (
                        changedBy: string,
                        linkString: string,
                        linkedEntityDisplay: string | undefined,
                        entityType: string,
                        formattedCreatedDateUtc: string
                    ) =>
                        `${changedBy} ${linkString} ${linkedEntityDisplay} ${entityType} ${formattedCreatedDateUtc}`,
                    taskCreateChange: (changedBy: string, formattedCreatedDateUtc: string) =>
                        `${changedBy} created task ${formattedCreatedDateUtc}`,
                    attachmentEditChange: (
                        changedBy: string,
                        attachmentDisplay: string,
                        formattedCreatedDateUtc: string
                    ) =>
                        `${changedBy} edited ${attachmentDisplay} attachment ${formattedCreatedDateUtc}`,
                },
            },
            TaskTable: {
                noResults: 'Tasks not found',
                linkedItems: (count: number) => `${count} Linked Items`,
                columnHeaders: {
                    title: 'Title',
                    source: 'Source',
                    assignee: 'Assignee',
                    dueDate: 'Due Date',
                    createdDate: 'Created Date',
                    modifiedDate: 'Modified Date',
                    type: 'Type',
                    priority: 'Priority',
                    status: 'Status',
                },
                dates: {
                    yesterday: 'Yesterday',
                    today: 'Today',
                    tomorrow: 'Tomorrow',
                },
                sorts: {
                    priorityAsc: 'Low to High',
                    priorityDesc: 'High to Low',
                    dateAsc: 'Latest First',
                    dateDesc: 'Soonest First',
                    alphaAsc: 'A to Z',
                    alphaDesc: 'Z to A',
                },
            },
            TaskPopOverMenu: {
                deleteTask: 'Delete',
                editTask: 'Edit',
            },
            TaskAddUpdateModal: {
                title: 'Task Update',
                body: 'Description of progress and/or task outcome(s):',
                saveText: 'Save',
            },
            TaskErrorModal: {
                confirmButton: 'Ok',
                error: 'An error occurred, this task could not be deleted.',
                title: 'Delete Task',
            },
            errorDeletingTask: 'Failed to delete Task item',
            errorSavingTask: 'Failed to save Task',
        },
        dashboard: {
            TasksDashboardContainer: {
                filterPlaceHolder: (renLabel: string) => `Source ${renLabel}, Record ID, Title`,
                filters: 'Filters',
                newTask: 'Create Task',
                title: 'Tasks',
            },
            TaskDashboardSearchForm: {
                labels: {
                    dateType: 'Date Type',
                    dateTimeRange: 'Date/Time Range',
                    daysUntilTaskDue: 'Days Until Task Due',
                    isOverdue: 'Is Overdue',
                },
                dateOptions: {
                    createdDate: 'Task Created Date',
                    updatedDate: 'Task Modified Date',
                    dueDate: 'Task Due Date',
                    statusDate: 'Task Completed Date',
                },
                tooltips: {
                    daysUntilTaskDue: `Results will include tasks due within the inputted value for days. Filter cannot be combined with the 'Is Overdue' and Date Range filter`,
                    isOverdue: `Results will include only tasks that are not completed within the task's due date. Filter cannot be combined with the 'Days Until Due' and Date Range filter`,
                },
                saveSearch: 'Save Search',
                unsaveSearch: 'Unsave Search',
                clear: 'Clear Filters',
                apply: 'Apply',
            },
        },
        cases: {
            CaseTasks: {
                newTask: 'Create New Task',
                taskUpdates: {
                    dateAdded: 'Date / Time Added',
                    creator: 'Update Creator',
                    note: 'Update Note',
                },
            },
        },
    },
    filterableList: {
        clearButtonText: 'Clear',
    },
    quickSearch: {
        core: {
            elasticSearchTypeHeader: {
                [ElasticSearchTypeEnum.ATTACHMENTS.name]: 'Attachments',
                [ElasticSearchTypeEnum.CAD_TICKET.name]: (cadDisplayName: string) =>
                    `${cadDisplayName} Events`,
                [ElasticSearchTypeEnum.CASE.name]: (caseDisplayName: string) =>
                    `${caseDisplayName}`,
                [ElasticSearchTypeEnum.COURT_ORDERS.name]: 'Court Orders',
                [ElasticSearchTypeEnum.ORGANIZATION.name]: (organizationDisplayName: string) =>
                    `${organizationDisplayName}`,
                [ElasticSearchTypeEnum.PERSON.name]: 'Persons',
                [ElasticSearchTypeEnum.PROPERTY.name]: 'Property',
                [ElasticSearchTypeEnum.REPORT.name]: 'Reports',
                [ElasticSearchTypeEnum.VEHICLE.name]: 'Vehicles',
                [ElasticSearchTypeEnum.WARRANT.name]: 'Warrants',
                [EntityTypeEnum.BOOKING.name]: 'Booking',
            },
        },
        generic: {
            title: 'Search',
            advancedSearch: 'Advanced search',
            hideExternalResults: 'Hide External Agency Results',
        },
        QuickSearchInput: {
            placeholder: 'Search reports, persons, property...',
        },
        QuickSearchResults: {
            queryTooLong: 'Search query is too long - 200 characters maximum',
            noResults: 'No results found',
            searching: 'Searching...',
            viewAll: 'View all results',
            error: 'A server request error occurred. Your search could not be completed.',
            showMore: 'Show more',
        },
        QuickSearchResultsSection: {
            loadMore: (num: number, type: string) => `Load ${num} more ${type}`,
            detaineeName: (lastName: string, firstName: string) =>
                `Detainee: ${lastName}, ${firstName}`,
        },
        RestrictedAccessNotice: {
            restricedAccess: 'Restricted Access',
        },
        booking: {
            bookingTitle: (bookingNumber: string) => `Booking #${bookingNumber}`,
        },
    },
    attachments: {
        core: {
            attachmentViewToggleButton: {
                gridView: 'Grid View',
                listView: 'List View',
            },
            bulkDownloadTitle: (entityTitle: string) => `${entityTitle} Attachments.zip`,
        },
        actions: {
            email: 'Email Attachment',
            download: 'Download Attachment',
        },
        upload: {
            uploadFailed: 'Upload Failed',
            uploadFailedDenied: 'File blocked for security reasons',
            uploadFailedDueToFileSize: 'File exceeds file size limit of 3GB',
            uploadFailedRetryOrContactSupport: 'Upload failed. Retry or contact Mark43 support.',
            InfectedFilesModal: {
                title: 'Security Alert',
                okText: 'Confirm',
                errorMessage:
                    'The following files were found to be potentially malicious and the upload was canceled. Contact your IT admin.',
            },
        },
    },
    records: {
        associatedRecords: {
            title: 'Associated Records',
            button: 'Add/Edit Associated Records',
            suggestedAssociations: 'Suggested Associations',
            sidePanel: {
                recordIdInput: 'Record ID',
                title: 'Add/Edit Associated Records',
                placeholder: ({
                    globalSequenceNumberLabel,
                }: {
                    globalSequenceNumberLabel: string;
                }) => `Search records by ${globalSequenceNumberLabel} or enter external record ID`,
                helpText: ({
                    renDisplayName,
                    currentRen,
                }: {
                    renDisplayName: string;
                    currentRen: string;
                }) =>
                    `Records in ${renDisplayName} ${currentRen} will not appear in this search because they are already associated with this report. They can be found on the report in the "${renDisplayName} Records" section`,

                addButtonText: ({ searchValue }: { searchValue: string }) =>
                    `Associate External Record ID: ${searchValue}`,
                saveText: 'Save & Close',
                cancelText: 'Cancel',
            },
            associatedBy: 'Associated By',
            add: 'Add',
        },
        core: {
            RoutingLabelsModal: {
                labels: {
                    okText: 'Save',
                    formTitle: 'Add/Edit Labels',
                    modalTitle: 'Edit Labels',
                },
            },
            sidebar: {
                LinkedTasksSection: {
                    title: 'Linked Tasks',
                    noResults: 'No Tasks linked to this Report',
                    loadingError: 'Failed to load tasks',
                },
                AttachmentsSection: {
                    title: 'Attachments',
                    noResults: 'No Linked Attachments',
                },
            },
        },
    },
    personalDashboard: {
        error: 'Could not load dashboard, please check your connection and try again',
        settings: 'Dashboard Settings',
        saveLayout: 'Save Layout',
        sidePanel: {
            sidebar: 'Sidebar',
            cards: 'Cards',
            show: 'Show',
            hide: 'Hide',
        },
        RecentlyViewed: {
            title: 'Recently Viewed',
            noResults: 'No Recent History',
            loadError: 'Failed to load data',
        },
        RecentSavedSearches: {
            title: 'Recent Saved Searches',
            noResults: 'No Saved Searches',
            loadError: 'Failed to load saved searches',
            searchTypes: {
                [ElasticSearchTypeEnum.CAD_TICKET.name]: 'Advanced Search - CAD Events',
                [ElasticSearchTypeEnum.CASE.name]: (caseModuleName: string) =>
                    `All ${caseModuleName}`,
                [ElasticSearchTypeEnum.CASE_ASSIGNED_TO_ME.name]: (caseModuleName: string) =>
                    `My ${caseModuleName}`,
                [ElasticSearchTypeEnum.EVIDENCE_ITEM.name]: (evidenceModuleName: string) =>
                    `${prettify(evidenceModuleName)}`,
                [ElasticSearchTypeEnum.ORGANIZATION.name]: (organization: string) =>
                    `Advanced Search - ${pluralize(organization)}`,
                [ElasticSearchTypeEnum.PERSON.name]: 'Advanced Search - Persons',
                [ElasticSearchTypeEnum.PROPERTY.name]: 'Advanced Search - Property',
                [ElasticSearchTypeEnum.REPORT.name]: 'Advanced Search - Reports',
                [ElasticSearchTypeEnum.REPORT_NOT_ASSIGNED_TO_CASE.name]: 'Unassigned Reports',
                [ElasticSearchTypeEnum.REPORTS_DASHBOARD.name]: 'Reports',
                [ElasticSearchTypeEnum.USER.name]: 'Users',
                [ElasticSearchTypeEnum.VEHICLE.name]: 'Advanced Search - Vehicles',
                [ElasticSearchTypeEnum.WARRANT.name]: 'Warrants',
            },
        },
        RecentAlerts: {
            title: 'Recent Alerts',
            viewAll: 'View All',
            noResults: 'No Recent Alerts',
            loadError: 'Failed to load alerts',
            attachmentsNumber: (n: number) => `(${n})`,
        },
        actionRequiredReports: {
            title: 'Action Required Reports',
            helpText: 'Reports requiring personal action from you in a timely manner',
            reportLongTitle: 'Report Details',
            updatedDateUtc: 'Modified Date',
            approvalStatus: 'Status',
            showMore: 'Show More',
            viewAll: 'View All',
            noResults: 'No Action Required Reports',
            loadError: 'Failed to load reports',
        },
        recentArrests: {
            title: 'Recent Arrests',
            restrictedAccess: 'Restricted Access',
            helpText: 'Recent department-wide arrests.',
            showMore: 'Show More',
            noResults: 'No Recent Arrests',
            loadError: 'Failed to load arrests',
        },
        filters: {
            button: 'Filter',
            title: 'Filters',
            primaryAgency: 'Primary Agency',
            submit: 'Apply',
            cancel: 'Cancel',
        },
        ExternalLinks: {
            title: 'External Links',
            noResults: 'No External Links',
            loadError: 'Failed to load data',
        },
        MyCadEvents: {
            title: (cadDisplayName: string) => `My ${cadDisplayName} Events`,
            helpText: 'Your CAD events from the past 7 days where you did not author a report',
            showMore: 'Show More',
            fullAddress: 'Location',
            createdDateUtc: 'Event Date',
            viewAll: 'View All',
            noResults: 'No CAD Events',
            loadError: 'Failed to load CAD Events',
        },
        MyTasks: {
            loadError: 'Failed to load Tasks',
            noResults: 'No Tasks',
            showMore: 'Show More',
            title: 'Tasks Assigned To Me And My Role',
            viewAll: 'View All',
        },
        ItemsPendingReview: {
            helpText: (reportDefinitionName: string) =>
                `${reportDefinitionName} reports with items pending your disposition review`,
            showMore: 'Show More',
            reportLongTitle: 'Report Details',
            responsibleParty: 'Responsible Party',
            createdDateUtc: 'Event Date',
            itemCount: 'Items',
            viewAll: 'View All',
            noResults: 'No Items Pending Review',
            loadError: 'Failed to load items',
            assignedInvestigator: (responsibleParty: string) =>
                `Assigned Investigator: ${responsibleParty}`,
            assignedUnit: (responsibleParty: string) => `Assigned Unit: ${responsibleParty}`,
            primaryOfficer: (responsibleParty: string) =>
                `Primary Reporting Officer: ${responsibleParty}`,
        },
        MyCases: {
            title: (caseDisplayName: string) => `My ${caseDisplayName}`,
            loadError: (displayFieldName: string) => `Failed to load ${displayFieldName}`,
            noResults: (caseDisplayName: string) => `No ${caseDisplayName}`,
            showMore: 'Show More',
            viewAll: 'View All',
            caseNumberCaseName: (caseDisplayName: string) =>
                `${caseDisplayName} # - ${caseDisplayName} Name`,
            yourInvolvement: 'Your Involvement',
            caseStatus: (caseDisplayName: string) => `${caseDisplayName} Status`,
            approvalStatus: 'Apv Status',
        },
        RecentWarrants: {
            title: 'Recent Warrants',
            viewAll: 'View All',
            loadError: 'Failed to Load Recent Warrants',
            noResults: 'No Recent Warrants',
            showMore: 'Show More',
            helpText: 'Most recent warrants',
        },
        Insights: {
            title: 'Insights',
            loadError: 'Failed to load Insights',
        },
        MyWarrants: {
            title: 'My Warrants',
            viewAll: 'View All',
            loadError: 'Failed to load my warrants',
            noResults: 'No warrants',
            showMore: 'Show More',
            helpText: 'Warrants created by user',
        },
        MyBriefings: {
            title: 'My Briefings',
            viewAll: 'View All',
            openBriefing: 'Open Briefing',
            loadError: 'Failed to load my briefings',
            noResults: 'No Briefings',
        },
        TargetProfiles: {
            title: (targetProfileDisplayName: string) => `My ${targetProfileDisplayName}`,
            loadError: (targetProfileDisplayName: string) =>
                `Failed to load ${targetProfileDisplayName}`,
            noResults: (targetProfileDisplayName: string) => `No ${targetProfileDisplayName}`,
            showMore: 'Show More',
            viewAll: 'View All',
            area: 'Area(s)',
            category: 'Category(s)',
            caseNumberCaseName: (caseDisplayName: string) => `${caseDisplayName} # - Name`,
        },
    },
    notepad: {
        title: 'Mobile',
        noCollections: 'No Collections',
        noArchivedCollections: 'No Archived Collections',
        menu: {
            // keyed by route paths in kebab-case
            collections: 'My Collections',
            archive: 'Archive',
        },
        collection: {
            newReport: 'New Report',
            createdOn: 'Created On',
            notes: 'Notes',
            photos: 'Photos & Videos',
            persons: 'Persons',
            locations: 'Locations',
        },
        sidePanel: {
            personsTitle: 'Persons in Mobile',
            locationsTitle: 'Locations in Mobile',
            personName: 'person',
            locationName: 'location',
            filterPersons: 'Filter by Name',
            filterLocations: 'Filter by Address',
        },
        pill: {
            label: (entityType: string) =>
                `Select ${entityType === EntityTypeEnum.PERSON_PROFILE.name ? 'person' : 'location'}
                 from Mobile collections`,
        },
    },
    errorPage: {
        support:
            'If you believe you are receiving this in error, have questions, or need technical assistance, please contact support@mark43.com.',
        goBackLink: 'Go back',
    },
    nameReportLinks: {
        nameFormat: {
            lastFirstMiddle: 'Name (Last, First Middle)',
            name: 'Name',
            nameAndId: 'Name and ID#',
        },
    },
    approvalStatuses: {
        draft: 'Draft',
        rejected: 'Rejected',
        submitted: 'Submitted & Pending Approval',
        approvedBySupervisor: 'Approved by Supervisor',
        approved: 'Approved',
    },
    keyboardShortcutsSidePanel: {
        title: 'List of Shortcut Keys',
        cancelText: 'Close',
    },
    quickCrashLinkCard: {
        crashReportCreatedWith: 'Crash report created with',
        editReport: 'Edit Report',
        viewReport: 'View Report',
    },
    trafficCrashSummary: {
        customFields: 'Custom Fields',
        otherFactors: 'Other Factors',
        roadway: 'Roadway',
        exitRamp: 'Exit Ramp',
        exitRampDirection: 'Direction from Exit Ramp',
        exitRampDistanceUnits: 'Exit Ramp Distance Units',
        exitRampDistance: 'Exit Ramp Distance',
        milepostDirection: 'Milepost Direction',
        conditions: 'Conditions',
        events: 'Events',
        crashClassificationOwnershipAttrId: 'Crash Classification Ownership',
        crashClassificationCharacteristicsAttrId: 'Crash Classification Characteristics',
        crashClassificationSecondaryCrashAttrId: 'Crash Classification Secondary Crash',
        crashDateAndTimeUtc: 'Crash Date/Time',
        roadwayClearanceDateAndTimeUtc: 'Roadway Clearance Date/Time',
        firstHarmfulEventAttrId: 'First Harmful Event',
        locationOfFirstHarmfulEventAttrId: 'Location Of First Harmful Event',
        mannerOfCrashAttrId: 'Manner Of Crash',
        lightConditionAttrId: 'Light Condition',
        roadwaySurfaceConditionAttrId: 'Roadway Surface Condition',
        junctionWithinInterchangeAreaAttrId: 'Junction Within Interchange Area',
        junctionSpecificLocationAttrId: 'Junction Specific Location',
        intersectionNumApproachesAttrId: 'Intersection Number Approaches',
        intersectionOverallGeometryAttrId: 'Overall Geometry',
        intersectionOverallTrafficControlDeviceAttrId: 'Traffic Control Device',
        schoolBusRelatedAttrId: 'School Bus-Related',
        workZoneRelatedAttrId: 'Work Zone-Related',
        workZoneLocationOfCrashAttrId: 'Work Zone Location Of Crash',
        workZoneTypeAttrId: 'Work Zone Type',
        workZoneWorkersPresentAttrId: 'Workers Present',
        workZoneLawEnforcementPresentAttrId: 'Law Enforcement Present',
        crashSeverityAttrId: 'Crash Severity',
        numVehicles: 'Number of Vehicles',
        numMotorists: 'Number of Motorists',
        numNonMotorists: 'Number of Non-Motorists',
        numNonFatallyInjuredPersons: 'Number of Non-Fatally Injured Persons',
        numFatalities: 'Number of Fatalities',
        alcoholInvolvementAttrId: 'Alcohol Involvement',
        drugInvolvementAttrId: 'Drug Involvement',
        postedSpeedLimit: 'Posted Speed Limit',
        hasPropertyDamage: 'Has Property Damage?',
        trafficControlInoperativeMissing: 'Are any Inoperative or Missing?',
        trafficControlDeviceType: 'Traffic Control Device Type',
        weather: 'Weather Conditions',
        contributingCircumstances: 'Contributing Circumstances',
        attachments: 'Attachments',
        landmarkLocation: 'Landmark Location',
        landmarkIntersectingRoadwayRouteNumber: 'Landmark Intersecting Route #',
        landmarkIntersectingRoadway: 'Landmark Intersection',
        crashRoadwayName: 'Roadway Name',
        intersectingRoadwayName: 'Intersecting Roadway Name',
        milepostLocation: 'Milepost Location',
        milepostRoadwayDirectionAttrId: 'Milepost Roadway Direction',
        milepostExitNumber: 'Milepost Exit #',
        milepostRouteNumber: 'Milepost Route #',
        routeNumber: 'Roadway Route #',
        roadwayName: 'Roadway Name',
        roadwayDirection: 'Roadway Direction',
        intersectionDetailsRoadwayRouteNumber: 'Intersecting Roadway 1 Route #',
        intersectingRoadway1: 'Intersecting Roadway 1',
        additionalIntersectionRoadwayRouteNumber: 'Intersecting Roadway 2 Route #',
        intersectingRoadway2: 'Intersecting Roadway 2',
        reportingLawEnforcementAgencyIdentifier: 'Law Enforcement Agency Identifier',
        reportingDistrict: 'Reporting District',
        judicialDistrict: 'Judicial District',
    },
    trafficCrashPersonSummary: {
        age: 'Age',
        personTypeAttrId: 'Person Type',
        incidentResponderAttrId: 'Incident Responder',
        vehicleUnitNumber: 'Vehicle Unit Number',
        restraintsAndHelmetsAttrId: 'Restraints And Helmets',
        restraintSystemsImproperUseAttrId: 'Restraint Systems Improper Use',
        ejectionAttrId: 'Ejection',
        driverLicenseJurisdictionAttrId: 'License Jurisdiction',
        driverLicenseJurisdictionName: 'License Jurisdiction Name',
        driverLicenseClassAttrId: 'License Class',
        driverLicenseCdlAttrId: 'License CDL',
        speedingRelatedAttrId: 'Speeding Related',
        offenseCodes: (offenseDisplayName: string) => `${offenseDisplayName} Codes`,
        alcoholInterlockPresentAttrId: 'Alcohol Interlock Present',
        distractedByAttrId: 'Distracted By',
        distractedBySourceAttrId: 'Distracted By Source',
        lawEnforcementSuspectsAlcoholUseAttrId: 'Law Enforcement Suspects Alcohol Use',
        alcoholTestStatusAttrId: 'Alcohol Test Status',
        alcoholTestTypeAttrId: 'Alcohol Test Type',
        alcoholTestResultAttrId: 'Alcohol Test Result',
        lawEnforcementSuspectsDrugUseAttrId: 'Law Enforcement Suspects Drug Use',
        drugTestStatusAttrId: 'Drug Test Status',
        drugTestTypeAttrId: 'Drug Test Type',
        medicalTransportSourceAttrId: 'Medical Transport Source',
        medicalTransportSourceAgencyId: 'Medical Transport Source Agency',
        medicalTransportEmsResponseRunNumber: 'Medical Transport Ems Response Run Number',
        medicalTransportFacility: 'Medical Transport Facility',
        injuryDiagnosis: 'Injury Diagnosis',
        injurySeverityAttrId: 'Injury Severity',
        attemptedAvoidanceManeuverAttrId: 'Attempted Avoidance Maneuver',
        fatalAlcoholTestTypeAttrId: 'Fatal Alcohol Test Type',
        fatalAlcoholTestResults: 'Fatal Alcohol Test Results',
        fatalDrugTestTypeAttrId: 'Fatal Drug Test Type',
        fatalDrugTestResults: 'Fatal Drug Test Results',
        unitNumOfMotorVehicleStrikingNonMotorist: 'Unit Num Of Motor Vehicle Striking Non Motorist',
        nonMotoristActionPriorToCrashAttrId: 'Non Motorist Action Prior To Crash',
        nonMotoristOriginOrDestinationAttrId: 'Non Motorist Origin Or Destination',
        nonMotoristContributingActionsAttrId: 'Non Motorist Contributing Actions',
        nonMotoristLocationAtTimeOfCrashAttrId: 'Non Motorist Location At Time Of Crash',
        initialContactPointOnNonMotoristAttrId: 'Initial Contact Point On Non Motorist',
        dateOfLicenseExpiration: 'License Expiration Date',
        seatingPositionAttrId: 'Seating Position',
        airbagDeployed: 'Airbag Deployed',
        driverActions: 'Driver Actions',
        driverLicenseRestrictions: 'License Restrictions',
        conditions: 'Conditions',
        drugTestResult: 'Drug Test Result',
        nonMotoristSafetyEquipment: 'Non-Motorist Safety Equipment',
        cmvLicenseStatusAttrId: 'CMV License Status',
        cmvLicenseComplianceAttrId: 'CMV License Compliance',
    },
    trafficCrashVehicleSummary: {
        title: 'Vehicles',
        unitTypeAttrId: 'Unit Type',
        unitNumber: 'Unit Number',
        registrationStateOrCountry: 'Registration State or Country',
        bodyTypeCategoryAttrId: 'Body Type Category',
        numTrailingUnitsAttrId: 'Num Trailing Units',
        vehicleSizeAttrId: 'Vehicle Size',
        hasHmPlacardAttrId: 'Has Hazardous Materials Placard',
        totalOccupantsInMotorVehicle: 'Total Occupants In Motor Vehicle',
        specialFunctionAttrId: 'Special Function',
        emergencyMotorVehicleUseAttrId: 'Emergency Motor Vehicle Use',
        postedSpeedLimit: 'Posted Speed Limit',
        directionOfTravelBeforeCrashAttrId: 'Direction Of Travel Before Crash',
        trafficwayTravelDirectionsAttrId: 'Trafficway Travel Directions',
        trafficwayDividedAttrId: 'Trafficway Divided',
        trafficwayBarrierTypeAttrId: 'Trafficway Barrier Type',
        trafficwayHovHotLanesAttrId: 'Trafficway Hov Hot Lanes',
        crashRelatedToHovHotLanesAttrId: 'Crash Related To Hov Hot Lanes',
        totalThroughLanes: 'Total Through Lanes',
        totalAuxLanes: 'Total Aux Lanes',
        roadwayHorizontalAlignmentAttrId: 'Roadway Horizontal Alignment',
        roadwayGradeAttrId: 'Roadway Grade',
        maneuverAttrId: 'Maneuver',
        initialPointOfContactAttrId: 'Initial Point Of Contact',
        resultingExtentOfDamageAttrId: 'Resulting Extent Of Damage',
        mostHarmfulEventAttrId: 'Most Harmful Event',
        hitAndRunAttrId: 'Hit and Run',
        towedDueToDisablingDamageAttrId: 'Towed Due To Disabling Damage',
        contributingCircumstancesAttrId: 'Contributing Circumstances',
        firstTrailerTitle: 'First Trailer',
        secondTrailerTitle: 'Second Trailer',
        thirdTrailerTitle: 'Third Trailer',
        motorCarrierIdentificationTypeAttrId: 'Motor Carrier Identification Type',
        motorCarrierIdentificationCountryOrStateCode:
            'Motor Carrier Identification Country Or State Code',
        motorCarrierIdentificationNumber: 'Motor Carrier Identification Number',
        motorCarrierIdentificationName: 'Motor Carrier Identification Name',
        motorCarrierAddressLocationId: 'Motor Carrier Address Location ID',
        motorCarrierIdentificationCarrierTypeAttrId: 'Motor Carrier Identification Carrier Type',
        vehicleConfigurationAttrId: 'Vehicle Configuration',
        vehicleConfigurationPermittedAttrId: 'Vehicle Configuration Permitted',
        cargoBodyTypeAttrId: 'Cargo Body Type',
        hazardousMaterialsId: 'Hazardous Materials ID',
        hazardousMaterialsClass: 'Hazardous Materials Class',
        hazardousMaterialsReleasedAttrId: 'Hazardous Materials Released',
        totalNumOfAxlesTruckTractor: 'Num Axles Truck Tractor',
        motorVehicleAutomatedDrivingSystemsAttrId: 'Motor Vehicle Automated Driving Systems',
        roadwayName: 'Roadway Name',
        hazardousMaterialsInvolved: 'Hazardous Materials Involved?',
        trafficControlsType: 'Traffic Controls Type',
        trafficControlsInoperativeMissing: 'Traffic Controls Inoperative/Missing',
        locationOfDamagedAreas: 'Location of Damaged Areas',
        sequenceOfEvents: 'Sequence of Events',
        vehicleConfigSpecialSizing: 'Vehicle Config Special Sizing',
        automatedDrivingSystemsLevel: 'Automated Driving Systems Level',
        automatedDrivingSystemsEngaged: 'Automated Driving Systems Engaged',
    },
    trafficCrashTrailerSummary: {
        vin: 'VIN',
        numAxels: 'Number of Axels',
        licensePlateNum: 'License Plate',
    },
    trafficCrashRoadwaySummary: {
        title: 'Roadways',
        roadwayDirectionAttrId: 'Direction',
        roadwayName: 'Name',
        structureIdentificationNumber: 'Structure ID Number',
        roadwayCurvatureRadius: 'Curvature Radius',
        roadwayCurvatureLength: 'Curvature Length',
        roadwayCurvatureElevation: 'Curvature Elevation',
        gradeDirectionOfSlopeAttrId: 'Grade Direction Of Slope',
        gradePercentOfSlope: 'Grade Percent Of Slope',
        partOfNationalHighwaySystemAttrId: 'Part Of National Highway System?',
        roadwayFunctionalClassAttrId: 'Functional Class',
        annualAverageDailyTrafficYear: 'Annual Average Daily Traffic Year',
        annualAverageDailyTraffic: 'Annual Average Daily Traffic',
        annualAverageDailyTrafficTruckCount: 'Annual Average Daily Traffic Truck Count',
        annualAverageDailyTrafficMotorcycleCount: 'Annual Average Daily Traffic Motorcycle Count',
        laneWidth: 'Lane Width',
        leftShoulderWidth: 'Left Shoulder Width',
        rightShoulderWidth: 'Right Shoulder Width',
        widthOfMedian: 'Width Of Median',
        accessControlAttrId: 'Access Control',
        railwayCrossingId: 'Railway Crossing ID',
        roadwayLightingAttrId: 'Lighting',
        longitudinalEdgelineTypeAttrId: 'Longitudinal Edgeline Type',
        longitudinalCenterlineTypeAttrId: 'Longitudinal Centerline Type',
        longitudinalLaneLineMarkingsAttrId: 'Longitudinal Lane Line Markings',
        typeOfBicycleFacilityAttrId: 'Type Of Bicycle Facility',
        signedBicycleRouteAttrId: 'Signed Bicycle Route',
        mainlineNumLanesAtIntersectionAttrId: 'Mainline Num Lanes At Intersection',
        crossStreetNumLanesAtIntersectionAttrId: 'Cross Street Num Lanes At Intersection',
        totalVolumeOfEnteringVehiclesAadtYear: 'Total Volume Of Entering Vehicles AADT Year',
        totalVolumeOfEnteringVehiclesAadt: 'Total Volume Of Entering Vehicles AADT',
    },
} as const;
