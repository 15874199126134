/* eslint-disable no-console */
import * as Sentry from '@sentry/browser';

const NODE_ENV = process.env.NODE_ENV;

export const logWarning = (
    msg: string,
    extras: { [index: string]: unknown; [index: number]: unknown } = {},
    tags: { [index: string]: string } = {}
) => {
    // log warning to sentry
    Sentry.withScope((scope) => {
        scope.setLevel(Sentry.Severity.Warning);
        scope.setExtras(extras);
        scope.setTags(tags);
        Sentry.captureMessage(msg);
    });
    if (NODE_ENV !== 'production') {
        console.warn(msg, extras);
    }
};

export const logError = (
    msg: string,
    extras: { [index: string]: unknown; [index: number]: unknown } = {}
) => {
    // log error to sentry
    Sentry.withScope((scope) => {
        scope.setLevel(Sentry.Severity.Error);
        scope.setExtras(extras);
        Sentry.captureMessage(msg);
    });
    if (NODE_ENV !== 'production') {
        console.error(msg, extras);
    }
};
