import { EditorEvent } from "tinymce"

const handleWidthChange = (imgElement: HTMLImageElement) => {
    const maximumWidth = 725
    const { width, height } = imgElement
    
    if(width <= maximumWidth) {
        return
    }

    const widthRatio = maximumWidth / width
    const newHeight = Math.floor(height * widthRatio)

    imgElement.setAttribute('width', maximumWidth.toString());
    imgElement.setAttribute('height', newHeight.toString())
}

const setWidthAndHeightAttributes = (imgElement: HTMLImageElement) => {
    imgElement.setAttribute('width', imgElement.width.toString())
    imgElement.setAttribute('height', imgElement.height.toString())
}

/**
 * The width of any image inserted on the editor must fit the size of a PDF page so it does not overflow when a pdf is generated using the editor's content.
 * This function checks if an image was changed, and resizes the width if needed, keeping aspect ratio.
 * @param e the editor event
 */
const handleImageWidth = (e: EditorEvent<{element: Element}>) => {
    if(e.element.tagName !== 'IMG') {
        return
    }

    const imgElement = e.element as HTMLImageElement
    const { width } = imgElement

    if(width === 0 && !imgElement.complete) {
        imgElement.onload = (e) => {
            const element = e.target as HTMLImageElement
            setWidthAndHeightAttributes(element)
            handleWidthChange(element)
        }
    }
    else {
        handleWidthChange(imgElement) 
    }

}

export default handleImageWidth