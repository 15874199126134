import { EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import { compact, isArray } from 'lodash';
import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import React, { useRef } from 'react';

import styled from 'styled-components';
import getIdFormatConfigurationResource from '~/client-common/core/domain/id-format-configurations/resources/idFormatConfigurationResource';
import useFields, { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { reportDefinitionByIdSelector } from '~/client-common/core/domain/report-definitions/state/data';
import { idFormatConfigurationToRecordSequenceType } from '~/client-common/core/domain/id-format-configurations/state/ui';
import {
    REPORT_REPORTING_EVENT_NUMBER,
    REPORT_RECORD_NUMBER,
    DISPLAY_ONLY_PERSONNEL_REPORT_OWNER_LABEL,
    SECURITY_CLASSIFICATION_SECURITY_CLASSIFICATION_ATTR_ID,
} from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import recordSequenceTypeEnum from '~/client-common/core/enums/client/recordSequenceTypeEnum';
import keyCodeEnum from '~/client-common/core/enums/client/keyCodeEnum';
import getReportResource from '~/client-common/core/domain/reports/resources/reportResource';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';

import testIds from '../../../../../core/testIds';
import AsyncRecordsHeaderHamburgerMenu from '../../../../records/core/components/header/AsyncRecordsHeaderHamburgerMenu';
import Link from '../../../../core/components/links/Link';
import { openCaseStatusesSidePanel } from '../../state/ui/caseStatuses';
import { openCreateCaseModal } from '../../../../cases/core/state/ui';
import {
    openReportChangeOwnerModal,
    openSendArrestToBookingModal,
    openReturnReportToDraftModal,
} from '../../state/ui';
import { openRenChangeModal } from '../../../../../legacy-redux/actions/renChangeActions';
import { openRecordNumberChangeModal } from '../../../../../legacy-redux/actions/recordNumberChangeActions';
import { openTaskSidePanelForEntity } from '../../../../tasks/core/state/ui';
import {
    openArrestUcrClassificationSidePanel,
    openUcrClassificationSidePanel,
} from '../../../ucr-classification/state/ui';
import AttachmentsSidePanel from '../../../../attachments/core/components/AttachmentsSidePanel';
import { initializeAttachmentsSidePanelForm } from '../../../../attachments/core/state/ui/attachmentsSidePanel';
import { openAssociatedRecordsSidePanel } from '../../../../records/associated-records/state/ui';
import TaskSidePanel from '../../../../tasks/core/components/TaskSidePanel';
import OverlayButton from '../../../../core/overlays/components/OverlayButton';
import { EFileCreationModal, useEFileDetailsModal, useEFileDisplayName } from '../../../../e-files';
import { useSecurityClassificationModals } from '../../../../security-classification/hooks/useSecurityClassificationModals';
import { EditSecurityClassificationModal } from '../../../../security-classification/components/EditSecurityClassificationModal';
import { openGangTrackingSidePanel } from '../../../gang-tracking/state/ui/gangTrackingSidePanel';
import openArrestChargesSidePanel from '../../state/ui/arrest-charges-side-panel/openArrestChargesSidePanel';
import { openTowVehicleCheckInSidePanel } from '../../state/ui/tow-vehicle-check-in-side-panel/towVehicleCheckInSidePanel';
import { openTowVehicleReleaseSidePanel } from '../../state/ui/tow-vehicle-release-side-panel/towVehicleReleaseSidePanel';
import {
    openCreateCaseFromReportsModal,
    openReasonForRelationSidePanel,
} from '../../../../cases/core/state/ui/openReasonForRelationSidePanel';
import ArrestChargesSidePanel from '../arrest-charges-side-panel/ArrestChargesSidePanel';
import TowVehicleCheckInSidePanel from '../tow-vehicle-check-in/TowVehicleCheckInSidePanel';
import TowVehicleReleaseSidePanel from '../tow-vehicle-release/TowVehicleReleaseSidePanel';
import CreateManageCaseModal from '../../../../cases/core/components/CreateManageCaseModal';
import ReasonForRelationSidePanel from '../../../../cases/core/components/ReasonForRelationSidePanel';
import IndividualReportSelectionSidePanel from '../../../../cases/core/components/IndividualReportSelectionSidePanel';
import CreateCaseFromMultiReportsModal from '../../../../cases/unassigned-reports/components/CreateCaseFromMultiReportsModal';

// Submissions
import { CreateSubmissionsModal, useGenericSubmissionsModals } from '../../../../submissions';

import isRecordWithoutRen from '../../utils/isRecordWithoutRen';
import { relatedReportEntitiesSuccess } from '../../state/data/reportRouting';
import reportsResource from '../../resources/reportsResource';
import DeleteReportModal, { useOpenDeleteReportModal } from './DeleteReportModal';
import RemoveRenModal from './RemoveRenModal';

const strings = componentStrings.reports.core.ReportHeaderHamburgerMenu;

const Section = styled(({ className, children }) => {
    if (!children || (isArray(children) && compact(children).length === 0)) {
        return null;
    }
    return <div className={className}>{children}</div>;
})`
    border-bottom: 2px solid ${(props) => props.theme.colors.lightGrey};
    padding: 8px 0;

    &:empty {
        border: 0;
        padding: 0;
    }
`;

const Menu = styled.div`
    ${/* sc-selector */ Section}:first-of-type {
        padding-top: 0;
        padding-bottom: 8px;
    }

    ${/* sc-selector */ Section}:last-of-type {
        border-bottom: none;
        padding-top: 8px;
        padding-bottom: 0;
    }
`;

const DropdownMenuOptionWithClose = ({ onClick, linkTo, title, testId, setRef, closeMenu }) => {
    return !!linkTo ? (
        <Link className="dropdown-menu-option" to={linkTo}>
            {title}
        </Link>
    ) : (
        <div
            className="dropdown-menu-option"
            onClick={onClick}
            onKeyDown={(e) => {
                if (e.keyCode === keyCodeEnum.ENTER) {
                    if (onClick) {
                        onClick();
                    }
                    closeMenu();
                }
            }}
            data-test-id={testId}
            tabIndex={0}
            ref={setRef}
        >
            {title}
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    applicationSettings: applicationSettingsSelector,
    reportDefinitionById: reportDefinitionByIdSelector,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    openCaseStatusesSidePanel: (reportId, reportingEventId) =>
        dispatch(openCaseStatusesSidePanel(reportId, reportingEventId)),
    openCreateCaseModal: (options) => dispatch(openCreateCaseModal(options)),
    openCreateCaseFromReportsModal: (reports) => {
        dispatch(
            openCreateCaseFromReportsModal(overlayIdEnum.CREATE_CASE_FROM_REPORT_MODAL, reports)
        );
    },
    openTowVehicleCheckInSidePanel: () =>
        dispatch(openTowVehicleCheckInSidePanel({ reportId: ownProps.currentReport.id })),
    openTowVehicleReleaseSidePanel: () =>
        dispatch(openTowVehicleReleaseSidePanel({ reportId: ownProps.currentReport.id })),
    openRenChangeModal: () => dispatch(openRenChangeModal()),
    openRecordNumberChangeModal: (reportDefinition) => {
        if (reportDefinition.idFormatConfigurationId) {
            return getIdFormatConfigurationResource()
                .getIdFormatConfigurationById(reportDefinition.idFormatConfigurationId)
                .then((idFormatConfiguration) => {
                    const recordSequenceType = idFormatConfigurationToRecordSequenceType(
                        idFormatConfiguration,
                        reportDefinition.isAutogenerated
                    );
                    dispatch(openRecordNumberChangeModal(reportDefinition, recordSequenceType));
                });
        } else {
            return dispatch(
                openRecordNumberChangeModal(reportDefinition, recordSequenceTypeEnum.FREE_TEXT)
            );
        }
    },
    openArrestUcrClassificationSidePanel: () => dispatch(openArrestUcrClassificationSidePanel()),
    openUcrClassificationSidePanel: () => dispatch(openUcrClassificationSidePanel()),
    openReturnReportToDraftModal: (callback) =>
        dispatch(
            openReturnReportToDraftModal({
                callback,
            })
        ),
    openReportChangeOwnerModal: (callback) =>
        dispatch(
            openReportChangeOwnerModal({
                callback,
            })
        ),
    openSendArrestToBookingModal: () =>
        dispatch(openSendArrestToBookingModal(ownProps.currentReport.id)),
    openAssociatedRecordsSidePanel: () =>
        dispatch(openAssociatedRecordsSidePanel(ownProps.currentReport.id)),
    openGangTrackingSidePanel: () => dispatch(openGangTrackingSidePanel(ownProps.currentReport.id)),
    openAttachmentsSidePanel: (attachmentEntityLinkDetails) => () => {
        dispatch(initializeAttachmentsSidePanelForm(attachmentEntityLinkDetails));
        // inline redux-thunk action to access overlayStore
        dispatch((dispatch, getState, { overlayStore }) =>
            overlayStore.open(
                overlayIdEnum.REPORT_HEADER_ATTACHMENTS_SIDE_PANEL,
                attachmentEntityLinkDetails
            )
        );
    },
    openArrestChargesSidePanel: () =>
        dispatch(
            openArrestChargesSidePanel({
                reportId: ownProps.currentReport.id,
            })
        ),
    openTaskSidePanel: () => {
        dispatch(
            openTaskSidePanelForEntity([
                {
                    entityId: ownProps.currentReport.id,
                    entityType: EntityTypeEnum.REPORT.name,
                    entityTitle: ownProps.title,
                },
            ])
        );
    },
    openAddToCasesSidePanel: () => {
        const { currentReport } = ownProps;
        const { id: reportId, reportingEventNumber, recordNumber } = currentReport;

        const onSave = () =>
            reportsResource
                .getReportRelatedEntities(reportId)
                .then((reportRelatedEntities) => {
                    dispatch(relatedReportEntitiesSuccess(reportRelatedEntities));
                })
                .catch((err) => {
                    throw err;
                });

        dispatch(
            openReasonForRelationSidePanel({
                reasonForRelationReports: [
                    {
                        reportingEventNumber,
                        recordNumber,
                        reportIds: [reportId],
                    },
                ],
                onSave,
            })
        );
    },
});

const ReportHeaderHamburgerMenu = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withHandlers({
        openCaseStatusesSidePanel({ currentReport, openCaseStatusesSidePanel }) {
            return () => openCaseStatusesSidePanel(currentReport.id, currentReport.eventId);
        },
        openCreateCaseModal({
            applicationSettings,
            currentReport,
            openCreateCaseModal,
            openCreateCaseFromReportsModal,
        }) {
            return () =>
                applicationSettings.RMS_INDIVIDUAL_REPORT_SELECTION_ENABLED
                    ? openCreateCaseFromReportsModal([currentReport])
                    : openCreateCaseModal(
                          isRecordWithoutRen(currentReport)
                              ? {
                                    recordWithoutRenReportId: currentReport.id,
                                }
                              : {
                                    reportingEventNumber: currentReport.reportingEventNumber,
                                }
                      );
        },
        openReturnReportToDraftModal({ openReturnReportToDraftModal }) {
            return () => openReturnReportToDraftModal(false);
        },
        openReportChangeOwnerModal({ openReportChangeOwnerModal }) {
            return () => openReportChangeOwnerModal(false);
        },
    })
)(function _ReportHeaderHamburgerMenu({
    currentReport,
    reportDefinitionById,
    openCaseStatusesSidePanel,
    openTowVehicleCheckInSidePanel,
    openCreateCaseModal,
    openTowVehicleReleaseSidePanel,
    openRenChangeModal,
    openRecordNumberChangeModal,
    openArrestUcrClassificationSidePanel,
    openUcrClassificationSidePanel,
    openReportChangeOwnerModal,
    openReturnReportToDraftModal,
    openAttachmentsSidePanel,
    openArrestChargesSidePanel,
    openSendArrestToBookingModal,
    openAssociatedRecordsSidePanel,
    openGangTrackingSidePanel,
    openTaskSidePanel,
    openAddToCasesSidePanel,
}) {
    const menuButtonRef = useRef(null);
    const fieldDisplayNames = useFields([
        REPORT_REPORTING_EVENT_NUMBER,
        REPORT_RECORD_NUMBER,
        DISPLAY_ONLY_PERSONNEL_REPORT_OWNER_LABEL,
        SECURITY_CLASSIFICATION_SECURITY_CLASSIFICATION_ATTR_ID,
    ]);
    const renDisplayName = fieldDisplayNames.REPORT_REPORTING_EVENT_NUMBER;
    const recordNumberDisplayName = fieldDisplayNames.REPORT_RECORD_NUMBER;
    const securityClassificationDisplayName =
        fieldDisplayNames.SECURITY_CLASSIFICATION_SECURITY_CLASSIFICATION_ATTR_ID;
    const { singularCaseFieldName: caseFieldName } = useCaseFieldName();
    const { eFileDisplayName } = useEFileDisplayName();
    const reportDefinition = reportDefinitionById(currentReport.reportDefinitionId);
    const attachmentEntityLinkDetails = {
        entityType: EntityTypeEnum.REPORT.name,
        entityId: currentReport.id,
        attachmentLinkType: LinkTypesEnum.REPORT_ATTACHMENT,
    };
    const openDeleteReportModal = useOpenDeleteReportModal({
        overlayId: overlayIdEnum.DELETE_REPORT_FOR_REPORT_HEADER_MODAL,
        reportId: currentReport.id,
    });

    const { openCreateEFile } = useEFileDetailsModal();
    const { openCreateGenericSubmissionsModal } = useGenericSubmissionsModals();
    const { openEditSecurityClassification } = useSecurityClassificationModals();

    return (
        <span>
            <AsyncRecordsHeaderHamburgerMenu
                dropdownMenuOptionWidth={231}
                fetchOptions={() => {
                    return getReportResource().getHamburgerMenuView(currentReport.id);
                }}
                ref={menuButtonRef}
                mapResultOptions={(reportHamburgerMenuView, closeMenu) => {
                    const DropdownMenuOption = (props) => (
                        <DropdownMenuOptionWithClose closeMenu={closeMenu} {...props} />
                    );
                    return (
                        <Menu>
                            <Section>
                                {reportHamburgerMenuView.canReturnToDraft && (
                                    <DropdownMenuOption
                                        onClick={openReturnReportToDraftModal}
                                        title={strings.returnToDraft}
                                        testId={testIds.RETURN_TO_DRAFT}
                                    />
                                )}
                                {reportHamburgerMenuView.canChangeOwner && (
                                    <DropdownMenuOption
                                        onClick={openReportChangeOwnerModal}
                                        title={strings.changeOwner(
                                            fieldDisplayNames.DISPLAY_ONLY_PERSONNEL_REPORT_OWNER_LABEL
                                        )}
                                        testId={testIds.CHANGE_OWNER}
                                    />
                                )}
                                {reportHamburgerMenuView.canChangeRen && (
                                    <DropdownMenuOption
                                        onClick={openRenChangeModal}
                                        title={strings.renChange(renDisplayName)}
                                        testId={testIds.CHANGE_REN}
                                    />
                                )}
                                {reportHamburgerMenuView.canRemoveRen && (
                                    <OverlayButton
                                        children={(openOverlay) => (
                                            <DropdownMenuOption
                                                onClick={openOverlay}
                                                title={strings.removeRen(renDisplayName)}
                                                testId={testIds.REMOVE_REN}
                                            />
                                        )}
                                        id={overlayIdEnum.REMOVE_REN_MODAL}
                                    />
                                )}
                                {reportHamburgerMenuView.canChangeRecordNumber && (
                                    <DropdownMenuOption
                                        onClick={() =>
                                            openRecordNumberChangeModal(reportDefinition)
                                        }
                                        title={strings.recordNumberChange(recordNumberDisplayName)}
                                        testId={testIds.CHANGE_RECORD_NUMBER}
                                    />
                                )}
                                {reportHamburgerMenuView.canEditSecurityClassification && (
                                    <DropdownMenuOption
                                        onClick={() =>
                                            openEditSecurityClassification({
                                                ownerId: currentReport.id,
                                                ownerType: EntityTypeEnum.REPORT.name,
                                            })
                                        }
                                        title={strings.editSecurityClassification(
                                            securityClassificationDisplayName
                                        )}
                                        testId={testIds.EDIT_SECURITY_CLASSIFICATION}
                                    />
                                )}
                                {reportHamburgerMenuView.canMasterEditAttachments && (
                                    <DropdownMenuOption
                                        onClick={openAttachmentsSidePanel(
                                            attachmentEntityLinkDetails
                                        )}
                                        title={strings.editAttachments}
                                        testId={testIds.EDIT_ATTACHMENTS}
                                    />
                                )}
                                {reportHamburgerMenuView.canManageAssociatedRecords && (
                                    <DropdownMenuOption
                                        onClick={openAssociatedRecordsSidePanel}
                                        title={strings.associatedRecords}
                                        testId={testIds.EDIT_ASSOCIATED_RECORDS}
                                    />
                                )}
                                <FeatureFlagged flag="RMS_E_FILING_ENABLED">
                                    {reportHamburgerMenuView.canCreateEFile && (
                                        <DropdownMenuOption
                                            onClick={() =>
                                                openCreateEFile({
                                                    entityId: currentReport.id,
                                                    entityType: EntityTypeEnum.REPORT.name,
                                                })
                                            }
                                            title={strings.createNewEFile(eFileDisplayName)}
                                            testId={testIds.CREATE_E_FILE}
                                        />
                                    )}
                                </FeatureFlagged>
                                <FeatureFlagged flag="RMS_GENERIC_EXTERNAL_SUBMISSIONS_ENABLED">
                                    {reportHamburgerMenuView.canSendSubmissionMessage && (
                                        <DropdownMenuOption
                                            onClick={openCreateGenericSubmissionsModal}
                                            title={strings.createSubmission}
                                            testId={testIds.CREATE_SUBMISSION}
                                        />
                                    )}
                                </FeatureFlagged>
                            </Section>

                            <Section>
                                {reportHamburgerMenuView.canEditCharges && (
                                    <DropdownMenuOption
                                        onClick={() => {
                                            openArrestChargesSidePanel();
                                        }}
                                        title={strings.addCharges}
                                        testId={testIds.EDIT_CHARGES}
                                    />
                                )}
                                {reportHamburgerMenuView.canSendArrestToBooking && (
                                    <DropdownMenuOption
                                        onClick={openSendArrestToBookingModal}
                                        title={strings.sendArrestReportToBooking}
                                        testId={testIds.SEND_TO_BOOKING}
                                    />
                                )}
                                {reportHamburgerMenuView.canEditDetentionDisposition && (
                                    <DropdownMenuOption
                                        linkTo={`/reports/${currentReport.id}/detention-disposition`}
                                        title={strings.detentionDisposition}
                                        testId={testIds.DETENTION_DISPOSITION}
                                    />
                                )}
                                {reportHamburgerMenuView.canEditTowVehicleCheckIn && (
                                    <DropdownMenuOption
                                        onClick={openTowVehicleCheckInSidePanel}
                                        title={strings.towVehicleCheckIn}
                                        testId={testIds.TOW_VEHICLE_CHECK_IN}
                                    />
                                )}
                                {reportHamburgerMenuView.canEditTowVehicleRelease && (
                                    <DropdownMenuOption
                                        onClick={openTowVehicleReleaseSidePanel}
                                        title={strings.towVehicleRelease}
                                        testId={testIds.TOW_VEHICLE_RELEASE}
                                    />
                                )}
                            </Section>

                            <Section>
                                {reportHamburgerMenuView.canEditCaseStatus && (
                                    <DropdownMenuOption
                                        onClick={openCaseStatusesSidePanel}
                                        title={strings.reportCaseStatus}
                                        testId={testIds.EDIT_REPORT_CASE_STATUS}
                                    />
                                )}
                                {reportHamburgerMenuView.canCreateCase && (
                                    <DropdownMenuOption
                                        onClick={openCreateCaseModal}
                                        title={strings.createNewCase(caseFieldName)}
                                        testId={testIds.CREATE_NEW_CASE}
                                    />
                                )}
                                {reportHamburgerMenuView.canAddToExistingCase && (
                                    <DropdownMenuOption
                                        onClick={() => openAddToCasesSidePanel()}
                                        title={strings.addToCase(caseFieldName)}
                                        testId={testIds.ADD_TO_INVESTIGATION}
                                    />
                                )}
                            </Section>

                            <Section>
                                {reportHamburgerMenuView.canCreateTasks && (
                                    <DropdownMenuOption
                                        onClick={openTaskSidePanel}
                                        title={strings.createTask}
                                        testId={testIds.CREATE_TASK}
                                    />
                                )}
                            </Section>

                            <Section>
                                {reportHamburgerMenuView.canSeal && (
                                    <DropdownMenuOption
                                        linkTo={`/reports/${currentReport.id}/sealing`}
                                        title={strings.sealReport}
                                        testId={testIds.SEAL}
                                    />
                                )}
                                {reportHamburgerMenuView.canUnseal && (
                                    <DropdownMenuOption
                                        linkTo={`/reports/${currentReport.id}/unsealing`}
                                        title={strings.unsealReport}
                                        testId={testIds.UNSEAL}
                                    />
                                )}
                                {reportHamburgerMenuView.canVacateCharges && (
                                    <DropdownMenuOption
                                        linkTo={`/reports/${currentReport.id}/charge-vacating`}
                                        title={strings.vacateCharges}
                                        testId={testIds.VACATE_CHARGES}
                                    />
                                )}
                                {reportHamburgerMenuView.canManageArrestUcrClassification && (
                                    <DropdownMenuOption
                                        onClick={openArrestUcrClassificationSidePanel}
                                        title={strings.ucrClassification}
                                        testId={testIds.EDIT_ARREST_UCR_CLASSIFICATION}
                                    />
                                )}
                                {reportHamburgerMenuView.canManageUcrClassification && (
                                    <DropdownMenuOption
                                        onClick={openUcrClassificationSidePanel}
                                        title={strings.ucrClassification}
                                        testId={testIds.EDIT_UCR_CLASSIFICATION}
                                    />
                                )}
                                {reportHamburgerMenuView.canGangReview && (
                                    <DropdownMenuOption
                                        onClick={openGangTrackingSidePanel}
                                        title={strings.criminalStreetGangTracking}
                                        testId={testIds.EDIT_GANG_TRACKING}
                                    />
                                )}
                            </Section>

                            <FeatureFlagged flag="RMS_CLIPS_DEX_INTEGRATION_ENABLED">
                                {reportHamburgerMenuView.canClipsSubmit && (
                                    <Section>
                                        <DropdownMenuOption
                                            linkTo={`/clips/${EntityTypeEnum.REPORT.name}/${currentReport.id}`}
                                            title={strings.sendToClips}
                                            testId={testIds.SEND_TO_CLIPS}
                                        />
                                    </Section>
                                )}
                            </FeatureFlagged>

                            <Section>
                                {reportHamburgerMenuView.canDelete && (
                                    <DropdownMenuOption
                                        onClick={() =>
                                            openDeleteReportModal(
                                                !reportHamburgerMenuView.canDeleteUnapproved &&
                                                    reportHamburgerMenuView.canDeleteApproved
                                            )
                                        }
                                        title={strings.deleteReport}
                                        testId={testIds.DELETE_REPORT}
                                    />
                                )}
                            </Section>
                        </Menu>
                    );
                }}
            />
            <RemoveRenModal
                reportId={currentReport.id}
                renDisplayName={renDisplayName}
                reportingEventNumber={currentReport.reportingEventNumber}
                closeFocusRef={menuButtonRef}
            />
            <CreateManageCaseModal
                title={componentStrings.cases.core.CaseHeader.manageCase(caseFieldName)}
                okText={componentStrings.cases.core.CaseHeader.save}
                isBulkCreate={false}
                includeLocalIdInTitle={true}
                onCloseRef={menuButtonRef}
            />
            <TaskSidePanel />
            <ArrestChargesSidePanel
                overlayId={overlayIdEnum.ARREST_CHARGES_SIDE_PANEL_DROPDOWN_MENU}
                reportId={currentReport.id}
            />
            <AttachmentsSidePanel overlayId={overlayIdEnum.REPORT_HEADER_ATTACHMENTS_SIDE_PANEL} />
            <TowVehicleCheckInSidePanel
                overlayId={overlayIdEnum.TOW_VEHICLE_CHECK_IN_SIDE_PANEL}
                reportId={currentReport.id}
            />
            <TowVehicleReleaseSidePanel
                overlayId={overlayIdEnum.TOW_VEHICLE_RELEASE_SIDE_PANEL}
                reportId={currentReport.id}
            />
            <DeleteReportModal
                closeFocusRef={menuButtonRef}
                overlayId={overlayIdEnum.DELETE_REPORT_FOR_REPORT_HEADER_MODAL}
            />
            <FeatureFlagged flag="RMS_INVESTIGATION_ENHANCEMENTS_PHASE_ONE">
                <ReasonForRelationSidePanel />
                <IndividualReportSelectionSidePanel />
                <CreateCaseFromMultiReportsModal
                    formLocation={overlayIdEnum.CREATE_CASE_FROM_REPORT_MODAL}
                />
            </FeatureFlagged>

            <FeatureFlagged flag="RMS_E_FILING_ENABLED">
                <EFileCreationModal />
            </FeatureFlagged>
            <FeatureFlagged flag="RMS_GENERIC_EXTERNAL_SUBMISSIONS_ENABLED">
                <CreateSubmissionsModal
                    entityId={currentReport.id}
                    entityType={EntityTypeEnum.REPORT.name}
                />
            </FeatureFlagged>
            <EditSecurityClassificationModal />
        </span>
    );
});

/**
 * Hamburger menu in the report header for actions that don't belong in the
 * other menus. Options are driven by `ReportHamburgerMenuView.java` fetched
 * asynchronously from the BE.
 */
export default ReportHeaderHamburgerMenu;
