import { LinkTypesEnum, EntityTypeEnum, LocationView, RefContextEnum } from '@mark43/rms-api';
import styled from 'styled-components';
import { lifecycleOptions, Observer } from 'markformythree';
import { useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { noop, first } from 'lodash';
import { Map } from '~/client-common/core/maps';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { reportCardTitleByReportIdAndCardIdSelector } from '~/client-common/core/domain/report-definitions/state/data';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import { crashLocationsWhereSelector } from '~/client-common/core/domain/crash-locations/state/data';
import { sortedLocationBundlesForLocationEntityLinksWhereSelector } from '~/client-common/core/domain/locations/state/ui';
import { LocationSummaryViewWrapper } from '../../../../records/core/components/summaries/locations/LocationSummaryViewWrapper';
import { useAdditionalFormConfigurationData } from '../../../../core/markformythree-arbiter/dynamic-fields/hooks/useAdditionalFormConfigurationData';
import ArbiterForm from '../../../../core/markformythree-arbiter/ArbiterForm';
import { LocationSummaryViewWrapperWithFormFields } from '../../../../records/core/components/summaries/locations/LocationSummaryViewWrapperWithFormFields';
import testIds from '../../../../../core/testIds';
import Card from '../../../../../legacy-redux/components/core/Card';
import { RMSArbiterProvider } from '../../../../core/arbiter';
import trafficCrashLocationCard from '../../state/ui/trafficCrashLocationCard';
import { registerCard } from '../../utils/cardsRegistry';
import withCard from '../../utils/withCard';
import { useFormGetter } from '../../../../core/forms/hooks/useFormGetter';
import formsRegistry from '../../../../../core/formsRegistry';
import { FormConfigurationRenderer } from '../../../../core/markformythree-arbiter/dynamic-fields/components/FormConfigurationRenderer';
import { CUSTOM_PROPERTIES_KEY_NAME } from '../../../../core/markformythree-arbiter/dynamic-fields/constants/constants';
import {
    trafficCrashLocationFormConfiguration,
    TRAFFIC_CRASH_LOCATION_PATH,
    TRAFFIC_CRASH_LONGITUDE_PATH,
    TRAFFIC_CRASH_LATITUDE_PATH,
    formName,
    convertToFormModel,
} from '../../state/forms/trafficCrashLocationForm';
import { ReportCardProps } from '../../types';

const CardContentWrapper = styled.div`
    margin: 0 3vh;
`;

const MapWrapper = styled.div`
    height: 180px;
`;

const TrafficCrashLocationCard: React.FC<ReportCardProps> = ({
    currentReportId,
    card,
    editCallback,
    onEdit,
    onSave,
    ...rest
}) => {
    const { getForm } = useFormGetter();
    const reportCardTitleByReportIdAndCardId = useSelector(
        reportCardTitleByReportIdAndCardIdSelector
    );
    const sortedLocationBundlesForLocationEntityLinksWhere = useSelector(
        sortedLocationBundlesForLocationEntityLinksWhereSelector
    );
    const additionalData = useAdditionalFormConfigurationData();
    const crashLocationsWhere = useSelector(crashLocationsWhereSelector);
    const crashLocation = first(crashLocationsWhere({ reportId: currentReportId }));

    // Currently a traffic crash can only have one location associated to it.
    const trafficCrashLocationBundle = sortedLocationBundlesForLocationEntityLinksWhere({
        entityType: EntityTypeEnum.REPORT.name,
        entityId: currentReportId,
        linkType: LinkTypesEnum.LOCATION_OF_TRAFFIC_CRASH,
    })[0];

    const isMissingTrafficCrashLocation = !trafficCrashLocationBundle?.location;

    useEffect(() => {
        const unregisterCard = registerCard({
            cardModule: trafficCrashLocationCard,
            onSave,
            index: currentReportId,
        });
        return () => {
            unregisterCard();
        };
    }, [currentReportId, onSave]);

    useEffect(() => {
        if (isMissingTrafficCrashLocation) {
            return;
        }
        formsRegistry.maybeDeferredOperation(formName, undefined, (form) => {
            const formModel = convertToFormModel(
                trafficCrashLocationBundle?.location,
                crashLocation
            );
            form.set('', formModel);
        });
    }, [
        crashLocation,
        currentReportId,
        isMissingTrafficCrashLocation,
        trafficCrashLocationBundle?.location,
    ]);

    const cardTitle = reportCardTitleByReportIdAndCardId(
        currentReportId,
        reportCardEnum.CRASH_LOCATION_INFO.id
    );

    const onCardEdit = () => {
        editCallback(() => onEdit({ index: currentReportId }));
    };

    const onSaveProgress = () => {
        const form = getForm(RefContextEnum.FORM_CRASH_LOCATION_INFO.name);
        if (!form) {
            return;
        }
        return rest.onSaveProgress(form);
    };

    const onTrafficCrashLocationAdd = (location: LocationView) => {
        const trafficCrashLocationForm = getForm(formName);
        if (trafficCrashLocationForm) {
            const formModel = convertToFormModel(location);
            trafficCrashLocationForm.set(TRAFFIC_CRASH_LOCATION_PATH, formModel.location);
        }
    };

    const onTrafficCrashLocationRemove = () => {
        const trafficCrashLocationForm = getForm(formName);
        if (trafficCrashLocationForm) {
            trafficCrashLocationForm.set(TRAFFIC_CRASH_LOCATION_PATH, undefined);
        }
    };

    return (
        <Card
            testId={testIds.CRASH_LOCATION_CARD}
            canEdit={card.canEditEventInfoReportCardStatus.canEditReportCard}
            errors={card.errorMessages}
            onEdit={onCardEdit}
            onSave={onSaveProgress}
            summaryMode={card.summaryMode}
            className="traffic-crash-location-card"
            anchor="traffic-crash-location-card"
            title={cardTitle}
            renderContent={(summaryMode) => {
                const locationSummaryProps = {
                    summaryMode,
                    entityType: EntityTypeEnum.REPORT.name,
                    entityId: currentReportId,
                    linkType: LinkTypesEnum.LOCATION_OF_TRAFFIC_CRASH,
                    hideCountry: true,
                    hideQuickAdd: true,
                    showLongitudeAndLatitudeFields: true,
                    onLocationAdd: noop,
                    onLocationRemove: noop,
                };

                return (
                    <CardContentWrapper>
                        <Observer
                            formName={formName}
                            subscriptions={{
                                latitude: TRAFFIC_CRASH_LATITUDE_PATH,
                                longitude: TRAFFIC_CRASH_LONGITUDE_PATH,
                            }}
                            render={({
                                longitude,
                                latitude,
                            }: {
                                longitude: number;
                                latitude: number;
                            }) => {
                                if (!longitude && !latitude) {
                                    return null;
                                }

                                return (
                                    <MapWrapper>
                                        <Map
                                            defaultCenter={{
                                                lat: latitude,
                                                lng: longitude,
                                            }}
                                        />
                                    </MapWrapper>
                                );
                            }}
                        />

                        <ArbiterForm
                            name={formName}
                            context={formName}
                            configuration={trafficCrashLocationFormConfiguration(additionalData)}
                            lifecycle={lifecycleOptions.REGISTER_AND_UNREGISTER}
                            render={(form) => {
                                if (summaryMode) {
                                    return (
                                        <>
                                            <LocationSummaryViewWrapper {...locationSummaryProps} />
                                            {!isMissingTrafficCrashLocation && (
                                                <FormConfigurationRenderer
                                                    form={form}
                                                    absoluteFormPath={CUSTOM_PROPERTIES_KEY_NAME}
                                                    mode="SUMMARY"
                                                />
                                            )}
                                        </>
                                    );
                                }
                                return (
                                    <>
                                        <LocationSummaryViewWrapperWithFormFields
                                            {...locationSummaryProps}
                                            onLocationRemove={onTrafficCrashLocationRemove}
                                            onLocationAdd={onTrafficCrashLocationAdd}
                                            locationDescriptionPath={noop}
                                            locationPositionAttrIdPath={noop}
                                        />
                                        {!isMissingTrafficCrashLocation && (
                                            <FormConfigurationRenderer
                                                form={form}
                                                absoluteFormPath={CUSTOM_PROPERTIES_KEY_NAME}
                                                mode="FORM"
                                            />
                                        )}
                                    </>
                                );
                            }}
                        />
                    </CardContentWrapper>
                );
            }}
        />
    );
};

const TrafficCrashLocationCardWrapper: React.FC<ReportCardProps> = (props) => {
    const applicationSettings = useSelector(applicationSettingsSelector);

    if (!applicationSettings.RMS_CRASH_DIAGRAM_ENABLED) {
        return null;
    }

    return (
        <RMSArbiterProvider context={formName}>
            {() => <TrafficCrashLocationCard {...props} />}
        </RMSArbiterProvider>
    );
};

export default withCard(trafficCrashLocationCard)(TrafficCrashLocationCardWrapper);
