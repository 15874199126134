import React, { FC, useCallback, useRef } from 'react';
import { InlineBanner } from 'arc';
import styled from 'styled-components';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { DataTableWrapper } from '../../../../core/components/DataTableWrapper';
import { EFileInvolvedProfileGridRowT, InvolvedProfileLinkType } from '../../../types';
import { useProfilesColumns } from './hooks/useProfilesColumns';
import { useProfilesSearch } from './hooks/useProfilesSearch';
import { useProfilesInitialQuery } from './hooks/useProfilesInitialQuery';
import { EFileProfilesGridRow } from './EFileProfilesGridRow';

const strings = componentStrings.eFiles.defendants.grid;

const ProfilesDataTableWrapper = DataTableWrapper<EFileInvolvedProfileGridRowT>;

const StyledProfilesDataTableWrapper = styled(ProfilesDataTableWrapper)`
    height: 0;
    flex-grow: 1;

    & .arc-table_container {
        flex: 0 1 auto;
    }

    & .arc-table_body {
        vertical-align: top;
    }
`;

type EFileProfilesGridProps = {
    eFileId: number;
    linkType: InvolvedProfileLinkType;
};

export const EFileProfilesGrid: FC<EFileProfilesGridProps> = ({ eFileId, linkType }) => {
    const actionsRef = useRef<HTMLDivElement>(null);
    const columns = useProfilesColumns(eFileId, linkType, actionsRef);
    const initialQuery = useProfilesInitialQuery(linkType);

    const { results, loading, initialLoading, pagination, errorMessage, sort, onColumnSortChange } =
        useProfilesSearch(initialQuery, eFileId);

    const renderRow = useCallback(
        ({ row, renderedCells }) => (
            <EFileProfilesGridRow
                item={row.original}
                eFileId={eFileId}
                linkType={linkType}
                rowId={row.id}
                actionsRef={actionsRef}
            >
                {renderedCells}
            </EFileProfilesGridRow>
        ),
        [eFileId, linkType]
    );

    if (errorMessage) {
        return <InlineBanner description={errorMessage} status="error" title={strings.error} />;
    }

    return (
        <StyledProfilesDataTableWrapper
            data={results}
            columns={columns}
            hasStickyHeaders={true}
            hasColumnOrdering={false}
            hasColumnResizing={false}
            hasColumnVisibility={false}
            paginationProps={pagination}
            onColumnSortChange={onColumnSortChange}
            config={{ sort }}
            noDataText={strings.noDataText}
            loading={loading}
            initialLoading={initialLoading}
            renderRow={renderRow}
            hasRowSelection={true}
        />
    );
};
