import React, { useState, useCallback } from 'react';
import { DataTableConfig as ArcDataTableConfig } from 'arc';
import { debounce } from 'lodash';

import { DataTableEnumType, DataTableConfig } from '@mark43/rms-api';
import { useResource, useResourceDeferred } from '~/client-common/core/hooks/useResource';
import { LoadingState } from '~/client-common/core/hooks/useLoadingState';

import { DataTableEnum } from '../../../../../../rms-api-package/rms-enums/src/enums';
import dataTableConfigsResource from './dataTableConfigResource';

export type DataTableEnumValues = (typeof DataTableEnum)[keyof typeof DataTableEnum]['value'];
export type DataTableConfigUpsertData = Pick<DataTableConfig, 'configJson' | 'dataTableId'> &
    Partial<Pick<DataTableConfig, 'savedSearchId'>>;

type UseDataTableReturn = {
    /** Accepts a table config to add or update in the table. Looks it up by the saved search id.
     * If there is no saved search id it will update/add the default config.
     */
    updateDataTableConfig: (config: ArcDataTableConfig) => void;
    // Returns a parsed config that matches the provided saved search id or the default config.
    currentConfig?: ArcDataTableConfig;
    /** Loading state from useResource while it is upserting the configs */
    upsertLoading: LoadingState;
};

const UPSERT_DEBOUNCE_DELAY = 1500;
const BASE_CONFIG_MAP_ID = 'BASE_ID';

type DataTableConfigMap = Map<string, DataTableConfig>;

const convertConfigsToMap = (configs: DataTableConfig[]): DataTableConfigMap => {
    const map = new Map();

    configs.forEach((config) =>
        map.set(config.savedSearchId?.toString() ?? BASE_CONFIG_MAP_ID, config)
    );

    return map;
};

export const useDataTableConfig = (
    dataTableName: DataTableEnumType,
    savedSearchId?: DataTableConfig['savedSearchId']
): UseDataTableReturn => {
    const dataTableId = DataTableEnum[dataTableName].value;
    const [configs, setConfigs] = useState<DataTableConfigMap>(new Map());

    const getCurrentConfig = useCallback(() => {
        let configJson: string | undefined;

        if (savedSearchId) {
            configJson = configs.get(savedSearchId.toString())?.configJson;
        }
        if (!configJson) {
            configJson = configs.get(BASE_CONFIG_MAP_ID)?.configJson;
        }

        return configJson ? JSON.parse(configJson) : undefined;
    }, [configs, savedSearchId]);

    const handleFetchSuccess = useCallback((configs: DataTableConfig[]) => {
        setConfigs(convertConfigsToMap(configs));
    }, []);

    const fetchTableConfigs = useCallback(() => {
        return dataTableConfigsResource.getByDataTableId(dataTableId);
    }, [dataTableId]);

    useResource(fetchTableConfigs, handleFetchSuccess);

    const handleUpsertSuccess = useCallback(
        (data: DataTableConfig[]) => {
            const updatedConfigs = new Map(configs);

            updatedConfigs.set(data[0].savedSearchId?.toString() ?? BASE_CONFIG_MAP_ID, data[0]);

            return setConfigs(updatedConfigs);
        },
        [configs]
    );

    const upsertConfig = useCallback(
        (config: ArcDataTableConfig) => {
            const data: DataTableConfigUpsertData = {
                dataTableId,
                configJson: JSON.stringify(config),
                savedSearchId,
            };
            return dataTableConfigsResource.upsertDataTableConfig([data]);
        },
        [dataTableId, savedSearchId]
    );

    const { loading: upsertLoading, callResource: callUpsertTableConfig } = useResourceDeferred(
        upsertConfig,
        handleUpsertSuccess
    );

    const debounceCall = debounce((config) => {
        if (upsertLoading.isLoading) {
            return;
        }
        callUpsertTableConfig(config);
    }, UPSERT_DEBOUNCE_DELAY);

    const debouncedConfigChange = React.useCallback(
        (config) => {
            debounceCall.cancel();
            debounceCall(config);
        },
        [debounceCall]
    );

    return {
        currentConfig: getCurrentConfig(),
        updateDataTableConfig: debouncedConfigChange,
        upsertLoading,
    };
};
