import React, { FC, useCallback, useEffect, useState } from 'react';
import { isEmpty, forEach, filter, includes, remove, map, uniqBy } from 'lodash';
import {
    ElasticSearchTypeEnum,
    CaseViewModel,
    EntityTypeEnum,
    RefContextEnum,
    UsageLog,
} from '@mark43/rms-api';
import styled from 'styled-components';
import { Button, Spacer, cssVar, useToast } from 'arc';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import { canRead } from '~/client-common/core/domain/entity-permissions/state/ui';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { linkedCaseTitleViewModelsSelector } from '../../../../legacy-redux/selectors/reportSelectors';
import { useFormGetter } from '../../../core/forms/hooks/useFormGetter';
import { currentUserDepartmentIdSelector } from '../../../core/current-user/state/ui';
import formsRegistry from '../../../../core/formsRegistry';
import { RecentlyViewedReports } from '../../../personal-dashboard/components/RecentlyViewedReports';
import { MIN_QUERY_LENGTH } from '../../../search/quick-search/config';

import EntitySearch from '../../../core/entity-search/EntitySearch';
import {
    CaseViewType,
    ReasonForRelationFormNItemDataShape,
    convertFromFormModelToReportRequest,
    convertToFormModel,
    resetReasonForRelationForm,
    convertFromFormModelToReportIdentifiers,
} from '../state/form/reasonForRelationForm';
import { caseDetailsByCaseIdSelector, getCaseDetailsByCaseIds } from '../state/ui';
import { openIndividualReportSelectionSidePanel } from '../state/ui/openReasonForRelationSidePanel';
import { OverlayBaseHelper } from '../../../core/components/OverlayBaseHelper';
import {
    PortalSidePanel,
    SidePanelSection,
} from '../../../../legacy-redux/components/core/SidePanel';
import { useOverlayStore } from '../../../core/overlays/hooks/useOverlayStore';
import { ReportIdentifier } from '../../../reports/core/utils/buildReportIdentifierView';
import { ReasonForRelationReport } from '../../types';
import caseReportLinksResource from '../resources/caseReportLinksResource';
import { getErrorMessagesFromErrors } from '../../../reports/core/helpers/validationHelpers';
import CaseItemComponent from './CaseItemComponent';
import ReasonForRelationForm from './ReasonForRelationForm';

const EntitySearchHeaderWrapper = styled.div`
    background-color: ${cssVar('arc.colors.surface.background')};
    padding: 5px 10px;
    font-size: 12px;
    color: ${cssVar('arc.colors.text.tertiary')};
    font-weight: bold;
    border-bottom: 1px solid ${cssVar('arc.colors.border.default')};
`;

const EntitySearchResultFooterWrapper = styled.div`
    background-color: ${cssVar('arc.colors.surface.background')};
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    padding: 10px 5px;
    border-top: 1px solid ${cssVar('arc.colors.border.default')};
`;

const SidePanelWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const strings = componentStrings.reports.core.ReasonForRelationSidePanel;
interface EntitySearchResultFooterProps {
    allSearchItems: CaseViewModel[];
    selectedEntityIds: number[];
    onClose: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    reasonForRelationReports: ReasonForRelationReport[];
    onCasesAdded: (addedCases: CaseViewType[]) => void;
}

const overlayId = overlayIdEnum.REASON_FOR_RELATION_SIDE_PANEL;

const formContext = RefContextEnum.FORM_REASON_FOR_RELATION_SIDE_PANEL.name;

const EntitySearchResultFooter: React.FC<EntitySearchResultFooterProps> = ({
    allSearchItems,
    selectedEntityIds,
    onClose,
    reasonForRelationReports,
    onCasesAdded,
}) => {
    const onAdd = useCallback(
        (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            const form = formsRegistry.get(formContext);
            if (form && selectedEntityIds.length) {
                const selectedCases = uniqBy(
                    filter(allSearchItems, (searchItem) =>
                        includes(selectedEntityIds, searchItem.caseId)
                    ),
                    'caseId'
                );

                const addedCases = map(selectedCases, (result) => {
                    return {
                        ...result,
                        recentlyViewedCase: false,
                    };
                });

                form.transaction(() => {
                    forEach(addedCases, (selectedResult) => {
                        form.push(
                            'reportCaseLinks',
                            convertToFormModel({
                                reasonForRelationReports,
                                caseView: selectedResult,
                            })
                        );
                    });
                });

                onCasesAdded(addedCases);
            }
            onClose(e);
        },
        [selectedEntityIds, onClose, allSearchItems, onCasesAdded, reasonForRelationReports]
    );

    return (
        <EntitySearchResultFooterWrapper>
            <Button disabled={isEmpty(selectedEntityIds)} variant="solid" onClick={(e) => onAdd(e)}>
                {strings.entitySearchResultFooter.addText}
            </Button>
            <Button onClick={onClose} variant="ghost">
                {strings.entitySearchResultFooter.closeText}
            </Button>
        </EntitySearchResultFooterWrapper>
    );
};

interface CustomRecentlyViewedComponentProps {
    caseId: number;
    reasonForRelationReports: ReasonForRelationReport[];
    onAddRecentlyViewedCase: (addedCases: CaseViewType[]) => void;
}

const CustomRecentlyViewedComponent = ({
    caseId,
    reasonForRelationReports,
    onAddRecentlyViewedCase,
}: CustomRecentlyViewedComponentProps) => {
    const caseDetailsByCaseId = useSelector(caseDetailsByCaseIdSelector);
    const currentDepartmentId = useSelector(currentUserDepartmentIdSelector);

    const caseDetails = caseDetailsByCaseId(caseId);

    const canViewCase = canRead(caseDetails.theCase?.permissionSet);

    const isCurrentDept = currentDepartmentId === caseDetails.theCase?.departmentId;

    const onAddRecentlyViewed = useCallback(
        (caseView: CaseViewType) => {
            const form = formsRegistry.get(formContext);
            if (form) {
                form.transaction(() => {
                    form.push(
                        'reportCaseLinks',
                        convertToFormModel({
                            reasonForRelationReports,
                            caseView,
                        })
                    );
                });
                onAddRecentlyViewedCase([caseView]);
            }
        },
        [onAddRecentlyViewedCase, reasonForRelationReports]
    );

    return isCurrentDept && canViewCase ? (
        <CaseItemComponent key={caseId} entityId={caseId} onClick={onAddRecentlyViewed} />
    ) : null;
};

export type ReasonForRelationSidePanelCustomProperties = {
    onSave: (reportIdentifiers: ReportIdentifier[]) => Promise<void>;
    reasonForRelationReports: ReasonForRelationReport[];
};

interface SidePanelContentProps extends WithRouterProps {
    closePanel: () => void;
    isAtBottomOfStack: () => boolean;
}

const SidePanelContent: FC<SidePanelContentProps> = ({ closePanel, router, isAtBottomOfStack }) => {
    const dispatch = useDispatch();
    const overlayStore = useOverlayStore<ReasonForRelationSidePanelCustomProperties>();
    const {
        customProperties: { onSave, reasonForRelationReports },
    } = overlayStore.getStateForId(overlayId);

    const linkedCaseTitleViewModels = useSelector(linkedCaseTitleViewModelsSelector);
    const applicationSettings = useSelector(applicationSettingsSelector);
    const individualReportSelectionEnabled = !!applicationSettings.RMS_INDIVIDUAL_REPORT_SELECTION_ENABLED;

    const [errors, setErrors] = useState<string[]>([]);
    const [searchText, setSearchText] = useState<string>('');
    const [casesToShow, setCasesToShow] = useState<CaseViewType[]>([]);
    const [caseLogIds, setCaseLogIds] = useState<number[]>([]);

    const { getForm } = useFormGetter();
    const toast = useToast();

    const form = getForm(formContext);

    const {
        pluralCaseFieldName: casesDisplayName,
        singularCaseFieldName: caseDisplayName,
    } = useCaseFieldName();

    const onCasesAdded = useCallback((addedCases: CaseViewType[]) => {
        setCasesToShow((existingCases) => uniqBy([...existingCases, ...addedCases], 'caseId'));
    }, []);

    const onRemoveFormItem = useCallback(
        (caseItem: ReasonForRelationFormNItemDataShape) => {
            const formCaseId = caseItem.caseId;

            if (formCaseId) {
                // always filter out recently viewed cases
                const newCases = remove(
                    casesToShow,
                    ({ caseId, recentlyViewedCase }) => caseId !== formCaseId || recentlyViewedCase
                );
                setCasesToShow(newCases);
            }
        },
        [casesToShow]
    );

    const getRecentlyViewedIds = useCallback((usageLogs: UsageLog[]) => {
        const caseLogs = filter(usageLogs, ['primaryEntityType', EntityTypeEnum.CASE.name]);
        setCaseLogIds(map(caseLogs, 'primaryEntityId'));
    }, []);

    const closeSidePanel = useCallback((close: boolean) => {
        resetReasonForRelationForm(formContext);
        setCasesToShow([]);
        setSearchText('');
        if (close) {
            closePanel();
        }
        // closePanel should be ignored otherwise it'll trigger extra call for useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (caseLogIds.length > 0) {
            dispatch(getCaseDetailsByCaseIds(caseLogIds));
        }

        return () => {
            closeSidePanel(false);
        };
    }, [caseLogIds, closeSidePanel, dispatch]);

    const onSaveSidePanel = useCallback(async () => {
        if (form) {
            try {
                const result = await form.submit();
                const formModel = result.form.getState().model;

                if (individualReportSelectionEnabled) {
                    dispatch(
                        openIndividualReportSelectionSidePanel({
                            formModel,
                            onSave: async (reportIdentifiers) => {
                                closePanel();

                                if (onSave) {
                                    await onSave(reportIdentifiers);
                                }
                            },
                        })
                    );

                    setErrors([]);

                    return;
                }

                const reportRequestWrapper = convertFromFormModelToReportRequest(formModel);

                if (reportRequestWrapper.reportIds.length === 0) {
                    throw new Error('Missing Report Ids');
                }
                const {
                    caseToastViews,
                } = await caseReportLinksResource.createCaseReportLinksFromReports(
                    reportRequestWrapper
                );

                resetReasonForRelationForm(formContext);
                closePanel();

                caseToastViews.forEach(({ caseId, caseNumber }) =>
                    toast({
                        status: 'default',
                        description: strings.reportAddedTo(caseDisplayName, caseNumber),
                        action: {
                            children: strings.openText,
                            onClick: () => {
                                router.push(`cases/${caseId}/summary`);
                            },
                        },
                    })
                );

                const reportIdentifiers = convertFromFormModelToReportIdentifiers(formModel);
                await onSave(reportIdentifiers);
            } catch (err) {
                setErrors(getErrorMessagesFromErrors(err));
            }
        }
    }, [
        individualReportSelectionEnabled,
        form,
        dispatch,
        onSave,
        closePanel,
        toast,
        caseDisplayName,
        router,
    ]);

    return (
        <PortalSidePanel
            id={overlayId}
            title={strings.title(caseDisplayName)}
            closePanel={() => closeSidePanel(true)}
            savePanel={onSaveSidePanel}
            errorMessages={errors}
            saveDisabled={!form || form.getState().model.reportCaseLinks?.length === 0}
            isAtBottomOfStack={isAtBottomOfStack}
            saveText={individualReportSelectionEnabled ? strings.nextText : strings.saveText}
        >
            <SidePanelWrapper>
                <EntitySearch
                    value={searchText}
                    onChange={(value: string) => {
                        setSearchText(value);
                    }}
                    entityType={ElasticSearchTypeEnum.CASE.name}
                    label={strings.entitySearchResultLabel}
                    minSearchCharacter={MIN_QUERY_LENGTH}
                    filterResults={(result: CaseViewModel) => {
                        /**
                         * Only want cases that the user has can View abilites on.
                         * And only want cases that aren't already staged/exist on the form.
                         * And don't want any usage log cases to show on search.
                         * Also don't want any cases if on a Report Page
                         */
                        const showResult =
                            result.canCurrentUserView &&
                            !includes(map(casesToShow, 'caseId'), result.caseId) &&
                            !includes(caseLogIds, result.caseId);

                        if (linkedCaseTitleViewModels.length > 0) {
                            return (
                                showResult &&
                                !includes(map(linkedCaseTitleViewModels, 'caseId'), result.caseId)
                            );
                        }
                        return showResult;
                    }}
                    helpTextContainer=".mark43-react-side-panel"
                    renderHeader={(result: CaseViewModel[]) => {
                        if (isEmpty(result)) {
                            return null;
                        }
                        return (
                            <EntitySearchHeaderWrapper>
                                {strings.entitySearchResultHeader(result.length)}
                            </EntitySearchHeaderWrapper>
                        );
                    }}
                    renderFooter={(
                        allSearchItems: CaseViewModel[],
                        selectedEntityIds: number[],
                        onClose: () => void
                    ) => (
                        <EntitySearchResultFooter
                            allSearchItems={allSearchItems}
                            selectedEntityIds={selectedEntityIds}
                            onClose={onClose}
                            reasonForRelationReports={reasonForRelationReports}
                            onCasesAdded={onCasesAdded}
                        />
                    )}
                    showCheckbox={true}
                    hideAddIcon={true}
                    hideCloseButton
                    excludeExternalResults={true}
                    clearSearchOnDefocus
                />
                {/* this extra div is needed to let the Spacer flex its height, otherwise it has 0 height */}
                <div>
                    <Spacer h="3" />
                </div>
                <ReasonForRelationForm
                    context={formContext}
                    formLocation={overlayId}
                    onRemoveItem={onRemoveFormItem}
                    casesToShow={casesToShow}
                />
                <SidePanelSection title={strings.recentlyViewed(casesDisplayName)}>
                    <RecentlyViewedReports
                        entityFilter={EntityTypeEnum.CASE.name}
                        getRecentlyViewedIds={getRecentlyViewedIds}
                        renderCustomComponent={(caseId: number) => {
                            const caseIdsOnForm = map(casesToShow, 'caseId');

                            if (
                                includes(caseIdsOnForm, caseId) ||
                                (linkedCaseTitleViewModels.length > 0 &&
                                    includes(map(linkedCaseTitleViewModels, 'caseId'), caseId))
                            ) {
                                return null;
                            }

                            return (
                                <CustomRecentlyViewedComponent
                                    caseId={caseId}
                                    reasonForRelationReports={reasonForRelationReports}
                                    onAddRecentlyViewedCase={onCasesAdded}
                                />
                            );
                        }}
                    />
                </SidePanelSection>
            </SidePanelWrapper>
        </PortalSidePanel>
    );
};

const ReasonForRelationSidePanel = (props: WithRouterProps) => (
    <OverlayBaseHelper<ReasonForRelationSidePanelCustomProperties> id={overlayId}>
        {(renderProps) => (
            <SidePanelContent
                closePanel={renderProps.closePanel}
                isAtBottomOfStack={renderProps.overlayBase.isAtBottomOfStack}
                {...props}
            />
        )}
    </OverlayBaseHelper>
);

export default withRouter(ReasonForRelationSidePanel);
