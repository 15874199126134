import React from 'react';
import { InjectedRouter } from 'react-router';
import { EntityTypeEnum, LinkTypesEnum, ReportCard } from '@mark43/rms-api';
import { useSelector } from 'react-redux';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { formatReportTitleForReportIdSelector } from '~/client-common/core/domain/reports/state/ui';
import { arrestForReportIdSelector } from '~/client-common/core/domain/arrests/state/data';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import DownloadablesCard from '../../../../legacy-redux/components/reports/DownloadablesCard';
import {
    canEditReportCardStatusSelector,
    currentReportSelector,
    isCurrentReportNewSelector,
    offenseModifyingSupplementRenOffenseReportIdSelector,
} from '../../../../legacy-redux/selectors/reportSelectors';
import AttachmentsCard from '../../../core/components/cards/AttachmentsCard';
import TrafficCrashCard from '../../traffic-crash/components/TrafficCrashCard';
import CrashEventInfoCard from './crash-event-info/CrashEventInfoCard';
import EventInfoCard from './event-info/EventInfoCard';
import TrafficCrashLocationCard from './traffic-crash-location/TrafficCrashLocationCard';
import SupplementInfoCard from './supplement-info/SupplementInfoCard';
import ReportLegacyMetadataCard from './report-legacy-metadata/ReportLegacyMetadataCard';
import BehavioralCrisisCard from './behavioral-crisis/BehavioralCrisisCard';
import FieldContactCard from './field-contact/FieldContactCard';
import CommunityInfoCard from './community-info/CommunityInfoCard';
import MissingPersonsCard from './missing-persons/MissingPersonsCard';
import InvolvedProfilesCard from './involved-profiles/InvolvedProfilesCard';
import CitationCard from './citation/CitationCard';
import UseOfForceCard from './use-of-force/UseOfForceCard';
import UseOfForceSubjectCardsWrapper from './use-of-force-subject/UseOfForceSubjectCardsWrapper';
import ArrestCard from './arrest/ArrestCard';
import PropertyCard from './items/PropertyCard';
import VehicleCard from './items/VehicleCard';
import StopCard from './stop/StopCard';
import ImpoundCard from './impound/ImpoundCard';
import TowVehicleCard from './tow-vehicle/TowVehicleCard';
import TowVehicleImpoundCard from './tow-vehicle/TowVehicleImpoundCard';
import TowVehicleCheckInCard from './tow-vehicle-check-in/TowVehicleCheckInCard';
import TowVehicleReleaseCard from './tow-vehicle-release/TowVehicleReleaseCard';
import ChargesCard from './charges/ChargesCard';
import BookingCard from './booking/BookingCard';
import CourtCaseCard from './court-case/CourtCaseCard';
import OffenseIncidentCardsWrapper from './OffenseIncidentCardsWrapper';
import ArrestBlock from './ArrestBlock';
import RelationshipsCardWrapper from './relationships/RelationshipsCardWrapper';
import SummaryNarrativeCardWrapper from './summary-narrative/SummaryNarrativeCard';
import NarrativeCard from './narrative/NarrativeCard';
import ReportStatusCommentsCard from './report-status-comments/ReportStatusCommentsCard';

type RMSCoreCardProps = {
    router: InjectedRouter;
    cardId: number;
    downloadableCard?: ReportCard;
    editCallback: (callback: () => void) => void;
};

export const RMSCoreCard = ({
    router,
    cardId,
    downloadableCard,
    editCallback,
}: RMSCoreCardProps): JSX.Element | null => {
    const currentReport = useSelector(currentReportSelector);
    const isNewReport = useSelector(isCurrentReportNewSelector);
    const canEditReportCardStatus = useSelector(canEditReportCardStatusSelector);
    const offenseModifyingSupplementRenOffenseReportId = useSelector(
        offenseModifyingSupplementRenOffenseReportIdSelector
    );
    const formatReportTitleForReportId = useSelector(formatReportTitleForReportIdSelector);
    const arrestForReportId = useSelector(arrestForReportIdSelector);

    if (!currentReport) {
        return null;
    }
    const { id: reportId, reportingEventNumber, reportDefinitionId } = currentReport;
    const baseReportId = offenseModifyingSupplementRenOffenseReportId || reportId;

    if (downloadableCard) {
        return <DownloadablesCard cardDetails={downloadableCard} />;
    }
    const arrest = arrestForReportId(reportId);

    const canEditReportCard = canEditReportCardStatus
        ? canEditReportCardStatus.canEditReportCard
        : false;
    const errorMessage = canEditReportCardStatus ? canEditReportCardStatus.errorMessage : undefined;

    switch (cardId) {
        case reportCardEnum.EVENT_INFO.id:
            return <EventInfoCard router={router} editCallback={editCallback} />;
        case reportCardEnum.CRASH_EVENT_INFO.id:
            return (
                <CrashEventInfoCard
                    router={router}
                    currentReportId={reportId}
                    editCallback={editCallback}
                />
            );
        case reportCardEnum.CRASH_LOCATION_INFO.id:
            return (
                <TrafficCrashLocationCard
                    router={router}
                    currentReportId={reportId}
                    editCallback={editCallback}
                />
            );
        case reportCardEnum.SUPPLEMENT_INFO.id:
            return (
                <SupplementInfoCard
                    editCallback={editCallback}
                    currentReportId={reportId}
                    router={router}
                />
            );
        case reportCardEnum.LEGACY_INFO.id:
            return <ReportLegacyMetadataCard currentReportId={reportId} />;
        case reportCardEnum.BEHAVIORAL_CRISIS.id:
            return (
                <BehavioralCrisisCard
                    editCallback={editCallback}
                    currentReportId={reportId}
                    router={router}
                />
            );
        case reportCardEnum.FIELD_CONTACT.id:
            return (
                <FieldContactCard
                    editCallback={editCallback}
                    currentReportId={reportId}
                    router={router}
                />
            );
        case reportCardEnum.COMMUNITY_INFORMATION.id:
            return (
                <CommunityInfoCard
                    editCallback={editCallback}
                    currentReportId={reportId}
                    router={router}
                />
            );
        case reportCardEnum.MISSING_PERSONS.id:
            return (
                <MissingPersonsCard
                    editCallback={editCallback}
                    currentReportId={reportId}
                    router={router}
                />
            );
        case reportCardEnum.INVOLVED_PROFILES.id:
            return (
                <InvolvedProfilesCard
                    editCallback={editCallback}
                    currentReportId={reportId}
                    router={router}
                />
            );
        case reportCardEnum.CITATION.id:
            return (
                <CitationCard
                    editCallback={editCallback}
                    currentReportId={reportId}
                    router={router}
                />
            );
        case reportCardEnum.TRAFFIC_CRASH.id:
            return (
                <TrafficCrashCard
                    editCallback={editCallback}
                    currentReportId={reportId}
                    router={router}
                />
            );
        case reportCardEnum.USE_OF_FORCE.id:
            return (
                <UseOfForceCard
                    editCallback={editCallback}
                    currentReportId={reportId}
                    router={router}
                />
            );
        case reportCardEnum.USE_OF_FORCE_SUBJECT.id:
            return (
                <UseOfForceSubjectCardsWrapper
                    editMode={false}
                    editCallback={editCallback}
                    reportId={reportId}
                    router={router}
                    currentReportREN={reportingEventNumber}
                />
            );
        case reportCardEnum.ARREST.id:
            return (
                <ArrestCard
                    router={router}
                    currentReportId={reportId}
                    editCallback={editCallback}
                />
            );
        case reportCardEnum.PROPERTY.id:
            return (
                <PropertyCard
                    ownerType={EntityTypeEnum.REPORT.name}
                    editCallback={editCallback}
                    currentReportId={reportId}
                    ownerId={baseReportId}
                    reportingEventNumber={reportingEventNumber}
                    router={router}
                />
            );
        case reportCardEnum.VEHICLE.id:
            return (
                <VehicleCard
                    ownerType={EntityTypeEnum.REPORT.name}
                    editCallback={editCallback}
                    currentReportId={reportId}
                    ownerId={baseReportId}
                    reportingEventNumber={reportingEventNumber}
                    router={router}
                    index={reportId}
                />
            );
        case reportCardEnum.STOP.id:
            return (
                <StopCard editCallback={editCallback} currentReportId={reportId} router={router} />
            );
        case reportCardEnum.IMPOUND.id:
            return (
                <ImpoundCard
                    editCallback={editCallback}
                    currentReportId={reportId}
                    router={router}
                />
            );
        case reportCardEnum.TOW_VEHICLE.id:
            return (
                <TowVehicleCard
                    // @ts-expect-error there is a react.forwardref that internal to this component that is causing this issue.
                    editCallback={editCallback}
                    currentReportId={reportId}
                    router={router}
                />
            );
        case reportCardEnum.TOW_VEHICLE_IMPOUND.id:
            return (
                <TowVehicleImpoundCard
                    editCallback={editCallback}
                    currentReportId={reportId}
                    router={router}
                />
            );
        case reportCardEnum.CHECK_IN_INFORMATION.id:
            return <TowVehicleCheckInCard reportId={reportId} router={router} />;
        case reportCardEnum.RELEASE_INFORMATION.id:
            return <TowVehicleReleaseCard reportId={reportId} router={router} />;
        case reportCardEnum.CHARGES.id:
            if (!arrest) {
                return null;
            }
            return <ChargesCard editCallback={editCallback} reportId={reportId} router={router} />;
        case reportCardEnum.BOOKING.id:
            return (
                <BookingCard
                    editCallback={editCallback}
                    currentReportId={reportId}
                    router={router}
                />
            );
        case reportCardEnum.COURT_CASE.id:
            return (
                <CourtCaseCard
                    editCallback={editCallback}
                    currentReportId={reportId}
                    router={router}
                />
            );
        /**
         * In an Offense/Incident report, arbitrarily many OFFENSE and INCIDENT cards are all rendered together by
         * OffenseIncidentCardsWrapper. While the report type must have both of those cards, only one of them should be
         * checked here. This means the ordering (ReportDefinitionOrderedCard.ordinal) of the INCIDENT card in this
         * report type does not matter.
         *
         * Similarly, in an Offense Modifying Supplement report, arbitrarily many SUPPLEMENT_OFFENSE and
         * SUPPLEMENT_INCIDENT cards are all rendered by OffenseIncidentCardsWrapper. This means the ordering of the
         * SUPPLEMENT_INCIDENT card in this report type does not matter.
         */
        case reportCardEnum.OFFENSE.id:
        case reportCardEnum.SUPPLEMENT_OFFENSE.id:
            return (
                <>
                    <OffenseIncidentCardsWrapper
                        editCallback={editCallback}
                        offenseReportId={baseReportId}
                        router={router}
                        currentReportREN={reportingEventNumber}
                        isNewReport={isNewReport}
                        reportDefinitionId={reportDefinitionId}
                    />
                    {cardId === reportCardEnum.OFFENSE.id && (
                        // In an Offense/Incident report, arbitrarily many arrest blocks are rendered here. Each block
                        // comprises cards from a linked arrest report.
                        <FeatureFlagged flag="RMS_DYNAMIC_REPORT_ENABLED">
                            <ArrestBlock offenseReportId={reportId} />
                        </FeatureFlagged>
                    )}
                </>
            );
        case reportCardEnum.RELATIONSHIPS.id:
            // @ts-expect-error - Convert this component to TS and define a proper type for props
            return <RelationshipsCardWrapper editCallback={editCallback} router={router} />;
        case reportCardEnum.SUMMARY_NARRATIVE.id:
            return (
                <SummaryNarrativeCardWrapper
                    editCallback={editCallback}
                    currentReportId={reportId}
                    router={router}
                    index={reportId}
                />
            );
        case reportCardEnum.NARRATIVE.id:
            return (
                <NarrativeCard
                    editCallback={editCallback}
                    currentReportId={reportId}
                    index={reportId}
                    router={router}
                />
            );
        case reportCardEnum.ATTACHMENTS.id:
            return (
                <AttachmentsCard
                    entityTitle={formatReportTitleForReportId(reportId, {
                        includeRenDisplayName: false,
                    })}
                    entityType={EntityTypeEnum.REPORT.name}
                    entityId={reportId}
                    index={reportId}
                    editCallback={editCallback}
                    linkType={LinkTypesEnum.REPORT_ATTACHMENT}
                    canEdit={canEditReportCard}
                    canEditErrorMessage={errorMessage}
                    router={router}
                    emailsEnabled={true}
                />
            );
        case reportCardEnum.REPORT_STATUS_COMMENTS.id:
            return <ReportStatusCommentsCard isCustodialReport={false} />;
        default:
            return null;
    }
};
