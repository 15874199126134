import { ElasticReport, RefContextEnum } from '@mark43/rms-api';
import overlayIdEnum, {
    OverlayIdEnumType,
} from '~/client-common/core/enums/universal/overlayIdEnum';
import type { ReportShortTitleWrapper } from '~/client-common/core/domain/report-short-titles/types';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { RmsAction } from '../../../../../core/typings/redux';
import { ReasonForRelationSidePanelCustomProperties } from '../../components/ReasonForRelationSidePanel';
import { IndividualReportSelectionSidePanelCustomProperties } from '../../components/IndividualReportSelectionSidePanel';
import {
    convertFromFormModelToReportIdentifiers,
    convertReportShortTitleWrapperToFormModel,
    convertReportShortTitleWrapperToReportsPerCaseFormModel,
    ReasonForRelationToCaseFormDataShape,
} from '../form/reasonForRelationForm';
import { ReportIdentifier } from '../../../../reports/core/utils/buildReportIdentifierView';
import { currentCaseIdSelector, getCaseDetailsByCaseIds, loadReasonForRelationExtraData } from '.';

const initReasonForRelationFormWithReportTitles = (
    overlayId: OverlayIdEnumType,
    reportIdentifiers: ReportIdentifier[],
    caseId = -1
): RmsAction<void> => {
    return (dispatch, getState, { overlayStore, formsRegistry }) => {
        const state = getState();
        const applicationSettings = applicationSettingsSelector(state);
        const individualReportSelectionEnabled = !!applicationSettings.RMS_INDIVIDUAL_REPORT_SELECTION_ENABLED;

        const selectedReportIds = individualReportSelectionEnabled
            ? reportIdentifiers.map(({ id }) => id)
            : undefined;

        overlayStore.setLoading(overlayId, true);

        dispatch(loadReasonForRelationExtraData(reportIdentifiers))
            .then((shortTitleWrapper: ReportShortTitleWrapper) => {
                formsRegistry.maybeDeferredOperation(
                    RefContextEnum.FORM_REASON_FOR_RELATION_MODAL.name,
                    undefined,
                    (form) => {
                        const model = convertReportShortTitleWrapperToFormModel(
                            shortTitleWrapper,
                            selectedReportIds,
                            caseId
                        );
                        form.set('', model);
                    }
                );
                overlayStore.setLoading(overlayId, false);
            })
            .catch((err) => {
                overlayStore.setError(overlayId, err.message);
            });
    };
};

export const openReasonForRelationSidePanel = (
    customProperties: Partial<ReasonForRelationSidePanelCustomProperties> = {}
): RmsAction<void> => (dispatch, getState, { overlayStore }) => {
    overlayStore.open(overlayIdEnum.REASON_FOR_RELATION_SIDE_PANEL, customProperties);
};

type OpenIndividualReportSelectionSidePanel = {
    formModel: ReasonForRelationToCaseFormDataShape;
} & IndividualReportSelectionSidePanelCustomProperties;
export const openIndividualReportSelectionSidePanel = ({
    formModel,
    ...customProperties
}: OpenIndividualReportSelectionSidePanel): RmsAction<void> => (
    dispatch,
    getState,
    { overlayStore, formsRegistry }
) => {
    const overlayId = overlayIdEnum.INDIVIDUAL_REPORT_SELECTION_SIDE_PANEL;
    overlayStore.open(overlayId, customProperties);

    const { reportCaseLinks = [] } = formModel;
    const reportIdentifiers = convertFromFormModelToReportIdentifiers(formModel);
    const caseIds = reportCaseLinks.map(({ caseId }) => caseId);

    overlayStore.setLoading(overlayId, true);

    dispatch(getCaseDetailsByCaseIds(caseIds))
        .then(() => dispatch(loadReasonForRelationExtraData(reportIdentifiers)))
        .then((shortTitleWrapper: ReportShortTitleWrapper) => {
            formsRegistry.maybeDeferredOperation(
                RefContextEnum.FORM_REASON_FOR_RELATION_MODAL.name,
                undefined,
                (form) => {
                    const model = convertReportShortTitleWrapperToReportsPerCaseFormModel(
                        shortTitleWrapper,
                        reportCaseLinks
                    );
                    form.set('', model);
                }
            );

            overlayStore.setLoading(overlayId, false);
        })
        .catch((err) => {
            overlayStore.setError(overlayId, err.message);
        });
};

type OverlayCreateCaseFromReports =
    | typeof overlayIdEnum.CREATE_CASE_FROM_REPORT_MODAL
    | typeof overlayIdEnum.CREATE_CASE_FROM_MULTI_REPORTS_MODAL;

export function openCreateCaseFromReportsModal(
    overlayId: OverlayCreateCaseFromReports,
    reports: ElasticReport[]
): RmsAction<void> {
    return (dispatch, getState, { overlayStore, formsRegistry }) => {
        formsRegistry.unregister(RefContextEnum.FORM_REASON_FOR_RELATION_MODAL.name);
        overlayStore.open(overlayId, { reports });
        dispatch(initReasonForRelationFormWithReportTitles(overlayId, reports));
    };
}

export const openReasonForRelationModal = (
    reportIdentifiers: ReportIdentifier[]
): RmsAction<void> => (dispatch, getState, { overlayStore, formsRegistry }) => {
    formsRegistry.unregister(RefContextEnum.FORM_REASON_FOR_RELATION_MODAL.name);

    const state = getState();
    // @ts-expect-error not typed
    const caseId = currentCaseIdSelector(state);

    const overlayId = overlayIdEnum.REASON_FOR_RELATION_MODAL;
    overlayStore.open(overlayId);
    dispatch(initReasonForRelationFormWithReportTitles(overlayId, reportIdentifiers, caseId));
};
