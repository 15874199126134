import _, { reduce } from 'lodash';
import { FormEnum } from '@mark43/rms-api';
import stringsConfig from '~/client-common/core/strings';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import { formDataIsEmpty } from '~/client-common/helpers/formHelpers';
import { abilitiesEnum } from '../../../../modules/core/abilities';
import { validateReduxForm } from '../../../validation/validationRunner';
import { userProfileFormFieldNames } from '../../../configs/adminConfig';
import {
    checkPhoneLength,
    checkPhoneValidCharacters,
} from '../../../validation/rules/phoneValidationHelpers';

export const getUserProfileFormValidation = (data, props) => {
    // As of 7/13/17, redux-form somehow re-runs validation immediately
    // after a network save fails. That validation always passes, and
    // ends up clearing error messages from the SidePanel header.
    // Instead, just no-op if saving fails, so that error persists.
    if (props.profile && props.profile.saveFailed) {
        return {};
    }

    return {
        // Validation is implemented this way because we need to validate
        // contents of an NItems manually. Using this pattern we inherit
        // as many server configured validations as possible and add our
        // own hardcoded client validations for user assignments (which
        // are members of an NItems)
        ...validateReduxForm(
            {
                form: FormEnum.ADMIN_USER_PROFILE.name,
                name: boxEnum.USER_PROFILE_ADMIN_SIDE_PANEL,
            },
            userProfileFormFieldNames
        )(data),
        ..._(data.userAssignments)
            .reject((userAssignment) => formDataIsEmpty(userAssignment))
            .reduce((acc, userAssignment, index) => {
                if (!userAssignment.startDateUtc) {
                    props.touch(`userAssignments[${index}].startDateUtc`);
                    return {
                        userAssignments: {
                            ...(acc.userAssignments || {}),
                            [index]: {
                                startDateUtc: stringsConfig.components.validation.requiredError,
                            },
                        },
                    };
                } else {
                    return acc;
                }
            }, {}),
        ...getDexValidation(data, props),
        ...reduce(
            data.phones,
            (acc, phone, index) => {
                if (!phone.phoneNumber) {
                    return acc;
                }
                const validCharacters = checkPhoneValidCharacters(phone.phoneNumber);
                const validLength = checkPhoneLength(phone.phoneNumber);

                if (validCharacters && validLength) {
                    return acc;
                } else {
                    return {
                        phones: {
                            ...acc.phones,
                            [index]: {
                                phoneNumber: !validCharacters
                                    ? stringsConfig.components.validation
                                          .phoneInvalidCharactersError
                                    : stringsConfig.components.validation.phoneLengthError,
                            },
                        },
                    };
                }
            },
            {}
        ),
        ...reduce(
            data.dutyStatuses,
            (acc, dutyStatus, index) => {
                const errors = {};

                if (!dutyStatus.dutyStatus) {
                    errors.dutyStatus =
                        stringsConfig.components.admin.userProfile.validations.dutyStatusRequiredErrorMessage;
                }

                if (!dutyStatus.dateEffective) {
                    errors.dateEffective =
                        stringsConfig.components.admin.userProfile.validations.effectiveDateErrorMessage;
                }

                if (Object.keys(errors).length > 0) {
                    acc.dutyStatuses = {
                        ...acc.dutyStatuses,
                        [index]: errors,
                    };
                }

                return acc;
            },
            {}
        ),
        // match number 1-9
        ...(!!data.heightFeet && !/^0?[1-9]$/.test(data.heightFeet)
            ? {
                  heightFeet: stringsConfig.components.validation.heightFeetError,
              }
            : {}),
        // match number 0-11
        ...(!!data.heightInches && !/^(0?[0-9]|1[0-1])$/.test(data.heightInches)
            ? {
                  heightInches: stringsConfig.components.validation.heightInchesError,
              }
            : {}),
        // match number 1-999
        ...(!!data.weight && !/^(0?[1-9][0-9]?[0-9]?)$/.test(data.weight)
            ? {
                  weight: stringsConfig.components.validation.weightError,
              }
            : {}),
        // match number 0-99
        ...(!!data.yearsOfExperience && !/^(0?[1-9]?[0-9]?)$/.test(data.yearsOfExperience)
            ? {
                  yearsOfExperience: stringsConfig.components.validation.yearsOfExperienceError,
              }
            : {}),
    };
};

const getDexValidation = (data, props) => {
    const { currentUserHasAbility, applicationSettings } = props;
    const canEditStateAuthenticationFields = currentUserHasAbility(
        abilitiesEnum.ADMIN.EDIT_STATE_AUTHENTICATION_FIELDS_FOR_GLOBAL_USERS
    );
    const isMultipleOriEnabled = applicationSettings.DEX_MULTIPLE_ORI_ENABLED;

    if (!canEditStateAuthenticationFields) {
        return {};
    }

    if (isMultipleOriEnabled) {
        let validation = reduce(
            data.dexStateUserIdOriAliasAssociations,
            (acc, oriAliasAssociation, index) => {
                const { dexUserIdValue, oriAliasId } = oriAliasAssociation;
                if (!!dexUserIdValue === !!oriAliasId) {
                    return acc;
                }

                const validation = {
                    // If one is present without the other, add required to the missing field
                    ...(!oriAliasId && !!dexUserIdValue
                        ? {}
                        : { dexUserIdValue: stringsConfig.components.validation.requiredError }),
                    ...(!dexUserIdValue && !!oriAliasId
                        ? {}
                        : { oriAliasId: stringsConfig.components.validation.requiredError }),
                };

                return {
                    dexStateUserIdOriAliasAssociations: {
                        ...acc.dexStateUserIdOriAliasAssociations,
                        [index]: validation,
                    },
                };
            },
            {}
        );

        const hasAssociations = data.dexStateUserIdOriAliasAssociations.some(
            ({ dexUserIdValue, oriAliasId }) => !!dexUserIdValue || !!oriAliasId
        );

        if (hasAssociations && !data.dateDexCertificationExpires) {
            validation = {
                ...validation,
                dateDexCertificationExpires: stringsConfig.components.validation.requiredError,
            };
        }
        return validation;
    }

    if (data.dexStateUserId && !data.dateDexCertificationExpires) {
        return {
            dateDexCertificationExpires: stringsConfig.components.validation.requiredError,
        };
    }
};
