import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Tile } from 'arc';
import styled from 'styled-components';

import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { FirearmProfileImage } from '../../../../core/components/ProfileImage';
import { iconSizes } from '../../../../core/components/Icon';
import { FirearmFormData } from './helpers';

const strings = componentStrings.core.FirearmPillSmall;

const StyledTile = styled(Tile)`
    & .chakra-text {
        white-space: pre-wrap;
        display: block;
    }
`;

type FirearmPillSmallProps = {
    onClick: () => void;
    firearm: FirearmFormData;
};

export const FirearmPillSmall: FC<FirearmPillSmallProps> = ({ onClick, firearm }) => {
    const formatAttributeById = useSelector(formatAttributeByIdSelector);
    const firearmType = formatAttributeById(firearm.itemCategoryAttrId);

    return (
        <StyledTile
            onMouseDown={onClick}
            title={joinTruthyValues([firearmType, firearm.description], ' - ')}
            description={`${strings.serialNumber} ${
                firearm.serialNumber || strings.epmtySerialNumber
            }`}
            media={<FirearmProfileImage iconSize={iconSizes.LARGE} size="40px" url={firearm.img} />}
        />
    );
};
