import { AttributeTypeEnum, EntityTypeEnum, RoleTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import _, { map, get, last, some, sortBy } from 'lodash';
import { createSelector } from 'reselect';

import stringsConfig from '~/client-common/core/strings';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { agencyProfilesSelector } from '~/client-common/core/domain/agency-profiles/state/data';
import {
    roleByIdSelector,
    roleOptionsByTypesSelector,
    rolesForUserSelector,
    rolesCheckboxOptionsForUserSelector,
    rolesManagedByUserSelector,
} from '~/client-common/core/domain/roles/state/data';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { getFullName } from '~/client-common/helpers/userHelpers';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { userAssignmentsWhereSelector } from '~/client-common/core/domain/user-assignments/state/data';
import { locationsSelector } from '~/client-common/core/domain/locations/state/data';
import { locationEntityLinksWhereSelector } from '~/client-common/core/domain/location-entity-links/state/data';
import { sortedAugmentedAttachmentViewModelsWhereSelector } from '~/client-common/core/domain/attachments/state/ui';
import * as fields from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { roleTypeIsIntegrationOrSuperUser } from '../../modules/admin/core/utils/roleTypeHelpers';
import { mapAttributes } from '../helpers/userProfileAdminHelpers';
import { getAttributeTypeSortingOrder } from '../helpers/subdivisionHelpers';
import {
    currentUserIdSelector,
    currentUserHasAbilitySelector,
    currentUserDepartmentIdSelector,
    currentUserIsSupportUserSelector,
} from '../../modules/core/current-user/state/ui';
import { subdivisionsUsageSelector } from './subdivisionsAdminSelectors';

const { NORMAL, DEPARTMENT, SUPPORT } = RoleTypeEnum;

const strings = componentStrings.admin.userProfile.fieldLabels;

const trainingAndSkillsStrings = stringsConfig.components.admin.trainingAndSkills.displayNames;
const officerDemographicsStrings =
    stringsConfig.components.admin.officerDemographicsRIPA.displayNames;

export const uiSelector = (state) => state.ui.userProfileAdmin;

export const userProfileAdminProfileSelector = createSelector(
    uiSelector,
    formatAttributeByIdSelector,
    rolesCheckboxOptionsForUserSelector,
    rolesManagedByUserSelector,
    subdivisionsUsageSelector,
    formatFieldByNameSelector,
    userAssignmentsWhereSelector,
    locationEntityLinksWhereSelector,
    locationsSelector,
    sortedAugmentedAttachmentViewModelsWhereSelector,
    agencyProfilesSelector,
    (
        ui,
        formatAttributeById,
        rolesCheckboxOptionsForUser,
        rolesManagedByUser,
        subdivisionsUsage,
        formatFieldByName,
        userAssignmentsWhere,
        locationEntityLinksWhere,
        locations,
        sortedAugmentedAttachmentViewModelsWhere,
        agencyProfiles
    ) => {
        const {
            attributes,
            profile,
            phones,
            dutyStatuses,
            saveFailed,
            dexStateUserIdOriAliasAssociations,
        } = ui;
        const departmentAgency = _.find(agencyProfiles, {
            id: profile.departmentAgencyId,
        });
        const userPhotoAttachments = sortedAugmentedAttachmentViewModelsWhere({
            entityId: profile.userId,
            entityType: EntityTypeEnum.USER.name,
            linkType: LinkTypesEnum.USER_PHOTO,
        });
        const userSignatureAttachments = sortedAugmentedAttachmentViewModelsWhere({
            entityId: profile.userId,
            entityType: EntityTypeEnum.USER.name,
            linkType: LinkTypesEnum.USER_SIGNATURE,
        });

        const userProfilePhoto = last(userPhotoAttachments);
        const userSignature = last(userSignatureAttachments);
        const userProfileAttachmentViewModels = sortedAugmentedAttachmentViewModelsWhere({
            entityType: EntityTypeEnum.USER.name,
            entityId: profile.userId,
            linkType: LinkTypesEnum.USER_PROFILE_ATTACHMENT,
        });

        const fieldLabelsByAttributeType = {
            SUBDIVISION_DEPTH_1: formatFieldByName(
                fields.LOCATION_ENTITY_LINK_SUBDIVISION_1_ATTR_ID
            ),
            SUBDIVISION_DEPTH_2: formatFieldByName(
                fields.LOCATION_ENTITY_LINK_SUBDIVISION_2_ATTR_ID
            ),
            SUBDIVISION_DEPTH_3: formatFieldByName(
                fields.LOCATION_ENTITY_LINK_SUBDIVISION_3_ATTR_ID
            ),
            SUBDIVISION_DEPTH_4: formatFieldByName(
                fields.LOCATION_ENTITY_LINK_SUBDIVISION_4_ATTR_ID
            ),
            SUBDIVISION_DEPTH_5: formatFieldByName(
                fields.LOCATION_ENTITY_LINK_SUBDIVISION_5_ATTR_ID
            ),
            BUREAU: formatFieldByName(fields.USER_ASSIGNMENT_BUREAU_ATTR_ID),
            DIVISION: formatFieldByName(fields.USER_ASSIGNMENT_DIVISION_ATTR_ID),
            BRANCH: formatFieldByName(fields.USER_ASSIGNMENT_BRANCH_ATTR_ID),
            PERSONNEL_UNIT: formatFieldByName(fields.USER_ASSIGNMENT_PERSONNEL_UNIT_ATTR_ID),
        };

        const locationEntityLinks = locationEntityLinksWhere({
            entityType: EntityTypeEnum.USER.name,
            entityId: profile.userId,
        });

        const locationId = get(last(sortBy(locationEntityLinks, 'createdDateUtc')), 'locationId');

        const homeAddress = locationId !== undefined ? locations[locationId] : undefined;
        return {
            ...profile,
            userAccountStatus: get(profile, 'user.isDisabled') ? strings.inactive : strings.active,
            isSsoUser: get(profile, 'isSsoUser') ? strings.enabled : strings.disabled,
            fullName: getFullName(profile),
            phones,
            dutyStatuses,
            stopUserAssignmentTypeAttrId: formatAttributeById(profile.stopUserAssignmentTypeAttrId),
            officerRaceOrEthnicity: mapAttributes(
                _(attributes).filter(
                    (attribute) =>
                        attribute.attributeType === AttributeTypeEnum.STOP_DATA_OFFICER_RACE.name
                ),
                officerDemographicsStrings,
                formatAttributeById
            ).value(),
            officerGender: mapAttributes(
                _(attributes).filter(
                    (attribute) =>
                        attribute.attributeType === AttributeTypeEnum.STOP_DATA_OFFICER_GENDER.name
                ),
                officerDemographicsStrings,
                formatAttributeById
            ).value(),
            driversLicenseTypeAttrId: formatAttributeById(profile.driversLicenseTypeAttrId),
            employeeTypeAttrId: formatAttributeById(profile.employeeTypeAttrId),
            educationLevelAttrId: formatAttributeById(profile.educationLevelAttrId),
            eyeColorAttrId: formatAttributeById(profile.eyeColorAttrId),
            hairColorAttrId: formatAttributeById(profile.hairColorAttrId),
            roleCheckboxOptions: rolesCheckboxOptionsForUser(profile.userId),
            userManagingRoleIds: map(rolesManagedByUser(profile.userId), 'roleId'),
            buildAttrId: formatAttributeById(profile.buildAttrId),
            sexAttrId: formatAttributeById(profile.sexAttrId),
            raceAttrId: formatAttributeById(profile.raceAttrId),
            rankAttrId: formatAttributeById(profile.rankAttrId),
            actingRankAttrId: formatAttributeById(profile.actingRankAttrId),
            defaultArrestingAgencyAttrId: formatAttributeById(profile.defaultArrestingAgencyAttrId),
            departmentAgencyId: departmentAgency && departmentAgency.agencyName,
            subdivisionUsage: subdivisionsUsage,
            homeAddress,
            userProfilePhoto,
            userSignature,
            userAssignments: map(
                userAssignmentsWhere({ userProfileId: profile.userId }),
                (userAssignment) => ({
                    id: userAssignment.id,
                    startDateUtc: userAssignment.startDateUtc,
                    endDateUtc: userAssignment.endDateUtc,
                    title: userAssignment.title,
                    actingRankAttrId: formatAttributeById(userAssignment.actingRankAttrId),
                    rankAttrId: formatAttributeById(userAssignment.rankAttrId),
                    branchAttrId: formatAttributeById(userAssignment.branchAttrId),
                    bureauAttrId: formatAttributeById(userAssignment.bureauAttrId),
                    divisionAttrId: formatAttributeById(userAssignment.divisionAttrId),
                    personnelUnitAttrId: formatAttributeById(userAssignment.personnelUnitAttrId),
                    isDetail: userAssignment.isDetail ? 'Yes' : undefined,
                    subdivision1AttrId: formatAttributeById(userAssignment.subdivision1AttrId),
                    subdivision2AttrId: formatAttributeById(userAssignment.subdivision2AttrId),
                    subdivision3AttrId: formatAttributeById(userAssignment.subdivision3AttrId),
                    subdivision4AttrId: formatAttributeById(userAssignment.subdivision4AttrId),
                    subdivision5AttrId: formatAttributeById(userAssignment.subdivision5AttrId),
                })
            ),
            subdivisionDisplay: _(attributes)
                .reject((attribute) => {
                    return (
                        attribute.attributeType === AttributeTypeEnum.USER_TRAINING.name ||
                        attribute.attributeType === AttributeTypeEnum.USER_SKILL.name ||
                        attribute.attributeType === AttributeTypeEnum.STOP_DATA_OFFICER_RACE.name ||
                        attribute.attributeType === AttributeTypeEnum.STOP_DATA_OFFICER_GENDER.name
                    );
                })
                .map((attribute) => {
                    return {
                        ...attribute,
                        name: fieldLabelsByAttributeType[attribute.attributeType],
                        value: formatAttributeById(attribute.attributeId),
                    };
                })
                .sortBy((attribute) => getAttributeTypeSortingOrder(attribute.attributeType))
                .value(),
            trainingCompleted: mapAttributes(
                _(attributes).filter(
                    (attribute) => attribute.attributeType === AttributeTypeEnum.USER_TRAINING.name
                ),
                trainingAndSkillsStrings,
                formatAttributeById
            ).value(),
            skills: mapAttributes(
                _(attributes).filter(
                    (attribute) => attribute.attributeType === AttributeTypeEnum.USER_SKILL.name
                ),
                trainingAndSkillsStrings,
                formatAttributeById
            ).value(),
            userProfileAttachmentViewModels,
            saveFailed,
            dexStateUserIdOriAliasAssociations,
        };
    }
);

export const userProfileAdminRolesSelector = createSelector(
    uiSelector,
    currentUserDepartmentIdSelector,
    roleOptionsByTypesSelector,
    rolesForUserSelector,
    currentUserIsSupportUserSelector,
    (ui, currentUserDepartmentId, roleOptionsByTypes, rolesForUser, currentUserIsSupportUser) => {
        const roleTypes = currentUserIsSupportUser
            ? [DEPARTMENT.name, NORMAL.name, SUPPORT.name]
            : [DEPARTMENT.name, NORMAL.name];
        return {
            userRoles: _.keys(rolesForUser(ui.profile.userId)).map(parseFloat),
            roleOptions: roleOptionsByTypes(roleTypes, {
                departmentId: currentUserDepartmentId,
            }),
        };
    }
);

export const userProfileAdminPermissionSelector = createSelector(
    uiSelector,
    rolesForUserSelector,
    roleByIdSelector,
    currentUserIdSelector,
    currentUserHasAbilitySelector,
    currentUserIsSupportUserSelector,
    (
        ui,
        rolesForUser,
        roleById,
        currentUserId,
        currentUserHasAbility,
        currentUserIsSupportUser
    ) => {
        const canEditGlobalUsers = currentUserHasAbility(abilitiesEnum.ADMIN.EDIT_GLOBAL_USERS);

        const isCurrentUser = currentUserId === ui.profile.userId;
        const isIntegrationOrSuperUser = some(rolesForUser(ui.profile.userId), (userRole) => {
            const role = roleById(userRole.roleId);
            // the role does not exist if it is another user's USER role (with roleType = USER)
            // and we don't need to load or check those here
            return role ? roleTypeIsIntegrationOrSuperUser(role.roleType) : false;
        });
        const notRestricted =
            isCurrentUser || currentUserIsSupportUser || !isIntegrationOrSuperUser;

        return {
            // whether the current user can edit the "normal" user profile fields, the ones that are not covered by the other booleans below
            // it is also possible to do this using the bulk uploader
            canEditProfile: canEditGlobalUsers,
            // whether the current user can edit the email address and the "Single Sign-On Enabled for Report Submission?" boolean
            // it is also possible to do this using the bulk uploader
            canEditAccount:
                currentUserHasAbility(abilitiesEnum.ADMIN.USER_ACCOUNT_MANAGEMENT) && notRestricted,
            // whether the current user can edit the Account Status, which means disabling/deactivating or enabling the user in this department
            // it is also possible to do this using the bulk uploader
            canEditStatus: canEditGlobalUsers && !isIntegrationOrSuperUser,
            // whether the current user can add or remove roles from this user
            // it is also possible to do this from the roles admin page
            canEditRoles:
                currentUserHasAbility(abilitiesEnum.ADMIN.EDIT_GLOBAL_ROLE_ENROLLMENT) &&
                notRestricted,
            // whether the current user can edit their own user account, for the same "normal" fields covered by `canEditProfile`
            canSelfEditProfile: currentUserHasAbility(
                abilitiesEnum.CORE.EDIT_USER_ACCOUNT_SETTINGS
            ),
            // whether the current user can view the list of roles that another user belongs to
            // this boolean has nothing to do with whether the current user is viewing their own user profile page
            canViewGlobalRoleConfiguration: currentUserHasAbility(
                abilitiesEnum.ADMIN.VIEW_GLOBAL_ROLE_CONFIGURATION
            ),
            // whether the current user is in the Mark43 Support role, which should have only Mark43 employees
            isSupportUser: currentUserIsSupportUser,
        };
    }
);
