import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Td, Tr, Icon, Text as ArcText, HStack, IconNameT } from 'arc';
import { noop } from 'lodash';
import { canFindOnly, canRead } from '~/client-common/core/domain/entity-permissions/state/ui';
import componentStrings from '~/client-common/core/strings/componentStrings';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import {
    currentUserHasAbilitySelector,
    currentUserDepartmentIdSelector,
} from '../current-user/state/ui';
import testIds from '../../../core/testIds';

const strings = componentStrings.core.Table;

type TableRowProps = Partial<{
    $hideWarningBorder: boolean;
    $isWarning: boolean;
    $isNewResult: boolean;
    $isRestricted: boolean;
    $isInteractive: boolean;
}>;

const TableRow = styled(Tr)<TableRowProps>`
    ${(props) =>
        props.$isNewResult &&
        `
        margin-left: -2px;
        border-left: 2px solid var(--arc-colors-positive-default);`}
    border-top: ${(props) =>
        props.$isWarning && !props.$hideWarningBorder
            ? `6px solid var(--arc-colors-negative-default)`
            : 'none'};
    background-color: ${(props) =>
        props.$isWarning
            ? 'var(--arc-colors-negative-accent) !important'
            : props.$isRestricted
            ? 'var(--arc-colors-surface-accent) !important'
            : 'inherit'};
    ${(props) =>
        props.$isInteractive &&
        `
        &:hover {
            background-color: var(--arc-colors-interactive-hover) !important;
            cursor: pointer;
        }`}
`;

const RestrictedAccessWarningCell = styled(Td)`
    padding: var(--arc-space-1) var(--arc-space-3);
    background-image: url(../images/stripe_pattern.png);
    background-repeat: repeat;
    border-bottom: none;
`;

type RestrictedAccessRowProps = TableRowProps & {
    text: string;
    subtext?: string;
    icon?: IconNameT;
    onClick?: () => void;
    children?: React.ReactNode;
};

export const RestrictedAccessRow = ({
    text,
    subtext,
    icon,
    children,
    onClick,
    $isWarning,
    $isRestricted,
    $isNewResult,
    $isInteractive,
    ...otherProps
}: RestrictedAccessRowProps) => {
    const colSpan = React.Children.count(children);
    return (
        <>
            <TableRow {...{ $isNewResult, $isWarning, $isRestricted }}>
                <RestrictedAccessWarningCell colSpan={colSpan}>
                    <ArcText isItalic fontWeight="semibold" casing="uppercase">
                        {text}
                    </ArcText>
                    {subtext && (
                        <HStack align="center">
                            {icon && <Icon color="negative.default" icon={icon} />}
                            <ArcText>{subtext}</ArcText>
                        </HStack>
                    )}
                </RestrictedAccessWarningCell>
            </TableRow>
            <TableRow
                $isRestricted
                $hideWarningBorder
                {...{ onClick, $isNewResult, $isWarning, $isInteractive }}
                {...otherProps}
            >
                {children}
            </TableRow>
        </>
    );
};

const RestrictedAccessRowConnected = <R extends Row>({
    row: { departmentId, isNewResult, isSealed, permissionSet },
    children,
    warning,
    ...otherProps
}: ElasticRowProps<R>) => {
    const currentUserHasAbility = useSelector(currentUserHasAbilitySelector);
    const currentUserDepartmentId = useSelector(currentUserDepartmentIdSelector);

    const restrictedSubtext = canFindOnly(permissionSet) ? strings.canFindOnly : undefined;
    const subtext = isSealed ? strings.sealedRecord : restrictedSubtext;

    const hasCourtOrderAbility = currentUserHasAbility(abilitiesEnum.REPORTING.VIEW_COURT_ORDERS);
    const rowIsClickable = isSealed && currentUserDepartmentId === departmentId && hasCourtOrderAbility;
    const onClickHandler = rowIsClickable ? otherProps.onClick : noop;

    return (
        <RestrictedAccessRow
            $hideWarningBorder
            $isWarning={warning}
            $isRestricted
            $isNewResult={!!isNewResult}
            $isInteractive={!!rowIsClickable}
            text={strings.restrictedAccess}
            subtext={subtext}
            icon={isSealed ? 'Permissions' : undefined}
            onClick={onClickHandler}
        >
            {children}
        </RestrictedAccessRow>
    );
};

const checkRowIsRestricted = (row: Row) =>
    !canRead(row.permissionSet) || canFindOnly(row.permissionSet);

type Row = Record<string, unknown>;

type ElasticRowProps<R extends Row> = JSX.IntrinsicElements['tr'] & {
    row: R;
    // If true it will change the background and border
    warning?: boolean;
};

/** This row is used for elastic results in the ARC based DataTable
 * If the permissionSet array does not have 'READ' permission it will
 * display "Restricted Access" and disable the row click (but not other links/buttons within) */
export function ElasticResultsDataTableRow<R extends Row>({
    row,
    children,
    warning,
    ...otherProps
}: ElasticRowProps<R>) {
    const isRestricted = checkRowIsRestricted(row);
    return !isRestricted ? (
        <TableRow
            {...otherProps}
            $isNewResult={row.isNewResult}
            data-test-id={testIds.TABLE_ROW}
            $isWarning={warning}
            $isInteractive={!!otherProps.onClick}
        >
            {children}
        </TableRow>
    ) : (
        <RestrictedAccessRowConnected<R> row={row} warning={warning} {...otherProps}>
            {children}
        </RestrictedAccessRowConnected>
    );
}
