import { DispositionApprovalTypeEnum } from '@mark43/evidence-api';
import { chain, get, isEmpty } from 'lodash';
import { createSelector } from 'reselect';
import componentStrings from '../../../../strings/componentStrings';
import { formatRoleNameByRoleIdSelector } from '../../../roles/state/data';
import { evidenceDepartmentConfigSelector } from '../data';

const strings =
    componentStrings.reports.custodialPropertySummary.disposition.DispositionEventDetails;

const isPreferredDispositionApproverAvailable = (
    dispositionApprovalType,
    roleIds,
    assignedSupervisorIds,
    assignedInvestigatorId
) => {
    switch (dispositionApprovalType) {
        case DispositionApprovalTypeEnum.ROLE.name:
            return !isEmpty(roleIds);
        case DispositionApprovalTypeEnum.ASSIGNED_INVESTIGATOR.name:
            return assignedInvestigatorId;
        case DispositionApprovalTypeEnum.ASSIGNED_SUPERVISORS.name:
            return !isEmpty(assignedSupervisorIds);
        default:
            // DispositionApprovalTypeEnum.RESPONSIBLE_OFFICER.name
            return true;
    }
};

const buildDisplayString = (currentApprovalType, roleIds, formatRoleIds, isFormatted) => {
    switch (currentApprovalType) {
        case DispositionApprovalTypeEnum.ROLE.name:
            return formatRoleIds(roleIds);
        case DispositionApprovalTypeEnum.ASSIGNED_INVESTIGATOR.name:
            return isFormatted
                ? strings.requestSentToAssignedInvestigator
                : strings.assignedInvestigator;
        case DispositionApprovalTypeEnum.ASSIGNED_SUPERVISORS.name:
            return isFormatted
                ? strings.requestSentToAssignedSupervisors
                : strings.assignedSupervisors;
        default:
            // DispositionApprovalTypeEnum.RESPONSIBLE_OFFICER.name
            return isFormatted
                ? strings.requestSentToResponsibleOfficer
                : strings.responsibleOfficer;
    }
};

export const dispositionReviewerSelector = createSelector(
    evidenceDepartmentConfigSelector,
    formatRoleNameByRoleIdSelector,
    (evidenceDepartmentConfig, formatRoleNameByRoleId) => (
        isPrimaryReview,
        assignedSupervisorIds,
        assignedInvestigatorId,
        isFormatted
    ) => {
        const formatRoleIds = (roleIds) =>
            chain(roleIds)
                .thru(formatRoleNameByRoleId)
                .thru((roles) => (isFormatted ? strings.requestSentToFollowingRoles(roles) : roles))
                .value();

        const preferredDispositionApprovalType = isPrimaryReview
            ? get(evidenceDepartmentConfig, 'primaryDispositionApprovalType')
            : get(evidenceDepartmentConfig, 'secondaryDispositionApprovalType');
        const alternateDispositionApprovalType = isPrimaryReview
            ? get(evidenceDepartmentConfig, 'alternatePrimaryDispositionApprovalType')
            : get(evidenceDepartmentConfig, 'alternateSecondaryDispositionApprovalType');
        const roleIds = isPrimaryReview
            ? get(evidenceDepartmentConfig, 'primaryDispositionApprovalRoleIds')
            : get(evidenceDepartmentConfig, 'secondaryDispositionApprovalRoleIds');
        const alternateRoleIds = isPrimaryReview
            ? get(evidenceDepartmentConfig, 'alternatePrimaryDispositionApprovalRoleIds')
            : get(evidenceDepartmentConfig, 'alternateSecondaryDispositionApprovalRoleIds');

        const isPreferredAvailable = isPreferredDispositionApproverAvailable(
            preferredDispositionApprovalType,
            roleIds,
            assignedSupervisorIds,
            assignedInvestigatorId
        );

        return buildDisplayString(
            isPreferredAvailable
                ? preferredDispositionApprovalType
                : alternateDispositionApprovalType,
            isPreferredAvailable ? roleIds : alternateRoleIds,
            formatRoleIds,
            isFormatted
        );
    }
);
