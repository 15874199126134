import React, { FC, useCallback, useEffect, useState, useMemo } from 'react';
import { withRouter } from 'react-router';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { cssVar } from '@arc/core';
import { AttributeTypeEnum, ElasticCase, SearchResultElasticCase } from '@mark43/rms-api';

import {
    useCaseFieldName,
    useTargetProfileFieldName,
} from '~/client-common/core/fields/hooks/useFields';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import {
    formatAttributeValue,
    formatElasticAttributes,
} from '~/client-common/core/domain/attributes/utils/attributesHelpers';
import { attributesWithParentAttributeIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { elasticCasesViewModelForElasticCasesSelector } from '~/client-common/core/domain/elastic-cases/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { useResourceDeferred } from '~/client-common/core/hooks/useResource';

import CaseNumberNameCell from '../../cases/core/components/CaseNumberNameCell';
import createElement from '../../core/utils/recompose.createElement';
import { TARGET_PROFILES_INTERVAL, TARGET_PROFILES_SORTS } from '../configuration';
import elasticSearchResource from '../../../legacy-redux/resources/elasticSearchResource';
import { useLoadAttributes } from '../../tasks/core/hooks/useLoadAttributes';
import { CaseProfileImage } from '../../core/components/ProfileImage';
import { NoDataBlock, ShowMoreButton } from './DashboardSection';
import { DashboardTable, DashboardTd, DashboardTh, DashboardTr } from './DashboardTable';
import { DashboardRightCardsLoader } from './DashboardLoaders';
import {
    TargetProfilesSnapshotModal,
    TargetProfilesSnapshotModalProps,
} from './TargetProfilesSnapshotModal';
import { TargetProfilesFilterQuery } from './TargetProfilesFilters';

const strings = componentStrings.personalDashboard.TargetProfiles;

const StyledDashboardTd = styled(DashboardTd)`
    display: flex;
    align-items: center;
`;

type DataCase = {
    id: string;
    case: ElasticCase;
    openModal: () => void;
};

const columnStyles = {
    avatar: { width: '5.5rem', maxWidth: '5.5rem' },
    name: { minWidth: '16rem' },
    category: { minWidth: '8rem' },
    area: { minWidth: '8rem' },
};

const StyledDashboardTr = styled(DashboardTr)`
    cursor: pointer;

    &:hover {
        background-color: ${cssVar('arc.colors.interactive.hover')};
    }
`;

const HeaderRow = () => {
    const { singularCaseFieldName: caseDisplayName } = useCaseFieldName();
    return (
        <DashboardTr>
            <DashboardTh style={columnStyles.avatar} />
            <DashboardTh style={columnStyles.name}>
                {strings.caseNumberCaseName(caseDisplayName)}
            </DashboardTh>
            <DashboardTh style={columnStyles.category}>{strings.category}</DashboardTh>
            <DashboardTh style={columnStyles.area}>{strings.area}</DashboardTh>
        </DashboardTr>
    );
};

const BodyRow: FC<{ data: ElasticCase; openModal: () => void }> = ({ data, openModal }) => {
    return (
        <StyledDashboardTr onClick={openModal}>
            {/** createElement is used here because data has viewModel props and
             * CaseNumberNameCell accepts elasticCase as the only argument.
             * As a result the viewModel parts gets removed when passing data with a spread opertaor.
             * With createElement work around data is past as a whole */}
            <StyledDashboardTd style={columnStyles.avatar}>
                <CaseProfileImage url={data.primaryImagePath} size={cssVar('arc.space.16')} />
            </StyledDashboardTd>
            <StyledDashboardTd style={columnStyles.name}>
                {createElement(CaseNumberNameCell, { ...data })}
            </StyledDashboardTd>
            <StyledDashboardTd style={columnStyles.category}>
                {formatElasticAttributes(data.targetProfileCategoryAttrDetails)}
            </StyledDashboardTd>
            <StyledDashboardTd style={columnStyles.area}>
                {formatElasticAttributes(data.targetProfileAreaAttrDetails)}
            </StyledDashboardTd>
        </StyledDashboardTr>
    );
};

type TargetProfilesTableProps = {
    filtersQueryObj: TargetProfilesFilterQuery;
};

const TargetProfilesTable: FC<TargetProfilesTableProps> = ({ filtersQueryObj }) => {
    const { pluralTargetProfileFieldName: targetProfilesDisplayName } = useTargetProfileFieldName();
    const elasticCasesViewModelsSelector = useSelector(
        elasticCasesViewModelForElasticCasesSelector
    );
    const attributesWithParentAttributeId = useSelector(attributesWithParentAttributeIdSelector);
    const [targetProfiles, setTargetProfiles] = useState<
        ReturnType<ReturnType<typeof elasticCasesViewModelForElasticCasesSelector>>
    >([]);
    const [totalCount, setTotalCount] = useState(0);
    const [from, setFrom] = useState(0);

    const priority1 = attributesWithParentAttributeId(globalAttributes.priorityGlobal.priority1);
    const priority2 = attributesWithParentAttributeId(globalAttributes.priorityGlobal.priority2);

    const priorityAttributes = useMemo(
        () => priority1.concat(priority2).filter(Boolean),
        [priority1, priority2]
    );

    const loadTargetProfiles = useCallback(() => {
        return elasticSearchResource.searchCases(
            {
                isTargetProfile: true,
                targetProfilePriorityAttrDetail: {
                    displayValues: priorityAttributes.map((attr) => formatAttributeValue(attr)),
                    ids: priorityAttributes.map((attr) => attr.id),
                },
                ...filtersQueryObj,
            },
            from,
            TARGET_PROFILES_INTERVAL,
            undefined,
            undefined,
            undefined,
            true,
            undefined,
            undefined,
            TARGET_PROFILES_SORTS
        );
    }, [priorityAttributes, filtersQueryObj, from]);

    const onResourceSuccess = useCallback(
        (result: SearchResultElasticCase) => {
            const elasticCaseViewModels = elasticCasesViewModelsSelector(result.items);
            setTargetProfiles((prev) =>
                result.query.from === 0
                    ? elasticCaseViewModels
                    : [...prev, ...elasticCaseViewModels]
            );
            setTotalCount(result.totalCount);
        },
        [elasticCasesViewModelsSelector]
    );

    const { callResource, loading } = useResourceDeferred(
        // @ts-expect-error elasticSearchResource is not typed yet
        loadTargetProfiles,
        onResourceSuccess
    );

    useLoadAttributes(AttributeTypeEnum.TARGET_PROFILE_PRIORITY.name);

    useEffect(() => {
        setFrom(0);
        setTargetProfiles([]);
    }, [filtersQueryObj]);

    useEffect(() => {
        // load target profiles only when priority attributes are available
        if (priorityAttributes.length !== 0) {
            callResource();
        }
    }, [callResource, priorityAttributes.length]);

    const [modalState, setModalState] = useState<Omit<TargetProfilesSnapshotModalProps, 'onClose'>>(
        { isOpen: false, caseItem: null }
    );

    if (loading.isLoading && targetProfiles.length === 0) {
        return <DashboardRightCardsLoader />;
    }

    if (loading.errorMessage) {
        return <NoDataBlock>{strings.loadError(targetProfilesDisplayName)}</NoDataBlock>;
    }

    if (targetProfiles.length === 0) {
        return <NoDataBlock>{strings.noResults(targetProfilesDisplayName)}</NoDataBlock>;
    }

    const renderBodyRow = (item: DataCase) => {
        return <BodyRow key={item?.id} data={item?.case} openModal={item.openModal} />;
    };

    const data = targetProfiles.map((item) => ({
        id: item.caseNumber,
        case: item,
        openModal: () => setModalState({ isOpen: true, caseItem: item }),
    }));

    return (
        <>
            <div>
                <DashboardTable
                    as="div"
                    data={data}
                    renderHeader={() => <HeaderRow />}
                    renderRow={renderBodyRow}
                />
                {targetProfiles.length < totalCount && (
                    <ShowMoreButton
                        onClick={() => {
                            setFrom((prevFrom) => prevFrom + TARGET_PROFILES_INTERVAL);
                        }}
                    >
                        {strings.showMore}
                    </ShowMoreButton>
                )}
            </div>
            <TargetProfilesSnapshotModal
                {...modalState}
                onClose={() => setModalState({ isOpen: false, caseItem: null })}
            />
        </>
    );
};

export default withRouter(TargetProfilesTable);
