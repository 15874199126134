import React, {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useReducer,
    useCallback,
} from 'react';
import { sortBy } from 'lodash';
import { useSelector } from 'react-redux';
import { ReportDefinitionOrderedCardViewModel, DynamicCardTypeEnum } from '@mark43/rms-api';
import {
    reportDefinitionByIdSelector,
    reportDefinitionHasCardSelector,
} from '~/client-common/core/domain/report-definitions/state/data';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { useResourceDeferred } from '~/client-common/core/hooks/useResource';
import { LoadingState } from '~/client-common/core/hooks/useLoadingState';
import { currentReportSelector } from '../../../../legacy-redux/selectors/reportSelectors';
import reportCardsConfigurationResource from '../resources/reportCardsConfigurationResource';
import {
    reportUiStateReducer,
    initialState,
    ReportUiState,
    setOrderedReportCardLinks,
} from './reducer';

type ReportPageUIContextType = {
    orderedReportCardLinks: ReportUiState['orderedReportCardLinks'];
    loadingState: LoadingState;
};

const ReportPageUiContext = createContext<ReportPageUIContextType | undefined>(undefined);

export function useReportUiStateContext(): ReportPageUIContextType {
    const context = useContext(ReportPageUiContext);

    if (context === undefined) {
        return {
            orderedReportCardLinks: [],
            loadingState: {
                isLoading: false,
                errorMessage: '',
            },
        };
    }
    return context;
}

export const ReportUiStateProvider = ({
    children,
}: React.PropsWithChildren<Record<never, never>>) => {
    const [state, dispatch] = useReducer(reportUiStateReducer, initialState);
    const currentReport = useSelector(currentReportSelector);
    const applicationSettings = useSelector(applicationSettingsSelector);
    const reportDefinitionById = useSelector(reportDefinitionByIdSelector);
    const reportDefinitionHasCard = useSelector(reportDefinitionHasCardSelector);

    const fetchReportDefinitionOrderedCardLinks = useCallback((reportDefinitionId: number) => {
        return reportCardsConfigurationResource.getOrderedCardsByReportDefinitionId(
            reportDefinitionId
        );
    }, []);

    const handleFetchReportOrderedCardLinksSuccess = useCallback(
        (viewModel: ReportDefinitionOrderedCardViewModel) => {
            if (currentReport) {
                const reportDefinition = reportDefinitionById(currentReport.reportDefinitionId);

                if (reportDefinition) {
                    const downloadableCards = reportDefinition.cards.filter(
                        (card) => card.dynamicCardType === DynamicCardTypeEnum.DOWNLOADABLES.name
                    );

                    const sortedOrderedCardLinks = sortBy(
                        viewModel.orderedCardLinks,
                        'ordinal'
                    ).filter((orderedCardLink) => {
                        if (!!orderedCardLink.externalCardId) {
                            return true;
                        }
                        if (!!orderedCardLink.cardId) {
                            return reportDefinitionHasCard(
                                reportDefinition.id,
                                orderedCardLink.cardId
                            );
                        }
                        return false;
                    });

                    const orderedCardLinks = sortedOrderedCardLinks.map((orderedCardLink) => {
                        const downloadableCard = downloadableCards.find(
                            (card) => orderedCardLink.cardId === card.id
                        );
                        return {
                            ...orderedCardLink,
                            downloadableCard,
                        };
                    });
                    dispatch(setOrderedReportCardLinks(orderedCardLinks));
                }
            }
        },
        [dispatch, currentReport, reportDefinitionHasCard, reportDefinitionById]
    );

    const { loading: loadingState, callResource } = useResourceDeferred(
        fetchReportDefinitionOrderedCardLinks,
        handleFetchReportOrderedCardLinksSuccess
    );

    useEffect(() => {
        const reportDefinitionId = currentReport?.reportDefinitionId;

        if (
            !reportDefinitionId ||
            !applicationSettings.RMS_DRAGON_AND_RMS_CARD_REORDERING_ENABLED
        ) {
            return;
        }

        const hasReportCardsLoaded = state.orderedReportCardLinks.length > 0;

        if (!hasReportCardsLoaded) {
            callResource(reportDefinitionId);
        }
    }, [
        currentReport?.reportDefinitionId,
        state.orderedReportCardLinks,
        callResource,
        applicationSettings.RMS_DRAGON_AND_RMS_CARD_REORDERING_ENABLED,
    ]);

    const value = useMemo(() => {
        return {
            orderedReportCardLinks: state.orderedReportCardLinks,
            loadingState,
        };
    }, [state.orderedReportCardLinks, loadingState]);

    if (!currentReport) {
        return null;
    }

    return <ReportPageUiContext.Provider value={value}>{children}</ReportPageUiContext.Provider>;
};
