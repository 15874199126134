import {
    InferFormDataShape,
    createField,
    createFormConfiguration,
    createNItems,
    lifecycleOptions,
} from 'markformythree';
import {
    RefContextEnumType,
    CaseReportLinkCreationFromCaseRequest,
    CaseReportLinkCreationFromCaseRequestWrapper,
    CaseReportLinkCreationFromReportRequest,
    CaseReportLinkCreationFromReportRequestWrapper,
    ApprovalStatusForCaseEnumType,
    CaseDefaultsRequest,
    ReportShortTitle,
} from '@mark43/rms-api';
import * as fields from '~/client-common/core/enums/universal/fields';
import type { ReportShortTitleWrapper } from '~/client-common/core/domain/report-short-titles/types';
import formsRegistry from '../../../../../core/formsRegistry';
import { ReportIdentifier } from '../../../../reports/core/utils/buildReportIdentifierView';
import { ReasonForRelationReport } from '../../../types';
import {
    getCaseReportLinkAttributeView,
    getUniqueReportIds,
} from '../../utils/reasonForRelationHelpers';

export interface CaseViewType {
    approvalStatus?: ApprovalStatusForCaseEnumType;
    assignedPersonnelUnitAttrId?: number;
    assigneeRoleId?: number;
    caseId: number;
    currentStatusAttrId?: number;
    shortTitle?: string;
    recentlyViewedCase: boolean;
}
const reportCaseLinkReport = createFormConfiguration({
    reportingEventNumber: createField<string>({
        fieldName: fields.REPORT_REPORTING_EVENT_NUMBER,
    }),
    recordNumber: createField<string>({}),
    reportIds: createField<number[]>({}),
    // needs for UI only as before we pass this data through all the components
    // it is better to have them on a for itself
    displayReportIds: createField<number[]>({}),
});

const getReasonForRelationConfig = (hideReasonForRelation: boolean) => {
    const reasonForRelationFields = {
        reasonForRelationAttrId: createField<number>({
            fieldName:
                fields.CASE_REPORT_LINK_ATTRIBUTE_ATTRIBUTE_TYPE_REASON_FOR_RELATION_ATTRIBUTE_ID,
        }),
        reasonForRelationOther: createField<string>({
            fieldName:
                fields.CASE_REPORT_LINK_ATTRIBUTE_ATTRIBUTE_TYPE_REASON_FOR_RELATION_OTHER_ATTRIBUTE_DESCRIPTION,
        }),
    };

    const reportCaseLinksConfig = createNItems({
        fields: {
            caseId: createField<number>({}),
            ...(hideReasonForRelation ? {} : reasonForRelationFields),
            reportData: createNItems({
                fields: {
                    ...reportCaseLinkReport,
                },
            }),
        },
    });

    return createFormConfiguration({
        reportCaseLinks: reportCaseLinksConfig,
    });
};

export type ReasonForRelationFormConfiguration = ReturnType<typeof getReasonForRelationConfig>;
export type ReasonForRelationFormNItemDataShape = InferFormDataShape<
    ReasonForRelationFormConfiguration['reportCaseLinks']
>[0];

export type ReasonForRelationToCaseFormDataShape = InferFormDataShape<ReasonForRelationFormConfiguration>;
export type ReportCaseLinkReportFormDataShape = InferFormDataShape<typeof reportCaseLinkReport>;

export function convertToFormModel({
    reasonForRelationReports,
    caseView,
}: {
    reasonForRelationReports: ReasonForRelationReport[];
    caseView: CaseViewType;
}) {
    return {
        reportData: reasonForRelationReports,
        caseId: caseView.caseId,
    };
}

export function convertReportShortTitleWrapperToFormModel(
    reportShortTitleWrapper: ReportShortTitleWrapper,
    selectedReportIds?: number[],
    caseId = -1
) {
    const {
        reportShortTitlesByREN,
        reportShortTitlesByRecordWithoutRENRecordNumber,
    } = reportShortTitleWrapper;

    const formatTitlesToReportCaseLink = (
        titles: Record<string, ReportShortTitle[]>,
        key: 'reportingEventNumber' | 'recordNumber'
    ) => {
        return Object.entries(titles).map(([renOrRecordNumber, reportShortTitles]) => {
            const displayReportIds = reportShortTitles.map(({ reportId }) => reportId);
            const reportIds = selectedReportIds
                ? displayReportIds.filter((reportId) => selectedReportIds.includes(reportId))
                : displayReportIds;

            return {
                caseId,
                reportData: [
                    {
                        [key]: renOrRecordNumber,
                        reportIds,
                        displayReportIds,
                    },
                ],
            };
        });
    };

    return {
        reportCaseLinks: [
            // one NItem per REN
            ...formatTitlesToReportCaseLink(reportShortTitlesByREN, 'reportingEventNumber'),
            // one NItem per record number
            // while the individual RWRs within each `reportShortTitles` array are not actually linked to each other, it's still
            // possible for different RWRs to have the same record number, and we group them together in one NItem
            ...formatTitlesToReportCaseLink(
                reportShortTitlesByRecordWithoutRENRecordNumber,
                'recordNumber'
            ),
        ],
    };
}

export function convertReportShortTitleWrapperToReportsPerCaseFormModel(
    reportShortTitleWrapper: ReportShortTitleWrapper,
    reportCaseLinks: ReasonForRelationFormNItemDataShape[]
) {
    const {
        reportShortTitlesByREN,
        reportShortTitlesByRecordWithoutRENRecordNumber,
    } = reportShortTitleWrapper;

    const selectedReportIds = getUniqueReportIds(reportCaseLinks);

    const formatTitlesToReportData = (
        titles: Record<string, ReportShortTitle[]>,
        key: 'reportingEventNumber' | 'recordNumber'
    ) => {
        return Object.entries(titles).map(([renOrRecordNumber, reportShortTitles]) => {
            const displayReportIds = reportShortTitles.map(({ reportId }) => reportId);
            const reportIds = selectedReportIds
                ? displayReportIds.filter((reportId) => selectedReportIds.includes(reportId))
                : displayReportIds;

            return {
                [key]: renOrRecordNumber,
                reportIds,
                displayReportIds,
            };
        });
    };

    const reportData = [
        ...formatTitlesToReportData(reportShortTitlesByREN, 'reportingEventNumber'),
        ...formatTitlesToReportData(
            reportShortTitlesByRecordWithoutRENRecordNumber,
            'recordNumber'
        ),
    ];

    return {
        reportCaseLinks: reportCaseLinks.map(
            ({ caseId, reasonForRelationAttrId, reasonForRelationOther }) => {
                return {
                    caseId,
                    reasonForRelationAttrId,
                    reasonForRelationOther,
                    reportData,
                };
            }
        ),
    };
}

export function convertFromFormModelToCaseRequest(
    formModel: ReasonForRelationToCaseFormDataShape,
    caseId: number,
    individualReportSelectionEnabled?: boolean
): CaseReportLinkCreationFromCaseRequestWrapper {
    const { reportCaseLinks = [] } = formModel;
    const caseReportLinkCreationFromCaseRequests = reportCaseLinks.reduce<
        CaseReportLinkCreationFromCaseRequest[]
    >((requests, nItem) => {
        const { reportData = [] } = nItem;
        const reasonForRelation = getCaseReportLinkAttributeView(nItem);

        const results = reportData.flatMap<CaseReportLinkCreationFromCaseRequest>(
            ({ reportingEventNumber, reportIds = [] }) => {
                const isRen = !!reportingEventNumber && !individualReportSelectionEnabled;
                return isRen
                    ? [
                          {
                              linkedReportREN: reportingEventNumber,
                              reasonForRelation,
                          },
                      ]
                    : reportIds.map((reportId) => ({
                          linkedRecordWithoutRENId: reportId,
                          reasonForRelation,
                      }));
            }
        );
        return [...requests, ...results];
    }, []);

    return {
        caseId,
        caseReportLinkCreationFromCaseRequests,
    };
}

export function convertFromFormModelToReportIdentifiers(
    formModel: ReasonForRelationToCaseFormDataShape
): ReportIdentifier[] {
    const { reportCaseLinks = [] } = formModel;

    return reportCaseLinks.reduce<ReportIdentifier[]>((acc, item) => {
        const { reportData = [] } = item;
        const caseReportIdentifiers = reportData.flatMap(
            ({ reportingEventNumber, recordNumber, reportIds = [] }) => {
                return reportIds.map((id) => ({
                    id,
                    reportingEventNumber,
                    recordNumber,
                }));
            }
        );

        for (const caseReportIdentifier of caseReportIdentifiers) {
            if (!acc.find(({ id }) => id === caseReportIdentifier.id)) {
                acc.push(caseReportIdentifier);
            }
        }

        return acc;
    }, []);
}

export function convertFromFormModelToReportRequest(
    formModel: ReasonForRelationToCaseFormDataShape
): CaseReportLinkCreationFromReportRequestWrapper {
    const { reportCaseLinks = [] } = formModel;

    const caseReportLinkCreationFromReportRequests: CaseReportLinkCreationFromReportRequest[] = reportCaseLinks.map(
        (reportCaseLink) => ({
            caseId: reportCaseLink.caseId || -1,
            reasonForRelation: getCaseReportLinkAttributeView(reportCaseLink),
        })
    );

    return {
        reportIds: getUniqueReportIds(reportCaseLinks),
        caseReportLinkCreationFromReportRequests,
    };
}

export function convertFromFormModelToReportBatchRequest(
    formModel: ReasonForRelationToCaseFormDataShape
): CaseReportLinkCreationFromReportRequestWrapper[] {
    const { reportCaseLinks = [] } = formModel;

    return reportCaseLinks.map((item) => {
        const { caseId = -1, reportData = [] } = item;
        return {
            caseReportLinkCreationFromReportRequests: [
                // we always send one item for batch request
                { caseId, reasonForRelation: getCaseReportLinkAttributeView(item) },
            ],
            reportIds: reportData.flatMap(({ reportIds = [] }) => reportIds),
        };
    });
}

export function convertFromFormModelToCaseDefaultsRequest(
    formModel: ReasonForRelationToCaseFormDataShape,
    caseDefinitionId: number
): CaseDefaultsRequest {
    const { reportCaseLinks = [] } = formModel;

    return {
        caseDefinitionId,
        reportIds: getUniqueReportIds(reportCaseLinks),
    };
}

export function resetReasonForRelationForm(formContext: RefContextEnumType) {
    const form = formsRegistry.get(formContext);

    if (form) {
        form.resetUi();
        form.resetModel();
    }
}

export const getReasonForRelationForm = (hideReasonForRelation: boolean) => ({
    configuration: getReasonForRelationConfig(hideReasonForRelation),
    lifecycle: lifecycleOptions.REGISTER_AND_RETAIN,
});
