import React from 'react';
import { LinkTypesEnum } from '@mark43/rms-api';
import { cssVar, HStack, Text, VStack } from 'arc';
import { withRouter, WithRouterProps } from 'react-router';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { ImportInvolvedProfilesSidePanel } from '../sidepanel/ImportInvolvedProfilesSidePanel';
import { EFileProfilesGrid } from '../profiles/EFileProfilesGrid';

const strings = componentStrings.eFiles.defendants;

const Header = () => {
    return (
        <HStack justify="space-between" align="start" width="100%">
            <Text variant="headingMd">{strings.header.title}</Text>
            <ImportInvolvedProfilesSidePanel linkType={LinkTypesEnum.DEFENDANT_IN_EFILE} />
        </HStack>
    );
};

const EFileDefendantsPageComponent = ({ params }: WithRouterProps) => {
    return (
        <VStack height="100%" gap={cssVar('arc.space.3')}>
            <Header />
            <EFileProfilesGrid
                eFileId={parseInt(params.eFileId)}
                linkType={LinkTypesEnum.DEFENDANT_IN_EFILE}
            />
        </VStack>
    );
};

export const EFileDefendantsPage = withRouter(EFileDefendantsPageComponent);
