import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

import { cssVar, HStack } from 'arc';
import HelpText from '../../../../legacy-redux/components/core/HelpText';
import testIds from '../../../../core/testIds';
import { IconButton } from '../../../core/components/IconButton';

const AdminHeaderDiv = styled(HStack)`
    justify-content: space-between;
    position: relative;
    width: 100%;
    height: 36px;
    color: var(--arc-colors-surface-foreground);
    background-color: ${(props) => props.theme.colors.mediumGrey};
    box-sizing: border-box;
    overflow: hidden;

    &.highlight {
        background-color: var(--arc-colors-brand-default);
    }

    &.error {
        background-color: var(--arc-colors-negative-default);
    }
`;

const AdminHeaderTitleContainer = styled.div`
    padding: 10px 12px;
    font-size: var(--arc-fontSizes-sm);
    font-family: var(--arc-fonts-body);
    // prevent overflow
    /* box-sizing: border-box; */
    white-space: nowrap;
    overflow: hidden;
    min-width: fit-content;
`;

const AdminHeaderTitle = styled.div`
    display: inline-block;
`;

const AdminHeaderHelpText = styled.div`
    display: inline-block;
`;

const AdminHeader: React.FC<{
    title: string | React.ReactElement;
    helpText?: string;
    onNewItemClick?: () => void;
    searchable?: boolean;
    onSearchClick?: () => void;
    highlight?: boolean;
    error?: boolean;
    renderActions?: () => React.ReactElement;
}> = ({
    title,
    helpText,
    onNewItemClick,
    searchable,
    onSearchClick,
    highlight,
    error,
    renderActions,
}) => {
    return (
        <AdminHeaderDiv
            className={classNames({ highlight, error })}
            data-test-id={testIds.ADMIN_HEADER}
        >
            <AdminHeaderTitleContainer>
                <AdminHeaderTitle>{title}</AdminHeaderTitle>
                {helpText && (
                    <AdminHeaderHelpText>
                        <HelpText content={helpText} />
                    </AdminHeaderHelpText>
                )}
            </AdminHeaderTitleContainer>
            <HStack>
                {typeof renderActions === 'function' && renderActions()}
                {searchable && (
                    <IconButton
                        aria-label="Admin header search button"
                        // @ts-expect-error color prop works here
                        // and design specified this color
                        color={cssVar('arc.colors.raw.blue.300')}
                        icon="Search"
                        onClick={onSearchClick}
                        testId={testIds.ADMIN_SEARCH_ICON}
                        variant="ghost"
                    />
                )}
                {!!onNewItemClick && (
                    <IconButton
                        // @ts-expect-error color prop works here
                        // and design specified this color
                        color={cssVar('arc.colors.raw.blue.300')}
                        icon="Add"
                        variant="ghost"
                        onClick={onNewItemClick}
                    />
                )}
            </HStack>
        </AdminHeaderDiv>
    );
};

export default AdminHeader;
