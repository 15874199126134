import { EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import Promise from 'bluebird';

import _, { compact, map, find } from 'lodash';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import getAttachmentsResource from '~/client-common/core/domain/attachments/resources/attachmentsResource';
import {
    storeAttachments,
    saveAttachmentsForEntities,
} from '~/client-common/core/domain/attachments/state/data';
import {
    loadOptOuts,
    notificationsSettingsVisibilitySelector,
} from '~/client-common/core/domain/user-opt-outs/state/data';
import { loadNotificationTypes } from '~/client-common/core/domain/notification-types/state/data';
import {
    storeUserAssignments,
    saveUserAssignments,
} from '~/client-common/core/domain/user-assignments/state/data';
import {
    storeAttributes,
    attributeIsOtherSelector,
} from '~/client-common/core/domain/attributes/state/data';
import { convertAttributeToAttributeView } from '~/client-common/core/domain/attributes/utils/attributesHelpers';
import { loadLocationsLinkedToEntity } from '~/client-common/core/domain/locations/state/data';
import { loadAbilityRoleLinksForRoleBatch } from '~/client-common/core/domain/ability-role-links/state/data';
import { loadAbilities } from '~/client-common/core/domain/abilities/state/data';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import oriAliasResource from '~/client-common/core/domain/dex-devices/resources/oriAliasResource';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import {
    currentUserIdSelector,
    currentUserHasAbilitySelector,
    currentUserDepartmentIdSelector,
} from '../../modules/core/current-user/state/ui';
import { uploadingFilesSelector } from '../../modules/attachments/core/state/ui/inlineAttachmentsUploader';
import userAccountResource from '../../modules/users/resources/userAccountResource';

import { filterNItemsFormData } from '../helpers/formHelpers';
import {
    convertUserProfileFormModel,
    convertAttributesFormModelToUserAttributes,
    convertPhonesFormModelToUserPhones,
} from '../helpers/userProfileAdminHelpers';
import {
    uiSelector,
    userProfileAdminPermissionSelector,
} from '../selectors/userProfileAdminSelectors';
import rolesResource from '../resources/rolesResource';
import userProfileAdminResource from '../resources/userProfileAdminResource';
import {
    closeBox,
    saveBoxSuccess,
    saveBoxFailure,
    saveBoxStart,
    saveBoxHalt,
    storeBoxErrorMessages,
} from './boxActions';
import { loadUserRolesSuccess } from './rolesActions';
import userProfileAdminActionTypes from './types/userProfileAdminActionTypes';

const context = {
    name: boxEnum.USER_PROFILE_ADMIN_SIDE_PANEL,
};

function pageLoadStart() {
    return {
        type: userProfileAdminActionTypes.PAGE_LOAD_START,
    };
}

function pageLoadError(err) {
    return {
        type: userProfileAdminActionTypes.PAGE_LOAD_ERROR,
        error: true,
        payload: err,
    };
}

function loadUserProfileSuccess(profile) {
    return {
        type: userProfileAdminActionTypes.LOAD_USER_PROFILE_SUCCESS,
        payload: profile,
    };
}

function saveUserProfileSuccess(profile) {
    return {
        type: userProfileAdminActionTypes.SAVE_USER_PROFILE_SUCCESS,
        payload: profile,
    };
}

function saveUserProfileFailure() {
    return {
        type: userProfileAdminActionTypes.SAVE_USER_PROFILE_FAILURE,
    };
}

function saveUserAccountSuccess(user) {
    return {
        type: userProfileAdminActionTypes.SAVE_USER_ACCOUNT_SUCCESS,
        payload: user,
    };
}

function saveUserAccountStatusSuccess(isDisabled) {
    return {
        type: userProfileAdminActionTypes.SAVE_USER_ACCOUNT_STATUS_SUCCESS,
        payload: isDisabled,
    };
}

function loadUserAttributesSuccess(attributes) {
    return {
        type: userProfileAdminActionTypes.LOAD_USER_ATTRIBUTES_SUCCESS,
        payload: attributes,
    };
}

function saveUserAttributesSuccess(attributes) {
    return {
        type: userProfileAdminActionTypes.SAVE_USER_ATTRIBUTES_SUCCESS,
        payload: attributes,
    };
}

function loadUserDutyStatusesSuccess(dutyStatuses) {
    return {
        type: userProfileAdminActionTypes.LOAD_USER_DUTY_STATUSES_SUCCESS,
        payload: dutyStatuses,
    };
}

function saveUserDutyStatusesSuccess(dutyStatuses) {
    return {
        type: userProfileAdminActionTypes.SAVE_USER_DUTY_STATUSES_SUCCESS,
        payload: dutyStatuses,
    };
}

function loadUserPhonesSuccess(phones) {
    return {
        type: userProfileAdminActionTypes.LOAD_USER_PHONES_SUCCESS,
        payload: phones,
    };
}

function saveUserPhonesSuccess(phones) {
    return {
        type: userProfileAdminActionTypes.SAVE_USER_PHONES_SUCCESS,
        payload: phones,
    };
}

function resendConfirmationStart() {
    return {
        type: userProfileAdminActionTypes.RESEND_CONFIRMATION_START,
    };
}

function resendConfirmationSuccess() {
    return {
        type: userProfileAdminActionTypes.RESEND_CONFIRMATION_SUCCESS,
    };
}

function resendConfirmationFailure(errorMessage) {
    return {
        type: userProfileAdminActionTypes.RESEND_CONFIRMATION_FAILURE,
        payload: errorMessage,
    };
}

function unlockUserAccountStart() {
    return {
        type: userProfileAdminActionTypes.UNLOCK_USER_ACCOUNT_START,
    };
}

function unlockUserAccountSuccess() {
    return {
        type: userProfileAdminActionTypes.UNLOCK_USER_ACCOUNT_SUCCESS,
    };
}

function unlockUserAccountFailure(errorMessage) {
    return {
        type: userProfileAdminActionTypes.UNLOCK_USER_ACCOUNT_FAILURE,
        payload: errorMessage,
    };
}

export function resetUserProfileForm(ui) {
    return {
        type: userProfileAdminActionTypes.RESET_USER_PROFILE_FORM,
        payload: ui,
    };
}

function setUserAssignmentFormValues(userAssignments) {
    return {
        type: userProfileAdminActionTypes.SET_USER_ASSIGNMENT_FORM_VALUES,
        payload: userAssignments,
    };
}

function loadUserAttachments(userId) {
    const attachmentsResource = getAttachmentsResource();
    return attachmentsResource.loadAttachmentsByEntityId(EntityTypeEnum.USER.name, userId);
}

function loadUserProfileAttachmentsSuccess(attachments) {
    return {
        type: userProfileAdminActionTypes.LOAD_USER_PROFILE_ATTACHMENTS_SUCCESS,
        payload: attachments,
    };
}

function saveUserProfileAttachmentsSuccess(attachments) {
    return {
        type: userProfileAdminActionTypes.SAVE_USER_PROFILE_ATTACHMENTS_SUCCESS,
        payload: attachments,
    };
}

export const resetUserProfileAdminState = () => ({
    type: userProfileAdminActionTypes.RESET_USER_PROFILE_ADMIN_STATE,
});

function loadUserProfileMultiStateOris(userId) {
    return oriAliasResource.getStateOrisForUser(userId);
}

/**
 * @param {{ dexUserIdValue: string; oriAliasId: number }[]} multiStateOris
 */
function loadUserProfileMultiStateOrisSuccess(multiStateOris) {
    return {
        type: userProfileAdminActionTypes.LOAD_MULTI_STATE_ORIS_SUCCESS,
        payload: multiStateOris,
    };
}

/**
 * @param {{ dexUserIdValue: string; oriAliasId: number }[]} multiStateOris
 */
function saveMultiStateOrisSuccess(multiStateOris) {
    return {
        type: userProfileAdminActionTypes.SAVE_MULTI_STATE_ORIS_SUCCESS,
        payload: multiStateOris,
    };
}

/**
 * @param {string} userId
 * @param {{ dexUserIdValue: string; oriAliasId: number }[]} multiStateOris
 */
function saveMultiStateOris(userId, multiStateOris) {
    return oriAliasResource.upsertMultiStateOrisForUser(userId, {
        dexStateUserIds: multiStateOris.map((ori) => ({
            ...ori,
            userId,
        })),
    });
}

const dummyMultiStateOriIds = { dexStateUserIds: [] };

export function pageLoad(userId) {
    return function (dispatch, getState) {
        dispatch(pageLoadStart());

        const isMultipleOriEnabled =
            applicationSettingsSelector(getState()).DEX_MULTIPLE_ORI_ENABLED;
        // if the notifications feature flag is on and the user has the most
        // basic notifications ability, store all the opt outs and notification types in state,
        const currentUserId = currentUserIdSelector(getState());
        const hasPermission = notificationsSettingsVisibilitySelector(getState())(
            userId,
            currentUserId
        );

        if (hasPermission) {
            dispatch(loadOptOuts(userId, currentUserId));
            dispatch(loadNotificationTypes());
        }

        // Check if the user is attempting to access their own profile
        const isRequestForCurrentUser = userId === currentUserIdSelector(getState());
        let fullProfileRequest = userProfileAdminResource.getCurrentFullProfile;

        const currentUserHasAbility = currentUserHasAbilitySelector(getState());

        if (!isRequestForCurrentUser) {
            // Check if the current user has permissions to view other user profile.
            const hasPermissions = currentUserHasAbility(abilitiesEnum.ADMIN.VIEW_GLOBAL_USERS);

            if (!isRequestForCurrentUser && !hasPermissions) {
                return dispatch(pageLoadError());
            }

            fullProfileRequest = userProfileAdminResource.getFullProfile;
        }

        const hasViewGlobalRoleConfiguration = currentUserHasAbility(
            abilitiesEnum.ADMIN.VIEW_GLOBAL_ROLE_CONFIGURATION
        );

        Promise.all([
            fullProfileRequest(userId),
            hasViewGlobalRoleConfiguration
                ? rolesResource.getUserRoles(userId).then((roles) => {
                      const roleIds = map(roles, 'id');
                      return dispatch(loadAbilityRoleLinksForRoleBatch(roleIds)).then(() => roles);
                  })
                : [],
            loadUserAttachments(userId),
            isMultipleOriEnabled
                ? loadUserProfileMultiStateOris(userId)
                : Promise.resolve(dummyMultiStateOriIds),
            dispatch(loadLocationsLinkedToEntity(EntityTypeEnum.USER.name, userId)),
            dispatch(loadAbilities()),
        ])
            .spread((fullProfile, roles, attachments, multiStateOris) => {
                dispatch(loadUserProfileMultiStateOrisSuccess(multiStateOris));
                dispatch(loadUserProfileSuccess(fullProfile.profile));
                dispatch(loadUserDutyStatusesSuccess(fullProfile.dutyStatusHistories));
                dispatch(loadUserPhonesSuccess(fullProfile.phones));
                dispatch(
                    storeAttributes(map(fullProfile.attributes, convertAttributeToAttributeView))
                );
                dispatch(loadUserAttributesSuccess(fullProfile.userAttributes));
                dispatch(storeUserAssignments(fullProfile.assignments));
                dispatch(setUserAssignmentFormValues(fullProfile.assignments));
                dispatch(loadUserRolesSuccess(_.map(roles, 'userRole')));
                // write to data state
                dispatch(storeAttachments(attachments));
                // write to UI and form state
                dispatch(loadUserProfileAttachmentsSuccess(attachments));
            })
            .catch(() => {
                dispatch(pageLoadError());
            });
    };
}

export function cancelUserProfilePanel({ clearForm = false } = {}) {
    return (dispatch, getState) => {
        dispatch(closeBox(context));
        // to force clear the form we send an undefined payload,
        // which will force the form back into an empty state instead
        // of populating it with the current profile
        const profileData = clearForm ? undefined : uiSelector(getState());
        dispatch(resetUserProfileForm(profileData));
    };
}

function enableOrDisableUser(userProfile) {
    if (userProfile.isDisabled) {
        userProfileAdminResource.disableUser(userProfile.userId);
        return Promise.resolve(true);
    } else {
        userProfileAdminResource.enableUser(userProfile.userId);
        return Promise.resolve(false);
    }
}

function updateUserAccount(userProfile) {
    return userProfileAdminResource.updateUserAccount({
        ...userProfile.user,
        isDisabled: userProfile.isDisabled,
        primaryEmail: userProfile.primaryEmail,
        isSsoUser: userProfile.isSsoUser,
    });
}

function createUser({ userProfile, user }) {
    return userProfileAdminResource.createUser({
        user,
        dutyStatus: userProfile.dutyStatus,
        isDisabled: userProfile.isDisabled,
        primaryEmail: userProfile.primaryEmail,
        isSsoUser: userProfile.isSsoUser,
        userProfile,
        userProfileCjisExtension: userProfile.userProfileCjisExtension,
    });
}

/**
 * This is the default action to save the _full_ UserProfileAdminForm.
 *   Note: this is similar to _saveSelfEditProfile_ but uses a different resource.
 */
function saveUserProfile({
    userProfile: userProfileFormData,
    canEditAccount,
    canEditProfile,
    isEditing,
}) {
    const filteredData = filterNItemsFormData(
        filterNItemsFormData(userProfileFormData, 'userAssignments'),
        'phones'
    );
    return (dispatch, getState) => {
        const state = getState();
        const isMultiOriEnabled = applicationSettingsSelector(state).DEX_MULTIPLE_ORI_ENABLED;
        userProfileFormData = convertUserProfileFormModel(userProfileFormData, {
            attributeIsOther: attributeIsOtherSelector(state),
        });

        const currentUserDepartmentId = currentUserDepartmentIdSelector(state);
        const userProfilePromise = isEditing
            ? Promise.resolve(userProfileFormData)
            : createUser({
                  userProfile: {
                      ...userProfileFormData,
                      departmentId: currentUserDepartmentId,
                  },
                  user: {
                      departmentId: currentUserDepartmentId,
                      isDisabled: userProfileFormData.isDisabled,
                      userRoleId: null,
                  },
              }).then(({ userProfile }) => userProfile);

        return userProfilePromise
            .then((userProfile) => {
                userProfile.user.isDisabled = userProfile.isDisabled;
                return Promise.all([
                    // Only update account if we are editing
                    isEditing ? userProfileAdminResource.updateProfile(userProfile) : userProfile,
                    isEditing && canEditAccount ? updateUserAccount(userProfile) : null,
                    isEditing && canEditProfile ? enableOrDisableUser(userProfile) : null,
                    saveUserAttributes(userProfile.userId, userProfileFormData.attributes),
                    saveUserDutyStatuses(userProfile.userId, userProfileFormData.dutyStatuses),
                    saveUserPhones(userProfile.userId, filteredData.phones),
                    isMultiOriEnabled
                        ? saveMultiStateOris(
                              userProfile.userId,
                              userProfileFormData.dexStateUserIdOriAliasAssociations
                          )
                        : Promise.resolve(dummyMultiStateOriIds),
                    dispatch(
                        saveUserAssignments(
                            userProfile.userId,
                            currentUserDepartmentId,
                            filteredData.userAssignments
                        )
                    ),
                    dispatch(saveUserProfilePhoto(userProfile.userId, userProfileFormData)),
                    dispatch(saveUserSignature(userProfile.userId, userProfileFormData)),
                ]);
            })
            .spread(
                (
                    profile,
                    user,
                    isDisabled,
                    attributes,
                    dutyStatuses,
                    phones,
                    multiStateOris,
                    userAssignments
                ) => {
                    dispatch(saveUserProfileSuccess(profile));
                    dispatch(setUserAssignmentFormValues(userAssignments));

                    if (isEditing && canEditAccount) {
                        dispatch(saveUserAccountSuccess(user));
                    }

                    if (isEditing && canEditProfile) {
                        dispatch(saveUserAccountStatusSuccess(isDisabled));
                    }

                    dispatch(saveUserAttributesSuccess(attributes));
                    dispatch(saveUserDutyStatusesSuccess(dutyStatuses));
                    dispatch(saveUserPhonesSuccess(phones));
                    dispatch(saveMultiStateOrisSuccess(multiStateOris));

                    return profile;
                }
            )
            .catch((err) => {
                dispatch(saveUserProfileFailure(err.message));
                throw err;
            });
    };
}

function saveUserDutyStatuses(userId, dutyStatuses) {
    const dutyStatusesWithUserId = _(dutyStatuses)
        .filter((dutyStatus) => {
            return dutyStatus.dutyStatus || dutyStatus.dateEffective;
        })
        .map((dutyStatus) => {
            return { ...dutyStatus, userId };
        })
        .value();

    return userProfileAdminResource.updateUserDutyStatuses(userId, dutyStatusesWithUserId);
}

function saveUserPhones(userId, phones) {
    const mappedPhones = convertPhonesFormModelToUserPhones(userId, phones);

    return userProfileAdminResource.updateUserPhones(userId, mappedPhones);
}

function saveUserAttributes(userId, attributes) {
    const mappedAttributes = convertAttributesFormModelToUserAttributes(userId, attributes);

    return userProfileAdminResource.updateAttributes(userId, mappedAttributes);
}

function saveUserProfilePhoto(userId, userProfileFormData) {
    return (dispatch) => {
        // This awkward double field is required to know on a redux form if we have removed the
        // existing profile photo, or just left it untouched.
        if (
            !userProfileFormData.userProfilePhotoId &&
            !userProfileFormData.removedUserProfilePhotoId
        ) {
            return;
        }
        const newUserProfilePhotoAttachment = userProfileFormData.userProfilePhotoId
            ? {
                  entityId: userId,
                  attachmentId: userProfileFormData.userProfilePhotoId,
                  attachmentType: EntityTypeEnum.IMAGE.name,
                  entityType: EntityTypeEnum.USER.name,
                  linkType: LinkTypesEnum.USER_PHOTO,
              }
            : undefined;
        const attachments = compact([newUserProfilePhotoAttachment]);

        // saveAttachmentsForEntities writes to data state
        return dispatch(
            saveAttachmentsForEntities({
                entityType: EntityTypeEnum.USER.name,
                entityIds: [userId],
                attachments,
                removeOthers: true,
                linkTypes: [LinkTypesEnum.USER_PHOTO],
            })
        ).then((attachments) => {
            // write to UI and form state
            dispatch(saveUserProfileAttachmentsSuccess(attachments));
            return attachments;
        });
    };
}

function saveUserSignature(userId, userProfileFormData) {
    return (dispatch) => {
        // This awkward double field is required to know on a redux form if we have removed the
        // existing profile photo, or just left it untouched.
        if (!userProfileFormData.userSignature && !userProfileFormData.removedUserSignature) {
            return;
        }
        const newUserSignatureAttachment = userProfileFormData.userSignature
            ? {
                  entityId: userId,
                  attachmentId: userProfileFormData.userSignature,
                  attachmentType: EntityTypeEnum.IMAGE.name,
                  entityType: EntityTypeEnum.USER.name,
                  linkType: LinkTypesEnum.USER_SIGNATURE,
              }
            : undefined;
        const attachments = compact([newUserSignatureAttachment]);

        // saveAttachmentsForEntities writes to data state
        return dispatch(
            saveAttachmentsForEntities({
                entityType: EntityTypeEnum.USER.name,
                entityIds: [userId],
                attachments,
                removeOthers: true,
                linkTypes: [LinkTypesEnum.USER_SIGNATURE],
            })
        ).then((attachments) => {
            // write to UI and form state
            dispatch(saveUserProfileAttachmentsSuccess(attachments));
            return attachments;
        });
    };
}

/**
 * When user has CORE.EDIT_USER_ACCOUNT_SETTINGS but not ADMIN.EDIT_GLOBAL_USERS ability,
 *   we display a subset of the UserProfileAdminForm for the user to edit.
 *   Note: this is similar to _saveUserProfile_ but uses a different resource
 */
function saveSelfEditProfile(userProfile) {
    const { userId } = userProfile;

    return (dispatch, getState) => {
        const state = getState();

        userProfile = convertUserProfileFormModel(userProfile, {
            attributeIsOther: attributeIsOtherSelector(state),
        });
        const userAttributes = convertAttributesFormModelToUserAttributes(
            userId,
            userProfile.attributes
        );
        const userPhones = convertPhonesFormModelToUserPhones(
            userId,
            filterNItemsFormData(userProfile, 'phones').phones
        );

        return Promise.all([
            userAccountResource.updateUserProfile(userProfile),
            userAccountResource.setUserAttributes(userAttributes),
            userAccountResource.addUserPhones(userPhones),
            dispatch(saveUserProfilePhoto(userId, userProfile)),
            dispatch(saveUserSignature(userId, userProfile)),
        ])
            .spread((userProfile, attributes, phones) => {
                dispatch(saveUserProfileSuccess(userProfile));
                dispatch(saveUserAttributesSuccess(attributes));
                dispatch(saveUserPhonesSuccess(phones));

                return userProfile;
            })
            .catch((err) => {
                dispatch(saveUserProfileFailure(err.message));
                throw err;
            });
    };
}

export function saveUserProfilePanel({ userProfile, isEditing, router }) {
    return (dispatch, getState) => {
        const state = getState();
        const { canEditAccount, canEditProfile, canSelfEditProfile } =
            userProfileAdminPermissionSelector(state);

        const isSelfEditProfile = canSelfEditProfile && !canEditProfile;

        const savePromise = isSelfEditProfile
            ? dispatch(saveSelfEditProfile(userProfile))
            : dispatch(saveUserProfile({ userProfile, canEditAccount, canEditProfile, isEditing }));

        // running requests in sequence and not parallel to make augmentation of attachments
        // with entity id easier
        return savePromise
            .then((profile) => {
                // `userProfilePhotoId` is a FE only field. Since we are re-using the server response
                // here to cover editing and creating of users we have to re-assign a possible
                // value to get user profile attachment uploads to work.
                profile.userProfilePhotoId = userProfile.userProfilePhotoId;
                return profile;
            })
            .then((profile) => {
                dispatch(saveBoxSuccess(context));
                dispatch(closeBox(context));
                if (!isEditing) {
                    router.push(`admin/users/${profile.userId}`);
                }
                return profile;
            })
            .catch((err) => dispatch(saveBoxFailure(context, err.message)));
    };
}

export function fileUploadStart() {
    return (dispatch) => {
        dispatch(saveBoxStart(context));
    };
}

export function fileUploadFailure() {
    return (dispatch) => {
        dispatch(saveBoxFailure(context));
    };
}

export function fileUploadSuccess() {
    return (dispatch, getState) => {
        const uploadingFiles = uploadingFilesSelector(getState());
        if (!hasActiveUploads(uploadingFiles)) {
            dispatch(saveBoxHalt(context));
        }
        if (!hasFailedUploads(uploadingFiles)) {
            dispatch(storeBoxErrorMessages(context, []));
        }
    };
}

function hasFailedUploads(uploadingFiles) {
    return find(uploadingFiles, (uploadingFile) => {
        return uploadingFile.file.uploadFailed && !uploadingFile.file.isDeleted;
    });
}

function hasActiveUploads(uploadingFiles) {
    return find(uploadingFiles, (uploadingFile) => {
        return uploadingFile.file.isLoading && !uploadingFile.file.isDeleted;
    });
}

export function resendConfirmation(userId) {
    return (dispatch) => {
        dispatch(resendConfirmationStart());

        return userProfileAdminResource
            .resendUserEmailConfirmation([userId])
            .then(() => dispatch(resendConfirmationSuccess()))
            .catch((err) => dispatch(resendConfirmationFailure(err.message)));
    };
}

export function unlockUserAccount(userId) {
    return (dispatch) => {
        dispatch(unlockUserAccountStart());
        return userProfileAdminResource
            .unlockUserAccount(userId)
            .then(() => dispatch(unlockUserAccountSuccess()))
            .catch((err) => dispatch(unlockUserAccountFailure(err.message)));
    };
}
