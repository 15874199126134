import styled from 'styled-components';
import { IconButton as _IconButton, Menu, MenuContent, MenuItem, MenuTrigger } from 'arc';
import { LinkTypesEnum, LinkTypesEnumType } from '@mark43/rms-api';
import React, { forwardRef, useCallback, useMemo } from 'react';
import { InjectedRouter } from 'react-router';
import { useResourceDeferred } from '~/client-common/core/hooks/useResource';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { useEFileDisplayName } from '../../../hooks/useEFileDisplayName';
import { useEFileContext } from '../../../hooks/useEFileContext';
import { eFileResource } from '../../../resources/eFileResource';
import { EFILE_LINK_TYPE_TO_NAME_MAP } from '../../../constants';
import testIds from '../../../../../core/testIds';

const strings = componentStrings.eFiles.core.iceCreamMenu;

const IconButton = styled(_IconButton)`
    border: none;
`;

type IceCreamMenuT = {
    isDeactivated: boolean;
    eFileId: number;
    contextedProfileId: number;
    linkType: LinkTypesEnumType;
    eFileLinkId: number;
    router?: InjectedRouter;
};

export const IceCreamMenu = forwardRef(
    (
        {
            isDeactivated,
            eFileId,
            contextedProfileId,
            linkType,
            eFileLinkId,
            router,
        }: IceCreamMenuT,
        /** This ref is to prevent the menu option clicks from propagating into the parent's
         *   onClick handler. One option is to use the e.stopPropagation() but that doesn't
         *   work for the menu options so a ref is used.
         */
        ref: React.Ref<HTMLDivElement> | undefined
    ) => {
        const { eFileDisplayName } = useEFileDisplayName();
        const {
            getters,
            handlers: {
                updateInvolvedNamesDeactivatedState,
                updateInvolvedProfileDeactivatedState,
                removeInvolvedNamesFromState,
                refreshInvolvedProfiles,
            },
        } = useEFileContext();
        const eFileViewModel = getters.efile.getEFileViewModel();
        const isDisabled = !eFileViewModel?.canEdit;

        const getDashboard = useMemo(() => {
            switch (linkType) {
                case LinkTypesEnum.DEFENDANT_IN_EFILE:
                    return `e-file/${eFileId}/defendants`;
                case LinkTypesEnum.ITEM_IN_EFILE:
                case LinkTypesEnum.ATTACHMENT_IN_EFILE:
                    return `e-file/${eFileId}/material`;
                default:
                    return `e-file/${eFileId}/summary`;
            }
        }, [eFileId, linkType]);

        const { callResource: onDeactivate } = useResourceDeferred(
            eFileResource.deactivateInvolvedNames,
            useCallback(
                (success) => {
                    if (success) {
                        updateInvolvedNamesDeactivatedState(eFileLinkId, !isDeactivated);
                        updateInvolvedProfileDeactivatedState(eFileLinkId, !isDeactivated);
                    }
                },
                [
                    eFileLinkId,
                    isDeactivated,
                    updateInvolvedNamesDeactivatedState,
                    updateInvolvedProfileDeactivatedState,
                ]
            )
        );

        const { callResource: onRemove } = useResourceDeferred(
            eFileResource.removeInvolvedEntities,
            useCallback(
                (success) => {
                    if (success) {
                        removeInvolvedNamesFromState(eFileLinkId);
                    }
                },
                [eFileLinkId, removeInvolvedNamesFromState]
            )
        );

        const onRemoveCallback = useCallback(async () => {
            // wait before navigating away
            await onRemove({
                // @ts-expect-error mark43-resource type generation of this paramter seems to be incorrect.
                linkType: EFILE_LINK_TYPE_TO_NAME_MAP[linkType],
                contextedIds: [contextedProfileId],
            });

            await refreshInvolvedProfiles();

            if (!!router) {
                router.push(getDashboard);
            }
        }, [contextedProfileId, getDashboard, linkType, onRemove, refreshInvolvedProfiles, router]);

        return (
            <Menu>
                <MenuTrigger isDisabled={isDisabled} asChild>
                    <IconButton
                        icon="MoreActions"
                        aria-label="iceCreamMenuButton"
                        data-test-id={testIds.E_FILE_PROFILE_ROW_MORE_MENU}
                        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                            e.stopPropagation();
                        }}
                    />
                </MenuTrigger>
                <MenuContent align="end" ref={ref}>
                    <MenuItem
                        data-test-id={testIds.E_FILE_PROFILE_ROW_REMOVE}
                        onSelect={onRemoveCallback}
                    >
                        {strings.remove(eFileDisplayName)}
                    </MenuItem>
                    <MenuItem
                        data-test-id={testIds.E_FILE_PROFILE_DEACTIVATE}
                        onSelect={() =>
                            onDeactivate({
                                eFileId,
                                // @ts-expect-error mark43-resource type generation of this paramter seems to be incorrect.
                                linkType: EFILE_LINK_TYPE_TO_NAME_MAP[linkType],
                                deactivate: !isDeactivated,
                                contextedProfileIds: [contextedProfileId],
                            })
                        }
                    >
                        {!isDeactivated
                            ? strings.deactivate(eFileDisplayName)
                            : strings.reactivate(eFileDisplayName)}
                    </MenuItem>
                </MenuContent>
            </Menu>
        );
    }
);
