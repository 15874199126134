import React, { forwardRef, Ref } from 'react';
import { EFileInvolvedProfileGridRowT, EFileLinkTypesT } from '../../../../types';
import { IceCreamMenu } from '../../table/IceCreamMenu';

type EFileProfileActionsCellProps = {
    item: EFileInvolvedProfileGridRowT;
    linkType: EFileLinkTypesT;
    eFileId: number;
};

const EFileProfileActionsCellComponent = (
    { item, linkType, eFileId }: EFileProfileActionsCellProps,
    ref: Ref<HTMLDivElement>
) => {
    return (
        <IceCreamMenu
            ref={ref}
            isDeactivated={item.isDeactivated}
            eFileId={eFileId}
            eFileLinkId={item.eFileLinkId}
            contextedProfileId={item.nameData.entityId}
            linkType={linkType}
        />
    );
};

export const EFileProfileActionsCell = forwardRef(EFileProfileActionsCellComponent);
