import {
    EFileView,
    SearchResultEFileNameSearchView,
    InvolvedProfilesForImport,
    EFileDefendantOrganizationView,
    EFileDefendantPersonView,
    EFileNameSearchRequest,
} from '@mark43/rms-api';
import { EFileSearchRequest, SelectedNameEntityT } from '../../types';

/* ACTION TYPES */
export const actionTypes = {
    SET_E_FILE: 'SET_E_FILE',
    SET_E_FILE_PROFILES: 'SET_E_FILE_PROFILES',
    SET_E_FILE_DEFENDANT_PERSON: 'SET_E_FILE_DEFENDANT_PERSON',
    SET_E_FILE_DEFENDANT_ORGANIZATION: 'SET_E_FILE_DEFENDANT_ORGANIZATION',
    SET_E_FILE_PROFILES_SEARCH_REQUEST: 'SET_E_FILE_PROFILES_SEARCH_REQUEST',
    SET_E_FILE_INVOLVED_PROFILES_TO_IMPORT: 'SET_E_FILE_INVOLVED_PROFILES_TO_IMPORT',
    SET_E_FILE_SELECTED_INVOLVED_PROFILES_TO_IMPORT:
        'SET_E_FILE_SELECTED_INVOLVED_PROFILES_TO_IMPORT',
    RESET_E_FILE_SIDE_PANEL: 'RESET_E_FILE_SIDE_PANEL',
    SET_E_FILE_SELECTED_INVOLVED_PROFILES: 'SET_E_FILE_SELECTED_INVOLVED_PROFILES',
    RESET_E_FILE_GRID: 'RESET_E_FILE_GRID',
} as const;

/* ACTIONS */
export const setEfileAction = (eFile: EFileView) => {
    return {
        type: actionTypes.SET_E_FILE,
        payload: { eFile },
    } as const;
};

export const setEfileProfilesAction = (eFileProfiles: SearchResultEFileNameSearchView) => {
    return {
        type: actionTypes.SET_E_FILE_PROFILES,
        payload: { eFileProfiles },
    } as const;
};

export const setEFileProfilesSearchRequestAction = (
    searchRequest: EFileSearchRequest<EFileNameSearchRequest>
) => {
    return {
        type: actionTypes.SET_E_FILE_PROFILES_SEARCH_REQUEST,
        payload: { searchRequest },
    } as const;
};

export const setEfileDefendantPersonProfileAction = (personProfile: EFileDefendantPersonView) => {
    return {
        type: actionTypes.SET_E_FILE_DEFENDANT_PERSON,
        payload: { personProfile },
    } as const;
};

export const setEfileDefendantOrganizationProfileAction = (
    organizationProfile: EFileDefendantOrganizationView
) => {
    return {
        type: actionTypes.SET_E_FILE_DEFENDANT_ORGANIZATION,
        payload: { organizationProfile },
    } as const;
};

export const setEFileInvolvedProfilesToImportAction = (
    involvedProfilesForImport: InvolvedProfilesForImport
) => {
    return {
        type: actionTypes.SET_E_FILE_INVOLVED_PROFILES_TO_IMPORT,
        payload: { involvedProfilesForImport },
    } as const;
};

export const setEFileSelectedInvolvedProfilesToImportAction = (
    selectedInvolvedProfilesForImport: SelectedNameEntityT[]
) => {
    return {
        type: actionTypes.SET_E_FILE_SELECTED_INVOLVED_PROFILES_TO_IMPORT,
        payload: { selectedInvolvedProfilesForImport },
    } as const;
};

export const resetEFileSidePanelAction = () => {
    return {
        type: actionTypes.RESET_E_FILE_SIDE_PANEL,
    };
};

export const setEFileSelectedInvolvedProfilesAction = (
    selectedInvolvedProfiles: SelectedNameEntityT[]
) => {
    return {
        type: actionTypes.SET_E_FILE_SELECTED_INVOLVED_PROFILES,
        payload: { selectedInvolvedProfiles },
    } as const;
};

export const resetEFileGridAction = () => {
    return {
        type: actionTypes.RESET_E_FILE_GRID,
    };
};

export type EFileActionTypes = ReturnType<
    | typeof setEfileAction
    | typeof setEfileProfilesAction
    | typeof setEFileProfilesSearchRequestAction
    | typeof setEfileDefendantPersonProfileAction
    | typeof setEfileDefendantOrganizationProfileAction
    | typeof setEFileInvolvedProfilesToImportAction
    | typeof setEFileSelectedInvolvedProfilesToImportAction
    | typeof resetEFileSidePanelAction
    | typeof setEFileSelectedInvolvedProfilesAction
    | typeof resetEFileGridAction
>;
