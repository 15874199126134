import React from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { compose, withHandlers } from 'recompose';
import classNames from 'classnames';
import { chain, filter, max, map, uniq, get, every } from 'lodash';
import styled from 'styled-components';
import {
    ButtonGroup as _ButtonGroup,
    IconButton as _IconButton,
    Menu,
    MenuContent,
    MenuItem,
    MenuTrigger,
} from 'arc';
import { ComplianceGroupEnum } from '@mark43/rms-api';
import useFields, {
    useCaseFieldName,
    useOffenseFieldName,
} from '~/client-common/core/fields/hooks/useFields';
import { caseStatusGlobalAttrIdToChildAttrIdsSelector } from '~/client-common/core/domain/case-statuses/state/ui';
import {
    REPORT_REPORTING_EVENT_NUMBER,
    REPORT_RECORD_NUMBER,
    DISPLAY_ONLY_WILL_NOT_INVESTIGATE_LABEL,
    LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_EVENT_LINK_TYPE,
    DISPLAY_ONLY_CASE_SEARCH_LABELS_LABEL,
    DISPLAY_ONLY_PERSONNEL_REPORT_OWNER_LABEL,
} from '~/client-common/core/enums/universal/fields';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { fromSizeToPage } from '~/client-common/helpers/searchHelpers';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';
import sortKeyEnum from '~/client-common/core/enums/universal/sortKeyEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import BatchBanners from '../../batch-operations/components/BatchBanners';
import ResultsHeader from '../../core/components/ResultsHeader';
import ScrollableUnderSubheader from '../../../core/components/ScrollableUnderSubheader';
import CasesResultsFooter from '../../core/components/CasesResultsFooter';
import CasesActionBar from '../../core/components/CasesActionBar';
import CreateManageCaseModal from '../../core/components/CreateManageCaseModal';
import { offenseReportDefinitionForCurrentDepartmentSelector } from '../../../core/report-definitions/state/ui';
import Table from '../../../../legacy-redux/components/core/tables/Table';
import ElasticResultRow from '../../../../legacy-redux/components/core/tables/ElasticResultRow';
import TableColumn from '../../../../legacy-redux/components/core/tables/TableColumn';
import OptionsTableColumn, {
    OptionsTableColumnOption,
} from '../../../../legacy-redux/components/core/tables/OptionsTableColumn';
import TableHeader from '../../../../legacy-redux/components/core/tables/TableHeader';
import TableBody from '../../../../legacy-redux/components/core/tables/TableBody';
import TableColumns from '../../../../legacy-redux/components/core/tables/TableColumns';
import { Tab } from '../../../../legacy-redux/components/core/Tabs';
import CaseDashboardTabs from '../../core/components/CaseDashboardTabs';
import { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import { Button as _Button } from '../../../core/components/Button';
import dateCellFactory from '../../../../legacy-redux/components/core/tables/elasticReportTable/dateCellFactory';
import ReportingEventNumberCell from '../../../../legacy-redux/components/core/tables/elasticReportTable/ReportingEventNumberCell';
import ReportOwnerCell from '../../../../legacy-redux/components/core/tables/elasticReportTable/ReportOwnerCell';
import ReportTypeCell from '../../../../legacy-redux/components/core/tables/elasticReportTable/ReportTypeCell';
import StatCell from '../../../../legacy-redux/components/core/tables/elasticReportTable/StatCell';
import PrimaryLocationCell from '../../../../legacy-redux/components/core/tables/elasticLocationTable/PrimaryLocationCell';
import OffenseLocationCell from '../../../../legacy-redux/components/core/tables/elasticLocationTable/OffenseLocationCell';
import PersonsCell from '../../../../legacy-redux/components/core/tables/elasticReportTable/PersonsCell';
import NarrativeCell from '../../../../legacy-redux/components/core/tables/elasticCaseTable/NarrativeCell';
import RoutingLabelsCell from '../../../../legacy-redux/components/core/tables/elasticReportTable/RoutingLabelsCell';
import { sortKeyToColumnKey } from '../../core/configuration';
import {
    currentUserDepartmentProfileSelector,
    currentUserHasAbilitySelector,
} from '../../../core/current-user/state/ui';
import { abilitiesEnum } from '../../../core/abilities';
import { overlayStore } from '../../../../core/overlayManager';
import { useDashboardScroll } from '../../../search/core/hooks/useDashboardScroll';
import _OverlayButton from '../../../core/overlays/components/OverlayButton';
import {
    unassignedReportsSearch,
    activeTabKeySelector,
    setQueryFromTabClick,
    markReportWillNotInvestigate,
    tabKeys,
    submitUnassignedReportsSearchForm,
    onSaveReasonForRelationSidePanelOnUnassignedReports,
} from '../state/ui';
import { openCreateCaseModal } from '../../core/state/ui';
import { Tooltip } from '../../../core/components/tooltip';
import testIds from '../../../../core/testIds';
import ReasonForRelationSidePanel from '../../core/components/ReasonForRelationSidePanel';
import IndividualReportSelectionSidePanel from '../../core/components/IndividualReportSelectionSidePanel';
import {
    openCreateCaseFromReportsModal,
    openReasonForRelationSidePanel,
} from '../../core/state/ui/openReasonForRelationSidePanel';
import CreateCaseFromMultiReportsModal from './CreateCaseFromMultiReportsModal';
import UnassignedReportsSearchForm from './UnassignedReportsSearchForm';
import WillNotInvestigateModal from './WillNotInvestigateModal';

const EventStartUtcCell = dateCellFactory('eventStartUtc');
const UpdatedDateUtcCell = dateCellFactory('updatedDateUtc');
const ReportCreationDateCell = dateCellFactory('reportCreationDateUtc');

const strings = componentStrings.cases.unassignedReports.UnassignedReports;

const BoldedText = styled.span`
    font-weight: ${(props) => props.theme.fontWeights.bold};
`;

const ButtonGroup = styled(_ButtonGroup)`
    margin-right: 10px;
`;

const buttonStyle = `
    &,
    &:active,
    &:hover {
        background-color: var(--arc-colors-surface-canvas) !important;
        color: var(--arc-colors-brand-default);
    }
`;

const Button = styled(_Button)`
    ${buttonStyle}
`;

const DisabledButton = styled(Button)`
    margin-right: 10px;
`;

const IconButton = styled(_IconButton)`
    ${buttonStyle}
`;

const OverlayButton = styled(_OverlayButton)`
    ${buttonStyle}
`;

const WillNotInvestigateButton = ({
    willNotInvestigateLabel,
    renDisplayName,
    multipleRowsSelected,
    disabled,
}) => {
    const offenseReportDefinition = useSelector(
        offenseReportDefinitionForCurrentDepartmentSelector
    );
    const offenseReportTypeDisplayName = offenseReportDefinition?.name.includes('Report')
        ? offenseReportDefinition.name
        : offenseReportDefinition?.name.concat(' Report');
    const label = strings.willNotInvestigate(willNotInvestigateLabel, multipleRowsSelected);

    if (disabled) {
        return (
            <Tooltip
                side="top"
                content={strings.cannotMarkWillNotInvestigate(
                    label,
                    renDisplayName,
                    offenseReportTypeDisplayName
                )}
            >
                <DisabledButton
                    className={buttonTypes.SECONDARY_BOLD}
                    testId={testIds.ACTION_BAR_WILL_NOT_INVESTIGATE_BUTTON}
                    disabled={true}
                >
                    {label}
                </DisabledButton>
            </Tooltip>
        );
    }
    return (
        <OverlayButton
            className={buttonTypes.SECONDARY_BOLD}
            id={overlayIdEnum.WILL_NOT_INVESTIGATE_MODAL}
            testId={testIds.ACTION_BAR_WILL_NOT_INVESTIGATE_BUTTON}
        >
            {label}
        </OverlayButton>
    );
};

function UnassignedReports({
    // Current Query and Search Results
    results = [],
    query: { from, size, sortKey: currentSortKey, sortType: currentSortType },
    totalCount: totalResults,
    activeColumnKeys,

    // Table UI State
    tableLoading,
    selectedRows,
    highlightedRows,
    activeTabKey,
    allResultsSelected,

    // Event Handlers
    handleBulkRowSelect,
    handleRowClick,
    handleDateOptionClick,
    handleDateSortTypeClick,
    handleEntityOptionClick,
    handlePaginationClick,
    handleSizeChange,
    handleCreateCaseClick,
    handleCreateCaseFromMultiReportsClick,
    handleTabClick,
    handleSelectAllRows,
    handleClearAllSelectedRows,
    handleOpenCreateManageCaseModal,
    handleRenLinkClick,
    handleAddToCaseClick,
    formatFieldByName,
    applicationSettings,
    currentUserHasAbility,
    router,
    departmentProfile,
}) {
    const [getCurrentScrollPosition] = useDashboardScroll({
        router,
        searchModule: unassignedReportsSearch,
    });

    const currentPage = max([1, fromSizeToPage(from + 1, size)]);
    const fieldDisplayNames = useFields([
        REPORT_REPORTING_EVENT_NUMBER,
        REPORT_RECORD_NUMBER,
        DISPLAY_ONLY_WILL_NOT_INVESTIGATE_LABEL,
        DISPLAY_ONLY_PERSONNEL_REPORT_OWNER_LABEL,
    ]);
    const renDisplayName = fieldDisplayNames.REPORT_REPORTING_EVENT_NUMBER;
    const recordNumberDisplayName = fieldDisplayNames.REPORT_RECORD_NUMBER;
    const willNotInvestigateLabel = fieldDisplayNames.DISPLAY_ONLY_WILL_NOT_INVESTIGATE_LABEL;
    const reportOwnerDisplayName = fieldDisplayNames.DISPLAY_ONLY_PERSONNEL_REPORT_OWNER_LABEL;
    const mandatoryInvestigationsAppSetting =
        applicationSettings.RMS_MANDATORY_INVESTIGATIONS_FOR_OFFENSE_INCIDENT_REPORTS;
    const individualReportSelectionEnabled = !!applicationSettings.RMS_INDIVIDUAL_REPORT_SELECTION_ENABLED;
    const {
        singularCaseFieldName: caseDisplayName,
        pluralCaseFieldName: casesDisplayName,
    } = useCaseFieldName();
    const offenseDisplayName = useOffenseFieldName();
    const columnKey = sortKeyToColumnKey[currentSortKey];
    const multipleRowsSelected = selectedRows.length > 1;
    const createCaseLabel = multipleRowsSelected
        ? strings.bulkCreateCases(casesDisplayName)
        : strings.createCase(caseDisplayName);

    const createButtonClickHandler = multipleRowsSelected
        ? handleOpenCreateManageCaseModal
        : handleCreateCaseClick;

    const willNotInvestigateButton = (
        <WillNotInvestigateModal
            fieldDisplayName={willNotInvestigateLabel}
            buttonElement={
                <WillNotInvestigateButton
                    willNotInvestigateLabel={willNotInvestigateLabel}
                    renDisplayName={renDisplayName}
                    multipleRowsSelected={multipleRowsSelected}
                    disabled={
                        mandatoryInvestigationsAppSetting &&
                        !every(selectedRows, (r) => !get(results[r], 'hasOffenseReportInRen'))
                    }
                />
            }
            reportIds={map(selectedRows, (r) => get(results[r], 'id'))}
        />
    );

    const uniqueRensSelected = filter(
        uniq(map(selectedRows, (selectedRow) => get(results[selectedRow], 'reportingEventNumber'))),
        (id) => id !== undefined
    );

    const recordWithoutRenReportIds = !!applicationSettings.RMS_INVESTIGATION_ENHANCEMENTS_PHASE_ONE
        ? chain(selectedRows)
              .filter((selectedRow) => !get(results[selectedRow], 'reportingEventNumber'))
              .map((selectedRow) => get(results[selectedRow], 'id'))
              .uniq()
              .value()
        : [];

    const selectedReports = map(selectedRows, (r) => results[r]);
    const numUniqueReportsSelected = !allResultsSelected
        ? uniqueRensSelected.length + recordWithoutRenReportIds.length
        : null;

    const rowSelectHandler = handleBulkRowSelect;
    const createManageCaseModalTitle = multipleRowsSelected
        ? strings.bulkCreateCases(casesDisplayName)
        : strings.createCase(caseDisplayName);
    const createManageCaseModalOkText = multipleRowsSelected
        ? allResultsSelected
            ? strings.createCases(casesDisplayName)
            : strings.createNumCases(numUniqueReportsSelected, casesDisplayName)
        : strings.createCase(caseDisplayName);
    const helpText = !!applicationSettings.RMS_INVESTIGATION_ENHANCEMENTS_PHASE_ONE
        ? strings.reportsFromNumRensSelected(numUniqueReportsSelected, renDisplayName)
        : strings.reportsFromNumReportsSelected(numUniqueReportsSelected);
    const CreateManageCaseModalHelpText = multipleRowsSelected ? (
        allResultsSelected ? (
            <>{strings.casesWillBeCreatedForAllReports(casesDisplayName)}</>
        ) : (
            <>
                {helpText}{' '}
                <BoldedText>
                    {strings.numCases(numUniqueReportsSelected, casesDisplayName)}
                </BoldedText>
                {':'}
            </>
        )
    ) : null;

    const isUKComplianceGroup =
        departmentProfile.complianceGroup === ComplianceGroupEnum.UNITED_KINGDOM.name;

    const resultsTable = (
        <Table
            data={results}
            onRowClick={handleRowClick(getCurrentScrollPosition)}
            sortKey={currentSortKey}
            sortType={currentSortType}
            noRowsText={strings.noResults}
            disableBody={tableLoading}
            selectableRows={currentUserHasAbility(abilitiesEnum.CASES.EDIT_GENERAL)}
            selectableRow={false}
            selectedRows={selectedRows}
            highlightedRows={highlightedRows}
            onSelectRow={rowSelectHandler}
            containerClassName={classNames('search-results-table')}
            rowComponent={ElasticResultRow}
            tableCheckboxMargin="10px"
        >
            <TableHeader>
                <TableColumns>
                    <OptionsTableColumn
                        display={({ display }) => display}
                        columnKey={columnKey}
                        activeValue={currentSortKey || sortKeyEnum.REPORT_REPORTING_EVENT_NUMBER}
                        activeSortType={currentSortType}
                        onOptionClick={handleDateOptionClick}
                        onSortTypeClick={handleDateSortTypeClick}
                        width={164}
                        popoutWidth={isUKComplianceGroup ? 270 : 160}
                    >
                        <OptionsTableColumnOption
                            display={strings.columns.reportingEventNumber.label(
                                renDisplayName,
                                recordNumberDisplayName
                            )}
                            value={sortKeyEnum.REPORT_REPORTING_EVENT_NUMBER}
                            columnKey="reportingEventNumber"
                            sortOptions={[
                                {
                                    display: strings.columns.reportingEventNumber.descendingSort(
                                        renDisplayName,
                                        recordNumberDisplayName
                                    ),
                                    sortType: sortTypeEnum.ALPHABETICAL_Z_TO_A,
                                },
                                {
                                    display: strings.columns.reportingEventNumber.ascendingSort(
                                        renDisplayName,
                                        recordNumberDisplayName
                                    ),
                                    sortType: sortTypeEnum.ALPHABETICAL_A_TO_Z,
                                },
                            ]}
                        />
                        <OptionsTableColumnOption
                            display={strings.columns.eventDate.label}
                            value={sortKeyEnum.REPORT_EVENT_START_UTC}
                            columnKey="eventStartUtc"
                            sortOptions={[
                                {
                                    display: strings.columns.eventDate.descendingSort,
                                    sortType: sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
                                },
                                {
                                    display: strings.columns.eventDate.ascendingSort,
                                    sortType: sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT,
                                },
                            ]}
                        />
                        <OptionsTableColumnOption
                            display={strings.columns.modifiedDate.label}
                            value={sortKeyEnum.REPORT_UPDATED_DATE_UTC}
                            columnKey="updatedDateUtc"
                            sortOptions={[
                                {
                                    display: strings.columns.modifiedDate.descendingSort,
                                    sortType: sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
                                },
                                {
                                    display: strings.columns.modifiedDate.ascendingSort,
                                    sortType: sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT,
                                },
                            ]}
                        />
                        <OptionsTableColumnOption
                            display={strings.columns.creationDate.label}
                            value={sortKeyEnum.REPORT_REPORT_CREATION_DATE_UTC}
                            columnKey="reportCreationDateUtc"
                            sortOptions={[
                                {
                                    display: strings.columns.creationDate.descendingSort,
                                    sortType: sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
                                },
                                {
                                    display: strings.columns.creationDate.ascendingSort,
                                    sortType: sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT,
                                },
                            ]}
                        />
                    </OptionsTableColumn>
                    <TableColumn
                        display={reportOwnerDisplayName}
                        columnKey="reportOwner"
                        width={101}
                    />
                    <TableColumn
                        display={strings.columns.reportType.label}
                        columnKey="reportType"
                        width={142}
                    />
                    <OptionsTableColumn
                        display={({ display }) => display}
                        columnKey={activeColumnKeys.entity}
                        activeValue={activeColumnKeys.entity}
                        onOptionClick={handleEntityOptionClick}
                        width={196}
                    >
                        <OptionsTableColumnOption
                            display={strings.columns.offenseLocation.label(offenseDisplayName)}
                            value="offenseLocation"
                        />
                        <OptionsTableColumnOption
                            display={
                                formatFieldByName(
                                    LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_EVENT_LINK_TYPE
                                ) || strings.columns.location.label
                            }
                            value="locations"
                        />
                        <OptionsTableColumnOption
                            display={strings.columns.persons.label}
                            value="persons"
                        />
                    </OptionsTableColumn>
                    <TableColumn
                        display={formatFieldByName(DISPLAY_ONLY_CASE_SEARCH_LABELS_LABEL)}
                        columnKey="routingLabels"
                        width={145}
                    />
                    <TableColumn
                        display={strings.columns.narrative.label}
                        columnKey="narrative"
                        width={126}
                    />
                    <TableColumn
                        display={strings.columns.status.label}
                        columnKey="stat"
                        width={46}
                    />
                </TableColumns>
            </TableHeader>
            <TableBody>
                <ReportingEventNumberCell
                    columnKey="reportingEventNumber"
                    handleRenLinkClick={handleRenLinkClick(getCurrentScrollPosition)}
                />
                <EventStartUtcCell columnKey="eventStartUtc" />
                <UpdatedDateUtcCell columnKey="updatedDateUtc" />
                <ReportCreationDateCell columnKey="reportCreationDateUtc" />
                <ReportOwnerCell columnKey="reportOwner" />
                <ReportTypeCell columnKey="reportType" />
                <PrimaryLocationCell columnKey="locations" />
                <OffenseLocationCell columnKey="offenseLocation" />
                <PersonsCell columnKey="persons" />
                <RoutingLabelsCell columnKey="routingLabels" />
                <NarrativeCell columnKey="narrative" />
                <StatCell columnKey="stat" />
            </TableBody>
        </Table>
    );
    const actionBarButtons = !!applicationSettings.RMS_INVESTIGATION_ENHANCEMENTS_PHASE_ONE ? (
        <>
            <ButtonGroup isAttached>
                <Button
                    className={buttonTypes.SECONDARY_BOLD}
                    onClick={() => {
                        if (multipleRowsSelected || individualReportSelectionEnabled) {
                            handleCreateCaseFromMultiReportsClick(selectedReports);
                        } else {
                            handleCreateCaseClick();
                        }
                    }}
                >
                    {strings.createCase(caseDisplayName)}
                </Button>
                <Menu>
                    <MenuTrigger asChild>
                        <IconButton aria-label="Open" icon="Open" />
                    </MenuTrigger>
                    <MenuContent>
                        <MenuItem onSelect={() => handleAddToCaseClick({ selectedReports })}>
                            {strings.addToExistingCase(caseDisplayName)}
                        </MenuItem>
                        {multipleRowsSelected && (
                            <MenuItem onSelect={handleOpenCreateManageCaseModal}>
                                {strings.createSeprateCases(casesDisplayName)}
                            </MenuItem>
                        )}
                    </MenuContent>
                </Menu>
            </ButtonGroup>
            {willNotInvestigateButton}
        </>
    ) : (
        <>
            <Button
                className={buttonTypes.SECONDARY_BOLD}
                onClick={createButtonClickHandler}
                testId={testIds.ACTION_BAR_CREATE_CASE_BUTTON}
            >
                {createCaseLabel}
            </Button>
            {willNotInvestigateButton}
        </>
    );

    return (
        <>
            <ScrollableUnderSubheader>
                <div className={unassignedReportsSearch.resultsContainerClassName}>
                    <BatchBanners />
                    <ResultsHeader
                        from={from}
                        to={from + results.length}
                        totalResults={totalResults}
                        onSizeChange={handleSizeChange}
                        SearchForm={UnassignedReportsSearchForm}
                        searchModule={unassignedReportsSearch}
                        isSearchSavable={true}
                    />
                    <CaseDashboardTabs selectedTab={activeTabKey} onSelectTab={handleTabClick}>
                        <Tab title={strings.tabs.new} tabKey={tabKeys.NEW}>
                            {resultsTable}
                        </Tab>
                        <Tab title={willNotInvestigateLabel} tabKey={tabKeys.WILL_NOT_INVESTIGATE}>
                            {resultsTable}
                        </Tab>
                    </CaseDashboardTabs>
                    {totalResults > 0 && (
                        <CasesResultsFooter
                            totalResults={totalResults}
                            searchSize={size}
                            currentPage={currentPage}
                            handlePaginationClick={handlePaginationClick}
                        />
                    )}
                </div>
            </ScrollableUnderSubheader>
            <CasesActionBar
                totalResultCount={totalResults}
                selectedResultCount={selectedRows.length}
                buttons={actionBarButtons}
                allResultsSelected={allResultsSelected}
                onClickSelectAll={handleSelectAllRows}
                onClickClearSelection={handleClearAllSelectedRows}
                visible={selectedRows.length > 0}
            />
            <CreateManageCaseModal
                title={createManageCaseModalTitle}
                okText={createManageCaseModalOkText}
                isBulkCreate={multipleRowsSelected}
                includeLocalIdInTitle={false}
                helpText={CreateManageCaseModalHelpText}
                uniqueRensSelected={uniqueRensSelected}
                uniqueRecordWithoutRenReportIdsSelected={recordWithoutRenReportIds}
            />
            <FeatureFlagged flag="RMS_INVESTIGATION_ENHANCEMENTS_PHASE_ONE">
                <ReasonForRelationSidePanel />
                <IndividualReportSelectionSidePanel />
                <CreateCaseFromMultiReportsModal
                    formLocation={overlayIdEnum.CREATE_CASE_FROM_MULTI_REPORTS_MODAL}
                />
            </FeatureFlagged>
        </>
    );
}

const mapStateToProps = createStructuredSelector({
    // table ui state
    query: unassignedReportsSearch.selectors.currentQuerySelector,
    results: unassignedReportsSearch.selectors.currentResultsViewModelsSelector,
    highlightedRows: unassignedReportsSearch.selectors.highlightedRowsSelector,
    totalCount: unassignedReportsSearch.selectors.totalCountSelector,
    tableLoading: unassignedReportsSearch.selectors.tableLoadingSelector,
    activeTabKey: activeTabKeySelector,
    activeColumnKeys: unassignedReportsSearch.selectors.activeColumnKeysSelector,
    caseStatusGlobalAttrIdToChildAttrIds: caseStatusGlobalAttrIdToChildAttrIdsSelector,

    // state needed for the assign bar
    selectedRows: unassignedReportsSearch.selectors.selectedRowsSelector,
    formatFieldByName: formatFieldByNameSelector,
    currentUserHasAbility: currentUserHasAbilitySelector,
    applicationSettings: applicationSettingsSelector,
    allResultsSelected: unassignedReportsSearch.selectors.allResultsSelectedSelector,
    departmentProfile: currentUserDepartmentProfileSelector,
});

const mapDispatchToProps = (dispatch, { router }) => ({
    handleTabClick(tabKey, recordsWithoutRenEnabled) {
        dispatch(setQueryFromTabClick(tabKey));
        dispatch(
            submitUnassignedReportsSearchForm({
                recordsWithoutRenEnabled,
            })
        );
    },
    handleRowSelect(selectedRow) {
        dispatch(
            unassignedReportsSearch.actionCreators.selectRows(
                !isUndefinedOrNull(selectedRow) ? [selectedRow] : []
            )
        );
    },
    handleBulkRowSelect(newSelectedRows) {
        dispatch(unassignedReportsSearch.actionCreators.selectRows(newSelectedRows));
    },
    handleRowClick(getCurrentScrollPosition) {
        return (elasticReport, rowIndex) => {
            dispatch(
                unassignedReportsSearch.actionCreators.openSearchResult(
                    elasticReport,
                    rowIndex,
                    router,
                    getCurrentScrollPosition()
                )
            );
        };
    },
    handleDateOptionClick({ value, sortOptions }) {
        dispatch(
            unassignedReportsSearch.actionCreators.search({
                sortKey: value,
                sortType: sortOptions ? sortOptions[0].sortType : undefined,
            })
        );
    },
    handleDateSortTypeClick(sortType) {
        dispatch(unassignedReportsSearch.actionCreators.search({ sortType }));
    },
    handleEntityOptionClick({ value }) {
        dispatch(unassignedReportsSearch.actionCreators.setActiveColumnKey('entity', value));
    },
    handlePaginationClick(nextPage, size) {
        dispatch(
            unassignedReportsSearch.actionCreators.search(
                { from: nextPage * size - size, size },
                { cacheBust: true }
            )
        );
    },
    handleSizeChange(size) {
        dispatch(unassignedReportsSearch.actionCreators.search({ from: 0, size }));
    },
    handleCreateCaseClick(elasticReport) {
        dispatch(openCreateCaseModal({ reportingEventNumber: elasticReport.reportingEventNumber }));
    },
    handleCreateCaseFromMultiReportsClick(elasticReports) {
        dispatch(
            openCreateCaseFromReportsModal(
                overlayIdEnum.CREATE_CASE_FROM_MULTI_REPORTS_MODAL,
                elasticReports
            )
        );
    },
    handleWillNotInvestigateClick(elasticReport) {
        dispatch(markReportWillNotInvestigate(elasticReport.id));
    },
    handleSelectAllRows() {
        dispatch(unassignedReportsSearch.actionCreators.selectAllResults());
    },
    handleClearAllSelectedRows() {
        dispatch(unassignedReportsSearch.actionCreators.selectRows([]));
    },
    handleRenLinkClick(getCurrentScrollPosition) {
        return (event) => {
            event.stopPropagation();
            dispatch(
                unassignedReportsSearch.actionCreators.setScrollPosition(getCurrentScrollPosition())
            );
        };
    },
    handleAddToCaseClick({ selectedReports }) {
        const onSave = (reportIdentifiers) =>
            dispatch(onSaveReasonForRelationSidePanelOnUnassignedReports(reportIdentifiers));

        dispatch(
            openReasonForRelationSidePanel({
                reasonForRelationReports: selectedReports.map(
                    ({ id, reportingEventNumber, recordNumber }) => ({
                        reportingEventNumber,
                        recordNumber,
                        reportIds: [id],
                    })
                ),
                onSave,
            })
        );
    },
});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withHandlers({
        handleTabClick: ({ handleTabClick, applicationSettings }) => (tabKey) => {
            handleTabClick(tabKey, !!applicationSettings.RMS_REPORT_RECORDS_WITHOUT_REN_ENABLED);
        },
        handlePaginationClick({ handlePaginationClick, query: { size } }) {
            return (lastPage, nextPage) => handlePaginationClick(nextPage, size);
        },

        handleCreateCaseClick({ handleCreateCaseClick, selectedRows, results }) {
            return () => handleCreateCaseClick(results[selectedRows[0]]);
        },
        handleWillNotInvestigateClick({ handleWillNotInvestigateClick, selectedRows, results }) {
            return () => handleWillNotInvestigateClick(results[selectedRows[0]]);
        },
        handleOpenCreateManageCaseModal() {
            return () => overlayStore.open(overlayIdEnum.CREATE_MANAGE_CASE_MODAL);
        },
    })
)(UnassignedReports);
