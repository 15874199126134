import SortKeyEnum from '~/client-common/core/enums/universal/sortKeyEnum';
import SortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';

export const NUM_RECENTLY_VIEWED = 5;
export const NUM_RECENT_SAVED_SEARCHES = 5;
export const NUM_RECENT_ALERTS = 5;
export const ACTION_REQUIRED_REPORTS_INTERVAL = 25;
export const MAX_ACTION_REQUIRED_REPORTS = 100;
export const MY_CAD_EVENTS_INTERVAL = 25;
export const ITEMS_PENDING_REVIEW_INTERVAL = 25;
export const MAX_ITEMS_PENDING_REVIEW = 100;
export const RECENT_ARRESTS_INTERVAL = 6;
export const MAX_RECENT_ARRESTS = 50;
export const MAX_MY_TASKS = 100;
export const MY_TASKS_INTERVAL = 25;
export const MY_CASES_INTERVAL = 25;
export const RECENT_WARRANTS_INTERVAL = 6;
export const MAX_RECENT_WARRANTS = 25;
export const MY_WARRANTS_INTERVAL = 6;
export const MAX_MY_WARRANTS = 50;
export const TARGET_PROFILES_INTERVAL = 25;
export const TARGET_PROFILES_SORTS = [
    {
        sortKey: SortKeyEnum.CASE_PRIORITY,
        sortType: SortTypeEnum.PRIORITY_HIGHEST_TO_LOWEST,
    },
    {
        sortKey: SortKeyEnum.CASE_CREATED_DATE_UTC,
        sortType: SortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
    },
];
