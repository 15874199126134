import React from 'react';
import { Text } from '@arc/typography';
import { useSelector } from 'react-redux';
import { Observer } from 'markformythree';
import componentStrings from '~/client-common/core/strings/componentStrings';
import Divider from '../../../core/components/Divider';
import { formatTitleByCaseIdSelector } from '../state/ui';
import {
    ReasonForRelationFormConfiguration,
    ReasonForRelationFormNItemDataShape,
    ReportCaseLinkReportFormDataShape,
} from '../state/form/reasonForRelationForm';
import { MFTNItems } from '../../../core/forms/components/NItems';
import {
    SHOW_CASE_HEADER_FOR_LOCATIONS,
    SHOW_REPORT_DETAILS_FORM_LOCATIONS,
} from '../configuration';
import { ReasonForRelationFormLocationType } from './ReasonForRelationForm';
import ReasonForRelationReportNItem from './ReasonForRelationReportNItem';

const strings = componentStrings.cases.core.ReasonForRelationForm;

export const ReasonForRelationCaseNItems = ({
    index,
    reportCaseLink,
    onChangeReportIds,
    formLocation,
}: {
    index: number;
    reportCaseLink: ReasonForRelationFormNItemDataShape;
    onChangeReportIds: (reportDataIndex: number, reportIds: number[]) => void;
    formLocation: ReasonForRelationFormLocationType;
}) => {
    const formatCaseTitle = useSelector(formatTitleByCaseIdSelector);
    const { caseId } = reportCaseLink;
    const isCaseHeaderVisible = SHOW_CASE_HEADER_FOR_LOCATIONS.includes(formLocation);
    const isReportDetailsVisible = SHOW_REPORT_DETAILS_FORM_LOCATIONS.includes(formLocation);

    return (
        <div>
            {isCaseHeaderVisible && (
                <>
                    <Text variant="headingXs" color="tertiary">
                        {strings.addToCase(formatCaseTitle(caseId, true))}
                    </Text>
                    <Divider />
                </>
            )}

            <MFTNItems<ReportCaseLinkReportFormDataShape>
                path="reportData"
                addItemOnEmpty={false}
                renderAddButton={undefined}
                renderRemoveButton={undefined}
                render={({ index: reportDataIndex, item }) => {
                    const { reportingEventNumber } = item;
                    const handleReportIdsChange = (reportIds: number[]) => {
                        if (onChangeReportIds) {
                            onChangeReportIds(reportDataIndex, reportIds);
                        }
                    };

                    return (
                        <Observer<
                            { reportIds: number[]; displayReportIds: number[] },
                            ReasonForRelationFormConfiguration
                        >
                            subscriptions={{
                                reportIds: `reportCaseLinks[${index}].reportData[${reportDataIndex}].reportIds`,
                                displayReportIds: `reportCaseLinks[${index}].reportData[${reportDataIndex}].displayReportIds`,
                            }}
                            render={({
                                reportIds: selectedReportIds = [],
                                displayReportIds = [],
                            }) => {
                                return (
                                    <ReasonForRelationReportNItem
                                        caseId={caseId ?? -1}
                                        showReportDetails={isReportDetailsVisible}
                                        selectedReportIds={selectedReportIds}
                                        displayReportIds={displayReportIds}
                                        onChange={handleReportIdsChange}
                                        isRen={!!reportingEventNumber}
                                    />
                                );
                            }}
                        />
                    );
                }}
            />
        </div>
    );
};
