import React from 'react';
import { Text, Icon, cssVar } from 'arc';
import styled from 'styled-components';
import { join } from 'lodash';
import { useSelector } from 'react-redux';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { allRoleFormatsByRoleIdSelector } from '~/client-common/core/domain/roles/state/data';
import { Link } from '../../../records/core/components/sidebar/LinkedRecordComponents';
import { CaseApprovalStatusIcon } from '../../../records/core/components/ApprovalStatusIcon';
import {
    getCaseAssignedTo,
    getCaseStatus,
} from '../../../records/core/helpers/caseStringFormatters';
import { CaseViewType } from '../state/form/reasonForRelationForm';

const Wrapper = styled.div`
    font-family: var(--arc-fonts-body);
    display: flex;
    align-items: center;
    gap: 1em;

    & a {
        color: var(--arc-colors-brand-default);
    }
`;

const StyledText = styled(Text)`
    word-break: break-word;
    width: auto;
`;

type CaseSearchLinkProps = {
    item: CaseViewType;
};

const ConditionalLink = ({
    children,
    to,
    ...rest
}: {
    children: React.ReactNode;
    to: string | false;
    openInNewTab?: boolean;
}) =>
    to ? (
        <Link to={to} {...rest} style={{ display: 'flex', gap: cssVar('arc.space.1') }}>
            {children} <Icon icon="OpenNewWindow" size="sm" />
        </Link>
    ) : (
        <>{children}</>
    );

const CaseSearchLink: React.FC<CaseSearchLinkProps> = ({ item }) => {
    const formatAttributeById = useSelector(formatAttributeByIdSelector);
    const allRoleFormatsByRoleId = useSelector(allRoleFormatsByRoleIdSelector);

    const allRoleFormats = item.assigneeRoleId && allRoleFormatsByRoleId(item.assigneeRoleId);

    const assignee = getCaseAssignedTo({
        assigneeRole:
            allRoleFormats &&
            ('fullNameWithFirstInitialAndIdNumber' in allRoleFormats
                ? allRoleFormats.fullNameWithFirstInitialAndIdNumber
                : allRoleFormats.default),
        assignedPersonnelUnit: formatAttributeById(item.assignedPersonnelUnitAttrId),
    });

    const caseStatus = getCaseStatus({
        caseStatus: formatAttributeById(item.currentStatusAttrId),
    });

    const secondaryInfo = join([assignee, caseStatus], ' | ');

    const to = `/cases/${item.caseId}`;

    return (
        <Wrapper>
            {item?.approvalStatus && (
                <CaseApprovalStatusIcon
                    className="approvalStatusIcon"
                    approvalStatus={item.approvalStatus}
                    size={20}
                />
            )}
            <div>
                <ConditionalLink to={to} openInNewTab>
                    <StyledText fontWeight="semibold" variant="headingSm" color="inherit">
                        {item.shortTitle}
                    </StyledText>
                </ConditionalLink>
                <StyledText color="secondary" variant="bodyMd">
                    {secondaryInfo}
                </StyledText>
            </div>
        </Wrapper>
    );
};

export default CaseSearchLink;
