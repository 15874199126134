import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { map, noop, includes } from 'lodash';
import { compose, withPropsOnChange, withProps } from 'recompose';

import boxEnum from '~/client-common/core/enums/client/boxEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';

import MenuPopover from '../../../core/box/components/MenuPopover';
import _Link from '../../../core/components/links/Link';
import NoDataBlock from '../../../core/components/NoDataBlock';
import _Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import { iconTypes } from '../../../core/components/Icon';
import { SimpleLoading } from '../../../../legacy-redux/components/core/Loading';

import { closeBox } from '../../../../legacy-redux/actions/boxActions';
import { InlineBanner } from '../../../core/components/InlineBanner';
import ItemInfo from '../../core/components/ItemInfo';
import { horizVertCenter } from '../../../core/styles/mixins';
import {
    itemQueueViewModelByMasterItemIdSelector,
    itemQueueMasterItemIdsSelector,
    loadingItemQueueSelector,
    itemQueueErrorMessageSelector,
    removeFromItemQueue,
    resetItemQueue,
    loadingEvidenceItemsSelector,
    loadingItemIdsSelector,
    itemQueuePopoverTimedCloseSelector,
    disableItemQueuePopoverTimedClose,
    closeItemQueuePopover,
} from '../state/ui';
import { ITEM_INFO_PADDING } from '../../core/configuration';
import testIds from '../../../../core/testIds';

const strings = componentStrings.evidence.itemQueue.ItemQueuePopover;
const popoverContext = { name: boxEnum.ITEM_QUEUE_POPOVER };

// same style as NotificationsPopover
const Header = styled.div`
    min-height: 38px;
    box-shadow: 0 1px 14px ${(props) => props.theme.colors.shadow};
    text-align: right;
    padding: 7px 20px;
    position: relative;
    z-index: 1;
`;

const Footer = styled.div`
    box-shadow: 0 1px 14px ${(props) => props.theme.colors.lightGrey};
    padding: 10px 20px;
    position: relative;
`;

const Link = styled(_Link)`
    color: ${(props) => props.theme.colors.cobaltBlue};
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    text-transform: uppercase;
    font-size: var(--arc-fontSizes-md);
`;
const Content = styled.div`
    overflow-x: hidden;
    height: 360px;
`;
const NoContent = styled.div`
    margin-top: 40px;
    text-align: center;
`;
const ItemQueueItem = styled.div`
    position: relative;
    padding: ${ITEM_INFO_PADDING}px;
    border-bottom: 1px solid ${(props) => props.theme.colors.extraLightGrey};
    font-size: var(--arc-fontSizes-sm);
    display: flex;

    &:hover {
        background-color: ${(props) => props.theme.colors.lightBlue};
    }
`;

const ItemLoadingSpinner = styled(SimpleLoading)`
    background: rgba(255, 255, 255, 0.5);
    ${horizVertCenter};

    .loading-whitebg {
        width: 25px;
        height: 25px;
    }
`;
const QueueLoadingSpinner = styled(SimpleLoading)`
    ${horizVertCenter};
`;
// remove default hover underline
const RemoveAllButton = styled(_Button)`
    font-size: var(--arc-fontSizes-md);

    &:hover .textvalignmiddle {
        text-decoration: none;
    }
`;

const ViewQueueButton = styled(_Button)`
    float: none;
    margin: 0;
`;

// HOC to optimize ItemQueuePopover performance
// When adding items to basket from evidence dashboard, hydrated evidence
// items dispatches multiple actions to update state. Passing in masterItemIds
// delays building (and re-building) of view models until popover is opened.
const ItemQueueItemInfo = compose(
    connect(
        createStructuredSelector({
            viewModelByMasterItemId: itemQueueViewModelByMasterItemIdSelector,
        })
    ),
    withPropsOnChange(
        ['masterItemId', 'viewModelByMasterItemId'],
        ({ masterItemId, viewModelByMasterItemId }) => {
            const viewModel = viewModelByMasterItemId(masterItemId) || {};

            return {
                viewModel,
                reportId: viewModel.reportId,
            };
        }
    )
)(ItemInfo);

const TrashButton = compose(
    connect(null, (dispatch, { masterItemId }) => ({
        removeFromItemQueue: () => dispatch(removeFromItemQueue(masterItemId)),
    })),
    withProps(({ removeFromItemQueue }) => ({
        onClick: removeFromItemQueue,
        className: buttonTypes.ICON_LINK,
        iconLeft: iconTypes.TRASH_CAN,
    }))
)(styled(_Button)`
    display: inline-block;
    vertical-align: top;
    margin-left: 30px;
`);

function ItemQueuePopover({
    closeBox,
    removeAll,
    masterItemIds,
    isLoadingQueue,
    errorMessage,
    loadingEvidenceItems,
    loadingItemIds,
    disableTimedClose,
    closePopover,
    itemQueuePopoverTimedClose,
}) {
    const isLoading = isLoadingQueue || loadingEvidenceItems;
    const modalStyle = {
        left: '64%',
        ...(masterItemIds.length === 0 && !isLoading && !errorMessage ? { height: 110 } : {}),
    };

    const header = (
        <Header>
            {!isLoading && (
                <RemoveAllButton
                    className={buttonTypes.ICON_LINK}
                    onClick={removeAll}
                    testId={testIds.EVIDENCE_BASKET_CLEAR_ALL}
                >
                    {strings.labels.clearQueue}
                </RemoveAllButton>
            )}
        </Header>
    );

    const content = (
        <div>
            {header}
            <Content>
                {isLoading ? (
                    <QueueLoadingSpinner />
                ) : (
                    map(masterItemIds, (masterItemId) => (
                        <ItemQueueItem key={masterItemId}>
                            <ItemQueueItemInfo
                                masterItemId={masterItemId}
                                width={274}
                                showHighRiskLabel={true}
                            />
                            <TrashButton masterItemId={masterItemId} />
                            {includes(loadingItemIds, masterItemId) && <ItemLoadingSpinner />}
                        </ItemQueueItem>
                    ))
                )}
            </Content>
            <Footer>
                <Link to="/evidence/item-queue">
                    <ViewQueueButton
                        className={buttonTypes.PRIMARY}
                        onClick={closeBox}
                        testId={testIds.VIEW_ITEM_QUEUE}
                    >
                        {strings.labels.viewQueue}
                    </ViewQueueButton>
                </Link>
            </Footer>
        </div>
    );

    const emptyContent = (
        <NoContent>
            <NoDataBlock testId={testIds.EVIDENCE_BASKET_NO_ITEMS}>
                {strings.labels.noItems}
            </NoDataBlock>
        </NoContent>
    );

    const errorContent = (
        <div>
            {header}
            <InlineBanner status="error">{errorMessage}</InlineBanner>
        </div>
    );

    return (
        <MenuPopover context={popoverContext} modalStyle={modalStyle} onClose={closePopover}>
            <div onMouseOver={itemQueuePopoverTimedClose ? disableTimedClose : noop}>
                {errorMessage
                    ? errorContent
                    : masterItemIds.length === 0 && !isLoading
                    ? emptyContent
                    : content}
            </div>
        </MenuPopover>
    );
}

/**
 * Popover to display items in item queue and a link to the Item Queue page.
 *   Open by clicking on the top menu Nav Link for Item Queue.
 *   Also, opened automagically when items added from evidence dashboard.
 *   Popover is positioned to the right under the Navigation Link
 */
export default connect(
    createStructuredSelector({
        masterItemIds: itemQueueMasterItemIdsSelector,
        isLoadingQueue: loadingItemQueueSelector,
        errorMessage: itemQueueErrorMessageSelector,
        loadingEvidenceItems: loadingEvidenceItemsSelector,
        loadingItemIds: loadingItemIdsSelector,
        itemQueuePopoverTimedClose: itemQueuePopoverTimedCloseSelector,
    }),
    (dispatch) => ({
        // fallback to close popover when Item Queue table already open
        closeBox: () => dispatch(closeBox(popoverContext)),
        removeAll: () => dispatch(resetItemQueue()),
        disableTimedClose: () => dispatch(disableItemQueuePopoverTimedClose()),
        // custom onClose callback to reset auto close timer
        closePopover: () => dispatch(closeItemQueuePopover()),
    })
)(ItemQueuePopover);
