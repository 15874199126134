import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import {
    StaffRemark as StaffRemarkType,
    EntityTypeEnumType,
    ElasticStaffRemark,
} from '@mark43/evidence-api';
import { useSelector } from 'react-redux';
import { staffRemarksByEntitySelector } from '~/client-common/core/domain/staff-remarks/state/data';
import { StaffRemark } from './StaffRemark';

type StaffRemarksProps = {
    staffRemarks?: StaffRemarkType[] | ElasticStaffRemark[];
    entityType?: EntityTypeEnumType;
    entityId?: number;
    deletable?: boolean;
    className?: string;
};

const StaffRemarks = ({
    staffRemarks,
    entityType,
    entityId,
    deletable = false,
    className,
}: StaffRemarksProps) => {
    const staffRemarksByEntity = useSelector(staffRemarksByEntitySelector);
    const [remarks, setRemarks] = useState<StaffRemarkType[] | ElasticStaffRemark[]>(
         []
    );

    useEffect(() => {
        if (staffRemarks) {
            setRemarks(staffRemarks);
        } else if (entityType && entityId !== undefined) {
            setRemarks(staffRemarksByEntity(entityType, entityId));
        }
    }, [staffRemarks, staffRemarksByEntity, entityType, entityId]);

    return (
        <div className={className}>
            {remarks.map((staffRemark) => (
                <StaffRemark key={staffRemark.id} staffRemark={staffRemark} deletable={deletable} />
            ))}
        </div>
    );
};

export default styled(StaffRemarks)`
    font-size: var(--arc-fontSizes-sm);

    &,
    * {
        box-sizing: border-box;
    }
`;
