import { values, isUndefined, every } from 'lodash';

export function objectValuesAreEmpty(object: Record<string, unknown>) {
    const objectValues = values(object);
    return objectValues.length === 0 || every(objectValues, isUndefined);
}

export type NestedObject = { [key: string]: NestedObject } | NestedObject[] | string | string[];

export const extractStrings = (obj: NestedObject): string[] => {
    const result: string[] = [];

    const extract = (value: NestedObject) => {
        if (typeof value === 'string') {
            result.push(value);
        } else if (Array.isArray(value)) {
            value.forEach(extract);
        } else if (value && typeof value === 'object') {
            Object.values(value).forEach(extract);
        }
    };

    extract(obj);
    return result;
};
