import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { first } from 'lodash';
import { _Form, lifecycleOptions } from 'markformythree';
import { RefContextEnum } from '@mark43/rms-api';

import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { reportCardTitleByReportIdAndCardIdSelector } from '~/client-common/core/domain/report-definitions/state/data';
import { reportAttributesSelector } from '~/client-common/core/domain/report-attributes/state/data';
import { crashDetailsWhereSelector } from '~/client-common/core/domain/crash-details/state/data';

import formsRegistry from '../../../../../core/formsRegistry';
import Card from '../../../../../legacy-redux/components/core/Card';
import { registerCard } from '../../utils/cardsRegistry';
import testIds from '../../../../../core/testIds';
import { useAdditionalFormConfigurationData } from '../../../../core/markformythree-arbiter/dynamic-fields/hooks/useAdditionalFormConfigurationData';
import ArbiterForm from '../../../../core/markformythree-arbiter/ArbiterForm';
import { RMSArbiterProvider } from '../../../../core/arbiter';
import { useFormGetter } from '../../../../core/forms/hooks/useFormGetter';
import crashEventInfoCard from '../../state/ui/crashEventInfoCard';
import {
    formName,
    convertToFormModel,
    createCrashEventInfoFormConfiguration,
    CrashEventInfoFormConfiguration,
} from '../../state/forms/crashEventInfoForm';
import withCard from '../../utils/withCard';
import { ReportCardProps } from '../../types';

import CrashEventInfoCardForm from './CrashEventInfoCardForm';
import CrashEventInfoSummary from './CrashEventInfoCardSummary';

const CrashEventInfoCard: React.FC<
    ReportCardProps & { form: _Form<CrashEventInfoFormConfiguration> }
> = (props) => {
    const { card, currentReportId, editCallback, form, onEdit, onSave } = props;
    const reportCardTitleByReportIdAndCardId = useSelector(
        reportCardTitleByReportIdAndCardIdSelector
    );
    const { getForm } = useFormGetter();
    const crashDetailsWhere = useSelector(crashDetailsWhereSelector);
    const reportAttributes = useSelector(reportAttributesSelector);
    const crashDetail = first(crashDetailsWhere({ reportId: currentReportId }));

    useEffect(() => {
        if (!crashDetail) {
            return;
        }
        formsRegistry.maybeDeferredOperation(formName, undefined, (form) => {
            form.resetUi();
            const formModel = convertToFormModel(crashDetail, reportAttributes, currentReportId);
            form.set('', formModel);
        });
    }, [crashDetail, currentReportId, reportAttributes]);

    useEffect(() => {
        const unregisterCard = registerCard({
            cardModule: crashEventInfoCard,
            onSave,
            index: currentReportId,
        });

        return () => {
            unregisterCard();
        };
    }, [currentReportId, onSave]);

    const cardTitle = reportCardTitleByReportIdAndCardId(
        currentReportId,
        reportCardEnum.CRASH_EVENT_INFO.id
    );

    const onCardEdit = () => {
        editCallback(() => onEdit({ index: currentReportId }));
    };

    const onSaveProgress = () => {
        const form = getForm(RefContextEnum.FORM_CRASH_EVENT_INFO.name);
        if (!form) {
            return;
        }
        return props.onSaveProgress(form);
    };

    return (
        <Card
            testId={testIds.CRASH_EVENT_INFO_CARD}
            canEdit={card.canEditEventInfoReportCardStatus.canEditReportCard}
            errors={card.errorMessages}
            onEdit={onCardEdit}
            onSave={onSaveProgress}
            summaryMode={card.summaryMode}
            className="crash-event-info-card"
            anchor="crash-event-info-card"
            title={cardTitle}
            renderContent={(summaryMode) => {
                return summaryMode ? (
                    <CrashEventInfoSummary form={form} />
                ) : (
                    <CrashEventInfoCardForm form={form} />
                );
            }}
        />
    );
};

const CrashEventInfoCardWrapper: React.FC<ReportCardProps> = (props) => {
    const additionalData = useAdditionalFormConfigurationData();
    const applicationSettings = useSelector(applicationSettingsSelector);

    if (!applicationSettings.RMS_CRASH_DIAGRAM_ENABLED) {
        return null;
    }
    return (
        <RMSArbiterProvider context={formName}>
            {() => (
                <ArbiterForm
                    configuration={createCrashEventInfoFormConfiguration(additionalData)}
                    name={formName}
                    context={formName}
                    lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
                    render={(form) => <CrashEventInfoCard form={form} {...props} />}
                />
            )}
        </RMSArbiterProvider>
    );
};

export default withCard(crashEventInfoCard)(CrashEventInfoCardWrapper);
