import { AttributeTypeEnum } from '@mark43/rms-api';
import { filter } from 'lodash';
import React from 'react';

import styled, { withTheme } from 'styled-components';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import withFields from '~/client-common/core/fields/components/withFields';
import {
    PERSON_PROFILE_DATE_OF_BIRTH,
    PERSON_PROFILE_FBI_UCN,
    PERSON_PROFILE_DL_NUMBER,
    PERSON_PROFILE_SSN,
    PERSON_PROFILE_STATE_ID_NUMBER,
} from '~/client-common/core/enums/universal/fields';
import {
    formatFullName,
    isUnknown,
} from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';

import { FormattedDate } from '~/client-common/core/dates/components';
import { PersonLabels as _PersonLabels } from '../../../../core/components/tags/PersonLabels';
import { PersonEnhancedCautions as _PersonEnhancedCautions } from '../../../../core/cautions/components/PersonEnhancedCautions';
import QuickSearchResultSectionItemTitle from '../QuickSearchResultSectionItemTitle';
import Highlighter from '../QuickSearchHighlighter';
import GenericContainer from '../layout/GenericContainer';
import FieldDisplay from '../layout/FieldDisplay';
import ExternalDepartmentName from '../ExternalDepartmentName';
import { BottomPositionedDeceasedLabel } from '../../../../core/persons/components/DeceasedLabel';
import { PersonProfileImage, profileImageSizes } from '../../../../core/components/ProfileImage';

const Wrapper = styled.div`
    position: relative;
    display: flex;
`;

const PersonItemInfoContainer = styled.div`
    vertical-align: top;
    flex: 1;
    margin-left: 10px;
    padding: 10px 0;
    display: inline-block;
`;

const QuickSearchPersonProfileImage = styled(PersonProfileImage)`
    background-color: ${(props) => props.theme.colors.lightGrey};
`;

const PersonLabels = styled(_PersonLabels)`
    margin-top: 6px;
`;

const PersonEnhancedCautions = styled(_PersonEnhancedCautions)`
    margin-top: 6px;
`;

const QuickSearchResultSectionPersonItem = ({
    query,
    item,
    fieldDisplayNames,
    theme,
    externalDepartmentName,
    testId,
    testType,
}) => {
    const {
        primaryMugshotPath,
        nicknames,
        isDead,
        nameAttributes,
        isJuvenile,
        hasPotentialActiveWarrant,
        isSuspectedGangMember,
        isVulnerable,
        dateVulnerableTo,
        cautions,
        isActiveTarget,
    } = item;
    const searchWords = [query];
    return (
        <Wrapper data-test-id={testId} data-test-type={testType}>
            <QuickSearchPersonProfileImage
                noBorder={true}
                isUnknown={isUnknown(item)}
                url={primaryMugshotPath}
                size={profileImageSizes.QUICK_SEARCH}
            >
                {isDead && <BottomPositionedDeceasedLabel />}
            </QuickSearchPersonProfileImage>
            <PersonItemInfoContainer>
                <QuickSearchResultSectionItemTitle width="100%">
                    <Highlighter searchWords={searchWords} textToHighlight={formatFullName(item)} />
                    {nicknames && !!nicknames.length && (
                        <GenericContainer color={theme.colors.lightGrey} italic={true}>
                            {' '}
                            <Highlighter
                                searchWords={searchWords}
                                textToHighlight={`aka: ${nicknames.join(', ')}`}
                            />{' '}
                        </GenericContainer>
                    )}
                    <ExternalDepartmentName theme={theme} departmentName={externalDepartmentName} />
                </QuickSearchResultSectionItemTitle>

                <div>
                    {item.dateOfBirth && (
                        <FieldDisplay
                            fieldDisplayNames={fieldDisplayNames}
                            field={PERSON_PROFILE_DATE_OF_BIRTH}
                            theme={theme}
                        >
                            <FormattedDate
                                date={item.dateOfBirth}
                                format={FormattedDate.FORMATS.SHORT_DATE}
                            />
                        </FieldDisplay>
                    )}
                    {item.ssn && (
                        <FieldDisplay
                            fieldDisplayNames={fieldDisplayNames}
                            field={PERSON_PROFILE_SSN}
                            theme={theme}
                        >
                            <Highlighter searchWords={searchWords} textToHighlight={item.ssn} />
                        </FieldDisplay>
                    )}
                    {item.fbiUcn && (
                        <FieldDisplay
                            fieldDisplayNames={fieldDisplayNames}
                            field={PERSON_PROFILE_FBI_UCN}
                            theme={theme}
                        >
                            <Highlighter searchWords={searchWords} textToHighlight={item.fbiUcn} />
                        </FieldDisplay>
                    )}
                    {item.stateIdNumber && (
                        <FieldDisplay
                            fieldDisplayNames={fieldDisplayNames}
                            field={PERSON_PROFILE_STATE_ID_NUMBER}
                            theme={theme}
                        >
                            <Highlighter
                                searchWords={searchWords}
                                textToHighlight={item.stateIdNumber}
                            />
                        </FieldDisplay>
                    )}
                    {item.dlNumber && (
                        <FieldDisplay
                            fieldDisplayNames={fieldDisplayNames}
                            field={PERSON_PROFILE_DL_NUMBER}
                            theme={theme}
                        >
                            <Highlighter
                                searchWords={searchWords}
                                textToHighlight={item.dlNumber}
                            />
                        </FieldDisplay>
                    )}
                </div>
                <FeatureFlagged
                    flag="RMS_PERSON_CAUTIONS_ENHANCEMENTS_ENABLED"
                    fallback={
                        <PersonLabels
                            elasticNameAttributes={filter(nameAttributes, {
                                nameAttrDetail: {
                                    type: AttributeTypeEnum.PERSON_LABEL_ATTRIBUTES.name,
                                },
                            })}
                            isJuvenile={isJuvenile}
                            hasPotentialActiveWarrant={hasPotentialActiveWarrant}
                            isSuspectedGangMember={isSuspectedGangMember}
                            priority1Only={true}
                            size="md"
                            isVulnerable={isVulnerable}
                            dateVulnerableTo={dateVulnerableTo}
                            isActiveTarget={isActiveTarget}
                        />
                    }
                >
                    <PersonEnhancedCautions
                        cautions={cautions}
                        includePriority1Only
                        isJuvenile={isJuvenile}
                        hasPotentialActiveWarrant={hasPotentialActiveWarrant}
                        isSuspectedGangMember={isSuspectedGangMember}
                        isVulnerable={isVulnerable}
                        dateVulnerableTo={dateVulnerableTo}
                        isActiveTarget={isActiveTarget}
                    />
                </FeatureFlagged>
            </PersonItemInfoContainer>
        </Wrapper>
    );
};

// This connects every single item to the store, meaning
// we will have `n` lookups of fieldnames.
// If this gets slow, then this connection should be moved
// up one level and a section renderer for person profiles
// should be introduced, which then only fetches fields once
export default withFields([
    PERSON_PROFILE_DATE_OF_BIRTH,
    PERSON_PROFILE_FBI_UCN,
    PERSON_PROFILE_DL_NUMBER,
    PERSON_PROFILE_SSN,
    PERSON_PROFILE_STATE_ID_NUMBER,
])(withTheme(QuickSearchResultSectionPersonItem));
