import { AttributeTypeEnum, ElasticSearchTypeEnum, RefContextEnum } from '@mark43/rms-api';

import React from 'react';
import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Stack } from 'arc';
import noop from 'lodash/noop';

import { Fieldset as MFTFieldset, FieldState, Observer } from 'markformythree';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import componentStrings from '~/client-common/core/strings/componentStrings';
import itemSidePanelOperationEnum from '~/client-common/core/enums/client/itemSidePanelOperationEnum';

import formsRegistry from '../../../../../../core/formsRegistry';
import { ArbiterMFTCheckbox } from '../../../../../core/forms/components/checkboxes/Checkbox';
import { ArbiterMFTAttributeSelect } from '../../../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTText } from '../../../../../core/forms/components/Text';
import FormRow from '../../../../../core/forms/components/FormRow';

import { ArbiterMFTEntitySearch } from '../../../../../core/entity-search/EntitySearch';
import { ArbiterMFTUserSelect } from '../../../../../core/forms/components/selects/UserSelect';
import { SidePanelSection } from '../../../../../../legacy-redux/components/core/SidePanel';
import { currentUserDepartmentIdSelector } from '../../../../../core/current-user/state/ui';
import {
    setFormWithSearchResult,
    storeItemSidePanelMasterItemId,
    itemSidePanelOperationSelector,
    storeItemSidePanelIsAutosearch,
} from '../../../state/ui';
import {
    itemSidePanelDexDataSelector,
    generateSidePanelFormDataFromRmsFirearm,
    generateSidePanelFormDataAsObjFromRmsFirearm,
    itemSidePanelIsAutosearchSelector,
    itemSidePanelQuerySelector,
} from '../../../state/ui/itemSidePanel';
import withItemCategoryFilterOptions from './withItemCategoryFilterOptions';
import { ArbiterAtfManufacturerSelect } from './AtfManufacturerSelect';
import { ArbiterAtfModelSelect } from './AtfModelSelect';
import { ArbiterAtfCaliberSelect } from './AtfCaliberSelect';

const strings = componentStrings.reports.core.FirearmFieldset;
const NEW_MASTER_ITEM_ID = -1;

const FirearmInlineSearch = compose(
    connect(
        createStructuredSelector({
            itemSidePanelOperation: itemSidePanelOperationSelector,
            currentUserDepartmentId: currentUserDepartmentIdSelector,
            dexData: itemSidePanelDexDataSelector,
            itemSidePanelIsAutosearch: itemSidePanelIsAutosearchSelector,
            query: itemSidePanelQuerySelector,
        }),
        {
            setFormWithSearchResult,
            storeItemSidePanelMasterItemId,
            storeItemSidePanelIsAutosearch,
        }
    ),
    withHandlers({
        onResultClick({
            currentUserDepartmentId,
            setFormWithSearchResult,
            storeItemSidePanelMasterItemId,
            itemSidePanelOperation,
        }) {
            return ({ item: { departmentId, id } }, dexData) => {
                const itemInCurrentUserDepartment = currentUserDepartmentId === departmentId;

                if (
                    itemSidePanelOperation === itemSidePanelOperationEnum.CREATE &&
                    itemInCurrentUserDepartment
                ) {
                    storeItemSidePanelMasterItemId(id);
                }
                return setFormWithSearchResult(
                    id,
                    itemInCurrentUserDepartment,
                    dexData ? generateSidePanelFormDataAsObjFromRmsFirearm(dexData) : undefined
                );
            };
        },
        onAddClick:
            ({ storeItemSidePanelMasterItemId, dexData }) =>
            () => {
                if (dexData) {
                    formsRegistry.maybeDeferredOperation(
                        RefContextEnum.FORM_HYDRATED_ITEM.name,
                        undefined,
                        (form) => {
                            Object.entries(
                                generateSidePanelFormDataFromRmsFirearm(dexData)
                            ).forEach(([key, value]) => {
                                form.set(key, value);
                            });
                        }
                    );
                }
                // called on click of + Use Serial # to indicate this item should be linked to a newly created master item
                return storeItemSidePanelMasterItemId(NEW_MASTER_ITEM_ID);
            },
    })
)(ArbiterMFTEntitySearch);

const SerialNumberRow = withHandlers({
    onSerialNumberChange({ form }) {
        return (value) => {
            form.set('firearm.serialNumber', value);
            if (!!value) {
                // clear the Unknown checkbox when Serial # is non-empty
                form.set('firearm.serialNumberUnknown', false);
                form.resetUi('firearm.serialNumberUnknown');
            }
        };
    },
    onSerialNumberUnknownChange({ form }) {
        return (value) => {
            form.set('firearm.serialNumberUnknown', value);
            if (value) {
                // clear Serial # when Unknown is checked
                form.resetModel('firearm.serialNumber', undefined);
                form.resetUi('firearm.serialNumber');
            }
        };
    },
})(function SerialNumberRow({
    editMode,
    helpTextCollisionBoundary,
    onSerialNumberChange,
    onSerialNumberUnknownChange,
}) {
    const serialNumberForm = editMode ? (
        <ArbiterMFTText onChange={onSerialNumberChange} path="serialNumber" />
    ) : (
        <FieldState
            path="serialNumber"
            render={({ model }) => (
                <FirearmInlineSearch
                    width="100%"
                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                    path="serialNumber"
                    onChange={onSerialNumberChange}
                    addButtonText={strings.addButtonText(model)}
                    entityType={ElasticSearchTypeEnum.PROPERTY.name}
                    filterResults={{ itemTypeAttrId: globalAttributes.itemType.firearm }}
                    isFirearm
                    setSearchValue={noop}
                />
            )}
        />
    );

    return (
        <Stack mb="var(--arc-space-2)">
            {serialNumberForm}
            <ArbiterMFTCheckbox
                helpTextCollisionBoundary={helpTextCollisionBoundary}
                path="serialNumberUnknown"
                onChange={onSerialNumberUnknownChange}
            />
        </Stack>
    );
});

function FirearmFieldset({ editMode, form, helpTextCollisionBoundary, itemCategoryFilterOptions }) {
    return (
        <SidePanelSection>
            <MFTFieldset path="firearm">
                <SerialNumberRow
                    editMode={editMode}
                    form={form}
                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                />
                <ArbiterMFTAttributeSelect
                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                    path="itemCategoryAttrId"
                    attributeType={AttributeTypeEnum.ITEM_CATEGORY.name}
                    filterOptions={itemCategoryFilterOptions}
                    length="lg"
                />
                <ArbiterMFTText
                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                    path="description"
                />
                <ArbiterAtfManufacturerSelect
                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                    path="atfManufacturerId"
                    onChange={() => {
                        form.transaction(() => {
                            form.set('firearm.itemModel', undefined);
                            form.set('firearm.caliber', undefined);
                        });
                    }}
                />

                <ArbiterMFTAttributeSelect
                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                    path="firearmMakeAttrId"
                    attributeType={AttributeTypeEnum.FIREARM_MAKE.name}
                />
                <FeatureFlagged flag="RMS_ETRACE_ATF_INTEGRATION_ENABLED">
                    <FormRow>
                        <Observer
                            subscriptions={{
                                'firearm.atfManufacturerId': 'firearm.atfManufacturerId',
                            }}
                            render={(formModel) => {
                                const atfManufacturerId = formModel['firearm.atfManufacturerId'];

                                if (!atfManufacturerId) {
                                    return null;
                                }

                                return (
                                    <ArbiterAtfModelSelect
                                        path="itemModel"
                                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                                        atfManufacturerId={atfManufacturerId}
                                        length="md"
                                        onChange={() => {
                                            form.set('firearm.caliber', undefined);
                                        }}
                                    />
                                );
                            }}
                        />

                        <Observer
                            subscriptions={{
                                'firearm.atfManufacturerId': 'firearm.atfManufacturerId',
                                'firearm.itemModel': 'firearm.itemModel',
                            }}
                            render={(formModel) => {
                                const atfManufacturerId = formModel['firearm.atfManufacturerId'];

                                if (!atfManufacturerId) {
                                    return null;
                                }

                                const atfWeaponModel = formModel['firearm.itemModel'] ?? '';

                                return (
                                    <ArbiterAtfCaliberSelect
                                        path="caliber"
                                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                                        atfWeaponModel={atfWeaponModel}
                                        atfManufacturerId={atfManufacturerId}
                                        length="md"
                                    />
                                );
                            }}
                        />
                    </FormRow>
                </FeatureFlagged>
                <FormRow>
                    <FeatureFlagged
                        flag="RMS_ETRACE_ATF_INTEGRATION_ENABLED"
                        fallback={
                            <ArbiterMFTText
                                helpTextCollisionBoundary={helpTextCollisionBoundary}
                                path="itemModel"
                                length="md"
                            />
                        }
                    />
                    <ArbiterMFTText
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="registrationNumber"
                        length="md"
                    />
                    <ArbiterMFTAttributeSelect
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="finishAttrId"
                        attributeType={AttributeTypeEnum.ITEM_COLOR.name}
                        length="md"
                    />
                    <ArbiterMFTAttributeSelect
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="stockAttrId"
                        attributeType={AttributeTypeEnum.FIREARM_STOCK.name}
                        length="md"
                    />
                    <ArbiterMFTAttributeSelect
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="gripAttrId"
                        attributeType={AttributeTypeEnum.FIREARM_GRIP.name}
                        length="md"
                    />
                    <ArbiterMFTAttributeSelect
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="ncicFirearmCaliberAttrId"
                        attributeType={AttributeTypeEnum.NCIC_FIREARM_CALIBER_TYPE.name}
                        length="md"
                    />
                    <FeatureFlagged
                        flag="RMS_ETRACE_ATF_INTEGRATION_ENABLED"
                        fallback={
                            <ArbiterMFTText
                                helpTextCollisionBoundary={helpTextCollisionBoundary}
                                path="caliber"
                                length="md"
                            />
                        }
                    />
                    <ArbiterMFTText
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="numberOfShots"
                        length="md"
                    />
                    <ArbiterMFTText
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="barrelLength"
                        length="md"
                    />
                </FormRow>
                <ArbiterMFTAttributeSelect
                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                    path="scopeAttachedAttrId"
                    length="md"
                    attributeType={AttributeTypeEnum.YES_NO_UNKNOWN.name}
                />
                <ArbiterMFTCheckbox
                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                    path="alterationIndicated"
                />
                <ArbiterMFTCheckbox
                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                    path="isRenderedSafe"
                />
                <FormRow hasIndent>
                    <ArbiterMFTUserSelect
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="renderedSafeByOfficerId"
                        includeOther
                        length="lg"
                    />
                </FormRow>
            </MFTFieldset>
            <MFTFieldset path="attributeLinks">
                <ArbiterMFTAttributeSelect
                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                    path="modelNumberLocationAttrIds"
                    attributeType={AttributeTypeEnum.FIREARM_MODEL_NUMBER_LOCATION.name}
                    multiple
                />
                <ArbiterMFTAttributeSelect
                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                    path="serialNumberLocationAttrIds"
                    attributeType={AttributeTypeEnum.FIREARM_SERIAL_NUMBER_LOCATION.name}
                    multiple
                />
            </MFTFieldset>
            <MFTFieldset path="firearm">
                <ArbiterMFTAttributeSelect
                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                    path="indemnityObtainedAttrId"
                    attributeType={AttributeTypeEnum.INDEMNITY_OBTAINED_TYPE.name}
                />
                <ArbiterMFTAttributeSelect
                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                    length="md"
                    path="compensationRequiredAttrId"
                    attributeType={AttributeTypeEnum.YES_NO_UNKNOWN.name}
                />
            </MFTFieldset>
        </SidePanelSection>
    );
}

/**
 * Fieldset in the item entry v2 form that appears only when the item type is firearm. It includes
 *   fields from the Firearm and ItemProfile models.
 */
export default withItemCategoryFilterOptions(globalAttributes.itemType.firearm)(FirearmFieldset);
