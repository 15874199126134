import { get } from 'lodash';
import { PersonProfile } from '@mark43/rms-api';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import { personProfileByIdSelector } from '~/client-common/core/domain/person-profiles/state/data';
import { RootState } from '../../../legacy-redux/reducers/rootReducer';
import { logError, logWarning } from '../../../core/logging';

const warningPrefix = 'Rule Field Getter getDataFromPersonProfileMatchesValue:';

type SupportedEntity = PersonProfile;
type GetPersonProfilePropertyValueProps = {
    /**
     * The property from the entity that we want to evaluate
     */
    propertyName: string;
    /**
     * The value we expect our entity to have at the given `propertyName`
     */
    expectedValue: string;
};

const getEntityContainsWantedData = (
    entity: SupportedEntity,
    args: GetPersonProfilePropertyValueProps
) => {
    const entityValue = get(entity, args.propertyName);

    if (isUndefinedOrNull(entityValue)) {
        return args.expectedValue === '';
    }

    // Currently, comparisons will be limited by this set of primitives
    switch (typeof entityValue) {
        case 'boolean': {
            return entityValue === (args.expectedValue.toLowerCase() === 'true');
        }
        case 'string': {
            return entityValue === args.expectedValue;
        }
        case 'number': {
            return entityValue === parseFloat(args.expectedValue);
        }
        default: {
            // It's not expected that we get into this branch.
            // If we do, allow the rule to pass, and also log an error to sentry
            // to further investigate why
            logError(
                'An invalid data-type was returned from the "getPersonProfilePropertyValue" RFG',
                {
                    extras: {
                        dataType: typeof entityValue,
                    },
                }
            );
            return true;
        }
    }
};

export const getDataFromPersonProfileMatchesValue =
    (getState: () => RootState) => (nameId: number, args: GetPersonProfilePropertyValueProps) => {
        if (isUndefinedOrNull(args.propertyName)) {
            logWarning(`${warningPrefix} missing rule condition argument for propertyName`);
            return true;
        }
        const state = getState();
        const personProfile = personProfileByIdSelector(state)(nameId);
        if (isUndefinedOrNull(personProfile)) {
            return true;
        }
        return getEntityContainsWantedData(personProfile, args);
    };
