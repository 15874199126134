import React, { useCallback, useState } from 'react';
import { withRouter, WithRouterProps } from 'react-router';
import { RefContextEnum } from '@mark43/rms-api';
import styled from 'styled-components';
import { useToast } from 'arc';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';

import { PortalSidePanel } from '../../../../legacy-redux/components/core/SidePanel';
import { useFormGetter } from '../../../core/forms/hooks/useFormGetter';
import { useOverlayStore } from '../../../core/overlays/hooks/useOverlayStore';
import { OverlayBaseHelper } from '../../../core/components/OverlayBaseHelper';
import { ReportIdentifier } from '../../../reports/core/utils/buildReportIdentifierView';
import { getErrorMessagesFromErrors } from '../../../reports/core/helpers/validationHelpers';
import {
    convertFromFormModelToReportBatchRequest,
    convertFromFormModelToReportIdentifiers,
    resetReasonForRelationForm,
} from '../state/form/reasonForRelationForm';
import { DashboardLeftSidebarLoader } from '../../../personal-dashboard/components/DashboardLoaders';
import caseReportLinksResource from '../resources/caseReportLinksResource';
import ReasonForRelationForm from './ReasonForRelationForm';

const SidePanelWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const strings = componentStrings.reports.core.ReasonForRelationSidePanel;

const overlayId = overlayIdEnum.INDIVIDUAL_REPORT_SELECTION_SIDE_PANEL;

const formContext = RefContextEnum.FORM_REASON_FOR_RELATION_MODAL.name;

type IndividualReportSelectionSidePanelProps = WithRouterProps & {
    closePanel: () => void;
    isAtBottomOfStack: () => boolean;
};

export type IndividualReportSelectionSidePanelCustomProperties = {
    onSave: (reportIdentifiers: ReportIdentifier[]) => Promise<void>;
};

const SidePanelContent = ({
    closePanel,
    isAtBottomOfStack,
    router,
}: IndividualReportSelectionSidePanelProps) => {
    const overlayStore = useOverlayStore<IndividualReportSelectionSidePanelCustomProperties>();
    const {
        customProperties: { onSave },
        isLoading,
    } = overlayStore.getStateForId(overlayId);

    const [errors, setErrors] = useState<string[]>([]);

    const { getForm } = useFormGetter();
    const toast = useToast();

    const { singularCaseFieldName: caseDisplayName } = useCaseFieldName();

    const closeSidePanel = useCallback(() => {
        resetReasonForRelationForm(formContext);
        closePanel();
    }, [closePanel]);

    const onSaveSidePanel = useCallback(async () => {
        const form = getForm(formContext);
        if (form) {
            try {
                const result = await form.submit();
                const formModel = result.form.getState().model;
                const request = convertFromFormModelToReportBatchRequest(formModel);
                const reportIds = request.flatMap(({ reportIds }) => reportIds);

                if (!reportIds.length) {
                    throw new Error('Missing Report Ids');
                }
                const {
                    caseToastViews,
                } = await caseReportLinksResource.createCaseReportLinksFromReportsBatch(request);

                resetReasonForRelationForm(formContext);
                closeSidePanel();

                caseToastViews.forEach(({ caseNumber, caseId }) =>
                    toast({
                        status: 'default',
                        description: strings.reportAddedTo(caseDisplayName, caseNumber),
                        action: {
                            children: strings.openText,
                            onClick: () => {
                                router.push(`cases/${caseId}/summary`);
                            },
                        },
                    })
                );

                const reportIdentifiers = convertFromFormModelToReportIdentifiers(formModel);
                await onSave(reportIdentifiers);
            } catch (err) {
                setErrors(getErrorMessagesFromErrors(err));
            }
        }
    }, [caseDisplayName, closeSidePanel, getForm, onSave, router, toast]);

    return (
        <PortalSidePanel
            id={overlayId}
            title={strings.title(caseDisplayName)}
            closePanel={closeSidePanel}
            savePanel={onSaveSidePanel}
            errorMessages={errors}
            isAtBottomOfStack={isAtBottomOfStack}
        >
            {isLoading ? (
                <DashboardLeftSidebarLoader />
            ) : (
                <SidePanelWrapper>
                    <ReasonForRelationForm
                        context={RefContextEnum.FORM_REASON_FOR_RELATION_MODAL.name}
                        formLocation={overlayId}
                    />
                </SidePanelWrapper>
            )}
        </PortalSidePanel>
    );
};

const IndividualReportSelectionSidePanel = (props: WithRouterProps) => (
    <OverlayBaseHelper<IndividualReportSelectionSidePanelCustomProperties> id={overlayId}>
        {(renderProps) => (
            <SidePanelContent
                closePanel={renderProps.closePanel}
                isAtBottomOfStack={renderProps.overlayBase.isAtBottomOfStack}
                {...props}
            />
        )}
    </OverlayBaseHelper>
);

export default withRouter(IndividualReportSelectionSidePanel);
