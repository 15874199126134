import React from 'react';
import { withRouter, WithRouterProps } from 'react-router';
import { EntityTypeEnum } from '@mark43/rms-api';
import styled from 'styled-components';
import { Avatar, cssVar, HStack, VStack } from 'arc';
import { ENTITY_TO_ICON_MAP } from '../../../../constants';
import { PopoutLink as _PopoutLink } from '../../../../../core/components/links/Link';
import { EFilePersonLabels } from '../../table/profiles/EFilePersonLabels';
import { EFileInvolvedProfileGridRowT } from '../../../../types';

const PopoutLink = styled(_PopoutLink)`
    font-size: ${cssVar('arc.fontSizes.sm')};
`;

type EFileProfileNameCellProps = WithRouterProps & Pick<EFileInvolvedProfileGridRowT, 'nameData'>;

export const EFileProfileNameCell = withRouter(({ nameData }: EFileProfileNameCellProps) => {
    const isPerson = nameData.entityType === EntityTypeEnum.PERSON_PROFILE.name;

    const entityPageLink = isPerson
        ? `/profiles/persons/${nameData.entityId}/details`
        : `/profiles/organizations/${nameData.entityId}/details`;

    return (
        <HStack alignItems="flex-start">
            <Avatar
                src={nameData.imageUrl}
                icon={ENTITY_TO_ICON_MAP[nameData.entityType]}
                size="md"
            />
            <VStack alignItems="flex-start" maxWidth="155px">
                <PopoutLink to={entityPageLink} onClickCapture={(e) => e.stopPropagation()}>
                    {nameData.name}
                </PopoutLink>
                {isPerson && <EFilePersonLabels personId={nameData.entityId} />}
            </VStack>
        </HStack>
    );
});
