import { EFileReducerType } from '../../types';
import { EFileActionTypes, actionTypes } from '../actions';

export const initialState: EFileReducerType = {
    eFile: undefined,
    search: {
        profiles: undefined,
        request: undefined,
    },
    selectedEFileEntity: {
        defendantPersonProfile: undefined,
        defendantOrganizationProfile: undefined,
    },
    sidePanel: {
        involvedProfilesForImport: undefined,
        selectedInvolvedProfilesForImport: [],
    },
    grid: {
        selectedInvolvedProfiles: [],
    },
};

export const eFileReducer = (
    state: EFileReducerType,
    action: EFileActionTypes
): EFileReducerType => {
    switch (action.type) {
        case actionTypes.SET_E_FILE:
            return {
                ...state,
                eFile: action.payload.eFile,
            };
        case actionTypes.SET_E_FILE_PROFILES:
            return {
                ...state,
                search: {
                    ...state.search,
                    profiles: action.payload.eFileProfiles,
                },
            };
        case actionTypes.SET_E_FILE_PROFILES_SEARCH_REQUEST:
            return {
                ...state,
                search: {
                    ...state.search,
                    request: action.payload.searchRequest,
                },
            };
        case actionTypes.SET_E_FILE_DEFENDANT_PERSON:
            return {
                ...state,
                selectedEFileEntity: {
                    ...state.selectedEFileEntity,
                    defendantPersonProfile: action.payload.personProfile,
                },
            };
        case actionTypes.SET_E_FILE_DEFENDANT_ORGANIZATION:
            return {
                ...state,
                selectedEFileEntity: {
                    ...state.selectedEFileEntity,
                    defendantOrganizationProfile: action.payload.organizationProfile,
                },
            };
        case actionTypes.SET_E_FILE_INVOLVED_PROFILES_TO_IMPORT:
            return {
                ...state,
                sidePanel: {
                    ...state.sidePanel,
                    involvedProfilesForImport: action.payload.involvedProfilesForImport,
                },
            };
        case actionTypes.SET_E_FILE_SELECTED_INVOLVED_PROFILES_TO_IMPORT:
            return {
                ...state,
                sidePanel: {
                    ...state.sidePanel,
                    selectedInvolvedProfilesForImport:
                        action.payload.selectedInvolvedProfilesForImport,
                },
            };
        case actionTypes.RESET_E_FILE_SIDE_PANEL:
            return {
                ...state,
                sidePanel: {
                    ...initialState.sidePanel,
                },
            };
        case actionTypes.SET_E_FILE_SELECTED_INVOLVED_PROFILES:
            return {
                ...state,
                grid: {
                    ...state.grid,
                    selectedInvolvedProfiles: action.payload.selectedInvolvedProfiles,
                },
            };
        case actionTypes.RESET_E_FILE_GRID:
            return {
                ...state,
                grid: {
                    ...initialState.grid,
                },
            };
        default:
            return state;
    }
};
