import _, { map, get, noop } from 'lodash';
import { annotateFunction, arbiterAnnotations, ruleFieldGetters, fieldTypes } from 'arbiter-mark43';

import environmentEnum from '~/client-common/core/enums/client/environmentEnum';
import { parentAttributeIdByAttributeIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { getOffenseHasVictim } from './getOffenseHasVictim';
import { getPersonProfileIsDead } from './getPersonProfileIsDead';
import { getOffenseVictimHasUnknownSex } from './getOffenseVictimHasUnknownSex';
import { getNibrsCodeFlags } from './getNibrsCodeFlags';
import { getNibrsCodeGroup } from './getNibrsCodeGroup';
import { getNibrsCodeCrimeAgainst } from './getNibrsCodeCrimeAgainst';
import {
    getCodeForAttributeIdAndSource,
    getCodeForAttributeIdsAndSource,
} from './getCodeForAttributeIdAndSource';
import { getChildAttributeCount } from './getChildAttributeCount';
import { getCount } from './getCount';
import { getCountDistinct } from './getCountDistinct';
import { getCodeCode } from './getCodeCode';
import { getProductModule } from './getProductModule';
import { getCountWithValue } from './getCountWithValue';
import { getEvidenceDepartmentConfig } from './getEvidenceDepartmentConfig';
import {
    getAttributeIsOther,
    getAttributeIsNone,
    getAttributesAreOther,
    getAttributesAreNone,
} from './helpers/hasAttributesOfTypeFactory';
import { getOffenseCodeFlag } from './getOffenseCodeFlag';
import { getOffenseCodePropertyValue } from './getOffenseCodePropertyValue';
import { getCountOffenseCodesRequiresAdditionalCrimeCharge } from './getCountOffenseCodesRequiresAdditionalCrimeCharge';
import { getReportHasArrestCard } from './getReportHasArrestCard';
import { getOffenseHasLessThanOrEqualRecoveredVehiclesThanStolen } from './getOffenseHasLessThanOrEqualRecoveredVehiclesThanStolen';
import { getOffenseHasLessThanOrEqualRecoveredPropertyValueThanStolen } from './getOffenseHasLessThanOrEqualRecoveredPropertyValueThanStolen';
import { getOffenderIsUnknownWithNotUnknownRelationship } from './getOffenderIsUnknownWithNotUnknownRelationship';
import { getReportHasMultipleOffenses } from './getReportHasMultipleOffenses';
import { getChargeOffenseCodeIdMatchesLinkedOffense } from './getChargeOffenseCodeIdMatchesLinkedOffense';
import { getVictimIsSuspect } from './getVictimIsSuspect';
import { getPersonProfileInOffenseWhere } from './getPersonProfileInOffenseWhere';
import { getCountNameReportLinksInOffenseWhere } from './getCountNameReportLinksInOffenseWhere';
import { getRelevantRelationshipExistsInEachDomesticViolenceOffense } from './getRelevantRelationshipExistsInEachDomesticViolenceOffense';
import { getIsBeforeCutoverDate } from './getIsBeforeCutoverDate';
import { getPersonProfileNumberOfPhysicalCharacteristics } from './getPersonProfileNumberOfPhysicalCharacteristics';
import { getUnbornPersonProfileRequiresParentRelationship } from './getUnbornPersonProfileRequiresParentRelationship';
import { getIsDateWithinFutureRange } from './getIsDateWithinFutureRange';
import { getVulnerablePersonProfileRequiresParentOrGuardianRelationship } from './getVulnerablePersonProfileRequiresParentOrGuardianRelationship';
import { getSuspectIsOffender } from './getSuspectIsOffender';
import { getIsAgeInvalidForOffenseCode } from './getIsAgeInvalidForOffenseCode';
import { getVictimHasValidDomesticViolenceRelationship } from './getVictimHasValidDomesticViolenceRelationship';
import { getDataFromLinkedEntitiesMatchesValue } from './getDataFromLinkedEntitiesMatchesValue';
import { getDataFromPersonProfileMatchesValue } from './getDataFromPersonProfileMatchesValue';

const onError =
    MARK43_ENV === environmentEnum.DEVELOPER
        ? (errorObj) => {
              // eslint-disable-next-line no-console
              console.warn(errorObj);
          }
        : noop;

export function buildRuleFieldGetterImplementations(getState) {
    const ruleFieldGetterImplementations = {
        [ruleFieldGetters.GET_OFFENSE_HAS_VICTIM]: annotateFunction({
            [arbiterAnnotations.RETURN_TYPE]: fieldTypes.BOOLEAN,
        })(getOffenseHasVictim(getState)),
        [ruleFieldGetters.GET_PERSON_PROFILE_IS_DEAD]: annotateFunction({
            [arbiterAnnotations.RETURN_TYPE]: fieldTypes.BOOLEAN,
        })(getPersonProfileIsDead(getState)),
        [ruleFieldGetters.GET_DATA_FROM_PERSON_PROFILE_MATCHES_VALUE]: annotateFunction({
            [arbiterAnnotations.RETURN_TYPE]: fieldTypes.BOOLEAN,
        })(getDataFromPersonProfileMatchesValue(getState)),
        [ruleFieldGetters.GET_OFFENSE_VICTIM_HAS_UNKNOWN_SEX]: annotateFunction({
            [arbiterAnnotations.RETURN_TYPE]: fieldTypes.BOOLEAN,
        })(getOffenseVictimHasUnknownSex(getState)),
        [ruleFieldGetters.GET_NIBRS_CODE_FLAGS]: annotateFunction({
            [arbiterAnnotations.RETURN_TYPE]: fieldTypes.STRING,
        })(getNibrsCodeFlags(getState)),
        [ruleFieldGetters.GET_NIBRS_CODE_GROUP]: annotateFunction({
            [arbiterAnnotations.RETURN_TYPE]: fieldTypes.STRING,
        })(getNibrsCodeGroup(getState)),
        [ruleFieldGetters.GET_NIBRS_CODE_CRIME_AGAINST]: annotateFunction({
            [arbiterAnnotations.RETURN_TYPE]: fieldTypes.STRING,
        })(getNibrsCodeCrimeAgainst(getState)),
        [ruleFieldGetters.GET_CODE_FOR_ATTRIBUTE_ID_AND_SOURCE]: annotateFunction({
            [arbiterAnnotations.RETURN_TYPE]: fieldTypes.STRING,
        })(getCodeForAttributeIdAndSource({ getState, onError })),
        [ruleFieldGetters.GET_CHILD_ATTRIBUTE_COUNT]: annotateFunction({
            [arbiterAnnotations.RETURN_TYPE]: fieldTypes.NUMBER,
        })(getChildAttributeCount(getState)),
        [ruleFieldGetters.GET_PARENT_ATTRIBUTE_ID]: annotateFunction({
            [arbiterAnnotations.RETURN_TYPE]: fieldTypes.NUMBER,
        })((val) => {
            const state = getState();
            const parentAttributeIdByAttributeId = parentAttributeIdByAttributeIdSelector(state);
            if (typeof val === 'undefined' || val === null) {
                return val;
            } else if (Array.isArray(val)) {
                return map(val, (value) => parentAttributeIdByAttributeId(value));
            } else {
                return parentAttributeIdByAttributeId(val);
            }
        }),
        [ruleFieldGetters.GET_ATTRIBUTE_IS_OTHER]: annotateFunction({
            [arbiterAnnotations.RETURN_TYPE]: fieldTypes.BOOLEAN,
        })(getAttributeIsOther(getState)),
        [ruleFieldGetters.GET_ATTRIBUTE_IS_NONE]: annotateFunction({
            [arbiterAnnotations.RETURN_TYPE]: fieldTypes.BOOLEAN,
        })(getAttributeIsNone(getState)),
        [ruleFieldGetters.GET_ATTRIBUTES_ARE_OTHER]: annotateFunction({
            [arbiterAnnotations.NITEMS_RESOLVER]: true,
            [arbiterAnnotations.RETURN_TYPE]: fieldTypes.BOOLEAN,
        })(getAttributesAreOther(getState)),
        [ruleFieldGetters.GET_ATTRIBUTES_ARE_NONE]: annotateFunction({
            [arbiterAnnotations.NITEMS_RESOLVER]: true,
            [arbiterAnnotations.RETURN_TYPE]: fieldTypes.BOOLEAN,
        })(getAttributesAreNone(getState)),
        [ruleFieldGetters.GET_COUNT]: annotateFunction({
            [arbiterAnnotations.NITEMS_RESOLVER]: true,
            [arbiterAnnotations.RETURN_TYPE]: fieldTypes.NUMBER,
        })(getCount),
        [ruleFieldGetters.GET_COUNT_DISTINCT]: annotateFunction({
            [arbiterAnnotations.NITEMS_RESOLVER]: true,
            [arbiterAnnotations.RETURN_TYPE]: fieldTypes.NUMBER,
        })(getCountDistinct),
        [ruleFieldGetters.GET_DECIMAL_COUNT]: annotateFunction({
            [arbiterAnnotations.RETURN_TYPE]: fieldTypes.NUMBER,
        })((val) => {
            // get the number of decimals in the value of 1 number field
            return get(/\.(\d+)$/.exec(val), '[1].length') || 0;
        }),
        [ruleFieldGetters.GET_PARENT_ATTRIBUTE_IDS]: annotateFunction({
            [arbiterAnnotations.NITEMS_RESOLVER]: true,
            [arbiterAnnotations.RETURN_TYPE]: fieldTypes.ATTRIBUTE,
        })((val) => {
            // get all the parent attribute ids of 1 attribute field across all the items within an
            // NItems, flattened into a single array and de-duped
            const state = getState();
            const parentAttributeIdByAttributeId = parentAttributeIdByAttributeIdSelector(state);
            if (typeof val === 'undefined' || val === null) {
                return val;
            } else if (Array.isArray(val)) {
                return _(val)
                    .map('castedValue')
                    .flatten() // each casted value is either an attrId or an array of attrIds
                    .map((value) => parentAttributeIdByAttributeId(value))
                    .uniq()
                    .value();
            } else {
                return;
            }
        }),
        [ruleFieldGetters.RETURN_ARGS]: (value, ruleConditionArgObject) => ruleConditionArgObject,
        [ruleFieldGetters.GET_CODE_CODE]: annotateFunction({
            [arbiterAnnotations.RETURN_TYPE]: fieldTypes.STRING,
        })(getCodeCode(getState)),
        [ruleFieldGetters.GET_PRODUCT_MODULE_IS_ACTIVE]: annotateFunction({
            [arbiterAnnotations.RETURN_TYPE]: fieldTypes.BOOLEAN,
        })(getProductModule(getState)),
        [ruleFieldGetters.GET_COUNT_WITH_VALUE]: annotateFunction({
            [arbiterAnnotations.NITEMS_RESOLVER]: true,
            [arbiterAnnotations.RETURN_TYPE]: fieldTypes.NUMBER,
        })(getCountWithValue),
        [ruleFieldGetters.GET_EVIDENCE_DEPARTMENT_CONFIG]: getEvidenceDepartmentConfig(getState),
        [ruleFieldGetters.GET_CODE_FOR_ATTRIBUTE_IDS_AND_SOURCE]: annotateFunction({
            [arbiterAnnotations.NITEMS_RESOLVER]: true,
            [arbiterAnnotations.RETURN_TYPE]: fieldTypes.STRING,
        })(getCodeForAttributeIdsAndSource({ getState, onError })),
        [ruleFieldGetters.GET_OFFENSE_CODE_FLAG]: annotateFunction({
            [arbiterAnnotations.RETURN_TYPE]: fieldTypes.BOOLEAN,
        })(getOffenseCodeFlag(getState)),
        [ruleFieldGetters.GET_OFFENSE_CODE_PROPERTY_VALUE]: annotateFunction({
            [arbiterAnnotations.RETURN_TYPE]: fieldTypes.STRING,
        })(getOffenseCodePropertyValue(getState)),
        [ruleFieldGetters.GET_COUNT_OFFENSE_CODES_REQUIRES_ADDITIONAL_CRIME_CHARGE]:
            annotateFunction({
                [arbiterAnnotations.NITEMS_RESOLVER]: true,
                [arbiterAnnotations.RETURN_TYPE]: fieldTypes.NUMBER,
            })(getCountOffenseCodesRequiresAdditionalCrimeCharge(getState)),
        [ruleFieldGetters.GET_REPORT_HAS_ARREST_CARD]: getReportHasArrestCard(getState),
        [ruleFieldGetters.GET_OFFENSE_HAS_LESS_THAN_OR_EQUAL_RECOVERED_VEHICLES_THAN_STOLEN]:
            getOffenseHasLessThanOrEqualRecoveredVehiclesThanStolen(getState),
        [ruleFieldGetters.GET_OFFENSE_HAS_LESS_THAN_OR_EQUAL_RECOVERED_PROPERTY_VALUE_THAN_STOLEN]:
            getOffenseHasLessThanOrEqualRecoveredPropertyValueThanStolen(getState),
        [ruleFieldGetters.GET_OFFENDER_IS_UNKNOWN_WITH_NOT_UNKNOWN_RELATIONSHIP]:
            getOffenderIsUnknownWithNotUnknownRelationship(getState),
        [ruleFieldGetters.GET_REPORT_HAS_MULTIPLE_OFFENSES]: getReportHasMultipleOffenses(getState),
        [ruleFieldGetters.GET_CHARGE_OFFENSE_CODE_ID_MATCHES_LINKED_OFFENSE_OFFENSE_CODE_ID]:
            getChargeOffenseCodeIdMatchesLinkedOffense(getState),
        [ruleFieldGetters.GET_VICTIM_IS_SUSPECT]: annotateFunction({
            [arbiterAnnotations.RETURN_TYPE]: fieldTypes.BOOLEAN,
        })(getVictimIsSuspect(getState)),
        [ruleFieldGetters.GET_SUSPECT_IS_OFFENDER]: annotateFunction({
            [arbiterAnnotations.RETURN_TYPE]: fieldTypes.BOOLEAN,
        })(getSuspectIsOffender(getState)),
        [ruleFieldGetters.GET_PERSON_PROFILE_IN_OFFENSE_WHERE]: annotateFunction({
            [arbiterAnnotations.RETURN_TYPE]: fieldTypes.BOOLEAN,
        })(getPersonProfileInOffenseWhere(getState)),
        [ruleFieldGetters.GET_COUNT_NAME_REPORT_LINKS_IN_OFFENSE_WHERE]: annotateFunction({
            [arbiterAnnotations.RETURN_TYPE]: fieldTypes.NUMBER,
        })(getCountNameReportLinksInOffenseWhere(getState)),
        [ruleFieldGetters.GET_RELEVANT_RELATIONSHIP_EXISTS_IN_EACH_DOMESTIC_VIOLENCE_OFFENSE]:
            annotateFunction({ [arbiterAnnotations.RETURN_TYPE]: fieldTypes.BOOLEAN })(
                getRelevantRelationshipExistsInEachDomesticViolenceOffense(getState)
            ),
        [ruleFieldGetters.GET_IS_BEFORE_RIPA_CUTOVER_DATE]: annotateFunction({
            [arbiterAnnotations.RETURN_TYPE]: fieldTypes.BOOLEAN,
        })(getIsBeforeCutoverDate(getState)),
        [ruleFieldGetters.GET_PERSON_PROFILE_NUMBER_OF_PHYSICAL_CHARACTERISTICS_ENTERED]:
            annotateFunction({ [arbiterAnnotations.RETURN_TYPE]: fieldTypes.BOOLEAN })(
                getPersonProfileNumberOfPhysicalCharacteristics(getState)
            ),
        [ruleFieldGetters.GET_UNBORN_PERSON_PROFILE_REQUIRES_PARENT_RELATIONSHIP]: annotateFunction(
            { [arbiterAnnotations.RETURN_TYPE]: fieldTypes.BOOLEAN }
        )(getUnbornPersonProfileRequiresParentRelationship(getState)),
        [ruleFieldGetters.GET_IS_DATE_WITHIN_FUTURE_RANGE]: annotateFunction({
            [arbiterAnnotations.RETURN_TYPE]: fieldTypes.BOOLEAN,
        })(getIsDateWithinFutureRange(getState)),
        [ruleFieldGetters.GET_VULNERABLE_PERSON_PROFILE_REQUIRES_PARENT_OR_GUARDIAN_RELATIONSHIP]:
            annotateFunction({
                [arbiterAnnotations.RETURN_TYPE]: fieldTypes.BOOLEAN,
            })(getVulnerablePersonProfileRequiresParentOrGuardianRelationship(getState)),
        [ruleFieldGetters.GET_IS_AGE_INVALID_FOR_OFFENSE_CODE]: annotateFunction({
            [arbiterAnnotations.RETURN_TYPE]: fieldTypes.BOOLEAN,
        })(getIsAgeInvalidForOffenseCode(getState)),
        [ruleFieldGetters.GET_VICTIM_HAS_VALID_DOMESTIC_VIOLENCE_RELATIONSHIP]: annotateFunction({
            [arbiterAnnotations.RETURN_TYPE]: fieldTypes.BOOLEAN,
        })(getVictimHasValidDomesticViolenceRelationship(getState)),
        [ruleFieldGetters.GET_DATA_FROM_LINKED_ENTITIES_MATCHES_VALUE]: annotateFunction({
            [arbiterAnnotations.RETURN_TYPE]: fieldTypes.BOOLEAN,
        })(getDataFromLinkedEntitiesMatchesValue(getState)),
    };
    return ruleFieldGetterImplementations;
}
