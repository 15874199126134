import React, { FC, useCallback } from 'react';
import { withRouter, WithRouterProps } from 'react-router';
import styled from 'styled-components';
import { cssVar } from 'arc';
import { Tr, Td } from '@arc/table';

import { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import componentStrings from '~/client-common/core/strings/componentStrings';

import testIds from '../../../../../core/testIds';
import { TargetProfilesDetailsRow } from '../../../../../legacy-redux/components/core/tables/TargetProfilesDetailsRow';
import { DataTableRow, DataTableWrapper } from '../../../../core/components/DataTableWrapper';
import { useCasesSearch } from './useCasesSearch';
import { defaultSorts } from './useCasesSorts';
import { CasesTableColumnOverrides, useColumnDefinitions } from './columns';

const strings = componentStrings.cases.core.CasesTable;

const TargetProfilesRow = styled(Tr)`
    border-bottom: 1px solid ${cssVar('arc.colors.border.default')};

    td,
    .target-profiles-details-row-component {
        padding: 0;
        border: 0;
    }
`;

type CasesTableComponentProps = WithRouterProps & {
    searchQuery: Record<string, unknown>;
    columnOverrides?: CasesTableColumnOverrides;
};

const CasesTableComponent: FC<CasesTableComponentProps> = ({ searchQuery, columnOverrides }) => {
    const { pluralCaseFieldName: caseFieldName } = useCaseFieldName();
    const columnDefinitions = useColumnDefinitions(columnOverrides);

    const { results, initialLoading, loading, pagination, onColumnSortChange, sort } =
        useCasesSearch({ ...defaultSorts, formData: searchQuery });

    const renderRow = useCallback(
        ({ row, renderedCells }) => (
            <>
                <DataTableRow
                    key={row.id}
                    hideBorder={row.original.isTargetProfile}
                    data-test-id={testIds.CASES_TABLE}
                >
                    {renderedCells}
                </DataTableRow>
                {row.original.isTargetProfile && (
                    <TargetProfilesRow>
                        <Td colSpan={renderedCells.length}>
                            <TargetProfilesDetailsRow
                                className="target-profiles-details-row-component"
                                row={row.original}
                            />
                        </Td>
                    </TargetProfilesRow>
                )}
            </>
        ),
        []
    );

    return (
        <DataTableWrapper
            data={results}
            columns={columnDefinitions}
            hasColumnOrdering={false}
            hasColumnResizing={false}
            hasColumnVisibility={false}
            hasStickyHeaders={true}
            paginationProps={pagination}
            tableProps={{ variant: 'accented' }}
            onColumnSortChange={onColumnSortChange}
            config={{ sort }}
            noDataText={strings.noDataText(caseFieldName)}
            loading={loading}
            initialLoading={initialLoading}
            renderRow={renderRow}
        />
    );
};

export const CasesTable = withRouter(CasesTableComponent);
