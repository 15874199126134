import React from 'react';
import { AttributeTypeEnum } from '@mark43/rms-api';
import styled, { withTheme } from 'styled-components';
import { connect } from 'react-redux';
import { filter } from 'lodash';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { formatTitleForElasticVehicleSelector } from '~/client-common/core/domain/elastic-vehicles/state/ui';
import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';
import ImgWithExifOrientation from '~/client-common/core/images/components/ImgWithExifOrientation';
import withFields from '~/client-common/core/fields/components/withFields';
import { VEHICLE_TAG, VEHICLE_VIN_NUMBER } from '~/client-common/core/enums/universal/fields';

import { VehicleEnhancedCautions } from '../../../../core/cautions/components/VehicleEnhancedCautions';
import Icon, { iconTypes } from '../../../../core/components/Icon';
import { VehicleLabels } from '../../../../core/components/tags/VehicleLabels';
import _QuickSearchResultSectionItemTitle from '../QuickSearchResultSectionItemTitle';
import Highlighter from '../QuickSearchHighlighter';
import FieldDisplay from '../layout/FieldDisplay';
import ExternalDepartmentName from '../ExternalDepartmentName';
import GenericContainer from '../layout/GenericContainer';

const Wrapper = styled.div`
    position: relative;
`;

const ProfilePhoto = styled(ImgWithExifOrientation)`
    width: 65px;
    height: 65px;
`;

const ProfilePhotoContainer = styled.div`
    background: ${(props) => props.theme.colors.lightGrey};
    width: 65px;
    height: 65px;
    overflow: hidden;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
`;

const VehicleItemInfoContainer = styled.div`
    vertical-align: top;
    width: calc(100% - 75px);
    margin-left: 10px;
    padding: ${(props) => props.containerPadding || '10px 0'};
    display: inline-block;
`;

const FieldWrapper = styled.div`
    display: block;
`;

const QuickSearchResultSectionItemTitle = styled(_QuickSearchResultSectionItemTitle)`
    margin-bottom: 5px;
`;

const QuickSearchResultSectionVehicleItem = ({
    query,
    item,
    formatAttributeById,
    formatTitleForElasticVehicle,
    theme,
    externalDepartmentName,
    fieldDisplayNames,
    testId,
    testType,
}) => {
    const searchWords = [query];
    const { primaryProfilePhotoPath, itemAttributes, cautions } = item;
    const registrationState = formatAttributeById(item.registrationStateAttrId);
    const title = formatTitleForElasticVehicle(item, { omitProps: 'itemCategory' });

    return (
        <Wrapper data-test-id={testId} data-test-type={testType}>
            <ProfilePhotoContainer>
                {primaryProfilePhotoPath ? (
                    <ProfilePhoto src={primaryProfilePhotoPath} />
                ) : (
                    <Icon type={iconTypes.VEHICLE} color="mediumLightGrey" size={40} />
                )}
            </ProfilePhotoContainer>
            <VehicleItemInfoContainer>
                <QuickSearchResultSectionItemTitle width="100%">
                    <Highlighter searchWords={searchWords} textToHighlight={title} />
                    {(item.primaryColorAttrId || item.bodyStyleAttrId) && (
                        <GenericContainer italic={true} color={theme.colors.lightGrey}>
                            {' '}
                            <Highlighter
                                searchWords={searchWords}
                                textToHighlight={joinTruthyValues(
                                    [
                                        formatAttributeById(item.primaryColorAttrId),
                                        item.bodyStyleOther ||
                                            formatAttributeById(item.bodyStyleAttrId),
                                    ],
                                    ' '
                                )}
                            />
                        </GenericContainer>
                    )}{' '}
                    <ExternalDepartmentName theme={theme} departmentName={externalDepartmentName} />
                </QuickSearchResultSectionItemTitle>
                {item.tag && (
                    <FieldWrapper>
                        <FieldDisplay
                            fieldDisplayNames={fieldDisplayNames}
                            field={VEHICLE_TAG}
                            theme={theme}
                            width="100%"
                        >
                            <Highlighter
                                searchWords={searchWords}
                                textToHighlight={`${item.tag}${
                                    registrationState ? ` (${registrationState})` : ''
                                }`}
                            />
                        </FieldDisplay>
                    </FieldWrapper>
                )}
                {item.vinNumber && (
                    <FieldWrapper>
                        <FieldDisplay
                            fieldDisplayNames={fieldDisplayNames}
                            field={VEHICLE_VIN_NUMBER}
                            theme={theme}
                            width="100%"
                        >
                            <Highlighter
                                searchWords={searchWords}
                                textToHighlight={item.vinNumber}
                            />
                        </FieldDisplay>
                    </FieldWrapper>
                )}
                <FeatureFlagged
                    flag="RMS_VEHICLE_CAUTIONS_ENHANCEMENTS_ENABLED"
                    fallback={
                        <FeatureFlagged flag="RMS_VEHICLE_CAUTIONS_ENABLED">
                            <VehicleLabels
                                elasticItemAttributes={filter(itemAttributes, {
                                    itemAttrDetail: {
                                        type: AttributeTypeEnum.VEHICLE_LABEL_ATTRIBUTES.name,
                                    },
                                })}
                                priority1Only={true}
                                isActiveTarget={item.isActiveTarget}
                                isStolen={item.isStolen}
                                size="sm"
                            />
                        </FeatureFlagged>
                    }
                >
                    <VehicleEnhancedCautions
                        cautions={cautions}
                        isStolen={item.isStolen}
                        isActiveTarget={item.isActiveTarget}
                        size="sm"
                        includePriority1Only
                    />
                </FeatureFlagged>
            </VehicleItemInfoContainer>
        </Wrapper>
    );
};

export default connect((state) => ({
    formatAttributeById: formatAttributeByIdSelector(state),
    formatTitleForElasticVehicle: formatTitleForElasticVehicleSelector(state),
}))(withFields([VEHICLE_TAG, VEHICLE_VIN_NUMBER])(withTheme(QuickSearchResultSectionVehicleItem)));
