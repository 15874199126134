import { keys, map } from 'lodash';

import { Attachment } from '@mark43/rms-api';

export function computeSelectedAttachments({
    attachmentsByEntity,
    initialSelectedAttachments,
    selectedEntities,
}: {
    attachmentsByEntity: Record<number, Attachment[]>;
    initialSelectedAttachments: Record<number, number[]>;
    selectedEntities?: number[];
}) {
    return keys(attachmentsByEntity)
        .map(Number)
        .reduce<Record<number, number[]>>((acc, entityId) => {
            if(selectedEntities && !selectedEntities.includes(entityId)) {
                return {
                    ...acc,
                    [entityId]: [],
                };
            }
            // skipping only entities which exist in the provided initial state
            // is important so that we still get a good default state for missing
            // entities.
            if (initialSelectedAttachments && initialSelectedAttachments[entityId]) {
                acc[entityId] = initialSelectedAttachments[entityId];
            } else if (attachmentsByEntity[entityId].length > 0) {
                acc[entityId] = map(
                    attachmentsByEntity[entityId],
                    (attachment) => attachment.attachmentId
                );
            }
            return acc;
        }, {});
}
