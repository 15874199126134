import { reduce } from 'lodash';
import { createSelector } from 'reselect';

import { offenseByIdSelector } from '~/client-common/core/domain/offenses/state/data';
import sortCharges from '../../../charges/utils/sortCharges';
import { nibrsOffenseCodeForOffenseCodeIdSelector } from '../../../offense-codes/state/data';
import {
    chargesWhereSelector,
    NEXUS_STATE_PROP as CHARGES_NEXUS_STATE_PROP,
} from '../../../charges/state/data';
import {
    NEXUS_STATE_PROP as ARRESTS_NEXUS_STATE_PROP,
    arrestForReportIdSelector,
} from '../../../arrests/state/data';
import getReportCardBundleResource from '../../resources/reportCardBundleResource';

export const nibrsOffenseCodeForFirstReportableChargeSelector = createSelector(
    chargesWhereSelector,
    offenseByIdSelector,
    nibrsOffenseCodeForOffenseCodeIdSelector,
    (chargesWhere, offenseById, nibrsOffenseCodeForOffenseCodeId) => (arrestId) => {
        const orderedCharges = sortCharges({ charges: chargesWhere({ arrestId }) });
        // Get the first reportable charge with a nibrs code (if any)
        return reduce(
            orderedCharges,
            (result, charge) => {
                const offense = offenseById(charge.offenseId);
                const nibrsOffenseCodeFromOffense =
                    offense?.nibrsCode ?? offense?.offenseCode?.nibrsCode;
                // charge.offenseId does not exist when a "Legacy Offense" is selected (charge.legacyCharge)
                const nibrsOffenseCodeFromCharge = nibrsOffenseCodeForOffenseCodeId(
                    charge.chargeOffenseCodeId
                );
                return result || nibrsOffenseCodeFromOffense || nibrsOffenseCodeFromCharge;
            },
            undefined
        );
    }
);

export function replaceChargesCardBundle({ reportId, chargesCardBundle }) {
    return function (dispatch, getState, { nexus: { withEntityItems } }) {
        const resource = getReportCardBundleResource();
        return resource
            .upsertChargesCard(reportId, chargesCardBundle)
            .then((updatedChargesCardBundle) => {
                const state = getState();
                const { arrestNibrsOffenseCode, charges } = updatedChargesCardBundle;
                const existingArrest = arrestForReportIdSelector(state)(reportId);
                const updatedArrest = {
                    ...existingArrest,
                    nibrsCode: arrestNibrsOffenseCode,
                };

                dispatch(
                    withEntityItems(
                        {
                            [ARRESTS_NEXUS_STATE_PROP]: [updatedArrest],
                            [CHARGES_NEXUS_STATE_PROP]: charges,
                        },
                        {
                            type: 'UPSERT_CHARGES_CARD_BUNDLE',
                        }
                    )
                );

                return updatedChargesCardBundle;
            });
    };
}
