import React from 'react';
import { DataList, DataItem, DataItemProps } from 'arc';

type ListItem = {
    label?: string;
    value?: string;
};

export const EFileProfileDataListCell = <T extends ListItem>({
    items,
    variant,
}: {
    items: T[];
    variant?: DataItemProps['variant']; // there's no DataListProps yet
}) => {
    return (
        <DataList variant={variant}>
            {items.map((item, index) => {
                return (
                    <DataItem key={index} dataKey={item.label}>
                        {item.value}
                    </DataItem>
                );
            })}
        </DataList>
    );
};
