import * as yup from 'yup';
import { defaultsDeep } from 'lodash';

import overlayStateTypeEnum from '~/client-common/core/enums/client/overlayStateTypeEnum';

const DEFAULT_SCHEMA_SENTINEL = 'DEFAULT_SCHEMA_SENTINEL';
const _schemas = {};

/**
 * Creates a screen state schema for the person side panel
 */
function createPersonProfileSidePanelScreenStateSchema() {
    return yup
        .object()
        .noUnknown(true)
        .shape({
            /**
             * The next screen to navigate to
             */
            nextScreen: yup.string().nullable(true).default(null),
            /**
             * The current screen we are viewing
             */
            currentScreen: yup.string().nullable(true).default(null),
            /**
             * Error messages for this screen
             */
            errorMessages: yup.array().of(yup.string()).default([]),

            /**
             * Whether the save button of the overlay should be disabled
             */
            saveDisabled: yup.boolean().default(false),

            /**
             * The id of the currently selected item
             */
            selectedId: yup.number().nullable(true).default(null),

            /**
             * The id of the unknown we want to identify
             */
            idToIdentify: yup.number().nullable(true).default(null),

            /**
             * The ids of the retrieved search results
             */
            searchResultIds: yup.array().of(yup.number()).default([]),

            /**
             * The total number of search results
             */
            totalSearchResultCount: yup.number().nullable(true).default(null),

            /**
             * Person Profile from DEx results
             */
            dexPerson: yup.object().nullable(true).default(null),
        });
}

/**
 * Creates a screen state schema for the location side panel
 */
function createLocationSidePanelScreenStateSchema() {
    return yup
        .object()
        .noUnknown(true)
        .shape({
            /**
             * The current screen we are viewing
             */
            currentScreen: yup.string().nullable(true).default(null),
            /**
             * Error messages for this screen
             */
            errorMessages: yup.array().of(yup.string()).default([]),
            /**
             * Location search results
             */
            searchResults: yup
                .array()
                // NOTE we could make this more restrictive
                .of(yup.object())
                .default([]),

            /**
             * Selected location
             */
            currentLocation: yup
                // NOTE we could make this more restrictive
                .object()
                .nullable()
                .default(null),

            /**
             * Track the initial form state to initialize
             */
            initialFormState: yup.object().default({}),
        });
}

function createChargesSidePanelScreenStateSchema() {
    return yup
        .object()
        .noUnknown(true)
        .shape({
            /**
             * The current screen we are viewing
             */
            currentScreen: yup.string().nullable(true).default(null),
            isInitialLoad: yup.boolean().default(false),
            initialHydratedCharges: yup.array().of(yup.object().default(null)).default([]),
            offenseOrderOfEditingCharge: yup.number().nullable().default(null),
            isPerformingAsyncAction: yup.boolean().default(false),
            editLegacyOffensePrefill: yup.object().default({}),
            editStubWarrantIdPrefill: yup.number().nullable().default(null),
            isOtherJurisdictionPrefill: yup.boolean().nullable().default(null),
            priorOffenseSearch: yup
                .object()
                .nullable()
                .default(null)
                .shape({
                    orderedCurrentResultElasticReportIds: yup.array().of(yup.number()).default([]),
                    elasticQuery: yup.object().default({}),
                    from: yup.number().default(0),
                    size: yup.number().default(0),
                    totalCount: yup.number().default(0),
                    priorOffenseSearchSelectedElasticReportViewModel: yup
                        .object()
                        .nullable()
                        .default(null),
                }),
            findWarrantSearch: yup
                .object()
                .nullable()
                .default(null)
                .shape({
                    orderedCurrentResultElasticWarrantIds: yup.array().of(yup.number()).default([]),
                    elasticQuery: yup.object().default({}),
                    from: yup.number().default(0),
                    size: yup.number().default(0),
                    totalCount: yup.number().default(0),
                }),
        });
}

function createTowVehicleReleaseSidePanelScreenStateSchema() {
    return yup
        .object()
        .noUnknown(true)
        .shape({
            /**
             * The current screen we are viewing
             */
            currentScreen: yup.string().nullable(true).default(null),
            isPerformingAsyncAction: yup.boolean().default(false),
        });
}

function createTowVehicleCheckInSidePanelScreenStateSchema() {
    return yup
        .object()
        .noUnknown(true)
        .shape({
            /**
             * The current screen we are viewing
             */
            currentScreen: yup.string().nullable(true).default(null),
            isPerformingAsyncAction: yup.boolean().default(false),
        });
}

/**
 * Creates a screen state schema for a given overlay id. If no id is provided,
 * an empty schema will be returned.
 * @param {string} [overlayStateType] The overlay type to create custom properties for
 */
function createScreenStateSchemaForOverlayStateType(overlayStateType) {
    switch (overlayStateType) {
        case overlayStateTypeEnum.PERSON_OVERLAY:
        case overlayStateTypeEnum.ORGANIZATION_OVERLAY:
            return createPersonProfileSidePanelScreenStateSchema();

        case overlayStateTypeEnum.LOCATION_OVERLAY:
            return createLocationSidePanelScreenStateSchema();

        case overlayStateTypeEnum.CHARGES_OVERLAY:
            return createChargesSidePanelScreenStateSchema();

        case overlayStateTypeEnum.TOW_VEHICLE_RELEASE_OVERLAY:
            return createTowVehicleReleaseSidePanelScreenStateSchema();

        case overlayStateTypeEnum.TOW_VEHICLE_CHECK_IN_OVERLAY:
            return createTowVehicleCheckInSidePanelScreenStateSchema();

        default:
            // an empty object allows to prevent using `screenState` for
            // arbitrary values when no custom schems is available for a given overlay id
            return yup
                .object()
                .noUnknown(true)
                .shape({
                    /**
                     * The current screen we are viewing
                     */
                    currentScreen: yup.string().nullable(true).default(null),
                });
    }
}

/**
 * Creates a property schema for a given overlay id. If no id is provided,
 * a base schema will be created
 * @param {string} [overlayStateType] The overlay type to create custom properties for
 */
function createCustomPropertySchema(overlayStateType) {
    return yup
        .object()
        .noUnknown(true)
        .shape({
            /**
             * Indicates if the overlay is currently saving
             * This is different from `loading`, which is natively supported
             * by our overlay manager and has its own api which does not require
             * custom properties
             */
            isSaving: yup.boolean().default(false),

            /**
             * The screen stack holds information unique to each screen currently visible within an overlay
             */
            screenStack: yup
                .array()
                .of(
                    yup
                        .object()
                        .noUnknown(true)
                        .shape({
                            /**
                             * The screen to which the state belongs
                             */
                            screen: yup.string().required(),
                            /**
                             * The screen's state
                             */
                            screenState: createScreenStateSchemaForOverlayStateType(
                                overlayStateType
                            ),
                            formSectionState: yup.object(),
                        })
                )
                .default([]),
        });
}

/**
 * Creates a validated custom properties object which can be passed to `overlayStore.setCustomProperties`
 * This function is mutative, e.g. it will mutate the passed-in `customProperties` object
 * by assigning defaults to it where necessary.
 * @param {Object} customProperties The custom properties to validate
 * @param {string} [overlayId] The overlay to create custom properties for
 */
export function createOverlayCustomProperties(
    customProperties,
    overlayId = DEFAULT_SCHEMA_SENTINEL,
    { setDefaults } = { setDefaults: false }
) {
    const schema =
        _schemas[overlayId] || (_schemas[overlayId] = createCustomPropertySchema(overlayId));

    const result = schema.validateSync(customProperties, {
        strict: true,
        recursive: true,
        abortEarly: true,
    });

    // we have to manually assign default values before returning the object. This is required because
    // we want the benefits of the strict `strict` option above, so that we can get runtime type checking
    // without casting. Unfortunately this means that `yup` cannot assign default values on its own
    // as any kind of transformation is skipped in strict mode.
    // Just using `schema.default()` does not work here as it does not return a full object with all defaults,
    // since by default no elements exist in `screenStack`. Casting the passed-in object and then assigning it
    // yields the desired behavior of strictness and default values.
    return setDefaults ? defaultsDeep(result, schema.cast(result)) : result;
}
