import { ElasticSearchTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { keys, invert } from 'lodash';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { cssVar } from 'arc';
import { withRouter } from 'react-router';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { consortiumDepartmentLinksAvailableSelector } from '~/client-common/core/domain/consortium-link-view/state/ui';
import _Checkbox from '../../../core/forms/components/checkboxes/Checkbox';
import testIds from '../../../../core/testIds';
import AsyncText from '../../../core/forms/components/AsyncText';
import { SearchErrorBoundary } from '../../../core/errors/components/ErrorBoundary';
import { clearfix } from '../../../core/styles/mixins';

import { MIN_QUERY_LENGTH, quickSearchResultTypeToPropMap } from '../config';
import {
    triggerQuickSearch,
    triggerQuickSearchLoadMoreForType,
    quickSearchResultsSelector,
    quickSearchisLoadingSelector,
    quickSearchIsSectionLoadingForTypeSelector,
    quickSearchErrorSelector,
    quickSearchQuerySelector,
    resetQuickSearchState,
    quickSearchHasResultsSelector,
    updateQuickSearchQuery,
    quickSearchDefaultResultTypesSelector,
} from '../state/ui';
import Icon, { iconTypes } from '../../../core/components/Icon';
import zIndexes from '../../../core/styles/zIndexes';
import advancedSearchCadTickets from '../../cad-tickets/state/ui';
import handleResultItemSelection from '../helpers/handleResultItemSelection';
import AdvancedSearchTypesMenu from './AdvancedSearchTypesMenu';
import QuickSearchResults from './QuickSearchResults';

const QUICK_SEARCH_INPUT_WIDTH = 292;
const MAX_QUERY_LENGTH = 200;

// height of the navigation bar plus
// some space towards the bottom of the page
const QUICK_SEARCH_SPACING = '220px';

const { quickSearch: quickSearchStrings } = componentStrings;

export const QuickSearchWrapper = styled.div`
    position: absolute;
    background: ${(props) => props.theme.colors.white};
    top: 60px;
    width: 500px;
    border-radius: 0 0 5px 5px;
    box-shadow: ${(props) => `0 5px 12px ${props.theme.colors.mediumLightGrey}`};
`;

const InputWrapper = styled.div`
    background: ${(props) => props.theme.colors.brightBlue};
    box-sizing: border-box;
    padding: 10px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    z-index: ${zIndexes.quickSearch};
    position: relative;
    ${clearfix};
`;

export const ResultsWrapper = styled.div`
    overflow: auto;
    border-radius: 0 0 5px 5px;
    ${(props) => !props.$isFullHeight && `max-height: calc(100vh - ${QUICK_SEARCH_SPACING})`};
`;

// using !important here is gross,
// but since we are mixing classes and
// styled components that's how it is.
const QuickSearchInput = styled(AsyncText)`
    margin: 0 10px 0 0 !important;
    color: ${cssVar('arc.colors.text.primary')};
    background-color: ${cssVar('arc.colors.surface.foreground')};

    & input {
        padding-left: 26px;
    }
`;

const SearchInputIcon = styled(Icon)`
    position: absolute;
    left: 14px;
    z-index: 1;
    padding: 3px;
    top: 16px;
    border-right: ${(props) => `1px solid ${props.theme.colors.lightGrey}`};
`;

const Checkbox = styled(_Checkbox)`
    margin: 10px 0 0;

    label {
        color: ${(props) => props.theme.colors.white};
    }
`;

export class QuickSearch extends React.Component {
    constructor(props, ...args) {
        super(props, ...args);
        // As soon as we start using the babel plugin for
        // class properties, this should be removed and the functions
        // rewritten as arrow functions which then get auto-bound
        this.handleQueryChange = this.handleQueryChange.bind(this);
        this.handleInputEnterPress = this.handleInputEnterPress.bind(this);
        this.handleAdvancedSearchButtonClick = this.handleAdvancedSearchButtonClick.bind(this);
        this.handleQueryOnFocus = this.handleQueryOnFocus.bind(this);
        this.triggerSearchQuery = this.triggerSearchQuery.bind(this);
        // This handler is being passed to child components
        // We do this to have a centralized way of controlling
        // how different result types should behave when being selected
        this.handleResultItemSelection = handleResultItemSelection({
            defaultAction: props.onClose,
            router: props.router,
            customItemTypeActions: {
                [ElasticSearchTypeEnum.CAD_TICKET.name]: props.handleCadResultClick,
            },
        });
        this.state = {
            showAdvancedMenu: false,
            excludeExternalAgencyResults: !!props.applicationSettings
                ?.RMS_CONSORTIUM_QUICK_SEARCH_FILTER,
        };
    }

    componentWillUnmount() {
        this.props.resetQuickSearchState();
    }

    triggerSearchQuery(value) {
        const val = value.trim();
        if (val.length < MIN_QUERY_LENGTH || val.length > MAX_QUERY_LENGTH) {
            return;
        }

        return this.props.triggerQuickSearch({
            allowedEntityTypes: this.props.allowedEntityTypes,
            query: val,
            excludeExternalAgencyResults: this.state.excludeExternalAgencyResults,
        });
    }

    handleQueryChange(value) {
        // if we moved from an invalid query to a valid query we want to
        // reset the result state before updating our query to prevent
        // flickering of old results
        const prevQueryLength = this.props.query.trim().length;
        const newQueryLength = value.trim().length;
        const prevQueryInvalid =
            prevQueryLength < MIN_QUERY_LENGTH || prevQueryLength > MAX_QUERY_LENGTH;
        const newQueryValid =
            newQueryLength >= MIN_QUERY_LENGTH && newQueryLength <= MAX_QUERY_LENGTH;
        if ((newQueryValid && prevQueryInvalid) || (!newQueryValid && !prevQueryInvalid)) {
            this.props.resetQuickSearchState();
        }
        this.props.updateQuickSearchQuery(value);
    }

    handleQueryOnFocus() {
        if (this.state.showAdvancedMenu) {
            this.setState(() => ({ showAdvancedMenu: false }));
        }
    }

    handleAdvancedSearchButtonClick() {
        this.setState((state) => ({
            showAdvancedMenu: !state.showAdvancedMenu,
        }));
    }

    handleInputEnterPress() {
        if (this.props.hasResults) {
            const { results } = this.props;
            // Loop through all the results we have and check if
            // there is exactly one item. If so, then we can navigate directly
            // to it once the user presses "enter". If we have more than one
            // result we don't do anything.
            const item = keys(results).reduce(
                (acc, key) => {
                    const { items } = results[key];
                    if (items.length > 1) {
                        acc.onlyOne = false;
                    }

                    if (items.length === 1) {
                        if (acc.item) {
                            acc.onlyOne = false;
                        }
                        acc.item = items[0];
                        acc.type = key;
                    }

                    if (!acc.onlyOne) {
                        return acc;
                    }

                    return acc;
                },
                { item: undefined, onlyOne: true }
            );

            if (item.onlyOne) {
                // sections know their items' types via the `type` prop.
                // since this is a global handler, we have to infer the actual
                // type from the result key.
                const itemType = invert(quickSearchResultTypeToPropMap)[item.type];
                this.handleResultItemSelection({ item: item.item, itemType });
            }
        }
    }

    handleExcludeExternalAgencyResultsChange = (excludeExternalAgencyResults) =>
        this.setState({ excludeExternalAgencyResults }, () =>
            this.triggerSearchQuery(this.props.query)
        );

    render() {
        const { query, results, resultTypes, isLoading, hasResults, error } = this.props;
        return (
            <SearchErrorBoundary>
                <QuickSearchWrapper>
                    {this.props.isPlainInput ? (
                        <AsyncText
                            label={this.props.inputLabel}
                            onChange={this.handleQueryChange}
                            onFocus={this.handleQueryOnFocus}
                            autoFocus={true}
                            value={query}
                            loading={isLoading}
                            placeholder={quickSearchStrings.QuickSearchInput.placeholder}
                            onPressEnter={this.handleInputEnterPress}
                            asyncAction={this.triggerSearchQuery}
                            typeaheadThrottle={600}
                            fieldName={testIds.QUICK_SEARCH_TEXT_INPUT}
                            disabled={this.props.disabled}
                        />
                    ) : (
                        <InputWrapper data-test-id={testIds.QUICK_SEARCH_TEXT_INPUT}>
                            <QuickSearchInput
                                onChange={this.handleQueryChange}
                                onFocus={this.handleQueryOnFocus}
                                autoFocus={true}
                                value={query}
                                loading={isLoading}
                                width={QUICK_SEARCH_INPUT_WIDTH}
                                placeholder={quickSearchStrings.QuickSearchInput.placeholder}
                                onPressEnter={this.handleInputEnterPress}
                                asyncAction={this.triggerSearchQuery}
                                typeaheadThrottle={400}
                                fieldName={testIds.QUICK_SEARCH_TEXT_INPUT}
                            />
                            <SearchInputIcon type={iconTypes.SEARCH} size={13} color="cobaltBlue" />
                            <AdvancedSearchTypesMenu
                                onButtonClick={this.handleAdvancedSearchButtonClick}
                                onMenuItemClick={this.props.onClose}
                                expanded={this.state.showAdvancedMenu}
                                resultTypes={resultTypes}
                            />
                            {this.props.consortiumDepartmentLinksAvailable && (
                                <Checkbox
                                    value={this.state.excludeExternalAgencyResults}
                                    onChange={this.handleExcludeExternalAgencyResultsChange}
                                    label={quickSearchStrings.generic.hideExternalResults}
                                />
                            )}
                        </InputWrapper>
                    )}
                    <ResultsWrapper $isFullHeight={this.props.isFullHeight}>
                        <QuickSearchResults
                            results={results}
                            resultTypes={resultTypes}
                            hasResults={hasResults}
                            error={error}
                            query={query}
                            isLoading={isLoading}
                            isSectionLoadingForType={this.props.isSectionLoadingForType}
                            onResultClick={
                                this.props.onSearchResultClick || this.handleResultItemSelection
                            }
                            excludeExternalAgencyResults={this.state.excludeExternalAgencyResults}
                            minQueryLength={MIN_QUERY_LENGTH}
                            maxQueryLength={MAX_QUERY_LENGTH}
                            triggerQuickSearchLoadMoreForType={
                                this.props.triggerQuickSearchLoadMoreForType
                            }
                        />
                    </ResultsWrapper>
                </QuickSearchWrapper>
            </SearchErrorBoundary>
        );
    }
}

export default connect(
    (state) => ({
        results: quickSearchResultsSelector(state),
        isLoading: quickSearchisLoadingSelector(state),
        isSectionLoadingForType: quickSearchIsSectionLoadingForTypeSelector(state),
        error: quickSearchErrorSelector(state),
        hasResults: quickSearchHasResultsSelector(state),
        query: quickSearchQuerySelector(state),
        resultTypes: quickSearchDefaultResultTypesSelector(state),
        applicationSettings: applicationSettingsSelector(state),
        consortiumDepartmentLinksAvailable: consortiumDepartmentLinksAvailableSelector(state),
    }),
    {
        triggerQuickSearch,
        triggerQuickSearchLoadMoreForType,
        resetQuickSearchState,
        updateQuickSearchQuery,
        handleCadResultClick: advancedSearchCadTickets.actionCreators.openSearchResult,
    }
)(withRouter(QuickSearch));
