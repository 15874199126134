import { createStructuredSelector } from 'reselect';
import { EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import * as fields from '~/client-common/core/enums/universal/fields';
import { replaceLocationLinksForEntity } from '~/client-common/core/domain/location-entity-links/state/data';
import _Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import Icon, { iconTypes } from '../../../core/components/Icon';
import { updateCaseNote, removeCaseNotes, updateCaseNoteInFolder } from '../state/data';
import Text from '../../../core/forms/components/Text';
import { LocationSidePanel } from '../../../core/locations/components/LocationSidePanel';
import { Editor as _Editor } from '../../../core/editor/components/TinyEditor';
import { withTinyEditor } from '../../../core/editor/utils/withTinyEditor';
import { sanitizeTinyMCEValue } from '../../../core/editor/utils/tinyEditor';
import { initializeAttachmentsSidePanelForm } from '../../../attachments/core/state/ui/attachmentsSidePanel';
import { openErrorModal } from '../../../../legacy-redux/actions/boxActions';
import { DragStatuses } from '../../core/components/drag-and-drop';
import { permanentlyDeleteCaseNotesFromFolder } from '../../core/state/data/folders';

import { DraggingIdContext, SelectedRowsContext, KebabMenuContext } from '../../core/contexts';

import { KebabMenuActions } from '../../core/components/KebabMenu';

import Modal from '../../../core/overlays/components/Modal';
import _OverlayButton from '../../../core/overlays/components/OverlayButton';
import AttachmentsSidePanel from '../../../attachments/core/components/AttachmentsSidePanel';
import testIds from '../../../../core/testIds';
import CaseNoteLocation from './CaseNoteLocation';
import CaseNoteAttachments from './CaseNoteAttachments';

const strings = componentStrings.cases.caseSummary.CaseNotes;
const Editor = styled(_Editor)`
    width: 100%;
    height: auto;
    border-top: 6px solid ${(props) => props.theme.colors.mediumGrey};
    flex-grow: 2;
    overflow: auto;
    flex-shrink: 1;
    flex-basis: 100%;
    .tox-editor-header {
        position: sticky;
        top: 0px;
    }
`;

const Button = styled(_Button)`
    margin-right: 20px;
`;

const OverlayButton = styled(_OverlayButton)`
    margin-right: 20px;
`;

const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
`;

const NoteControls = styled.div`
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const NoteLeftControls = styled.div`
    display: flex;
`;

const NoteTitleInput = styled(Text)`
    box-sizing: border-box;
    width: calc(100% - 10px);
    margin-left: 0;
    padding-left: 8px;
    box-shadow: none !important;
    border: 0;
    font-weight: 600;
    font-size: var(--arc-fontSizes-lg);
`;

const CASE_NOTES_TINYMCE_EDITOR_ID = 'CASE_NOTES_TINYMCE_EDITOR';

class BaseCaseNoteEditor extends React.Component {
    state = {
        title: this.props.caseNote.title,
        content: this.props.caseNote.content,
    };

    deleted = false;
    componentWillUnmount() {
        if (this.props.applicationSettings.RMS_CASE_FOLDERING_ENABLED) {
            const { dragStatus, setDragStatus } = this.props.draggingIdContext;
            const { initiatedAction, setInitiatedAction } = this.props.kebabMenuContext;
            const { setSelectedRows } = this.props.selectedRowsContext;

            if (
                initiatedAction !== KebabMenuActions.REMOVE_FROM_FOLDER &&
                initiatedAction !== KebabMenuActions.DELETE &&
                dragStatus !== DragStatuses.COMPLETE
            ) {
                this.saveCaseNote();
            } else {
                setSelectedRows([]);
            }

            if (setInitiatedAction) {
                setInitiatedAction(undefined);
            }

            if (setDragStatus) {
                setDragStatus(DragStatuses.HAS_NOT_STARTED);
            }
        } else {
            if (!this.deleted) {
                this.saveCaseNote();
            }
        }
    }

    deleteCaseNoteFromTopLevel = () => {
        this.props
            .removeCaseNotes([this.props.caseNote.id])
            .then(() => {
                this.props.router.push(`/cases/${this.props.caseNote.caseId}/notes`);
            })
            .catch((err) => {
                this.deleted = false;
                this.props.openErrorModal({
                    title: err.name,
                    paragraphs: [err.message],
                });
            });
    };

    deleteCaseNote = () => {
        if (this.props.applicationSettings.RMS_CASE_FOLDERING_ENABLED) {
            this.props.kebabMenuContext.setInitiatedAction(KebabMenuActions.DELETE);
            if (this.props.folderId) {
                this.props
                    .permanentlyDeleteCaseNotesFromFolder(this.props.folderId, [
                        this.props.caseNote.id,
                    ])
                    .catch((err) => {
                        this.deleted = false;
                        this.props.openErrorModal({
                            title: err.name,
                            paragraphs: [err.message],
                        });
                    });
            } else {
                this.deleteCaseNoteFromTopLevel();
            }
        } else {
            this.deleted = true;
            this.deleteCaseNoteFromTopLevel();
        }
    };

    saveCaseNote = () => {
        const editor = withTinyEditor(CASE_NOTES_TINYMCE_EDITOR_ID, 'SYNCHRONOUS_OR_THROW');
        const narrativeHtml = sanitizeTinyMCEValue(editor.getContent() || '');

        if (this.props.applicationSettings.RMS_CASE_FOLDERING_ENABLED && this.props.folderId) {
            this.props
                .updateCaseNoteInFolder(
                    {
                        ...this.props.caseNote,
                        title: this.state.title,
                        content: narrativeHtml,
                    },
                    parseInt(this.props.folderId)
                )
                .catch((error) => {
                    this.props.openErrorModal({
                        title: strings.errorFailedToSaveCaseNotePleaseTryAgain.title,
                        paragraphs: [error.message],
                    });
                });
        } else {
            this.props
                .updateCaseNote({
                    ...this.props.caseNote,
                    title: this.state.title,
                    content: narrativeHtml,
                })
                .catch((error) => {
                    this.props.openErrorModal({
                        title: strings.errorFailedToSaveCaseNotePleaseTryAgain.title,
                        paragraphs: [error.message],
                    });
                });
        }
    };

    deleteCaseNoteLocation = () => {
        this.props
            .replaceLocationLinksForEntity(
                EntityTypeEnum.CASE_NOTE.name,
                this.props.caseNote.id,
                []
            )
            .catch(() => {
                this.props.openErrorModal({
                    title: strings.failedToDeleteLocation,
                });
            });
    };

    handleEditorError = (errorMessage) => {
        this.props.openErrorModal({
            paragraphs: [errorMessage],
        });
    };

    render() {
        const { caseNote, initializeAttachmentsSidePanelForm } = this.props;
        const attachmentEntityLinkDetails = {
            entityType: EntityTypeEnum.CASE_NOTE.name,
            entityId: this.props.caseNote.id,
            attachmentLinkType: LinkTypesEnum.CASE_NOTE_ATTACHMENT,
        };
        return (
            <Container>
                <NoteControls>
                    <NoteLeftControls>
                        <LocationSidePanel
                            entityType={EntityTypeEnum.CASE_NOTE.name}
                            entityId={caseNote.id}
                            overlayId={overlayIdEnum.LOCATION_OVERLAY_CASE_NOTES}
                            linkType={LinkTypesEnum.CASE_NOTE_LOCATION}
                            renderButton={({ overlayBase: { open } }) => (
                                <>
                                    <Button
                                        onClick={open}
                                        iconLeft={iconTypes.LOCATION}
                                        className={buttonTypes.ICON_LINK}
                                        testId={testIds.CASE_NOTE_MANAGE_LOCATIONS}
                                    >
                                        {strings.location}
                                    </Button>
                                </>
                            )}
                        />
                        <AttachmentsSidePanel
                            getInitialCustomPropertyState={() => attachmentEntityLinkDetails}
                            overlayId={overlayIdEnum.CASE_NOTE_ATTACHMENTS_SIDE_PANEL}
                            renderButton={({ overlayBase: { open } }) => {
                                function handleAddClick() {
                                    initializeAttachmentsSidePanelForm(attachmentEntityLinkDetails);
                                    open();
                                }

                                return (
                                    <Button
                                        onClick={handleAddClick}
                                        iconLeft={iconTypes.DOCUMENT}
                                        className={buttonTypes.ICON_LINK}
                                        testId={testIds.CASE_NOTE_MANAGE_ATTACHMENTS}
                                    >
                                        {strings.attachment}
                                    </Button>
                                );
                            }}
                        />
                    </NoteLeftControls>

                    <div>
                        <Button
                            onClick={this.saveCaseNote}
                            float="none"
                            className={buttonTypes.PRIMARY}
                            display="inline-block"
                            testId={testIds.CASE_NOTE_SAVE}
                        >
                            {strings.save}
                        </Button>
                        <Modal
                            buttonElement={
                                <OverlayButton
                                    testId={testIds.TRASH_BUTTON}
                                    className={buttonTypes.ICON_LINK}
                                    iconLeft={
                                        <Icon
                                            size={15}
                                            color="cobaltBlue"
                                            type={iconTypes.TRASH_CAN}
                                        />
                                    }
                                    id={overlayIdEnum.DELETE_CASE_NOTE_MODAL}
                                />
                            }
                            id={overlayIdEnum.DELETE_CASE_NOTE_MODAL}
                            onSave={this.deleteCaseNote}
                            title={strings.confirmDeleteModal.title(
                                this.props.displayFieldNames.singularCaseFieldName
                            )}
                        >
                            {strings.confirmDeleteModal.message(
                                this.props.displayFieldNames.singularCaseFieldName
                            )}
                        </Modal>
                    </div>
                </NoteControls>
                <CaseNoteLocation caseNoteId={caseNote.id} onDelete={this.deleteCaseNoteLocation} />
                <NoteTitleInput
                    value={this.state.title}
                    onChange={(value) => this.setState({ title: value })}
                    placeholder="Untitled"
                    fieldName={fields.CASE_NOTE_TITLE}
                />
                <Editor
                    id={CASE_NOTES_TINYMCE_EDITOR_ID}
                    value={this.state.content}
                    hideNarrativeGuide={false}
                    entityType={EntityTypeEnum.CASE_NOTE.name}
                    fieldName={fields.CASE_NOTE_CONTENT}
                    isScrollable={true}
                    setError={this.handleEditorError}
                />
                <CaseNoteAttachments
                    caseNoteId={caseNote.id}
                    openAttachmentsSidePanel={this.openAttachmentsSidePanel}
                />
            </Container>
        );
    }
}

const CaseNoteEditor = (props) => {
    const kebabMenuContext = useContext(KebabMenuContext);
    const draggingIdContext = useContext(DraggingIdContext);
    const selectedRowsContext = useContext(SelectedRowsContext);
    const displayFieldNames = useCaseFieldName();
    return (
        <BaseCaseNoteEditor
            {...props}
            kebabMenuContext={kebabMenuContext}
            draggingIdContext={draggingIdContext}
            selectedRowsContext={selectedRowsContext}
            displayFieldNames={displayFieldNames}
        />
    );
};

const mapDispatchToProps = {
    updateCaseNote,
    updateCaseNoteInFolder,
    replaceLocationLinksForEntity,
    openErrorModal,
    removeCaseNotes,
    initializeAttachmentsSidePanelForm,
    permanentlyDeleteCaseNotesFromFolder,
};

const mapStateToProps = createStructuredSelector({
    applicationSettings: applicationSettingsSelector,
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(CaseNoteEditor);
