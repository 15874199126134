import {
    AttributeTypeEnum,
    EntityTypeEnum,
    EntityTypeEnumType,
    ItemProfile,
    NameCaseLink,
} from '@mark43/rms-api';
import React, { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import pluralize from 'pluralize';
import { mediaQueries } from 'arc';

import { itemProfileByIdSelector } from '~/client-common/core/domain/item-profiles/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { fieldConfigurationByFieldNameSelector } from '~/client-common/core/domain/field-configurations/state/data';
import { NAME_CASE_LINK_INVOLVEMENT_TYPE_ATTR_ID } from '~/client-common/core/enums/universal/fields';

import testIds from '../../../../core/testIds';
import { Tooltip } from '../../../core/components/tooltip/Tooltip';
import { SidePanelSection as _SidePanelSection } from '../../../../legacy-redux/components/core/SidePanel';
import NameSummaryViewWrapperCase from '../../../core/components/NameSummaryViewWrapperCase';
import { ArbiterMFTAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import ManageItemsItemList from '../../../reports/core/components/items/ManageItemsItemList';
import Row from '../../../core/components/Row';
import { currentCaseIdSelector } from '../../core/state/ui';
import {
    caseLinkedProfilesForm,
    CaseLinkedProfilesFormType,
    convertFromFormModel,
    NameLinksFormPath,
    NameLinksFormPathFromReport,
} from '../state/forms/caseLinkedProfilesForm';
import { addNameCaseLink, removeNameCaseLink } from '../state/ui/caseLinkedProfilesSidePanel';
import { useCaseLinkedProfileLabels } from '../hooks';
import Select from '../../../core/forms/components/selects/Select';
import {
    formatPersonInvolvementSelector,
    formatOrganizationInvolvementSelector,
    checkProfileIsFromReportSelector,
} from '../state/ui';

const strings = componentStrings.cases.core.CaseSidebar;

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const SelectContainer = styled.div`
    @media (min-width: ${mediaQueries.sm}) {
        max-width: 75%;
    }
`;

const SidePanelSection = styled(_SidePanelSection)`
    padding: 0 20px;
`;

const CaseLinkedProfilesForm: FC<{ form: CaseLinkedProfilesFormType }> = ({ form }) => {
    const {
        personLabel,
        organizationLabel,
        vehicleLabel,
    } = useCaseLinkedProfileLabels();
    const currentCaseId = useSelector(currentCaseIdSelector);
    const itemProfileById = useSelector(itemProfileByIdSelector);
    const checkProfileIsFromReport = useSelector(checkProfileIsFromReportSelector);

    const model = convertFromFormModel(form?.getState().model);

    const showPeople =
        model.personNameCaseLinks.length + model.personNameCaseLinksFromReport.length > 0;
    const showOrgs = model.orgNameCaseLinks.length + model.orgNameCaseLinksFromReport.length > 0;
    const showVehicles = model.vehicleItemCaseLinks.length > 0;

    const showActionButtons = (masterItemId: number) => !checkProfileIsFromReport(
        masterItemId,
        EntityTypeEnum.ITEM_PROFILE.name
    );

    return (
        <Container>
            {showPeople &&
                <SidePanelSection title={pluralize(personLabel)}>
                    <ProfileSection
                        entityType={EntityTypeEnum.PERSON_PROFILE.name}
                        path="personNameCaseLinks"
                        form={form}
                    />
                </SidePanelSection>
            }
            {showOrgs && 
                <SidePanelSection title={pluralize(organizationLabel)}>
                    <ProfileSection
                        entityType={EntityTypeEnum.ORGANIZATION_PROFILE.name}
                        path="orgNameCaseLinks"
                        form={form}
                    />
                </SidePanelSection>
            }
            {showVehicles &&
                <SidePanelSection title={pluralize(vehicleLabel)}>
                    {/* @ts-expect-error `ManageItemsItemList` is not typed yet */}
                    <ManageItemsItemList
                        disableItemLinking={false}
                        itemProfiles={model.vehicleItemCaseLinks.reduce<ItemProfile[]>((acc, { itemId }) => {
                            const itemProfile = itemProfileById(itemId);
                            return itemProfile ? [...acc, itemProfile] : acc;
                        }, [])}
                        isVehicle={true}
                        limitToOne={false}
                        ownerType={EntityTypeEnum.CASE.name}
                        ownerId={currentCaseId}
                        showCheckboxes={false}
                        showActionButtons={showActionButtons}
                        renderAdditionalItem={({ index }: { index: number }) => (
                            <Row>
                                <ArbiterMFTAttributeSelect
                                    attributeType={AttributeTypeEnum.TARGET_PROFILE_LEVEL.name}
                                    path={`vehicleItemCaseLinks[${index}].targetProfileLevelAttrId`}
                                    length="lg"
                                />
                            </Row>
                        )}
                    />
                </SidePanelSection>
            }
        </Container>
    );
};

type ProfileSectionProps = {
    entityType: EntityTypeEnumType;
    form: CaseLinkedProfilesFormType;
    path: NameLinksFormPath;
};

type NameCaseLinkIndexed = NameCaseLink & { index: number };

const INVOLVEMENT_ATTR_DEFAULT_SELECT_OPTION_KEY = 'INVOLVEMENT_ATTR_DEFAULT_SELECT_OPTION_KEY';

const ProfileSection: FC<ProfileSectionProps> = ({ entityType, form, path }) => {
    const dispatch = useDispatch();

    const currentCaseId = useSelector(currentCaseIdSelector);
    const checkProfileIsFromReport = useSelector(checkProfileIsFromReportSelector);
    const involvementTypeLabel = useSelector(fieldConfigurationByFieldNameSelector)[
        NAME_CASE_LINK_INVOLVEMENT_TYPE_ATTR_ID
    ].displayName;
    const formatPersonInvolvement = useSelector(formatPersonInvolvementSelector);
    const formatOrganizationInvolvement = useSelector(formatOrganizationInvolvementSelector);

    const formatInvolvementMap: Partial<Record<EntityTypeEnumType, (id: number) => string>> = {
        [EntityTypeEnum.PERSON_PROFILE.name]: formatPersonInvolvement,
        [EntityTypeEnum.ORGANIZATION_PROFILE.name]: formatOrganizationInvolvement,
    };

    const convertedFormData = convertFromFormModel(form.getState().model);

    const pathFromReport: NameLinksFormPathFromReport = `${path}FromReport`;

    const stubbedNameItemLinks = useMemo(
        () => [
            ...convertedFormData[path].map((link, index) => ({
                ...link,
                index,
            })),
            ...convertedFormData[pathFromReport].map((link, index) => ({
                ...link,
                index,
            })),
        ],
        [convertedFormData, path, pathFromReport]
    );

    return (
        <NameSummaryViewWrapperCase
            form={caseLinkedProfilesForm}
            formPath={path}
            contextType={EntityTypeEnum.CASE.name}
            contextId={currentCaseId}
            parentEntityType={EntityTypeEnum.CASE.name}
            parentId={currentCaseId}
            entityType={entityType}
            show={{
                people: entityType === EntityTypeEnum.PERSON_PROFILE.name,
                organizations: entityType === EntityTypeEnum.ORGANIZATION_PROFILE.name,
            }}
            hideQuickAdd={false}
            limitToOne={true}
            showMugshotThumbnail={true}
            addNameReportLink={(link: NameCaseLink) => {
                dispatch(addNameCaseLink(path, link));
            }}
            deleteNameReportLink={(link: NameCaseLink) => {
                dispatch(removeNameCaseLink(path, link));
            }}
            stubbedNameItemLinks={stubbedNameItemLinks}
            summaryModeItem={(nameLink: NameCaseLinkIndexed) => checkProfileIsFromReport(nameLink.nameId, nameLink.entityType)}
            renderAdditionalItem={({
                nameLink,
            }: {
                nameLink: NameCaseLinkIndexed;
            }) => {
                const isFromReport = checkProfileIsFromReport(nameLink.nameId, entityType);

                return (
                    <Row>
                        {isFromReport ? (
                            <Tooltip
                                isOpen={isFromReport ? undefined : false}
                                data-test-id={testIds.CASE_LINKED_PROFILES_CASE_FROM_REPORT_TOOLTIP}
                                content={strings.tooltips.editableFromSource}
                                hasArrow
                                side="top"
                                sideOffset={-20}
                            >
                                <SelectContainer>
                                    <Select
                                        label={involvementTypeLabel}
                                        disabled
                                        value={INVOLVEMENT_ATTR_DEFAULT_SELECT_OPTION_KEY}
                                        options={[
                                            {
                                                label:
                                                    formatInvolvementMap[nameLink.entityType]?.(
                                                        nameLink.nameId
                                                    ) || '',
                                                value: INVOLVEMENT_ATTR_DEFAULT_SELECT_OPTION_KEY,
                                            },
                                        ]}
                                    />
                                </SelectContainer>
                            </Tooltip>
                        ) : (
                            <ArbiterMFTAttributeSelect
                                disabled={isFromReport}
                                attributeType={AttributeTypeEnum.FIELD_CONTACT_SUBJECT_TYPE.name}
                                path={`${path}[${nameLink.index}].involvementTypeAttrId`}
                                length="lg"
                            />
                        )}
                        <ArbiterMFTAttributeSelect
                            attributeType={AttributeTypeEnum.TARGET_PROFILE_LEVEL.name}
                            path={`${isFromReport ? pathFromReport : path}[${
                                nameLink.index
                            }].targetProfileLevelAttrId`}
                            length="lg"
                        />
                    </Row>
                );
            }}
        />
    );
};

export default CaseLinkedProfilesForm;
